// fires immediately (no delay) and ignores other fn requests until timeout
export function throttle(fn, ms) {
  let lastMoment = 0;

  return function process(...args) {
    if (lastMoment + ms > Date.now()) {
      return;
    }

    fn(...args);
    lastMoment = Date.now();
  };
}
