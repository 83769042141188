import styled from 'styled-components';
import ReactECharts from 'echarts-for-react';
import { breakpoints } from 'styles/theme/breakpoints';
import {
  LegendItemValue,
  LegendItemTitle,
  ItemValue,
  ItemPercent,
  LegendWrapperValue,
} from '../Legend/styled';

export const ContainerStyled = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column;
  }
`;

export const Chart = styled(ReactECharts)`
  @media (min-width: ${breakpoints.xxl}) and (max-width: ${breakpoints.xxlSuperMax}) {
    width: 420px;
  }
  @media (min-width: ${breakpoints.xxlSuperMax}) {
    width: 680px;
  }
  width: 100%;
`;

export const LegendContainerStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  ${LegendItemValue} {
    ${LegendItemTitle} {
      width: 80px;
    }
    ${LegendWrapperValue}, ${ItemPercent} {
      text-align: end;
      width: 70px;
    }
    ${ItemValue} {
      width: 50px;
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
    margin-top: 25px;
  }
`;
