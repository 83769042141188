import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  useApp,
  useCustomer,
  useLoading,
  usePatchManagementAssets,
  usePlatform,
} from 'store';
import { Row } from 'antd';
import { EmptyTablePreview, InnerLayout, MobileTable, MobileTableCol } from 'shared';
import { AssetsListSchema, GridRequestParams } from 'services/api';
import { getTableColumns } from 'services/helpers';
import { defaultCurrent, defaultPageSize, GUTTER } from 'app-constants';
import { Routes } from 'services/entities';
import { CustomTable } from 'shared/Table/CustomTable';
import { patchManagementApi } from 'store/patchManagement/assets/thunks';
import { getQueryString } from 'services/api/core/request';
import { PmPlatformFilter } from 'shared/PmPlatformsFilter';
import { useLocation } from 'react-router-dom';
import { filterFields, initialOrderBy, redirectToCmdbParams, searchSettings } from './constants';
import { getColumns } from './components/TableColumns';
import { getLayoutConfig } from './helpers';
import { useFilter } from '../../services/hooks/useFilter';
import { HistoryState } from '../../services/interfaces';
import { CmdbTabKeys } from '../CmdbHostDetails/constants';

export const PatchManagementAssets = () => {
  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();
  const { data } = usePatchManagementAssets();
  const { isMobile } = useApp();
  const history = useHistory();
  const platform = usePlatform();
  const { state: historyState, search } = useLocation<HistoryState>();

  const loading = useLoading([patchManagementApi.getAssetsList.typePrefix]);

  const [params, updateParams] = useFilter({
    dispatch,
    isMobile,
    customerId,
    page: 'PATCH_MANAGEMENT_ASSETS',
    noRequest: true,
    historyState,
    initialOrderBy,
  });

  React.useEffect(() => {
    dispatch(
      patchManagementApi.getAssetsList({
        ...params,
        platform,
      }),
    );
  }, [params, platform, dispatch]);

  const layoutConfig = getLayoutConfig({ customComponent: <PmPlatformFilter /> }, historyState);

  const redirectToCmdbDetails = React.useCallback(
    (record: AssetsListSchema) => {
      const path =
        typeof record.hostId === 'string'
          ? record.hostId
          : getQueryString(redirectToCmdbParams(record.name || ''));

      history.push(`${Routes.CMDB}/${path}`, {
        backTo: {
          route: Routes.PATCH_MANAGEMENT_ASSETS,
          search: history.location.search,
          title: 'Back to Assets',
        },
        rootBackTo: historyState?.backTo,
        activeTabKey: CmdbTabKeys.PATCH_MANAGEMENT,
      });
    },
    [history, historyState?.backTo],
  );

  const emptyMessage = (
    <EmptyTablePreview
      title='No Assets yet'
      loading={loading[patchManagementApi.getAssetsList.typePrefix]}
    />
  );

  return (
    <InnerLayout {...layoutConfig}>
      {!isMobile ? (
        <CustomTable<AssetsListSchema, GridRequestParams>
          data={data}
          columns={getTableColumns(getColumns(search), params.orderBy)}
          params={params}
          setParams={updateParams}
          searchSettings={searchSettings}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          filterFields={filterFields}
          onRowClickHandle={redirectToCmdbDetails}
          isLoadingContent={loading[patchManagementApi.getAssetsList.typePrefix]}
          emptyMessage={emptyMessage}
        />
      ) : (
        <Row gutter={GUTTER}>
          <MobileTableCol flex='auto'>
            <MobileTable<AssetsListSchema, GridRequestParams>
              data={data}
              columns={getTableColumns(getColumns(search), params.orderBy)}
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              searchSettings={searchSettings}
              filterFields={filterFields}
              onRowClick={redirectToCmdbDetails}
              isLoadingContent={loading[patchManagementApi.getAssetsList.typePrefix]}
              emptyMessage={emptyMessage}
            />
          </MobileTableCol>
        </Row>
      )}
    </InnerLayout>
  );
};
