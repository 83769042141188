import styled from 'styled-components';
import { ThemeProps } from 'services/interfaces';
import { breakpoints } from 'styles/theme';
import { Box, Icon } from '../../../../../shared';

export const TotalWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  margin: 0 50px;
  padding-bottom: 50px;

  @media (max-width: ${breakpoints.xml}) {
    padding-bottom: 60px;
  }
`;

export const DotIcon = styled(Icon)`
  padding: 10px 20px 0 0;

  @media (max-width: ${breakpoints.lg}) {
    padding: 5px 20px 0 0;
  }
`;

export const TotalValue = styled(Box)<ThemeProps>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }: ThemeProps) => theme.sizes[6]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.black};
  line-height: 28px;
  min-width: 75px;
  @media (max-width: ${breakpoints.xl}) {
    font-size: ${({ theme }: ThemeProps) => theme.sizes[5]};
    line-height: 24px;
  }

  span {
    font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
    font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
    line-height: 20px;
    padding-top: 12px;

    @media (max-width: ${breakpoints.xl}) {
      font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
    }
  }
`;

export const PercentValue = styled(Box)<ThemeProps>`
  font-size: 16px;
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
  line-height: 20px;
  margin: 5px 0 0 5px;
  padding-left: 5px;

  @media (max-width: ${breakpoints.xl}) {
    margin: 1px 0 0 5px;
    font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
    width: 40px;
  }
`;
