import React from 'react';
import { BoostScoresContainer, StyledCard } from 'pages/Dashboard/styled';
import { TooltipTitles } from 'pages/Dashboard/constants';
import { BoostScoreItemSchema } from 'services/api';
import { useApp } from 'store';
import { useTheme } from 'styled-components';
import { BoostScoreItem } from './BoostScoreItem';
import { emptyMessage } from '../../../../shared/Table/EmptyTablePreview/constants';

type BoostScoreProps = {
  isLoading?: boolean;
  boostScores?: BoostScoreItemSchema[];
  boostScoreOnClick: (score: BoostScoreItemSchema) => void;
  score?: number;
  scoreLoading?: boolean;
};

export const BoostScore = ({
  isLoading,
  boostScores,
  boostScoreOnClick,
  score,
  scoreLoading,
}: BoostScoreProps) => {
  const theme = useTheme();

  const { isMobile } = useApp();

  return (
    <StyledCard
      title='Boost score'
      valueMini={score || 0}
      isValueMiniLoading={scoreLoading}
      isHiddable={isMobile}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles.BoostScores}
      showLoader={isLoading}
      headStyles={
        !isMobile
          ? { pb: `${theme.spacing[4]}` }
          : { pb: `${theme.spacing[0]}`, titlePaddingRight: `${theme.spacing[0]}` }
      }
      bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
      cardPadding={
        !isMobile
          ? `${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[2]} ${theme.spacing[6]}`
          : `${theme.spacing[1]} ${theme.spacing[4]} ${theme.spacing[1]} ${theme.spacing[4]}`
      }
      showDivider={!isMobile}
    >
      {boostScores?.length ? (
        <BoostScoresContainer>
          {boostScores?.map((score: BoostScoreItemSchema) => {
            return (
              <BoostScoreItem
                title={score.title || ''}
                subTitle={score.vendor}
                date={score.expirationDate}
                onClick={() => {
                  boostScoreOnClick(score);
                }}
                key={score.title}
              />
            );
          })}
        </BoostScoresContainer>
      ) : (
        emptyMessage('No boost score data yet')
      )}
    </StyledCard>
  );
};
