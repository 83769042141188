import { Theme } from 'styles/theme/types';
import { Colors, SegmentedPieData, Values } from '../types';
import { ICustomPieChartTooltipProps } from '../formatters';

export const getData = (values: Values, colors?: Colors) => {
  return Object.keys(values).reduce(
    (result: SegmentedPieData, key) => [
      ...result,
      {
        name: key,
        value: values[key],
        itemStyle: { color: colors && colors[key] },
      },
    ],
    [],
  );
};

// const subTitleFormatterSum = (data: number[], float = 0): number => {
//   const sum = data?.reduce((accumulator: number, value = 0) => accumulator + value, 0);
//   return numberFormatter(sum, float) as number;
// };

export const getOption = ({
  values,
  colors,
  hasClickHandler,
  getCustomDataHandler,
}: {
  values: Values;
  theme: Theme;
  colors?: Colors;
  entitiesName: string;
  isMobile?: boolean;
  noPercentageLabel?: boolean;
  hasClickHandler?: boolean;
  getCustomDataHandler?: (values: Values, colors?: Colors) => SegmentedPieData;
  tooltip?: ICustomPieChartTooltipProps;
  customTotal?: string;
  minShowLabelAngle?: number;
  startAngle?: number;
}) => {
  const data = getCustomDataHandler
    ? getCustomDataHandler(values, colors)
    : getData(values, colors);

  const valueData = data.map((item) => item.value);

  return {
    polar: {
      radius: [1, '90%'],
    },
    angleAxis: {
      max: Math.max(...valueData),
      show: false,
      startAngle: 75,
    },
    radiusAxis: {
      type: 'category',
      data: data.sort((a, b) => a.value - b.value).map((item) => item.name),
      z: 10,
      axisLabel: {
        show: false,
      },
    },
    tooltip: {
      show: false,
    },
    series: {
      cursor: hasClickHandler ? 'pointer' : 'default',
      type: 'bar',
      barMinAngle: 5,
      data: data.sort((a, b) => a.value - b.value),
      coordinateSystem: 'polar',
    },
  };
};
