import styled from 'styled-components';
import { Table } from 'shared';
import { Tabs } from 'antd';
import { Theme } from 'styles/theme/types';

type Props = {
  theme: Theme;
};

export const ClickableTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;

    &:before {
      display: none;
    }
    .ant-tabs-tab {
      border-radius: ${({ theme }: Props) =>
        `${theme.sizes['1.2']} ${theme.sizes['1.2']} ${theme.sizes[0]} ${theme.sizes[0]}`};
      color: ${({ theme }: Props) => theme.colorSet.tabsNavigation.inactiveTabColor};
      &:hover {
        color: ${({ theme }: Props) => theme.colorSet.tabsNavigation.inactiveTabHoverColor};
      }
    }
    .ant-tabs-tab-active {
      background: ${({ theme }: Props) => theme.colorSet.tabsNavigation.activeTabBg};
      color: ${({ theme }: Props) => theme.colorSet.tabsNavigation.activeTabColor};
    }
    .ant-tabs-ink-bar {
      opacity: 0;
    }
  }
`;
