import React from 'react';
import { Icon, UcFirstLetter } from 'shared';
import { useTheme } from 'styled-components';
import { Spacer } from '../../../shared/TableFilters/styled';
import { StatusWrapper } from '../styled';
import { CEStatus, opacityStatusColor } from '../constants';

interface StatusType {
  background: string;
  icon: string;
}

interface CustomTagProps {
  status: string;
}

export const CEPlusStatus = ({ status }: CustomTagProps) => {
  const theme = useTheme();

  const statusType = (tag: string): StatusType => {
    switch (tag) {
      case CEStatus.Compliant:
        return { background: opacityStatusColor.green, icon: theme.colors.green[500] };
      default:
        return { background: opacityStatusColor.red, icon: theme.colors.red[400] };
    }
  };

  return (
    <StatusWrapper color={statusType(status).background} key={status}>
      <Icon
        component={theme.icons.fiber_manual_record}
        color={statusType(status).icon}
        dimension='8px'
      />
      <Spacer w='10px' />
      {UcFirstLetter(status)}
    </StatusWrapper>
  );
};
