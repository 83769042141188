/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagingConnectionInfoResponse } from '../models/MessagingConnectionInfoResponse';
import { request as __request } from '../core/request';

export class MessagingService {
  /**
   * Get messaging connection info
   * Get messaging connection info
   * @returns MessagingConnectionInfoResponse Connection information
   * @throws ApiError
   */
  public static async getConnectionInfo(): Promise<MessagingConnectionInfoResponse> {
    const result = await __request({
      method: 'GET',
      path: `/messaging/connection-info`,
      errors: {
        400: `Bad request`,
      },
    });
    return result.body;
  }
}
