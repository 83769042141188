import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { Card } from 'shared';
import { CardTitle } from 'shared/Card/components/styled';

type Props = { theme: Theme };

export const StyledCard = styled(Card)<Props>`
  ${CardTitle} {
    @media (min-width: ${({ theme }: Props) => theme.breakpoints.md}) {
      margin-top: ${({ theme }) => theme.sizes[1]};
    }
  }
`;
