import React from 'react';
import { useDispatch } from 'react-redux';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { ActionPopup } from 'shared';
import { useApp, useAuth, useCustomer } from 'store';
import { useHistory } from 'react-router';
import { useGetPermission } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { NotificationStatus } from 'services/api';
import { Popup } from './styled';
import { getActions, getCustomMenu, showPopup } from '../helpers';
import { DropdownActionsProps } from './DropdownActions';
import { ActionTypeName } from '../constant';

export interface MobileActionsPopupProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

export const MobileActionsPopup = ({
  isVisible,
  setIsVisible,
  notification,
  onSuccess,
}: MobileActionsPopupProps & DropdownActionsProps) => {
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const history = useHistory();
  const { user } = useAuth();
  const isNormUser = user.isSuperAdmin;
  const isFullAccess = useGetPermission(PermissionOptions.Notifications) === PermissionsLevel.Full;
  const readButtonSwitcher = notification?.read
    ? [ActionTypeName.MarkAsRead]
    : [ActionTypeName.MarkAsUnread];
  const isRead =
    notification.status !== NotificationStatus.DRAFTS
      ? readButtonSwitcher
      : [ActionTypeName.MarkAsRead, ActionTypeName.MarkAsUnread];
  const isPublished =
    notification?.status === NotificationStatus.PUBLISHED
      ? ActionTypeName.Publish
      : ActionTypeName.UnPublish;
  const excludeActionName = [...isRead, isPublished];

  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsVisible(false);
  };

  const actions = getActions((isNormUser && isFullAccess) as boolean, excludeActionName);

  const handleClickAction = (popupId: string) => {
    handleCancel();
    if (notification.id) {
      const notificationDetails = notification;

      showPopup({
        popupId,
        setPopupDetails,
        setIsVisiblePopup,
        notificationDetails,
        dispatch,
        customerId,
        onSuccess,
        isMobile,
        history,
      });
    }
  };

  return (
    <>
      {isVisible && (
        <Popup
          className='mobile-actions-modal'
          title='Actions'
          visible={isVisible}
          width={240}
          footer={null}
          onCancel={handleCancel}
          closable={false}
        >
          {getCustomMenu(actions, handleClickAction)}
        </Popup>
      )}

      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
          inputType='textarea'
        />
      )}
    </>
  );
};
