import React, { PropsWithChildren } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAuth } from '../../store';
import { Routes } from '../../services/entities';

export function PublicRouteGroup({ children, ...props }: PropsWithChildren<RouteProps>) {
  const { isAuthorized } = useAuth();

  if (isAuthorized) {
    return <Redirect to={Routes.DASHBOARD} />;
  }

  return <Route {...props}>{children}</Route>;
}
