import React from 'react';
import { Tooltip } from 'antd';
import { Icon } from 'shared';
import { icons, sizes } from 'styles/theme';

interface IProps {
  labelText: string;
  tooltipText: string;
}

export const LabelForm = ({ labelText, tooltipText }: IProps) => {
  return (
    <>
      {labelText}
      <Tooltip title={tooltipText}>
        <Icon component={icons.info_outline} dimension={sizes[6]} className='tooltipInfo' />
      </Tooltip>
    </>
  );
};
