import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { initialPagination } from 'app-constants';
import { TableLayout } from 'rc-table/lib/interface';
import ScrollContainer from 'react-indiana-drag-scroll';
import { TableRowSelection } from 'antd/lib/table/interface';
import { getOrderType } from 'services/helpers';
import {
  Box,
  Card,
  EmptyTablePreview,
  EmptyTablePreviewProps,
  Pagination,
  Table,
  TableFilters,
} from 'shared';
import { TablePaginationConfig, TableProps } from 'antd';
import { Filters, GridRequestParams, Pagination as PaginationType } from 'services/api';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useTheme } from 'styled-components';
import { FilterType, SearchSettings, SetParamsType } from './types';
import { noMathCriteria } from './EmptyTablePreview/constants';
import { MultyDeletionButtons } from './components/MultyDeletionButtons';

export interface RowType {
  _id?: unknown;
  id?: string;
  qid?: string;
  title?: string;
  hostId?: string;
}

export type Props<T extends RowType, T2 extends GridRequestParams> = {
  data?: {
    rows?: Array<T>;
    count?: number;
  };
  columns: ColumnsType<T>;
  params: GridRequestParams;
  setParams: (updatedParams: SetParamsType<T2>) => void;
  defaultPageSize: number;
  defaultCurrent: number;
  searchSettings?: SearchSettings;
  filterFields: FilterType[];
  expandable?: {
    expandedRowRender: (record: T) => JSX.Element;
    onExpandedRowsChange?: (expandedRows: readonly React.Key[]) => void;
  };
  isLoadingContent?: boolean;
  maxFilterFieldsLength?: number;
  onRowClickHandle?: (param: T) => void;
  expandedRowKeys?: string[];
  noPagination?: boolean;
  rowSelection?: TableRowSelection<T>;
  tableLayout?: TableLayout;
  emptyMessage?: React.ReactNode;
  onMultyDeletionSubmit?: (soft: boolean, action?: string, tagId?: string) => void;
  customEmptyMessageText?: EmptyTablePreviewProps | null;
  className?: string;
};

export const CustomTable = <T extends RowType, T2 extends GridRequestParams>({
  data,
  columns,
  params,
  setParams,
  defaultPageSize,
  defaultCurrent,
  searchSettings,
  filterFields,
  isLoadingContent,
  onRowClickHandle,
  rowSelection,
  expandable,
  maxFilterFieldsLength,
  tableLayout,
  expandedRowKeys,
  emptyMessage,
  onMultyDeletionSubmit,
  customEmptyMessageText,
  noPagination,
  className,
}: Props<T, T2>) => {
  const theme = useTheme();

  const [selectedItems, setSelectedItems] = useState<React.Key[]>([]);
  const [isBulkActivated, setIsBulkActivated] = useState(false);

  useEffect(() => {
    setSelectedItems([]);
  }, [data]);

  const handleTableChange = (tablePagination: PaginationType, sorter: SorterResult<T>) => {
    let newParams = {
      ...params,
      pagination: {
        count: tablePagination.count,
        page: tablePagination.page,
      },
    };

    if (sorter.field && sorter.order) {
      newParams = {
        ...newParams,
        pagination: {
          count: tablePagination.count,
          page: initialPagination.page,
        },
        orderBy: { field: `${sorter.field}`, type: getOrderType(sorter.order) },
      };
    }

    setParams((prevParams: T2) => ({
      ...prevParams,
      ...newParams,
    }));
  };

  const handleFiltersChange = React.useCallback(
    (filter: Filters | null) => {
      if (!filter) {
        setParams((prevParams: T2) => {
          const { customerId, orderBy, pagination, tab } = prevParams;

          return {
            customerId,
            orderBy,
            pagination: { count: pagination.count, page: initialPagination.page },
            ...(tab ? { tab } : {}),
          } as T2;
        });

        return;
      }

      setParams((prevParams: T2) => {
        const newFilter = {
          ...filter,
        };

        if (filter && filter?.fields?.length) {
          newFilter.fields = filter.fields;
        } else {
          delete newFilter.fields;
        }

        if (
          searchSettings?.min &&
          filter &&
          filter.search?.value &&
          filter.search.value.length >= searchSettings.min
        ) {
          newFilter.search = filter.search;
        } else {
          delete newFilter.search;
        }

        return {
          ...prevParams,
          filter: newFilter,
          pagination: { count: prevParams.pagination?.count, page: initialPagination.page },
        };
      });
    },
    [searchSettings?.min, setParams],
  );

  if (!isLoadingContent && !data?.count && !params.filter && emptyMessage) {
    return (
      <Card bodyPadding='0' cardPadding={`${theme.sizes[12]} 0`}>
        {emptyMessage}
      </Card>
    );
  }

  return (
    <>
      {(filterFields.length || searchSettings) && (
        <TableFilters
          onFiltersChange={handleFiltersChange}
          filterFields={filterFields}
          searchSettings={searchSettings}
          currentFilter={params.filter || null}
          maxFilterFieldsLengthDefault={maxFilterFieldsLength}
        />
      )}
      <Card
        cardPadding={`${theme.sizes['5.5']}`}
        bodyPadding={`${theme.sizes[0]}`}
        className='custom-table-card-wrapper'
      >
        <>
          {!noPagination && (
            <Pagination
              current={params.pagination?.page}
              pageSize={params.pagination?.count}
              total={data?.count ? data.count : undefined}
              showTotal={(total: number, range: [number, number]) => (
                <Box display='flex' gap='12px' flexWrap='wrap'>
                  {isBulkActivated ? (
                    <span>{`${selectedItems.length} of ${total} item(s) selected`}</span>
                  ) : (
                    `${range[0]}-${range[1]} of ${total} items`
                  )}
                  {onMultyDeletionSubmit && (
                    <Box mt='-4px'>
                      <MultyDeletionButtons
                        onCancel={() => {
                          setIsBulkActivated(false);
                        }}
                        deleteDisabled={!selectedItems.length}
                        onSubmit={(soft: boolean, action?: string, tagId?: string) => {
                          onMultyDeletionSubmit(soft, action, tagId);
                        }}
                        onActivated={() => setIsBulkActivated(true)}
                      />
                    </Box>
                  )}
                </Box>
              )}
              onChange={(page: number, pageSize?: number) =>
                handleTableChange({ page, count: pageSize }, params.orderBy)
              }
              defaultPageSize={defaultPageSize}
              defaultCurrent={defaultCurrent}
              showSizeChanger
              showQuickJumper
              responsive
              showLessItems
            />
          )}
          <ScrollContainer
            ignoreElements='.ant-table-tbody'
            hideScrollbars={false}
            vertical={false}
          >
            <Table<React.FC<TableProps<T>>>
              className={className}
              columns={columns}
              dataSource={data?.rows?.length ? data.rows : []}
              rowKey={(record: T) => {
                if (record._id) {
                  return JSON.stringify(record._id);
                }

                return (
                  record.id ||
                  record.qid ||
                  (record.title as React.Key) ||
                  (record.hostId as React.Key)
                );
              }}
              expandable={expandable}
              expandedRowKeys={expandedRowKeys}
              onChange={(
                __: TablePaginationConfig,
                _,
                sorter: SorterResult<T> | SorterResult<T>[],
              ) => {
                if (!Array.isArray(sorter)) {
                  handleTableChange(params.pagination || initialPagination, sorter);
                }
              }}
              pagination={false}
              onRow={(record: T) => ({
                onClick: () => {
                  if (onRowClickHandle) {
                    onRowClickHandle(record);
                  }
                },
              })}
              rowSelection={
                rowSelection && isBulkActivated
                  ? {
                      ...rowSelection,
                      selectedRowKeys: selectedItems,
                      onChange: (selectedRowKeys: React.Key[], selectedRows: T[]) => {
                        rowSelection?.onChange?.(selectedRowKeys, selectedRows);
                        setSelectedItems(selectedRowKeys);
                      },
                    }
                  : undefined
              }
              isClickable={Boolean(onRowClickHandle)}
              loading={isLoadingContent}
              tableLayout={tableLayout || 'auto'}
              locale={{
                emptyText: (
                  <EmptyTablePreview
                    title={isEmpty(customEmptyMessageText) ? '' : customEmptyMessageText?.title}
                    textContent={
                      isEmpty(customEmptyMessageText)
                        ? noMathCriteria
                        : customEmptyMessageText?.textContent
                    }
                    loading={isLoadingContent}
                    alignCenter
                    marginTop={theme.spacing['5']}
                  />
                ),
              }}
            />
          </ScrollContainer>
        </>
        {!noPagination && (
          <Pagination
            current={params.pagination?.page}
            pageSize={params.pagination?.count}
            total={data?.count ? data.count : undefined}
            showTotal={(total: number, range: [number, number]) => (
              <Box display='flex' gap='12px' flexWrap='wrap'>
                {isBulkActivated ? (
                  <span>{`${selectedItems.length} of ${total} item(s) selected`}</span>
                ) : (
                  `${range[0]}-${range[1]} of ${total} items`
                )}
              </Box>
            )}
            onChange={(page: number, pageSize?: number) =>
              handleTableChange({ page, count: pageSize }, params.orderBy)
            }
            defaultPageSize={defaultPageSize}
            defaultCurrent={defaultCurrent}
            showSizeChanger
            showQuickJumper
            responsive
            showLessItems
          />
        )}
      </Card>
    </>
  );
};
