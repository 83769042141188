import React from 'react';
import { useDispatch } from 'react-redux';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { Box, Button, Input } from 'shared';
import { Popup } from 'shared/ActionPopup/styled';
import { PayloadAction } from '@reduxjs/toolkit';
import { adminUsersApi, AppDispatch, MessageType, useCustomer, useLoading } from 'store';
import { RequestStatus } from 'store/loading/slice';
import * as Yup from 'yup';
import { CreateUserRequest, SuccessResponse } from 'services/api';
import { replaceSpace, showError, showMessage } from 'services/helpers';
import { ISimpleInputFields } from 'shared/inputs/Input/types';
import { Form } from 'antd';
import {
  createUserFields,
  FieldNames,
  formErrorMessages,
  phoneMinLength,
  phoneRegExp,
  TableTitles,
} from '../constants';
import { CreateModalMode, FieldValues } from '../types';
import { getCreateModalButtonLabel, getCreateModalTitle } from '../helpers';
import { getCssVar } from '../../../styles/theme';

type CreateUserPopupProps = {
  isVisible: boolean;
  mode: CreateModalMode;
  editFieldValues?: FieldValues;
  currentContactId?: string;
  setIsVisible: (value: boolean) => void;
  onSuccess: () => void;
};

const defaultFieldsValues = createUserFields.reduce(
  (acc: FieldValues, field: ISimpleInputFields) => ({ ...acc, [field.name]: field.value }),
  {} as FieldValues,
);

const validationSchema = Yup.object({
  [FieldNames.FirstName]: Yup.string()
    .trim()
    .required(formErrorMessages[FieldNames.FirstName].isRequired),
  [FieldNames.LastName]: Yup.string()
    .trim()
    .required(formErrorMessages[FieldNames.LastName].isRequired),
  [FieldNames.Email]: Yup.string()
    .trim()
    .email(formErrorMessages[FieldNames.Email].isValid)
    .required(formErrorMessages[FieldNames.Email].isRequired),
  [FieldNames.Phone]: Yup.string()
    .trim()
    .min(phoneMinLength, formErrorMessages[FieldNames.Phone].isValid)
    .matches(phoneRegExp, formErrorMessages[FieldNames.Phone].isValid),
});

export const CreateUserPopup = ({
  isVisible,
  currentContactId,
  setIsVisible,
  mode,
  editFieldValues,
  onSuccess,
}: CreateUserPopupProps) => {
  const customer = useCustomer();
  const dispatch: AppDispatch = useDispatch();
  const loading = useLoading([
    adminUsersApi.createUser.typePrefix,
    adminUsersApi.updateUser.typePrefix,
  ]);

  const onCancel = React.useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const createUserOnSubmit = React.useCallback(
    async (requestBody: CreateUserRequest) => {
      const api =
        mode === CreateModalMode.Edit
          ? adminUsersApi.updateUser({
              customerId: customer,
              userId: currentContactId || '',
              requestBody: { ...requestBody, currentContactId },
            })
          : adminUsersApi.createUser({
              customerId: customer,
              requestBody: { ...requestBody, currentContactId },
            });

      const result = (await dispatch(api)) as PayloadAction<
        SuccessResponse,
        string,
        { requestStatus: string },
        { message: string }
      >;

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        const errorMessage = result.error.message;

        showError(errorMessage || undefined);

        return;
      }

      showMessage(
        {
          content: `User has been successfully created.`,
        },
        MessageType.Success,
      );
      onSuccess();
      onCancel();
    },
    [dispatch, customer, onSuccess, onCancel, currentContactId, mode],
  );

  const formik = useFormik({
    initialValues: editFieldValues || defaultFieldsValues,
    validationSchema,
    onSubmit: (values: FieldValues) =>
      createUserOnSubmit({
        email: values.email.trim(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        phone: values.phone.trim(),
      }),
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.setFieldValue(FieldNames.Email, replaceSpace(e.currentTarget.value));
  };

  return (
    <Popup
      className='actions-modal'
      title={getCreateModalTitle(mode)}
      visible={isVisible}
      onCancel={onCancel}
      confirmLoading={
        loading[
          adminUsersApi[mode === CreateModalMode.Edit ? 'updateUser' : 'createUser'].typePrefix
        ]
      }
      okButtonProps={{ disabled: !formik.dirty || !formik.isValid }}
      okText={getCreateModalButtonLabel(mode)}
      onOk={() => formik.handleSubmit()}
      footer={[
        <Button key='back' onClick={() => setIsVisible(false)}>
          Cancel
        </Button>,
        <Button
          type='primary'
          key='submit'
          disabled={!formik.dirty || !formik.isValid}
          onClick={() => formik.handleSubmit()}
          loading={
            loading[
              adminUsersApi[mode === CreateModalMode.Edit ? 'updateUser' : 'createUser'].typePrefix
            ]
          }
        >
          {getCreateModalButtonLabel(mode)}
        </Button>,
      ]}
    >
      <Form layout='vertical' onFinish={formik.handleSubmit} initialValues={formik.initialValues}>
        {mode === CreateModalMode.Edit && (
          <Box d='flex' gap='56px' mb='28px' color={getCssVar('textColor')}>
            <Box fontWeight={500}>{TableTitles.Email}</Box>
            <Box>{editFieldValues?.email}</Box>
          </Box>
        )}
        {createUserFields.map((field: ISimpleInputFields) => {
          if (mode === CreateModalMode.Edit && field.name === FieldNames.Email) {
            return null;
          }

          return (
            <Box key={field.name}>
              <Input
                id={field.name}
                name={field.name}
                label={field.label}
                onChange={field.name === FieldNames.Email ? handleEmailChange : formik.handleChange}
                value={formik.values[field.name as FieldNames]}
                maxLength={field.maxLength}
                validateStatus={
                  formik.errors[field.name as keyof FormikErrors<FieldValues>] &&
                  formik.touched[field.name as keyof FormikTouched<FieldValues>]
                    ? 'error'
                    : 'success'
                }
                help={
                  formik.errors[field.name as keyof FormikErrors<FieldValues>] &&
                  formik.touched[field.name as keyof FormikTouched<FieldValues>]
                    ? formik.errors[field.name as keyof FormikErrors<FieldValues>]
                    : undefined
                }
                onBlur={formik.handleBlur}
                required={field.name !== 'phone'}
              />
            </Box>
          );
        })}
      </Form>
    </Popup>
  );
};
