import React from 'react';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { Notification, NotificationStatus } from 'services/api';
import { Routes } from 'services/entities';
import { backlinkRoutes } from 'app-constants/routes';
import { Theme } from 'styles/theme/types';
import { StatusComponent } from 'shared/layouts/Inner/components';
import { getFormattedDate } from 'services/helpers';
import { IRowTableType } from './types';
import { UpdateNotification } from './constants';
import { AdminButtons } from './components/AdminButtons';

export const getLayoutConfig = (
  title?: string,
  notificationStatus?: NotificationStatus | string,
  showAdminButtons = false,
): InnerLayout => ({
  title: title ?? UpdateNotification.CREATE,
  backLink: (
    <BackLink
      defaultRoute={Routes.NOTIFICATIONS}
      defaultTitle={backlinkRoutes[Routes.NOTIFICATIONS]}
    />
  ),
  status: notificationStatus && <StatusComponent level={notificationStatus || ''} />,
  customComponent: showAdminButtons && <AdminButtons />,
});

export const getRowsForSummaryTable = (request: Notification, isNormUser: boolean) => {
  const { createdBy, created, published } = request;

  const dataForTableWithoutPriority: Array<IRowTableType> = [
    { title: 'Created by', description: createdBy || '-' },
    {
      title: 'Created',
      description: getFormattedDate(created),
    },
    { title: 'Published', description: getFormattedDate(published) },
  ];

  return isNormUser
    ? dataForTableWithoutPriority
    : dataForTableWithoutPriority.filter((item) => item.title === 'Published');
};

export const getHeadStyles = (isMobile: boolean, theme: Theme) =>
  !isMobile
    ? { pb: `${theme.spacing[4]}` }
    : { pb: `${theme.spacing[2]}`, titlePaddingRight: `${theme.spacing[0]}` };

export const getCardPadding = (isMobile: boolean, theme: Theme) =>
  !isMobile
    ? `${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[2]} ${theme.spacing[6]}`
    : `${theme.spacing['3.5']} ${theme.spacing[5]} ${theme.spacing[2]} ${theme.spacing[5]}`;

export enum FormFieldLabels {
  Type = 'type',
  Title = 'title',
  Message = 'message',
}
