import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Box, Card } from 'shared';
import { Tabs } from 'antd';
import { useApp, useCustomer, useLoading } from 'store';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { initialPagination } from 'app-constants';
import { TabsStyled } from 'pages/CmdbHostDetails/styled';
import { useTheme } from 'styled-components';
import { CmdbTabKeys, CmdbTabsTitles } from '../../../constants';
import { Vulnerabilities } from '../../Vulnerabilities/Vulnerabilities';
import { AssetDetails } from '../../AssetDetails/AssetDetails';
import { InterfaceDetails } from '../../InterfaceDetails/InterfaceDetails';
import { InstalledSoftware } from '../../InstalledSoftware/InstalledSoftware';
import { initialOrderBy } from '../../Vulnerabilities/constants';
import { AssetDetailsPatchManagement } from '../../PatchManagement/PatchManagement';
import { HistoryState } from '../../../../../services/interfaces';
import { Alarms } from '../../Alarms';
// import { SuppressedVulnerabilities } from '../../SuppressedVulnerabilities/SuppressedVulnerabilities';
import { SuppressedVulnerabilitiesTable } from '../../SuppressedVulnerabilities/SuppressedVulnerabilitiesTable';

export const DefaultLayout = () => {
  const theme = useTheme();
  const { TabPane } = Tabs;
  const { isMobile } = useApp();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const customerId = useCustomer();
  const { state: historyState } = useLocation<HistoryState>();
  const loading = useLoading([
    assetDetailsApi.getDetailsVulnerabilitiesListByHost.typePrefix,
    assetDetailsApi.getVulnerabilitiesBySeverity.typePrefix,
    assetDetailsApi.getAssetDetails.typePrefix,
    assetDetailsApi.getInstalledSoftware.typePrefix,
    assetDetailsApi.getInterfaceDetails.typePrefix,
  ]);

  React.useEffect(() => {
    dispatch(
      assetDetailsApi.getAssetDetails({
        customerId,
        hostId: id,
      }),
    );
    dispatch(
      assetDetailsApi.getInterfaceDetails({
        customerId,
        hostId: id,
      }),
    );
    dispatch(
      assetDetailsApi.getInstalledSoftware({
        customerId,
        orderBy: initialOrderBy,
        pagination: initialPagination,
        hostId: id,
      }),
    );
    dispatch(
      assetDetailsApi.getVulnerabilitiesCountByStatus({
        customerId,
        hostId: id,
      }),
    );
  }, [dispatch, customerId, id]);

  return (
    <Card
      height={theme.sizes.full}
      bodyPadding={!isMobile ? undefined : theme.sizes[0]}
      cardPadding={
        !isMobile ? theme.sizes['2.5'] : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes[5]}`
      }
      showLoader={Object.values(loading).some((action: boolean) => action)}
    >
      <TabsStyled
        className='customTabs'
        defaultActiveKey={historyState?.activeTabKey || CmdbTabKeys.VULNERABILITIES}
      >
        <TabPane tab={CmdbTabsTitles.Vulnerabilities} key={CmdbTabKeys.VULNERABILITIES}>
          <Vulnerabilities />
        </TabPane>

        <TabPane tab={CmdbTabsTitles.AssetDetails} key={CmdbTabKeys.ASSETS_DETAILS}>
          <Box
            p={
              !isMobile
                ? `${theme.sizes[0]} ${theme.sizes['2.5']} ${theme.sizes['2.5']}`
                : `${theme.sizes[3]} ${theme.sizes[0]} ${theme.sizes[0]}`
            }
          >
            <AssetDetails />
          </Box>
        </TabPane>

        <TabPane tab={CmdbTabsTitles.InterfaceDetails} key={CmdbTabKeys.INTERFACE_DETAILS}>
          <InterfaceDetails />
        </TabPane>

        <TabPane tab={CmdbTabsTitles.InstalledSoftware} key={CmdbTabKeys.INSTALLED_SOFTWARE}>
          <InstalledSoftware />
        </TabPane>

        <TabPane tab={CmdbTabsTitles.PatchManagement} key={CmdbTabKeys.PATCH_MANAGEMENT}>
          <AssetDetailsPatchManagement />
        </TabPane>

        <TabPane tab={CmdbTabsTitles.Alarms} key={CmdbTabKeys.ALARMS}>
          <Alarms />
        </TabPane>
        <TabPane
          tab={CmdbTabsTitles.SuppressedVulnerabilities}
          key={CmdbTabKeys.SUPPRESSED_VULNERABILITIES}
        >
          <SuppressedVulnerabilitiesTable />
        </TabPane>
      </TabsStyled>
    </Card>
  );
};
