import { initialPagination } from 'app-constants';
import { Filter, OrderBy, SupportRequestsStatus, SupportRequestsType } from 'services/api';

const securityIncidentTypeFilterField = {
  name: 'type',
  value: SupportRequestsType.SECURITY_INCIDENT,
  type: Filter.type.VALUE,
};

export const openIncidentsParams = {
  orderBy: { field: 'updated', type: OrderBy.type.DESC },
  pagination: initialPagination,
  filter: { fields: [securityIncidentTypeFilterField] },
};

export const withNormIncidentsParams = {
  orderBy: { field: 'updated', type: OrderBy.type.DESC },
  pagination: initialPagination,
  filter: {
    fields: [
      securityIncidentTypeFilterField,
      { name: 'status', value: SupportRequestsStatus.WITH_NORM, type: Filter.type.VALUE },
    ],
  },
};

export const withYouIncidentsParams = {
  orderBy: { field: 'updated', type: OrderBy.type.DESC },
  pagination: initialPagination,
  filter: {
    fields: [
      securityIncidentTypeFilterField,
      { name: 'status', value: SupportRequestsStatus.WITH_CUSTOMER, type: Filter.type.VALUE },
    ],
  },
};
