import { Status } from 'pages/PenetrationTesting/constants';
import { Filter, OrderBy } from 'services/api';
import { Severity } from 'services/constants';
import { OptionType } from 'shared/Table/types';
import { testFilterOptions } from './data/data';
import { InputType } from '../../services/api/models/InputType';

export const initialOrderBy = {
  field: 'endDate',
  type: OrderBy.type.DESC,
};

export enum ColumnKey {
  Vulnerability = 'vulnerability',
  Severity = 'severity',
  Status = 'status',
  Test = 'test',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum TableTitles {
  Vulnerability = 'Vulnerability',
  Severity = 'Severity',
  Status = 'Status',
  Test = 'Test',
  StartDate = 'Start date',
  EndDate = 'End date',
}

export const optionsChangeStatus: OptionType[] = [
  { value: Status.ToStart, label: Status.ToStart },
  { value: Status.InProgress, label: Status.InProgress },
  { value: Status.Completed, label: Status.Completed },
];

export const optionsChangeSeverity: OptionType[] = [
  { value: Severity.Critical, label: Severity.Critical },
  { value: Severity.High, label: Severity.High },
  { value: Severity.Medium, label: Severity.Medium },
  { value: Severity.Low, label: Severity.Low },
  { value: Severity.Info, label: Severity.Info },
];

export const filterFields = [
  {
    field: 'status',
    options: optionsChangeStatus,
    label: 'Status',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'severity',
    options: optionsChangeSeverity,
    label: 'Severity',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'test',
    label: 'Test',
    options: testFilterOptions,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.VALUE,
    showSearch: true,
  },
  {
    field: 'startDate',
    label: 'Start date',
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export const searchSettings = {
  fields: ['vulnerability'],
  min: 2,
  placeholder: 'Search by vulnerability name',
};
