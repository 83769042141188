import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useGetPermissions } from 'services/hooks';
import { managedDetectionsResponseApi, useCustomerChange, useEvents, useLoading } from 'store';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { Routes } from 'services/entities';
import { getSubTitleArrow, numberFormatter } from 'services/helpers';
import { getOption } from 'pages/ThreatDetection/components/AllEvents/helpers';
import { emptyValues } from 'pages/ThreatDetection/constants';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { Area } from 'shared/charts';
import { RadioButtonsDays } from 'shared/RadioButtonsWithRangePicker/constants';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { ScrollContainer } from 'shared';
import { EmptyValues, IEvents } from 'pages/ThreatDetection/types';
import { useTheme } from 'styled-components';
import { TooltipTitles, viewMoreConfig } from '../../constants';
import { StyledCard } from './styled';

interface ThreatDetectionCardProps {
  customerId: string;
  isMobile: boolean;
}

const EmptyAreaGridHeight = 285;
const areaGridHeight = 265;

const EmptyAreaHeight = 350;
const areaHeight = 330;

export const ThreatDetectionCard = ({ customerId, isMobile }: ThreatDetectionCardProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { eventsData } = useEvents();
  const loading = useLoading([managedDetectionsResponseApi.events.typePrefix]);

  const customerChange = useCustomerChange([managedDetectionsResponseApi.events.typePrefix]);
  const isShowEmptyChartAllEvents = customerChange[managedDetectionsResponseApi.events.typePrefix];

  const permissions = useGetPermissions([
    PermissionOptions.ThreatDetection,
    PermissionOptions.SupportRequests,
  ]);

  const eventsDate = React.useMemo(
    () => ({
      startDate: moment()
        .subtract(RadioButtonsDays.SevenDays - 1, 'days')
        .startOf('day')
        .valueOf(),
      endDate: moment().valueOf(),
    }),
    [],
  );

  React.useEffect(() => {
    const { startDate, endDate } = eventsDate;

    dispatch(
      managedDetectionsResponseApi.events({
        customerId,
        startDate,
        endDate,
      }),
    );
  }, [dispatch, customerId, eventsDate]);

  return (
    <StyledCard
      title='Threat detection - All events'
      subTitle={
        <SubTitle
          boldText={
            eventsData.allEventsCount ? `${numberFormatter(eventsData.allEventsCount)}` : ''
          }
          isLocked={permissions.threatDetection === PermissionsLevel.NoAccess}
          lightText={eventsData.trendingPercent ? `${eventsData.trendingPercent}%` : ''}
          period='last 7 days'
          arrow={getSubTitleArrow(eventsData.trendingPercent)}
          isEnabledArrow={false}
          paddingRight={theme.sizes[4]}
        />
      }
      showDivider={false}
      tooltipTitle={TooltipTitles.ThreatDetection}
      viewMore={
        permissions.threatDetection === PermissionsLevel.NoAccess ? undefined : viewMoreConfig.mdr
      }
      bodyPadding={theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[7]} ${theme.sizes[8]} ${theme.sizes['5.5']}`
          : `${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[2]} ${theme.sizes[4]}`
      }
      headStyles={!isMobile ? { headAlign: 'flex-start' } : { headAlign: 'flex-start' }}
      showLoader={loading[managedDetectionsResponseApi.events.typePrefix]}
      getAccessMessage={
        permissions.threatDetection === PermissionsLevel.NoAccess &&
        permissions.supportRequests === PermissionsLevel.Full
          ? {
              text: 'Order Threat Detection & Response',
              href: `${Routes.SERVICE_REQUEST}?type=purchase&product=Threat+Detection+%26+Response`,
              layout: 'column',
            }
          : undefined
      }
    >
      <ScrollContainer width='600px'>
        <TotalCustomTooltipContainer>
          <RenderChartWrapper<EmptyValues[], EmptyValues[]>
            values={
              permissions.threatDetection === PermissionsLevel.NoAccess
                ? emptyValues
                : eventsData?.allEvents
            }
            emptyValues={emptyValues}
            emptyText='No events yet'
            isShowEmptyChart={isShowEmptyChartAllEvents}
          >
            <Area
              values={emptyValues as IEvents[]}
              getOption={getOption}
              date={eventsDate}
              gridHeight={!eventsData?.allEvents ? EmptyAreaGridHeight : areaGridHeight}
              height={!eventsData?.allEvents ? EmptyAreaHeight : areaHeight}
            />
          </RenderChartWrapper>
        </TotalCustomTooltipContainer>
      </ScrollContainer>
    </StyledCard>
  );
};
