import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCssVar, icons } from 'styles/theme';
import { Icon } from 'shared';
import { newsNotificationsApi, useApp, useCustomer, useNewsNotifications } from 'store';
import { useHistory } from 'react-router-dom';
import { Routes } from 'services/entities';
import {
  NotificationIconWrapper,
  NotificationListDrawer,
  NotificationMessage,
  Overlay,
  Pulsing,
} from './styled';
import { NotificationList } from './NotificationList';
import { notificationFilterFields } from './constants';
import { useOutsideClick } from '../../../../services/hooks/useClickAway';

const MAX_COUNT_NOTIFICATION = 9;

export const NotificationsHeader = () => {
  const ref = useRef<HTMLSpanElement>(null);
  const dispatch = useDispatch();
  const { isDesktop } = useApp();
  const customerId = useCustomer();
  const history = useHistory();
  const route = history.location.pathname.split('/')[1];
  const notificationsRoute = Routes.NOTIFICATIONS.split('/')[1];
  const { notificationUnreadGrid } = useNewsNotifications();
  const unreadCountRef = useRef(notificationUnreadGrid.count ?? 0);
  const [isNotificationIconActive, setNotificationIconAsActive] = useState(
    route === notificationsRoute,
  );
  const [isOpen, setIsOpen] = useState(false);

  const getNotificationFilterFields = useMemo(
    () => ({
      ...notificationFilterFields,
      customerId,
    }),
    [customerId],
  );

  useEffect(() => {
    const html = document.querySelector('html');

    if (html && !isDesktop) html.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen, isDesktop]);

  useEffect(() => {
    const count = notificationUnreadGrid?.count;

    if (count && unreadCountRef.current !== count) {
      unreadCountRef.current = count;

      return;
    }

    dispatch(newsNotificationsApi.getUnreadNotificationGrid(getNotificationFilterFields));
  }, [dispatch, getNotificationFilterFields, notificationUnreadGrid]);

  const onCloseHandler = useCallback(() => {
    setNotificationIconAsActive(route === notificationsRoute);
    setIsOpen(false);
  }, [notificationsRoute, route]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onCloseHandler();
    }
  });

  const openNotification = useCallback(() => {
    setNotificationIconAsActive(true);
    setIsOpen(true);
  }, []);

  const toggleNotification = useCallback(
    (isOpened: boolean) => {
      if (isOpened) {
        onCloseHandler();
      } else {
        openNotification();
      }
    },
    [onCloseHandler, openNotification],
  );

  const notificationCount = notificationUnreadGrid?.count ?? 0;

  return (
    <>
      {!isDesktop && <Overlay isOpen={isOpen} />}
      <NotificationIconWrapper
        ref={ref}
        onClick={() => toggleNotification(isOpen)}
        className={isNotificationIconActive ? 'active' : ''}
      >
        <Icon
          component={icons.notification}
          color={getCssVar('primaryColor')}
          className='notification-icon'
        />
        {notificationCount > 0 && (
          <Pulsing>{notificationCount > MAX_COUNT_NOTIFICATION ? '9+' : notificationCount}</Pulsing>
        )}
      </NotificationIconWrapper>
      <NotificationListDrawer
        title={<NotificationMessage>Notifications</NotificationMessage>}
        placement='right'
        visible={isOpen}
        onClose={onCloseHandler}
        mask={false}
        width='100%'
        getContainer={ref.current as HTMLElement}
        closeIcon={<Icon component={icons.close} />}
      >
        <NotificationList closeHandler={onCloseHandler} isOpen={isOpen} />
      </NotificationListDrawer>
    </>
  );
};
