import { createGlobalStyle } from 'styled-components';
import { PATH_TO_FONTS } from 'app-constants';

export const mainFontFamily = 'Segoe UI';

export const FontsNasstar = createGlobalStyle`

  @font-face {
    font-family: ${mainFontFamily};
    src: local('Segoe UI Light'), local('SegoeUI-Light'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-Light.woff2) format('woff2'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: local('Segoe UI'), local('SegoeUI'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI.woff2) format('woff2'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI.woff) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: local('Segoe UI Semibold'), local('SegoeUI-SemiBold'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-SemiBold.woff2) format('woff2'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: local('Segoe UI Bold'), local('SegoeUI-Bold'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-Bold.woff2) format('woff2'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-Bold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: local('Segoe UI Black'), local('SegoeUI-Black'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-Black.woff2) format('woff2');
    font-weight: 900;
    font-style: normal;
  }




  @font-face {
    font-family: ${mainFontFamily};
    src: local('Segoe UI Italic'), local('SegoeUI-Italic'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-Italic.woff2) format('woff2'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-Italic.woff) format('woff');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: local('Segoe UI Bold Italic'), local('SegoeUI-BoldItalic'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-BoldItalic.woff2) format('woff2'),
         url(${PATH_TO_FONTS}/nasstar/SegoeUI-BoldItalic.woff) format('woff');
    font-weight: bold;
    font-style: italic;
  }
`;
