import { Routes } from 'services/entities';

export const viewMore = {
  recentPenetration: {
    route: Routes.PENETRATION_TESTS_LIST,
    text: 'See all penetration tests',
  },
  currentFindings: {
    route: Routes.FINDINGS,
    text: 'See all findings',
  },
};

export enum Status {
  ToStart = 'To start',
  Completed = 'Completed',
  InProgress = 'In progress',
}
