import styled from 'styled-components';
import { ThemeProps } from 'services/interfaces';
import { Box, Icon } from 'shared';
import { Tooltip } from 'antd';
import { breakpoints, getCssVar } from '../../styles/theme';
import { EmailsChartContainer } from '../Dashboard/components/styled/styled';
import { ScrollContainer } from '../People/components/styled';
import { StyledSelect } from '../../shared/layouts/Inner/components/CustomersSelect/styled';

type MetricProps = {
  $collapsed?: boolean;
};

export const MetricWrapper = styled(Box)<ThemeProps & MetricProps>`
  align-items: center;
  border-color: ${getCssVar('divider.color')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }
`;

export const MetricElement = styled(Box)<ThemeProps & MetricProps>`
  align-items: center;
  display: flex;
  width: fit-content;

  &:last-child {
    margin-left: ${({ $collapsed }) => ($collapsed ? '70px' : '20px')};
  }

  @media (max-width: ${breakpoints.xxl}) {
    width: auto;
  }

  @media (max-width: ${breakpoints.xl}) {
    justify-content: space-between;
    margin-top: 16px;
    &:last-child {
      margin-left: 0;
    }
  }
`;

export const MetricSection = styled(Box)<ThemeProps>`
  display: flex;
  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const MetricValue = styled(Box)<ThemeProps>`
  font-size: ${({ theme }: ThemeProps) => theme.sizes[6]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.black};
  margin: 0 12px;

  @media (max-width: ${breakpoints.xl}) {
    margin: 0;
  }
`;

export const MetricTooltip = styled(Tooltip)<ThemeProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const MetricTitle = styled(Box)<ThemeProps>`
  font-size: ${({ theme }: ThemeProps) => theme.sizes['4.5']};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};

  @media (max-width: ${breakpoints.md}) {
    margin-right: 0;
    text-align: center;
    font-size: ${({ theme }: ThemeProps) => theme.sizes['4']};
  }
`;

export const MetricIcon = styled(Icon)<ThemeProps & MetricProps>`
  margin-left: 30px;
  margin-top: 10px;

  @media (max-width: ${breakpoints.xxl}) {
    margin-top: 8px;
  }

  @media (max-width: ${breakpoints.xl}) {
    display: flex;
    align-items: flex-end;
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-right: 0;
  }
`;

export const AssetsChart = styled(EmailsChartContainer)<ThemeProps>``;

export const VendorChart = styled(AssetsChart)<ThemeProps>`
  .chart-container {
    width: 100%;
  }
`;

export const ScrollWrapper = styled(ScrollContainer)<ThemeProps>``;

export const SelectPlatform = styled(StyledSelect)<ThemeProps>`
  font-weight: 400;
`;
