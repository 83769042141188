import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { app, AppState } from 'store';
import { DefaultTheme, ThemeProvider as StyledComponentsProvider } from 'styled-components';
import * as theme from 'styles/theme';
import { useSystemThemeSwitcher } from 'pages/Appearance/useThemeSwitcher';
import { isSystemThemeModeActive } from 'pages/Appearance/helpers';
import { norm } from 'styles/theme';
import { getThemeMode } from 'services/helpers/theme';
import { getColorSet } from './helpers';
import { Themes } from './types';

type ThemeProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const dispatch = useDispatch();
  const { themeName, currentThemeMode } = useSelector(
    ({ app: { themeName, currentThemeMode } }: AppState) => ({
      themeName,
      currentThemeMode,
    }),
  );
  const { systemTheme } = useSystemThemeSwitcher(isSystemThemeModeActive);

  const currentTheme = useMemo<DefaultTheme>(
    () => ({
      ...theme,
      colorSet: getColorSet(themeName) as typeof norm,
    }),
    [themeName],
  );

  useEffect(() => {
    if (window.location.host === process.env.REACT_APP_NASSTAR_DOMAIN) {
      dispatch(app.actions.setTheme(Themes.Nasstar));

      return;
    }

    // first login on the page || system theme
    if (!currentThemeMode || isSystemThemeModeActive(currentThemeMode)) {
      const themeMode = getThemeMode();

      if (themeMode) {
        dispatch(app.actions.setTheme(themeMode));
        dispatch(app.actions.setMode(themeMode));

        return;
      }

      dispatch(
        app.actions.setTheme(
          window.matchMedia('(prefers-color-scheme: dark)')?.matches
            ? Themes.DarkNorm
            : Themes.Norm,
        ),
      );

      return;
    }

    dispatch(app.actions.setTheme((themeName as Themes) || Themes.Norm));
  }, [currentThemeMode, dispatch, systemTheme, themeName]);

  return (
    <StyledComponentsProvider theme={currentTheme}>
      {themeName && children}
    </StyledComponentsProvider>
  );
};
