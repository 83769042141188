import { Pagination as AntdPagination } from 'antd';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

export const Pagination = styled(AntdPagination)`
  align-items: center;
  color: ${getCssVar('textColor')};
  display: flex;
  margin: ${({ theme }: { theme: Theme }) => `${theme.sizes['2.5']} ${theme.sizes[0]}`};

  .ant-pagination-item-link,
  .ant-pagination-item {
    background-color: ${getCssVar('pagination.page.bg')};
    border-color: ${getCssVar('pagination.page.borderColor')};
    border-radius: ${({ theme }: { theme: Theme }) => theme.radius.sm};
    color: ${getCssVar('textColor')};
    font-family: ${getCssVar('fontFamily')} !important;
    font-weight: ${getCssVar('pagination.textFontWeight')};

    &:disabled {
      color: ${getCssVar('input.disable.color')};
    }

    span {
      padding-bottom: ${getCssVar('pagination.paddingBottom')};
    }

    a {
      color: ${getCssVar('textColor')};
    }

    &:hover,
    &:focus {
      background: ${getCssVar('pagination.secondary')} !important;
      border-color: ${getCssVar('pagination.borderColor')};
      box-shadow: ${getCssVar('pagination.boxShadow')};

      a {
        color: ${getCssVar('textColor')};
      }
    }
  }

  .ant-pagination-item-active a {
    color: ${getCssVar('pagination.activeColor')} !important;
  }

  .ant-pagination-item-active {
    a,
    &:hover,
    &:focus {
      background: ${getCssVar('pagination.main')};
      border-color: ${getCssVar('pagination.main')};
      color: ${getCssVar('pagination.color')};
    }

    border-color: ${getCssVar('pagination.main')};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
  .ant-select-selector {
    border-color: ${getCssVar('pagination.main')};
    box-shadow: none;
  }

  .ant-pagination-total-text {
    flex: 1 1;
    font-size: 14px;
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.normal};
    height: auto;
    margin-right: auto;
  }

  .ant-pagination-options-quick-jumper input {
    background-color: ${getCssVar('input.bg')};
    border-color: ${getCssVar('input.borderColor')};
    color: ${getCssVar('textColor')};
    margin: ${({ theme }: { theme: Theme }) =>
      `${theme.sizes[0]} ${theme.sizes[2]} ${theme.sizes[0]} ${theme.sizes[2]}`};

    &:focus {
      border-color: ${getCssVar('select.multiple.borderColor')};
      box-shadow: 0 0 0 2px transparent;
    }
  }
`;

export const MobilePaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }: { theme: Theme }) => `${theme.spacing[2]} 0 ${theme.spacing[1]}`};
`;

export const PerPageContainer = styled.div`
  align-items: center;
  display: flex;

  > span {
    font-size: 14px;
    margin-right: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
  }

  .ant-select {
    min-width: 64px;

    .ant-select-arrow {
      margin-top: -6px;
      padding-left: 11px !important;
    }

    .ant-select-selector {
      padding: 0 11px 0 12px !important;

      .ant-select-selection-item {
        padding-right: 4px;
      }
    }
  }

  .ant-form-item {
    margin: 0 !important;
  }
`;

export const GoToContainer = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[3]};

  > span {
    font-size: 14px;
    margin-right: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
    white-space: nowrap;
  }

  .ant-form-item {
    margin: 0 !important;

    .ant-input {
      padding: 7px 12px;
    }
  }

  .ant-form-item-control-input-content {
    width: 60px;
  }
`;
