import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { maintenanceSettingsApi } from './thunks';
import { MaintenanceSettingsRequest } from '../../services/api';

interface MaintenanceState {
  snackbarText: string;
  maintenancePageMessage: string;
  maintenancePageMessageViewMode: string;
  toggleNotifyUsers?: boolean;
  maintenanceSettings?: MaintenanceSettingsRequest;
}

const initialState: MaintenanceState = {
  snackbarText: '',
  maintenancePageMessage: '',
  maintenancePageMessageViewMode: '',
  toggleNotifyUsers: false,
  maintenanceSettings: {},
};

export const maintenanceSetting = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setSnackbarText: (state: MaintenanceState, action: PayloadAction<string>) => ({
      ...state,
      snackbarText: action.payload,
    }),
    setMaintenancePageMessageViewMode: (
      state: MaintenanceState,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      maintenancePageMessageViewMode: action.payload,
    }),
    setMaintenancePageMessageText: (state: MaintenanceState, action: PayloadAction<string>) => ({
      ...state,
      maintenancePageMessage: action.payload,
    }),
    setIsNotifyUsers: (state: MaintenanceState, action: PayloadAction<boolean>) => ({
      ...state,
      toggleNotifyUsers: action.payload,
    }),
  },
  extraReducers: {
    [maintenanceSettingsApi.getMaintenanceSettingsSnackBarText.fulfilled.toString()]: (
      state: MaintenanceState,
      action: PayloadAction<MaintenanceSettingsRequest>,
    ) => {
      state.snackbarText = action.payload.snackbarText as string;
      state.toggleNotifyUsers = action.payload.isNotifyUsers;
    },
    [maintenanceSettingsApi.getMaintenanceSettings.rejected.toString()]: (
      state: MaintenanceState,
    ) => {
      return state;
    },
    [maintenanceSettingsApi.getMaintenanceSettings.fulfilled.toString()]: (
      state: MaintenanceState,
      action: PayloadAction<MaintenanceSettingsRequest>,
    ) => {
      state.maintenanceSettings = action.payload;
      state.toggleNotifyUsers = state.maintenanceSettings?.isNotifyUsers;
    },
    [maintenanceSettingsApi.getMaintenanceSettings.rejected.toString()]: (
      state: MaintenanceState,
    ) => {
      state.maintenanceSettings = {};
    },
  },
});
