import { CertificateName } from 'services/api';
import { TotalStressScoreLabels } from '../constants';

export enum LatestReportsKeys {
  Name = 'name',
  Size = 'size',
  CreatedOn = 'createdOn',
  Action = 'action',
}

export const stressScoreMobileOrder = {
  [TotalStressScoreLabels.CriticalHosts]: 0,
  [TotalStressScoreLabels.HighHosts]: 1,
  [TotalStressScoreLabels.IncompleteTraining]: 5,
  [TotalStressScoreLabels.IsolatedDevices]: 4,
  [TotalStressScoreLabels.SecurityIncidents]: 3,
  [TotalStressScoreLabels.CertificationsDue]: 6,
};

export const columnsOrder: { [key: string]: number } = {
  [LatestReportsKeys.Name]: 0,
  [LatestReportsKeys.Size]: 1,
  [LatestReportsKeys.CreatedOn]: 2,
  [LatestReportsKeys.Action]: 3,
};

export const staticCertificates = [
  {
    title: CertificateName.CYBER_ESSENTIALS,
  },
  {
    title: CertificateName.CYBER_ESSENTIALS_PLUS,
  },
  {
    title: CertificateName.ISO27001,
  },
];

export const emptyCurrentTrainingStatusObject = {
  completed: 0,
  notStarted: 0,
  started: 0,
};
export const emptyPhishingLastThirtyDaysObject = {
  clicked: 0,
  noActions: 0,
  opened: 0,
  providedCredentialsOrDownloadedAnAttachment: 0,
};

export const emptyRefresherStatistics = {
  passed: 0,
  ignored: 0,
  failed: 0,
};
