import React from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { RowType } from 'shared/Table/CustomTable';
import { Box, Icon } from 'shared';
import { icons } from 'styles/theme';
import { Tooltip } from 'antd';
import { ColumnKey, MAX_CHARS_IN_SHORTED_TEXT, TableTitles, userColumns } from '../constant';
import { DropdownActions } from './DropdownActions';
import { IconColoring } from './IconColoring';
import { Spacer } from '../../../shared/TableFilters/styled';
import { Notification } from '../../../services/api';
import { IconType } from './IconType';
import {
  getFormattedDate,
  getShortenedText,
  getStringWithoutTags,
  getTimeAgoLabel,
} from '../../../services/helpers';

import {
  ColumnDateWrapper,
  ColumnTitle,
  NotificationMessageColumn,
  UserActionWrapper,
} from './styled';

const USER_TABLE_COLUMN_WIDTH = {
  [ColumnKey.Title]: '300px',
  [ColumnKey.Message]: '600px',
};
const ADMIN_TABLE_COLUMN_WIDTH = {
  [ColumnKey.Title]: '180px',
  [ColumnKey.Message]: '340px',
};

export const getNormUserColumns = (
  onSuccess: () => void,
  setReadNotification: (isRead: boolean, notificationIds: string[]) => void,
  sizes = {
    title: ADMIN_TABLE_COLUMN_WIDTH[ColumnKey.Title],
    message: ADMIN_TABLE_COLUMN_WIDTH[ColumnKey.Message],
  },
): ColumnsType<RowType> => [
  {
    key: ColumnKey.Title,
    title: TableTitles.Title,
    dataIndex: ColumnKey.Title,
    render: (title: string, record: Notification) => (
      <ColumnTitle w={sizes.title} $isRead={record?.read || false}>
        {title}
      </ColumnTitle>
    ),
  },
  {
    key: ColumnKey.Message,
    title: TableTitles.Message,
    dataIndex: ColumnKey.Message,
    render: (message: string) => (
      <NotificationMessageColumn w={sizes.message}>
        {getShortenedText(getStringWithoutTags(message || ''), MAX_CHARS_IN_SHORTED_TEXT)}
      </NotificationMessageColumn>
    ),
  },
  {
    key: ColumnKey.Type,
    title: TableTitles.Type,
    dataIndex: ColumnKey.Type,
    sorter: true,
    render: (type: string) => {
      return (
        <Box display='flex' alignItems='center' flexDirection='row' w='200px'>
          <IconType type={type} />
          <Spacer w='10' />
          {type}
        </Box>
      );
    },
  },
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    sorter: true,
    render: (status: string) => {
      return (
        <Box flexDirection='row' w='140px'>
          <IconColoring status={status} />
          {status}
        </Box>
      );
    },
  },
  {
    key: ColumnKey.CreatedBy,
    title: TableTitles.CreatedBy,
    dataIndex: ColumnKey.CreatedBy,
    sorter: true,
    render: (createdBy: string) => {
      return <>{createdBy}</>;
    },
  },
  {
    key: ColumnKey.Created,
    title: TableTitles.Created,
    dataIndex: ColumnKey.Created,
    sorter: true,
    render: (created: number) => {
      return (
        <ColumnDateWrapper>
          {getFormattedDate(created)}
          <Box opacity='50%'>{getTimeAgoLabel(created)}</Box>
        </ColumnDateWrapper>
      );
    },
  },
  {
    key: ColumnKey.Published,
    title: TableTitles.Published,
    dataIndex: ColumnKey.Published,
    sorter: true,
    width: 120,
    render: (published: string) => {
      if (!published) {
        return '-';
      }

      return (
        <ColumnDateWrapper>
          {getFormattedDate(published)}
          <Box opacity='50%'>{getTimeAgoLabel(published)}</Box>
        </ColumnDateWrapper>
      );
    },
  },
  {
    key: ColumnKey.Actions,
    title: TableTitles.Actions,
    dataIndex: ColumnKey.Actions,
    align: 'right',
    render: (_: string, record: Notification) => {
      return (
        <Box onClick={(event: React.MouseEvent) => event.stopPropagation()}>
          <DropdownActions notification={record} onSuccess={onSuccess} />
        </Box>
      );
    },
  },
];

export const getColumns = (
  onSuccess: () => void,
  setReadNotification: (isRead: boolean, notificationIds: string[]) => void,
): ColumnsType<RowType> => [
  ...getNormUserColumns(onSuccess, setReadNotification, {
    title: USER_TABLE_COLUMN_WIDTH[ColumnKey.Title],
    message: USER_TABLE_COLUMN_WIDTH[ColumnKey.Message],
  }).filter((column) => userColumns.includes(column?.key as ColumnKey)),
  {
    key: ColumnKey.Actions,
    title: TableTitles.Actions,
    dataIndex: ColumnKey.Actions,
    align: 'right',
    render: (_: string, record: Notification) => {
      const toggleRead = record.read ? 'unread' : 'read';
      const notificationIds = record?.id ?? '';
      const isRead = toggleRead === 'read';

      return (
        <UserActionWrapper
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            setReadNotification(isRead, [notificationIds]);
          }}
        >
          <Tooltip title={`Mark as ${toggleRead}`}>
            <Icon component={icons[toggleRead]} />
          </Tooltip>
        </UserActionWrapper>
      );
    },
  },
];
