import { createContext, useContext } from 'react';

export const FeatureFlagContext = createContext<Record<string, boolean>>({});

export const useFlags = () => {
  const context = useContext(FeatureFlagContext);

  if (context === undefined) {
    throw new Error('useFlags must be used within a FeatureFlagProvider');
  }

  return context;
};

export const useFeatureFlag = (featureName: string): boolean => {
  const flags = useFlags();

  return flags[featureName];
};
