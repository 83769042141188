/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllVulnerabilitiesTrendSchema } from '../models/AllVulnerabilitiesTrendSchema';
import type { AssetVulnerabilitySchema } from '../models/AssetVulnerabilitySchema';
import type { ByCriticalitiesVulnerabilitiesTrendSchema } from '../models/ByCriticalitiesVulnerabilitiesTrendSchema';
import type { Count } from '../models/Count';
import type { CurrentVulnerabilitiesByEachCriticalitySchema } from '../models/CurrentVulnerabilitiesByEachCriticalitySchema';
import type { CustomerId } from '../models/CustomerId';
import type { Filters } from '../models/Filters';
import type { getVulnerabilitiesByIdsRequest } from '../models/getVulnerabilitiesByIdsRequest';
import type { MostVulnerableHostsSchema } from '../models/MostVulnerableHostsSchema';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { SeverityLevel } from '../models/SeverityLevel';
import type { Suppression } from '../models/Suppression';
import type { SuppressionGridResponse } from '../models/SuppressionGridResponse';
import type { SuppressVulnerabilitiesUsersResponse } from '../models/SuppressVulnerabilitiesUsersResponse';
import type { TagId } from '../models/TagId';
import type { Timestamp } from '../models/Timestamp';
import type { UpdateVulnerabilitySuppressionRequest } from '../models/UpdateVulnerabilitySuppressionRequest';
import type { VulnerabilitiesCountByStatusResponse } from '../models/VulnerabilitiesCountByStatusResponse';
import type { VulnerabilitiesResponse } from '../models/VulnerabilitiesResponse';
import type { VulnerabilitiesWithAffectedHostsSchema } from '../models/VulnerabilitiesWithAffectedHostsSchema';
import type { VulnerabilitiesWithCurrentAndTotalAffectedHostsSchema } from '../models/VulnerabilitiesWithCurrentAndTotalAffectedHostsSchema';
import type { VulnerabilitySchema } from '../models/VulnerabilitySchema';
import type { VulnerabilityStatus } from '../models/VulnerabilityStatus';
import type { VulnerabilityTrendPointTypeSchema } from '../models/VulnerabilityTrendPointTypeSchema';
import { request as __request } from '../core/request';

export class VulnerabilityManagementService {
  /**
   * Vulnerability Grid
   * Returns list of vulnerabilities
   * @returns VulnerabilitiesResponse List of vulnerability
   * @throws ApiError
   */
  public static async getVulnerabilities({
    customerId,
    orderBy,
    pagination,
    filter,
    tab,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters & {
      cvssScore?: number | null;
      /**
       * The From First Found timestamp filter
       */
      fromFirstFound?: Timestamp | null;
      /**
       * The To First Found timestamp filter
       */
      toFirstFound?: Timestamp | null;
      fixedVulnerabilities?: boolean | null;
      /**
       * The To First Found timestamp filter for fixed vulnerabilities
       */
      fixedVulnerabilitiesFrom?: Timestamp | null;
    };
    /** Tab parameter **/
    tab?: string;
  }): Promise<VulnerabilitiesResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerabilities`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
        tab: tab,
      },
      errors: {
        400: `An error message when get vulnerabilities list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerability filter options
   * Returns list of vulnerabilities categories
   * @returns any List of vulnerabilities categories
   * @throws ApiError
   */
  public static async getVulnerabilitiesCategories({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerabilities-categories`,
      errors: {
        400: `An error message when get vulnerabilities list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerability Details
   * Returns details on vulnerability
   * @returns VulnerabilitySchema Details on vulnerability
   * @throws ApiError
   */
  public static async getVulnerabilityDetails({
    customerId,
    vulnerabilityId,
    filter,
  }: {
    /** Customer id **/
    customerId: string;
    /** VulnerabilityId id **/
    vulnerabilityId: string;
    /** The filter parameter **/
    filter?: Filters & {
      cvssScore?: number | null;
      /**
       * The From First Found timestamp filter
       */
      fromFirstFound?: Timestamp | null;
      /**
       * The To First Found timestamp filter
       */
      toFirstFound?: Timestamp | null;
    };
  }): Promise<VulnerabilitySchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerabilities/${vulnerabilityId}`,
      query: {
        filter: filter,
      },
      errors: {
        400: `An error message when get alarms list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * All Vulnerabilities Trend
   * Returns trend for all vulnerabilities
   * @returns AllVulnerabilitiesTrendSchema Trend
   * @throws ApiError
   */
  public static async getAllVulnerabilitiesTrend({
    customerId,
    startDate,
    endDate,
    pointType,
    vulnerabilityStatus,
    tagIds,
    includeSuppressedVulnerabilities,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate: Timestamp;
    /** The end date parameter **/
    endDate: Timestamp;
    /** Chart point type **/
    pointType: VulnerabilityTrendPointTypeSchema;
    /** Vulnerability statuses parameter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Tags list **/
    tagIds?: Array<string>;
    /** Include suppressed vulnerabilities in response **/
    includeSuppressedVulnerabilities?: boolean;
  }): Promise<AllVulnerabilitiesTrendSchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/all-vulnerabilities-trend`,
      query: {
        startDate: startDate,
        endDate: endDate,
        pointType: pointType,
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
        includeSuppressedVulnerabilities: includeSuppressedVulnerabilities,
      },
      errors: {
        400: `An error message when get vulnerabilities trend`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * By Criticalities Vulnerabilities Trend
   * Returns trend for vulnerabilities by criticalities
   * @returns ByCriticalitiesVulnerabilitiesTrendSchema Trends by criticalities
   * @throws ApiError
   */
  public static async getVulnerabilitiesTrendsByCriticalities({
    customerId,
    startDate,
    endDate,
    pointType,
    criticalities,
    vulnerabilityStatus,
    tagIds,
    includeSuppressedVulnerabilities,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate: Timestamp;
    /** The end date parameter **/
    endDate: Timestamp;
    /** Chart point type **/
    pointType: VulnerabilityTrendPointTypeSchema;
    /** Array of criticalities values **/
    criticalities: Array<SeverityLevel>;
    /** Vulnerability statuses parameter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Tags list **/
    tagIds?: Array<string>;
    /** Include suppressed vulnerabilities in response **/
    includeSuppressedVulnerabilities?: boolean;
  }): Promise<ByCriticalitiesVulnerabilitiesTrendSchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/vulnerabilities-trends-by-criticalities`,
      query: {
        startDate: startDate,
        endDate: endDate,
        pointType: pointType,
        criticalities: criticalities,
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
        includeSuppressedVulnerabilities: includeSuppressedVulnerabilities,
      },
      errors: {
        400: `An error message when get vulnerabilities trend`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Current Vulnerabilities By Each Criticality
   * Returns current vulnerabilities by each criticality
   * @returns CurrentVulnerabilitiesByEachCriticalitySchema current vulnerabilities by each criticality
   * @throws ApiError
   */
  public static async getCurrentVulnerabilities({
    customerId,
    vulnerabilityStatus,
    tagIds,
    includeSuppressedVulnerabilities,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Vulnerability statuses parameter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Tags list **/
    tagIds?: Array<string>;
    /** Include suppressed vulnerabilities in response **/
    includeSuppressedVulnerabilities?: boolean;
  }): Promise<CurrentVulnerabilitiesByEachCriticalitySchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/current-vulnerabilities`,
      query: {
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
        includeSuppressedVulnerabilities: includeSuppressedVulnerabilities,
      },
      errors: {
        400: `An error message when get vulnerabilities trend`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Latest Vulnerabilities
   * Get latest vulnerabilities with affected hosts by criticalities
   * @returns VulnerabilitiesWithAffectedHostsSchema Latest Vulnerabilities
   * @throws ApiError
   */
  public static async getLatestVulnerabilitiesWithAffectedHosts({
    customerId,
    count,
    criticalities,
    vulnerabilityStatus,
    tagIds,
    includeSuppressedVulnerabilities,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Count **/
    count: Count;
    /** Array of criticalities values **/
    criticalities: Array<SeverityLevel>;
    /** Vulnerability statuses parameter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Tags list **/
    tagIds?: Array<string>;
    /** Include suppressed vulnerabilities in response **/
    includeSuppressedVulnerabilities?: boolean;
  }): Promise<VulnerabilitiesWithAffectedHostsSchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/latest-vulnerabilities-with-affected-hosts-by-criticalities`,
      query: {
        count: count,
        criticalities: criticalities,
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
        includeSuppressedVulnerabilities: includeSuppressedVulnerabilities,
      },
      errors: {
        400: `An error message when get vulnerabilities trend`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerabilities with most affected hosts
   * Get vulnerabilities with most affected hosts
   * @returns VulnerabilitiesWithCurrentAndTotalAffectedHostsSchema Vulnerabilities with most affected hosts
   * @throws ApiError
   */
  public static async getVulnerabilitiesWithMostAffectedHosts({
    customerId,
    count,
    criticalities,
    vulnerabilityStatus,
    tagIds,
    includeSuppressedVulnerabilities,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Count **/
    count: Count;
    /** Array of criticalities values **/
    criticalities: Array<SeverityLevel>;
    /** Vulnerability statuses parameter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Tags list **/
    tagIds?: Array<string>;
    /** Include suppressed vulnerabilities in response **/
    includeSuppressedVulnerabilities?: boolean;
  }): Promise<VulnerabilitiesWithCurrentAndTotalAffectedHostsSchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/vulnerabilities-with-most-affected-hosts`,
      query: {
        count: count,
        criticalities: criticalities,
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
        includeSuppressedVulnerabilities: includeSuppressedVulnerabilities,
      },
      errors: {
        400: `An error message when get vulnerabilities trend`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Most vulnerable hosts
   * Get most vulnerable hosts
   * @returns MostVulnerableHostsSchema The most vulnerable hosts
   * @throws ApiError
   */
  public static async getMostVulnerableHosts({
    customerId,
    orderBy,
    pagination,
    vulnerabilityStatus,
    tagIds,
    filter,
    includeSuppressedVulnerabilities,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** Vulnerability statuses parameter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Tags **/
    tagIds?: Array<TagId>;
    /** The filter parameter **/
    filter?: Filters;
    /** Include suppressed vulnerabilities in response **/
    includeSuppressedVulnerabilities?: boolean;
  }): Promise<MostVulnerableHostsSchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/most-vulnerable-hosts`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
        filter: filter,
        includeSuppressedVulnerabilities: includeSuppressedVulnerabilities,
      },
      errors: {
        400: `An error message when get vulnerabilities trend`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Asset Vulnerability Details
   * Returns details on asset vulnerability
   * @returns AssetVulnerabilitySchema Details on asset vulnerability
   * @throws ApiError
   */
  public static async getAssetVulnerabilityDetails({
    customerId,
    vulnerabilityId,
  }: {
    /** Customer id **/
    customerId: string;
    /** VulnerabilityId id (Object id) **/
    vulnerabilityId: string;
  }): Promise<AssetVulnerabilitySchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-vulnerabilities/${vulnerabilityId}`,
      errors: {
        400: `An error message when get asset vulnerability`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Export Vulnerabilities data
   * Export Vulnerabilities data
   * @returns any Export Vulnerabilities in csv file
   * @throws ApiError
   */
  public static async exportVulnerabilities({
    customerId,
    orderBy,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** The filter parameter **/
    filter?: Filters & {
      cvssScore?: number | null;
      /**
       * The From First Found timestamp filter
       */
      fromFirstFound?: Timestamp | null;
      /**
       * The To First Found timestamp filter
       */
      toFirstFound?: Timestamp | null;
      fixedVulnerabilities?: boolean | null;
      /**
       * The To First Found timestamp filter for fixed vulnerabilities
       */
      fixedVulnerabilitiesFrom?: Timestamp | null;
    };
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/export`,
      query: {
        orderBy: orderBy,
        filter: filter,
      },
      errors: {
        400: `An error message when exporting cmdb data`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerabilities count by status
   * Vulnerabilities count by status
   * @returns VulnerabilitiesCountByStatusResponse Vulnerabilities count by status
   * @throws ApiError
   */
  public static async getVulnerabilitiesCountByStatus({
    customerId,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Tags list **/
    tagIds?: Array<string>;
  }): Promise<VulnerabilitiesCountByStatusResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/count-by-status`,
      query: {
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when getting cmdb vulnerabilities by severity level`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Patch and configuration vulnerabilities count
   * Patch and configuration vulnerabilities count
   * @returns any Patch and configuration vulnerabilities count
   * @throws ApiError
   */
  public static async getPatchVsConfiguration({
    customerId,
    vulnerabilityStatus,
    tagIds,
    includeSuppressedVulnerabilities,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Vulnerability statuses parameter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Tags list **/
    tagIds?: Array<string>;
    /** Include suppressed vulnerabilities in response **/
    includeSuppressedVulnerabilities?: boolean;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability-management/patch-vs-configuration`,
      query: {
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
        includeSuppressedVulnerabilities: includeSuppressedVulnerabilities,
      },
      errors: {
        400: `An error message when get patch and configuration`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerability Grid
   * Returns list of vulnerabilities
   * @returns VulnerabilitiesResponse List of vulnerability
   * @throws ApiError
   */
  public static async getVulnerabilitiesByIds({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    requestBody?: getVulnerabilitiesByIdsRequest;
  }): Promise<VulnerabilitiesResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/vulnerabilities-by-ids`,
      body: requestBody,
      errors: {
        400: `An error message when get vulnerabilities list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerability Details
   * Returns suppression history rows of the vulnerability
   * @returns SuppressionGridResponse get vulnerability suppress grid
   * @throws ApiError
   */
  public static async getVulnerabilitySuppressionGrid({
    customerId,
    vulnerabilityId,
    pagination,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** vulnerability id **/
    vulnerabilityId: CustomerId;
    /** Pagination parameter **/
    pagination: Pagination;
  }): Promise<SuppressionGridResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/vulnerability/${vulnerabilityId}/suppression-grid`,
      query: {
        pagination: pagination,
      },
      errors: {
        400: `An error message when get vulnerability suppression`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message not found`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerability Details
   * Returns suppression data of the vulnerability
   * @returns Suppression updated vulnerability suppression data
   * @throws ApiError
   */
  public static async updateVulnerabilitySuppression({
    customerId,
    vulnerabilityId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** vulnerability id **/
    vulnerabilityId: CustomerId;
    requestBody?: UpdateVulnerabilitySuppressionRequest;
  }): Promise<Suppression> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/vulnerability/${vulnerabilityId}/suppression`,
      body: requestBody,
      errors: {
        400: `An error message when update vulnerability suppression`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message not found`,
      },
    });
    return result.body;
  }

  /**
   * Vulnerability filter options
   * Returns a list of users who have suppressed vulnerabilities.
   * @returns SuppressVulnerabilitiesUsersResponse List of vulnerabilities categories
   * @throws ApiError
   */
  public static async getSuppressVulnerabilitiesUsers({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<SuppressVulnerabilitiesUsersResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/suppress-vulnerabilities-users`,
      errors: {
        400: `An error message when get vulnerabilities list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
