import React from 'react';
import { List, PageWrapper, SectionTitle } from './styled';

export function PdfPage12() {
  return (
    <PageWrapper>
      <SectionTitle>Anything for us?</SectionTitle>
      <List>
        <li>
          <span>*</span> Security Concerns
        </li>
        <li>
          <span>*</span> Feedback for the team
        </li>
        <li>
          <span>*</span> Infrastructure changes
        </li>
        <li>
          <span>*</span> Network Coverage
        </li>
        <li>
          <span>*</span> Service Requests
        </li>
        <li>
          <span>*</span> Contact information
        </li>
      </List>
    </PageWrapper>
  );
}
