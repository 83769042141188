import { TagProps } from 'antd';
import { UcFirstLetter } from 'shared';
import { useTheme } from 'styled-components';
import { StatusTag } from 'pages/ThreatDetection/components/MonitoredServices/styled';
import { RiskOptionLabel } from '../constants';

type TagColoringProps = TagProps & {
  title: string;
};

export const TagColoring = ({ title, ...TagProps }: TagColoringProps) => {
  const theme = useTheme();

  const tagType = (title: string) => {
    switch (title) {
      case RiskOptionLabel.Critical:
        return theme.colors.red[700];
      case RiskOptionLabel.High:
        return theme.colors.red[400];
      case RiskOptionLabel.Medium:
        return theme.colors.yellow[500];
      case RiskOptionLabel.Low:
        return theme.colors.green[300];
      default:
        return theme.colors.blue[100];
    }
  };

  return (
    <StatusTag color={tagType(title)} key={title} {...TagProps} title={title}>
      {UcFirstLetter(title)}
    </StatusTag>
  );
};
