import { useHistory } from 'react-router';

export const useSearchParams = () => {
  const history = useHistory();
  const paramsArray = [...new URLSearchParams(history.location.search)];

  return paramsArray.reduce((acc: Record<string, string>, [key, value]: [string, string]) => {
    return { ...acc, [key]: value };
  }, {});
};
