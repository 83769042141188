import { ColumnsType } from 'antd/es/table/interface';
import { renderValue } from 'pages/CmdbHostDetails/helpers';
import { HostNetworkInterfaceSchema } from 'services/api';
import { TableTitles } from './constants';

export const columns: ColumnsType<HostNetworkInterfaceSchema> = [
  {
    key: 'interface',
    title: TableTitles.Interface,
    dataIndex: 'interface',
    render: (value: string) => renderValue(value),
  },
  {
    key: 'address',
    title: TableTitles.Address,
    dataIndex: 'address',
    render: (value: string) => renderValue(value),
  },
  {
    key: 'gateway',
    title: TableTitles.Gateway,
    dataIndex: 'gateway',
    render: (value: string) => renderValue(value),
  },
  {
    key: 'mac',
    title: TableTitles.Mac,
    dataIndex: 'mac',
    align: 'left',
    render: (value: string) => renderValue(value),
  },
];
