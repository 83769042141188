import { colors, fontWeights, spacing } from 'styles/theme';
import { leftMenu as leftMenuNorm, login as loginNorm } from './Norm';
import * as buttonsNorm from './Norm/buttons';
import * as buttonsDarkNorm from './DarkNorm/buttons';
import { leftMenu as leftMenuDark, login as loginDarkNorm } from './DarkNorm';

import { leftMenu as leftMenuNasstar, login as loginNasstar } from './Nasstar';
import * as buttonsNasstar from './Nasstar/buttons';
import { severity as normSeverity } from './Norm/severity';
import { severity as nasstarSeverity } from './Nasstar/severity';
import { mainFontFamily as nasstarFontFamily } from './Nasstar/fontsNasstar';
import { mainFontFamily as normFontFamily } from './Norm/fontsNorm';
import { process as processNasstar } from './Nasstar/process';
import { process as processNorm } from './Norm/process';
import { process as processDark } from './DarkNorm/process';

import { pieChart as pieChartNasstar } from './Nasstar/pieChart';
import { pieChart as pieChartNorm } from './Norm/pieChart';
import { nasstarScoreGradient } from './Nasstar/scoreGradient';
import { normScoreGradient } from './Norm/scoreGradient';
import { users as usersNasstar } from './Nasstar/users';
import { users as usersNorm } from './Norm/users';
import { vulnerabilityStatus as normVulnerabilityStatus } from './Norm/vulnerabilityStatus';
import { vulnerabilityStatus as nasstarVulnerabilityStatus } from './Nasstar/vulnerabilityStatus';
import { activeEDR } from './Norm/activeEDR';

export const nasstar = {
  primaryColor: '#2a26f9',
  secondaryColor: '#e03b92',
  successColor: '#43a047',
  errorColor: '#eb4a70',
  errorSecondaryColor: '#9c233f',
  bodyBackground: '#eeecec',
  lessFontFamily: `-apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
  codeFamily: `'Segoe UI', Consolas, 'Liberation Mono', Menlo, Courier, monospace;`,
  textColor: colors.black,
  headingColor: colors.black,
  criticality: colors.white,
  certificate: {
    tagColor: colors.gray[800],
  },
  sidebar: {
    bg: colors.black,
  },
  appWrapper: {
    bg: colors.gray[100],
  },
  charts: {
    emptyChart: '#EEEDE8',
    tooltip: {
      color: '#292933',
      bg: colors.white,
    },
    axisLabel: '#6E7079',
    culture: {
      lineColor: colors.blue[50],
      areaStyle: {
        internalColor: colors.white,
        externalColor: colors.blue[50],
      },
    },
  },
  card: {
    bg: colors.white,
    border: colors.gray[120],
    boxShadow: colors.blackAlpha[100],
    footerBorderTopColor: colors.gray[70],
    headBorderColor: colors.gray[100],
    headAccessMessageBG: colors.gray[100],
    headUnderTitleColor: colors.blue[900],
  },
  popup: { titleColor: colors.blue[900] },
  alert: {
    errorBg: colors.red[100],
    errorBorderColor: colors.red[100],
    errorColor: colors.red[700],
    messageColor: colors.white,
    iconColor: colors.red[400],
    linkColor: colors.brown[400],
  },
  blur: {
    bg: colors.blackAlpha[600],
  },
  divider: {
    color: colors.gray[80],
  },
  notificationIcon: {
    bg: colors.gray[100],
    bgMobile: colors.gray[300],
  },
  pulsing: {
    boxShadow: `0 0 0 0 rgba(232, 76, 61, 0.7)`,
    bg: colors.red[700],
  },
  readButton: {
    color: '#2a26f9',
  },
  notificationWrapper: {
    closeIcon: '#00000073',
    bg: '#eeede8',
    borderColor: colors.gray[70],
    errorColor: '#eb4a70',
  },
  overlay: {
    open: 'rgba(0, 0, 0, 0.5)',
    close: 'rgba(0, 0, 0, 0)',
  },
  tagAssets: {
    bg: '#EEEDE8',
    borderColor: '#2a26f9',
    limit: {
      bg: '#f9e0b0',
      color: '#72551c',
    },
  },

  fontFamily: nasstarFontFamily,
  theme: 'nasstar',
  primary: colors.blue[550],
  arrow: colors.pink[500],
  disabled: colors.gray[150],
  unActiveArrow: colors.gray[150],
  filterMobile: colors.black,
  resetFilterIcon: colors.yellow[460],
  filterTitle: colors.black,
  filterMobileTitle: colors.white,
  filterLabel: colors.white,
  filterLabelWeight: fontWeights.semibold,
  filterIconClose: colors.gray[150],
  filterSearchInput: colors.gray[110],
  filterSortPopup: colors.gray[80],
  filterSortPopupNotActive: colors.white,

  gradientPieContainer: {
    bg: colors.white,
    border: colors.gray[200],
    boxShadow: colors.blackAlpha[100],
    iconDropShadow: colors.black,
    iconDropShadowSecond: colors.blackAlpha[200],
  },

  spinContainer: {
    bg: colors.whiteAlpha[600],
  },

  lightTextContainer: { color: colors.blue[900] },

  link: {
    fontWeight: fontWeights.semibold,
    disabledColor: colors.brown[100],
    bg: '#babaab',
    color: '#2a26f9',
  },

  mainMenuDrawer: {
    bg: colors.gray[700],
  },

  errorOnDark: '#FF7092',

  selectChecked: colors.gray[80],

  scoreGradient: nasstarScoreGradient,

  colorHigh: colors.red[450],
  colorCritical: colors.purple[550],

  accentTableColumn: fontWeights.semibold,

  done: colors.green[320],

  success: colors.green[700],
  medium: colors.yellow[460],
  closed: colors.gray[150],
  activeGreen: colors.green[700],
  draft: colors.brown[100],
  processing: colors.blue[100],
  default: colors.gray[300],
  tagColor: colors.white,

  subTitleIconCritical: colors.purple[550],
  subTitleIconHigh: colors.red[450],
  subTitleIconMedium: colors.yellow[460],

  scrollBar: colors.black,

  radio: {
    inner: {
      bg: colors.white,
      borderColor: colors.brown[100],
    },
    disabled: {
      borderColor: '#babaab80',
    },
    main: colors.pink[500],
    secondary: colors.brown[100],
    border: colors.gray[150],
    color: colors.white,
    wrapperBg: '#eeede87F',
  },

  tooltipColor: colors.gray[150],
  tooltip: {
    hover: colors.pink[500],
    background: colors.black,
  },

  tooltipInnerColor: colors.white,
  tooltipBg: colors.black,

  dangerTriangle: colors.pink[100],

  severity: nasstarSeverity,
  activeEDR,
  tabsNavigation: {
    activeTabBg: colors.white,
    activeTabColor: colors.brown[400],
    inactiveTabColor: colors.black,
    inactiveTabHoverColor: colors.brown[400],
  },
  vulnStatus: nasstarVulnerabilityStatus,
  vulnSuppression: {
    suppressed: {
      bg: colors.brown[200],
      color: colors.white,
      borderColor: colors.brown[200],
    },
    unsuppressed: {
      bg: colors.white,
      color: colors.black,
      borderColor: colors.brown[200],
    },
  },

  tableHeader: colors.gray[110],

  pagination: {
    paddingBottom: '2px',
    main: colors.pink[500],
    page: {
      bg: colors.white,
      borderColor: '#EEEDE8',
    },
    textFontWeight: fontWeights.semibold,
    secondary: colors.gray[100],
    color: colors.black,
    activeColor: colors.white,
    borderColor: colors.gray[200],
    boxShadow: `0 2px 4px rgba(0, 0, 0, 0.15)`,
  },
  input: {
    autofillTextColor: colors.white,
    errorBorder: colors.red[450],
    errorMessage: colors.red[650],
    datePicker: 'rgb(224, 59, 146)',
    filterBorder: colors.black,
    iconColor: colors.gray[300],
    labelColor: colors.blue[900],
    zeroStrengthColor: colors.gray[100],
    bg: colors.white,
    borderColor: colors.gray[80],
    color: colors.yellow[100],
    focusBorderColor: colors.gray[200],
    hoverBorderColor: colors.gray[200],
    placeholderColor: '#babaab',
    passwordBg: colors.transparent,
    search: {
      borderColor: colors.red[400],
      color: colors.red[700],
    },
    disable: {
      bg: '#f5f5f5',
      color: '#00000040',
      borderColor: '#eeede8',
    },
  },

  table: {
    expandIcon: colors.black,
    borderBottom: '#e0e3e8',
    th: {
      color: colors.gray[400],
      iconColor: colors.pink[500],
    },
    tr: {
      hover: {
        bg: '#e0e3e833',
      },
      expanded: {
        bg: '#d7d7cf',
      },
      emptyBg: colors.white,
      emptyColor: '#29292980',
      bg: colors.gray[300],
      rowBg: colors.blueAlpha[200],
      rowDarkBg: colors.gray[100],
    },
    tableItem: {
      color: colors.blue[900],
      borderColor: colors.blue[50],
    },
    filters: {
      moreFilers: {
        bg: colors.red[300],
      },
      hiddenFilterIcon: colors.red[700],
    },
  },

  bulkActionButtons: {
    deleteColor: colors.white,
    manageColor: colors.black,
  },

  formItemLabelColor: colors.red[700],

  datePicker: {
    checked: {
      bg: '#e03b92',
      borderColor: '#e03b92',
    },
    bg: colors.white,
    textColor: colors.black,
    iconColor: '#00000040',
    borderColor: colors.gray[100],
    hoverBorderColor: colors.gray[300],
    color: colors.green[200],
    pickerPanelBg: colors.gray[100],
    rangeHoverBorderColor: '#e03b92',
    innerBg: '#f0efef',
    cellRangeBg: '#f5f5f5',
    disabledRangeBG: '#f5f5f5',
    clearIconColor: 'rgb(166, 158, 153)',
  },

  checkBox: {
    indeterminate: {
      bg: colors.white,
      borderColor: colors.gray[100],
    },
    borderColor: colors.brown[100],
    borderColorAfter: colors.white,
    bg: colors.brown[100],
  },

  select: {
    borderColor: colors.gray[80],
    bg: colors.white,
    hoverBg: '#babaab1f',
    selected: {
      bg: '#eeecec',
    },
    active: {
      bg: 'rgba(186, 186, 171, 0.12)',
    },
    checked: {
      borderColor: colors.black,
      bg: colors.gray[80],
    },
    multiple: {
      borderColor: colors.gray[200],
      bg: colors.gray[300],
      remove: {
        color: colors.gray[500],
        focusColor: colors.gray[700],
      },
    },
    iconColor: '#A69E99',
    cascader: {
      bg: colors.white,
      bgHover: '#babaab1f',
    },
  },

  wysiwyg: {
    placeholderColor: colors.brown[100],
    bg: colors.white,
    view: {
      table: {
        borderColor: colors.gray[70],
        cellPadding: '5px 12px',
      },
    },
  },

  button: buttonsNasstar.button,
  buttonSecondary: buttonsNasstar.buttonSecondary,
  buttonDisabled: buttonsNasstar.buttonDisabled,
  buttonDanger: buttonsNasstar.buttonDanger,
  buttonSimple: buttonsNasstar.buttonSimple,
  buttonPrimaryOrDangerDisabled: buttonsNasstar.buttonPrimaryOrDangerDisabled,
  buttonShowMoreColor: colors.brown[500],

  menu: {
    hoverBg: '#f5f5f5',
    item: {
      color: colors.white,
      selected: {
        color: '#e03b92',
        bg: colors.gray[810],
      },
    },
    adminTitle: {
      desktop: colors.gray[410],
      mobile: '#a69e9980',
    },
    submenu: {
      selectedColor: colors.white,
      bg: colors.blue[910],
      hoverBg: colors.blue[910],
    },
    bg: 'black',
    sidebar: {
      bg: colors.black,
      tooltipBg: 'rgba(41,41,51,.8)',
    },
    boxShadow: `0 0 100px rgb(255 169 0 / 50%)`,
    browserDarkTheme: {
      color: '#fff',
      inlineSubmenuBg: colors.blue[910],
      highlightColor: '#e03b92',
      itemActiveBg: colors.blue[910],
      selectedItemIconColor: '#e03b92',
      selectedItemTextColor: '#e03b92',
      itemHoverBg: 'transparent',
    },
  },

  layout: {
    body: {
      bg: '#eeecec',
    },
    header: {
      bg: colors.white,
    },
    modal: {
      bg: colors.white,
    },
    bg: colors.white,
    titleColor: `rgba(166, 158, 153, 50%)`,
    boxShadow: `0 0 100px rgb(255 169 0 / 50%)`,
  },

  snackBar: {
    bg: colors.white,
  },

  iconMenu: {
    color: colors.blue[900],
    sidebarColor: colors.blue[900],
  },

  menuLink: {
    color: colors.gray[300],
    hoverColor: '#CDB689',
  },

  login: loginNasstar,

  leftMenu: leftMenuNasstar,

  process: processNasstar,

  pieChart: pieChartNasstar,

  securitySettingsWeight: 600,

  users: usersNasstar,

  notFoundBackImg: '',

  notFoundWrapperBackground: colors.black,

  notFoundTextColor: colors.white,

  notFoundBackLinkColor: colors.yellow[460],

  notFoundBorder: colors.gray[120],

  notFoundFontWeight: fontWeights.semibold,
  notFoundFontSize: spacing['3.6'],
  notFoundlineHeight: spacing[5],

  backFontWeight: fontWeights.semibold,

  twoFAText: fontWeights.semibold,

  alertAction: colors.gray[810],
  alertActionViewMore: colors.yellow[460],

  dashboardDigits: '26px',
  dashboardDigitsWeight: fontWeights.bold,

  titlePageWeight: fontWeights.bold,
  titlePageSize: '26px',
  titlePageColor: colors.black,

  iconCheck: colors.green[320],

  labelsTextTransform: 'uppercase',

  labelMediumColorText: colors.black,
  labelFontWeight: fontWeights.semibold,
  segmented: {
    item: {
      bg: colors.white,
      selected: colors.black,
    },
    bg: '#0000000f',
  },
  modal: {
    closeIcon: '#C2BAAA',
    borderColor: '#f0f0f0',
  },
  tabs: {
    borderBottomColor: '#f0f0f0',
    card: {
      active: {
        color: '#e03b92',
      },
    },
    ink: {
      bar: {
        color: '#e03b92',
      },
    },
    highlight: {
      color: '#e03b92',
    },
    hover: {
      color: '#e03b92',
    },
    active: {
      color: '#e03b92',
    },
  },
  picker: {
    dateHoverRangeBorderColor: 'lighten(#e03b92, 20)',
    basic: {
      cell: {
        hover: {
          withRangeColor: '#e3e3dc',
        },
        active: {
          withRangeColor: '#f0efef',
        },
      },
    },
  },
  scrollbar: { bg: colors.gray[100] },
  switch: {
    color: colors.gray[150],
    sm: {
      minWidth: '16px',
      height: '17px',
    },
  },
  stepper: {
    borderColor: colors.gray[120],
    backgroundColor: colors.white,
    activeBorderColor: '#e03b92',
    activeBackgroundColor: '#f0f0f0',
  },
  dull: {
    backgroundColor: 'transparent',
    mobileBackgroundColor: 'transparent',
    imageColor: 'transparent',
    mobileImageColor: 'transparent',
  },
};

export const norm = {
  primaryColor: colors.brown[500],
  secondaryColor: '#d7d7cf',
  errorColor: colors.red[400],
  errorSecondaryColor: colors.red[700],
  successColor: '#43a047',
  bodyBackground: '#eeede8',
  lessFontFamily: `-apple-system, 'Helvetica Now Display', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
`,
  textColor: '#292933',
  codeFamily: `'Helvetica Now Display', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
`,
  criticality: colors.white,

  certificate: {
    tagColor: colors.gray[800],
  },
  appWrapper: {
    bg: colors.gray[100],
  },
  charts: {
    emptyChart: '#EEEDE8',
    tooltip: {
      color: '#292933',
      bg: colors.white,
    },
    axisLabel: '#6E7079',
    culture: {
      lineColor: colors.blue[50],
      areaStyle: {
        internalColor: colors.white,
        externalColor: colors.blue[50],
      },
    },
  },
  card: {
    border: colors.gray[200],
    bg: colors.white,
    boxShadow: colors.blackAlpha[100],
    footerBorderTopColor: colors.gray[70],
    headBorderColor: colors.gray[100],
    headAccessMessageBG: colors.gray[100],
    headUnderTitleColor: colors.blue[900],
  },
  popup: { titleColor: colors.blue[900] },
  alert: {
    errorBg: colors.red[100],
    errorBorderColor: colors.red[100],
    errorColor: colors.red[400],
    messageColor: colors.white,
    iconColor: colors.red[400],
    linkColor: colors.brown[400],
  },
  blur: {
    bg: colors.blackAlpha[600],
  },
  divider: {
    color: colors.gray[70],
  },
  notificationIcon: {
    bg: colors.gray[100],
    bgMobile: colors.gray[300],
  },
  pulsing: {
    boxShadow: `0 0 0 0 rgba(232, 76, 61, 0.7)`,
    bg: colors.red[700],
  },
  readButton: {
    color: colors.brown[500],
  },
  notificationWrapper: {
    closeIcon: '#00000073',
    bg: '#eeede8',
    borderColor: colors.gray[70],
    errorColor: '#ff4d4f',
  },
  overlay: {
    open: 'rgba(0, 0, 0, 0.5)',
    close: 'rgba(0, 0, 0, 0)',
  },
  tagAssets: {
    bg: '#EEEDE8',
    borderColor: 'rgba(186, 186, 171, 0.5)',
    limit: {
      bg: '#f9e0b0',
      color: '#72551c',
    },
  },

  fontFamily: normFontFamily,
  theme: 'norm',
  primary: colors.brown[500],
  arrow: colors.brown[500],
  disabled: colors.brown[100],
  unActiveArrow: colors.brown[100],
  filterMobile: colors.gray[300],
  resetFilterIcon: colors.brown[500],
  filterTitle: colors.blue[900],
  filterMobileTitle: colors.blue[900],
  filterIconClose: colors.blue[900],
  filterLabel: colors.blue[900],
  filterLabelWeight: fontWeights.medium,
  filterSearchInput: colors.gray[300],
  filterSortPopup: colors.gray[100],
  filterSortPopupNotActive: colors.white,

  gradientPieContainer: {
    bg: colors.white,
    border: colors.gray[200],
    boxShadow: colors.blackAlpha[100],
    iconDropShadow: colors.black,
    iconDropShadowSecond: colors.blackAlpha[200],
  },

  spinContainer: {
    bg: colors.whiteAlpha[600],
  },

  lightTextContainer: { color: colors.blue[900] },

  link: {
    fontWeight: fontWeights.bold,
    disabledColor: colors.brown[100],
    bg: '#babaab',
    color: colors.brown[500],
  },

  mainMenuDrawer: {
    bg: colors.gray[700],
  },

  errorOnDark: colors.red[500],

  colorHigh: colors.red[400],
  colorCritical: colors.red[700],

  accentTableColumn: fontWeights.medium,

  done: colors.brown[500],

  success: colors.green[300],
  medium: colors.yellow[500],
  closed: colors.green[200],
  activeGreen: colors.green[500],
  draft: colors.brown[100],
  processing: colors.blue[100],
  default: colors.gray[300],
  tagColor: colors.white,

  selectChecked: colors.gray[100],

  scoreGradient: normScoreGradient,

  dangerTriangle: colors.red[400],

  subTitleIconCritical: colors.red[700],
  subTitleIconHigh: colors.red[400],
  subTitleIconMedium: colors.yellow[500],

  scrollBar: colors.brown[400],

  tooltipColor: colors.green[200],
  tooltip: {
    hover: colors.brown[500],
    bg: colors.blue[900],
  },

  tooltipInnerColor: colors.white,
  tooltipBg: 'rgba(41,41,51,.8)',

  severity: normSeverity,
  activeEDR,
  tabsNavigation: {
    activeTabBg: colors.white,
    activeTabColor: colors.brown[400],
    inactiveTabColor: colors.black,
    inactiveTabHoverColor: colors.brown[400],
  },
  vulnStatus: normVulnerabilityStatus,
  vulnSuppression: {
    suppressed: {
      bg: colors.brown[200],
      color: colors.white,
      borderColor: colors.brown[200],
    },
    unsuppressed: {
      bg: colors.white,
      color: colors.black,
      borderColor: colors.brown[200],
    },
  },

  tableHeader: colors.gray[300],

  radio: {
    inner: {
      bg: colors.white,
      borderColor: colors.brown[100],
    },
    disabled: {
      borderColor: '#babaab80',
    },
    main: colors.brown[500],
    secondary: colors.brown[100],
    border: colors.gray[200],
    color: colors.white,
    wrapperBg: '#eeede87F',
  },
  pagination: {
    paddingBottom: 0,
    main: colors.brown[500],
    page: {
      bg: colors.white,
      borderColor: '#EEEDE8',
    },
    secondary: colors.gray[100],
    color: colors.white,
    textFontWeight: fontWeights.medium,
    borderColor: colors.gray[200],
    activeColor: colors.white,
    boxShadow: `0 2px 4px rgba(0, 0, 0, 0.15)`,
  },

  input: {
    autofillTextColor: colors.white,
    errorBorder: colors.red[400],
    errorMessage: colors.red[700],
    datePicker: colors.brown[500],
    filterBorder: colors.brown[500],
    iconColor: colors.gray[300],
    labelColor: colors.blue[900],
    zeroStrengthColor: colors.gray[100],
    bg: colors.white,
    borderColor: colors.gray[100],
    color: colors.yellow[100],
    focusBorderColor: colors.gray[200],
    passwordBg: colors.transparent,
    hoverBorderColor: colors.gray[200],
    search: {
      borderColor: colors.red[400],
      color: colors.red[700],
    },
    placeholderColor: '#babaab',
    disable: {
      bg: '#f5f5f5',
      color: '#00000040',
      borderColor: '#EEEDE8',
    },
  },

  table: {
    borderBottom: colors.gray[300],
    expandIcon: colors.black,
    th: {
      color: colors.gray[400],
      iconColor: colors.brown[500],
    },
    tr: {
      hover: {
        bg: '#e0e3e833',
      },
      expanded: {
        bg: '#d7d7cf',
      },
      emptyBg: colors.white,
      emptyColor: '#29292980',
      bg: colors.gray[300],
      rowBg: '#e0e3e833',
      rowDarkBg: colors.gray[100],
    },
    tableItem: {
      color: colors.blue[900],
      borderColor: colors.gray[70],
    },
    filters: {
      moreFilers: {
        bg: colors.red[300],
      },
      hiddenFilterIcon: colors.red[700],
    },
  },

  bulkActionButtons: {
    deleteColor: colors.white,
    manageColor: colors.black,
  },

  formItemLabelColor: colors.red[700],

  datePicker: {
    checked: {
      bg: colors.brown[500],
      borderColor: colors.brown[500],
    },
    bg: colors.white,
    textColor: colors.black,
    iconColor: '#00000040',
    borderColor: colors.gray[100],
    hoverBorderColor: colors.gray[200],
    rangeHoverBorderColor: colors.brown[500],
    color: colors.green[200],
    pickerPanelBg: colors.gray[100],
    innerBg: '#c7c5bb',
    cellRangeBg: '#f5f5f5',
    disabledRangeBG: '#f5f5f5',
    clearIconColor: 'rgb(166, 158, 153)',
  },

  checkBox: {
    indeterminate: {
      bg: colors.white,
      borderColor: colors.gray[100],
    },
    borderColor: colors.brown[100],
    borderColorAfter: colors.white,
    bg: colors.brown[100],
  },

  select: {
    borderColor: colors.gray[100],
    hoverBg: '#babaab1f',
    bg: colors.white,
    selected: {
      bg: '#eeede8',
    },
    active: {
      bg: '#eeede8',
    },
    checked: {
      borderColor: colors.brown[500],
      bg: colors.gray[100],
    },
    multiple: {
      borderColor: colors.yellow[100],
      bg: colors.gray[300],
      remove: {
        color: colors.gray[500],
        focusColor: colors.gray[700],
      },
    },
    iconColor: '#A69E99',
    cascader: {
      bg: colors.white,
      bgHover: '#babaab1f',
    },
  },

  wysiwyg: {
    placeholderColor: colors.brown[100],
    bg: colors.white,
    view: {
      table: {
        borderColor: colors.gray[70],
        cellPadding: '5px 12px',
      },
    },
  },

  button: buttonsNorm.button,
  buttonSecondary: buttonsNorm.buttonSecondary,
  buttonDisabled: buttonsNorm.buttonDisabled,
  buttonDanger: buttonsNorm.buttonDanger,
  buttonSimple: buttonsNorm.buttonSimple,
  buttonPrimaryOrDangerDisabled: buttonsNasstar.buttonPrimaryOrDangerDisabled,
  buttonShowMoreColor: colors.brown[500],

  scrollbar: { bg: colors.gray[100] },
  switch: { color: colors.brown[100] },
  sidebar: {
    bg: '#494b42',
    tooltipBg: 'rgba(41,41,51,.8)',
  },
  menu: {
    hoverBg: '#f5f5f5',
    item: {
      color: colors.gray[300],
      selected: {
        color: colors.gray[300],
        bg: colors.gray[800],
      },
    },
    adminTitle: {
      desktop: '#ffffff80',
      mobile: '#a69e9980',
    },
    submenu: {
      selectedColor: 'fff',
      bg: '#42443c',
      hoverBg: colors.gray[800],
    },
    body: {
      bg: '#eeede8',
    },
    sidebar: {
      bg: '#494b42',
    },
    boxShadow: `0 0 100px rgb(255 169 0 / 50%)`,
    browserDarkTheme: {
      color: '#d7d7cf',
      inlineSubmenuBg: '#42443c',
      highlightColor: '#d7d7cf',
      itemActiveBg: '#34372b',
      selectedItemIconColor: '#a69e99',
      selectedItemTextColor: '#d7d7cf',
      itemHoverBg: '#34372b',
    },
  },

  layout: {
    header: { bg: colors.white },
    body: {
      bg: '#eeede8',
    },
    modal: {
      bg: colors.white,
    },
    titleColor: `rgba(166, 158, 153, 50%)`,
    boxShadow: `0 0 100px rgb(255 169 0 / 50%)`,
  },

  snackBar: {
    bg: colors.white,
  },

  iconMenu: {
    color: colors.blue[900],
    sidebarColor: colors.blue[900],
  },

  menuLink: {
    color: colors.gray[300],
    hoverColor: '#CDB689',
  },

  login: loginNorm,

  leftMenu: leftMenuNorm,

  process: processNorm,

  pieChart: pieChartNorm,

  securitySettingsWeight: 500,

  users: usersNorm,

  notFoundBackImg: '/assets/images/notFoundBackNorm.jpg',

  notFoundWrapperBackground: colors.gray[60],

  notFoundTextColor: colors.blue[900],
  notFoundBackLinkColor: colors.brown[500],

  notFoundBorder: colors.yellow[100],

  notFoundFontWeight: fontWeights.normal,
  notFoundFontSize: spacing[4],
  notFoundlineHeight: spacing[6],

  backFontWeight: fontWeights.bold,

  twoFAText: fontWeights.normal,

  alertAction: colors.gray[800],
  alertActionViewMore: colors.yellow[400],

  dashboardDigits: '24px',
  dashboardDigitsWeight: fontWeights.black,

  titlePageWeight: fontWeights.black,
  titlePageSize: '24px',
  titlePageColor: colors.blue[900],

  iconCheck: colors.brown[500],

  labelsTextTransform: 'none',

  labelMediumColorText: colors.white,
  labelFontWeight: fontWeights.bold,
  modal: {
    closeIcon: '#C2BAAA',
    borderColor: '#E0E3E8',
  },
  segmented: {
    item: {
      bg: colors.white,
      selected: colors.black,
    },
    bg: '#0000000f',
  },
  tabs: {
    borderBottomColor: '#f0f0f0',
    card: {
      active: {
        color: colors.brown[500],
      },
    },
    ink: {
      bar: {
        color: colors.brown[500],
      },
    },
    highlight: {
      color: colors.brown[500],
    },
    hover: {
      color: colors.brown[500],
    },
    active: {
      color: colors.brown[500],
    },
  },
  stepper: {
    borderColor: colors.red[200],
    backgroundColor: colors.gray[200],
    activeBorderColor: colors.brown[500],
    activeBackgroundColor: colors.orange[200],
  },
  dull: {
    backgroundColor: colors.gray[800],
    mobileBackgroundColor: colors.gray[700],
    imageColor: colors.gray[700],
    mobileImageColor: '#42443C',
  },
};

export const darkNorm = {
  primaryColor: colors.brown[400],
  secondaryColor: '#d7d7cf',
  errorColor: '#EB644A',
  errorSecondaryColor: '#9c2b23',
  successColor: '#43a047',
  bodyBackground: '#161716',
  lessFontFamily: `-apple-system, 'Helvetica Now Display', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
`,
  textColor: colors.white,
  codeFamily: `'Helvetica Now Display', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
`,
  criticality: colors.white,
  certificate: {
    tagColor: colors.white,
  },
  appWrapper: {
    bg: '#161716',
  },
  layout: {
    body: {
      bg: '#161716',
    },
    header: {
      bg: '#1F201F',
    },
    modal: {
      bg: '#1F201F',
    },
    bg: colors.white,
    titleColor: `rgba(166, 158, 153, 50%)`,
    boxShadow: `0 0 100px rgb(255 169 0 / 50%)`,
  },
  snackBar: {
    bg: '#242524',
  },
  card: {
    bg: '#1F201F',
    border: '#323332',
    boxShadow: colors.blackAlpha[100],
    footerBorderTopColor: '#323332',
    headBorderColor: '#323332',
    headAccessMessageBG: '#1F201F',
    headUnderTitleColor: colors.blue[900],
  },
  charts: {
    emptyChart: '#242524',
    tooltip: {
      color: colors.white,
      bg: '#323332',
    },
    axisLabel: colors.white,
    culture: {
      lineColor: '#E0E3E8',
      areaStyle: {
        internalColor: colors.white,
        externalColor: '#1F201F',
      },
    },
  },
  popup: { titleColor: colors.blue[900] },
  alert: {
    errorBg: '#eb644a4d',
    errorBorderColor: '#ffffff0f',
    errorColor: colors.red[500],
    messageColor: colors.white,
    iconColor: colors.red[400],
    linkColor: colors.brown[400],
  },
  blur: {
    bg: colors.blackAlpha[600],
  },
  divider: {
    color: '#323332',
  },
  notificationIcon: {
    bg: '#323332',
    bgMobile: '#323332',
  },
  pulsing: {
    boxShadow: `0 0 0 0 rgba(232, 76, 61, 0.7)`,
    bg: colors.red[700],
  },
  readButton: {
    color: colors.brown[400],
  },
  notificationWrapper: {
    closeIcon: '#C2BAAA',
    bg: '#242524',
    borderColor: '#323332',
    errorColor: '#ff4d4f',
  },
  overlay: {
    open: 'rgba(0, 0, 0, 0.5)',
    close: 'rgba(0, 0, 0, 0)',
  },

  tagAssets: {
    bg: '#292A29',
    borderColor: '#494b42',
    limit: {
      bg: 'rgba(241, 174, 39, 0.3)',
      color: 'rgba(205, 182, 137, 1)',
    },
  },
  fontFamily: normFontFamily,
  theme: 'darkNorm',
  primary: colors.brown[400],
  arrow: colors.brown[400],
  disabled: colors.brown[100],
  unActiveArrow: colors.brown[100],
  filterMobile: '#292A29',
  resetFilterIcon: colors.brown[400],
  filterTitle: colors.blue[900],
  filterMobileTitle: colors.white,
  filterIconClose: colors.white,
  filterLabel: colors.blue[900],
  filterLabelWeight: fontWeights.medium,
  filterSearchInput: '#1F201F',
  filterSortPopup: '#292A29',
  filterSortPopupNotActive: '#1F201F',

  gradientPieContainer: {
    bg: '#1F201F',
    border: '#323332',
    boxShadow: colors.blackAlpha[100],
    iconDropShadow: colors.black,
    iconDropShadowSecond: colors.blackAlpha[200],
  },

  spinContainer: {
    bg: 'rgba(41, 42, 41, 0.6)',
  },

  lightTextContainer: { color: colors.blue[900] },

  link: {
    fontWeight: fontWeights.bold,
    disabledColor: colors.brown[100],
    bg: '#babaab',
    color: colors.brown[400],
  },

  mainMenuDrawer: {
    bg: '#1F201F',
  },

  errorOnDark: colors.red[500],

  colorHigh: colors.red[400],
  colorCritical: '#FF8770',

  accentTableColumn: fontWeights.medium,

  done: colors.brown[400],

  success: colors.green[300],
  medium: colors.yellow[500],
  closed: colors.green[200],
  activeGreen: colors.green[500],
  draft: colors.brown[100],
  processing: colors.blue[100],
  default: colors.gray[300],
  tagColor: colors.black,

  selectChecked: 'rgba(50, 51, 50, 1)',

  scoreGradient: normScoreGradient,

  dangerTriangle: colors.red[400],

  subTitleIconCritical: colors.red[700],
  subTitleIconHigh: colors.red[400],
  subTitleIconMedium: colors.yellow[500],

  scrollBar: colors.brown[400],

  tooltipColor: '#5F6159',
  tooltip: {
    hover: '#6A6B64',
    bg: '#5F6159',
  },
  tooltipInnerColor: '#161716',
  tooltipBg: 'rgba(41,41,51,.8)',

  severity: normSeverity,
  activeEDR,
  tabsNavigation: {
    activeTabBg: '#323332',
    activeTabColor: colors.brown[400],
    inactiveTabColor: colors.white,
    inactiveTabHoverColor: colors.brown[400],
  },
  vulnStatus: normVulnerabilityStatus,
  vulnSuppression: {
    suppressed: {
      bg: colors.brown[200],
      color: colors.white,
      borderColor: colors.brown[200],
    },
    unsuppressed: {
      bg: colors.black,
      color: colors.white,
      borderColor: colors.brown[200],
    },
  },

  tableHeader: '#1B1C1B',

  radio: {
    inner: {
      bg: '#323332',
      borderColor: '#494B42',
    },
    disabled: {
      borderColor: '#292A29',
    },
    main: colors.brown[400],
    secondary: '#494B42',
    border: '#494B42',
    color: colors.white,
    wrapperBg: '#323332',
  },

  pagination: {
    paddingBottom: 0,
    main: colors.brown[400],
    page: {
      bg: '1B1C1B',
      borderColor: '#323332',
    },
    secondary: '#242524',
    color: colors.white,
    textFontWeight: fontWeights.medium,
    borderColor: '#323332',
    activeColor: colors.black,
    boxShadow: `0 2px 4px rgba(0, 0, 0, 0.15)`,
  },

  input: {
    autofillTextColor: colors.white,
    errorBorder: colors.red[400],
    errorMessage: colors.red[700],
    datePicker: colors.brown[400],
    filterBorder: 'rgba(190, 161, 103, 1)',
    iconColor: colors.gray[300],
    labelColor: colors.white,
    zeroStrengthColor: colors.gray[100],
    bg: '#242524',
    borderColor: '#323332',
    color: colors.yellow[100],
    hoverBorderColor: '#494B42',
    focusBorderColor: '#494B42',
    placeholderColor: colors.white,
    passwordBg: colors.transparent,
    search: {
      borderColor: colors.red[400],
      color: colors.red[700],
    },
    disable: {
      bg: '#161716',
      color: '#6A6B64',
      borderColor: '#323332',
    },
  },

  table: {
    borderBottom: '#323332',
    expandIcon: colors.white,
    th: {
      color: colors.gray[400],
      iconColor: colors.brown[400],
    },
    tr: {
      hover: {
        bg: '#292A29',
      },
      expanded: {
        bg: '#242524',
      },
      emptyBg: '#1F201F',
      emptyColor: colors.white,
      bg: '#1F201F',
      rowBg: '#292A29',
      rowDarkBg: '#292A29',
    },
    tableItem: {
      color: colors.white,
      borderColor: '#323332',
    },
    filters: {
      moreFilers: {
        bg: colors.red[300],
      },
      hiddenFilterIcon: '#EB644A',
    },
  },

  bulkActionButtons: {
    deleteColor: colors.white,
    manageColor: colors.white,
  },

  formItemLabelColor: colors.red[700],

  datePicker: {
    checked: {
      bg: colors.brown[400],
      borderColor: colors.brown[400],
    },
    bg: '#242524',
    textColor: colors.white,
    iconColor: colors.white,
    borderColor: '#323332',
    hoverBorderColor: '#494B42',
    rangeHoverBorderColor: colors.brown[400],
    color: colors.white,
    pickerPanelBg: '#242524',
    innerBg: '#363736',
    cellRangeBg: '#292A29',
    disabledRangeBG: '#6A6B64',
    clearIconColor: 'rgb(166, 158, 153)',
  },

  checkBox: {
    indeterminate: {
      bg: '#323332',
      borderColor: '#494B42',
    },
    borderColor: '#494B42',
    borderColorAfter: colors.black,
    bg: '#323332',
  },

  select: {
    borderColor: '#323332',
    bg: '#242524',
    hoverBg: '#292A29',
    selected: {
      bg: '#242524',
    },
    active: {
      bg: '#242524',
    },
    checked: {
      borderColor: 'rgba(190, 161, 103, 1)',
      bg: '#242524',
    },
    multiple: {
      borderColor: '#494B42',
      bg: '#494B42',
      remove: {
        color: colors.white,
        focusColor: colors.white,
      },
    },
    iconColor: '#A69E99',
    cascader: {
      bg: '#242524',
      bgHover: '#323332',
    },
  },

  wysiwyg: {
    placeholderColor: colors.white,
    bg: '#242524',
    view: {
      table: {
        borderColor: colors.gray[70],
        cellPadding: '5px 12px',
      },
    },
  },

  button: buttonsDarkNorm.button,
  buttonSecondary: buttonsDarkNorm.buttonSecondary,
  buttonDisabled: buttonsDarkNorm.buttonDisabled,
  buttonDanger: buttonsDarkNorm.buttonDanger,
  buttonSimple: buttonsDarkNorm.buttonSimple,
  buttonPrimaryOrDangerDisabled: buttonsDarkNorm.buttonPrimaryOrDangerDisabled,
  buttonShowMoreColor: colors.brown[400],
  scrollbar: { bg: '#494b42' },
  switch: { color: colors.gray[700] },
  sidebar: {
    bg: '#1F201F',
    tooltipBg: 'rgba(41,41,51,.8)',
  },
  menu: {
    hoverBg: '#292A29',
    item: {
      color: '#D7D7CF',
      selected: {
        color: '#D7D7CF',
        bg: '#323332',
      },
    },
    adminTitle: {
      desktop: '#ffffff80',
      mobile: '#A69E99',
    },
    submenu: {
      selectedColor: '#D7D7CF',
      bg: '#292A29',
      hoverBg: '#323332',
    },
    body: {
      bg: '#eeede8',
    },
    sidebar: {
      bg: '#1F201F',
    },
    boxShadow: `0 0 100px rgb(255 169 0 / 50%)`,
    browserDarkTheme: {
      color: '#d7d7cf',
      inlineSubmenuBg: '#42443c',
      highlightColor: '#d7d7cf',
      itemActiveBg: '#34372b',
      selectedItemIconColor: '#a69e99',
      selectedItemTextColor: '#d7d7cf',
      itemHoverBg: '#34372b',
    },
  },

  iconMenu: {
    color: colors.blue[900],
    sidebarColor: colors.white,
  },

  menuLink: {
    color: colors.gray[300],
    hoverColor: '#CDB689',
  },

  login: loginDarkNorm,

  leftMenu: leftMenuDark,

  process: processDark,

  pieChart: pieChartNorm,

  securitySettingsWeight: 500,

  users: usersNorm,

  notFoundBackImg: '/assets/images/notFoundBackNorm.jpg',

  notFoundWrapperBackground: colors.gray[60],

  notFoundTextColor: colors.blue[900],
  notFoundBackLinkColor: colors.brown[500],

  notFoundBorder: colors.yellow[100],

  notFoundFontWeight: fontWeights.normal,
  notFoundFontSize: spacing[4],
  notFoundlineHeight: spacing[6],

  backFontWeight: fontWeights.bold,

  twoFAText: fontWeights.normal,

  alertAction: colors.gray[800],
  alertActionViewMore: colors.yellow[400],

  dashboardDigits: '24px',
  dashboardDigitsWeight: fontWeights.black,

  titlePageWeight: fontWeights.black,
  titlePageSize: '24px',
  titlePageColor: colors.white,

  iconCheck: colors.brown[400],

  labelsTextTransform: 'none',

  labelMediumColorText: colors.white,
  labelFontWeight: fontWeights.bold,
  modal: {
    closeIcon: '#C2BAAA',
    borderColor: '#363736',
  },
  segmented: {
    item: {
      bg: '#292A29',
      selected: colors.white,
    },
    bg: '#ffffff0f',
  },
  tabs: {
    borderBottomColor: '#323332',
    card: {
      active: {
        color: '#BEA167',
      },
    },
    ink: {
      bar: {
        color: colors.brown[400],
      },
    },
    highlight: {
      color: colors.brown[400],
    },
    hover: {
      color: colors.brown[400],
    },
    active: {
      color: colors.brown[400],
    },
  },
  stepper: {
    borderColor: '#594F4D',
    backgroundColor: '#363635',
    activeBorderColor: colors.brown[400],
    activeBackgroundColor: '#342F2B',
  },
  dull: {
    backgroundColor: '#292A29',
    mobileBackgroundColor: 'transparent',
    imageColor: '#323332',
    mobileImageColor: '#323332',
  },
};

type CSSVars<T, K extends keyof T = keyof T> = K extends string
  ? T[K] extends infer InnerObject
    ?
        | `${K}`
        | (InnerObject extends Record<string, unknown> ? `${K}.${CSSVars<InnerObject>}` : never)
    : never
  : never;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type ThemePropertyRecord = Record<string, any>;

export type CSSVarsType = CSSVars<typeof nasstar>;

export const getCssVar = (varName: CSSVars<typeof nasstar>, wrapWithVarFunction = true) => {
  if (!wrapWithVarFunction) {
    return `${varName.replaceAll('.', '-')}`;
  }

  return `var(--norm-${varName.replaceAll('.', '-')})`;
};

export const createCSSVarsFromObject = (theme: typeof norm | typeof darkNorm | typeof nasstar) => {
  const processObject = (obj: ThemePropertyRecord, path: string) => {
    return Object.entries(obj).reduce<ThemePropertyRecord>((acc, [key, value]) => {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        return {
          ...acc,
          ...processObject(value, `${path}-${key}`),
        };
      }

      // eslint-disable-next-line no-param-reassign
      acc[`${path}-${key}`] = value;

      return acc;
    }, {});
  };

  return processObject(theme, '--norm');
};

const cssVarsObjToString = (map: Record<string, string>) =>
  `${Object.entries(map)
    .map(([varName, varValue]) => `${varName}: ${varValue}`)
    .join(';')};`;

export const createNormCSSVarsFromObject = () => cssVarsObjToString(createCSSVarsFromObject(norm));

export const createDarkCSSVarsFromObject = () =>
  cssVarsObjToString(createCSSVarsFromObject(darkNorm));

export const createNasstarCSSVarsFromObject = () =>
  cssVarsObjToString(createCSSVarsFromObject(nasstar));
