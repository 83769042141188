import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import moment from 'moment';
import {
  ActionPopup,
  Box,
  EmptyTablePreview,
  InnerLayout,
  MobileTable,
  MobileTableCol,
} from 'shared';
import { CustomTable } from 'shared/Table/CustomTable';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { downloadCSV } from 'services/helpers/downloadCSV';
import { showError, showMessage, excludeAgedHostTag } from 'services/helpers';
import {
  CMDBReportType,
  GridRequestParams,
  HostSchema,
  TagSchema,
  CmdbService,
} from 'services/api';
import { HistoryState } from 'services/interfaces';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { useGetPermission, useAgedHostsOrCmdbRoute } from 'services/hooks';
import {
  backlinkRoutes,
  dateFormatHours,
  defaultCurrent,
  defaultPageSize,
  errorDefaultNotificationMessage,
  loadingNotificationMessage,
  PermissionOptions,
  PermissionsLevel,
} from 'app-constants';
import {
  app,
  AppDispatch,
  cmdb,
  cmdbApi,
  MessageType,
  useApp,
  useAuth,
  useCmdb,
  useCMDBPotentialVulnerabilities,
  useCustomer,
  useLoading,
  useTagsManagement,
} from 'store';
import { RequestStatus } from 'store/loading/slice';
import { getFiltersFields } from 'pages/CmdbHostDetails/components/Vulnerabilities/helpers';
import { assetDetails } from 'store/assetDetails/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { downloadZip } from 'services/helpers/downloadZip';
import { useMessagingClient, useFileDownloadMessagingProgress } from 'services/messaging';
import { getColumns } from './components/TableColumns';
import { getLayoutConfig, showDeletePopup } from './helpers';
import {
  allowedUploadOnlyCSV,
  confirmedVulnerabilitiesFilter,
  filterFields,
  initialOrderBy,
  ManageTagDropdownLabels,
  popupConfig as staticPopupConfig,
  potentialVulnerabilitiesFilter,
  searchSettings,
  defaultAssetSourcesOptions,
} from './constants';
import { MobileActions } from './components/MobileActions';
import { useFilter } from '../../services/hooks/useFilter';
import { tagsManagementApi } from '../../store/tagsManagement/thunks';
import { OptionType } from '../../shared/Table/types';
import { PopupWithProgress } from './components/PopupWithProgress';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const FULL_WIDTH_COLS = 32;

export const Cmdb = () => {
  const history = useHistory();
  const { data } = useCmdb();
  const { tagsList } = useTagsManagement();
  const { potentialVulnerabilitiesExists } = data;
  const dispatch: AppDispatch = useDispatch();
  const customerId = useCustomer();
  const loading = useLoading([
    cmdbApi.fetchData.typePrefix,
    cmdbApi.getCSVReport.typePrefix,
    cmdbApi.deleteHosts.typePrefix,
    tagsManagementApi.getTagsList.typePrefix,
    cmdbApi.uploadTags.typePrefix,
  ]);
  const isToggleButtonLoading = Object.values(loading).some((item) => item);
  const [popupConfig, setPopupConfig] = useState<PopupConfigType>(staticPopupConfig.delete);
  const { isMobile } = useApp();
  const { user } = useAuth();
  const [selectedHosts, setSelectedHosts] = useState<HostSchema[]>([]);
  const { state: historyState, pathname } = useLocation<HistoryState>();
  const isPotentialVulnerabilitiesActive = useCMDBPotentialVulnerabilities();
  const inputRef = React.createRef<HTMLInputElement>();
  const isFullAccess = useGetPermission(PermissionOptions.CMDB) === PermissionsLevel.Full;
  const { pageRoute, isAgedHostsPage } = useAgedHostsOrCmdbRoute();

  const newVulnerabilityStatusFilter = isPotentialVulnerabilitiesActive
    ? potentialVulnerabilitiesFilter
    : confirmedVulnerabilitiesFilter;

  const request = useCallback(
    (params) => {
      const filter = {
        ...params.filter,
        fields: getFiltersFields(
          params?.filter?.fields || [],
          newVulnerabilityStatusFilter.fields[0],
        ),
      };

      dispatch(
        cmdbApi.fetchData({
          ...params,
          filter,
          agedHosts: isAgedHostsPage,
        }),
      );
    },
    [dispatch, newVulnerabilityStatusFilter.fields, isAgedHostsPage],
  );

  const [params, updateParams] = useFilter({
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
    filter: isPotentialVulnerabilitiesActive
      ? { ...potentialVulnerabilitiesFilter }
      : { ...confirmedVulnerabilitiesFilter },
    page: isAgedHostsPage ? 'CMDB_AGED_HOSTS' : 'CMDB',
    historyState,
    request,
  });

  const GUTTER: [Gutter, Gutter] = !isMobile
    ? [H_GUTTER, V_GUTTER]
    : [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

  const permission = useGetPermission(PermissionOptions.CMDB);

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const onSwitchToggleButton = useCallback(
    (isPotentialVulnsSwitched: boolean) => {
      dispatch(cmdb.actions.setShowPotentialVulnerabilities(isPotentialVulnsSwitched));
      dispatch(assetDetails.actions.setShowPotentialVulnerabilities(isPotentialVulnsSwitched));
    },
    [dispatch],
  );

  const handleTableClick = useCallback(
    (record: HostSchema) => {
      history.push(`${pageRoute}/${record.id}`, {
        backTo: {
          route: `${pageRoute}`,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[pageRoute]}`,
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [history, historyState, pageRoute],
  );

  const updateHostsList = React.useCallback(() => {
    dispatch(
      cmdbApi.fetchData({
        ...params,
        agedHosts: isAgedHostsPage,
      }),
    );
  }, [dispatch, params, isAgedHostsPage]);

  const columns = useMemo(() => {
    return getColumns(
      (row: HostSchema, soft?: boolean) => {
        showDeletePopup({
          setPopupConfig,
          setIsPopupVisible,
          hostData: [{ id: row.id, hostName: row.hostname }],
          dispatch,
          customerId,
          onSuccess: updateHostsList,
          isNormUser: user.isSuperAdmin,
          soft,
        });
      },
      permission,
      isMobile,
      user.isSuperAdmin,
    );
  }, [permission, isMobile, user.isSuperAdmin, dispatch, customerId, updateHostsList]);

  const getActions = useCallback(
    (row: HostSchema) => (
      <MobileActions
        onAction={(soft: boolean) =>
          showDeletePopup({
            setPopupConfig,
            setIsPopupVisible,
            hostData: [{ id: row.id, hostName: row.hostname }],
            dispatch,
            customerId,
            onSuccess: updateHostsList,
            isNormUser: user.isSuperAdmin,
            soft,
          })
        }
      />
    ),
    [setIsPopupVisible, customerId, user.isSuperAdmin, updateHostsList, dispatch],
  );

  const onSelectedChanged = useCallback((_: React.Key[], selectedRows: HostSchema[]) => {
    setSelectedHosts(selectedRows);
  }, []);

  const handleSelectedDelete = useCallback(
    (soft: boolean, action?: string, tagId?: string) => {
      if (action && tagId) {
        if (action === ManageTagDropdownLabels.AddTags) {
          selectedHosts.forEach(({ id }: HostSchema) => {
            if (id) {
              dispatch(cmdbApi.setTag({ customerId, hostId: id.toString(), tagId }));
            }
          });
        }

        if (action === ManageTagDropdownLabels.DeleteTags) {
          selectedHosts.forEach(({ id }: HostSchema) => {
            if (id) {
              dispatch(cmdbApi.removeTag({ customerId, hostId: id.toString(), tagId }));
            }
          });
        }

        return;
      }

      showDeletePopup({
        setPopupConfig,
        setIsPopupVisible,
        hostData: selectedHosts.map((host: HostSchema) => ({
          id: host.id,
          hostName: host.hostname,
        })),
        dispatch,
        customerId,
        onSuccess: updateHostsList,
        isNormUser: user.isSuperAdmin,
        soft,
      });
    },
    [customerId, dispatch, selectedHosts, user.isSuperAdmin, updateHostsList],
  );

  useEffect(() => {
    if (!loading[cmdbApi.deleteHosts.typePrefix]) {
      dispatch(app.actions.setBlured(false));

      return;
    }

    showMessage(loadingNotificationMessage, MessageType.Loading);
    dispatch(app.actions.setBlured(true));
  }, [loading, dispatch, params]);

  useEffect(() => {
    dispatch(tagsManagementApi.getTagsList(customerId));
  }, [customerId, dispatch]);

  const [customerAssetSourcesOptions, setCustomerAssetSourcesOptions] = useState<OptionType[]>(
    defaultAssetSourcesOptions,
  );

  useEffect(() => {
    CmdbService.getCustomerHostsSources({ customerId })
      .then((sources) => {
        setCustomerAssetSourcesOptions(sources as OptionType[]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching customer CMDB sources', error);

        setCustomerAssetSourcesOptions(defaultAssetSourcesOptions);
      });
  }, [customerId]);

  const messagingClient = useMessagingClient();

  const {
    progress,
    setProgress,
    progressMessageHandler,
    initialProgressState,
  } = useFileDownloadMessagingProgress();

  const onDownloadCSV = async (reportType: CMDBReportType = CMDBReportType.SUMMARY) => {
    const hostsTypes = isAgedHostsPage ? 'CMDB AGED HOSTS' : 'CMDB';
    const filename = `${hostsTypes}  ${moment().utc().format(dateFormatHours)} UTC`;
    const isDetailedReport = reportType.toLowerCase() === CMDBReportType.DETAILED;

    const getReport = async (connectionId = '') => {
      return dispatch(
        cmdbApi.getCSVReport({
          ...params,
          filter: {
            ...params.filter,
            fields: getFiltersFields(
              params?.filter?.fields || [],
              newVulnerabilityStatusFilter.fields[0],
            ),
          },
          reportType,
          agedHosts: isAgedHostsPage,
          connectionId,
        }),
      ).unwrap();
    };

    try {
      setProgress({ ...initialProgressState, isProcessing: true });

      if (isDetailedReport) {
        const { connectionInfo } = await messagingClient?.listen(progressMessageHandler);
        const result = await getReport(connectionInfo.connectionId);

        if (result instanceof Blob) {
          downloadZip(result, filename);
        }
      } else {
        const result = await getReport();

        downloadCSV(result, filename);
      }
    } catch (error) {
      showError();
    } finally {
      messagingClient.stop();

      setProgress(initialProgressState);
    }
  };

  const handleAttachButton = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  const onUploadTags = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const filesUploaded = event.currentTarget.files;

      if (!filesUploaded) {
        return;
      }

      if (filesUploaded[0].type !== allowedUploadOnlyCSV) {
        showMessage({ content: 'CSV (only) files can be uploaded.' }, MessageType.Error);

        return;
      }

      const reader = new FileReader();

      reader.readAsArrayBuffer(filesUploaded[0] as File);
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const blob = new Blob([e?.target?.result as ArrayBuffer], {
          type: 'application/octet-stream',
        });

        const result = (await dispatch(
          cmdbApi.uploadTags({ customerId, requestBody: blob }),
        )) as PayloadAction<
          { notFoundHosts?: string[]; incorrectTags?: string[]; hostsMaxTagsReached?: string[] },
          string,
          { requestStatus: string },
          { message: string }
        >;

        if (result.meta.requestStatus === RequestStatus.Rejected) {
          showMessage(
            { content: result.error.message || errorDefaultNotificationMessage },
            MessageType.Error,
          );

          return;
        }

        const notFoundHosts = result.payload?.notFoundHosts as string[];
        const incorrectTags = result.payload?.incorrectTags as string[];
        const hostsMaxTagsReached = result.payload?.hostsMaxTagsReached as string[];

        const content: string[] = [
          'File processing completed successfully, however, there are some unprocessed data described below.',
        ];

        if (notFoundHosts.length) {
          content.push(`The following hosts have not been found: ${notFoundHosts.join(', ')}`);
        }

        if (incorrectTags.length) {
          content.push(`The following tags have invalid names: ${incorrectTags.join(', ')}`);
        }

        if (hostsMaxTagsReached.length) {
          content.push(
            `The maximum number of tags has been reached for: ${hostsMaxTagsReached.join(', ')}`,
          );
        }

        if (content.length === 1) {
          showMessage(
            { content: `File successfully uploaded, without any warnings.` },
            MessageType.Success,
          );
        } else {
          showMessage(
            {
              style: {
                margin: 'auto',
                maxWidth: '750px',
              },
              content: (
                <>
                  {content.map((warning) => (
                    <Box key={warning}>{warning}</Box>
                  ))}
                </>
              ),
            },
            MessageType.Warning,
          );
        }

        request(params);
      };

      const input = inputRef.current;

      if (input) {
        input.value = '';
      }
    },
    [customerId, dispatch, inputRef, params, request],
  );

  const layoutConfig = getLayoutConfig(
    onDownloadCSV,
    onSwitchToggleButton,
    loading[cmdbApi.getCSVReport.typePrefix],
    historyState,
    pathname,
    isPotentialVulnerabilitiesActive,
    isToggleButtonLoading,
    potentialVulnerabilitiesExists,
    isMobile,
    onUploadTags,
    handleAttachButton,
    inputRef,
    loading[cmdbApi.uploadTags.typePrefix],
    isFullAccess,
    isAgedHostsPage,
  );

  const emptyMessage = (
    <EmptyTablePreview
      title='No hosts discovered yet'
      textContent='Here will be displayed your hosts within your environment once discovered.'
      loading={loading[cmdbApi.fetchData.typePrefix]}
      alignCenter
    />
  );

  const fields = useMemo(() => {
    const selectTagOptions = tagsList?.filter(excludeAgedHostTag).map((item: TagSchema) => ({
      label: item?.name,
      value: item?.id,
    })) as OptionType[];

    return filterFields(customerAssetSourcesOptions, selectTagOptions, isAgedHostsPage);
  }, [tagsList, isAgedHostsPage, customerAssetSourcesOptions]);

  const tableColumns = getTableColumns(columns, params.orderBy);

  return (
    <InnerLayout {...layoutConfig}>
      {isMobile ? (
        <Row gutter={GUTTER}>
          <MobileTableCol span={FULL_WIDTH_COLS} flex='auto'>
            <MobileTable<HostSchema, GridRequestParams>
              data={data}
              searchSettings={searchSettings}
              columns={tableColumns}
              filterFields={fields}
              onRowClick={handleTableClick}
              params={params}
              setParams={updateParams}
              isLoadingContent={loading[cmdbApi.fetchData.typePrefix]}
              initialOrderBy={initialOrderBy}
              getActions={permission !== PermissionsLevel.Full ? undefined : getActions}
              emptyMessage={emptyMessage}
            />
          </MobileTableCol>
        </Row>
      ) : (
        <CustomTable<HostSchema, GridRequestParams>
          columns={tableColumns}
          data={data}
          params={params}
          setParams={updateParams}
          filterFields={fields}
          searchSettings={searchSettings}
          rowSelection={{
            onChange: onSelectedChanged,
          }}
          onMultyDeletionSubmit={
            permission === PermissionsLevel.Full ? handleSelectedDelete : undefined
          }
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          isLoadingContent={loading[cmdbApi.fetchData.typePrefix]}
          onRowClickHandle={handleTableClick}
          emptyMessage={emptyMessage}
        />
      )}
      {isPopupVisible && (
        <ActionPopup
          isVisible={isPopupVisible}
          setIsVisiblePopup={setIsPopupVisible}
          popupDetails={popupConfig}
        />
      )}

      <PopupWithProgress
        visible={progress.isProcessing}
        percent={progress.percent}
        disableCancel={!messagingClient.isConnected}
        handleCancel={() => {
          messagingClient.stop();
          setProgress(initialProgressState);
        }}
      />
    </InnerLayout>
  );
};
