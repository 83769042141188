import { Routes } from '../services/entities';

export const backlinkRoutes: Record<string, string> = {
  [Routes.PRIVACY]: 'Privacy',
  [Routes.TERMS]: 'Terms',
  [Routes.DASHBOARD]: 'Dashboard',
  [Routes.PROCESS]: 'Process',
  [Routes.PEOPLE]: 'People',
  [Routes.PEOPLE_PHISHING]: 'Phishing and training activity',
  [Routes.VULNERABILITY_MANAGEMENT]: 'Vulnerability management',
  [Routes.VULNERABILITIES_LIST]: 'Vulnerabilities list',
  [Routes.THREAT_DETECTION]: 'Threat detection',
  [Routes.PENETRATION_TESTING]: 'Penetration testing',
  [Routes.PENETRATION_TESTS_LIST]: 'Penetration tests',
  [Routes.EMAIL_SECURITY]: 'Email security',
  [Routes.DATA_PROTECTION]: 'Data protection',
  [Routes.CMDB]: 'CMDB',
  [Routes.CMDB_AGED_HOSTS]: 'Aged hosts',
  [Routes.CMDB_HOST_DETAILS]: 'Cmdb host details',
  [Routes.ALARMS]: 'Alarms',
  [Routes.SUPPORT_REQUESTS]: 'Support requests',
  [Routes.SUPPORT_REQUESTS_DETAILS]: 'Support request',
  [Routes.DOCUMENTS]: 'Documents',
  [Routes.SOFTWARE]: 'Software',
  [Routes.USERS]: 'Users',
  [Routes.AUDIT_LOG]: 'Audit log',
  [Routes.SECURITY_SETTINGS]: 'Security settings',
  [Routes.REPORT_INCIDENT]: 'Report incident',
  [Routes.SERVICE_REQUEST]: 'Service request',
  [Routes.PATCH_MANAGEMENT]: 'Patch management',
  [Routes.PATCH_MANAGEMENT_ASSETS]: 'Assets',
  [Routes.PATCH_MANAGEMENT_PATCHES]: 'Patches',
  [Routes.CE_PLUS]: 'Cyber Essentials dashboard',
  [Routes.MAINTENANCE_SETTINGS]: 'Maintenance page setting',
  [Routes.TAGS_MANAGEMENT]: 'Tags management',
  [Routes.NOTIFICATIONS]: 'Notifications',
  [Routes.SERVICES_INTEGRATIONS]: 'Norm-approved integrations',
  [Routes.SERVICE_INTEGRATION_DETAILS]: 'Service integration',
};
