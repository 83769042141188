import { useAssetDetails, useLoading } from 'store';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { renderValue } from 'pages/CmdbHostDetails/helpers';
import { CardStyled } from 'pages/CmdbHostDetails/styled';
import { useTheme } from 'styled-components';
import { Descriptions } from '../../styled';
import { MainInfoTitle } from './constants';

export type HostDetailsMainInfoProps = {
  isMobile?: boolean;
};

export const HostDetailsMainInfo = ({ isMobile }: HostDetailsMainInfoProps) => {
  const theme = useTheme();
  const loading = useLoading([assetDetailsApi.getDetailsAdditionalInformation.typePrefix]);
  const { detailsAdditionalInformation } = useAssetDetails();

  return (
    <CardStyled
      marginBottom={isMobile ? theme.spacing[4] : theme.spacing[8]}
      isHiddable={isMobile}
      title={isMobile ? 'Details' : undefined}
      headStyles={!isMobile ? {} : { pb: theme.sizes['2.5'], titlePaddingRight: theme.sizes[0] }}
      bodyPadding={!isMobile ? theme.sizes['2.5'] : theme.sizes[0]}
      cardPadding={
        !isMobile ? theme.sizes['5.5'] : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes['1.5']}`
      }
      showLoader={loading[assetDetailsApi.getDetailsAdditionalInformation.typePrefix]}
    >
      <Descriptions column={1}>
        <Descriptions.Item label={MainInfoTitle.LastUserLogin}>
          {renderValue(detailsAdditionalInformation.lastUserLogin)}
        </Descriptions.Item>

        <Descriptions.Item label={MainInfoTitle.IP}>
          {renderValue(detailsAdditionalInformation.ip)}
        </Descriptions.Item>

        <Descriptions.Item label={MainInfoTitle.Manufacturer}>
          {renderValue(detailsAdditionalInformation.manufacturer)}
        </Descriptions.Item>

        <Descriptions.Item label={MainInfoTitle.OS}>
          {renderValue(detailsAdditionalInformation.os)}
        </Descriptions.Item>

        <Descriptions.Item label={MainInfoTitle.DeviceStatus}>
          {renderValue(detailsAdditionalInformation.deviceStatus)}
        </Descriptions.Item>
      </Descriptions>
    </CardStyled>
  );
};
