import React from 'react';
import _ from 'lodash';
import { getFormattedDate, getRenderValue } from 'services/helpers';
import { AssetsListSchema, AssetStatus, Filter, PatchStatus } from 'services/api';
import { Box } from 'shared';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { Routes } from 'services/entities';
import { backlinkRoutes, initialPagination } from 'app-constants';
import { getQueryString } from 'services/api/core/request';
import { initialOrderBy as patchesOrderBy } from 'pages/PatchManagementPatches/constants';
import { IconColoring } from './IconColoring';
import { ColumnKey, TableTitles } from '../types';

export const getColumns = (locationSearch: string): ColumnsType<AssetsListSchema> => [
  {
    key: ColumnKey.Asset,
    title: TableTitles.Asset,
    dataIndex: ColumnKey.Asset,
    render: (text: string) => (
      <Box display='flex' flexDirection='column' w='200px'>
        <Box>{getRenderValue(text.toLowerCase())}</Box>
      </Box>
    ),
    sorter: true,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.OS,
    title: TableTitles.OS,
    dataIndex: ColumnKey.OS,
    sorter: true,
    showSorterTooltip: false,
    render: (text: string) => <Box w='500px'>{getRenderValue(text)}</Box>,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    sorter: true,
    render: (status: AssetStatus) => (
      <Box flexDirection='row' minW='100px'>
        <>
          <IconColoring status={status} />
          <span>{status}</span>
        </>
      </Box>
    ),
    showSorterTooltip: false,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.MissingPatches,
    title: TableTitles.MissingPatches,
    dataIndex: ColumnKey.MissingPatches,
    sorter: false,
    align: 'right',
    render: (missingCount: number, row: AssetsListSchema) => (
      <Link
        to={{
          pathname: Routes.PATCH_MANAGEMENT_PATCHES,
          search: getQueryString({
            pagination: initialPagination,
            orderBy: patchesOrderBy,
            filter: {
              fields: [
                {
                  name: 'patchStatus',
                  value: [PatchStatus.MISSING],
                  type: Filter.type.MULTIPLE,
                },
                {
                  name: 'hostId',
                  value: row.hostId,
                  type: Filter.type.VALUE,
                },
              ],
            },
          }),
          state: {
            backTo: {
              route: Routes.PATCH_MANAGEMENT_ASSETS,
              title: `Back to ${backlinkRoutes[Routes.PATCH_MANAGEMENT_ASSETS]}`,
              search: locationSearch,
            },
          },
        }}
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
      >
        {getRenderValue(missingCount)}
      </Link>
    ),
    showSorterTooltip: false,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.InstalledPatches,
    title: TableTitles.InstalledPatches,
    dataIndex: ColumnKey.InstalledPatches,
    sorter: false,
    align: 'right',
    render: (installedCount: number, row) => (
      <Link
        to={{
          pathname: Routes.PATCH_MANAGEMENT_PATCHES,
          search: getQueryString({
            pagination: initialPagination,
            orderBy: patchesOrderBy,
            filter: {
              fields: [
                {
                  name: 'patchStatus',
                  value: [PatchStatus.INSTALLED],
                  type: Filter.type.MULTIPLE,
                },
                {
                  name: 'hostId',
                  value: row.hostId,
                  type: Filter.type.VALUE,
                },
              ],
            },
          }),
          state: {
            backTo: {
              route: Routes.PATCH_MANAGEMENT_ASSETS,
              title: `Back to ${backlinkRoutes[Routes.PATCH_MANAGEMENT_ASSETS]}`,
              search: locationSearch,
            },
          },
        }}
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
      >
        {getRenderValue(installedCount)}
      </Link>
    ),
    showSorterTooltip: false,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.Scanned,
    title: TableTitles.Scanned,
    dataIndex: ColumnKey.Scanned,
    sorter: true,
    showSorterTooltip: false,
    render: (date: string) => <>{getFormattedDate(date, '', true)}</>,
    className: 'right-head-title',
    align: 'right',
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
];
