import { createSlice } from '@reduxjs/toolkit';
import { initialState, setDownloading, makeExtraReducers } from '../documents/slice';
import { softwareApi } from './thunks';

export const software = createSlice({
  name: 'software',
  initialState,
  reducers: {
    setDownloading,
  },
  extraReducers: makeExtraReducers(softwareApi),
});
