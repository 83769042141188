import { Descriptions } from 'pages/FindingsVulnerabilityDetails/styled';
import React from 'react';
import { formatUpdatedOrCreatedTime } from 'services/helpers';
import { Card } from 'shared';
import { useApp, useFindingsVulnerabilityDetails } from 'store';
import { useTheme } from 'styled-components';
import { AssigneTo } from './AssignTo';
import { DetailsLabels } from './constants';

type TestDetailsProps = {
  loading: boolean;
};

export const TestDetails = ({ loading }: TestDetailsProps) => {
  const theme = useTheme();
  const { details } = useFindingsVulnerabilityDetails();
  const { isMobile } = useApp();

  return (
    <Card
      title='Vulnerability test details'
      headStyles={
        !isMobile
          ? { pb: theme.sizes[5], titlePaddingRight: theme.sizes['2.5'] }
          : { pb: theme.sizes['2.5'], titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={
        !isMobile ? `${theme.sizes[6]} ${theme.sizes['2.5']} ${theme.sizes['2.5']}` : theme.sizes[0]
      }
      cardPadding={
        !isMobile
          ? `${theme.sizes[7]} ${theme.sizes['5.5']} ${theme.sizes['5.5']}`
          : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes['1.5']}`
      }
      marginBottom={theme.spacing[8]}
      showLoader={loading}
    >
      <Descriptions column={1}>
        <Descriptions.Item key='status' label={DetailsLabels.Status}>
          {details.status}
        </Descriptions.Item>
        <Descriptions.Item key='test' label={DetailsLabels.Test}>
          {details.test}
        </Descriptions.Item>
        <Descriptions.Item key='assigned' label={DetailsLabels.AssignedTo}>
          <AssigneTo name={details.assigned?.name} email={details.assigned?.email} />
        </Descriptions.Item>
        <Descriptions.Item key='startDate' label={DetailsLabels.Start}>
          {details.startDate && formatUpdatedOrCreatedTime(details.startDate)}
        </Descriptions.Item>
        <Descriptions.Item key='endDate' label={DetailsLabels.End}>
          {details.endDate && formatUpdatedOrCreatedTime(details.endDate)}
        </Descriptions.Item>
        <Descriptions.Item key='updated' label={DetailsLabels.Updated}>
          {details.updated && formatUpdatedOrCreatedTime(details.updated)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
