import React from 'react';
import { DashboardScores } from 'services/api';
import moment from 'moment';
import { getSortedDashboardScores } from '../helpers';

type TotalScoreTableProps = {
  totalScoreTable?: DashboardScores;
};

const MAX_MONTH = 6;

const getDinamicIcon = (prevScore: number, totalScore: number) => {
  if (totalScore < prevScore) {
    return <span className='color-red text-bold font-size-12'>▽</span>;
  }

  if (totalScore > prevScore) {
    return <span className='color-green text-bold font-size-12'>△</span>;
  }

  return <span className='text-bold font-size-12'>-</span>;
};

export const TotalScoreTable = ({ totalScoreTable }: TotalScoreTableProps) => {
  const totalScoreCollection =
    totalScoreTable &&
    getSortedDashboardScores([...totalScoreTable]).slice(0, totalScoreTable.length - 1);

  return (
    <div style={{ width: '160px', margin: '0 auto' }}>
      <table className='total-score-table' width='100px'>
        <tbody>
          <tr>
            {totalScoreCollection?.map((item, index: number) => {
              const prevScore = totalScoreCollection?.[index - 1]?.total;

              if (
                item.total === undefined ||
                (index === 0 && totalScoreCollection.length >= MAX_MONTH)
              ) {
                return null;
              }

              return (
                <td key={item.timestamp}>
                  <div>{item.timestamp && moment(item.timestamp).format('MMM')}</div>
                  <div className='arrow-icon'>
                    {prevScore ? (
                      getDinamicIcon(prevScore, item.total)
                    ) : (
                      <span className='text-bold font-size-12'>-</span>
                    )}
                  </div>
                  <div>{item.total}</div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
