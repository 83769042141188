import React from 'react';
import { CertificateSchema } from 'services/api';
import { PermissionsLevel } from 'app-constants';
import { ProcessItem } from './ProcessItem';
import { staticCertificates } from '../../../constants';

type ProcessProps = {
  certificates?: Array<CertificateSchema>;
  permission: string;
};

export const ProcessList = ({ certificates, permission }: ProcessProps) => {
  return (
    <>
      {permission !== PermissionsLevel.NoAccess && certificates && certificates?.length > 0 && (
        <>
          <table className='text-center'>
            <tbody>
              <tr>
                {(permission === PermissionsLevel.NoAccess
                  ? staticCertificates
                  : certificates
                )?.map((certificate: CertificateSchema) => {
                  return (
                    <ProcessItem
                      {...certificate}
                      key={certificate.title}
                      isLocked={permission === PermissionsLevel.NoAccess}
                    />
                  );
                })}
              </tr>
            </tbody>
          </table>
          <hr />
        </>
      )}
    </>
  );
};
