import styled from 'styled-components';
import { MobileTableWrapper as MobileTableWrapperShared } from 'shared';
import { breakpoints } from 'styles/theme';
import { Document } from './Document';

export const DocumentStyled = styled(Document)`
  .ant-card-body {
    padding: 6px 15px;
  }
  .ant-col {
    display: flex;
    align-items: center;
  }
  .ant-row {
    justify-content: space-between;
  }
  .title {
    font-weight: 700;
    margin: 0;
  }
  .size {
    font-weight: 300;
    font-size: 14px;
  }
  .ant-space {
    margin-left: 6px;
  }
`;

export const MobileTableWrapper = styled(MobileTableWrapperShared)`
  .ant-descriptions-item-content {
    @media (max-width: ${breakpoints.md}) {
      padding-right: 20px;
    }
  }
`;
