import { colors } from '../colors';
import { fontWeights } from '../fontWeights';
import { mainFontFamily as nasstarFontFamily } from '../Nasstar/fontsNasstar';

export const process = {
  labelWeight: fontWeights.bold,
  labelInputsWeight: fontWeights.medium,
  labelFontFamily: nasstarFontFamily,
  success: colors.green[500],
  expired: colors.red[700],
  inProgress: colors.red[400],
  soonExpired: 'rgba(235, 100, 74, 0.3)',
  labelUppercase: 'none',
  hoverBg: 'rgba(224, 227, 232, 0.2)',
};
