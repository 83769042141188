/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductCertificateRequest } from '../models/CreateProductCertificateRequest';
import type { CustomerId } from '../models/CustomerId';
import type { CustomerProductsListResponse } from '../models/CustomerProductsListResponse';
import type { GetAllBoostScoreItemsResponse } from '../models/GetAllBoostScoreItemsResponse';
import type { GetAllCertificatesResponse } from '../models/GetAllCertificatesResponse';
import type { Product } from '../models/Product';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { UpdateBoostScoreItemRequest } from '../models/UpdateBoostScoreItemRequest';
import type { UpdateProductCertificateRequest } from '../models/UpdateProductCertificateRequest';
import { request as __request } from '../core/request';

export class ProcessService {
  /**
   * Create product certificate
   * Create product certificate
   * @returns SuccessResponse Create product certificate
   * @throws ApiError
   */
  public static async createProductCertificate({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Create product certificate **/
    requestBody?: CreateProductCertificateRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/product-certificate`,
      body: requestBody,
      errors: {
        400: `An error message when create`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Update product certificate
   * Update product certificate
   * @returns SuccessResponse Update product certificate
   * @throws ApiError
   */
  public static async updateProductCertificate({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Update product certificate **/
    requestBody?: UpdateProductCertificateRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/product-certificate`,
      body: requestBody,
      errors: {
        400: `An error message when update`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete product certificate
   * Delete product certificate
   * @returns SuccessResponse Delete product certificate
   * @throws ApiError
   */
  public static async deleteProductCertificate({
    customerId,
    product,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Product name parameter **/
    product: Product;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/product-certificate`,
      query: {
        product: product,
      },
      errors: {
        400: `An error message when delete`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get all certificates
   * Get all customer's certificates
   * @returns GetAllCertificatesResponse Get all certificates
   * @throws ApiError
   */
  public static async getAllCertificates({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<GetAllCertificatesResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/product-certificates`,
      errors: {
        400: `An error message when update`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Update boost score item
   * Update boost score item
   * @returns SuccessResponse Update boost score item
   * @throws ApiError
   */
  public static async updateBoostScoreItem({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Update boost score item **/
    requestBody?: UpdateBoostScoreItemRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/boost-score-item`,
      body: requestBody,
      errors: {
        400: `An error message when update`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete boost score item
   * Delete boost score item
   * @returns SuccessResponse Delete boost score item
   * @throws ApiError
   */
  public static async deleteBoostScoreItem({
    customerId,
    product,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Product name parameter **/
    product: Product;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/boost-score-item`,
      query: {
        product: product,
      },
      errors: {
        400: `An error message when delete`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get all boost score items
   * Get all customer's boost score items
   * @returns GetAllBoostScoreItemsResponse Get all boost score items
   * @throws ApiError
   */
  public static async getAllBoostScoreItems({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<GetAllBoostScoreItemsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/boost-score-items`,
      errors: {
        400: `An error message when get`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get all not failed products
   * Get all customer's not failed products
   * @returns CustomerProductsListResponse Customer products list
   * @throws ApiError
   */
  public static async getProducts({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<CustomerProductsListResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/products`,
      errors: {
        400: `An error message when get customer's products`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
