import styled from 'styled-components';
import { ThemeProps } from 'services/interfaces';
import { Box } from 'shared';
import { breakpoints } from 'styles/theme';

export const Total = styled(Box)<ThemeProps>`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 2px;
`;

interface ChartProps {
  hasCursor?: boolean;
}

export const ChartWrapper = styled(Box)<ChartProps>`
  cursor: ${({ hasCursor }: ChartProps) => (hasCursor ? 'pointer' : 'default')};

  canvas {
    cursor: ${({ hasCursor }: ChartProps) => (hasCursor ? 'pointer' : 'default')};
  }
`;

export const ButtonWrapper = styled(Box)`
  align-items: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;

  @media (max-width: ${breakpoints.lg}) {
    align-items: start;
    flex-direction: column;
  }
`;
