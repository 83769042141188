import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Filter } from 'services/api';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { Card } from 'shared';
import { SegmentedPie } from 'shared/charts';
import { EChartsEventParams } from 'shared/charts/interfaces';
import {
  useApp,
  useCustomer,
  useExcludedVulnerabilitiesStatusFilter,
  useLoading,
  useVulnerability,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import {
  confirmedVulnerabilitiesStatusFilter,
  initialOrderBy,
} from 'pages/VulnerabilitiesList/constants';
import { initialPagination } from 'app-constants';
import { useTheme } from 'styled-components';
import { getParamsFromVulnerabilitiesStatusFilter } from 'pages/VulnerabilityManagement/helpers';
import { IFilterField } from '../../../CEPlus/constants';
import { validateField, validateFieldValue } from '../../../CEPlus/helpers';
import { ExcludeFilterTooltip } from '../ExcludeFilterTooltip';

export const PatchManagement = () => {
  const theme = useTheme();
  const customerId = useCustomer();
  const dispatch = useDispatch();
  const history = useHistory();
  const { patchVsConfiguration, selectedTags = [] } = useVulnerability();
  const loading = useLoading([vulnerabilitiesApi.getPatchVsConfiguration.typePrefix]);
  const { isMobile, isMenuCollapsed } = useApp();
  const excludedVulnerabilitiesFilter = useExcludedVulnerabilitiesStatusFilter() as string[];

  const getVulnerabilitiesParams = (value: string, tags?: string[]) => {
    const filterFields: IFilterField[] = [
      {
        name: 'patchable',
        value,
        type: Filter.type.VALUE,
      },
      {
        name: 'tagsAssets',
        value: tags,
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
      { ...confirmedVulnerabilitiesStatusFilter.fields[0] },
    ];

    return {
      orderBy: initialOrderBy,
      pagination: initialPagination,
      tab: 'active',
      filter: {
        fields: validateField(filterFields),
      },
    };
  };

  const redirectToVulnerabilitiesList = useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      history.push(
        `${Routes.VULNERABILITIES_LIST}${getQueryString(
          getVulnerabilitiesParams(params.name, selectedTags),
        )}`,
      );
    },
    [history, selectedTags],
  );

  useEffect(() => {
    dispatch(
      vulnerabilitiesApi.getPatchVsConfiguration({
        customerId,
        tagIds: selectedTags || [],
        ...getParamsFromVulnerabilitiesStatusFilter(excludedVulnerabilitiesFilter),
      }),
    );
  }, [dispatch, excludedVulnerabilitiesFilter, customerId, selectedTags]);

  return (
    <Card
      title='Patches vs configuration'
      tooltipTitle='Current vulnerabilities that can be remediated by applying software patches versus those that require manual re-configuration of the device/appliance to be performed'
      showDivider={false}
      height={theme.sizes.full}
      headStyles={{
        headAlign: 'flex-start',
        titlePaddingRight: isMobile ? theme.sizes[0] : theme.sizes[5],
      }}
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={!isMobile ? theme.sizes[6] : theme.sizes[5]}
      showLoader={loading[vulnerabilitiesApi.getPatchVsConfiguration.typePrefix]}
      subTitle={
        <SubTitle className='exclude-filter-icon'>
          <ExcludeFilterTooltip />
        </SubTitle>
      }
    >
      <SegmentedPie
        style={isMobile ? { height: '240px' } : { height: '320px' }}
        values={{
          config: patchVsConfiguration.configuration || 0,
          patch: patchVsConfiguration.patch || 0,
        }}
        colors={{ config: theme.colors.purple[410], patch: theme.colors.blue[100] }}
        entitiesName=' '
        isMobile={isMobile}
        isMenuCollapsed={isMenuCollapsed}
        onEvents={{ click: redirectToVulnerabilitiesList }}
        isLoading={loading[vulnerabilitiesApi.getPatchVsConfiguration.typePrefix]}
      />
    </Card>
  );
};
