import React from 'react';
import {
  ByCriticalityVulnerabilitiesTrendSchema,
  CertificateStatusSchemaWithDownloadName,
  HostsCountBySeverity,
  HostsCountBySeverityResponse,
  IncompleteTrainingTrendResponse,
  IsolatedDevicesTrendResponse,
  SecurityIncidentsTrendResponse,
  SeverityLevel,
} from 'services/api';
import { InnerLayoutProps as InnerLayout } from 'shared';
import { TotalStressScoreLabels, totalStressScoreRedirects } from './constants';
import { WithTm } from './styled';
import {
  isCertificateSoonExpiredOrExpired,
  isStatusFailed,
  isStatusInProgress,
} from '../Process/helpers';
import { TotalStressScore } from './types';

export const getLayoutConfig = ({
  downloadReport,
  downloadScoreTrendsReport,
  hasReportPermission,
  loading,
}: {
  downloadReport: () => void;
  downloadScoreTrendsReport: () => void;
  hasReportPermission: boolean;
  loading: boolean;
}): InnerLayout => ({
  title: (
    <>
      Welcome to <WithTm>smartbloc</WithTm> LIVE
    </>
  ),
  downloadDoc: hasReportPermission
    ? [
        {
          id: 1,
          title: 'Download trend report',
          onDownload: downloadScoreTrendsReport,
          loading,
        },
        {
          id: 2,
          title: 'Download Report',
          onDownload: downloadReport,
        },
      ]
    : undefined,
});

export const getFilteredCertificatesScore = (
  certificates: CertificateStatusSchemaWithDownloadName[] | undefined,
) => {
  return certificates?.filter(
    ({ status, expirationDate }: CertificateStatusSchemaWithDownloadName) =>
      status &&
      !isStatusInProgress(status) &&
      !isStatusFailed(status) &&
      isCertificateSoonExpiredOrExpired(expirationDate),
  ).length;
};

export const getStressScores = ({
  hostsCountBySeverity,
  isolatedDevices,
  certificates,
  securityIncidents,
  incompleteTraining,
}: {
  hostsCountBySeverity?: HostsCountBySeverityResponse;
  isolatedDevices?: IsolatedDevicesTrendResponse;
  certificates?: number;
  securityIncidents?: SecurityIncidentsTrendResponse;
  incompleteTraining?: IncompleteTrainingTrendResponse;
}): TotalStressScore[] => {
  const criticalHostsCount = hostsCountBySeverity?.currentHostsCountByCriticalities?.find(
    (item: HostsCountBySeverity) => item.criticality === SeverityLevel._5,
  )?.count;
  const highHostsCount = hostsCountBySeverity?.currentHostsCountByCriticalities?.find(
    (item: HostsCountBySeverity) => item.criticality === SeverityLevel._4,
  )?.count;

  const criticalHostsTrend = hostsCountBySeverity?.trends?.find(
    (item: ByCriticalityVulnerabilitiesTrendSchema) => item.criticality === SeverityLevel._5,
  );
  const highHostsTrend = hostsCountBySeverity?.trends?.find(
    (item: ByCriticalityVulnerabilitiesTrendSchema) => item.criticality === SeverityLevel._4,
  );

  return [
    {
      label: TotalStressScoreLabels.CriticalHosts,
      value: criticalHostsCount,
      chartValues: { trend: criticalHostsTrend },
      redirect: totalStressScoreRedirects.criticalHosts,
    },
    {
      label: TotalStressScoreLabels.SecurityIncidents,
      value: securityIncidents?.count,
      chartValues: { trend: securityIncidents?.trend },
      redirect: totalStressScoreRedirects.securityIncidents,
    },
    {
      label: TotalStressScoreLabels.IncompleteTraining,
      value: incompleteTraining?.count,
      chartValues: { trend: incompleteTraining?.trend },
      redirect: totalStressScoreRedirects.incompleteTraining,
    },
    {
      label: TotalStressScoreLabels.HighHosts,
      value: highHostsCount,
      chartValues: { trend: highHostsTrend },
      redirect: totalStressScoreRedirects.highHosts,
    },
    {
      label: TotalStressScoreLabels.IsolatedDevices,
      value: isolatedDevices?.count,
      chartValues: { trend: isolatedDevices?.trend },
      redirect: totalStressScoreRedirects.isolatedDevices,
    },
    {
      label: TotalStressScoreLabels.CertificationsDue,
      value: certificates,
      chartValues: {},
      redirect: totalStressScoreRedirects.certificationsDue,
    },
  ];
};
