import { colors as themeColors } from 'styles/theme';

const colors = {
  primary: themeColors.brown[500],
  textColor: themeColors.blue[900],
};

export const ui = {
  colors,
};
