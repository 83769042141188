import React, { useEffect } from 'react';
import { InnerLayout } from 'shared';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useRowGap } from 'services/hooks/useRowGap';
import { AppDispatch, useCustomer, useVulnerability } from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import {
  ActiveVulnerabilitiesBySeverity,
  AllVulnerabilities,
  LatestVulnerabilities,
  MostVulnerableHosts,
  VulnerabilitiesSeverity,
  VulnerabilitiesWithMostAffectedHosts,
} from './components';
import { getLayoutConfig } from './helpers';
import { PatchManagement } from './components/PatchManagement/PatchManagment';
import { tagsManagementApi } from '../../store/tagsManagement/thunks';

export const VulnerabilityManagement = () => {
  const dispatch: AppDispatch = useDispatch();
  const customerId = useCustomer();
  const { selectedTags } = useVulnerability();

  const layoutConfig = getLayoutConfig();

  useEffect(() => {
    dispatch(tagsManagementApi.getTagsList(customerId));
    dispatch(
      vulnerabilitiesApi.getVulnerabilitiesCountByStatus({
        customerId,
        tagIds: selectedTags || [],
      }),
    );
  }, [dispatch, customerId, selectedTags]);

  const { gapNumber } = useRowGap();

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} xs={24} md={24} xxl={12}>
          <AllVulnerabilities />
        </Col>

        <Col span={gapNumber} xs={24} md={24} xxl={12}>
          <VulnerabilitiesSeverity />
        </Col>

        <Col span={gapNumber} xs={24} md={24} xxl={16}>
          <ActiveVulnerabilitiesBySeverity />
        </Col>

        <Col span={gapNumber} xs={24} md={24} xxl={8}>
          <PatchManagement />
        </Col>

        <Col span={gapNumber} xs={24} md={24} xxl={8}>
          <LatestVulnerabilities />
        </Col>

        <Col span={gapNumber} xs={24} md={24} xxl={8}>
          <VulnerabilitiesWithMostAffectedHosts />
        </Col>

        <Col span={gapNumber} xs={24} md={24} xxl={8}>
          <MostVulnerableHosts />
        </Col>
      </Row>
    </InnerLayout>
  );
};
