/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PhishingActivitySchema {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  OTHER = 'other',
}
