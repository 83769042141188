import { Theme } from 'styles/theme/types';

const getLevelColors = (value = 0, max = 0): string[] => {
  return value < max ? ['#43a047', '#9ccc65'] : ['#9c2b23', '#eb644a'];
};

const getGradientCoords = () => {
  return {
    x: 0,
    y: 0,
    x2: 1,
    y2: 1,
  };
};

const FULL_CHART = 100;

export const getOptions = (value: number, max: number, theme: Theme) => {
  const currentColors = getLevelColors(value || 0, max);
  const gradientCoords = getGradientCoords();
  const data = {
    name: 'value',
    value: value > max ? FULL_CHART : value,
    emphasis: { scale: false },
    itemStyle: {
      color: {
        type: 'linear',
        colorStops: currentColors.map((color, offset) => ({ offset, color })),
        ...gradientCoords,
      },
    },
  };

  const restSector = {
    name: 'rest',
    value: max - value,
    label: { show: false },
    emphasis: {
      scale: false,
      color: theme.colorSet.textColor,
    },
    itemStyle: {
      color: '#9CCC65',
      opacity: '0.45',
    },
  };

  const option = {
    legend: { show: false },
    cursor: 'pointer',
    silent: true,
    grid: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    xAxis: {
      type: 'value',
      show: false,
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      show: false,
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
        },
        emphasis: {
          focus: 'series',
        },
        data: [data],
      },
      {
        type: 'bar',
        stack: 'total',
        label: {
          show: false,
        },
        emphasis: {
          focus: 'series',
        },
        data: value < max ? [restSector] : [],
      },
    ],
  };

  return option;
};
