export const getNameCapitalLetters = (name?: string) => {
  if (!name) {
    return 'UN';
  }

  const [firstWord, secondWord] = name.split(' ').filter((word: string) => word !== '');

  return `${firstWord[0].toUpperCase()}${secondWord[0].toUpperCase()}`;
};

export const createLinkHistoryState = (pathname: string) => ({
  pathname,
  state: { shouldResetHistory: true },
});
