import { colors } from 'styles/theme';
import { SeverityLevel } from 'services/api';
import { Theme } from 'styles/theme/types';
import { Severity } from 'services/constants';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { OptionType } from 'shared/Table/types';
import {
  EmptyValues,
  EmptyValuesAllVulnerabilities,
  EmptyValuesVulnerabilitiesSeverity,
} from './types';

export const getCurrentVulnerabilitiesBySeverityColors = (theme: Theme) => ({
  urgent: theme.colorSet.severity.critical,
  critical: theme.colorSet.severity.high,
  serious: theme.colorSet.severity.meduim,
  medium: theme.colorSet.severity.low,
  minimal: theme.colorSet.severity.informational,
  analysing: theme.colorSet.severity.analysis,
});

export const refreshingTestsColors = {
  patch: colors.blue[600],
  config: colors.red[400],
};

export enum TooltipTitles {
  AllVulnerabilities = 'Total technical vulnerabilities discovered across your technology environment over a selected time period',
  VulnerabilitiesByCriticalHighSeverity = 'Total urgent and critical  vulnerabilities discovered across your technology environment over a selected time period',
  ActiveVulnerabilitiesBySeverity = 'Current Active vulnerabilities discovered across your technology environment by severity',
  NewVulnerabilities = 'Most recent urgent & critical vulnerabilities that have been discovered in your technology environment and the number of hosts affected',
  TopTenVulnerabilities = 'Current Top 10 active vulnerabilities, and the impact of these across your environment.  By addressing these as a group you could reduce your risk considerably',
  TopTenVulnerableHosts = 'Current Top 10 most vulnerable hosts within your environment sorted by criticality.  These hosts should be remediated as a priority. ',
}

export enum VulnerabilitiesTitles {
  AllVulnerabilities = 'Vulnerabilities',
  VulnerabilitiesByCriticalHighSeverity = 'Vulnerabilities by urgent & critical severity',
  ActiveVulnerabilitiesBySeverity = 'Vulnerabilities by severity',
  NewVulnerabilities = 'Emerging Threats',
  TopTenVulnerabilities = 'Top 10 vulnerabilities',
  TopTenVulnerableHosts = 'Top 10 vulnerable hosts',
  TopFiveVulnerabilities = 'Top 5 vulnerabilities',
  TopFiveMostVulnerableHosts = 'Top 5 most vulnerable hosts',
}

export const TOP_COUNT = 10;
export const FIXED_VALUE = 2;

export enum TotalRowKey {
  Impact = 'impact',
}

export const severityValues = {
  [Severity.Critical]: SeverityLevel._5,
  [Severity.High]: SeverityLevel._4,
  [Severity.Medium]: SeverityLevel._3,
  [Severity.Low]: SeverityLevel._2,
  [Severity.Info]: SeverityLevel._1,
  [Severity.Analysing]: SeverityLevel._0,
};

export const emptyMessageConfig = emptyMessage('No vulnerabilities yet');

export const emptyStateActiveVulnerabilitiesBySeverity: EmptyValues = {
  urgent: 0,
  critical: 0,
  minimal: 0,
  medium: 0,
  serious: 0,
};

export const emptyValuesVulnerabilitiesSeverity: EmptyValuesVulnerabilitiesSeverity = {
  critical: [
    {
      date: 0,
      value: 0,
    },
  ],
  high: [
    {
      date: 0,
      value: 0,
    },
  ],
};

export const emptyValuesAllVulnerabilities: EmptyValuesAllVulnerabilities = [
  {
    date: 0,
    value: 0,
  },
];

export const toggleButton = {
  title: 'Show potential vulnerabilities',
  tooltipTitle:
    'Potential Vulnerabilities include vulnerabilities that cannot be fully verified. In these cases, at least one necessary condition for the vulnerability has been detected.',
};

export const excludedVulnerabilitiesFilterOptions = [
  { label: 'Potential', value: 'potential' },
  { label: 'Suppressed', value: 'suppressed' },
] as OptionType[];
