import React from 'react';
import { VulnerabilityDetails } from 'services/api';
import {
  getCapitalizedString,
  getFormattedDate,
  getRenderValue,
  getTimeAgoLabel,
} from 'services/helpers';
import { ColumnsType } from 'antd/es/table/interface';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { Box } from 'shared';
import { ColumnKey, TableTitles } from './constants';
import { IconColoring } from '../../../ThreatDetection/components/RecentAlarms/IconColoring';
import { TagColoring } from '../../../Alarms/components/TagColoring';
import { ColumnDateWrapper } from '../../../Notifications/components/styled';

export const columns: ColumnsType<VulnerabilityDetails> = [
  {
    key: 'name',
    title: TableTitles.Name,
    dataIndex: 'name',
    render: (name: string) => getRenderValue(name, NO_VALUE_LABEL),
  },
  {
    key: ColumnKey.Risk,
    title: TableTitles.Risk,
    dataIndex: ColumnKey.Risk,
    render: (risk: string) =>
      !risk ? emptyDataString : <TagColoring title={getCapitalizedString(risk)} />,
  },
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    render: (status: string) => (
      <span>
        {status === null || status === undefined ? (
          emptyDataString
        ) : (
          <>
            <IconColoring status={status} />
            {status}
          </>
        )}
      </span>
    ),
  },
  {
    key: ColumnKey.Updated,
    title: TableTitles.Updated,
    dataIndex: ColumnKey.Updated,
    render: (date: string) => (
      <ColumnDateWrapper>
        {getFormattedDate(date)}
        <Box opacity='50%'>{getTimeAgoLabel(date)}</Box>
      </ColumnDateWrapper>
    ),
    align: 'right',
    className: 'right-head-title',
    width: 160,
  },
];
