import { createSlice } from '@reduxjs/toolkit';
import {
  Notification,
  NotificationsCreatedByListResponse,
  NotificationsGridResponse,
} from 'services/api';
import { newsNotificationsApi } from './thunks';

interface NewsNotificationsState {
  notificationGrid: NotificationsGridResponse;
  notificationUnreadGrid: NotificationsGridResponse;
  createdByList: null | string[];
  notificationDetails: Notification;
  isNotificationIconActive: boolean;
}

export const newsNotificationsInitialState: NewsNotificationsState = {
  notificationGrid: {},
  notificationUnreadGrid: {},
  createdByList: [],
  notificationDetails: {},
  isNotificationIconActive: false,
};

const updateNotificationGrid = (
  notificationGrid: Array<Notification>,
  updatedReadNotificationList?: string[],
) => {
  const copy = [...notificationGrid];

  if (updatedReadNotificationList?.length === 0) {
    notificationGrid.forEach((_, index) => {
      copy[index] = {
        ...copy[index],
        read: true,
      };
    });
  }

  updatedReadNotificationList?.forEach((updatedId) => {
    const index = notificationGrid?.findIndex((tag) => tag.id === updatedId);

    if (index !== undefined && index >= 0) {
      copy[index] = {
        ...copy[index],
        read: !copy[index].read,
      };
    }
  });

  return copy;
};

export const newsNotifications = createSlice({
  name: 'newsNotifications',
  initialState: newsNotificationsInitialState,
  reducers: {
    updatedNotificationGrid: (
      state: NewsNotificationsState,
      action: { payload: string[] | undefined },
    ) => {
      state.notificationGrid = {
        ...state.notificationGrid,
        rows: updateNotificationGrid(state.notificationGrid.rows || [], action?.payload),
      };
      state.notificationUnreadGrid = {
        ...state.notificationUnreadGrid,
        rows: updateNotificationGrid(state.notificationUnreadGrid.rows || [], action?.payload),
      };
    },
    cleanNotificationDetails: (state: NewsNotificationsState) => {
      state.notificationDetails = {};
    },
  },
  extraReducers: {
    [newsNotificationsApi.getNotificationGrid.fulfilled.toString()]: (
      state: NewsNotificationsState,
      action: { payload: NotificationsGridResponse },
    ) => {
      state.notificationGrid = action.payload;
    },
    [newsNotificationsApi.getNotificationGrid.rejected.toString()]: (
      state: NewsNotificationsState,
    ) => {
      state.notificationGrid = {};
    },
    [newsNotificationsApi.getUnreadNotificationGrid.fulfilled.toString()]: (
      state: NewsNotificationsState,
      action: { payload: NotificationsGridResponse },
    ) => {
      state.notificationUnreadGrid = action.payload;
    },
    [newsNotificationsApi.getUnreadNotificationGrid.rejected.toString()]: (
      state: NewsNotificationsState,
    ) => {
      state.notificationUnreadGrid = {};
    },
    [newsNotificationsApi.getCreatedByList.fulfilled.toString()]: (
      state: NewsNotificationsState,
      action: { payload: NotificationsCreatedByListResponse },
    ) => {
      state.createdByList = action.payload.createdBy ?? [];
    },
    [newsNotificationsApi.getCreatedByList.rejected.toString()]: (
      state: NewsNotificationsState,
    ) => {
      state.createdByList = [];
    },
    [newsNotificationsApi.getNotificationDetails.fulfilled.toString()]: (
      state: NewsNotificationsState,
      action: { payload: Notification },
    ) => {
      state.notificationDetails = action.payload;
    },
    [newsNotificationsApi.getNotificationDetails.rejected.toString()]: (
      state: NewsNotificationsState,
    ) => {
      state.notificationDetails = {};
    },
    [newsNotificationsApi.updateNotification.fulfilled.toString()]: (
      state: NewsNotificationsState,
      action: { payload: Notification },
    ) => {
      state.notificationDetails = action.payload;
    },
    [newsNotificationsApi.updateNotification.rejected.toString()]: (
      state: NewsNotificationsState,
    ) => {
      state.notificationDetails = {};
    },
  },
});
