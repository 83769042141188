import styled from 'styled-components';
import { Row } from 'antd';
import { ThemeProps } from 'services/interfaces';
import { Button, Card } from 'shared';
import { CardTitle, CardBody } from 'shared/Card/components/styled';

export const StyledRow = styled(Row)<ThemeProps>`
  margin-top: ${({ theme }: ThemeProps) => theme.sizes[5]};

  @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoints.md}) {
    margin-top: ${({ theme }: ThemeProps) => theme.sizes[4]};
  }
`;

export const StyledCard = styled(Card)<ThemeProps>`
  ${CardBody} {
    :after {
      border-bottom: none !important;
    }
  }

  ${CardTitle} {
    font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};

    @media (max-width: ${({ theme }: ThemeProps) => theme.breakpoints.md}) {
      max-width: 100px;
    }
  }
`;

export const StyledSeeAllVulnsLink = styled(Button)<ThemeProps>`
  display: block;
  font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
  padding-top: ${({ theme }: ThemeProps) => theme.sizes['2.5']};
  text-align: left;
  width: 100%;
`;
