import { useHistory } from 'react-router';
import { Routes } from 'services/entities';

export const useAgedHostsOrCmdbRoute = () => {
  const {
    location: { pathname },
  } = useHistory();

  const isAgedHostsPage = pathname.startsWith(Routes.CMDB_AGED_HOSTS);
  const pageRoute = isAgedHostsPage ? Routes.CMDB_AGED_HOSTS : Routes.CMDB;

  return {
    isAgedHostsPage,
    pageRoute,
  };
};
