import React from 'react';
import styled from 'styled-components';
import { sum } from 'lodash';
import { ReportChart } from './ReportChart';
import { Theme } from '../../../../../../../styles/theme/types';
import { Colors, SegmentedPieData, Values } from '../../../../../../../shared/charts/types';
import { ui } from '../../../../../../../app-constants/ui';
import { LabelFormatterParams } from '../../../../../../../shared/charts/interfaces';
import { getData } from '../../../../../../../shared/charts/SegmentedPie/helpers';
import { Legend } from './Legend';

interface Props {
  onChartLoaded: () => void;
  theme: Theme;
  colors: Colors;
  values: Values;
  entitiesName?: string;
  height: string;
  width: string;
}

export const Wrapper = styled.div`
  > div {
    background: transparent;
    border: none;

    margin-left: auto;
    margin-right: auto;
  }
`;

const getOption = ({
  values,
  theme,
  colors,
  entitiesName,
}: {
  values: Values;
  theme: Theme;
  colors?: Colors;
  entitiesName?: string;
  getCustomDataHandler?: (values: Values, colors?: Colors) => SegmentedPieData;
}) => {
  const totalValue = sum(Object.values(values));

  const seriesCommonSettings = {
    type: 'pie',
    radius: ['45%', '70%'],
    center: ['50%', '50%'],
  };

  const data = getData(values, colors);

  const percentageLabel = {
    ...seriesCommonSettings,
    name: 'layer1',
    labelLine: {
      length: 5,
    },
    label: {
      position: 'outside',
      formatter: (params: LabelFormatterParams) => `${Math.round(params.percent)}%`,
      alignTo: 'none',
      bleedMargin: 0,
      fontSize: 13,
      color: ui.colors.textColor,
    },
    data,
  };

  const formatter = [`{totalValue|${totalValue}}`];

  if (entitiesName) {
    formatter.push(`Total ${entitiesName.trim()}`.trim());
  }

  return {
    legend: { show: false },
    series: [
      {
        ...seriesCommonSettings,
        name: 'total',
        label: {
          position: 'center',
          formatter: formatter.join('\n'),
          rich: {
            textBorderWidth: 0,
            totalValue: {
              fontSize: 28,
              fontWeight: 900,
              lineHeight: 25,
            },
          },
          fontWeight: theme.fontWeights.normal,
          fontSize: 10,
          textBorderWidth: 0,
        },
        data,
      },
      percentageLabel,
    ],
    textStyle: {
      fontWeight: theme.fontWeights.bold,
    },
  };
};

export function SegmentReportChartWithLegend({
  onChartLoaded,
  theme,
  colors,
  values,
  height,
  width,
  entitiesName,
}: Props) {
  return (
    <Wrapper>
      <ReportChart
        onFinished={onChartLoaded}
        option={getOption({
          values,
          theme,
          colors,
          entitiesName,
        })}
        width={width}
        height={height}
      />
      <Legend values={values} colors={colors} />
    </Wrapper>
  );
}
