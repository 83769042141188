import React from 'react';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { BackLink, HeaderTagColoring } from 'shared';
import { backlinkRoutes, NO_VALUE_LABEL } from 'app-constants';
import { Link, SuppressionGridResponse, VulnerabilityStatus } from 'services/api';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import {
  getCapitalizedString,
  getParametersFromPath,
  isMoreThanNumberOfDaysAgo,
} from 'services/helpers';
import { TagVulnStatus } from 'shared/TagColoring/VulnStatus';
import { TagVulnSuppression } from 'shared/TagColoring/VulnSuppression';
import { LinkStyled } from './styled';
import { SuppressionMaxDaysNumber } from './constants';

export const getLayoutConfig = (
  title?: string,
  severity = '',
  status: VulnerabilityStatus | string = '',
  historyState?: HistoryState | undefined,
  pathname?: string,
  suppression?: SuppressionGridResponse,
  suppressionRef?: React.RefObject<HTMLDivElement>,
  isAsset?: boolean,
) => {
  return {
    title,
    backLink: (
      <BackLink
        historyState={historyState?.backTo?.route !== pathname ? historyState : undefined}
        defaultRoute={Routes.VULNERABILITIES_LIST}
        defaultTitle={backlinkRoutes[Routes.VULNERABILITIES_LIST]}
      />
    ),
    severity: <HeaderTagColoring title={getCapitalizedString(getSeverityLevelName(severity))} />,
    status: isAsset && status && (
      <TagVulnStatus title={getCapitalizedString(status)} style={{ marginLeft: 0 }} />
    ),
    vulnerabilitySuppression: suppression?.status && suppressionRef?.current && (
      <TagVulnSuppression
        status={suppression.status}
        style={{ marginLeft: 0, cursor: 'pointer' }}
        onClickHandler={() => suppressionRef.current?.scrollIntoView()}
        asButton
      />
    ),
  };
};

export const renderValue = (value: Link) => {
  if (value.url) {
    return (
      <LinkStyled href={value.url} key={value.id}>
        {value.id}
      </LinkStyled>
    );
  }

  return value.id || NO_VALUE_LABEL;
};

export const getVulnerabilitiesFilters = (searchString: string, isSuppressed?: boolean) => {
  const result: Record<string, unknown> = {};

  const searchParams = getParametersFromPath(searchString);

  if (!searchParams) {
    return result;
  }

  const searchFilter = (searchParams?.filter || {}) as Record<string, unknown>;

  if (!searchFilter) {
    return result;
  }

  if (searchFilter.cvssScore) {
    result.vulnerabilitiesCvssScore = searchFilter.cvssScore;
  }

  if (searchFilter.toFirstFound) {
    result.vulnerabilitiesTo = searchFilter.toFirstFound;
  }

  if (searchFilter.fromFirstFound) {
    result.vulnerabilitiesFrom = searchFilter.fromFirstFound;
  }

  if (isSuppressed) {
    result.suppressedVulnerabilities = true;
  }

  return result;
};

export const getSuppressionShouldBeReviewed = (date?: string) => {
  if (!date) {
    return false;
  }

  return isMoreThanNumberOfDaysAgo(SuppressionMaxDaysNumber, date);
};
