import React, { Ref } from 'react';
import { Tooltip } from 'antd';
import { icons } from 'styles/theme';
import { Box, Icon } from 'shared';
import { FilterType, SearchSettings } from 'shared/Table/types';
import { Filter, Filters } from 'services/api';
import { DebouncedInput } from 'shared/Table/components/DebouncedInput';
import {
  FilterActionsCol,
  FilterFieldsCol,
  FilterLabelCol,
  FiltersRow,
  FilterTitle,
  InputsRow,
  MoreFiltersButton,
  SearchCol,
  ShowMoreFiltersButtonCol,
  Spacer,
  TableFiltersContainer,
  TableFiltersContainerRow,
} from '../styled';
import { ResetFilter } from './ResetFilter';
import { FilterFactory } from './FilterFactory';
import { useApp } from '../../../store';

export type DesktopFiltersProps = {
  searchSettings?: SearchSettings;
  handleDebouncedInputChange: (value: string) => void;
  visibleFilterFields: FilterType[];
  hiddenFilterFields: FilterType[];
  isShowHiddenFilterFields: boolean;
  toggleShowHiddenFilterFields: () => void;
  isHiddenFilterFieldChecked: boolean;
  isShowResetButton: boolean;
  getSelectOrRangeValue: (name: string) => string[] | string | undefined;
  onFilterChange: (field: string, value: string[] | string, filterType: Filter.type) => void;
  clearAll: () => void;
  inputValue?: string;
  ref?: Ref<HTMLDivElement>;
  filterFields?: Filters | null;
  isResetFiltersClick?: boolean;
  hiddenFilters?: Filter[] | Record<string, unknown>;
  getSelectField: (name: string) => null | Filter;
};

export const DesktopFilters = React.forwardRef<HTMLDivElement, DesktopFiltersProps>(
  (
    {
      searchSettings,
      handleDebouncedInputChange,
      visibleFilterFields,
      hiddenFilterFields,
      isShowHiddenFilterFields,
      toggleShowHiddenFilterFields,
      isHiddenFilterFieldChecked,
      isShowResetButton,
      getSelectOrRangeValue,
      onFilterChange,
      clearAll,
      inputValue,
      filterFields,
      isResetFiltersClick,
      hiddenFilters,
      getSelectField,
    }: DesktopFiltersProps,
    ref,
  ) => {
    const paddingBottom = isShowHiddenFilterFields ? '69px' : '0';
    const isResetButtonVisible = isShowResetButton || !!hiddenFilters?.length;
    const { isTablet } = useApp();

    return (
      <TableFiltersContainer $isTablet={isTablet} $isSearchField={!!searchSettings} ref={ref}>
        <TableFiltersContainerRow gutter={12} justify='space-between'>
          {searchSettings && (
            <SearchCol $isFilterFields={!!visibleFilterFields.length}>
              <DebouncedInput
                searchSettings={searchSettings}
                onDebouncedInputChange={handleDebouncedInputChange}
                inputValue={inputValue}
                filterFields={filterFields || undefined}
                isResetFiltersClick={isResetFiltersClick}
              />
              {searchSettings.tooltip ? (
                <Box mb='6px' mt='6px' alignSelf='flex-start'>
                  <Tooltip title={searchSettings.tooltip} placement='right'>
                    <Icon component={icons.info_outline} dimension='27px' className='tooltipInfo' />
                  </Tooltip>
                </Box>
              ) : (
                <Spacer w='33px' />
              )}
            </SearchCol>
          )}

          {!!visibleFilterFields.length && (
            <FilterActionsCol>
              <FiltersRow gutter={12}>
                <FilterLabelCol style={{ paddingBottom }}>
                  <FilterTitle>Filter by:</FilterTitle>
                </FilterLabelCol>

                <FilterFieldsCol>
                  <InputsRow gutter={12}>
                    {visibleFilterFields.map((filterField: FilterType) => (
                      <FilterFactory
                        key={filterField.label}
                        filterField={filterField}
                        getValue={getSelectOrRangeValue}
                        onFilterChange={onFilterChange}
                        getSelectField={getSelectField}
                      />
                    ))}
                  </InputsRow>

                  {isShowHiddenFilterFields && !!hiddenFilterFields.length && (
                    <InputsRow gutter={12}>
                      {hiddenFilterFields.map((filterField: FilterType) => (
                        <FilterFactory
                          key={filterField.label}
                          filterField={filterField}
                          getValue={getSelectOrRangeValue}
                          onFilterChange={onFilterChange}
                          getSelectField={getSelectField}
                        />
                      ))}
                    </InputsRow>
                  )}
                </FilterFieldsCol>

                {!!hiddenFilterFields.length && (
                  <ShowMoreFiltersButtonCol>
                    <MoreFiltersButton
                      type='link'
                      onClick={toggleShowHiddenFilterFields}
                      checked={isHiddenFilterFieldChecked}
                    >
                      <span className='more-filter-btn-text'>More filters</span>

                      <Icon
                        className='arrow-dropdown'
                        component={
                          isShowHiddenFilterFields ? icons.arrow_drop_up : icons.arrow_drop_down
                        }
                      />
                    </MoreFiltersButton>
                  </ShowMoreFiltersButtonCol>
                )}
                <ResetFilter
                  isVisible={isResetButtonVisible}
                  clearAll={clearAll}
                  hasHiddenFilters={!!hiddenFilters?.length}
                />
              </FiltersRow>
            </FilterActionsCol>
          )}
        </TableFiltersContainerRow>
      </TableFiltersContainer>
    );
  },
);
