import { Box, Button, Icon } from 'shared';
import { icons } from 'styles/theme';
import { useUserPermissions } from 'pages/ServicesIntegrations/hooks';
import { useHistory, useParams } from 'react-router';
import { Routes } from 'services/entities';
import { useCallback, useState } from 'react';
import { useCustomer } from 'store';
import { DisconnectPopup } from '../DisconnectPopup';

const ActionButtonStyles = { marginLeft: '12px' };

type ActionButtonsProps = {
  isLoading?: boolean;
  connectedAt?: string;
  serviceName?: string;
};

export const HeaderActionButtons = ({
  connectedAt,
  isLoading,
  serviceName,
}: ActionButtonsProps) => {
  const { canSetupConnection } = useUserPermissions();
  const history = useHistory();
  const { id: serviceId } = useParams<{ id: string }>();
  const customerId = useCustomer();
  const [showPopup, setShowPopup] = useState(false);

  const openEditConnection = useCallback(() => {
    history.push(Routes.SERVICE_INTEGRATION_CONNECTION_EDIT.replace(':id', serviceId), {
      backTo: {
        route: Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', serviceId),
        search: history.location.search,
      },
      data: { serviceName },
    });
  }, [serviceId, history, serviceName]);

  if (!canSetupConnection) {
    return null;
  }

  return (
    <Box d='flex' alignItems='center' flexWrap='wrap' justifyContent='flex-end'>
      {connectedAt && (
        <Button
          type='link'
          icon={<Icon component={icons.edit} />}
          disabled={isLoading}
          onClick={openEditConnection}
        >
          Edit connection
        </Button>
      )}
      {connectedAt && (
        <Button
          type='link'
          icon={<Icon component={icons.close} />}
          style={ActionButtonStyles}
          disabled={isLoading}
          onClick={() => setShowPopup(true)}
        >
          Disconnect
        </Button>
      )}
      {!connectedAt && (
        <Button
          type='primary'
          style={ActionButtonStyles}
          disabled={isLoading}
          onClick={openEditConnection}
        >
          Connect now
        </Button>
      )}

      {showPopup && (
        <DisconnectPopup
          customerId={customerId}
          serviceId={serviceId}
          onCancel={() => setShowPopup(false)}
        />
      )}
    </Box>
  );
};
