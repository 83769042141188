import styled from 'styled-components';
import { Menu as AntdMenu } from 'antd';
import { Theme } from '../../styles/theme/types';

export const Menu = styled(AntdMenu)`
  .ant-dropdown-menu-item {
    padding: 10px 15px;
  }

  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-active.ant-dropdown-menu-item-danger {
    background-color: ${({ theme }: { theme: Theme }) =>
      `${theme.colors.green[200]}${theme.opacity[12]}`};
  }

  .ant-dropdown-menu-item-danger {
    color: ${({ theme }: { theme: Theme }) => theme.colors.red[400]};
  }

  .ant-dropdown-menu-item-active.ant-dropdown-menu-item-danger {
    color: ${({ theme }: { theme: Theme }) => theme.colors.red[400]};
  }

  .anticon {
    margin-top: -2px;

    svg {
      fill: ${({ theme }: { theme: Theme }) => theme.colors.gray[300]};
    }
  }
`;

export const CustomMenuItem = styled(Menu.Item)`
  align-items: start;
  display: flex;
  min-height: 100% !important;
  min-width: 255px !important;
  padding: 0 !important;
`;
