import React from 'react';
import { Divider, Form as AntForm } from 'antd';
import moment, { Moment } from 'moment';
import { isEmptyObject } from 'services/helpers';
import { dateFormat } from 'app-constants';
import { DatePicker } from 'shared/DatePicker/styled';
import { Button } from 'shared';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch, useApp, useCustomer } from 'store';
import { emptyFile } from 'shared/UploadPopup/constants';
import { BtnWrapper, ExpiryDate, FormContainer, InputStyled } from '../../styled';
import { DataForm, IValuesForm } from '../../types';
import { Uploader } from '../Uploader';
import { onSubmitForm } from './onSubmitForm';
import { getCssVar } from '../../../../styles/theme';

interface IProps {
  hideModal: () => void;
  preparedDataForm?: DataForm;
  setPreparedDataForm?: (value: DataForm) => void;
  title?: string;
}

export const Form = ({ hideModal, preparedDataForm, setPreparedDataForm, title }: IProps) => {
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const dispatch: AppDispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [attachedFile, setAttachedFile] = React.useState<File>(emptyFile);
  const [isFileAttached, setIsFileAttached] = React.useState(false);

  const isDataFormExist = !isEmptyObject(preparedDataForm);

  const initialValues: IValuesForm = {
    vendor: preparedDataForm?.vendor,
    date: isDataFormExist ? moment(preparedDataForm?.date) : null,
  };

  const validationSchema = Yup.object({
    vendor: Yup.string().trim().required('Vendor is a required field'),
    date: Yup.string().required('Expiration date is a required field').nullable(),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      onSubmitForm({
        values,
        setLoading,
        isFileAttached,
        dispatch,
        customerId,
        title,
        hideModal,
        attachedFile,
      }),
  });

  const onReset = React.useCallback(() => {
    resetForm({
      values: {
        vendor: '',
        date: undefined,
      },
    });
  }, [resetForm]);

  React.useEffect(() => {
    return () => {
      onReset();
      if (setPreparedDataForm) {
        setPreparedDataForm({} as DataForm);
      }
    };
  }, [onReset, setPreparedDataForm]);

  const onPickDate = (value: Moment | null) => setFieldValue('date', value?.valueOf());

  return (
    <AntForm layout='vertical' onFinish={handleSubmit} initialValues={initialValues}>
      <p style={{ color: getCssVar('textColor') }}>
        Improve your cyber stress score by providing your certification details
      </p>
      <FormContainer>
        <Uploader
          isFileAttached={isFileAttached}
          attachedFile={attachedFile}
          setAttachedFile={setAttachedFile}
          setIsFileAttached={setIsFileAttached}
        />
        <InputStyled
          label='Vendor'
          type='text'
          name='vendor'
          value={values.vendor}
          onChange={handleChange}
          onBlur={handleBlur}
          validateStatus={errors.vendor && touched.vendor ? 'error' : 'success'}
          help={errors.vendor && touched.vendor ? errors.vendor : undefined}
          required
        />
        <ExpiryDate
          name='date'
          label='Expiration date'
          validateStatus={errors.date && touched.date ? 'error' : 'success'}
          help={errors.date && touched.date ? errors.date : undefined}
          required
        >
          <DatePicker
            onChange={onPickDate}
            onBlur={handleBlur}
            format={dateFormat}
            inputReadOnly={isMobile}
          />
        </ExpiryDate>
      </FormContainer>
      <Divider />
      <BtnWrapper>
        <Button htmlType='reset' onClick={hideModal}>
          Cancel
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          loading={loading}
          disabled={!isValid || !values.date || !values.vendor}
        >
          Confirm certification
        </Button>
      </BtnWrapper>
    </AntForm>
  );
};
