import { DPLDocumentType, Filter, OrderBy } from 'services/api';
import { InputType } from '../../services/api/models/InputType';

export const initialOrderBy = {
  field: '',
  type: OrderBy.type.DESC,
};

export const allowedExtensions = [
  'application/pdf',
  '.docx',
  '.doc',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/jpg',
  'image/jpeg',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
].join(',');

export const dplDocumentTypesOptions = [
  { value: DPLDocumentType.GUIDES, label: 'Guides' },
  { value: DPLDocumentType.CHECKLISTS, label: 'Checklists' },
  { value: DPLDocumentType.POLICIES, label: 'Policies' },
  { value: DPLDocumentType.NOTICES, label: 'Notices' },
  { value: DPLDocumentType.OTHER, label: 'Other' },
];

export const searchSettings = {
  placeholder: 'Search by document name',
  fields: ['name'],
  min: 3,
};

export const filtersFields = [
  {
    field: 'documenttype',
    options: dplDocumentTypesOptions,
    label: 'Document type',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];
