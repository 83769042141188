import React from 'react';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import { backlinkRoutes } from 'app-constants/routes';
import { Routes } from 'services/entities';
import {
  SupportRequestsImpact,
  SupportRequestsPriority,
  SupportRequestsStatus,
} from 'services/api';
import { BackLink, Button, InnerLayoutProps as InnerLayout } from 'shared';
import { StatusLevel } from 'services/constants';
import { ServiceRequestType } from '../../SupportRequestForm/constants';
import { HistoryState } from '../../../services/interfaces';

export const getLayoutConfig = (
  servicePermission: boolean,
  isButtonLoading: boolean,
  downloadCSV: () => void,
  loading: boolean,
  isDesktop?: boolean,
  historyState?: HistoryState,
): InnerLayout => ({
  title: 'Support requests',
  downloadDoc: isDesktop
    ? [
        {
          id: 1,
          title: 'Download CSV',
          onDownload: downloadCSV,
          loading,
        },
      ]
    : undefined,
  backLink: <BackLink preferRoot historyState={historyState} />,
  isBeforeToggleComponent: isButtonLoading ? (
    <Spin />
  ) : (
    servicePermission && (
      <Button
        type='primary'
        style={{ marginTop: isDesktop ? 0 : '10px', marginRight: isDesktop ? 0 : '-10px' }}
      >
        <Link
          style={{ textDecoration: 'none' }}
          to={{
            pathname: `${Routes.SERVICE_REQUEST}`,
            search: `?type=${ServiceRequestType.Service}`,
            state: {
              backTo: {
                title: `Back to ${backlinkRoutes[Routes.SUPPORT_REQUESTS]}`,
                route: Routes.SUPPORT_REQUESTS,
              },
            },
          }}
        >
          Raise service request
        </Link>
      </Button>
    )
  ),
});

export const getLabelPriority = (numberOfPriority: string) => {
  switch (numberOfPriority) {
    case SupportRequestsPriority._1:
      return SupportRequestsImpact.HIGH;
    case SupportRequestsPriority._2:
      return SupportRequestsImpact.MEDIUM;
    case SupportRequestsPriority._3:
      return SupportRequestsImpact.LOW;
    default:
      return SupportRequestsImpact.LOW;
  }
};

export const getNormalizedStatus = (status: string) => {
  if (status === SupportRequestsStatus.RESOLVED || status === SupportRequestsStatus.CLOSED) {
    return status.toLowerCase();
  }

  if (status.trimEnd() === SupportRequestsStatus.WITH_CUSTOMER) {
    return SupportRequestsStatus.WITH_CUSTOMER;
  }

  return StatusLevel.Open.toLowerCase();
};
