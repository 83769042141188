/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SupportRequestsStatus {
  ACTIVE = 'Active',
  WITH_NORM = 'With norm',
  RESOLVED = 'Resolved',
  CLOSED = 'Closed',
  WITH_CUSTOMER = 'With Customer',
}
