import * as React from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { OuterLayout } from 'shared';
import { RestorePasswordSteps, Routes } from 'services/entities';
import { AppState, logoutAction } from 'store';
import { useTheme } from 'styled-components';
import { getLayoutProps } from './helpers';
import { ResetForm } from './components/ResetForm';

export const ResetPassword = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const step = useSelector(({ auth }: AppState) => auth.restorePassword.step);
  const layoutProps = getLayoutProps(RestorePasswordSteps.RESET, '', theme);

  React.useEffect(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  if (step === RestorePasswordSteps.DONE) {
    return <Redirect to={Routes.ROOT} />;
  }

  return (
    <OuterLayout {...layoutProps}>
      <ResetForm />
    </OuterLayout>
  );
};
