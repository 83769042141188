import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { useRowGap } from 'services/hooks/useRowGap';
import { Box, InnerLayout } from 'shared';
import { AppDispatch, MessageType, newsNotificationsApi, useApp, useCustomer } from 'store';
import { useHistory, useParams } from 'react-router';
import { loadingNotificationMessage } from 'app-constants';
import { useTheme } from 'styled-components';
import { getCardPadding, getHeadStyles, getLayoutConfig } from './helpers';
import { StyledCard } from './styled';
import { Spacer } from '../../shared/TableFilters/styled';
import { Form } from '../../shared/Notification/Form';
import {
  CreateNotificationRequest,
  Notification,
  NotificationStatus,
  UpdateNotificationRequest,
} from '../../services/api';
import { redirectToNotificationDetails, showPopup } from '../Notifications/helpers';
import { popupConfig } from '../Notifications/constant';
import { RequestStatus } from '../../store/loading/slice';
import { showError, showMessage } from '../../services/helpers';
import { PopupConfigType } from '../../shared/ActionPopup/types';
import { onCreateMessage, UpdateNotification } from './constants';
import { useUpdateData } from './hooks/useUpdateData';

export const EditNotification = () => {
  const theme = useTheme();
  const { gapNumber } = useRowGap();
  const { isMobile } = useApp();
  const customerId = useCustomer();
  const history = useHistory();
  const { id: notificationId } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const updatedData = useUpdateData(notificationId);
  const { type, title, message } = updatedData as Notification;
  const layoutConfig = getLayoutConfig(UpdateNotification.EDIT);

  const successMessage = useCallback(
    (notificationStatus: NotificationStatus) =>
      showMessage(
        {
          content: onCreateMessage[notificationStatus],
        },
        MessageType.Success,
      ),
    [],
  );

  const initialValues: CreateNotificationRequest = useMemo(
    () => ({
      type,
      title,
      message,
      status: undefined,
    }),
    [type, title, message],
  );

  const onSubmitForm = useCallback(
    async (values: UpdateNotificationRequest) => {
      const requestBody = { ...values };
      const notificationStatus = requestBody.status as NotificationStatus;

      if (requestBody.status === NotificationStatus.PUBLISHED) {
        showPopup({
          popupId: popupConfig.updateAndPublish.id,
          setPopupDetails,
          setIsVisiblePopup,
          notificationDetails: { ...requestBody, id: notificationId },
          dispatch,
          customerId,
          onSuccess: () => successMessage(notificationStatus),
          history,
        });

        return;
      }

      showMessage(loadingNotificationMessage, MessageType.Loading);
      dispatch(
        newsNotificationsApi.updateNotification({ customerId, notificationId, requestBody }),
      ).then((result) => {
        if (result.meta.requestStatus === RequestStatus.Rejected) {
          showError();

          return;
        }

        redirectToNotificationDetails(history, updatedData.id as string);
        successMessage(notificationStatus);
      });
    },
    [dispatch, customerId, notificationId, successMessage, history, updatedData.id],
  );

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} lg={24} xs={24} order={isMobile ? 2 : 1}>
          <StyledCard
            headStyles={getHeadStyles(isMobile, theme)}
            bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
            cardPadding={getCardPadding(isMobile, theme)}
          >
            <Box fontSize='20px' fontWeight={700}>
              Notification
            </Box>
            <Spacer h='20px' />
            <Form
              initialValues={initialValues}
              onSubmitForm={onSubmitForm}
              isVisiblePopup={isVisiblePopup}
              popupDetails={popupDetails}
              setIsVisiblePopup={setIsVisiblePopup}
            />
          </StyledCard>
        </Col>
      </Row>
    </InnerLayout>
  );
};
