import { Routes } from 'services/entities';
import { icons } from 'styles/theme';
import { MenuItem } from '../types';
import { Filter, NotificationStatus, OrderBy } from '../../../../services/api';

export const submenusChildren: { [x: string]: Routes[] } = {
  '/technology': [
    Routes.VULNERABILITY_MANAGEMENT,
    Routes.THREAT_DETECTION,
    Routes.PATCH_MANAGEMENT,
  ],
  [Routes.DOCUMENTS]: [Routes.DOCUMENTS, Routes.DATA_PROTECTION_LIBRARY],
  '/certificates': [Routes.PROCESS, Routes.CE_PLUS],
};

export const menuItems: MenuItem[] = [
  {
    path: Routes.DASHBOARD,
    name: 'Dashboard',
    icon: icons.home,
  },
  {
    path: '/certificates',
    name: 'Process',
    icon: icons.cached,
    children: [
      {
        path: Routes.PROCESS,
        name: 'Certifications',
      },
      {
        path: Routes.CE_PLUS,
        name: 'Cyber Essentials dashboard',
      },
    ],
  },
  {
    path: Routes.PEOPLE,
    name: 'People',
    icon: icons.people,
  },
  {
    path: '/technology',
    name: 'Technology',
    icon: icons.memory,
    children: [
      {
        path: Routes.THREAT_MAP,
        name: 'Threat map',
      },
      {
        path: Routes.VULNERABILITY_MANAGEMENT,
        name: 'Vulnerability management',
      },
      {
        path: Routes.PATCH_MANAGEMENT,
        name: 'Patch management',
      },
      {
        path: Routes.THREAT_DETECTION,
        name: 'Threat detection',
      },
    ],
  },
  {
    path: Routes.CMDB,
    name: 'CMDB',
    icon: icons.storage,
    children: [
      {
        path: Routes.CMDB,
        name: 'CMDB',
      },
      {
        path: Routes.CMDB_AGED_HOSTS,
        name: 'Aged hosts',
      },
    ],
  },
  {
    path: Routes.SUPPORT_REQUESTS,
    name: 'Support requests',
    icon: icons.live_help,
  },
  {
    path: Routes.DOCUMENTS,
    name: 'Documents',
    icon: icons.description,
    children: [
      {
        path: Routes.DOCUMENTS,
        name: 'General documents',
      },
      {
        path: Routes.DATA_PROTECTION_LIBRARY,
        name: 'Data protection library',
      },
    ],
  },
  {
    path: Routes.SOFTWARE,
    name: 'Software',
    icon: icons.download,
  },
];

export const menuItemsAdmin = [
  {
    path: Routes.USERS,
    name: 'Users',
    icon: icons.people,
  },
  {
    path: Routes.TAGS_MANAGEMENT,
    name: 'Tags management',
    icon: icons.tagsManagement,
  },
  {
    path: Routes.SECURITY_SETTINGS,
    name: 'Security settings',
    icon: icons.security_settings,
  },
  {
    path: Routes.AUDIT_LOG,
    name: 'Audit log',
    icon: icons.verified,
  },
  {
    path: Routes.MAINTENANCE_SETTINGS,
    name: 'Maintenance settings',
    icon: icons.maintenanceSettings,
  },
  {
    path: Routes.SERVICES_INTEGRATIONS,
    name: 'Integrations',
    icon: icons.servicesIntegrations,
  },
];

export const submenus = Object.keys(submenusChildren);

export const notificationFilterFields = {
  orderBy: { field: 'published', type: OrderBy.type.DESC },
  pagination: { count: 1000, page: 1 },
  filter: {
    fields: [
      { name: 'status', value: [NotificationStatus.PUBLISHED], type: Filter.type.MULTIPLE },
      {
        name: 'read',
        value: 'false',
        type: Filter.type.VALUE,
      },
    ],
  },
};
