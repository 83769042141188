export enum Domains {
  Norm = 'normcyber.com',
  Nasstar = 'masstar.com',
}

export enum Themes {
  Norm = 'norm',
  Nasstar = 'nasstar',
  DarkNorm = 'darkNorm',
  System = 'system',
}

export interface IloginBackground {
  themeName?: Themes;
  isMobile?: boolean;
  width?: string;
  isTablet?: boolean;
}
