import React from 'react';
import { Col } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { useApp } from 'store';
import { getScore } from 'services/helpers';
import { Card } from 'shared';
import { useTheme } from 'styled-components';
import { StyledRow } from './styled';
import { TooltipTitles } from '../../constants';
import { ThreatDetectionCard } from './ThreatDetectionCard';
import { VulnerabilityManagementCard } from './VulnerabilityManagementCard';

interface TechnologyProps {
  customerId: string;
  score?: number;
  scoreLoading?: boolean;
}

const ColResponsiveProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 12,
};

const DESKTOP_GUTTER = 20;
const MOBILE_GUTTER = 16;

export const Technology = ({ customerId, score, scoreLoading }: TechnologyProps) => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const gutter: [Gutter, Gutter] = !isMobile
    ? [DESKTOP_GUTTER, DESKTOP_GUTTER]
    : [MOBILE_GUTTER, MOBILE_GUTTER];

  return (
    <Card
      title='Technology'
      valueMini={getScore(score)}
      isValueMiniLoading={scoreLoading}
      isHiddable={isMobile}
      tooltipTitle={TooltipTitles.Technology}
      headStyles={
        !isMobile
          ? { pb: theme.spacing[4], titlePaddingRight: theme.spacing['2.5'] }
          : { pb: theme.spacing[0], titlePaddingRight: theme.spacing[0] }
      }
      bodyPadding={theme.spacing[0]}
      cardPadding={
        !isMobile
          ? `${theme.spacing[6]} ${theme.spacing['5.5']} ${theme.spacing[8]}`
          : `${theme.spacing[1]} ${theme.spacing[5]} ${theme.spacing[2]} ${theme.spacing[5]}`
      }
      showDivider={!isMobile}
    >
      <StyledRow gutter={gutter}>
        <Col {...ColResponsiveProps}>
          <VulnerabilityManagementCard customerId={customerId} isMobile={isMobile} />
        </Col>
        <Col {...ColResponsiveProps}>
          <ThreatDetectionCard customerId={customerId} isMobile={isMobile} />
        </Col>
      </StyledRow>
    </Card>
  );
};
