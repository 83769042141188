import React from 'react';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { Routes } from '../../services/entities';
import { backlinkRoutes } from '../../app-constants';
import { HistoryState } from '../../services/interfaces';

export const getLayoutConfig = ({ customComponent }: InnerLayout, historyState?: HistoryState) => {
  return {
    title: 'Patches',
    customComponent,
    backLink: (
      <BackLink
        historyState={historyState}
        defaultRoute={Routes.PATCH_MANAGEMENT}
        defaultTitle={backlinkRoutes[Routes.PATCH_MANAGEMENT]}
      />
    ),
  };
};
