import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page, SidebarTextBlock } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide10.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const Image = styled.img`
  height: 50px;
  position: absolute;
  width: 50px;
`;

export const Encryption = styled.div`
  position: absolute;
  right: 450px;
  top: 310px;
`;

export const MultiFactorAuthentication = styled(Encryption)`
  right: 365px;
`;

export const EmailSecurity = styled(Encryption)`
  top: 390px;
`;

export const MobileDeviceManagement = styled(EmailSecurity)`
  right: 365px;
`;

export const RestEasyTextBlock = styled(SidebarTextBlock)`
  color: #fff;
  top: 200px;
`;
