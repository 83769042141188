import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page, SidebarTextBlock } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide7.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
  font-size: 13px;
`;

export const TechnologyVulnerabilityManagementDescription = styled(SidebarTextBlock)`
  top: 180px;
`;

export const TechnologyVulnerabilityManagementPieChartWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 20px;
`;

export const VulnerabilityManagementWrapper = styled.div`
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
  padding-right: 15px;
  text-align: right;

  .negative {
    color: #9c2b23;
  }

  .positive {
    color: #43a047;
  }
`;

export const AverageTimeToRemediate = styled.p`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 63px;
  left: 532px;
  line-height: 1;
  padding: 5px 0;
  position: absolute;
  text-align: center !important;
  top: 296px;
  width: 63px;

  span {
    display: block;
    font-size: 26px;
    margin-bottom: 5px;
  }
`;

export const PatchesVsConfiguration = styled.span`
  font-weight: 600;
  left: 386px;
  position: absolute;
  top: 60px;
`;

export const AllVulnerabilities = styled.span`
  font-weight: 600;
  left: 633px;
  position: absolute;
  top: 60px;
`;

export const AverageTimeTitle = styled.span`
  font-size: 11px;
  font-weight: 600;
  left: 385px;
  max-width: 120px;
  position: absolute;
  top: 306px;
`;

export const PatchesVsConfigurationPieWrapper = styled.div`
  left: 370px;
  position: absolute;
  text-align: center;
  top: 60px;
`;

export const MultiColumnsWrapper = styled.div`
  left: 634px;
  position: absolute;
  top: 88px;
`;

export const TechnologyVulnerabilityListWrapper = styled.div`
  max-width: 280px;
  position: absolute;
  top: 390px;

  h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  ul {
    font-size: 13px;
    list-style: none;

    > li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`;

export const MustVulnerableHosts = styled(TechnologyVulnerabilityListWrapper)`
  left: 405px;
`;

export const TopUserSystemModels = styled(TechnologyVulnerabilityListWrapper)`
  left: 735px;
  max-width: 250px;

  li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
