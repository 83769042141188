import { Card, Box, Icon, Switch } from 'shared';
import { useTheme } from 'styled-components';
import { useApp, servicesIntegrationsApi, AppDispatch, PayloadActionType } from 'store';
import { ServiceIntegrationListItem, ServiceIntegrationResponse } from 'services/api';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { RequestStatus } from 'store/loading/slice';
import { showError, showSuccess } from 'services/helpers';
import { serviceIcons } from '../../icons';
import * as S from './ServiceCard.styled';
import { Status } from './Status';
import { ConfigureIntegrationLink } from './ConfigureIntegrationLink';
import { formatDate } from '../../helpers';

type ServiceCardProps = ServiceIntegrationListItem & {
  canEnableService: boolean;
  showToggle?: boolean;
  isLoading?: boolean;
};

export const ServiceCard = ({
  serviceName = '',
  enabled = false,
  status = false,
  connectedAt = '',
  customerId = '',
  canEnableService = false,
  showToggle = false,
  id = '',
  isLoading = false,
}: ServiceCardProps) => {
  const { sizes } = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const [cardState, updateCardState] = useState({ enabled, loading: false });

  const connectedDate = formatDate(connectedAt);
  const shouldRenderIcon = !!serviceIcons[serviceName];

  const { isMobile } = useApp();
  const cardPadding = !isMobile
    ? `${sizes[4]} ${sizes['5.5']} ${sizes[8]} ${sizes['5.5']}`
    : `${sizes[5]} ${sizes[4]} ${sizes[4]} ${sizes[4]}`;

  const onToggleChangeHandler = async () => {
    const currentStatus = cardState.enabled;

    updateCardState((state) => ({ enabled: !state.enabled, loading: true }));

    const result = (await dispatch(
      servicesIntegrationsApi.enableService({
        customerId,
        requestBody: { serviceName, enabled: !currentStatus },
      }),
    )) as PayloadActionType<ServiceIntegrationResponse>;

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      showError();
      updateCardState((state) => ({ enabled: !state.enabled, loading: false }));
    } else {
      await dispatch(servicesIntegrationsApi.getServices({ customerId }));

      showSuccess(`Integration has been ${result.payload.enabled ? 'enabled' : 'disabled'}`);

      updateCardState((state) => ({ ...state, loading: false }));
    }
  };

  return (
    <Card bodyPadding='0' cardPadding={cardPadding} showLoader={cardState.loading || isLoading}>
      <Status isConnected={status} />

      <S.LastUpdatedWrapper>
        <Box visibility={connectedDate ? 'visible' : 'hidden'}>Last updated {connectedDate}</Box>
      </S.LastUpdatedWrapper>

      <S.TitleWrapper>
        <S.TitleAndIconContainer>
          {shouldRenderIcon && (
            <S.IconWrappaer>
              <Icon component={() => serviceIcons[serviceName]} />
            </S.IconWrappaer>
          )}
          <S.Title>{serviceName}</S.Title>
        </S.TitleAndIconContainer>
      </S.TitleWrapper>

      <S.Divider />

      {showToggle && (
        <S.ToggleWrapper>
          <Switch
            size='small'
            onChange={onToggleChangeHandler}
            checked={cardState.enabled}
            disabled={!canEnableService}
          />
          <S.ToggleText disabled={!canEnableService}>
            {cardState.enabled ? 'Disable for customer' : 'Enable for customer'}
          </S.ToggleText>
        </S.ToggleWrapper>
      )}

      <ConfigureIntegrationLink id={id} />
    </Card>
  );
};
