import { useCallback, useEffect, useRef } from 'react';
import { Stepper } from 'shared/Stepper/Stepper';
import { StepperItem, StepperWrapper } from 'shared/Stepper/RollUpStepper';
import { useApp } from 'store';
import { StepperText } from './styled';

type Props = {
  items: Array<{ value: string; link?: string; isActive: boolean }>;
};

export function AlarmStepper({ items }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const { isMobile } = useApp();

  useEffect(() => {
    const index = items.findIndex(({ isActive }) => isActive);

    if (index === -1) {
      ref.current?.scrollTo(0, 0);

      return;
    }

    const element = ref.current?.children[index] as HTMLDivElement;
    const offset = 25;

    ref.current?.scrollTo(element.offsetLeft - element.offsetWidth / 2 - offset, 0);
  }, [items]);

  const available = items.findIndex(({ isActive }) => isActive) >= 0;

  const onClickHandler = useCallback(
    (isActive, index, link) => {
      if (isActive) {
        window.open(link, '_blank', 'noreferrer');

        return;
      }

      const data = ref.current?.children as HTMLCollection;
      const element = data?.[index] as HTMLDivElement;

      if (isMobile) {
        if (element.classList.contains('focus')) {
          window.open(link, '_blank', 'noreferrer');

          return;
        }

        element.classList.add('focus');

        return;
      }

      element.classList.add('focus');
      window.open(link, '_blank', 'noreferrer');
    },
    [isMobile],
  );

  const onHoverHandler = useCallback((isActive, index) => {
    if (isActive) {
      return;
    }

    Array.from<Element>(ref.current?.children as HTMLCollection).forEach((element, childIndex) => {
      if (index === childIndex && element.classList.contains('focus')) {
        return;
      }

      element.classList.remove('focus');
    });
  }, []);

  return (
    <StepperWrapper available={available}>
      <Stepper ref={ref}>
        {items.map(({ value, isActive, link }, index) => (
          <StepperItem
            key={value}
            active={isActive}
            onClick={() => {
              onClickHandler(isActive, index, link);
            }}
            onHover={() => {
              onHoverHandler(isActive, index);
            }}
          >
            {value}
          </StepperItem>
        ))}
      </Stepper>
      {!available && <StepperText>There are no MITRE ATT&CK information available</StepperText>}
    </StepperWrapper>
  );
}
