import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, OuterLayout } from 'shared';
import { AppState, auth } from 'store';
import { RestorePasswordSteps } from 'services/entities';
import { useTheme } from 'styled-components';
import { getLayoutProps } from './helpers';
import { InitialForm } from './components/InitialForm';
import { ResetForm } from './components/ResetForm';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { step, email } = useSelector(({ auth }: AppState) => auth.restorePassword);
  const layoutProps = getLayoutProps(step, email, theme);

  React.useEffect(() => {
    dispatch(auth.actions.setRestorePasswordStep(RestorePasswordSteps.INITIAL));
  }, [dispatch]);

  const getContent = (step: number) => {
    switch (step) {
      case RestorePasswordSteps.RESET:
        return <ResetForm />;
      case RestorePasswordSteps.INSTRUCTIONS_SENT:
        return (
          <Box textAlign='center'>
            You’ll receive this email within 5 minutes.
            <br />
            Be sure to check your spam folder, too.
          </Box>
        );
      default:
        return <InitialForm />;
    }
  };

  return <OuterLayout {...layoutProps}>{getContent(step)}</OuterLayout>;
};
