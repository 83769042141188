import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatchVendorSeverity } from 'services/api';
import { defaultVendorSeverityValue } from 'shared/PmSeverityFilter/constants';

interface InitialState {
  severities: PatchVendorSeverity[];
}

const initialState: InitialState = {
  severities: defaultVendorSeverityValue,
};

export const vendorSeverity = createSlice({
  name: 'vendorSeverity',
  initialState,
  reducers: {
    setSeverities: (state: InitialState, action: PayloadAction<PatchVendorSeverity[]>) => {
      state.severities = action.payload;
    },
  },
});
