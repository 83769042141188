import React from 'react';
import { useDispatch } from 'react-redux';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { Box, Button, Input } from 'shared';
import { Popup } from 'shared/ActionPopup/styled';
import { PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, MessageType, useCustomer, useLoading } from 'store';
import { RequestStatus } from 'store/loading/slice';
import { SuccessResponse } from 'services/api';
import { showError, showMessage } from 'services/helpers';
import { ISimpleInputFields } from 'shared/inputs/Input/types';
import { Form } from 'antd';
import { getCreateModalButtonLabel, getCreateModalTitle } from '../helpers';
import {
  createTagFields,
  defaultFieldsValues,
  FieldNames,
  FieldValues,
  validationSchema,
} from '../constant';
import { CreateModalMode } from '../../Users/types';
import { tagsManagementApi } from '../../../store/tagsManagement/thunks';

type CreateUserPopupProps = {
  isVisible: boolean;
  mode: CreateModalMode;
  editFieldValues?: FieldValues;
  setIsVisible: (value: boolean) => void;
  onSuccess: () => void;
};

export const CreateTagPopup = ({
  isVisible,
  setIsVisible,
  mode,
  editFieldValues,
  onSuccess,
}: CreateUserPopupProps) => {
  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();
  const loading = useLoading([tagsManagementApi.createTag.typePrefix]);

  const onCancel = React.useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const createTagOnSubmit = React.useCallback(
    async ({ name }) => {
      const api = tagsManagementApi.createTag({ customerId, requestBody: { name: name?.trim() } });

      const result = (await dispatch(api)) as PayloadAction<
        SuccessResponse,
        string,
        { requestStatus: string }
      >;

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        showError(
          'Tag Already Exist. The tag name you entered is already in use. Please choose a different name to ensure uniqueness and avoid conflicts with existing tags.',
        );

        return;
      }

      showMessage(
        {
          content: `Tag has been successfully created.`,
        },
        MessageType.Success,
      );
      onSuccess();
      onCancel();
    },
    [customerId, dispatch, onSuccess, onCancel],
  );

  const formik = useFormik({
    initialValues: editFieldValues || defaultFieldsValues,
    validationSchema,
    onSubmit: (values: FieldValues) => createTagOnSubmit(values),
  });

  return (
    <Popup
      className='actions-modal'
      title={getCreateModalTitle(mode)}
      visible={isVisible}
      onCancel={onCancel}
      confirmLoading={loading[tagsManagementApi.createTag.typePrefix]}
      okButtonProps={{ disabled: !formik.dirty || !formik.isValid }}
      okText={getCreateModalButtonLabel(mode)}
      onOk={() => formik.handleSubmit()}
      footer={[
        <Button key='back' onClick={() => setIsVisible(false)}>
          Cancel
        </Button>,
        <Button
          type='primary'
          key='submit'
          disabled={!formik.dirty || !formik.isValid}
          onClick={() => formik.handleSubmit()}
          loading={loading[tagsManagementApi.createTag.typePrefix]}
        >
          {getCreateModalButtonLabel(mode)}
        </Button>,
      ]}
    >
      <Box mb='25px'>Create new tag to be used for the assets</Box>
      <Form layout='vertical' onFinish={formik.handleSubmit} initialValues={formik.initialValues}>
        {createTagFields.map((field: ISimpleInputFields) => {
          return (
            <Box key={field.name}>
              <Input
                id={field.name}
                name={field.name}
                label={field.label}
                onChange={formik.handleChange}
                placeholder={field.placeholder}
                value={formik.values[field.name as FieldNames]}
                maxLength={field.maxLength}
                validateStatus={
                  formik.errors[field.name as keyof FormikErrors<FieldValues>] &&
                  formik.touched[field.name as keyof FormikTouched<FieldValues>]
                    ? 'error'
                    : 'success'
                }
                help={formik.errors[field.name as keyof FormikErrors<FieldValues>]}
                onBlur={formik.handleBlur}
                required
              />
            </Box>
          );
        })}
      </Form>
    </Popup>
  );
};
