import { createAsyncThunk } from '@reduxjs/toolkit';
import { DPLDocumentCategory } from 'services/api/models/DPLDocumentCategory';
import {
  ApiError,
  CustomerId,
  DataProtectionLibraryService,
  DPLDocumentType,
  Filename,
  GridRequestParams,
  Timestamp,
} from '../../services/api';

const fetchTableContent = createAsyncThunk('dpl/fetch', async (requestBody: GridRequestParams) => {
  try {
    const data = await DataProtectionLibraryService.getDplDocuments(requestBody);

    return data;
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});

const getCategories = createAsyncThunk('dpl/caregories', async (requestBody: GridRequestParams) => {
  try {
    const data = await DataProtectionLibraryService.getDplCategories(requestBody);

    return data;
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});

const uploadFile = createAsyncThunk(
  'dpl/upload',
  async (
    requestBody: {
      customerId: CustomerId;
      filename: Filename;
      documentName: string;
      documentType: DPLDocumentType;
      documentCategory: DPLDocumentCategory;
      createdDate: Timestamp;
      premium: boolean;
      essentialPlus: boolean;
      requestBody?: Blob;
    },
    { rejectWithValue },
  ) => {
    try {
      const data = await DataProtectionLibraryService.dplImportDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      return rejectWithValue(body);
    }
  },
);

const deleteFile = createAsyncThunk(
  'dpl/delete',
  async (requestBody: { customerId: string; filename: string }) => {
    try {
      const data = await DataProtectionLibraryService.deleteDplDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const downloadFile = createAsyncThunk(
  'dpl/download',
  async (requestBody: { customerId: string; filename: string }) => {
    try {
      const data = await DataProtectionLibraryService.dplExportDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const dplApi = {
  fetchTableContent,
  uploadFile,
  deleteFile,
  downloadFile,
  getCategories,
};

export type DplApiType = typeof dplApi;
