import React from 'react';
import { useDispatch } from 'react-redux';
import { useAuth, useCustomer } from 'store';
import { ActionPopup, Button, Icon } from 'shared';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { Dropdown } from 'antd';
import { UserSchema } from 'services/api';
import { icons } from 'styles/theme';
import { filterActionsByStatus, showPopup } from '../helpers';
import { getCustomMenu } from './UserDetails/helpers';

type DropdownActionsProps = {
  userData: UserSchema;
  onSuccess: () => void;
};

export const DropdownActions = ({ userData, onSuccess }: DropdownActionsProps) => {
  const { user } = useAuth();
  const customer = useCustomer();
  const userId = userData.id || '';
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const dispatch = useDispatch();

  const handleClickAction = (popupId: string) => {
    if (userData && userData.name && userData.email) {
      const userDetails = { id: userId, name: userData.name, email: userData.email };

      showPopup({
        popupId,
        setPopupDetails,
        setIsVisiblePopup,
        userData: userDetails,
        dispatch,
        customerId: customer,
        onSuccess,
        user,
      });
    }
  };

  const filteredActionsByStatus = filterActionsByStatus(userData.status);

  return (
    <>
      <Dropdown
        overlay={getCustomMenu(filteredActionsByStatus, handleClickAction)}
        trigger={['click']}
      >
        <Button
          className='btn-table-action'
          type='link'
          icon={<Icon component={icons.more_vert} />}
        />
      </Dropdown>
      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
        />
      )}
    </>
  );
};
