import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { StepperItem as BaseStepperItem } from './Stepper';
import { StepItemBody } from './Stepper.styled';

export const StepperWrapper = styled.div<{ available: boolean }>`
  display: ${({ available }) => (available ? 'block' : 'inline-block')};
  margin-bottom: 15px;
  min-width: 390px;

  position: relative;

  @media (max-width: ${breakpoints.xs}) {
    min-width: 320px;
    width: 100%;
  }

  .stepper {
    opacity: ${({ available }) => (available ? 1 : '0.25')};
    padding-bottom: 5px;
  }
`;

export const StepperItem = styled(BaseStepperItem)`
  cursor: pointer;

  ${StepItemBody} {
    span {
      display: block;
      min-width: 125px;
    }
  }

  ${({ active }) =>
    !active &&
    `min-width: 39px;
    transition: min-width 0.3s ease-in-out;

    ${StepItemBody} {
      padding-left: 0;
      padding-right: 0;
      width: 0;
      overflow: hidden;
      transition: width 0.3s ease-in-out;
    }

    &.focus,
    &:hover {
      min-width: 164px;

      &:first-child {
        min-width: 144px;
      }

      ${StepItemBody} {
        width: 125px;
      }
    }

    &:first-child {
      min-width: 20px;
    }`}
`;
