import { RouteProps } from 'react-router';
import {
  AlarmDetails,
  Alarms,
  Appearance,
  AuditLog,
  CEPlus,
  Cmdb,
  CmdbHostDetails,
  CreateNotification,
  CvssVectorStrings,
  Dashboard,
  DataProtectionLibrary,
  Documents,
  EditNotification,
  ForgotPassword,
  LocalReportPage,
  Login,
  MaintenancePageSettings,
  MonitoredServiceDetails,
  NotificationDetails,
  Notifications,
  PatchManagement,
  PatchManagementAssets,
  PatchManagementPatchDetails,
  PatchManagementPatches,
  People,
  PeoplePhishing,
  Process,
  ResetPassword,
  SecuritySettings,
  Software,
  SupportRequestDetails,
  SupportRequestForm,
  SupportRequests,
  TagsManagement,
  ThreatDetection,
  ThreatMap,
  UiKit,
  UserDetails,
  Users,
  VulnerabilitiesList,
  VulnerabilityDetails,
  VulnerabilityManagement,
  ServicesIntegrations,
  ServiceIntegrationDetails,
  EditConnectionDetails,
  EditGuidanceOverview,
  EditGuidanceInstructions,
} from 'pages';
import { Routes } from 'services/entities';

export type Route = {
  path: string;
} & RouteProps;

export const developmentRoutes: Route[] = [];

if (process.env.NODE_ENV !== 'production') {
  developmentRoutes.push(
    { path: Routes.UI_KIT, exact: true, component: UiKit },
    {
      path: Routes.MONTHLY_REPORT,
      component: LocalReportPage,
      exact: true,
    },
  );
}

export const publicRoutes: Route[] = [
  { path: Routes.ROOT, exact: true, component: Login },
  { path: Routes.FORGOT_PASSWORD, exact: true, component: ForgotPassword },
  { path: Routes.RESET_PASSWORD, exact: true, component: ResetPassword },
];

export const protectedRoutes: Route[] = [
  { path: Routes.DASHBOARD, exact: true, component: Dashboard },
  { path: Routes.SECURITY_SETTINGS, exact: true, component: SecuritySettings },
  { path: Routes.AUDIT_LOG, exact: true, component: AuditLog },
  { path: Routes.CMDB, exact: true, component: Cmdb },
  { path: Routes.CMDB_AGED_HOSTS, exact: true, component: Cmdb },
  { path: Routes.CMDB_HOST_DETAILS, exact: true, component: CmdbHostDetails },
  { path: Routes.CMDB_AGED_HOSTS_DETAILS, exact: true, component: CmdbHostDetails },
  { path: Routes.DOCUMENTS, exact: true, component: Documents },
  { path: Routes.DATA_PROTECTION_LIBRARY, exact: true, component: DataProtectionLibrary },
  { path: Routes.PEOPLE_PHISHING, exact: true, component: PeoplePhishing },
  { path: Routes.PEOPLE, exact: true, component: People },
  { path: Routes.PROCESS, exact: true, component: Process },
  { path: Routes.SUPPORT_REQUESTS, exact: true, component: SupportRequests },
  { path: Routes.SUPPORT_REQUESTS_DETAILS, exact: true, component: SupportRequestDetails },
  { path: Routes.REPORT_INCIDENT, exact: true, component: SupportRequestForm },
  {
    path: Routes.SERVICE_REQUEST,
    exact: true,
    component: () => <SupportRequestForm isServiceRequest />,
  },
  { path: Routes.SOFTWARE, exact: true, component: Software },
  { path: Routes.THREAT_MAP, exact: true, component: ThreatMap },
  { path: Routes.THREAT_DETECTION, exact: true, component: ThreatDetection },
  { path: Routes.ALARMS, exact: true, component: Alarms },
  { path: Routes.ALARM_DETAILS, exact: true, component: AlarmDetails },
  { path: Routes.MONITORED_SERVICE_DETAILS, exact: true, component: MonitoredServiceDetails },
  { path: Routes.USERS, exact: true, component: Users },
  { path: Routes.USERS_DETAILS, exact: true, component: UserDetails },
  { path: Routes.PATCH_MANAGEMENT_PATCHES, exact: true, component: PatchManagementPatches },
  { path: Routes.PATCH_MANAGEMENT, exact: true, component: PatchManagement },
  {
    path: Routes.PATCH_MANAGEMENT_PATCH_DETAILS,
    exact: true,
    component: PatchManagementPatchDetails,
  },
  { path: Routes.CVSS_VECTOR_STRINGS, exact: true, component: CvssVectorStrings },
  {
    path: Routes.VULNERABILITY_MANAGEMENT,
    exact: true,
    component: VulnerabilityManagement,
  },
  {
    path: Routes.VULNERABILITY_DETAILS,
    exact: true,
    component: VulnerabilityDetails,
  },
  {
    path: Routes.VULNERABILITIES_LIST,
    exact: true,
    component: VulnerabilitiesList,
  },
  { path: Routes.PATCH_MANAGEMENT_ASSETS, exact: true, component: PatchManagementAssets },
  { path: Routes.CE_PLUS, exact: true, component: CEPlus },
  { path: Routes.MAINTENANCE_SETTINGS, exact: true, component: MaintenancePageSettings },
  { path: Routes.TAGS_MANAGEMENT, exact: true, component: TagsManagement },
  { path: Routes.NOTIFICATIONS, exact: true, component: Notifications },
  { path: Routes.NOTIFICATIONS_CREATE, exact: true, component: CreateNotification },
  { path: Routes.NOTIFICATIONS_EDIT, exact: true, component: EditNotification },
  {
    path: `${Routes.NOTIFICATIONS_DETAILS}([\\w+]{24})`,
    exact: true,
    component: NotificationDetails,
  },
  { path: Routes.APPEARANCE, exact: true, component: Appearance },

  /* 
     Services integrations 
  */
  { path: Routes.SERVICES_INTEGRATIONS, exact: true, component: ServicesIntegrations },
  { path: Routes.SERVICE_INTEGRATION_DETAILS, exact: true, component: ServiceIntegrationDetails },
  {
    path: Routes.SERVICE_INTEGRATION_CONNECTION_EDIT,
    exact: true,
    component: EditConnectionDetails,
  },
  {
    path: Routes.SERVICE_INTEGRATION_OVERVIEW_EDIT,
    exact: true,
    component: EditGuidanceOverview,
  },
  {
    path: Routes.SERVICE_INTEGRATION_INSTRUCTIONS_EDIT,
    exact: true,
    component: EditGuidanceInstructions,
  },
];
