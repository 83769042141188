import styled from 'styled-components';
import { ThemeProps } from 'services/interfaces';
import { Theme } from 'styles/theme/types';
import { PAGE_HEIGHT, PAGE_WIDTH } from '../../config';

export const Page = styled.div`
  height: ${PAGE_HEIGHT};
  overflow: hidden;
  position: relative;
`;

export const PdfBox = styled.div<ThemeProps>`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-family: -apple-system, 'Helvetica Now Display', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 13px;
  font-weight: 400;
  line-height: 1.3;
  margin: -8px;
  width: ${PAGE_WIDTH};

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-indent: 0;
  }

  table {
    border-collapse: collapse;
    line-height: inherit;
    text-align: left;
    width: 100%;
  }

  thead {
    th {
      vertical-align: middle;
    }
  }

  td {
    padding: 0;
    vertical-align: top;
  }

  th {
    font-weight: 600 !important;
    padding: 0;
    vertical-align: top;
  }

  .table-layout-fixed {
    table-layout: fixed;
  }

  .table-section {
    border: 2px solid #a69e99;
    margin-bottom: 10px;

    > tbody,
    > thead {
      > tr {
        > td {
          padding: 10px;
        }
      }
    }

    .echarts-for-react {
      overflow: hidden;
    }
  }

  .row-spacing {
    .table-section {
      height: calc(100% - 10px);
    }

    > td {
      &:first-child {
        padding-right: 5px;
      }
    }

    > td + td {
      padding-left: 5px;
    }
  }

  .header {
    .title {
      width: 100%;
    }

    > td {
      padding-bottom: 15px;
    }
  }

  p {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  a {
    color: #000;
    text-decoration: none;
  }

  h1 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
  }

  .h1 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
  }

  h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }

  h4,
  .h4 {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .font-size-10 {
    font-size: 10px;
  }

  .font-size-12 {
    font-size: 12px;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .bold,
  b {
    font-weight: 600;
  }

  hr {
    border: 2px solid #a69e99;
    border-bottom: none;
    margin: 10px 0;
  }

  .text-normal {
    font-weight: 400 !important;
  }

  .text-bold {
    font-weight: 600 !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: 5px !important;
  }

  .mb-2 {
    margin-bottom: 10px !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-1 {
    margin-top: 5px !important;
  }

  .mt-2 {
    margin-top: 10px !important;
  }

  .my-1 {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }

  .my-2 {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .px-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pr-2 {
    padding-right: 10px !important;
  }

  .color-red {
    color: ${({ theme }) => theme.colors.red[800]};
  }

  .color-green {
    color: ${({ theme }) => theme.colors.green[450]};
  }

  .border-rt {
    position: relative;

    &:after {
      border-right: 2px solid #a69e99;
      bottom: 10px;
      content: '';
      position: absolute;
      right: 0;
      top: 10px;
    }
  }

  .table {
    td {
      padding: 3px 5px;
    }

    th {
      padding: 3px 5px;
    }
  }

  .table-border {
    td {
      border: 1px solid ${({ theme }) => theme.colors.black};
    }

    th {
      border: 1px solid ${({ theme }) => theme.colors.black};
    }
  }

  .table-border-vr {
    td + td {
      border-left: 1px solid ${({ theme }) => theme.colors.black};
    }

    th + th {
      border-left: 1px solid ${({ theme }) => theme.colors.black};
    }
  }

  .table-border-hr {
    tr {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.colors.black};
      }

      &:first-of-type {
        td {
          border-top: 1px solid ${({ theme }) => theme.colors.black};
        }
      }
    }
  }

  .total-score-table {
    font-size: 10px;
    text-align: center;

    .arrow-icon {
      font-size: 8px;
      line-height: 1;
      padding: 2px 0;
      vertical-align: middle;
    }
  }

  .people-score-tables {
    padding: 0 10px;

    td:last-of-type {
      padding-right: 5px;
    }
  }

  .action-stepper {
    table-layout: fixed;
    text-align: center;

    .row-steps {
      td {
        &:first-of-type {
          .line-point {
            &:after {
              left: 50%;
            }
          }
        }

        &:last-of-type {
          .line-point {
            &:after {
              right: 50%;
            }
          }
        }
      }
    }

    .row-text {
      td {
        padding: 0 5px;
      }
    }
  }

  .action {
    background: #d8d8d2;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    font-weight: 600 !important;
    margin-bottom: 5px;
    min-width: 45px;
    padding: 3px 8px;
    position: relative;
    text-align: center;

    &:after {
      border: transparent solid 6px;
      border-top-color: #d8d8d2;
      content: '';
      left: 50%;
      margin-left: -6px;
      position: absolute;
      top: 100%;
    }
  }

  .ac-2 {
    background: #bab9a7;

    &:after {
      border-top-color: #bab9a7;
    }
  }

  .ac-3 {
    background: #a79f9c;

    &:after {
      border-top-color: #a79f9c;
    }
  }

  .ac-4 {
    background: #aeb3b9;

    &:after {
      border-top-color: #aeb3b9;
    }
  }

  .line-point {
    margin: 5px 0;
    position: relative;

    &:after {
      background-image: linear-gradient(to right, #000 35%, rgba(255, 255, 255, 0) 15%);
      background-position: top;
      background-repeat: repeat-x;
      background-size: 3px 1px;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: -1;
    }
  }

  .point {
    background: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    box-shadow: inset 0 0 0 1.5px ${({ theme }) => theme.colors.white};
    display: block;
    height: 10px;
    margin: 0 auto;
    width: 10px;
  }

  .people-tb {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }

    td {
      &:nth-child(3) {
        text-align: right;
        width: 50px;
      }
    }
  }

  .td-statistic {
    text-align: center;
    width: 220px;
  }

  .td-statistic-item {
    color: ${({ theme }) => theme.colors.white};
    display: inline-block;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 9px;
    text-transform: uppercase;
  }

  .td-events {
    background: #a69e99;
    font-size: 14px;
    width: 100%;
  }

  .td-alerts {
    background: ${({ theme }) => theme.colors.yellow[500]};
    color: ${({ theme }) => theme.colors.black};
    font-size: 12px;
    min-width: 200px;
  }

  .td-incidents {
    background: ${({ theme }) => theme.colors.red[400]};
    min-width: 100px;
  }

  .td-closet {
    background: ${({ theme }) => theme.colors.green[500]};
    min-width: 100px;
  }

  .td-active {
    background: ${({ theme }) => theme.colors.red[700]};
    min-width: 100px;
  }

  .table-sr {
    thead {
      th {
        background: ${({ theme }) => theme.colors.gray[130]};
      }

      td {
        background: ${({ theme }) => theme.colors.gray[130]};
      }
    }

    tbody {
      th {
        background: #e7e6e6;
      }

      td {
        background: #f1f1f1;
      }

      th.green {
        background: #e1eed9;
      }

      td.green {
        background: #e1eed9;
      }

      th.red {
        background: #fae3d4;
      }

      td.red {
        background: #fae3d4;
      }
    }
  }

  .boost-th-title {
    margin: 0 auto;
    max-width: 100px;
  }

  .text-right {
    text-align: right !important;
  }

  .text-left {
    text-align: left !important;
  }

  .text-center {
    text-align: center !important;
  }
`;

interface GradientPieReportContainerProps {
  height: string;
  right?: boolean;
  theme: Theme;
}

export const GradientPieReportContainer = styled.div<GradientPieReportContainerProps>``;

interface ProcessItemContainerProps {
  color?: string;
  dateColor: string;
  isIcon: boolean;
  isLocked?: boolean;
}

export const ProcessItemContainer = styled.div<ProcessItemContainerProps>`
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};

  > div {
    > .icon {
      position: relative;
      display: inline-block;
      line-height: 1;
      padding: 5px;

      > .anticon {
        width: 80%;
        height: 80%;
        display: inline-block;
      }

      > .lockicon {
        position: absolute;
        width: 10px;
        height: 10px;
        opacity: 1;
        left: 0;
        top: 0;
        filter: ${({
          theme,
        }: {
          theme: Theme;
        }) => `drop-shadow(${theme.spacing.px} ${theme.spacing.px} ${theme.spacing[0]} ${theme.colors.black})
          drop-shadow(${theme.spacing['0.5']} ${theme.spacing['0.5']} ${theme.spacing[1]} ${theme.colors.blackAlpha[50]})`};
      }

      &::after {
        content: '';
        width: ${({ theme }: { theme: Theme }) => theme.sizes[2]};
        display: ${(props: ProcessItemContainerProps) => (props.isIcon ? 'none' : 'block')};
        height: ${({ theme }: { theme: Theme }) => theme.sizes[2]};
        border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
        left: 2px;
        top: 2px;
        background-color: ${(props: ProcessItemContainerProps) => props.color};
        position: absolute;
      }
    }

    .title {
      font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.semibold};
      opacity: ${({ isLocked }: ProcessItemContainerProps) => (isLocked ? '0.3' : 1)};
      width: 105px;
      line-height: 30px;
      height: 30px;
      vertical-align: middle;
      margin: 0 auto;

      span {
        line-height: 1.3;
        display: inline-block;
      }
    }

    .date {
      color: ${(props: ProcessItemContainerProps) => props.dateColor};
    }
  }
`;

export const TotalScorePieWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  right: 20px;
  top: 22px;
`;

export const LogoPlaceholder = styled.p`
  font-size: 38px;
  font-style: italic;
  position: absolute;
`;

export const Score = styled.div`
  position: absolute;
  right: 15px;
  top: 20px;
`;

export const Goals = styled.div`
  left: 90px;
  min-height: 100px;
  min-width: 400px;
  position: absolute;
  top: 130px;

  p {
    color: white;
    font-size: 14px;
  }
`;

export const Action = styled.div`
  left: 50px;
  max-width: 957px;
  padding: 20px 40px;
  position: absolute;
  top: 320px;
`;

export const TableItem = styled.td`
  max-width: 65px;
  padding: 0;
  text-align: center;
  vertical-align: top;
  width: 50px;
`;

export const CyberResilienceScoreDescriptionScoreMessage = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;

export const Table = styled.table`
  font-size: 13px;

  tr > th {
    border-bottom: 2px solid #d7d7cf;
    padding: 5px 15px 8px 15px;

    &:not(:first-child) {
      text-align: center;
    }
  }

  tr > td {
    padding: 6px 10px;

    &:not(:first-child) {
      text-align: center;
    }
  }

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px solid #d7d7cf;
      }
    }
  }
`;

export const SidebarTextBlock = styled.div`
  font-size: 13px;
  left: 50px;
  padding: 0 40px;
  position: absolute;
  width: 296px;
`;
