import * as Yup from 'yup';
import { getStringWithoutTags } from '../../services/helpers';

export const NOTIFICATION_FIELDS_LENGTH = 3;
export const TITLE_MIN_LENGTH = 2;
export const TITLE_MAX_LENGTH = 100;
export const MESSAGE_MIN_LENGTH = 30;
export const MESSAGE_MAX_LENGTH = 10000;

export const validationSchema = Yup.object({
  type: Yup.string().required('Type should not be empty'),
  title: Yup.string()
    .trim()
    .required('Title should not be empty')
    .min(TITLE_MIN_LENGTH, `Title must be at least ${TITLE_MIN_LENGTH} characters`)
    .max(TITLE_MAX_LENGTH, `Title should be less then ${TITLE_MAX_LENGTH} characters`),
  message: Yup.string()
    .trim()
    .required('Message should not be empty')
    .test('min', `Message must be at least ${MESSAGE_MIN_LENGTH} characters`, (value = '') => {
      return MESSAGE_MIN_LENGTH < getStringWithoutTags(value as string).length;
    })
    .test('max', `Message should be less then ${MESSAGE_MAX_LENGTH} characters`, (value = '') => {
      return MESSAGE_MAX_LENGTH > getStringWithoutTags(value as string).length;
    })
    .test('doesnt-have-images', 'Message must be without images', (value) => {
      const hasTags = (value || '').search(/<img\b[^>]*\/?>/gm) === -1;
      const hasBase64Images =
        (value || '').search(
          /!\[[a-zA-z\- 1+0]+]\(data:image\/[bmp,gif,ico,jpg,png,svg,webp,x\-icon,svg+xml]+;base64,[a-zA-Z0-9,+,/]+={0,2}\)/gm,
        ) === -1;

      return hasTags && hasBase64Images;
    }),
});

export enum FormFieldLabels {
  Type = 'type',
  Title = 'title',
  Message = 'message',
}
