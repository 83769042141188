import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScoreResponse } from 'services/api';
import { dashboardApi } from './thunks';

interface InitialState {
  boostScore: ScoreResponse | null;
  peopleScore: ScoreResponse | null;
  processScore: ScoreResponse | null;
  technologyScore: ScoreResponse | null;
}

const initialState: InitialState = {
  boostScore: null,
  peopleScore: null,
  processScore: null,
  technologyScore: null,
};

export const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [dashboardApi.getBoostScore.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<ScoreResponse>,
    ) => {
      state.boostScore = {
        points: action.payload.points || 0,
        score: action.payload.score || 0,
      };
    },
    [dashboardApi.getBoostScore.rejected.toString()]: (state: InitialState) => {
      state.boostScore = null;
    },
    [dashboardApi.getPeopleScore.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<ScoreResponse>,
    ) => {
      state.peopleScore = {
        points: action.payload.points || 0,
        score: action.payload.score || 0,
      };
    },
    [dashboardApi.getPeopleScore.rejected.toString()]: (state: InitialState) => {
      state.peopleScore = null;
    },
    [dashboardApi.getProcessScore.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<ScoreResponse>,
    ) => {
      state.processScore = {
        points: action.payload.points || 0,
        score: action.payload.score || 0,
      };
    },
    [dashboardApi.getProcessScore.rejected.toString()]: (state: InitialState) => {
      state.processScore = null;
    },
    [dashboardApi.getTechnologyScore.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<ScoreResponse>,
    ) => {
      state.technologyScore = {
        points: action.payload.points || 0,
        score: action.payload.score || 0,
      };
    },
    [dashboardApi.getTechnologyScore.rejected.toString()]: (state: InitialState) => {
      state.technologyScore = null;
    },
  },
});
