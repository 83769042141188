import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../../../styles/theme';

export const PatchDetailsContainer = styled.div`
  padding-bottom: 32px;
  padding-left: 8px;
`;

export const MoreButton = styled.div`
  color: ${getCssVar('primary')};
  cursor: pointer;
  font-size: 15px;
  width: fit-content;

  &:hover {
    background: ${({ theme }: { theme: Theme }) => `${getCssVar('primary')}${theme.opacity[12]}`};
  }
`;

export const CVEList = styled.div`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
  padding-right: 20px;
`;
