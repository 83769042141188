export enum ValueLevels {
  LOW = 26,
  MEDIUM = 51,
  HIGH = 76,
  VERY_HIGH = 101,
}

export const VALUE_FULL = 100;
export const DEFAULT_FONT_SIZE = 18;
export const DEFAULT_LINE_HEIGHT = 1.2;
export const DEFAULT_TITLE_FONT_SIZE = 14;
export const DEFAULT_FONT_SIZE_LARGE = 36;

export const DELAY = 1000;
