import { CreateNotificationRequest, NotificationStatus } from '../../services/api';

export const onCreateMessage = {
  [NotificationStatus.DRAFTS]: 'Notification has been saved as draft',
  [NotificationStatus.PUBLISHED]: 'Notification has been published',
};

export enum UpdateNotification {
  CREATE = 'Create notification',
  EDIT = 'Edit notification',
}

export const initialValues: CreateNotificationRequest = {
  type: undefined,
  title: '',
  message: '',
  status: undefined,
};
