import React, { useCallback, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { alarmsApi, useAlarms, useApp, useCustomer, useLoading } from 'store';
import { Empty, TableProps } from 'antd';
import { AlarmSchema, OrderBy } from 'services/api';
import { backlinkRoutes, defaultCurrent } from 'app-constants';
import { Box, Card, Icon, ScrollContainer } from 'shared';
import { LinkContainer } from 'shared/Link/LinkContainer';
import {
  desktopTableRowMaxLength,
  ManagedDetectionsResponseTitles,
  maxTableElementsWithoutScroll,
  mobileTableRowMaxLength,
  tooltipTitles,
} from 'pages/ThreatDetection/constants';
import { Routes } from 'services/entities';
import { useTheme } from 'styled-components';
import { Table } from '../styled';
import { getColumns } from './TableColumns';

export const RecentAlarms = () => {
  const history = useHistory();
  const theme = useTheme();
  const customer = useCustomer();
  const { isMobile } = useApp();
  const count = !isMobile ? desktopTableRowMaxLength : mobileTableRowMaxLength;
  const { data } = useAlarms();
  const dispatch = useDispatch();
  const loading = useLoading([alarmsApi.alarmsTableContent.typePrefix]);
  const loadingTable = loading[alarmsApi.alarmsTableContent.typePrefix];
  const dataSource = data?.rows || [];

  const ref = useRef<HTMLDivElement>(null);

  const isShowTableScroll = !isMobile && dataSource.length > maxTableElementsWithoutScroll;

  useEffect(() => {
    dispatch(
      alarmsApi.alarmsTableContent({
        customerId: customer,
        orderBy: { field: 'updated', type: OrderBy.type.DESC },
        pagination: { count, page: defaultCurrent },
      }),
    );
  }, [dispatch, customer, count]);

  const emptyMessage = {
    emptyText: data.count ? null : (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No recent alarms yet' />
    ),
  };

  const onRowClickHandler = useCallback(
    (record: AlarmSchema) => {
      history.push(Routes.ALARM_DETAILS.replace(':id', record.id as string), {
        backTo: {
          route: `${Routes.THREAT_DETECTION}`,
          title: `Back to ${backlinkRoutes[Routes.THREAT_DETECTION]}`,
        },
      });
    },
    [history],
  );

  return (
    <Card
      title={ManagedDetectionsResponseTitles.RecentAlarms}
      tooltipTitle={tooltipTitles.recentAlarms}
      showDivider={false}
      height={theme.sizes.full}
      headStyles={
        !isMobile
          ? { pt: theme.sizes[1], titlePaddingRight: theme.sizes['2.5'] }
          : { titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={theme.sizes[0]}
      cardPadding={
        !isMobile
          ? theme.sizes['5.5']
          : `${theme.sizes[5]} ${theme.sizes[0]} ${theme.sizes[5]} ${theme.sizes[5]}`
      }
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        h={theme.sizes.full}
      >
        <ScrollContainer
          width='900px'
          margin={`${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[0]}`}
          ref={ref}
          $isScrollTop
        >
          <Table<React.FC<TableProps<AlarmSchema>>>
            columns={getColumns(isMobile)}
            dataSource={dataSource}
            tableLayout='auto'
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                onRowClickHandler(record);
              },
            })}
            scroll={isShowTableScroll ? { y: 335, x: 760 } : {}}
            rowKey={(record: AlarmSchema) => record.id as React.Key}
            loading={loadingTable}
            locale={emptyMessage}
          />
        </ScrollContainer>

        <LinkContainer
          borderTop={!isMobile ? '' : `${theme.sizes.px} solid ${theme.colors.blue[50]}`}
          hasBorder
        >
          <Link to={Routes.ALARMS}>
            See all alarms
            <Icon component={theme.icons.arrow_right_alt} />
          </Link>
        </LinkContainer>
      </Box>
    </Card>
  );
};
