import { minLengthToSearch } from 'app-constants';
import { Filter, OrderBy, PatchCategory, PatchStatus, PatchVendorSeverity } from 'services/api';
import { InputType } from '../../services/api/models/InputType';

export const initialOrderBy = {
  field: 'publishedDate',
  type: OrderBy.type.DESC,
};

export const searchPlaceholder = 'Search by title';
export const searchFields = ['title'];

export const searchSettings = {
  fields: searchFields,
  min: minLengthToSearch,
  placeholder: searchPlaceholder,
};

export enum SeverityOptionLabel {
  URGENT = 'Urgent',
  CRITICAL = 'Critical',
  SERIOUS = 'Serious',
  MEDIUM = 'Medium',
  MINIMAL = 'Minimal',
}

export const SeverityOptions = [
  {
    label: SeverityOptionLabel.URGENT,
    value: PatchVendorSeverity._5,
  },
  {
    label: SeverityOptionLabel.CRITICAL,
    value: PatchVendorSeverity._4,
  },
  {
    label: SeverityOptionLabel.SERIOUS,
    value: PatchVendorSeverity._3,
  },
  {
    label: SeverityOptionLabel.MEDIUM,
    value: PatchVendorSeverity._2,
  },
  {
    label: SeverityOptionLabel.MINIMAL,
    value: PatchVendorSeverity._1,
  },
];

export const filterFields = [
  {
    field: 'patchStatus',
    options: Object.values(PatchStatus).map((v) => ({ value: v, label: v })),
    label: 'Patch status',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'category',
    options: Object.values(PatchCategory).map((v) => ({ value: v, label: v })),
    label: 'Category',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'vendorSeverity',
    options: SeverityOptions.map(({ label, value }) => ({ value, label })),
    label: 'Severity',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];
