import React from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { Box } from 'shared';
import { StackedHorizontalBarFull } from 'shared/charts';
import { KeyValue, VulnerableHostSchema } from 'services/api';
import { StatusLevel } from 'services/constants';
import { emptyDataString } from 'app-constants';
import _ from 'lodash';
import { TableValueStyled } from '../styled/styled';

type TableRedirect = { onClick: () => void };

export const getColumns = (
  tableRedirectToAssetDetails: (record: VulnerableHostSchema) => TableRedirect,
): ColumnsType<VulnerableHostSchema> => [
  {
    key: 'hostname',
    title: 'Host',
    dataIndex: 'hostname',
  },
  {
    key: 'vulnerabilities',
    title: 'Criticality',
    dataIndex: 'vulnerabilities',
    align: 'center',
    width: 210,
    render: (_: KeyValue[], row: VulnerableHostSchema) => (
      <Box marginLeft='auto' minW='180px'>
        {!row.totalCount ? (
          emptyDataString
        ) : (
          <StackedHorizontalBarFull
            values={{
              [StatusLevel.Critical]: row.vulnerabilitiesCriticality5Count || 0,
              [StatusLevel.High]: row.vulnerabilitiesCriticality4Count || 0,
              [StatusLevel.Medium]: row.vulnerabilitiesCriticality3Count || 0,
              [StatusLevel.Low]: row.vulnerabilitiesCriticality2Count || 0,
              [StatusLevel.Informational]: row.vulnerabilitiesCriticality1Count || 0,
            }}
            onEvents={tableRedirectToAssetDetails(row)}
          />
        )}
      </Box>
    ),
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: 'totalCount',
    title: 'Total',
    dataIndex: 'totalCount',
    render: (text: string) => (
      <Box>
        <TableValueStyled>{text}</TableValueStyled>
      </Box>
    ),
    align: 'right',
  },
];
