import React from 'react';
import { DashboardScores } from 'services/api';
import moment from 'moment';
import { getSortedDashboardScores } from '../helpers';
import { TableItem } from '../pages/styled';

type TotalScoreTableProps = {
  totalScoreTable?: DashboardScores;
};

const MAX_MONTH = 6;

const getDinamicIcon = (prevScore: number, totalScore: number) => {
  if (totalScore < prevScore) {
    return <span className='color-red text-bold font-size-16'>▼</span>;
  }

  if (totalScore > prevScore) {
    return <span className='color-green text-bold font-size-16'>▲</span>;
  }

  return <span className='text-bold font-size-16'>-</span>;
};

export const TotalScoreTable = ({ totalScoreTable }: TotalScoreTableProps) => {
  const totalScoreCollection =
    totalScoreTable &&
    getSortedDashboardScores([...totalScoreTable]).slice(0, totalScoreTable.length - 1);

  return (
    <table className='total-score-table'>
      <tbody>
        <tr>
          {totalScoreCollection?.map((item, index: number) => {
            const prevScore = totalScoreCollection?.[index - 1]?.total || 0;

            if (
              item.total === undefined ||
              (index === 0 && totalScoreCollection.length >= MAX_MONTH)
            ) {
              return null;
            }

            return (
              <TableItem key={item.timestamp}>
                <div className='font-size-16'>
                  {item.timestamp && moment(item.timestamp).format('MMM')}
                </div>
                <div className='arrow-icon'>{getDinamicIcon(prevScore, item.total)}</div>
                <div className='text-bold font-size-16'>{item.total}</div>
              </TableItem>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};
