import styled from 'styled-components';
import { getCssVar } from 'styles/theme';
import { Descriptions } from 'pages/CmdbHostDetails/components/AssetDetails/styled';

export const StepperText = styled.div`
  align-items: center;
  color: ${getCssVar('textColor')};
  display: flex;
  font-size: 15px;
  font-weight: 400;

  height: 100%;
  justify-content: center;
  left: 0;

  line-height: 21px;
  position: absolute;
  top: -2px;

  width: 100%;
`;

export const DescriptionItem = styled(Descriptions.Item)`
  .ant-descriptions-item-content {
    display: block;
  }
`;
