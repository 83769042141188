/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AlarmStatusEnum {
  OPEN = 'Open',
  TUNING_REQUIRED = 'Tuning Required',
  INVESTIGATING = 'Investigating',
  CLOSED = 'Closed',
  WITH_CUSTOMER = 'With customer',
  RE_OPENED = 'Re-Opened',
}
