import {
  AppDispatch,
  documents,
  documentsApi,
  dpl,
  dplApi,
  MessageType,
  software,
  softwareApi,
} from 'store';
import { RequestStatus } from 'store/loading/slice';
import { showError, showMessage } from 'services/helpers/index';

interface IProps {
  dispatch: AppDispatch;
  customer: string;
  filename: string;
  api?: typeof documentsApi | typeof dplApi | typeof softwareApi;
  slice?: typeof documents | typeof dpl | typeof software;
}

export const downloadDocument = async ({ dispatch, customer, filename, api, slice }: IProps) => {
  dispatch(
    slice ? slice.actions.setDownloading(filename) : documents.actions.setDownloading(filename),
  );

  const result = (await dispatch(
    api
      ? api.downloadFile({
          customerId: customer,
          filename,
        })
      : documentsApi.downloadFile({
          customerId: customer,
          filename,
        }),
  )) as { payload: { downloadLink: string }; meta: { requestStatus: RequestStatus } };

  if (result.meta.requestStatus === RequestStatus.Rejected) {
    showError();

    return;
  }

  showMessage(
    { content: `The file is being downloaded, please check the progress in your browser` },
    MessageType.Success,
  );
  const url = result.payload.downloadLink;
  const link = document.createElement('a');

  link?.setAttribute('href', url);
  link?.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
