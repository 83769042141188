import moment from 'moment';
import React from 'react';
import { IDate } from 'services/interfaces';
import { LogoPlaceholder, PageTitleDate, Wrapper } from './styled';

type Props = { reportDate: IDate };

export function PageTitle({ reportDate }: Props) {
  return (
    <Wrapper>
      <LogoPlaceholder>Company Logo</LogoPlaceholder>
      <PageTitleDate>
        <p>{moment(reportDate.startDate).format('MMMM YYYY')}</p>
      </PageTitleDate>
    </Wrapper>
  );
}
