/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNotificationRequest } from '../models/CreateNotificationRequest';
import type { CustomerId } from '../models/CustomerId';
import type { Filters } from '../models/Filters';
import type { MarkAsReadOrUnreadNotificationsRequest } from '../models/MarkAsReadOrUnreadNotificationsRequest';
import type { Notification } from '../models/Notification';
import type { NotificationsCreatedByListResponse } from '../models/NotificationsCreatedByListResponse';
import type { NotificationsGridResponse } from '../models/NotificationsGridResponse';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { UpdateNotificationRequest } from '../models/UpdateNotificationRequest';
import { request as __request } from '../core/request';

export class NotificationsService {
  /**
   * Create a notification
   * Create a notification
   * @returns Notification Created Notification
   * @throws ApiError
   */
  public static async createNotification({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Create a notification **/
    requestBody?: CreateNotificationRequest;
  }): Promise<Notification> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/notifications/notification`,
      body: requestBody,
      errors: {
        400: `An error message when create a notification`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Notifications List
   * get Notifications data
   * @returns NotificationsGridResponse Notifications Grid
   * @throws ApiError
   */
  public static async getNotifications({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<NotificationsGridResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/notifications`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when getting notifications`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Notifications Details
   * Get Notification details
   * @returns Notification Notification Details
   * @throws ApiError
   */
  public static async getNotificationDetails({
    customerId,
    notificationId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Notification id **/
    notificationId: string;
  }): Promise<Notification> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/notifications/${notificationId}`,
      errors: {
        400: `An error message when getting notification details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete Notification
   * Delete Notification
   * @returns SuccessResponse Delete notification
   * @throws ApiError
   */
  public static async deleteNotification({
    customerId,
    notificationId,
  }: {
    /** Customer id **/
    customerId: string;
    /** Notification Id **/
    notificationId: string;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/notifications/${notificationId}`,
      errors: {
        400: `An error message when wrong notification Id`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Update Notification
   * Update Notification
   * @returns Notification Updated Notification
   * @throws ApiError
   */
  public static async updateNotification({
    customerId,
    notificationId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** Notification Id **/
    notificationId: string;
    requestBody?: UpdateNotificationRequest;
  }): Promise<Notification> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/notifications/${notificationId}`,
      body: requestBody,
      errors: {
        400: `An error message when wrong notification Id`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Mark as read or unread notifications
   * Mark as read or unread notifications
   * @returns SuccessResponse Mark as read or unread notifications
   * @throws ApiError
   */
  public static async setReadBy({
    customerId,
    isRead,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** The boolean flag indicates is read or not parameter **/
    isRead: boolean;
    requestBody?: MarkAsReadOrUnreadNotificationsRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/notifications/set-read-by`,
      query: {
        isRead: isRead,
      },
      body: requestBody,
      errors: {
        400: `An error message when wrong notification Id`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Notifications created by filter options
   * Returns list of notifications created by
   * @returns NotificationsCreatedByListResponse Notifications created by filter options
   * @throws ApiError
   */
  public static async getNotificationCreatedBy({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<NotificationsCreatedByListResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/notifications/created-by`,
      errors: {
        400: `An error message when get created by list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
