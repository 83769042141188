import { FeatureFlagService } from 'services/api';

export const fetchFlags = async () => {
  try {
    const flags = await FeatureFlagService.getAllFlags();

    return flags;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error while fetching feature flags', error);

    return {};
  }
};

export const fetchFlag = async (featureName) => {
  try {
    const flag = await FeatureFlagService.getFlag({ featureName });

    return flag;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error while fetching feature flag', error);

    return { enabled: false };
  }
};
