import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Input } from 'shared';
import { Popup } from 'shared/ActionPopup/styled';
import { useDispatch } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import { Form } from 'antd';
import {
  AppDispatch,
  managedDetectionsResponseApi,
  MessageType,
  useCustomer,
  useLoading,
} from '../../../../../store';
import { tagsManagementApi } from '../../../../../store/tagsManagement/thunks';
import { SuccessResponse } from '../../../../../services/api';
import { RequestStatus } from '../../../../../store/loading/slice';
import { showError, showMessage } from '../../../../../services/helpers';
import { licenceRequestFields, validationSchema } from '../constants';

export type LicenceRequestFieldType = typeof licenceRequestFields[number] & {
  disabled?: boolean;
};

export const LicenseRequest = ({
  isVisible,
  setIsVisible,
}: {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();
  const loading = useLoading([tagsManagementApi.createTag.typePrefix]);
  const initialValues = {
    details: '',
  };

  const onCancel = React.useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const requestOnSubmit = React.useCallback(
    async ({ details }) => {
      const api = managedDetectionsResponseApi.sendLicenseRequest({
        customerId,
        requestBody: { details: details?.trim() },
      });

      const result = (await dispatch(api)) as PayloadAction<
        SuccessResponse,
        string,
        { requestStatus: string }
      >;

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        showError('Something went wrong. Please, try again');

        return;
      }

      showMessage(
        {
          content: `Thank you for your request. A member of our team will be in touch to discuss your requirements.`,
        },
        MessageType.Success,
      );
      setIsVisible(false);
    },
    [customerId, dispatch, setIsVisible],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => requestOnSubmit(values),
  });

  return (
    <Popup
      className='actions-modal'
      title='Order license'
      visible={isVisible}
      onCancel={onCancel}
      confirmLoading={loading[tagsManagementApi.createTag.typePrefix]}
      okButtonProps={{ disabled: !formik.dirty || !formik.isValid }}
      onOk={() => formik.handleSubmit()}
      footer={[
        <Button key='back' onClick={() => setIsVisible(false)}>
          Cancel
        </Button>,
        <Button
          type='primary'
          key='submit'
          disabled={!formik.dirty || !formik.isValid}
          onClick={() => formik.handleSubmit()}
          loading={loading[tagsManagementApi.createTag.typePrefix]}
        >
          Order
        </Button>,
      ]}
    >
      <Form layout='vertical' onFinish={formik.handleSubmit} initialValues={formik.initialValues}>
        {licenceRequestFields.map((field: LicenceRequestFieldType) => {
          return (
            <Box key={field.name}>
              <Input
                id={field.name}
                type={field.type}
                name={field.name}
                label={field.label}
                disabled={field.disabled}
                onChange={formik.handleChange}
                placeholder={field.placeholder}
                value={formik.values[field.name]}
                maxLength={field.maxLength}
                height={field.height}
                validateStatus={
                  formik.errors[field.name] && formik.touched[field.name] ? 'error' : 'success'
                }
                help={formik.errors[field.name]}
                onBlur={formik.handleBlur}
                required={field.required}
              />
            </Box>
          );
        })}
      </Form>
    </Popup>
  );
};
