import {
  CertificateName,
  SupportRequestProducts,
  SupportRequestsImpact,
  SupportRequestsPriority,
} from 'services/api';

export enum ServiceRequestType {
  Service = 'service',
  Purchase = 'purchase',
}

export enum PageTitles {
  Purchase = 'Service request - Purchase product',
  Service = 'Service request',
  Incident = 'Report incident',
}

export enum PrioritySubLabel {
  High = 'Time sensitive issue, requires an immediate response',
  Medium = 'Moderate, not time-sensitive issue',
  Low = 'Doesnt require an immediate response, the least concern',
}

export enum ImpactSubLabel {
  High = 'Critical functionality or network access interrupted, degraded or unusable, affecting business and high number of staff',
  Medium = 'Non-critical function or procedure, unusable or hard to use having an operational impact',
  Low = 'Issue with limited business impact',
}

export const defaultTitleBackLink = 'Back to Support requests';

export const optionsImpact = [
  {
    label: SupportRequestsImpact.HIGH,
    subLabel: ImpactSubLabel.High,
    value: SupportRequestsImpact.HIGH,
    type: 'impact',
  },
  {
    label: SupportRequestsImpact.MEDIUM,
    subLabel: ImpactSubLabel.Medium,
    value: SupportRequestsImpact.MEDIUM,
    type: 'impact',
  },
  {
    label: SupportRequestsImpact.LOW,
    subLabel: ImpactSubLabel.Low,
    value: SupportRequestsImpact.LOW,
    type: 'impact',
  },
];

export const optionsPriority = [
  {
    label: SupportRequestsImpact.HIGH,
    subLabel: PrioritySubLabel.High,
    value: SupportRequestsPriority._1,
  },
  {
    label: SupportRequestsImpact.MEDIUM,
    subLabel: PrioritySubLabel.Medium,
    value: SupportRequestsPriority._2,
  },
  {
    label: SupportRequestsImpact.LOW,
    subLabel: PrioritySubLabel.Low,
    value: SupportRequestsPriority._3,
  },
];

export const optionsProduct = [
  {
    label: CertificateName.CYBER_ESSENTIALS,
    value: CertificateName.CYBER_ESSENTIALS,
  },
  {
    label: CertificateName.CYBER_ESSENTIALS_PLUS,
    value: CertificateName.CYBER_ESSENTIALS_PLUS,
  },
  {
    label: SupportRequestProducts.CYBER_SAFETY_PHISHING,
    value: SupportRequestProducts.CYBER_SAFETY_PHISHING,
  },
  {
    label: SupportRequestProducts.VULNERABILITY_SCANNING,
    value: SupportRequestProducts.VULNERABILITY_SCANNING,
  },
  {
    label: SupportRequestProducts.THREAT_DETECTION_RESPONSE,
    value: SupportRequestProducts.THREAT_DETECTION_RESPONSE,
  },
];
