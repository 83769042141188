import React from 'react';
import { Box, Icon } from 'shared';
import { useTheme } from 'styled-components';
import { useApp } from '../../../../store';
import { Themes } from '../../../../themes/types';

interface IProps {
  isMobile?: boolean;
  isTablet?: boolean;
}

export const Smartbloc = ({ isMobile, isTablet }: IProps) => {
  const { icons, colors, spacing } = useTheme();
  const { themeName } = useApp();

  const smartBlocIconColor =
    isMobile || isTablet || themeName === Themes.DarkNorm ? colors.white : colors.blue[900];

  return (
    <Box
      width='348px'
      height='66px'
      justifyContent='center'
      align='center'
      margin='0 auto'
      marginBottom={spacing['3.6']}
    >
      <Icon component={icons.smartbloc} width='348px' height='66px' color={smartBlocIconColor} />
    </Box>
  );
};
