import { startCase } from 'lodash';
import { Severity } from 'services/constants';
import { Colors, Values } from '../types';
import { Theme } from '../../../styles/theme/types';

export const getOption = (
  values: Values,
  colors: Colors,
  hasClickHandler: boolean,
  theme: Theme,
) => {
  const seriesNames = Object.keys(values).map((name: string) => startCase(name));
  const seriesData = Object.values(values).map((value: number) => value);

  const data = seriesNames.map((value: string, index: number) => {
    return {
      name: value,
      itemStyle: { color: colors[value.toLowerCase()] },
      value: seriesData[index],
      cursor: value !== Severity.Analysing ? 'pointer' : 'default',
    };
  });

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
    },
    grid: {
      left: '3%',
      top: '5%',
      right: '3%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: seriesNames,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          show: true,
          interval: 0,
          color: theme.colorSet.charts.axisLabel,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        axisLabel: {
          color: theme.colorSet.charts.axisLabel,
        },
      },
    ],
    series: [
      {
        data,
        type: 'bar',
        name: 'Total',
        cursor: hasClickHandler ? 'pointer' : 'auto',
        silent: !hasClickHandler,
      },
    ],
  };
};
