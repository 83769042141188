/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MaintenanceSettingsRequest } from '../models/MaintenanceSettingsRequest';
import type { ToggleMaintenanceModeRequest } from '../models/ToggleMaintenanceModeRequest';
import { request as __request } from '../core/request';

export class MaintenanceSettingsService {
  /**
   * Maintenance settings snackbar text
   * Get maintenance settings snackbar text
   * @returns any Maintenance settings snackbar text
   * @throws ApiError
   */
  public static async getSnackBarText(): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/maintenance-settings/snackbar`,
      errors: {
        400: `An error message when get maintenance snackbar text`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Maintenance settings
   * Get maintenance settings
   * @returns any Maintenance settings
   * @throws ApiError
   */
  public static async getMaintenanceSettings({
    customerId,
  }: {
    /** Customer id **/
    customerId: string;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/maintenance-settings`,
      errors: {
        400: `An error message when get maintenance settings`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Update maintenance settings
   * Update maintenance settings
   * @returns any Maintenance settings
   * @throws ApiError
   */
  public static async updateMaintenanceSettings({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** Is notify users mode **/
    requestBody?: MaintenanceSettingsRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PATCH',
      path: `/customer/${customerId}/maintenance-settings`,
      body: requestBody,
      errors: {
        400: `An error message when get maintenance settings`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Toggle maintenance mode
   * Toggle maintenance mode
   * @returns any Maintenance settings
   * @throws ApiError
   */
  public static async toggleMaintenanceMode({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** Is notify users mode **/
    requestBody?: ToggleMaintenanceModeRequest;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/maintenance-settings/toggle-maintenance-mode`,
      body: requestBody,
      errors: {
        400: `An error message when get maintenance settings`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
