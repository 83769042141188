import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const Link = css<Props>`
  &:not(.ant-table-body) {
    padding: 0;
    font-size: ${({ theme }: Props) => theme.sizes['4']};
    font-weight: ${getCssVar('link.fontWeight')};

    &:not(:disabled) {
      .anticon {
        position: relative;

        &:after {
          content: '';
          border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
          background: ${getCssVar('link.bg')};
          position: absolute;
          left: 50%;
          top: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
          transition: all 0.5s ease;
        }
      }

      &:hover,
      &.hover,
      &:focus,
      &:active,
      &.active {
        opacity: 1;

        .anticon {
          &:after {
            opacity: 0.24;
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    &:disabled {
      color: ${getCssVar('link.disabledColor')};
    }
  }

  .anticon + span,
  span + .anticon {
    margin-left: 4px;
  }
`;
