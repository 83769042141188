import { InnerLayoutProps as InnerLayout } from 'shared';

export const getLayoutConfig = (): InnerLayout => ({
  title: 'Threat map',
  titleTooltip:
    'The FortiNET Threat Map offers real-time information on various threats like malware, botnets, and DDoS attacks. Drawing upon a vast global intelligence network, data for the map is collected from devices worldwide, contributing valuable insights',
});

export const legendItems = [
  {
    title: 'Remote execution attacks',
    img: `/assets/images/threat-map/execution.png`,
  },
  {
    title: 'Memory related attacks',
    img: `/assets/images/threat-map/memory.png`,
  },
  {
    title: 'Attack from a remote location',
    img: `/assets/images/threat-map/link.png`,
  },
  {
    title: 'Denial of Service attacks',
    img: `/assets/images/threat-map/dos.png`,
  },
  {
    title: ' Generic attacks',
    img: `/assets/images/threat-map/single.png`,
  },
];
