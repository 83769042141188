import React from 'react';
import { Button, Icon } from 'shared';
import { icons } from 'styles/theme';

export type DeleteButtonProps = {
  onClick: () => void;
};

export const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <Button
      className='btn-table-action'
      type='link'
      icon={<Icon component={icons.delete} />}
      onClick={onClick}
    />
  );
};
