import { InnerLayout } from 'shared';
import { Col, Row, Spin } from 'antd';
import { useRowGap } from 'services/hooks/useRowGap';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Routes } from 'services/entities';
import { ServiceIntegrationType } from 'pages/ServicesIntegrations/types';
import { useUserPermissions } from 'pages/ServicesIntegrations/hooks';
import { SpinContainer } from 'shared/Card/components/styled';
import { servicesIntegrationsApi } from 'store';
import { useIsAnyRequestLoading } from 'shared/hooks';
import { getLayoutConfig } from './helpers';
import { ConnectionDetails, GuidanceDetails } from './components';
import { useGetServiceDetails } from './hooks';

export const ServiceIntegrationDetails = () => {
  const { gapNumber } = useRowGap();
  const [layoutConfig, setLayoutConfig] = useState({});
  const { canView } = useUserPermissions();

  const { serviceName, connectionDetails, guidanceDetails } = useGetServiceDetails();

  const isLoading = useIsAnyRequestLoading([
    servicesIntegrationsApi.disconnectService.typePrefix,
    servicesIntegrationsApi.testExistingConnection.typePrefix,
    servicesIntegrationsApi.getServiceDetails.typePrefix,
  ]);

  useEffect(() => {
    if (canView) {
      setLayoutConfig(
        getLayoutConfig({
          serviceName,
          isConnected: connectionDetails?.status,
          isLoading,
          connectedAt: connectionDetails?.connectedAt,
        }),
      );
    }
  }, [isLoading, serviceName, connectionDetails?.status, connectionDetails?.connectedAt, canView]);

  if (!canView) {
    return <Redirect to={Routes.NOT_FOUND} />;
  }

  return (
    <InnerLayout {...layoutConfig}>
      {isLoading && (
        <SpinContainer>
          <Spin size='large' />
        </SpinContainer>
      )}
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} xs={24} xl={14} xxl={16}>
          <GuidanceDetails {...guidanceDetails} />
        </Col>

        <Col span={gapNumber} xs={24} xl={10} xxl={8}>
          <ConnectionDetails
            serviceName={serviceName as ServiceIntegrationType}
            {...connectionDetails}
          />
        </Col>
      </Row>
    </InnerLayout>
  );
};
