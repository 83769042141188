import { useState, useMemo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  useApp,
  useCustomer,
  useCustomerChange,
  useExcludedVulnerabilitiesStatusFilter,
  useLoading,
  useVulnerabilitiesTrendsByCriticalities,
  useVulnerability,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { initialPagination } from 'app-constants';
import {
  ByCriticalityVulnerabilitiesTrendSchema,
  Filter,
  SeverityLevel,
  VulnerabilityTrendPointSchema,
} from 'services/api';
import { getSubTitleArrow } from 'services/helpers';
import { CriticalHighCustomTooltipContainer } from 'shared/charts/styled';
import { RadioButtonsWithRangePicker } from 'shared/RadioButtonsWithRangePicker/RadioButtonsWithRangePicker';
import { Line } from 'shared/charts';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { getDefaultDateRangeValue } from 'shared/RadioButtonsWithRangePicker/helpers';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { Routes } from 'services/entities';
import { IDate } from 'services/interfaces';
import { getQueryString } from 'services/api/core/request';
import {
  emptyValuesVulnerabilitiesSeverity,
  FIXED_VALUE,
  severityValues,
  TooltipTitles,
  VulnerabilitiesTitles,
} from 'pages/VulnerabilityManagement/constants';
import { Box, Card, Icon } from 'shared';
import {
  getParamsFromVulnerabilitiesStatusFilter,
  getPointType,
  getSeverityLevelName,
} from 'pages/VulnerabilityManagement/helpers';
import {
  confirmedVulnerabilitiesStatusFilter,
  initialOrderBy,
  tabsKeys as vulnerabilitiesListTabs,
} from 'pages/VulnerabilitiesList/constants';
import { useTheme } from 'styled-components';
import { ScrollContainer } from '../styled/styled';
import { getOption } from './helpers';
import { EmptyValuesVulnerabilitiesSeverity } from '../../types';
import { IFilterField } from '../../../CEPlus/constants';
import { validateField, validateFieldValue } from '../../../CEPlus/helpers';
import { ExcludeFilterTooltip } from '../ExcludeFilterTooltip';

export const VulnerabilitiesSeverity = () => {
  const theme = useTheme();
  const [date, setDate] = useState<IDate>(getDefaultDateRangeValue());
  const vulnerabilitiesTrendsByCriticalities = useVulnerabilitiesTrendsByCriticalities();
  const { selectedTags } = useVulnerability();
  const customer = useCustomer();
  const { isMobile } = useApp();
  const dispatch = useDispatch();
  const loading = useLoading([
    vulnerabilitiesApi.getVulnerabilitiesTrendsByCriticalities.typePrefix,
  ]);

  const customerChange = useCustomerChange([
    vulnerabilitiesApi.getVulnerabilitiesTrendsByCriticalities.typePrefix,
  ]);

  const isShowEmptyChartInVulnerabilitiesSeverity =
    customerChange[vulnerabilitiesApi.getVulnerabilitiesTrendsByCriticalities.typePrefix];
  const vulnerabilitiesFilterbyStatus = useExcludedVulnerabilitiesStatusFilter() as string[];

  const updateDate = useCallback((selectedDate: IDate) => {
    setDate(selectedDate);
  }, []);

  const getValues = (values: ByCriticalityVulnerabilitiesTrendSchema[]) =>
    [...values].reduce(
      (
        acc: { [x: string]: VulnerabilityTrendPointSchema[] },
        value: ByCriticalityVulnerabilitiesTrendSchema,
      ) => {
        if (!value.criticality || !value.points) {
          return { ...acc };
        }

        const key = getSeverityLevelName(value.criticality);

        return { ...acc, [key]: value.points };
      },
      {},
    );

  const vulnerabilitiesParams = useMemo(() => {
    const filterFields: IFilterField[] = [
      {
        name: 'severity',
        value: [severityValues.Urgent, severityValues.Critical],
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'tagsAssets',
        value: selectedTags || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
      { ...confirmedVulnerabilitiesStatusFilter.fields[0] },
    ];

    return {
      orderBy: initialOrderBy,
      pagination: initialPagination,
      tab: vulnerabilitiesListTabs.active,
      filter: {
        fields: validateField(filterFields),
      },
    };
  }, [selectedTags]);

  useEffect(() => {
    const { startDate, endDate } = date;

    dispatch(
      vulnerabilitiesApi.getVulnerabilitiesTrendsByCriticalities({
        customerId: customer,
        startDate,
        endDate,
        pointType: getPointType(startDate, endDate),
        criticalities: [SeverityLevel._4, SeverityLevel._5],
        tagIds: selectedTags || [],
        ...getParamsFromVulnerabilitiesStatusFilter(vulnerabilitiesFilterbyStatus),
      }),
    );
  }, [dispatch, customer, date, vulnerabilitiesFilterbyStatus, selectedTags]);

  const boldText =
    vulnerabilitiesTrendsByCriticalities.count || vulnerabilitiesTrendsByCriticalities.count === 0
      ? vulnerabilitiesTrendsByCriticalities.count.toLocaleString('ru')
      : '';

  const lightText =
    vulnerabilitiesTrendsByCriticalities.percentageComparison ||
    vulnerabilitiesTrendsByCriticalities.percentageComparison === 0
      ? `${vulnerabilitiesTrendsByCriticalities.percentageComparison.toFixed(FIXED_VALUE)}%`
      : '';

  const subTitle = (vulnerabilitiesTrendsByCriticalities.count ||
    vulnerabilitiesTrendsByCriticalities.count === 0) && (
    <SubTitle
      boldText={
        <Box display='flex' alignItems='center' gap={`${theme.sizes['1.2']}`}>
          <ExcludeFilterTooltip />
          {boldText}
        </Box>
      }
      lightText={lightText}
      arrow={getSubTitleArrow(vulnerabilitiesTrendsByCriticalities.percentageComparison)}
      paddingRight='32px'
    />
  );

  const vulnerabilityTrends = vulnerabilitiesTrendsByCriticalities.trends?.filter((item) =>
    Boolean(item?.points?.length),
  );

  return (
    <Card
      title={VulnerabilitiesTitles.VulnerabilitiesByCriticalHighSeverity}
      showDivider={false}
      subTitle={subTitle}
      tooltipTitle={TooltipTitles.VulnerabilitiesByCriticalHighSeverity}
      headStyles={{
        headAlign: 'flex-start',
        titlePaddingRight: isMobile ? theme.sizes[0] : theme.sizes[2],
        maxWidthTitle: isMobile ? '180px' : '450px',
      }}
      cardPadding={
        !isMobile
          ? theme.sizes[6]
          : `${theme.sizes[5]} ${theme.sizes[0]} ${theme.sizes[5]} ${theme.sizes[5]}`
      }
      bodyPadding={
        !isMobile ? `${theme.sizes[2]} ${theme.sizes[2]} ${theme.sizes[0]}` : theme.sizes[0]
      }
      showLoader={loading[vulnerabilitiesApi.getVulnerabilitiesTrendsByCriticalities.typePrefix]}
      height={theme.sizes.full}
      headAction={
        <Link
          to={`${Routes.VULNERABILITIES_LIST}${getQueryString(vulnerabilitiesParams)}`}
          style={{ fontSize: theme.sizes[4], display: 'block', paddingTop: theme.sizes['2.5'] }}
        >
          See all urgent & critical vulnerabilities
          <Icon component={theme.icons.arrow_right_alt} />
        </Link>
      }
    >
      <ScrollContainer className='default-cursor-chart'>
        <>
          <RadioButtonsWithRangePicker
            handleDateChange={updateDate}
            date={date}
            isMobile={isMobile}
          />

          <CriticalHighCustomTooltipContainer>
            <RenderChartWrapper<
              ByCriticalityVulnerabilitiesTrendSchema,
              EmptyValuesVulnerabilitiesSeverity
            >
              values={getValues(vulnerabilityTrends || [])}
              emptyValues={emptyValuesVulnerabilitiesSeverity}
              emptyText='No vulnerabilities yet'
              isShowEmptyChart={isShowEmptyChartInVulnerabilitiesSeverity}
            >
              <Line
                values={emptyValuesVulnerabilitiesSeverity}
                getOption={getOption}
                date={date}
                isMobile={isMobile}
                height={{ chart: '320px', grid: 'auto' }}
              />
            </RenderChartWrapper>
          </CriticalHighCustomTooltipContainer>
        </>
      </ScrollContainer>
    </Card>
  );
};
