import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { excludeAgedHostTag } from 'services/helpers';
import { useCEPlus, useLoading, useTagsManagement } from '../../../store';
import { icons } from '../../../styles/theme';
import { Icon, TableFiltersSelect } from '../../../shared';
import { Filter, InputType, TagSchema } from '../../../services/api';
import { OptionType } from '../../../shared/Table/types';
import { FilterLabel, FilterWrapper } from '../../VulnerabilityManagement/components/styled/styled';
import { cyberEssentialPlus } from '../../../store/cyberEssentialPlus/slice';
import { tagsManagementApi } from '../../../store/tagsManagement/thunks';

export const FilterCEPlusByTags = () => {
  const dispatch = useDispatch();
  const { tagsList } = useTagsManagement();
  const loading = useLoading([tagsManagementApi.getTagsList.typePrefix]);
  const omittedValuesCount = (currentValue: string[]) => `+${currentValue.length}`;
  const { selectedTags } = useCEPlus();

  const selectTagOptions = useMemo(() => {
    return tagsList?.filter(excludeAgedHostTag).map((item: TagSchema) => ({
      label: item?.name,
      value: item?.id,
    })) as OptionType[];
  }, [tagsList]);

  const filterOption = (inputValue: string, option: OptionData | OptionGroupData | undefined) =>
    (option as OptionType).label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

  const onClear = () => {
    dispatch(cyberEssentialPlus.actions.setFilterTags([]));
  };

  return (
    <FilterWrapper>
      <FilterLabel>Filter by tags:</FilterLabel>
      <TableFiltersSelect
        label=''
        inputType={InputType.SELECTOR}
        filterType={Filter.type.MULTIPLE}
        field='tags'
        mode='multiple'
        placeholder='All'
        maxTagCount={1}
        maxTagPlaceholder={omittedValuesCount}
        showSearch
        showArrow
        value={selectedTags}
        suffixIcon={
          !selectedTags?.length ? (
            <Icon component={icons.arrow_drop_down} />
          ) : (
            <Icon margin='4px' component={icons.select_clear} onClick={onClear} />
          )
        }
        loading={loading[tagsManagementApi.getTagsList.typePrefix]}
        options={selectTagOptions}
        onSelectChange={(_, value) =>
          dispatch(cyberEssentialPlus.actions.setFilterTags((value as string[]) || []))
        }
        dropdownAlign={{
          overflow: { adjustY: true },
        }}
        filterOption={filterOption}
      />
    </FilterWrapper>
  );
};
