import {
  ExpiryValues,
  Complexity,
  Expiry,
  Lock,
  MinLength,
  PasswordReUsePrevention,
} from '../constants';

const userAccountExpiry = [
  { value: ExpiryValues.Never, label: Expiry.Never },
  { value: ExpiryValues.ThirtyDays, label: Expiry.ThirtyDays },
  { value: ExpiryValues.SixtyDays, label: Expiry.SixtyDays },
  { value: ExpiryValues.NinetyDays, label: Expiry.NinetyDays },
  { value: ExpiryValues.SixMonths, label: Expiry.SixMonths },
  { value: ExpiryValues.ThirteenMonths, label: Expiry.ThirteenMonths },
];

const passwordExpiry = [
  { value: ExpiryValues.Never, label: Expiry.Never },
  { value: ExpiryValues.ThirtyDays, label: Expiry.ThirtyDays },
  { value: ExpiryValues.SixtyDays, label: Expiry.SixtyDays },
  { value: ExpiryValues.NinetyDays, label: Expiry.NinetyDays },
  { value: ExpiryValues.SixMonths, label: Expiry.SixMonths },
  { value: ExpiryValues.ThirteenMonths, label: Expiry.ThirteenMonths },
];

const passwordMinLength = [
  { value: 6, label: MinLength.SixCharacters },
  { value: 8, label: MinLength.EightCharacters },
  { value: 10, label: MinLength.TenCharacters },
  { value: 12, label: MinLength.TwelveCharacters },
];

const passwordComplexity = [
  { value: 2, label: Complexity.TwoCharacters },
  { value: 3, label: Complexity.ThreeCharacters },
  { value: 4, label: Complexity.FourCharacters },
];

const passwordReUsePrevention = [
  { value: 2, label: PasswordReUsePrevention.LastTwoPasswords },
  { value: 4, label: PasswordReUsePrevention.LastFourPasswords },
  { value: 6, label: PasswordReUsePrevention.LastSixPasswords },
  { value: 13, label: PasswordReUsePrevention.LastThirteenPasswords },
];

const passwordLock = [
  { value: 3, label: Lock.AfterThreeAttempts },
  { value: 5, label: Lock.AfterFiveAttempts },
  { value: 10, label: Lock.AfterTenAttempts },
];

export const options = {
  userAccountExpiry,
  passwordExpiry,
  passwordMinLength,
  passwordComplexity,
  passwordReUsePrevention,
  passwordLock,
};
