import { message } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { MAX_PERCENT, PermissionsLevel } from 'app-constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CustomerProductsListSchema, SoftwareDocumentSchema } from 'services/api';
import { showError, showMessage } from 'services/helpers';
import { chunkSize } from 'shared/UploadPopup/constants';
import { UploadPopup } from 'shared/UploadPopup/UploadPopup';
import { AppDispatch, MessageType, softwareApi, UserProps } from 'store';
import { RequestStatus } from 'store/loading/slice';
import { getSelections } from '../helpers';
import { initialSelect, softwareDocTypeOptions } from './constants';
import { PopupWithProgressBar } from './PopupWithProgressBar';

type UploadProps = {
  visible: boolean;
  setVisible: (x: boolean) => void;
  fetchTableContent: () => void;
  customerId: string;
  permission: PermissionsLevel | null;
  loading?: boolean;
  user?: UserProps;
  productsSelectOptions?: {
    label: CustomerProductsListSchema;
    value: CustomerProductsListSchema;
  }[];
};

export const Upload = ({
  visible,
  setVisible,
  fetchTableContent,
  customerId,
  permission,
  loading,
  user,
  productsSelectOptions,
}: UploadProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [downloadPercentage, setDownloadPercentage] = React.useState<number>(0);
  const [isShowProgress, setIsShowProgress] = React.useState(false);
  const [isUploadingCanceled, setIsUploadingCanceled] = React.useState(false);
  const [lastChunkFileName, setLastChunkFileName] = React.useState<string | null>(null);

  const [fileData, setFileData] = React.useState<{
    filename: string;
    documentType: SoftwareDocumentSchema.documenttype;
    serviceType: SoftwareDocumentSchema.servicetype;
    fileSize: number;
    totalChunks: number;
    blob: Blob;
  } | null>(null);

  const [currentChunkIndex, setCurrentChunkIndex] = React.useState(0);
  const [beginningOfTheChunk, setBeginningOfTheChunk] = React.useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = React.useState(chunkSize);

  const resetProgress = () => {
    setIsShowProgress(false);
    setDownloadPercentage(0);
  };

  const resetChunkProperties = () => {
    setFileData(null);
    setCurrentChunkIndex(0);
    setBeginningOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setLastChunkFileName(null);
  };

  const handleUploadingError = React.useCallback(() => {
    resetProgress();
    showError();
  }, []);

  const onUploadingCancel = React.useCallback(() => {
    setIsUploadingCanceled(true);
  }, []);

  const sliceAndUploadCurrentChunk = React.useCallback(async () => {
    if (isUploadingCanceled) {
      await dispatch(softwareApi.deleteFile({ customerId, filename: lastChunkFileName || '' }));
      setIsUploadingCanceled(false);
      resetProgress();
      resetChunkProperties();
      message.destroy();

      return;
    }

    if (!fileData) {
      resetProgress();
      resetChunkProperties();
      setIsUploadingCanceled(false);

      return;
    }

    const { filename, fileSize, totalChunks, blob, documentType, serviceType } = fileData;

    if (currentChunkIndex === totalChunks) {
      showMessage({ content: `File ${filename} successfully uploaded.` }, MessageType.Success);
      resetProgress();
      resetChunkProperties();
      fetchTableContent();

      return;
    }

    const chunk = blob.slice(beginningOfTheChunk, endOfTheChunk, 'application/octet-stream');

    const result = await dispatch(
      softwareApi.uploadFile({
        customerId,
        filename: lastChunkFileName || filename,
        fileSize,
        totalChunks,
        requestBody: chunk,
        documentType,
        serviceType,
        currentChunkIndex,
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Fulfilled) {
      const payload = result.payload as { filename: string };
      const end = endOfTheChunk < fileSize ? endOfTheChunk + chunkSize : fileSize;

      setBeginningOfTheChunk(endOfTheChunk);
      setLastChunkFileName(payload.filename);
      setEndOfTheChunk(end);
      setCurrentChunkIndex(currentChunkIndex + 1);
      setDownloadPercentage(Math.ceil(((currentChunkIndex + 1) / totalChunks) * MAX_PERCENT));

      return;
    }

    handleUploadingError();
    resetChunkProperties();
  }, [
    customerId,
    dispatch,
    beginningOfTheChunk,
    currentChunkIndex,
    endOfTheChunk,
    fetchTableContent,
    fileData,
    isUploadingCanceled,
    handleUploadingError,
    lastChunkFileName,
  ]);

  React.useEffect(() => {
    if (fileData) {
      setIsShowProgress(true);
      sliceAndUploadCurrentChunk();
    }
    // eslint-disable-next-line
  }, [fileData, currentChunkIndex]);

  const handleUpload = React.useCallback(
    (
      name: string,
      docType: SoftwareDocumentSchema.documenttype,
      file: File,
      selections: Record<string, SelectValue> | undefined,
    ) => {
      const reader = new FileReader();

      reader.onloadend = async (e: ProgressEvent<FileReader>) => {
        const blob = new Blob([e?.target?.result as ArrayBuffer]);
        const totalChunks = Math.ceil(blob.size / chunkSize);

        setFileData({
          filename: name,
          documentType: docType,
          blob,
          fileSize: blob.size,
          serviceType: selections?.service as SoftwareDocumentSchema.servicetype,
          totalChunks,
        });
      };

      reader.onerror = () => {
        handleUploadingError();
      };

      reader.readAsArrayBuffer(file);
    },
    [handleUploadingError],
  );

  return (
    <>
      <UploadPopup<SoftwareDocumentSchema.documenttype>
        visible={visible}
        setVisible={setVisible}
        handleOk={handleUpload}
        radioButtons={softwareDocTypeOptions}
        selections={getSelections(productsSelectOptions || [])}
        initialSelections={initialSelect}
        loadingServiceType={loading}
        noExtensions={permission === PermissionsLevel.Full && user?.isSuperAdmin}
        uploadFileSize={user?.uploadFileSize?.software}
      />
      <PopupWithProgressBar
        visible={isShowProgress}
        percent={downloadPercentage}
        cancelLoading={isUploadingCanceled}
        cancelDisabled={!currentChunkIndex}
        handleCancel={onUploadingCancel}
        title='Uploading...'
      />
    </>
  );
};
