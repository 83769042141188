import React from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { sevenDaysTime } from 'app-constants';
import {
  SeverityLevel,
  TopVulnerabilitySchema,
  VulnerabilityStatus,
  VulnerabilityTrendPointSchema,
  VulnerabilityTrendPointTypeSchema,
  VulnerableHostSchema,
} from 'services/api';
import { StatusLevel } from 'services/constants';
import { getPeriod } from 'services/helpers';
import { Box, InnerLayoutProps as InnerLayout } from 'shared';
import {
  TablePercentStyled,
  TableValueStyled,
  FilterVulnerabilitiesWrapper,
} from './components/styled/styled';
import { TotalRowKey } from './constants';
import { FilterVulnerabilitiesByTags } from './components/FilterVulnerabilitiesByTags';
import { FilterVulnerabilitiesByStatus } from './components/FilterVulnerabilitiesByStatus';

export const getLayoutConfig = (): InnerLayout => ({
  title: 'Vulnerability management',
  isBeforeToggleComponent: (
    <FilterVulnerabilitiesWrapper>
      <FilterVulnerabilitiesByTags />
      <FilterVulnerabilitiesByStatus />
    </FilterVulnerabilitiesWrapper>
  ),
  beforeToggleComponentStyle: { maxWidth: '960px' },
});

export const getPointType = (start: number, end: number) => {
  const period = getPeriod(start, end);

  if (period <= sevenDaysTime) {
    return VulnerabilityTrendPointTypeSchema.HOUR;
  }

  return VulnerabilityTrendPointTypeSchema.DAY;
};

export const getSeverityLevelName = (level: string) => {
  switch (level) {
    case SeverityLevel._1:
      return StatusLevel.Informational;
    case SeverityLevel._2:
      return StatusLevel.Low;
    case SeverityLevel._3:
      return StatusLevel.Medium;
    case SeverityLevel._4:
      return StatusLevel.High;
    case SeverityLevel._5:
      return StatusLevel.Critical;
    default:
      return StatusLevel.Analysing;
  }
};

export const getData = (entries: VulnerabilityTrendPointSchema[]) => {
  const seriesData: number[] = [];
  const xAxisData: number[] = [];

  entries.map((entry: VulnerabilityTrendPointSchema) => {
    if (entry.value == null || !entry.date) {
      return entry;
    }

    seriesData.push(entry.value);
    xAxisData.push(entry.date);

    return entry;
  });

  if (!seriesData.length) {
    return [[0], xAxisData];
  }

  return [seriesData, xAxisData];
};

const getPercentageFromTotal = (total: number, value: number) => {
  const ONE_HUNDRED = 100;
  const FIXED_VALUE = 2;

  return ((value / total) * ONE_HUNDRED).toFixed(FIXED_VALUE);
};

export const getTableColumns = (
  qualysHostsCount: number,
  currentAffectedHostsCount: number | undefined,
  totalActiveVulnerabilitiesCount?: number,
): ColumnsType<VulnerableHostSchema | TopVulnerabilitySchema> => [
  {
    key: 'title',
    title: 'Vulnerability',
    dataIndex: 'title',
    render: (text: string) => <>{text}</>,
  },
  {
    key: 'affectedHosts',
    title: 'Affected hosts',
    dataIndex: 'affectedHosts',
    width: 145,
    sorter: false,
    render: (hostsCount: number, row: TopVulnerabilitySchema) => (
      <Box display='flex' justify='space-between'>
        <TablePercentStyled>
          {row.title === 'Impact'
            ? `${getPercentageFromTotal(
                totalActiveVulnerabilitiesCount || 1,
                currentAffectedHostsCount || 1,
              )}%`
            : `${getPercentageFromTotal(qualysHostsCount, hostsCount)}%`}
        </TablePercentStyled>
        <TableValueStyled>{hostsCount}</TableValueStyled>
      </Box>
    ),
    align: 'right',
  },
];

export const getTotalRow = () => ({
  id: TotalRowKey.Impact,
  title: 'Impact',
});

export const getParamsFromVulnerabilitiesStatusFilter = (excludedFilterOptions: string[]) => ({
  vulnerabilityStatus: excludedFilterOptions.includes('potential')
    ? [VulnerabilityStatus.CONFIRMED]
    : [VulnerabilityStatus.CONFIRMED, VulnerabilityStatus.POTENTIAL],
  includeSuppressedVulnerabilities: !excludedFilterOptions.includes('suppressed'),
});
