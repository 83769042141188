import React, { PropsWithChildren, useRef } from 'react';
import { capitalize } from 'lodash';
import { Values } from '../charts/types';
import { StyledTooltip, TooltipWrapper } from './Tooltip.styled';

type Props = {
  colors: Record<string, string>;
  values: Values;
};

function TooltipTitle({ values, colors }: Props) {
  return (
    <ul>
      {Object.entries(values).map(([key, value]) => {
        return (
          <li key={key}>
            <span
              className='horizontal-bar-tooltip-circle'
              style={{ backgroundColor: colors[key] }}
            />
            <div className='horizontal-bar-tooltip-title'>{capitalize(key.toLowerCase())}</div>
            <span className='horizontal-bar-tooltip-value'>{value}</span>
          </li>
        );
      })}
    </ul>
  );
}

export function Tooltip({ colors, values, children }: PropsWithChildren<Props>) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <TooltipWrapper ref={ref}>
      <StyledTooltip
        title={<TooltipTitle colors={colors} values={values} />}
        placement='top'
        arrowPointAtCenter
        overlayClassName='vulnerability-tooltip'
      >
        {children}
      </StyledTooltip>
    </TooltipWrapper>
  );
}
