import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { ActionNames, PermissionsLevel } from 'app-constants';
import { DownloadButton } from 'pages/Documents/components/DownloadButton';
import { DPLDocumentSchema } from 'services/api';
import { getFormattedDate } from 'services/helpers';
import { UcFirstLetter } from 'shared';
import { DeleteButton } from 'shared/buttons/DeleteButton';
import { ColumnKey } from './constants';

type GetExpandedColumnsProps = (
  downloadClick: (name: string) => void,
  downloading: string,
  permission: PermissionsLevel | null,
  actionClick: (row: DPLDocumentSchema, popupId: ActionNames) => void,
  isSuperAdmin?: boolean,
) => ColumnsType<DPLDocumentSchema>;

export const getColumns: GetExpandedColumnsProps = (
  downloadClick,
  downloading,
  permission,
  actionClick,
  isSuperAdmin,
) => {
  return [
    {
      key: ColumnKey.Name,
      title: 'Document name',
      dataIndex: ColumnKey.Name,
      render: (text: string) => <>{text}</>,
    },
    {
      key: ColumnKey.DocumentType,
      title: 'Document type',
      dataIndex: ColumnKey.DocumentType,
      render: (documentType: string) =>
        !documentType ? '' : <>{UcFirstLetter(documentType.toLowerCase())}</>,
    },
    {
      key: ColumnKey.CreatedOn,
      title: 'Created date',
      dataIndex: ColumnKey.CreatedOn,
      showSorterTooltip: false,
      render: (date: string) => <>{getFormattedDate(date)}</>,
      sorter: true,
      className: 'right-head-title',
      align: 'right',
    },
    {
      key: ColumnKey.Actions,
      title: 'Actions',
      dataIndex: ColumnKey.Actions,
      render: (_: string, row: DPLDocumentSchema) => (
        <>
          <DownloadButton
            name={row.filename || ''}
            click={downloadClick}
            downloading={downloading}
          />
          {((row.isownedbysuperadmin && permission === PermissionsLevel.Full && isSuperAdmin) ||
            (!row.isownedbysuperadmin && permission === PermissionsLevel.Full)) && (
            <DeleteButton onClick={() => actionClick(row, ActionNames.Delete)} />
          )}
        </>
      ),
      align: 'right',
      className: 'right-head-title',
    },
  ];
};
