import { Grid } from 'antd';

export const useScreenSizes = () => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();

  const isLargeDesktop = breakpoints.xl || breakpoints.xxl;
  const isDesktop = breakpoints.lg || breakpoints.xl || breakpoints.xxl;
  const isTablet = !breakpoints.lg && breakpoints.md && breakpoints.sm;
  const isMobile = !isDesktop && !isTablet;

  return [isDesktop, isTablet, isMobile, isLargeDesktop];
};
