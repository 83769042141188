import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MobileTable } from 'shared';
import {
  AppDispatch,
  useApp,
  useCustomer,
  useLoading,
  useVulnerabilitySuppressionGrid,
} from 'store';
import { GridRequestParams, SuppressionHistoryItem } from 'services/api';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { useModal } from 'services/hooks/useModal';
import { useTheme } from 'styled-components';
import { Col } from 'antd';
import Row, { Gutter } from 'antd/lib/grid/row';
import { CustomTable } from 'shared/Table/CustomTable';
import { SuppressionHeader } from './components/SuppressionHeader';
import { UpdateSuppressionPopup } from './components/UpdateSuppressionPopup';
import {
  defaultCurrent,
  defaultPageSize,
  emptyTableContent,
  initialOrderBy,
  sectionTitle,
} from './constants';
import { getColumns } from './table-columns';
import { CardStyled } from './styled';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const FULL_WIDTH_COLS = 32;

type SuppressionProps = {
  suppressionRef: React.RefObject<HTMLDivElement>;
};

export const Suppression = ({ suppressionRef }: SuppressionProps) => {
  const { id } = useParams<{ id: string }>();
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const theme = useTheme();
  const modal = useModal();
  const dispatch: AppDispatch = useDispatch();
  const data = useVulnerabilitySuppressionGrid() || { rows: [], count: 0 };
  const loading = useLoading([vulnerabilitiesApi.getVulnerabilitySuppressionGrid.typePrefix]);
  const isLoading = loading[vulnerabilitiesApi.getVulnerabilitySuppressionGrid.typePrefix];
  const [params, setParams] = React.useState<GridRequestParams>({
    customerId,
    orderBy: initialOrderBy,
    pagination: { count: 10, page: 1 },
  });

  const GUTTER: [Gutter, Gutter] = !isMobile
    ? [H_GUTTER, V_GUTTER]
    : [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

  const updateParams = React.useCallback(
    (updatedParams: GridRequestParams | ((prevParams: GridRequestParams) => GridRequestParams)) => {
      setParams(updatedParams);
    },
    [],
  );

  React.useEffect(() => {
    dispatch(
      vulnerabilitiesApi.getVulnerabilitySuppressionGrid({
        customerId,
        vulnerabilityId: id,
        pagination: params.pagination,
      }),
    );
  }, [dispatch, id, customerId, params]);

  return (
    <CardStyled
      title={sectionTitle}
      headStyles={
        isMobile
          ? {
              pb: theme.sizes[3],
              pt: theme.sizes[5],
            }
          : {
              pb: theme.sizes[4],
              titlePaddingRight: theme.sizes['2.5'],
            }
      }
      bodyPadding={!isMobile ? theme.sizes[0] : theme.sizes[0]}
      cardPadding={!isMobile ? theme.sizes['5.5'] : theme.sizes[5]}
      showLoader={isLoading}
      headAction={<SuppressionHeader status={data?.status} action={() => modal.showModal()} />}
    >
      <>
        {isMobile ? (
          <Row gutter={GUTTER}>
            <Col span={FULL_WIDTH_COLS} flex='auto'>
              <MobileTable<SuppressionHistoryItem, GridRequestParams>
                data={data}
                columns={getColumns()}
                params={params}
                setParams={updateParams}
                initialOrderBy={initialOrderBy}
                customEmptyMessageText={emptyTableContent}
                noFilters
                sortable={false}
              />
            </Col>
          </Row>
        ) : (
          <CustomTable<SuppressionHistoryItem, GridRequestParams>
            data={data}
            columns={getColumns()}
            params={params}
            setParams={updateParams}
            defaultPageSize={defaultPageSize}
            defaultCurrent={defaultCurrent}
            filterFields={[]}
            customEmptyMessageText={emptyTableContent}
          />
        )}
      </>
      {modal.isModalShown && (
        <UpdateSuppressionPopup
          isVisible={modal.isModalShown}
          suppression={data}
          setIsVisible={(value: boolean) => (value ? modal.showModal() : modal.hideModal())}
          onSuccess={() => {
            suppressionRef.current?.scrollIntoView();
            dispatch(
              vulnerabilitiesApi.getVulnerabilitySuppressionGrid({
                customerId,
                vulnerabilityId: id,
                pagination: params.pagination,
              }),
            );
          }}
        />
      )}
    </CardStyled>
  );
};
