export enum TableTitles {
  Patch = 'Patch',
  VendorSeverity = 'Vendor severity',
  Published = 'Published',
}

export enum ColumnKey {
  Patch = 'title',
  VendorSeverity = 'vendorSeverity',
  Published = 'publishedDate',
}
