import styled from 'styled-components';
import { ThemeProps } from 'services/interfaces';
import { Theme } from 'styles/theme/types';
import { Box } from 'shared';

type LinkContainerProps = {
  theme: Theme;
  hasBorder?: boolean;
};

export const LinkContainer = styled(Box)<LinkContainerProps>`
  font-size: ${({ theme }: LinkContainerProps) => theme.sizes[4]};
  font-weight: ${({ theme }: LinkContainerProps) => theme.fontWeights.bold};
  margin-top: ${({ theme }: LinkContainerProps) => theme.sizes['4.5']};
  padding: ${({ theme }: LinkContainerProps) => `${theme.sizes[0]} ${theme.sizes['2.5']}`};
  @media (max-width: ${({ theme }: LinkContainerProps) => theme.breakpoints.md}) {
    padding: ${({ theme, hasBorder }: LinkContainerProps) =>
      hasBorder ? `${theme.sizes['4.5']} ${theme.sizes[0]} ${theme.sizes[0]}` : theme.sizes[0]};
  }

  .anticon {
    margin-left: ${({ theme }: LinkContainerProps) => theme.sizes['1.5']};
    transition: all 0.1s ease-out;
  }

  &:not(:disabled) {
    &:hover,
    &.hover,
    &:focus,
    &:active,
    &.active {
      color: ${({ theme }: ThemeProps) => `${theme.colors.brown[500]}`};

      .anticon {
        transform: translateX(2px);

        &:after {
          opacity: 0;
        }
      }
    }
  }
`;
