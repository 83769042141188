import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import ReactECharts from 'echarts-for-react';
import { Col, Row, Typography } from 'antd';
import { breakpoints, getCssVar } from 'styles/theme';

type Props = {
  theme: Theme;
};

export const ReactEChartsStyled = styled(ReactECharts)`
  border-radius: ${({ theme }: Props) => theme.radius.sm};
  height: ${({ theme }: Props) => theme.sizes[5]} !important;
  overflow: hidden;
`;

export const ReactEChartsTotal = styled.div`
  font-size: ${({ theme }: Props) => theme.sizes[9]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.black};
`;

export const ReactEChartsTotalLabel = styled.div`
  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 0;
  }
`;

export const RowContainer = styled(Row)`
  @media (min-width: ${breakpoints.sm}) {
    flex-wrap: nowrap;
  }
`;

export const ColTotal = styled(Col)`
  flex: 1 1 auto;
  text-align: center;
  @media (min-width: ${breakpoints.sm}) {
    flex: 0 0 auto;
  }
`;

export const TitleParagraph = styled(Typography.Paragraph)`
  strong {
    color: ${getCssVar('textColor')};
    font-size: ${({ theme }) => theme.spacing[4]} !important;
    font-weight: ${({ theme }) => theme.fontWeights.bold} !important;
  }

  @media (max-width: ${breakpoints.sm}) {
    text-align: center;
  }
`;

export const ReactEChartsLegend = styled(Row)`
  margin-bottom: ${({ theme }: Props) => theme.sizes[4]};
  margin-top: ${({ theme }: Props) => theme.sizes[4]};
  @media (max-width: ${breakpoints.xxl}) {
    .legend-item {
      width: 100%;
    }

    .legend-item-value {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    justify-content: center;
  }
`;
