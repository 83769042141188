import { colors } from '../colors';
import { icons } from '../icons';
import { fontWeights } from '../fontWeights';

export const login = {
  logoDesktop: 'logo-nasstar.svg',
  logoMobile: 'logo-nasstar-mobile.svg',
  logoWidth: '190px',
  logoHeight: '29px',
  formLogo: false,
  footerLogo: icons.poweredByNormLogo,
  footerLogoMobile: icons.poweredByNormLogoMobile,
  footerLogoWidth: '110px',
  footerLogoHeight: '25px',
  rightBackground: 'nasstar',
  altLinkColor: colors.blue[550],
  altLinkColorMobile: colors.yellow[460],
  inputTwoFABorderColor: colors.gray[80],
  inputTwoFABorderColorHover: '',

  footerLogoColor: '',

  titleFontSize: '26px',
  titleFontWeight: fontWeights.bold,
  titleLineHeight: '30px',

  strengthColors: [colors.red[450], colors.yellow[460], colors.yellow[460], colors.green[700]],
};
