import React from 'react';
import { Box } from 'shared';
import { ColumnsType } from 'antd/es/table/interface';
import { ActiveServicesItemSchema } from 'services/api';
import { emptyDataString } from 'app-constants';
import { getCapitalizedString, getFormattedDate } from 'services/helpers';
import { StatusColoring } from '../StatusColoring';

export const columns: ColumnsType<ActiveServicesItemSchema> = [
  {
    key: 'name',
    title: 'Data Type',
    dataIndex: 'name',
    render: (name: string) => {
      return <>{getCapitalizedString(name)}</>;
    },
  },
  {
    key: 'collectedDateTime',
    title: 'Data last received',
    dataIndex: 'collectedDateTime',
    render: (collectedDateTime: number) => <Box>{getFormattedDate(collectedDateTime)}</Box>,
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) =>
      !status ? (
        emptyDataString
      ) : (
        <Box className='status'>
          <StatusColoring status={status} />
        </Box>
      ),
  },
];
