import React from 'react';
import { getCapitalizedString, getFormattedDate } from 'services/helpers';
import { ColumnsType } from 'antd/es/table/interface';
import { PatchStatus, RecentAssetPatches } from 'services/api';
import { Tooltip } from 'antd';
import { ColumnKey, TableTitles } from '../types';
import { TagColoring } from '../../../../PatchManagementPatches/components/TagColoring';
import { Box } from '../../../../../shared';
import { SeverityOptionLabel, SeverityOptions } from '../../../../PatchManagementPatches/constants';

export const getTableColumns = (
  patchStatus: PatchStatus,
  isMobile: boolean,
): ColumnsType<RecentAssetPatches> => [
  {
    key: ColumnKey.Patch,
    title: TableTitles.Patch,
    dataIndex: ColumnKey.Patch,
    render: (value: string) => (
      <Tooltip
        title={value}
        placement='top'
        trigger={isMobile ? 'click' : 'hover'}
        style={{ whiteSpace: 'nowrap' }}
      >
        <Box maxW='250px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
          {value}
        </Box>
      </Tooltip>
    ),
    align: 'left',
    width: '100%',
  },
  patchStatus === PatchStatus.MISSING
    ? {
        key: ColumnKey.VendorSeverity,
        title: TableTitles.VendorSeverity,
        dataIndex: ColumnKey.VendorSeverity,
        render: (value: string) => {
          const item = SeverityOptions.find((option) => option.value === value);

          return (
            <TagColoring title={getCapitalizedString(item?.label || SeverityOptionLabel.MINIMAL)} />
          );
        },
        className: 'right-head-title',
        align: 'right',
        width: '150px',
      }
    : {
        key: ColumnKey.Published,
        title: TableTitles.Published,
        dataIndex: ColumnKey.Published,
        render: (value: string) => <>{getFormattedDate(value, '', true)}</>,
        showSorterTooltip: false,
        className: 'right-head-title',
        align: 'right',
        width: '120px',
      },
];
