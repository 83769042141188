import React from 'react';
import { Box, Icon } from 'shared';
import { useTheme } from 'styled-components';
import { PreviewText } from '../style';
import { getCssVar } from '../../../styles/theme';
import { Spacer } from '../../../shared/TableFilters/styled';

interface IProps {
  onPress?: () => void;
}

export const Preview = ({ onPress }: IProps) => {
  const theme = useTheme();

  return (
    <Box onClick={() => onPress?.()} cursor='pointer' display='flex' alignItems='center'>
      <Icon component={theme.icons.preview} color={getCssVar('primaryColor')} />
      <Spacer w='5' />
      <PreviewText>Preview</PreviewText>
    </Box>
  );
};
