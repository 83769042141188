import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  useApp,
  useAssetDetails,
  useAssetDetailsPotentialVulnerabilities,
  useCMDBPotentialVulnerabilities,
  useCustomer,
  useLoading,
} from 'store';
import { StackedHorizontalBarFull } from 'shared/charts';
import { Card } from 'shared';
import { Values } from 'shared/charts/types';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { VulnerabilityBySeveritySchema, VulnerabilityStatus } from 'services/api';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { assetDetails } from 'store/assetDetails/slice';
import { useTheme } from 'styled-components';

export const VulnerabilitiesLevelChart = () => {
  const theme = useTheme();
  const ref = useRef(false);
  const customer = useCustomer();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useApp();
  const dispatch = useDispatch();
  const isPotentialVulnerabilitiesActive = useAssetDetailsPotentialVulnerabilities();
  const isCMDBPotentialVulnerabilities = useCMDBPotentialVulnerabilities();
  const { vulnerabilitiesBySeverity } = useAssetDetails();
  const loading = useLoading([assetDetailsApi.getVulnerabilitiesBySeverity.typePrefix]);

  const allVulnerabilities = undefined;

  const getVulnerabilitiesBySeverityValues = () =>
    [...vulnerabilitiesBySeverity]
      .sort((valueA: VulnerabilityBySeveritySchema, valueB: VulnerabilityBySeveritySchema) => {
        if (valueA.criticality && valueB.criticality) {
          return Number(valueB.criticality) - Number(valueA.criticality);
        }

        return 0;
      })
      .reduce((acc: Values, value: VulnerabilityBySeveritySchema) => {
        const key = getSeverityLevelName(value.criticality || '');

        if (value.count) {
          return { ...acc, [key]: value.count };
        }

        return acc;
      }, {});

  React.useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      dispatch(
        assetDetails.actions.setShowPotentialVulnerabilities(isCMDBPotentialVulnerabilities),
      );
    }
  }, [dispatch, isCMDBPotentialVulnerabilities]);

  React.useEffect(() => {
    dispatch(
      assetDetailsApi.getVulnerabilitiesBySeverity({
        customerId: customer,
        hostId: id,
        status: isPotentialVulnerabilitiesActive
          ? allVulnerabilities
          : VulnerabilityStatus.CONFIRMED,
      }),
    );
  }, [dispatch, customer, id, history, isPotentialVulnerabilitiesActive, allVulnerabilities]);

  return (
    <Card
      marginBottom={theme.sizes[6]}
      showLoader={loading[assetDetailsApi.getVulnerabilitiesBySeverity.typePrefix]}
      cardPadding={!isMobile ? theme.spacing[2] : `${theme.spacing[0]} ${theme.spacing[2]}`}
    >
      {!loading[assetDetailsApi.getVulnerabilitiesBySeverity.typePrefix] && (
        <StackedHorizontalBarFull
          values={getVulnerabilitiesBySeverityValues()}
          fullInformation
          title='Vulnerabilities by severity level'
        />
      )}
    </Card>
  );
};
