import styled from 'styled-components';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { ThemeProps } from 'services/interfaces';
import { Box } from 'shared';
import { breakpoints } from 'styles/theme';

export const SubTitleStyled = styled(SubTitle)<ThemeProps>`
  @media (max-width: ${breakpoints.xxlMax}) {
    > div {
      margin-right: 3px;
    }
  }
`;

export const IncidentTypeWrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
