import moment from 'moment';
import { CertificateName, CertificateStatusSchema } from 'services/api';
import { HistoryState } from 'services/interfaces';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { Theme } from 'styles/theme/types';
import { ABOUT_TO_EXPIRE_MONTHS, CertificateIcons } from './constants';

export const getLayoutConfig = (historyState?: HistoryState, pathname?: string): InnerLayout => ({
  title: 'Process',
  backLink: historyState && historyState.backTo?.route !== pathname && (
    <BackLink historyState={historyState} />
  ),
});

export const getIconName = (certificateTitle: string | undefined, status?: string) => {
  switch (certificateTitle) {
    case CertificateName.CYBER_ESSENTIALS:
    case CertificateName.CYBER_ESSENTIALS_PLUS:
      return status ? CertificateIcons.ActiveCyberEssentials : CertificateIcons.CyberEssentials;
    case CertificateName.ISO27001:
      return status ? CertificateIcons.ActiveISO : CertificateIcons.ISO;
    default:
      return status ? CertificateIcons.ActiveCyberEssentials : CertificateIcons.CyberEssentials;
  }
};

export const getStatusColor = (
  expiration: moment.Moment | null,
  isCertificateSoonExpired: boolean | null,
  status: string | undefined,
  theme: Theme,
) => {
  if (status && expiration?.isBefore(moment())) {
    return theme.colorSet.process.expired;
  }

  switch (status) {
    case CertificateStatusSchema.CERTIFIED:
    case CertificateStatusSchema.DELIVERED:
    case CertificateStatusSchema.LIVE:
      if (isCertificateSoonExpired) {
        return theme.colorSet.process.inProgress;
      }

      return theme.colorSet.process.success;
    case CertificateStatusSchema.CANCELLED:
    case CertificateStatusSchema.FAILED:
      return theme.colorSet.process.expired;
    case CertificateStatusSchema.IN_DELIVERY:
    case CertificateStatusSchema.ORDER_RECEIVED:
    case CertificateStatusSchema.PENDING_DELIVERY:
    case CertificateStatusSchema.ORDER_ACKNOWLEDGED:
      return theme.colorSet.process.inProgress;
    default:
      return null;
  }
};

export const isCertificateSoonExpiredOrExpired = (expirationDate?: number): boolean => {
  const currentDate = moment();
  const expiration = moment(expirationDate);

  const isCertificateSoonExpire =
    expiration && expiration.diff(currentDate, 'month') < ABOUT_TO_EXPIRE_MONTHS;

  const isStatusExpired = expiration.isBefore(currentDate);

  return isStatusExpired || isCertificateSoonExpire;
};

export const isCertificateCertified = (status?: string): boolean => {
  return (
    status === CertificateStatusSchema.LIVE ||
    status === CertificateStatusSchema.CERTIFIED ||
    status === CertificateStatusSchema.DELIVERED
  );
};

export const isStatusInProgress = (status?: string): boolean => {
  return (
    status === CertificateStatusSchema.IN_DELIVERY ||
    status === CertificateStatusSchema.ORDER_RECEIVED ||
    status === CertificateStatusSchema.PENDING_DELIVERY ||
    status === CertificateStatusSchema.ORDER_ACKNOWLEDGED
  );
};

export const isStatusFailed = (status?: string): boolean =>
  status === CertificateStatusSchema.FAILED;
