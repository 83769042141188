import { Col, Row } from 'antd';
import { InnerLayout } from 'shared';
import { useRowGap } from 'services/hooks/useRowGap';
import { getLayoutConfig } from './helpers';
import {
  AverageConfidence,
  Culture,
  CurrentRefresherTests,
  CurrentTrainingStatus,
  LicenseUsage,
  PhishingLastThirtyDays,
  PhishingByMonth,
} from './components';

export const People = () => {
  const layoutConfig = getLayoutConfig();
  const { gapNumber } = useRowGap();

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} xs={24} md={12} xxl={8}>
          <CurrentTrainingStatus />
        </Col>

        <Col span={gapNumber} xs={24} md={12} xxl={8}>
          <LicenseUsage />
        </Col>

        <Col span={gapNumber} xs={24} md={12} xxl={8}>
          <CurrentRefresherTests />
        </Col>

        <Col span={gapNumber} xs={24} xxl={12}>
          <AverageConfidence />
        </Col>

        <Col span={gapNumber} xs={24} xxl={12}>
          <Culture />
        </Col>

        <Col span={gapNumber} xs={24} xxl={12}>
          <PhishingByMonth />
        </Col>

        <Col span={gapNumber} xs={24} xxl={12}>
          <PhishingLastThirtyDays />
        </Col>
      </Row>
    </InnerLayout>
  );
};
