import React from 'react';
import { PageWrapper, ServiceChangesTable } from './styled';

export interface Data {
  id: number | string;
  name: string;
  status: string;
  eta: string;
}

interface Props {
  data: Data[];
}

export function PdfPage13({ data }: Props) {
  return (
    <PageWrapper>
      <ServiceChangesTable>
        <tbody>
          {data.map(({ id, name, status, eta }) => (
            <tr key={id}>
              <td>{name}</td>
              <td>{status}</td>
              <td>{eta}</td>
            </tr>
          ))}
        </tbody>
      </ServiceChangesTable>
    </PageWrapper>
  );
}
