import { Box } from 'shared';
import { fontWeights } from 'styles/theme';

export const DEFAULT_NOTIFICATION_DURATION = 10; // 10 seconds

export const loadingNotificationMessage = { content: 'Action in progress...', duration: 240 };
export const successNotificationMessage = {
  content: 'Successfully completed',
  duration: DEFAULT_NOTIFICATION_DURATION,
};

export const errorDefaultNotificationMessage = {
  content: (
    <Box>
      Oops! Something went wrong. Please try again. If still experiencing problems, please contact
      <Box fontWeight={fontWeights.medium}>support@normcyber.com</Box>
    </Box>
  ),
  duration: DEFAULT_NOTIFICATION_DURATION,
};

export const customError = (message: string, duration?: number) => ({
  content: message,
  duration: duration || DEFAULT_NOTIFICATION_DURATION,
});

export enum ActionNames {
  Download = 'Download',
  Delete = 'Delete',
}
