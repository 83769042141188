import { renderValue } from 'pages/ServicesIntegrations/helpers';
import { SentinelOneCredentialsType } from 'pages/ServicesIntegrations/types';
import { UnmaskedCredential } from 'pages/ServiceIntegrationDetails/components';
import { useUserPermissions } from 'pages/ServicesIntegrations/hooks';
import { ConnectionDetailsItem } from '../../ConnectionDetailsItem';

type SentinelOneLayoutProps = {
  credentials: SentinelOneCredentialsType;
};

export const SentinelOneLayout = ({ credentials }: SentinelOneLayoutProps) => {
  const { canView } = useUserPermissions();

  return (
    <>
      <ConnectionDetailsItem
        label='Tenant URL'
        value={
          canView && credentials?.tenantUrl ? (
            <UnmaskedCredential
              name='tenantUrl'
              label='Tenant URL'
              defaultValue={credentials?.tenantUrl}
            />
          ) : (
            renderValue(credentials?.tenantUrl)
          )
        }
      />
      <ConnectionDetailsItem label='API Token' value={renderValue(credentials?.apiToken)} />
    </>
  );
};
