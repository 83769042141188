import styled from 'styled-components';
import { Theme } from 'styles/theme/types';

export const CommentContainer = styled.div`
  border-top: ${({ theme }: { theme: Theme }) =>
    `${theme.sizes['0.5']} solid ${theme.colors.gray[70]}`};
  padding: ${({ theme }: { theme: Theme }) => `${theme.spacing[6]} ${theme.spacing['2.5']}`};
  .anticon {
    color: ${({ theme }: { theme: Theme }) => theme.colors.gray[300]};
    cursor: pointer;
    &:hover {
      color: ${({ theme }: { theme: Theme }) => theme.colors.red[600]};
    }
  }
`;

export const CreateNoteContainer = styled.div`
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[6]};
  padding-left: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
  width: 70%;
  button:last-child {
    margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[3]};
  }
`;

export const Name = styled.span`
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes['3.5']};
  font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.light};
`;

export const DaysAgo = styled.span`
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes['3.5']};
  font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.light};
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
  opacity: 0.5;
`;
