import React from 'react';
import { Routes, TwoFASteps } from 'services/entities';
import { icons, spacing } from 'styles/theme';
import { OuterLayoutProps as OuterLayoutConfig, Alert, Icon, Box } from 'shared';
import { isTokenExpired, showMessage } from 'services/helpers';
import { AppDispatch, auth, MessageType } from 'store';

export const getLayoutConfig = (
  step: number,
  error?: string,
  isMobile?: boolean,
): OuterLayoutConfig | undefined => {
  switch (step) {
    case TwoFASteps.INITIAL:
      return {
        title: 'Login',
        subTitle: error ? <Alert type='error' message={error} /> : null,
        altLink: { title: 'Forgot password?', to: Routes.FORGOT_PASSWORD },
      };
    case TwoFASteps.SETUP:
      return {
        title: (
          <Box mt={isMobile ? spacing[20] : undefined}>
            <Box>Set up</Box>
            <Box>2-step authentication</Box>
          </Box>
        ),
        subTitle: (
          <>
            <div>Protect your account with an extra layer of security.</div>
            {error ? <Alert type='error' message={error} /> : <></>}
          </>
        ),
      };
    case TwoFASteps.CONFIRM:
      return {
        altLink: {
          to: Routes.ROOT,
          title: (
            <Box d='flex' align='center' justify='center'>
              <Icon component={icons.arrow_left_alt} />
              <Box ml={spacing[1]}>Return to login</Box>
            </Box>
          ),
        },
      };
    default:
      return {};
  }
};

export const checkLoginTokenExpired = (token: string, dispatch: AppDispatch) => {
  if (isTokenExpired(token)) {
    dispatch(auth.actions.setStepToInitial());
    showMessage({ content: 'Session expired' }, MessageType.Error);

    return true;
  }

  return false;
};
