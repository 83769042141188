import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PatchDetailsResponse,
  PatchesByIdsResponse,
  PatchesListResponse,
  VulnerabilitiesResponse,
} from 'services/api';
import { patchesApi } from './thunks';

interface InitialState {
  data: PatchesListResponse;
  details: PatchDetailsResponse;
  supersededBy: PatchesByIdsResponse;
  superseded: PatchesByIdsResponse;
  resolvedVulnerabilities: VulnerabilitiesResponse;
}

const initialState: InitialState = {
  data: {},
  details: {},
  supersededBy: [],
  superseded: [],
  resolvedVulnerabilities: {},
};

const extraReducers = {
  [patchesApi.getPatches.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchesListResponse>,
  ) => {
    state.data = action.payload;
  },
  [patchesApi.getPatches.rejected.toString()]: (state: InitialState) => {
    state.data = {};
  },
  [patchesApi.getPatchDetails.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchDetailsResponse>,
  ) => {
    state.details = action.payload;
  },
  [patchesApi.getPatchDetails.rejected.toString()]: (state: InitialState) => {
    state.details = {};
  },
  [patchesApi.getSupersededBy.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchesByIdsResponse>,
  ) => {
    state.supersededBy = action.payload;
  },
  [patchesApi.getSupersededBy.rejected.toString()]: (state: InitialState) => {
    state.supersededBy = [];
  },
  [patchesApi.getSuperseded.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchesByIdsResponse>,
  ) => {
    state.superseded = action.payload;
  },
  [patchesApi.getSuperseded.rejected.toString()]: (state: InitialState) => {
    state.superseded = [];
  },
  [patchesApi.getResolvedVulnerabilities.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<VulnerabilitiesResponse>,
  ) => {
    state.resolvedVulnerabilities = action.payload;
  },
  [patchesApi.getResolvedVulnerabilities.rejected.toString()]: (state: InitialState) => {
    state.resolvedVulnerabilities = {};
  },
};

export const patches = createSlice({
  name: 'patches',
  initialState,
  reducers: {
    resetValues: (state: InitialState) => {
      state.resolvedVulnerabilities = {};
    },
  },
  extraReducers,
});
