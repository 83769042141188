import React from 'react';
import { useHistory } from 'react-router';
import { History } from 'history';
import { Box, Icon } from 'shared';
import { useApp, useLoading, useNewsNotifications } from 'store';
import { useTheme } from 'styled-components';
import { NotificationMessage, NotificationProp, StyledCard } from '../styled';
import { newsNotificationsApi } from '../../../store/newsNotifications/thunks';
import { getCardPadding, getHeadStyles } from '../helpers';
import { Spacer } from '../../../shared/TableFilters/styled';
import { IconType } from '../../Notifications/components/IconType';
import { getCssVar, icons } from '../../../styles/theme';
import { Routes } from '../../../services/entities';
import { backlinkRoutes } from '../../../app-constants';

export const AdminNotificationDetails = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile } = useApp();
  const { notificationDetails } = useNewsNotifications();
  const { title, type = '', message = '', id } = notificationDetails;
  const loading = useLoading([newsNotificationsApi.getNotificationDetails.typePrefix]);

  const redirectToNotificationEdit = (history: History, id: string) => {
    history.push(Routes.NOTIFICATIONS_EDIT.replace(':id', id), {
      backTo: {
        route: Routes.NOTIFICATIONS,
        search: history.location.search,
        title: `Back to ${backlinkRoutes[Routes.NOTIFICATIONS]}`,
      },
    });
  };

  return (
    <StyledCard
      title={
        <Box display='flex' flexDirection='row' alignItems='center' cursor='pointer'>
          Overview
          <Box
            display='flex'
            flexDirection='row'
            ml='10px'
            onClick={() => redirectToNotificationEdit(history, id as string)}
          >
            <Icon component={icons.edit} color={getCssVar('primaryColor')} />
            <Box fontSize='16px' fontWeight={700} color={getCssVar('primaryColor')}>
              Edit
            </Box>
          </Box>
        </Box>
      }
      headStyles={getHeadStyles(isMobile, theme)}
      bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
      cardPadding={getCardPadding(isMobile, theme)}
      showLoader={loading[newsNotificationsApi.getNotificationDetails.typePrefix]}
    >
      <Spacer h='30px' />
      <NotificationProp>Type</NotificationProp>
      <Spacer h='20px' />
      <Box display='flex' flexDirection='row' alignItems='center' fontSize='15px'>
        <IconType type={type} />
        <Spacer w='10' />
        {type}
      </Box>
      <Spacer h='30px' />
      <NotificationProp>Title</NotificationProp>
      <Spacer h='20px' />
      <Box fontSize='15px'>{title}</Box>
      <Spacer h='30px' />
      <NotificationProp>Message</NotificationProp>
      <Spacer h='20px' />
      <NotificationMessage dangerouslySetInnerHTML={{ __html: message }} />
    </StyledCard>
  );
};
