import { Table } from 'shared';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

export const CvssTable = styled(Table)`
  margin-bottom: 24px;
  margin-top: 12px;

  .ant-table-thead {
    .ant-table-cell {
      height: 32px;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        height: 24px;
      }
    }
  }
`;

export const Header2 = styled.h1`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes[5]};
  font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.semibold};
`;

type TextProps = {
  hardBreak?: boolean;
};

export const Text = styled.p<TextProps>`
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
  line-break: ${({ hardBreak }: TextProps) => (hardBreak ? 'anywhere' : 'normal')};
`;
