import React from 'react';
import { useDispatch } from 'react-redux';
import { cybersafetyApi, useApp, useCustomer, useLoading, useRefresherRates } from 'store';
import { isEmptyObject } from 'services/helpers';
import { Card } from 'shared';
import { SegmentedPie } from 'shared/charts/SegmentedPie/SegmentedPie';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { useTheme } from 'styled-components';
import { TooltipTitles } from '../../constants';

export const CurrentRefresherTests = () => {
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const customerId = useCustomer();
  const dispatch = useDispatch();
  const refresherRates = useRefresherRates();
  const loading = useLoading([cybersafetyApi.getRefresherRates.typePrefix]);

  const refreshingTestsColors = {
    passed: theme.colorSet.pieChart.CurrentTrainingStatusSuccess,
    ignored: theme.colorSet.pieChart.CurrentRefresherTestsIgnored,
    failed: theme.colorSet.pieChart.CurrentRefresherTestsFailed,
  };

  React.useEffect(() => {
    dispatch(
      cybersafetyApi.getRefresherRates({
        customerId,
      }),
    );
  }, [dispatch, customerId]);

  return (
    <Card
      title='Current refresher tests'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles.CurrentRefresherTests}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2] }
          : { titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={!isMobile ? theme.sizes[6] : theme.sizes[5]}
      showLoader={loading[cybersafetyApi.getRefresherRates.typePrefix]}
    >
      {refresherRates && !isEmptyObject(refresherRates) ? (
        <SegmentedPie
          values={refresherRates}
          colors={refreshingTestsColors}
          entitiesName='tests'
          style={isMobile ? { height: '240px' } : { height: '320px' }}
          isMobile={isMobile}
          isMenuCollapsed={isMenuCollapsed}
          isLoading={loading[cybersafetyApi.getRefresherRates.typePrefix]}
        />
      ) : (
        emptyMessage('No refresher tests yet')
      )}
    </Card>
  );
};
