import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, ScrollContainer, Table } from 'shared';
import { useApp, useCustomer, useLoading, usePlatform, usePmPatches } from 'store';
import { patchesApi } from 'store/patchManagement';
import { useTheme } from 'styled-components';
import { columns } from './TableColumns';

export const Supersedes = () => {
  const { isMobile } = useApp();
  const { superseded } = usePmPatches();
  const platform = usePlatform();
  const dispatch = useDispatch();
  const { details } = usePmPatches();

  const customerId = useCustomer();
  const theme = useTheme();
  const loading = useLoading([patchesApi.getSuperseded.typePrefix])[
    patchesApi.getSuperseded.typePrefix
  ];

  React.useEffect(() => {
    dispatch(
      patchesApi.getSuperseded({
        customerId,
        platform,
        requestBody: { patchId: details.supersedes },
      }),
    );
  }, [dispatch, platform, customerId, details.supersedes]);

  return (
    <Card cardPadding={isMobile ? theme.sizes[5] : theme.sizes['5.5']} bodyPadding={theme.sizes[0]}>
      <ScrollContainer width='400px' $isScrollTop>
        <Table
          columns={columns}
          dataSource={superseded || []}
          tableLayout='auto'
          pagination={false}
          scroll={!isMobile ? { x: 400 } : {}}
          rowKey='patchId'
          loading={loading}
        />
      </ScrollContainer>
    </Card>
  );
};
