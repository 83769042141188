import { minLengthToSearch } from 'app-constants';
import { AlarmRiskEnum, AlarmStatusEnum, Filter, OrderBy } from 'services/api';
import { OptionType } from 'shared/Table/types';
import { InputType } from '../../services/api/models/InputType';

export const searchPlaceholder = 'Search by Description/Source/Destination';
export const searchFields = ['description', 'sourceIp', 'destHostName', 'destIp'];

export const searchSettings = {
  fields: searchFields,
  min: minLengthToSearch,
  placeholder: searchPlaceholder,
};

export enum TableTitles {
  Risk = 'Risk',
  Created = 'Created',
  Description = 'Description',
  Tactic = 'Tactic',
  Source = 'Source',
  Destination = 'Destination',
  Status = 'Status',
  Updated = 'Updated',
}

export enum ColumnKey {
  Risk = 'risk',
  Created = 'created',
  Name = 'name',
  Tactic = 'tactic',
  Source = 'sourceIp',
  Destination = 'destIp',
  Status = 'status',
  Updated = 'updated',
}

export enum StatusTitles {
  TuningRequired = 'Tuning Required',
  Investigating = 'Investigating',
  Open = 'Open',
  Closed = 'Closed',
  Withcustomer = 'With customer',
  ReOpened = 'Re-opened',
}

export enum RiskOptionLabel {
  Minimal = 'Minimal',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Critical = 'Critical',
}

export const optionsRisk: OptionType[] = Object.values(AlarmRiskEnum).map((alarmRisk) => ({
  value: alarmRisk,
  label: alarmRisk,
}));

export const optionsStatus: OptionType[] = Object.values(AlarmStatusEnum).map((alarmStatus) => ({
  value: alarmStatus,
  label: alarmStatus,
}));

export const filterFields = [
  {
    field: 'risk',
    options: optionsRisk,
    label: 'Risk',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'status',
    options: optionsStatus,
    label: 'Status',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'created',
    label: TableTitles.Created,
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export const initialOrderBy = {
  field: 'updated',
  type: OrderBy.type.DESC,
};
