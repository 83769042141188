import { Grid } from 'antd';
import { useEffect, useState } from 'react';
import { useApp } from 'store';

enum Gaps {
  Small = 16,
  Medium = 24,
  Large = 32,
}

export const useRowGap = () => {
  const [gap, setGap] = useState(Gaps.Large);
  const breakpoints = Grid.useBreakpoint();
  const { isMobile } = useApp();

  useEffect(() => {
    if (breakpoints.xl) {
      setGap(Gaps.Large);
    } else if (isMobile) {
      setGap(Gaps.Small);
    } else {
      setGap(Gaps.Medium);
    }
  }, [breakpoints, isMobile]);

  return { gapString: `${gap}px`, gapNumber: gap };
};
