import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { RequestStatus } from 'store/loading/slice';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { InnerLayout } from 'shared';
import { HistoryState } from 'services/interfaces';
import { useRowGap } from 'services/hooks/useRowGap';
import { Routes } from 'services/entities';
import {
  AppDispatch,
  cmdb,
  useApp,
  useCustomer,
  useLoading,
  usePotentialVulnerabilities,
  useVulnerability,
  useVulnerabilitySuppressionGrid,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { getParametersFromPath } from 'services/helpers';
import { getFiltersFields } from 'pages/CmdbHostDetails/components/Vulnerabilities/helpers';
import { getLayoutConfig } from './helpers';
import { lastPosition } from './constants';
import { Overview } from './components/Overview';
import { Details } from './components/Details';
import { CVE } from './components/CVE';
import { CVSS } from './components/CVSS';
import {
  confirmedVulnerabilitiesStatusFilter,
  potentialVulnerabilitiesStatusFilter,
  confirmedAndPotentialVulnerabilitiesStatusFilter,
  tabsKeys,
} from '../VulnerabilitiesList/constants';
import { Suppression } from './components/Suppression/Suppression';
import { SuppressionWrap } from './components/Suppression/styled';

export const VulnerabilityDetails = () => {
  const { state: historyState, pathname } = useLocation<HistoryState>();
  const { isMobile } = useApp();
  const customer = useCustomer();
  const isPotentialVulnerabilitiesActive = usePotentialVulnerabilities();
  const { id } = useParams<{ id: string }>();
  const dispatch: AppDispatch = useDispatch();
  const { gapNumber } = useRowGap();
  const ref = useRef(false);
  const suppressionRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const loading = useLoading([
    vulnerabilitiesApi.vulnerabilityDetails.typePrefix,
    vulnerabilitiesApi.assetVulnerabilityDetails.typePrefix,
  ]);

  const isAsset = new URLSearchParams(history.location.search).get('type') === 'asset';
  const isLoading =
    loading[
      vulnerabilitiesApi[isAsset ? 'assetVulnerabilityDetails' : 'vulnerabilityDetails'].typePrefix
    ];

  const { vulnerabilityDetails, assetVulnerabilityDetails } = useVulnerability();
  const vulnerabilitySuppression = useVulnerabilitySuppressionGrid();

  const details = useMemo(() => {
    return isAsset ? assetVulnerabilityDetails : vulnerabilityDetails;
  }, [vulnerabilityDetails, assetVulnerabilityDetails, isAsset]);

  const layoutConfig = getLayoutConfig(
    details.title,
    details.severity_level,
    details.vulnerabilityStatus,
    historyState,
    pathname,
    vulnerabilitySuppression,
    suppressionRef,
    isAsset,
  );

  const getVulnerabilityDetails = useCallback(async () => {
    // Need to get filter from URL to pass it to the API vulnerabilityDetails request to calculate affected hosts
    const getFilter = () => {
      const searchParams = getParametersFromPath(history.location.search);
      const filter = searchParams?.filter || {};
      const activeTab = searchParams?.tab;
      let vulnerabilityStatusFilter = confirmedVulnerabilitiesStatusFilter;

      if (isPotentialVulnerabilitiesActive || activeTab === tabsKeys.suppressed) {
        vulnerabilityStatusFilter = confirmedAndPotentialVulnerabilitiesStatusFilter;
      }

      if (activeTab === tabsKeys.potential) {
        vulnerabilityStatusFilter = potentialVulnerabilitiesStatusFilter;
      }

      const filterFields = getFiltersFields(
        filter.fields || [],
        vulnerabilityStatusFilter.fields[0],
      );

      return {
        filter: {
          ...filter,
          fields: filterFields,
        },
      };
    };

    const result = await dispatch(
      vulnerabilitiesApi[isAsset ? 'assetVulnerabilityDetails' : 'vulnerabilityDetails']({
        customerId: customer,
        vulnerabilityId: id,
        ...(isAsset ? {} : getFilter()),
      }),
    );

    if (!isAsset) {
      dispatch(cmdb.actions.setShowPotentialVulnerabilities(isPotentialVulnerabilitiesActive));
    }

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      history.push(Routes.NOT_FOUND);
    }
  }, [history, dispatch, customer, id, isAsset, isPotentialVulnerabilitiesActive]);

  React.useEffect(() => {
    window.scroll(0, 0);
    if (!ref.current) {
      getVulnerabilityDetails();
      ref.current = true;

      return;
    }

    history.push(Routes.VULNERABILITIES_LIST);
  }, [history, getVulnerabilityDetails]);

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col xs={24} md={24} xxl={16} order={isMobile ? lastPosition : 0}>
          <Overview
            diagnosis={details.diagnosis}
            consequence={details.consequence}
            solution={details.solution}
            result={assetVulnerabilityDetails.results}
            port={assetVulnerabilityDetails.port}
            isAsset={isAsset}
            isLoading={isLoading}
          />
          {!isAsset && (
            <SuppressionWrap ref={suppressionRef}>
              <Suppression suppressionRef={suppressionRef} />
            </SuppressionWrap>
          )}
        </Col>
        <Col xs={24} md={24} xxl={8}>
          <Row gutter={[gapNumber, gapNumber]}>
            <Col xs={24}>
              <Details
                isAsset={isAsset}
                qid={details.id}
                title={details.title}
                type={details.vuln_type}
                category={details.category}
                vendorReference={details.vendor_reference_list?.vendor_reference}
                hosts={vulnerabilityDetails.affectedHosts}
                pci={details.pci_flag}
                patch={details.patchable}
                normPatchable={vulnerabilityDetails.norm_patchable}
                publishedDate={details.published_datetime}
                isLoading={isLoading}
                status={details.vulnerabilityStatus}
              />
            </Col>

            {(details.cvss || details.cvss_v3) && (
              <Col xs={24}>
                <CVSS
                  cvss={details.cvss || {}}
                  cvssV3={details.cvss_v3 || {}}
                  isLoading={isLoading}
                  qid={details.id}
                  title={details.title}
                />
              </Col>
            )}

            {details.cve_list?.cve && Array.isArray(details.cve_list?.cve) && (
              <Col xs={24}>
                <CVE cveList={vulnerabilityDetails.cve_list?.cve} isLoading={isLoading} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </InnerLayout>
  );
};
