/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerId } from '../models/CustomerId';
import type { ExportDocumentResponse } from '../models/ExportDocumentResponse';
import type { Filters } from '../models/Filters';
import type { ImportSoftwareDocumentResponse } from '../models/ImportSoftwareDocumentResponse';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { SoftwareDocumentsResponse } from '../models/SoftwareDocumentsResponse';
import type { SuccessResponse } from '../models/SuccessResponse';
import { request as __request } from '../core/request';

export class SoftwareDocumentsService {
  /**
   * Software documents list
   * Get list of software documents in Azure Blob
   * @returns SoftwareDocumentsResponse List of software documents in Azure Blob
   * @throws ApiError
   */
  public static async getSoftwareDocuments({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<SoftwareDocumentsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/software-documents`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when get software documents`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete software document
   * Delete software document from Azure Blob
   * @returns SuccessResponse Delete document from Azure Blob
   * @throws ApiError
   */
  public static async deleteSoftwareDocument({
    customerId,
    filename,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filename parameter **/
    filename: string;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/software-document`,
      query: {
        filename: filename,
      },
      errors: {
        400: `An error message when delete software document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Import (upload) software ducument
   * Import (upload) software ducument to Azure Blob
   * @returns ImportSoftwareDocumentResponse Import software document to Azure Blob
   * @throws ApiError
   */
  public static async importSoftwareDocument({
    customerId,
    filename,
    documentType,
    serviceType,
    currentChunkIndex,
    totalChunks,
    fileSize,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filename parameter **/
    filename: string;
    /** The document type parameter **/
    documentType:
      | 'CONTRACT'
      | 'TEMPLATE'
      | 'REPORT'
      | 'OTHER'
      | 'SOFTWARE AGENT'
      | 'SOFTWARE IMAGE';
    /** The service type parameter **/
    serviceType: '1' | '2' | '3';
    /** The current chunk index parameter **/
    currentChunkIndex: number;
    /** Total file's chunks **/
    totalChunks: number;
    /** Total file size **/
    fileSize: number;
    /** Import software document **/
    requestBody?: any;
  }): Promise<ImportSoftwareDocumentResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/software-document/import`,
      query: {
        filename: filename,
        documentType: documentType,
        serviceType: serviceType,
        currentChunkIndex: currentChunkIndex,
        totalChunks: totalChunks,
        fileSize: fileSize,
      },
      body: requestBody,
      errors: {
        400: `An error message when import software document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Export (download) software document
   * Export (download) software document from Azure Blob
   * @returns ExportDocumentResponse Export software document from Azure Blob
   * @throws ApiError
   */
  public static async exportSoftwareDocument({
    customerId,
    filename,
  }: {
    /** Customer id **/
    customerId: string;
    /** The filename parameter **/
    filename: string;
  }): Promise<ExportDocumentResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/software-document/export`,
      query: {
        filename: filename,
      },
      errors: {
        400: `An error message when export software document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
