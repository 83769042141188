/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectionCredentialResponse } from '../models/ConnectionCredentialResponse';
import type { ConnectionCredentials } from '../models/ConnectionCredentials';
import type { CustomerId } from '../models/CustomerId';
import type { EnableServiceRequest } from '../models/EnableServiceRequest';
import type { ServiceIntegrationResponse } from '../models/ServiceIntegrationResponse';
import type { ServicesIntegrationsListResponse } from '../models/ServicesIntegrationsListResponse';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { TestServiceConnectionResponse } from '../models/TestServiceConnectionResponse';
import type { UpdateGuidanceDetailsRequest } from '../models/UpdateGuidanceDetailsRequest';
import { request as __request } from '../core/request';

export class ServicesIntegrationsService {
  /**
   * Services integrations list
   * get customer Services integrations list
   * @returns ServicesIntegrationsListResponse Services integrations list
   * @throws ApiError
   */
  public static async getServices({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<ServicesIntegrationsListResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/services-integrations`,
      errors: {
        400: `An error message when getting integrations`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Service details
   * get customer Service details
   * @returns ServiceIntegrationResponse Service details
   * @throws ApiError
   */
  public static async getServiceDetails({
    customerId,
    serviceId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Service id **/
    serviceId: string;
  }): Promise<ServiceIntegrationResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/services-integrations/${serviceId}`,
      errors: {
        400: `An error message when getting service details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Disconnect service
   * Disconnect service
   * @returns ServiceIntegrationResponse Success response
   * @throws ApiError
   */
  public static async disconnectService({
    customerId,
    serviceId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Service id **/
    serviceId: string;
  }): Promise<ServiceIntegrationResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/services-integrations/${serviceId}`,
      errors: {
        400: `An error message when update service guidance details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Enable/disable service
   * Enable/disable service
   * @returns SuccessResponse Enable/disable service
   * @throws ApiError
   */
  public static async enableService({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Enable/disable service **/
    requestBody?: EnableServiceRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/services-integrations/activation`,
      body: requestBody,
      errors: {
        400: `An error message when getting integrations`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Update service guidance details
   * Update service guidance details
   * @returns SuccessResponse Success message response
   * @throws ApiError
   */
  public static async updateServiceGuidanceDetails({
    customerId,
    serviceId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Service id **/
    serviceId: string;
    /** Update service guidance details **/
    requestBody?: UpdateGuidanceDetailsRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/services-integrations/${serviceId}/guidance-details`,
      body: requestBody,
      errors: {
        400: `An error message when update service guidance details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Test service connection
   * Test service connection
   * @returns TestServiceConnectionResponse Service connection status
   * @throws ApiError
   */
  public static async testServiceConnection({
    customerId,
    serviceId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Service id **/
    serviceId: string;
    /** Connection credentials **/
    requestBody?: ConnectionCredentials;
  }): Promise<TestServiceConnectionResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/services-integrations/${serviceId}/test-connection`,
      body: requestBody,
      errors: {
        400: `An error message when update service guidance details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        422: `An error message for not validated request`,
      },
    });
    return result.body;
  }

  /**
   * Test existing service connection
   * Test existing service connection
   * @returns TestServiceConnectionResponse Service connection status
   * @throws ApiError
   */
  public static async testExistingServiceConnection({
    customerId,
    serviceId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Service id **/
    serviceId: string;
  }): Promise<TestServiceConnectionResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/services-integrations/${serviceId}/test-existing-connection`,
      errors: {
        400: `An error message when update service guidance details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Update service connections details
   * Update service connections details
   * @returns ServiceIntegrationResponse Service details
   * @throws ApiError
   */
  public static async updateServiceConnectionDetails({
    customerId,
    serviceId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Service id **/
    serviceId: string;
    /** Service connections details **/
    requestBody?: ConnectionCredentials;
  }): Promise<ServiceIntegrationResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/services-integrations/${serviceId}/connection-details`,
      body: requestBody,
      errors: {
        400: `An error message when update service guidance details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Service connection credential
   * get customer Service credential value
   * @returns ConnectionCredentialResponse Connection credential value
   * @throws ApiError
   */
  public static async getServiceConnectionCredential({
    customerId,
    serviceId,
    credentialName,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Service id **/
    serviceId: string;
    /** Connection credential name **/
    credentialName: string;
  }): Promise<ConnectionCredentialResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/services-integrations/${serviceId}/connection-details/${credentialName}`,
      errors: {
        400: `An error message when getting service credential`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
