import React from 'react';

export const TitleTooltipContent = () => {
  return (
    <p>
      This folder is where you can find documents and resources that, where included in your
      subscription, are free to access, download, and use.
    </p>
  );
};
