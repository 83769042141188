import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  CustomerId,
  GetPatchesByIdsRequest,
  getVulnerabilitiesByIdsRequest,
  GridRequestParams,
  OrderBy,
  PatchManagementPlatform,
  PatchManagementService,
  VulnerabilityManagementService,
} from 'services/api';

const getPatches = createAsyncThunk(
  'patch-management/patches/get',
  async (requestBody: GridRequestParams & { platform: PatchManagementPlatform }) => {
    try {
      return await PatchManagementService.getPatches(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getPatchDetails = createAsyncThunk(
  'patch-management/patch/details/get',
  async (requestBody: { customerId: string; patchId: string }) => {
    try {
      return await PatchManagementService.getPatchDetails(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getSupersededBy = createAsyncThunk(
  'patch-management/patch/supersededBy/get',
  async (requestBody: {
    customerId: CustomerId;
    platform: PatchManagementPlatform;
    requestBody: GetPatchesByIdsRequest;
    orderBy?: OrderBy;
  }) => {
    try {
      return await PatchManagementService.getPatchesByIds(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getSuperseded = createAsyncThunk(
  'patch-management/patch/superseded/get',
  async (requestBody: {
    customerId: CustomerId;
    platform: PatchManagementPlatform;
    requestBody: GetPatchesByIdsRequest;
    orderBy?: OrderBy;
  }) => {
    try {
      return await PatchManagementService.getPatchesByIds(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);
const getResolvedVulnerabilities = createAsyncThunk(
  'patch-management/patch/resolvedVulns/get',
  async (requestBody: { customerId: CustomerId; requestBody: getVulnerabilitiesByIdsRequest }) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilitiesByIds(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const patchesApi = {
  getPatches,
  getPatchDetails,
  getSupersededBy,
  getSuperseded,
  getResolvedVulnerabilities,
};
