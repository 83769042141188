import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { Box, Card } from 'shared';
import { CardBody } from 'shared/Card/components/styled';
import { getCssVar } from '../../styles/theme';

type Props = { theme: Theme };

export const StyledCard = styled(Card)`
  ${CardBody} {
    margin-bottom: ${({ theme }: Props) => theme.spacing['1.5']};
  }
`;

export const NotificationProp = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const NotificationMessage = styled.div`
  box-sizing: border-box;
  display: block;
  max-width: 80%;

  strong {
    color: ${getCssVar('textColor')};
    font-weight: bold;
  }

  td,
  th {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: normal;
    padding: 5px 14px 5px 12px;
    text-align: left;
    word-break: break-word;
  }

  a {
    word-wrap: break-word;
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    max-width: 100%;
  }
`;

export const AdminButton = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;

export const UserTitle = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }: Props) => theme.sizes['3.6']};
  font-weight: ${({ theme }: Props) => theme.fontWeights.normal};
  padding: 10px;
`;
