import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Wrapper } from './styled';
import { editorConfig } from './config';

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
}

export function Editor({ initialValue, onChange, placeholder, onBlur }: Props) {
  return (
    <Wrapper>
      <CKEditor
        config={{ ...editorConfig, placeholder }}
        editor={ClassicEditor}
        data={initialValue || ''}
        onChange={(_event: Event, editor: { getData: () => string }) => {
          onChange(editor.getData());
        }}
        onBlur={() => {
          if (typeof onBlur === 'function') {
            onBlur();
          }
        }}
      />
    </Wrapper>
  );
}
