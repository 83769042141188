/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetPatchesCountBySeverityResponse } from '../models/AssetPatchesCountBySeverityResponse';
import type { AssetsCountResponse } from '../models/AssetsCountResponse';
import type { AssetsListResponse } from '../models/AssetsListResponse';
import type { AssetsPatchesCountByPlatformResponse } from '../models/AssetsPatchesCountByPlatformResponse';
import type { CustomerId } from '../models/CustomerId';
import type { Filters } from '../models/Filters';
import type { GetPatchesByIdsRequest } from '../models/GetPatchesByIdsRequest';
import type { HostId } from '../models/HostId';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { PatchCountGrouppedByResponse } from '../models/PatchCountGrouppedByResponse';
import type { PatchDetailsResponse } from '../models/PatchDetailsResponse';
import type { PatchesByIdsResponse } from '../models/PatchesByIdsResponse';
import type { PatchesCountByStatusResponse } from '../models/PatchesCountByStatusResponse';
import type { PatchesCountResponse } from '../models/PatchesCountResponse';
import type { PatchesListResponse } from '../models/PatchesListResponse';
import type { PatchGroupBy } from '../models/PatchGroupBy';
import type { PatchId } from '../models/PatchId';
import type { PatchManagementPlatform } from '../models/PatchManagementPlatform';
import type { PatchStatus } from '../models/PatchStatus';
import type { PatchStatusFilter } from '../models/PatchStatusFilter';
import type { PatchVendorSeverityFilter } from '../models/PatchVendorSeverityFilter';
import type { RecentAssetPatchesResponse } from '../models/RecentAssetPatchesResponse';
import { request as __request } from '../core/request';

export class PatchManagementService {
  /**
   * Get Patches List
   * Get patches in patch management section
   * @returns PatchesListResponse Get Patches
   * @throws ApiError
   */
  public static async getPatches({
    customerId,
    orderBy,
    pagination,
    filter,
    platform,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
    /** Platform **/
    platform?: PatchManagementPlatform;
  }): Promise<PatchesListResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/patches`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
        platform: platform,
      },
      errors: {
        400: `An error message when get patches list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Patches by ids
   * Get patches by patch id
   * @returns PatchesByIdsResponse Get patches by ids
   * @throws ApiError
   */
  public static async getPatchesByIds({
    customerId,
    orderBy,
    platform,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy?: OrderBy;
    /** Platform **/
    platform?: PatchManagementPlatform;
    requestBody?: GetPatchesByIdsRequest;
  }): Promise<PatchesByIdsResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/patch-management/patches`,
      query: {
        orderBy: orderBy,
        platform: platform,
      },
      body: requestBody,
      errors: {
        400: `An error message when get patches by ids`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Patch Summary
   * Get patch summary by patch id
   * @returns PatchDetailsResponse Get patch summary
   * @throws ApiError
   */
  public static async getPatchDetails({
    customerId,
    patchId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** id of patch in Patch Management **/
    patchId: PatchId;
  }): Promise<PatchDetailsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/patches/${patchId}`,
      errors: {
        400: `An error message when get patch summary`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Assets List
   * Get Assets in patch management section
   * @returns AssetsListResponse Get Assets
   * @throws ApiError
   */
  public static async getAssets({
    customerId,
    orderBy,
    pagination,
    filter,
    platform,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
    /** Platform **/
    platform?: PatchManagementPlatform;
  }): Promise<AssetsListResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/assets`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
        platform: platform,
      },
      errors: {
        400: `An error message when get assets list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Patches count
   * Get Patches count
   * @returns PatchesCountResponse Get patches count
   * @throws ApiError
   */
  public static async getPatchesCount({
    customerId,
    patchStatus,
    platform,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Patch Status **/
    patchStatus?: PatchStatusFilter;
    /** Platform **/
    platform?: PatchManagementPlatform;
  }): Promise<PatchesCountResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/patches-count`,
      query: {
        patchStatus: patchStatus,
        platform: platform,
      },
      errors: {
        400: `An error message when get Patches count`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get patches count groupped by
   * Get patches count groupped by
   * @returns PatchCountGrouppedByResponse Get patches count groupped by
   * @throws ApiError
   */
  public static async getPatchesCountGrouppedby({
    customerId,
    patchStatus,
    groupBy,
    platform,
    limit,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Patch Status **/
    patchStatus?: PatchStatusFilter;
    /** Group By Parameters **/
    groupBy?: PatchGroupBy;
    /** Platform **/
    platform?: PatchManagementPlatform;
    /** Limit **/
    limit?: number;
  }): Promise<PatchCountGrouppedByResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/patches-count-groupped-by`,
      query: {
        patchStatus: patchStatus,
        groupBy: groupBy,
        platform: platform,
        limit: limit,
      },
      errors: {
        400: `An error message when Get patches count groupped by`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Assets Missing Patches By Platform
   * Get Assets Missing Patches By Platform
   * @returns AssetsPatchesCountByPlatformResponse Get Assets Missing Patches By Platform
   * @throws ApiError
   */
  public static async getAssetsMissingPatchesByPlatform({
    customerId,
    platform,
    limit,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Platform **/
    platform?: PatchManagementPlatform;
    /** Limit **/
    limit?: number;
  }): Promise<AssetsPatchesCountByPlatformResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/assets/missing-patches-by-platform`,
      query: {
        platform: platform,
        limit: limit,
      },
      errors: {
        400: `An error message when get Assets Missing Patches By Platform`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Assets Patches By Severity
   * Get Assets Patches By Severity
   * @returns AssetPatchesCountBySeverityResponse Get Assets Patches By Severity
   * @throws ApiError
   */
  public static async getAssetPatchesBySeverity({
    customerId,
    assetId,
    patchStatus,
    vendorSeverity,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** assetId **/
    assetId: HostId;
    /** Patch Status **/
    patchStatus?: PatchStatus;
    /** Patch vendor severity **/
    vendorSeverity?: PatchVendorSeverityFilter;
  }): Promise<AssetPatchesCountBySeverityResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/assets/${assetId}/patches-by-severity`,
      query: {
        patchStatus: patchStatus,
        vendorSeverity: vendorSeverity,
      },
      errors: {
        400: `An error message when get Assets Patches By Severity`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Assets Recent Patches
   * Get Assets Recent Patches
   * @returns RecentAssetPatchesResponse Get Assets Patches By Severity
   * @throws ApiError
   */
  public static async getAssetRecentPatches({
    customerId,
    assetId,
    patchStatus,
    vendorSeverity,
    limit,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** assetId **/
    assetId: HostId;
    /** Patch Status **/
    patchStatus?: PatchStatus;
    /** Patch vendor severity **/
    vendorSeverity?: PatchVendorSeverityFilter;
    /** Limit **/
    limit?: number;
  }): Promise<RecentAssetPatchesResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/assets/${assetId}/recent-patches`,
      query: {
        patchStatus: patchStatus,
        vendorSeverity: vendorSeverity,
        limit: limit,
      },
      errors: {
        400: `An error message when get Assets Recent Patches`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Patches count by status
   * Get Patches count by status
   * @returns PatchesCountByStatusResponse Get patch count by status
   * @throws ApiError
   */
  public static async getPatchesCountByStatus({
    customerId,
    platform,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Platform **/
    platform?: PatchManagementPlatform;
  }): Promise<PatchesCountByStatusResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/patches-count-by-status`,
      query: {
        platform: platform,
      },
      errors: {
        400: `An error message when get Patches count`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get Assets Count
   * Get Assets count in patch management section
   * @returns AssetsCountResponse Get Assets Count
   * @throws ApiError
   */
  public static async getAssetsCount({
    customerId,
    platform,
    isPendingReboot,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Platform **/
    platform?: PatchManagementPlatform;
    /** is Pending Reboot parameter **/
    isPendingReboot?: boolean;
  }): Promise<AssetsCountResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/patch-management/assets-count`,
      query: {
        platform: platform,
        isPendingReboot: isPendingReboot,
      },
      errors: {
        400: `An error message when get assets list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
