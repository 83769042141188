import React from 'react';
import { Button as AntButton } from 'antd';
import { ButtonProps as AntButtonProps } from 'antd/lib/button/button';

export const ButtonTypes = [
  'default',
  'primary',
  'ghost',
  'dashed',
  'link',
  'text',
  'secondary',
  'showMore',
] as const;

export interface ButtonProps extends Omit<AntButtonProps, 'type'> {
  type?: typeof ButtonTypes[number];
}

export function Button({ type, children, ...props }: ButtonProps) {
  const mapTypeToAntType = (type?: typeof ButtonTypes[number]) => {
    switch (type) {
      case 'secondary':
      case 'showMore':
        return 'default'; // Map to a valid type if 'secondary' or 'showMore' are not supported by AntButton
      default:
        return type;
    }
  };

  return (
    <AntButton {...props} type={mapTypeToAntType(type)}>
      {children}
    </AntButton>
  );
}
