import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tabs, Tooltip } from 'antd';
import { InnerLayout } from 'shared';
import { getParametersFromPath, showError } from 'services/helpers';
import { downloadCSV } from 'services/helpers/downloadCSV';
import { AppDispatch, useApp, useCustomer, useLoading } from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { HistoryState } from 'services/interfaces';
import { Filter, GridRequestParams } from 'services/api';
import { RequestStatus } from 'store/loading/slice';
import { potentialTabTooltip, tabsKeys, tabsLabels } from './constants';
import { getCsvFileName, getDefaultQueryParamsByTab, getLayoutConfig } from './helpers';
import {
  ActiveVulnerabilitiesTab,
  PotentialVulnerabilitiesTab,
  SuppressedVulnerabilitiesTab,
} from './tabs';
import { StyledTabs } from './styled';
import { useTabsQueryParams } from './hooks/useTabsQueryParams';

export const VulnerabilitiesList = () => {
  const history = useHistory();
  const customerId = useCustomer();
  const { state, pathname } = useLocation<HistoryState>();
  const dispatch: AppDispatch = useDispatch();
  const historyState = useMemo(() => {
    return state;
    // eslint-disable-next-line
  }, []);
  const { updateActiveTab, updateQueryParams } = useTabsQueryParams();
  const searchParams = getParametersFromPath(history.location.search, customerId);
  const activeTab = getParametersFromPath(history.location.search)?.tab || 'active';

  useEffect(() => {
    updateActiveTab(activeTab);
    // eslint-disable-next-line
  }, []);

  const { isMobile } = useApp();
  const loading = useLoading([vulnerabilitiesApi.exportVulnerability.typePrefix]);

  const onDownloadCSV = useCallback(async () => {
    const currentTab = searchParams?.tab || 'active';
    const defaultParams = getDefaultQueryParamsByTab({ activeTab: currentTab, forExport: true });
    const searchFilter = searchParams?.filter || {};
    const filter = { ...defaultParams.filter, ...searchFilter };
    let fields = (filter.fields as Filter[]) || [];

    if (searchParams?.expiredSuppression) {
      fields = [...fields, { name: 'expiredSuppression', value: 'true', type: Filter.type.VALUE }];
    }

    fields = fields.filter((item) => item?.name !== 'vulnerabilityStatus');
    const exportParams = {
      ...defaultParams,
      ...searchParams,
      filter: { ...filter, fields: [...fields, ...defaultParams.filter.fields] },
    } as GridRequestParams;
    const result = await dispatch(vulnerabilitiesApi.exportVulnerability(exportParams));

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      showError();
    }

    downloadCSV(result.payload, getCsvFileName(currentTab));
  }, [dispatch, searchParams]);

  const layoutConfig = getLayoutConfig(
    onDownloadCSV,
    loading[vulnerabilitiesApi.exportVulnerability.typePrefix],
    isMobile,
    historyState,
    pathname,
  );

  return (
    <InnerLayout {...layoutConfig}>
      <StyledTabs
        defaultActiveKey={tabsKeys.active}
        activeKey={activeTab}
        className='customTabs'
        onChange={updateActiveTab}
        destroyInactiveTabPane={true}
      >
        <Tabs.TabPane tab={tabsLabels.active} key={tabsKeys.active}>
          <ActiveVulnerabilitiesTab updateQueryParams={updateQueryParams} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<Tooltip title={potentialTabTooltip}>{tabsLabels.potential}</Tooltip>}
          key={tabsKeys.potential}
        >
          <PotentialVulnerabilitiesTab updateQueryParams={updateQueryParams} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={tabsLabels.suppressed} key={tabsKeys.suppressed}>
          <SuppressedVulnerabilitiesTab updateQueryParams={updateQueryParams} />
        </Tabs.TabPane>
      </StyledTabs>
    </InnerLayout>
  );
};
