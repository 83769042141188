import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  CreateProductCertificateRequestBody,
  CustomerId,
  GetAllCertificatesRequestBody,
  ProcessService,
  RequestWithOnlyCustomer,
  UpdateBoostScoreItemRequest,
  UpdateProductCertificateRequestBody,
} from 'services/api';

const getAllCertificates = createAsyncThunk(
  'process/get/data',
  async (requestBody: GetAllCertificatesRequestBody) => {
    try {
      return await ProcessService.getAllCertificates(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const updateProductCertificate = createAsyncThunk(
  'process/put/certificate',
  async (request: UpdateProductCertificateRequestBody) => {
    try {
      return await ProcessService.updateProductCertificate(request);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const createCertificate = createAsyncThunk(
  'process/post/certificate',
  async (request: CreateProductCertificateRequestBody) => {
    try {
      return await ProcessService.createProductCertificate(request);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getBoostScores = createAsyncThunk(
  'process/get/boostScores',
  async (request: RequestWithOnlyCustomer) => {
    try {
      return await ProcessService.getAllBoostScoreItems(request);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const updateBoostScore = createAsyncThunk(
  'process/update/boostScore',
  async (request: { customerId: CustomerId; requestBody?: UpdateBoostScoreItemRequest }) => {
    try {
      return await ProcessService.updateBoostScoreItem(request);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getProducts = createAsyncThunk(
  'process/get/products',
  async (request: RequestWithOnlyCustomer) => {
    try {
      return await ProcessService.getProducts(request);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const processApi = {
  getAllCertificates,
  updateProductCertificate,
  createCertificate,
  getBoostScores,
  updateBoostScore,
  getProducts,
};
