import { useState, useCallback } from 'react';

export const useModal = () => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [title, setTitle] = useState<string | undefined>('');

  const hideModal = useCallback(() => {
    setIsModalShown(false);
  }, []);

  const showModal = useCallback(() => {
    setIsModalShown(true);
  }, []);

  return { isModalShown, showModal, hideModal, title, setTitle };
};
