import React from 'react';
import { EmailTitle, Line, DescriptionStyled, DescriptionContainer } from '../styled';
import { Email } from './Email';

interface IProps {
  summary: string;
  email: string;
  createdTime: string;
  description: string;
}

export const Description = ({ summary, email, createdTime, description }: IProps) => {
  return (
    <>
      <DescriptionContainer>
        <EmailTitle>{summary}</EmailTitle>
        <Email email={email || ''} createdTime={createdTime || ''} />

        {description && (
          <DescriptionStyled
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
      </DescriptionContainer>
      <Line />
    </>
  );
};
