import { message } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { MAX_PERCENT, PermissionsLevel } from 'app-constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CertificateName, DocumentType } from 'services/api';
import { showError, showMessage } from 'services/helpers';
import { chunkSize } from 'shared/UploadPopup/constants';
import { UploadPopup } from 'shared/UploadPopup/UploadPopup';
import { AppDispatch, documentsApi, MessageType, UserProps } from 'store';
import { RequestStatus } from 'store/loading/slice';
import { docTypeOptions, docTypeOptionsForNormUser, initialSelect } from './constants';
import { PopupWithProgressBar } from '../../Software/components/PopupWithProgressBar';
import { SelectType } from '../../../shared/UploadPopup/types';

type UploadProps = {
  visible: boolean;
  setVisible: (x: boolean) => void;
  fetchTableContent: () => void;
  customerId: string;
  permission: PermissionsLevel | null;
  loading: boolean;
  user?: UserProps;
  selections?: SelectType[];
};

export const Upload = ({
  visible,
  setVisible,
  fetchTableContent,
  customerId,
  permission,
  selections,
  user,
  loading,
}: UploadProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [downloadPercentage, setDownloadPercentage] = React.useState<number>(0);
  const [isShowProgress, setIsShowProgress] = React.useState(false);
  const [isUploadingCanceled, setIsUploadingCanceled] = React.useState(false);
  const [lastChunkFileName, setLastChunkFileName] = React.useState<string | null>(null);

  const [fileData, setFileData] = React.useState<{
    filename: string;
    documentType: DocumentType;
    certificateName: CertificateName;
    fileSize: number;
    totalChunks: number;
    blob: Blob;
  } | null>(null);

  const [currentChunkIndex, setCurrentChunkIndex] = React.useState(0);
  const [beginningOfTheChunk, setBeginningOfTheChunk] = React.useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = React.useState(chunkSize);

  const resetProgress = () => {
    setIsShowProgress(false);
    setDownloadPercentage(0);
  };

  const resetChunkProperties = () => {
    setFileData(null);
    setCurrentChunkIndex(0);
    setBeginningOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setLastChunkFileName(null);
  };

  const handleUploadingError = React.useCallback(() => {
    resetProgress();
    showError();
  }, []);

  const onUploadingCancel = React.useCallback(() => {
    setIsUploadingCanceled(true);
  }, []);

  const sliceAndUploadCurrentChunk = React.useCallback(async () => {
    if (isUploadingCanceled) {
      await dispatch(documentsApi.deleteFile({ customerId, filename: lastChunkFileName || '' }));
      setIsUploadingCanceled(false);
      resetProgress();
      resetChunkProperties();
      fetchTableContent();
      message.destroy();

      return;
    }

    if (!fileData) {
      resetProgress();
      resetChunkProperties();
      setIsUploadingCanceled(false);

      return;
    }

    const { filename, fileSize, totalChunks, blob, documentType, certificateName } = fileData;

    if (currentChunkIndex === totalChunks) {
      showMessage({ content: `File ${filename} successfully uploaded.` }, MessageType.Success);
      resetProgress();
      resetChunkProperties();
      fetchTableContent();

      return;
    }

    const chunk = blob.slice(beginningOfTheChunk, endOfTheChunk, 'application/octet-stream');

    const result = await dispatch(
      documentsApi.uploadFile({
        customerId,
        filename: lastChunkFileName || filename,
        fileSize,
        totalChunks,
        certificateName,
        requestBody: chunk,
        documentType,
        currentChunkIndex,
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Fulfilled) {
      const payload = result.payload as { filename: string };
      const end = endOfTheChunk < fileSize ? endOfTheChunk + chunkSize : fileSize;

      setBeginningOfTheChunk(endOfTheChunk);
      setLastChunkFileName(payload.filename);
      setEndOfTheChunk(end);
      setCurrentChunkIndex(currentChunkIndex + 1);
      setDownloadPercentage(Math.ceil(((currentChunkIndex + 1) / totalChunks) * MAX_PERCENT));

      return;
    }

    handleUploadingError();
    resetChunkProperties();
  }, [
    isUploadingCanceled,
    fileData,
    currentChunkIndex,
    beginningOfTheChunk,
    endOfTheChunk,
    dispatch,
    customerId,
    lastChunkFileName,
    handleUploadingError,
    fetchTableContent,
  ]);

  React.useEffect(() => {
    if (fileData) {
      setIsShowProgress(true);
      sliceAndUploadCurrentChunk();
    }
    // eslint-disable-next-line
  }, [fileData, currentChunkIndex]);

  const handleUpload = React.useCallback(
    (
      name: string,
      docType: DocumentType,
      file: File,
      selections: Record<string, SelectValue> | undefined,
    ) => {
      const reader = new FileReader();

      reader.onloadend = async (e: ProgressEvent<FileReader>) => {
        const blob = new Blob([e?.target?.result as ArrayBuffer]);
        const totalChunks = Math.ceil(blob.size / chunkSize);

        setFileData({
          filename: name,
          documentType: docType,
          certificateName: selections?.certificate as CertificateName,
          blob,
          fileSize: blob.size,
          totalChunks,
        });
      };

      reader.onerror = () => {
        handleUploadingError();
      };

      reader.readAsArrayBuffer(file);
    },
    [handleUploadingError],
  );

  return (
    <>
      <UploadPopup<DocumentType>
        visible={visible}
        setVisible={setVisible}
        handleOk={handleUpload}
        radioButtons={user?.isSuperAdmin ? docTypeOptionsForNormUser : docTypeOptions}
        selections={selections}
        initialSelections={initialSelect}
        noExtensions={false && permission === PermissionsLevel.Full && user?.isSuperAdmin}
        uploadFileSize={user?.uploadFileSize?.documents}
        loadingServiceType={loading}
      />
      <PopupWithProgressBar
        visible={isShowProgress}
        percent={downloadPercentage}
        cancelLoading={isUploadingCanceled}
        cancelDisabled={!currentChunkIndex}
        handleCancel={onUploadingCancel}
        title='Uploading...'
      />
    </>
  );
};
