import React from 'react';
import { Box, TagColoring } from 'shared';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { ColumnsType } from 'antd/es/table/interface';
import {
  formatUpdatedOrCreatedTime,
  getRenderValue,
  getTimeFromLastUpdateOrCreate,
} from 'services/helpers';
import { ColumnKey, TableTitles } from 'pages/SupportRequests/constants';
import { SupportRequestsRow } from 'pages/SupportRequests/types';
import { getLabelPriority } from 'pages/SupportRequests/helpers/helpers';

export const columns: ColumnsType<SupportRequestsRow> = [
  {
    key: ColumnKey.Priority,
    title: TableTitles.Priority,
    dataIndex: ColumnKey.Priority,
    render: (priority: string) =>
      priority ? <TagColoring title={getLabelPriority(priority)} /> : emptyDataString,
  },
  {
    key: ColumnKey.Ticket,
    title: TableTitles.Ticket,
    dataIndex: ColumnKey.Ticket,
    render: (ticket: string) => (
      <Box flexDirection='row' minW='80px'>
        {getRenderValue(ticket)}
      </Box>
    ),
    className: 'center-head-title',
    align: 'center',
  },
  {
    key: ColumnKey.Summary,
    title: TableTitles.Summary,
    dataIndex: ColumnKey.Summary,
    render: (summary: string) => <Box w='280px'>{getRenderValue(summary, NO_VALUE_LABEL)}</Box>,
  },
  {
    key: ColumnKey.SubmittedBy,
    title: TableTitles.SubmittedBy,
    dataIndex: ColumnKey.SubmittedBy,
    render: (submittedBy: string) => (
      <Box flexDirection='row' w='120px'>
        {getRenderValue(submittedBy)}
      </Box>
    ),
  },
  {
    key: ColumnKey.Created,
    title: TableTitles.Created,
    dataIndex: ColumnKey.Created,
    width: 160,
    render: (date: string) =>
      !date ? (
        emptyDataString
      ) : (
        <Box w='160px'>
          <Box>{formatUpdatedOrCreatedTime(date)}</Box>
          <Box opacity='0.5'>{getTimeFromLastUpdateOrCreate(date)}</Box>
        </Box>
      ),
    className: 'right-head-title',
    align: 'right',
  },
  {
    key: ColumnKey.Updated,
    title: TableTitles.Updated,
    dataIndex: ColumnKey.Updated,
    width: 160,
    render: (date: string) =>
      !date ? (
        emptyDataString
      ) : (
        <Box w='160px'>
          <Box>{formatUpdatedOrCreatedTime(date)}</Box>
          <Box opacity='0.5'>{getTimeFromLastUpdateOrCreate(date)}</Box>
        </Box>
      ),
    align: 'right',
    className: 'right-head-title',
  },
];
