export const isEmptyObject = (object: Record<string, unknown> | undefined): boolean => {
  if (!object) {
    return true;
  }

  return Object.keys(object).length === 0;
};

export const isEmptyLegendValuesObject = (object: Record<string, unknown> | undefined): boolean => {
  if (!object) {
    return true;
  }

  return Object.values(object).filter((item) => !!item).length === 0;
};
