import { createSlice } from '@reduxjs/toolkit';
import { PenetrationTestsData } from 'pages/PenetrationTestsList/data';
import { penetrationTestingApi } from '../thunks';

type PenetrationTestsState = {
  data: PenetrationTestsData;
};

const initialState: PenetrationTestsState = {
  data: {},
};

export const penetrationTests = createSlice({
  name: 'penetrationTests',
  initialState,
  reducers: {},
  extraReducers: {
    [penetrationTestingApi.getPenetrationTests.fulfilled.toString()]: (
      state: PenetrationTestsState,
      action: { payload: PenetrationTestsData },
    ) => {
      state.data = action.payload;
    },
    [penetrationTestingApi.getPenetrationTests.rejected.toString()]: (
      state: PenetrationTestsState,
    ) => {
      state.data = {};
    },
  },
});
