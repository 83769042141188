import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

const mobilePadding = '16px';
const tabletPadding = '24px';

// todo refactor calc
export const MobileSidebarWrapper = styled.div`
  left: ${tabletPadding};
  position: absolute;
  top: calc(${tabletPadding} + 15px);
  z-index: 501;

  @media (max-width: ${breakpoints.md}) {
    left: ${mobilePadding};
    top: calc(${mobilePadding} + 15px);
  }
`;
