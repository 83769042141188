import React from 'react';
import { Grid } from 'antd';
import { Button, Icon } from '../../../shared';
import { icons } from '../../../styles/theme';

export type DownloadButtonProps = {
  click: (name: string) => void;
  downloading?: string;
  name: string;
  textWhenMobile?: boolean;
};

export const DownloadButton = ({
  click,
  downloading,
  name,
  textWhenMobile,
}: DownloadButtonProps) => {
  const breakpoints = Grid.useBreakpoint();
  const isMobile = !(breakpoints.lg || breakpoints.xl || breakpoints.xxl);

  return (
    <Button
      className='btn-table-action'
      type='link'
      icon={<Icon component={icons.download} />}
      loading={downloading === name}
      disabled={!!downloading}
      onClick={() => click(name)}
    >
      {textWhenMobile && isMobile && 'Download'}
    </Button>
  );
};
