import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page, SidebarTextBlock } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide4.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const CyberResilienceScoreText = styled(SidebarTextBlock)`
  color: #fff;
  top: 125px;
  width: 413px;
`;

export const ScoreWrapper = styled.div`
  position: absolute;
  right: 149px;
  top: 82px;
`;

export const ScoreDifferenceMonth = styled.p`
  font-size: 16px;
  position: absolute;
  right: 149px;
  text-align: center !important;
  top: 270px;
  width: 309px;
  z-index: 1001;
`;

export const ScoreDifferenceQuarter = styled(ScoreDifferenceMonth)`
  top: 290px;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  right: 235px;
  top: 245px;
  z-index: 10;
`;

export const TotalWrapper = styled.div`
  left: 463px;
  padding-left: 50px;
  padding-right: 50px;
  position: absolute;
  top: 425px;
  width: 595px;

  table {
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
  }
`;
