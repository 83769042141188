import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../../styles/theme';

export const ScrollContainer = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
    overflow-x: scroll;
    .echarts-for-react,
    .ant-radio-group {
      min-width: 600px;
    }
  }

  ::-webkit-scrollbar {
    height: 16px;
    bottom: 10px;
    width: 50px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }: { theme: Theme }) => theme.colors.gray[10]};
    border-radius: 10px;
    margin-right: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${getCssVar('scrollBar')};
    border-radius: 10px;
  }
`;
