import React from 'react';
import { NO_VALUE_LABEL } from 'app-constants';
import { Descriptions } from 'pages/CmdbHostDetails/styled';
import { HostAssetOwnerSchema } from 'services/api';
import { getPriorityValue, renderValue } from 'pages/CmdbHostDetails/helpers';
import { AssetOwnerTitle } from './constants';
import { StatusTagColoring } from '../../../../shared/TagColoring/StatusTagColoring';

type AssetOwnerViewModeProps = {
  assetOwner?: HostAssetOwnerSchema;
};

export const AssetOwnerViewMode = ({ assetOwner }: AssetOwnerViewModeProps) => {
  return (
    <Descriptions column={1}>
      <Descriptions.Item label={AssetOwnerTitle.Name}>
        {renderValue(assetOwner?.name)}
      </Descriptions.Item>

      <Descriptions.Item label={AssetOwnerTitle.Department}>
        {renderValue(assetOwner?.department)}
      </Descriptions.Item>

      <Descriptions.Item label={AssetOwnerTitle.Priority}>
        {assetOwner?.priority ? (
          <StatusTagColoring title={getPriorityValue(assetOwner?.priority)} />
        ) : (
          NO_VALUE_LABEL
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};
