import { minLengthToSearch } from 'app-constants';
import { Filter, OrderBy } from 'services/api';
import { InputType } from '../../services/api/models/InputType';

export enum TableTitles {
  Email = 'Email',
  Date = 'Date',
  Action = 'Action',
  Address = 'Address',
  ActorEmail = 'Actor',
  Note = 'Note',
  CustomerId = 'Customer ID',
}

export const initialOrderBy = {
  field: 'timestamp',
  type: OrderBy.type.DESC,
};

export const searchPlaceholder = 'Search by Email/Address/Actor/Note';
export const searchFields = ['email', 'ip', 'actor', 'note'];

export const searchSettings = {
  fields: searchFields,
  min: minLengthToSearch,
  placeholder: searchPlaceholder,
};

export const getFilterFields = (actionTypes: string[]) => [
  {
    field: 'action',
    options: actionTypes.map((type: string) => ({ label: type, value: type })),
    label: TableTitles.Action,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'timestamp',
    label: TableTitles.Date,
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export enum ColumnKey {
  Email = 'email',
  Timestamp = 'timestamp',
  Action = 'action',
  ActorEmail = 'actorEmail',
  Note = 'note',
  Ip = 'ip',
  CustomerId = 'customer',
}

export const UserColumns = [
  ColumnKey.Email,
  ColumnKey.Ip,
  ColumnKey.ActorEmail,
  ColumnKey.Action,
  ColumnKey.Note,
  ColumnKey.Timestamp,
];
