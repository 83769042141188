import styled from 'styled-components';
import { getCssVar } from 'styles/theme';
import { Box } from 'shared';

const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.sizes['4']};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: ${({ theme }) => theme.sizes['2']};
  }
`;

export const HeaderActionsWrapper = styled(FlexWrapper)`
  flex-grow: 1;
  justify-content: space-between;
`;

export const ButtonWrapper = styled(FlexWrapper)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
    flex-grow: 1;
  }
`;

export const EditorWrapper = styled.div<{ isError: boolean }>`
  max-width: 700px;
  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing['2.5']} 0px`};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.spacing[4]} 0px 0px`};
  }

  .ck-editor {
    border: ${({ isError }) => (isError ? `2px solid ${getCssVar('errorColor')}` : 'none')};
  }
`;

export const EditorCounterWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
`;

export const EditorCounterText = styled(Box)`
  color: ${getCssVar('textColor')};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  opacity: 50%;
`;

export const EditorErrorMessage = styled(Box)`
  color: ${getCssVar('errorColor')};
`;

export const EditorButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: ${({ theme }) => theme.spacing[6]};

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-direction: column;
  }
`;

export const GuidanceTextWrapper = styled(Box)`
  strong {
    color: ${getCssVar('textColor')};
    font-weight: bold;
  }

  td,
  th {
    border: 1px solid;
    border-color: ${({ theme }) => theme.colorSet.wysiwyg.view.table.borderColor};
    font-weight: normal;
    padding: ${({ theme }) => theme.colorSet.wysiwyg.view.table.cellPadding};
    text-align: left;
    word-break: break-word;
  }

  a {
    word-wrap: break-word;
  }
`;
