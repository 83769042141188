import React, { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { PhishingByMonthSchema, VulnerabilityTrendPointSchema } from 'services/api';
import { IDate } from 'services/interfaces';
import { Theme } from 'styles/theme/types';
import { useTheme } from 'styled-components';
import { MultiValues } from '../types';
import { EChartsEventParams, IEChartsOption } from '../interfaces';

type ValuesType =
  | MultiValues
  | VulnerabilityTrendPointSchema[]
  | {
      [x: string]: VulnerabilityTrendPointSchema[];
    }
  | {
      clicked?: PhishingByMonthSchema[] | undefined;
      opened?: PhishingByMonthSchema[] | undefined;
      providedCredentialsOrDownloadedAnAttachment?: PhishingByMonthSchema[] | undefined;
    };

export type OptionParams<T extends ValuesType> = {
  values: T;
  theme: Theme;
  date?: IDate;
  isMobile?: boolean;
  colors?: string[];
  xLabels?: string[];
  noLegend?: boolean;
  height?: string;
  hasClickHandler: boolean;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
};

type Props<T extends ValuesType> = {
  values: T;
  getOption: (params: OptionParams<T>) => IEChartsOption;
  date?: IDate;
  colors?: string[];
  isMobile: boolean;
  xLabels?: string[];
  noLegend?: boolean;
  chartRef?: React.LegacyRef<ReactECharts>;
  onEvents?: Record<string, (params: EChartsEventParams) => void>;
  height?: {
    grid: string;
    chart: string;
  };
  top?: string;
  bottom?: string;
  left?: string;
  onLoad?: () => void;
  right?: string;
  width?: string;
};

export const Line = <T extends ValuesType>({
  values,
  getOption,
  date,
  xLabels,
  noLegend,
  colors,
  onLoad,
  onEvents,
  height,
  top,
  bottom,
  left,
  right,
  width,
  isMobile,
  chartRef,
}: Props<T>) => {
  const theme = useTheme();

  const chartOptions = useMemo(() => {
    return getOption({
      values,
      theme,
      date,
      height: height?.grid,
      top,
      bottom,
      left,
      right,
      width,
      isMobile,
      colors,
      xLabels,
      noLegend,
      hasClickHandler: Boolean(onEvents),
    });
  }, [
    values,
    theme,
    date,
    height,
    top,
    bottom,
    left,
    right,
    width,
    isMobile,
    colors,
    xLabels,
    noLegend,
    onEvents,
    getOption,
  ]);

  return (
    <ReactECharts
      option={chartOptions}
      onEvents={onEvents}
      onChartReady={onLoad}
      opts={{ renderer: 'canvas' }}
      style={{ height: height?.chart || '280px', width: width || 'auto' }}
      ref={chartRef}
      notMerge
    />
  );
};
