import { useCallback } from 'react';
import { Form as AntdForm, Input } from 'antd';
import { ActionPopup, Box, Button, Icon } from 'shared/index';
import { useFormik } from 'formik';
import { SelectValue } from 'antd/es/select';
import { useHistory } from 'react-router';
import { InputLabel } from '../../pages/MaintenancePageSettings/style';
import { SelectPlatform } from '../../pages/PatchManagement/styled';
import { icons } from '../../styles/theme';
import { FormFieldLabels, MESSAGE_MAX_LENGTH, validationSchema } from './constants';
import { CreateNotificationRequest, NotificationStatus } from '../../services/api';
import { PopupConfigType } from '../ActionPopup/types';
import { optionTypes } from '../../pages/Notifications/constant';

import {
  ButtonWrapper,
  CounterInner,
  CounterWrapper,
  EditorCustomLabel,
  EditorWrapper,
  MessageErrorWrapper,
} from './styled';
import { Editor } from '../Wysiwyg';
import { getStringWithoutTags, isEmptyObject } from '../../services/helpers';

interface Props {
  initialValues: CreateNotificationRequest;
  onSubmitForm: (values: CreateNotificationRequest) => void;
  isVisiblePopup: boolean;
  popupDetails: PopupConfigType | null;
  setIsVisiblePopup: (isVisible: boolean) => void;
}

export const Form = ({
  initialValues,
  onSubmitForm,
  isVisiblePopup,
  popupDetails,
  setIsVisiblePopup,
}: Props) => {
  const history = useHistory();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: CreateNotificationRequest) => onSubmitForm(values),
  });

  const onModelChange = useCallback((value: string) => {
    formik.setFieldValue('message', value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const submitButtonHandler = useCallback(
    (submitType: NotificationStatus) => {
      return formik.setFieldValue('status', submitType);
    },
    [formik],
  );

  const messageLength = getStringWithoutTags(formik.values.message as string).length;

  return (
    <Box>
      <AntdForm
        layout='vertical'
        onFinish={formik.handleSubmit}
        initialValues={formik.initialValues}
      >
        <InputLabel
          label='Type'
          required
          validateStatus={formik.errors.type && formik.touched.type ? 'error' : 'success'}
          help={formik.errors.type && formik.touched.type ? formik.errors.type : undefined}
        >
          <SelectPlatform
            placeholder='Please select'
            suffixIcon={<Icon className='ant-select-suffix' component={icons.arrow_drop_down} />}
            style={{ maxWidth: '350px' }}
            options={optionTypes}
            onChange={(value: SelectValue) => {
              formik.setFieldValue('type', value);
            }}
            value={formik.values.type}
          />
        </InputLabel>
        <InputLabel
          label='Title'
          required
          validateStatus={formik.errors.title && formik.touched.title ? 'error' : 'success'}
          help={formik.errors.title && formik.touched.title ? formik.errors.title : undefined}
        >
          <Input
            name={FormFieldLabels.Title}
            placeholder='Notification title'
            style={{ maxWidth: '370px' }}
            onChange={formik.handleChange}
            value={formik.values.title}
          />
        </InputLabel>
        <EditorCustomLabel
          label='Message'
          required
          help={formik.errors.message}
          validateStatus={formik.errors.message ? 'error' : 'success'}
        >
          <EditorWrapper isError={!!formik.errors.message}>
            <Editor
              onChange={onModelChange}
              initialValue={formik.values.message || ''}
              placeholder='Please enter the notification message'
            />
            <CounterWrapper>
              <MessageErrorWrapper>{formik.errors.message}</MessageErrorWrapper>
              <CounterInner>
                {messageLength} / {MESSAGE_MAX_LENGTH}
              </CounterInner>
            </CounterWrapper>
          </EditorWrapper>
        </EditorCustomLabel>
        <ButtonWrapper>
          <Button
            type='primary'
            htmlType='submit'
            onClick={() => submitButtonHandler(NotificationStatus.PUBLISHED)}
            disabled={!isEmptyObject(formik.errors) && !isEmptyObject(formik.touched)}
          >
            Publish now
          </Button>
          <Button
            type='secondary'
            htmlType='submit'
            onClick={() => submitButtonHandler(NotificationStatus.DRAFTS)}
            disabled={!isEmptyObject(formik.errors) && !isEmptyObject(formik.touched)}
          >
            Save as draft
          </Button>
          <Button type='secondary' onClick={handleCancelButton}>
            Cancel
          </Button>
        </ButtonWrapper>
      </AntdForm>

      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
        />
      )}
    </Box>
  );
};
