import React, { useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { useDispatch } from 'react-redux';
import {
  managedDetectionsResponseApi,
  useActiveEDRAgents,
  useApp,
  useCustomer,
  useCustomerChange,
} from 'store';
import { EdrTrendBySource, EdrTrendPointSchema } from 'services/api';
import { ActiveEDRAgentCustomTooltipContainer } from 'shared/charts/styled';
import { IDate } from 'services/interfaces';
import { RadioButtonsWithRangePicker } from 'shared/RadioButtonsWithRangePicker/RadioButtonsWithRangePicker';
import { getDefaultDateRangeValue } from 'shared/RadioButtonsWithRangePicker/helpers';
import { emptyValuesActiveEDRAgents } from 'pages/ThreatDetection/constants';
import { EmptyValuesActiveEDRAgents } from 'pages/ThreatDetection/types';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { Legend, Line } from 'shared/charts';
import { Values } from 'shared/charts/types';
import { useTheme } from 'styled-components';
import { ScrollContainer } from 'shared';
import { activeEDRChartColors, getLegendValues, getOption } from './helpers';

export const ActiveEDRAgentsGraph = () => {
  const theme = useTheme();
  const chartRef = useRef<ReactECharts>();
  const customer = useCustomer();
  const { isMobile, isMenuCollapsed } = useApp();
  const [date, setDate] = React.useState<IDate>(getDefaultDateRangeValue());
  const dispatch = useDispatch();
  const { activeEDRAgents } = useActiveEDRAgents();
  // const loading = useLoading([managedDetectionsResponseApi.activeEDRAgents.typePrefix]);

  const customerChange = useCustomerChange([
    managedDetectionsResponseApi.activeEDRAgents.typePrefix,
  ]);
  const isShowEmptyChart = customerChange[managedDetectionsResponseApi.activeEDRAgents.typePrefix];

  useEffect(() => {
    const { startDate, endDate } = date;

    if (startDate && endDate) {
      dispatch(
        managedDetectionsResponseApi.activeEDRAgents({
          customerId: customer,
          startDate,
          endDate,
        }),
      );
    }
  }, [dispatch, customer, date]);

  const updateDate = React.useCallback((selectedDate: IDate) => {
    setDate(selectedDate);
  }, []);

  const getValues = (values: EdrTrendBySource[]) =>
    [...values].reduce((acc: { [x: string]: EdrTrendPointSchema[] }, value: EdrTrendBySource) => {
      if (!value.source || !value.points) {
        return { ...acc };
      }

      return { ...acc, [value.source]: value.points };
    }, {});

  const chartColors = activeEDRChartColors(theme);
  const legendValues: Values = getLegendValues(activeEDRAgents.trends ?? []);

  return (
    <>
      <ScrollContainer width='600px'>
        <RadioButtonsWithRangePicker
          handleDateChange={updateDate}
          date={date}
          isMobile={isMobile}
        />

        <ActiveEDRAgentCustomTooltipContainer>
          <RenderChartWrapper<EdrTrendBySource, EmptyValuesActiveEDRAgents>
            values={getValues(activeEDRAgents.trends || [])}
            emptyValues={emptyValuesActiveEDRAgents}
            emptyText='No active EDR agents yet'
            isShowEmptyChart={isShowEmptyChart}
          >
            <Line
              values={emptyValuesActiveEDRAgents}
              date={date}
              isMobile={isMobile}
              getOption={getOption}
              height={{ chart: '320px', grid: 'auto' }}
              chartRef={(r: ReactECharts) => {
                chartRef.current = r;
              }}
            />
          </RenderChartWrapper>
        </ActiveEDRAgentCustomTooltipContainer>
      </ScrollContainer>
      <Legend
        values={legendValues}
        colors={chartColors}
        noValues
        actionOptions={(name: string) => ({ seriesName: name })}
        direction='row'
        marginTop={isMobile ? theme.sizes[4] : theme.sizes[6]}
        chartRef={chartRef}
        isMobile={isMobile}
        isMenuCollapsed={isMenuCollapsed}
        isShowingUnchangedText
      />
    </>
  );
};
