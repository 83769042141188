import React from 'react';
import { Box } from 'shared';
import { ColumnsType } from 'antd/es/table/interface';
import {
  getCapitalizedString,
  getFormattedDate,
  getRenderValue,
  stripTags,
} from 'services/helpers';
import { AlarmSchema } from 'services/api';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { Tooltip } from 'antd';
import { ColumnKey, TableTitles } from 'pages/Alarms/constants';
import { TagColoring } from 'pages/Alarms/components/TagColoring';
import { IconColoring } from './IconColoring';

export const getColumns = (isMobile: boolean): ColumnsType<AlarmSchema> => [
  {
    key: ColumnKey.Risk,
    title: TableTitles.Risk,
    dataIndex: ColumnKey.Risk,
    render: (risk: string) => (
      <>{!risk ? emptyDataString : <TagColoring title={getCapitalizedString(risk)} />}</>
    ),
  },
  {
    key: ColumnKey.Created,
    title: TableTitles.Created,
    dataIndex: ColumnKey.Created,
    render: (date: string) => (
      <Box w='160px'>
        <Box>{getFormattedDate(date)}</Box>
      </Box>
    ),
    width: 160,
    className: 'right-head-title',
    align: 'right',
  },
  {
    key: ColumnKey.Name,
    title: TableTitles.Description,
    dataIndex: ColumnKey.Name,
    render: (name: string, row: AlarmSchema) => (
      <Tooltip
        title={stripTags(row?.description || '')}
        placement='top'
        trigger={isMobile ? 'click' : 'hover'}
      >
        <Box w='300px' cursor='pointer'>
          <span>{getRenderValue(name, NO_VALUE_LABEL)}</span>
        </Box>
      </Tooltip>
    ),
    width: 300,
  },
  {
    key: ColumnKey.Tactic,
    title: TableTitles.Tactic,
    dataIndex: ColumnKey.Tactic,
    render: (category: string) => <>{getRenderValue(category)}</>,
  },
  {
    key: ColumnKey.Source,
    title: TableTitles.Source,
    dataIndex: ColumnKey.Source,
    render: (sourceIp: string, row: AlarmSchema) => (
      <Box display='flex' flexDirection='column'>
        <Box>{sourceIp || row.sourceHostName || emptyDataString}</Box>
        {sourceIp && row.sourceHostName ? (
          <Box className='caption' opacity='0.5'>
            {row.sourceHostName}
          </Box>
        ) : null}
      </Box>
    ),
  },
  {
    key: ColumnKey.Destination,
    title: TableTitles.Destination,
    dataIndex: ColumnKey.Destination,
    render: (destIp: string, row: AlarmSchema) => (
      <Box display='flex' flexDirection='column'>
        <Box>{destIp || row.destHostName || emptyDataString}</Box>
        {destIp && row.destHostName ? (
          <Box className='caption' opacity='0.5'>
            {row.destHostName}
          </Box>
        ) : null}
      </Box>
    ),
  },
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    render: (status: string) => (
      <span>
        {status === null || status === undefined ? (
          emptyDataString
        ) : (
          <>
            <IconColoring status={status} />
            {status}
          </>
        )}
      </span>
    ),
  },
  {
    key: ColumnKey.Updated,
    title: TableTitles.Updated,
    dataIndex: ColumnKey.Updated,
    render: (date: string) => <Box w='160px'>{getFormattedDate(date)}</Box>,
    align: 'right',
    className: 'right-head-title',
    width: 160,
  },
];
