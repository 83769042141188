/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SecuritySettingsResponse } from '../models/SecuritySettingsResponse';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { UpdateSecuritySettingsRequest } from '../models/UpdateSecuritySettingsRequest';
import { request as __request } from '../core/request';

export class SecuritySettingsService {
  /**
   * Security settings
   * Update customer security settings
   * @returns SuccessResponse Update security settings
   * @throws ApiError
   */
  public static async updateSecuritySettings({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** Security settings parameters **/
    requestBody?: UpdateSecuritySettingsRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/security-settings`,
      body: requestBody,
      errors: {
        400: `An error message when get security settings`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Security settings
   * Get customer security settings
   * @returns SecuritySettingsResponse Customer security settings
   * @throws ApiError
   */
  public static async getSecuritySettings({
    customerId,
  }: {
    /** Customer id **/
    customerId: string;
  }): Promise<SecuritySettingsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/security-settings`,
      errors: {
        400: `An error message when get security settings`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
