/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerId } from '../models/CustomerId';
import type { FilteredAlarmsResponse } from '../models/FilteredAlarmsResponse';
import type { Filters } from '../models/Filters';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { TagRequest } from '../models/TagRequest';
import type { TagsGridResponse } from '../models/TagsGridResponse';
import type { TagsList } from '../models/TagsList';
import type { UpdateTagSchema } from '../models/UpdateTagSchema';
import { request as __request } from '../core/request';

export class TagsManagementService {
  /**
   * Tags list
   * Get list of tags
   * @returns TagsList list of tags
   * @throws ApiError
   */
  public static async getTagsList({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<TagsList> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/tags-management/tags`,
      errors: {
        400: `An error message when get tags grid`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Create tag
   * Create new tag
   * @returns TagsGridResponse Tags list for Grid
   * @throws ApiError
   */
  public static async createTag({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Create new tag **/
    requestBody?: TagRequest;
  }): Promise<TagsGridResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/tags-management/tags`,
      body: requestBody,
      errors: {
        400: `An error message when new tag didn\`t create`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Tags list
   * Get filtered, searchable and sortable list of tags
   * @returns TagsGridResponse Filtered, searchable and sortable list of tags
   * @throws ApiError
   */
  public static async getTagsGrid({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<TagsGridResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/tags-management/grid`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when get tag grid`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Edit tag
   * Edit new tag
   * @returns UpdateTagSchema Edit tag by id
   * @throws ApiError
   */
  public static async editTag({
    customerId,
    tagId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** tag id **/
    tagId: string;
    /** Edit tag **/
    requestBody?: TagRequest;
  }): Promise<UpdateTagSchema> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/tags-management/tags/${tagId}`,
      body: requestBody,
      errors: {
        400: `An error message when edit tag`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete tag
   * Delete new tag
   * @returns FilteredAlarmsResponse Delete tag by id
   * @throws ApiError
   */
  public static async deleteTag({
    customerId,
    tagId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** tag id **/
    tagId: string;
    /** Remove tag **/
    requestBody?: TagRequest;
  }): Promise<FilteredAlarmsResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/tags-management/tags/${tagId}`,
      body: requestBody,
      errors: {
        400: `An error message when delete tag`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
