import React, { useCallback } from 'react';
import { Tooltip } from 'antd';
import {
  StressScoreDigits,
  StressScoreLabel,
  StressScoresContainer,
  TotalStressCard,
} from 'pages/Dashboard/styled';
import ReactECharts from 'echarts-for-react';
import { TotalStressScore } from 'pages/Dashboard/types';
import { getLevel } from 'shared/charts/GradientPie/helpers';
import { getScoreAnnotations, TooltipTitles } from 'pages/Dashboard/constants';
import { useHistory } from 'react-router';
import { Icon } from 'shared';
import { useApp, useCustomerName } from 'store';
import { icons } from 'styles/theme/icons';
import { useTheme } from 'styled-components';
import { StressContainer } from '../styled/styled';
import { stressScoreMobileOrder } from '../constants';
import { getOption } from './helpers';
import { isEmptyObject } from '../../../../services/helpers';
import { getCssVar } from '../../../../styles/theme';

interface StressScoreProps {
  data: TotalStressScore[];
  layout: 'horizontal' | 'vertical';
  isLoading?: boolean;
  score?: number;
  scoreLoading?: boolean;
}

export const StressScore = ({ data, layout, isLoading, score, scoreLoading }: StressScoreProps) => {
  const theme = useTheme();
  const history = useHistory();
  const customerName = useCustomerName();
  const { isMobile, isMenuCollapsed } = useApp();
  const annotations = getScoreAnnotations(customerName || '');
  const annotation = annotations[getLevel(Number(score?.toFixed(0)) || 0)];

  const underTitle = isMobile ? (
    <Tooltip placement='bottomRight' title={annotation.description}>
      <span style={{ color: getCssVar('textColor') }}>{annotation.title}</span>
    </Tooltip>
  ) : undefined;

  const hanldeStressScoreClick = React.useCallback(
    (stressScore: TotalStressScore) => {
      if (stressScore.value === undefined) {
        return;
      }

      history.push(stressScore.redirect);
    },
    [history],
  );

  const getChartOptions = useCallback(
    (values: TotalStressScore) => {
      if (isLoading || isEmptyObject(values) || scoreLoading) {
        return {};
      }

      return getOption({ values: values.chartValues, theme });
    },
    [isLoading, scoreLoading, theme],
  );

  return (
    <TotalStressCard
      title='Cyber Resilience score'
      tooltipTitle={TooltipTitles.TotalStress}
      layout={layout}
      valueMini={Number(score?.toFixed(0)) || 0}
      scoreAnnotations={!isMobile ? annotations : undefined}
      isValueMiniLoading={scoreLoading}
      valuePlacement='inside'
      underTitle={underTitle}
      headStyles={
        !isMobile
          ? { pb: `${theme.spacing[4]}` }
          : { pb: `${theme.spacing[0]}`, titlePaddingRight: `${theme.spacing[0]}` }
      }
      bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
      cardPadding={
        !isMobile
          ? `${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[2]} ${theme.spacing[6]}`
          : `${theme.spacing[0]} ${theme.spacing[4]} ${theme.spacing[2]} ${theme.spacing[4]}`
      }
      showDivider={!isMobile}
      showLoader={isLoading}
    >
      <StressScoresContainer $collapsed={isMenuCollapsed}>
        {data.map((stressScore: TotalStressScore, index: number) => (
          <StressContainer
            key={stressScore.label}
            onClick={() => hanldeStressScoreClick(stressScore)}
            order={isMobile ? stressScoreMobileOrder[stressScore.label] : index}
            $collapsed={isMenuCollapsed}
          >
            <div>
              <div>
                <StressScoreDigits>
                  {stressScore.value === undefined ? (
                    <Icon component={icons.medium_lock} />
                  ) : (
                    stressScore.value
                  )}
                </StressScoreDigits>
                <StressScoreLabel>{stressScore.label}</StressScoreLabel>
              </div>
              <div>
                {stressScore.value === undefined && !isMobile ? (
                  <Icon
                    component={
                      index <= 2
                        ? icons.total_stress_empty_chart_1
                        : icons.total_stress_empty_chart_2
                    }
                    className='empty-chart'
                    color='red'
                  />
                ) : (
                  <ReactECharts
                    option={getChartOptions(stressScore)}
                    style={{ height: '30px', maxWidth: '340px', width: '100%' }}
                    notMerge
                  />
                )}
              </div>
            </div>
            {stressScore.value !== undefined && (
              <Icon className='arrow' component={icons.arrow_right} />
            )}
          </StressContainer>
        ))}
      </StressScoresContainer>
    </TotalStressCard>
  );
};
