import React from 'react';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { Routes } from '../../services/entities';
import { backlinkRoutes } from '../../app-constants';
import { HistoryState } from '../../services/interfaces';
import { Themes } from '../../themes/types';

export const getLayoutConfig = (historyState?: HistoryState): InnerLayout => ({
  title: 'Appearance',
  backLink: (
    <BackLink
      defaultRoute={Routes.DASHBOARD}
      defaultTitle={backlinkRoutes[Routes.DASHBOARD]}
      historyState={historyState}
    />
  ),
});

export const isSystemThemeModeActive = (themeMode?: string) => themeMode === Themes.System;
