export enum DescriptionsTitle {
  Tags = 'Tags',
  Identification = 'Identification',
  VulnerabilityAgentActivity = 'Vulnerability agent activity',
  EndpointProtectionAgentActivity = 'Endpoint protection agent activity',
}

export enum IdentificationTitle {
  IP = 'IPv4 address',
  Timezone = 'Timezone',
  Manufacturer = 'Manufacturer',
  Model = 'Model',
  CPU = 'CPU',
  BIOS = 'BIOS description',
}

export enum VulnerabilityAgentActivityTitle {
  Created = 'Created',
  LastModified = 'Last modified',
  LastSystemBoot = 'Last system boot',
  LastCheckedIn = 'Last checked-in',
  LastVulnerabilityScan = 'Last vulnerability scan',
}

export enum EndpointProtectionTitle {
  Created = 'Created',
  LastCheckedIn = 'Last checked-in',
  State = 'State',
}

export const MAX_TAGS_ON_HOSTS = 30;
