import React, { useState } from 'react';
import { AddTagButton } from '../styled';
import { TagsSelector } from './TagsSelector';

interface ITagButtonProps {
  onChanged?: () => void;
  rowId?: string;
}

export const TagButton = ({ rowId, onChanged }: ITagButtonProps) => {
  const [showSelector, setShowSelector] = useState(false);

  return (
    <>
      {!showSelector && (
        <AddTagButton
          onClick={(event) => {
            event.stopPropagation();
            setShowSelector(true);
          }}
        >
          + Add Tag
        </AddTagButton>
      )}
      {showSelector && (
        <TagsSelector
          rowId={rowId}
          onChanged={onChanged}
          closeSelector={() => setShowSelector(false)}
        />
      )}
    </>
  );
};
