import React, { useCallback } from 'react';
import { difference } from 'lodash';
import { Filter, Filters } from 'services/api';
import { DesktopFilters, MobileFilters } from 'shared';
import { FilterType, SearchSettings } from 'shared/Table/types';
import { useGetMaxVisiableFilters } from './useGetMaxVisiableFilters';
import { hiddenFiltersNames } from './constants';

export type TableFiltersProps = {
  onFiltersChange: (filter: Filters | null, isResetClick?: boolean) => void;
  filterFields?: FilterType[];
  maxFilterFieldsLengthDefault?: number;
  currentFilter?: Filters | null;
  searchSettings?: SearchSettings;
  isMobile?: boolean;
  setIsDrawerVisible?: (value: boolean) => void;
  setMobileResetButtonClick?: (value: boolean) => void;
  isDrawerVisible?: boolean;
};

export const TableFilters = ({
  onFiltersChange,
  currentFilter,
  filterFields,
  maxFilterFieldsLengthDefault,
  searchSettings,
  isMobile,
  setIsDrawerVisible,
  setMobileResetButtonClick,
  isDrawerVisible,
}: TableFiltersProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isInit = React.useRef(true);

  const [filter, setFilter] = React.useState<Filters | null>(currentFilter || null);
  const [visibleFilterFields, setVisibleFilterFields] = React.useState<FilterType[]>([]);
  const [hiddenFilterFields, setHiddenFilterFields] = React.useState<FilterType[]>([]);
  const [isShowHiddenFilterFields, setIsShowHiddenFilterFields] = React.useState(false);
  const [isShowResetButton, setIsShowResetButton] = React.useState(false);
  const [isHiddenFilterFieldChecked, setIsHiddenFilterFieldChecked] = React.useState(false);
  const [isClearAll, setIsClearAll] = React.useState(false);
  const { vulnerabilityStatus, destHostName } = hiddenFiltersNames;

  React.useEffect(() => {
    if (setMobileResetButtonClick && isDrawerVisible) {
      setMobileResetButtonClick(false);
    }

    if (!filter?.search?.value) {
      setIsClearAll(false);
    }
  }, [filter?.search?.value, filterFields, isDrawerVisible, setMobileResetButtonClick]);

  const maxFilterFieldsLength = useGetMaxVisiableFilters(ref, maxFilterFieldsLengthDefault);

  const hiddenFilters = filter?.fields?.filter(
    (filter) =>
      filter.name !== vulnerabilityStatus &&
      filter.name !== destHostName &&
      filterFields?.map((filterField) => filterField.field).indexOf(filter.name as string) === -1,
  );

  const getSelectValue = (name: string) => {
    if (filter?.fields?.length) {
      return filter.fields.find((field: Filter) => field.name === name)?.value;
    }

    return [];
  };

  const getSelectField = useCallback(
    (name: string): null | Filter => {
      if (filter?.fields?.length) {
        return filter.fields.find((field: Filter) => field.name === name) || null;
      }

      return null;
    },
    [filter?.fields],
  );

  const toggleShowHiddenFilterFields = React.useCallback(() => {
    setIsShowHiddenFilterFields(!isShowHiddenFilterFields);
  }, [isShowHiddenFilterFields]);

  const clearAll = () => {
    setFilter(null);

    setIsShowResetButton(false);
  };

  const handleCloseClick = () => {
    setFilter(currentFilter || null);
    if (setIsDrawerVisible) {
      setIsDrawerVisible(false);
    }
  };

  const handleApplyClick = React.useCallback(() => {
    onFiltersChange(filter);
    if (isClearAll) {
      if (setMobileResetButtonClick) {
        setMobileResetButtonClick(true);
        onFiltersChange(filter, true);
      }
    } else {
      onFiltersChange(filter);
    }

    if (setIsDrawerVisible) {
      setIsDrawerVisible(false);
    }
  }, [filter, isClearAll, onFiltersChange, setIsDrawerVisible, setMobileResetButtonClick]);

  const handleFilterChange = (
    field: string,
    value: string[] | string,
    type: Filter.type,
    params: Record<string, unknown> = {},
  ) => {
    if (!Array.isArray(value) && !value?.length) {
      const filteredFilterFields = filter?.fields?.filter(
        (filterField: Filter) => filterField.name !== field,
      );

      if (!filteredFilterFields?.length && filter?.fields) {
        if (!filter.search) {
          setFilter(null);
        } else {
          const { search } = filter;

          setFilter({ search });
        }
      } else {
        setFilter({ ...filter, fields: filteredFilterFields });
      }

      return;
    }

    if (filter && filter.fields?.length) {
      let isUpdated = false;
      const updatedFilterFields: Filter[] = filter.fields.map((filterField: Filter) => {
        if (filterField.name === field) {
          isUpdated = true;

          return { ...filterField, value, ...params };
        }

        return filterField;
      });

      if (!isUpdated) {
        updatedFilterFields.push({ name: field, value, type, ...params });
      }

      setFilter({ ...filter, fields: updatedFilterFields });
    } else {
      setFilter({ ...filter, fields: [{ name: field, value, type, ...params }] });
    }
  };

  const handleDebouncedInputChange = React.useCallback(
    (value: string) => {
      if (!value) {
        setFilter((prevFilter: Filters | null) => {
          if (!prevFilter?.search) {
            return prevFilter;
          }

          if (prevFilter?.fields?.length) {
            const filter = { ...prevFilter };

            delete filter.search;

            return filter;
          }

          return null;
        });
      } else {
        setFilter((prevFilter: Filters | null) => ({
          ...prevFilter,
          search: { value, fields: searchSettings?.fields },
        }));
      }
    },
    [setFilter, searchSettings?.fields],
  );

  React.useEffect(() => {
    if (filterFields && filterFields.length <= maxFilterFieldsLength) {
      setVisibleFilterFields(filterFields);
      setHiddenFilterFields([]);
    }

    if (filterFields && filterFields.length > maxFilterFieldsLength) {
      setVisibleFilterFields(filterFields.slice(0, maxFilterFieldsLength));
      setHiddenFilterFields(filterFields.slice(maxFilterFieldsLength));
    }
  }, [filterFields, maxFilterFieldsLength]);

  React.useEffect(() => {
    const noResetFilter = filter?.fields?.every(
      (field) => field.name === vulnerabilityStatus || field.name === destHostName,
    );

    if (
      !filter?.fields?.length ||
      !difference(filter?.fields, hiddenFilters as Array<Filter>).length ||
      noResetFilter
    ) {
      setIsShowResetButton(false);
      setIsHiddenFilterFieldChecked(false);
    } else {
      setIsShowResetButton(true);
      const hiddenFilterFieldNames = hiddenFilterFields.map(
        (filterField: FilterType) => filterField.field,
      );
      const isCheckedHiddenFilterField = filter.fields.some((field: Filter) =>
        !field.name ? !!field.name : hiddenFilterFieldNames.includes(field.name),
      );

      setIsHiddenFilterFieldChecked(isCheckedHiddenFilterField);
    }
  }, [filter, hiddenFilterFields, filterFields, hiddenFilters, vulnerabilityStatus, destHostName]);

  React.useEffect(() => {
    if (!isInit.current && !isMobile) {
      onFiltersChange(filter);
    }

    isInit.current = false;
  }, [filter, onFiltersChange, isMobile]);

  const clearAllHandler = () => {
    clearAll();
    setIsClearAll(true);
  };

  return isMobile ? (
    <MobileFilters
      handleCloseClick={handleCloseClick}
      filterFields={filterFields || []}
      isShowResetButton={isShowResetButton}
      clearAll={clearAllHandler}
      handleApplyClick={handleApplyClick}
      getSelectOrRangeValue={getSelectValue}
      onFilterChange={handleFilterChange}
      hiddenFilters={hiddenFilters || []}
      getSelectField={getSelectField}
    />
  ) : (
    <DesktopFilters
      searchSettings={searchSettings}
      filterFields={filter}
      handleDebouncedInputChange={handleDebouncedInputChange}
      inputValue={currentFilter?.search?.value}
      visibleFilterFields={visibleFilterFields}
      hiddenFilterFields={hiddenFilterFields}
      isShowHiddenFilterFields={isShowHiddenFilterFields}
      toggleShowHiddenFilterFields={toggleShowHiddenFilterFields}
      isHiddenFilterFieldChecked={isHiddenFilterFieldChecked}
      isShowResetButton={isShowResetButton}
      getSelectOrRangeValue={getSelectValue}
      onFilterChange={handleFilterChange}
      clearAll={clearAllHandler}
      isResetFiltersClick={isClearAll}
      ref={ref}
      hiddenFilters={hiddenFilters || []}
      getSelectField={getSelectField}
    />
  );
};
