import { Box } from 'shared';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import Cascader from 'rc-cascader';
import { OptionType } from 'shared/Table/types';
import { getCssVar } from 'styles/theme';

type Props = {
  theme: Theme;
  value?: string | string[];
  options?: OptionType[];
  maxTagCount?: number;
};

export const FilterGroupContainer = styled(Box)`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 8px;
  position: relative;
  width: 282px;

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    min-width: 100%;
    padding: 0 6px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    label {
      font-weight: ${({ theme }: Props) => theme.fontWeights.medium};
    }
  }
`;

export const FilterGroupSelect = styled(Cascader)`
  display: flex;
  width: 100%;

  .ant-select-arrow {
    height: 24px;
    margin-top: -12px;
    width: 24px;
  }

  .ant-select-selection-overflow {
    &-item-rest {
      .ant-select-selection-item {
        background: transparent;
        border: none;
        padding: 0;
      }
    }
  }

  + .ant-select-dropdown {
    background: transparent;
    border: none;
    box-shadow: none;
    overflow: inherit;
    width: 100%;

    .ant-cascader-menus {
      background: transparent;
      box-shadow: none;
      display: block;
      font-size: 15px;
      max-width: 100%;
      position: relative;
      white-space: nowrap;
      z-index: 1050;

      @media (max-width: 1080px) {
        width: 70%;
      }

      @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
        width: 100%;
      }

      .ant-cascader-menu {
        background: ${getCssVar('select.cascader.bg')};
        border-radius: 2px 0 0 2px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 9px 28px 8px rgba(0, 0, 0, 0.05);
        display: inline-block;
        font-size: 15px;
        font-weight: 400;
        height: auto;

        max-height: 250px;
        width: 100%;

        @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
          width: 50%;
        }

        &:not(:first-child) {
          border-radius: 0 2px 2px 2px;
          margin-left: 1px;
          max-width: 260px;

          @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
            max-width: 50%;
            margin-left: 0;
          }
        }

        &-item {
          padding: 10px 8px 10px 12px;
          position: relative;

          &-content {
            overflow: hidden;
            padding-right: 20px;
            text-overflow: ellipsis;

            &:before {
              background: ${getCssVar('select.cascader.bg')};
              content: '';
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
              z-index: -1;
            }
          }

          &-active {
            background-color: ${getCssVar('selectChecked')};
          }

          &:hover {
            background: ${getCssVar('select.cascader.bgHover')};
          }

          &-expand-icon {
            margin-left: 8px;
            margin-top: -2px;

            > span {
              align-items: center;
              display: flex;
              flex-direction: row;
            }
          }
        }

        &:nth-child(1) {
          .ant-cascader-checkbox {
            display: none;
          }
        }
      }
    }

    .ant-cascader-checkbox {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);

      .ant-cascader-checkbox-inner {
        display: none;
      }

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='check' width='1em' height='1em' fill='%23866930' aria-hidden='true'%3E%3Cpath d='M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: 13px;
        opacity: 0;
        width: 18px;
      }

      &-checked {
        &:before {
          opacity: 1;
        }

        + .ant-cascader-menu-item-content {
          &:before {
            background-color: ${getCssVar('selectChecked')};
          }
        }
      }
    }
  }

  .ant-select-selector {
    box-shadow: none !important;
    height: 40px;
    min-width: 100%;
    padding: 1px 4px;
  }

  .ant-select-selection-overflow-item {
    max-width: ${({ value, options, maxTagCount }: Props) => {
      // we have to keep place for search field which is added by Cascader
      const CONTAINER_WIDTH = '95%';

      if (!value || value.length < 2) {
        return CONTAINER_WIDTH;
      }

      const option = options?.find((option) => option.value === value[0][0]);

      // check if all children are selected because Cascader shows only parent item if all children are selected
      if (option?.children?.length === value.length) {
        return CONTAINER_WIDTH;
      }

      const count = maxTagCount && value.length > maxTagCount ? maxTagCount : value.length;

      return `calc(${CONTAINER_WIDTH} / ${count})`;
    }};
  }

  &.checked {
    .ant-select-selector {
      background-color: ${getCssVar('selectChecked')};
      border: 2px solid ${getCssVar('input.filterBorder')};
    }

    .ant-select-clear {
      background-color: ${getCssVar('selectChecked')};
    }

    &:hover {
      .ant-select-selector {
        border: 2px solid ${getCssVar('input.filterBorder')};
      }
    }
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    width: ${({ theme }: Props) => theme.sizes.full};
  }

  .clear-icon {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .ant-select-selection-item {
    background: ${getCssVar('select.multiple.bg')};
    border-color: ${getCssVar('select.multiple.borderColor')};
    height: 28px;
    line-height: 26px;
    margin-bottom: 0;
    margin-top: 0;
    padding-inline-start: 5px;
  }

  .ant-select-selection-item-remove {
    color: ${getCssVar('select.multiple.remove.color')};

    &:hover,
    &:focus {
      color: ${getCssVar('select.multiple.remove.focusColor')};
    }
  }

  .ant-select-selection-item-remove svg {
    align-self: center;
    display: flex;
    height: 100%;
  }
`;
