import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../../../styles/theme';

type FormLabelProps = {
  required?: boolean;
};

export const FormLabel = styled.span<FormLabelProps>`
  color: ${getCssVar('textColor')};

  &:before {
    color: ${({ theme }: { theme: Theme }) => theme.colors.red[700]};
    content: '*';
    display: ${({ required }: FormLabelProps) => (required ? 'inline-block' : 'none')};
    font-family: SimSun, sans-serif;
    font-size: 15px;
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.medium};
    position: relative;
    top: -2px;
  }

  font-size: 15px;
  font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.semibold};
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[1]};
`;

type FormItemProps = {
  maxWidth?: string;
};

export const FormItem = styled.span<FormItemProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
  max-width: ${({ maxWidth }: FormItemProps) => maxWidth || '600px'};
`;
