import React from 'react';
import { getCssVar, icons } from 'styles/theme';
import { Filter } from 'services/api';
import { Icon } from 'shared';
import { FilterType } from 'shared/Table/types';
import {
  FilterActionsCol,
  FilterFieldsCol,
  FilterLabelCol,
  FiltersRow,
  FilterTitle,
  InputsRow,
  StyledButton,
  TableFiltersContainer,
  TableFiltersContainerRow,
} from '../styled';
import { useApp } from '../../../store';
import { ResetFilter } from './ResetFilter';
import { FilterFactory } from './FilterFactory';

export type MobileFiltersProps = {
  handleCloseClick: () => void;
  filterFields?: FilterType[];
  isShowResetButton: boolean;
  clearAll: () => void;
  handleApplyClick: () => void;
  getSelectOrRangeValue: (name: string) => string[] | string | undefined;
  getSelectField: (name: string) => null | Filter;
  onFilterChange: (
    field: string,
    value: string[] | string,
    filterType: Filter.type,
    params?: Record<string, unknown>,
  ) => void;
  hiddenFilters?: Filter[] | Record<string, unknown>;
};

export const MobileFilters = ({
  handleCloseClick,
  filterFields,
  isShowResetButton,
  clearAll,
  handleApplyClick,
  onFilterChange,
  getSelectOrRangeValue,
  hiddenFilters,
  getSelectField,
}: MobileFiltersProps) => {
  const { isMobile } = useApp();
  const isResetButtonVisible = isShowResetButton || !!hiddenFilters?.length;

  return (
    <TableFiltersContainer>
      <TableFiltersContainerRow gutter={12} justify='space-between'>
        {filterFields && !!filterFields.length && (
          <FilterActionsCol>
            <FiltersRow gutter={12}>
              <FilterLabelCol>
                <Icon
                  color={getCssVar('filterIconClose')}
                  component={icons.close}
                  onClick={handleCloseClick}
                />
                <FilterTitle $isMobile={isMobile}>Filters</FilterTitle>
              </FilterLabelCol>

              <FilterFieldsCol>
                <InputsRow gutter={12}>
                  {filterFields.map((filterField: FilterType) => (
                    <FilterFactory
                      key={filterField.label}
                      filterField={filterField}
                      getValue={getSelectOrRangeValue}
                      onFilterChange={onFilterChange}
                      getSelectField={getSelectField}
                    />
                  ))}
                </InputsRow>
              </FilterFieldsCol>
              <ResetFilter
                isVisible={isResetButtonVisible}
                clearAll={clearAll}
                hasHiddenFilters={!!hiddenFilters?.length}
              />
            </FiltersRow>
          </FilterActionsCol>
        )}

        <StyledButton type='primary' onClick={handleApplyClick}>
          Apply
        </StyledButton>
      </TableFiltersContainerRow>
    </TableFiltersContainer>
  );
};
