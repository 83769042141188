import React from 'react';
import { Box } from 'shared';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { ColumnsType } from 'antd/es/table/interface';
import {
  formatUpdatedOrCreatedTime,
  getCapitalizedString,
  getRenderValue,
  getTimeFromLastUpdateOrCreate,
} from 'services/helpers';
import { TimeFromLastUpdate } from 'shared/styled';
import { ColumnKey, TableTitles } from '../constants';
import { SupportRequestsRow } from '../types';
import { IconColoring } from './IconColoring';
import { getLabelPriority, getNormalizedStatus } from '../helpers/helpers';
import { TagColoring } from './TagColoring';

export const columns: ColumnsType<SupportRequestsRow> = [
  {
    key: ColumnKey.Priority,
    title: TableTitles.Priority,
    dataIndex: ColumnKey.Priority,
    render: (priority: string) =>
      priority ? <TagColoring title={getLabelPriority(priority)} /> : emptyDataString,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    key: ColumnKey.Ticket,
    title: TableTitles.Ticket,
    dataIndex: ColumnKey.Ticket,
    sorter: true,
    showSorterTooltip: false,
    render: (ticket: string) => (
      <Box flexDirection='row' minW='80px'>
        {getRenderValue(ticket)}
      </Box>
    ),
    className: 'center-head-title',
    align: 'center',
  },
  {
    key: ColumnKey.Summary,
    title: TableTitles.Summary,
    dataIndex: ColumnKey.Summary,
    render: (summary: string) => <Box w='280px'>{getRenderValue(summary, NO_VALUE_LABEL)}</Box>,
  },
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    render: (status: string) =>
      !status ? (
        emptyDataString
      ) : (
        <Box flexDirection='row' minW='80px'>
          <IconColoring status={getNormalizedStatus(status)} />
          <span>{getCapitalizedString(getNormalizedStatus(status))}</span>
        </Box>
      ),
    width: 120,
  },
  {
    key: ColumnKey.Type,
    title: TableTitles.Type,
    dataIndex: ColumnKey.Type,
    showSorterTooltip: false,
    render: (type: string) => <>{getRenderValue(type)}</>,
  },
  {
    key: ColumnKey.SubmittedBy,
    title: TableTitles.SubmittedBy,
    dataIndex: ColumnKey.SubmittedBy,
    render: (submittedBy: string) => (
      <Box flexDirection='row' minW='120px'>
        {getRenderValue(submittedBy)}
      </Box>
    ),
  },
  {
    key: ColumnKey.Created,
    title: TableTitles.Created,
    dataIndex: ColumnKey.Created,
    width: 170,
    sorter: true,
    showSorterTooltip: false,
    render: (date: string) =>
      !date ? (
        emptyDataString
      ) : (
        <Box w='160px'>
          <Box>{formatUpdatedOrCreatedTime(date)}</Box>
          <TimeFromLastUpdate>{getTimeFromLastUpdateOrCreate(date)}</TimeFromLastUpdate>
        </Box>
      ),
    className: 'right-head-title',
    align: 'right',
  },
  {
    key: ColumnKey.Updated,
    title: TableTitles.Updated,
    dataIndex: ColumnKey.Updated,
    width: 160,
    sorter: true,
    showSorterTooltip: false,
    render: (date: string) =>
      !date ? (
        emptyDataString
      ) : (
        <Box w='160px'>
          <Box>{formatUpdatedOrCreatedTime(date)}</Box>
          <TimeFromLastUpdate>{getTimeFromLastUpdateOrCreate(date)}</TimeFromLastUpdate>
        </Box>
      ),
    align: 'right',
    className: 'right-head-title',
  },
];
