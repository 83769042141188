/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeatureFlagResponse } from '../models/FeatureFlagResponse';
import type { FeaturesFlagsResponse } from '../models/FeaturesFlagsResponse';
import { request as __request } from '../core/request';

export class FeatureFlagService {
  /**
   * Features flags
   * Get features flags
   * @returns FeaturesFlagsResponse Return all features flags
   * @throws ApiError
   */
  public static async getAllFlags(): Promise<FeaturesFlagsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/get-all-flags`,
    });
    return result.body;
  }

  /**
   * Feature flag
   * Get feature flag
   * @returns FeatureFlagResponse Return feature flag
   * @throws ApiError
   */
  public static async getFlag({
    featureName,
  }: {
    /** Feature name **/
    featureName: string;
  }): Promise<FeatureFlagResponse> {
    const result = await __request({
      method: 'GET',
      path: `/get-flag/${featureName}`,
      errors: {
        400: `Missing or incorrect feature name`,
      },
    });
    return result.body;
  }
}
