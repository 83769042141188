/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FileExtension {
  PDF = 'pdf',
  DOCX = 'docx',
  XLSX = 'xlsx',
  JPEG = 'jpeg',
  JPG = 'jpg',
}
