import React from 'react';
import { LogoPlaceholder, Wrapper } from './styled';

export function PdfPage16() {
  return (
    <Wrapper>
      <h2>Sweet dreams with…</h2>

      <LogoPlaceholder>Company Logo</LogoPlaceholder>
    </Wrapper>
  );
}
