export enum PermissionsLevel {
  Full = 'Full access',
  ViewOnly = 'View only',
  NoAccess = 'No access',
}

export enum PermissionOptions {
  Process = 'Process',
  CybersafetyAndPhishing = 'Cybersafety and Phishing',
  VulnerabilityManagement = 'Vulnerability management',
  ThreatDetection = 'Threat detection',
  PenetrationTesting = 'Penetration testing',
  EmailSecurity = 'Email security',
  DataProtection = 'Data protection',
  CMDB = 'CMDB',
  SupportRequests = 'Support Requests',
  Documents = 'Documents',
  ManageSoftware = 'Manage Software',
  AdminUsers = 'Admin Users',
  SecuritySettings = 'Security settings',
  AuditLogs = 'Audit logs',
  Report = 'Report',
  PatchManagement = 'Patch management',
  MaintenanceSettings = 'Maintenance settings',
  Notifications = 'Notifications',
  Messaging = 'Messaging',
  ServicesIntegrations = 'Services integrations',
}
