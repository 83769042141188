import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Typography } from 'antd';
import { Box } from 'shared';
import { useTheme } from 'styled-components';
import { getCssVar } from '../../../../styles/theme';

type Props = {
  isMobile?: boolean;
};

const { Paragraph } = Typography;

export const SubTitle = ({ isMobile, children }: PropsWithChildren<Props>) => {
  const { colors, spacing } = useTheme();
  const textColor = isMobile ? colors.white : getCssVar('textColor');

  return (
    <Paragraph>
      <Box textAlign='center' mt={spacing[2]} color={textColor}>
        {children}
      </Box>
    </Paragraph>
  );
};
