import styled from 'styled-components';
import { Box } from '../../shared';
import { getCssVar } from '../../styles/theme';

interface StyledProps {
  isSelected?: boolean;
  isMobile?: boolean;
}

export const ModeWrapper = styled(Box)<StyledProps>`
  border-color: ${({ isSelected }) => (isSelected ? getCssVar('primaryColor') : 'transparent')};
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  min-width: 180px;

  .anticon {
    height: 120px;
    margin: 12px;
    width: 155px;
  }

  .ant-radio-wrapper {
    color: ${getCssVar('textColor')};
    padding: 0 12px 12px;
  }
`;
