import styled from 'styled-components';
import { Theme } from 'styles/theme/types';

export const AssigneToContainer = styled.div`
  display: flex;
`;

export const IconsContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[6]};
  .anticon {
    color: ${({ theme }: { theme: Theme }) => theme.colors.gray[300]} !important;
    cursor: pointer;
  }
  .anticon:last-child {
    margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[7]};
  }
`;
