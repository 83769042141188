import React from 'react';
import { renderValue } from 'pages/CmdbHostDetails/helpers';
import { ColumnsType } from 'antd/es/table/interface';
import { Box } from 'shared';
import { getFormattedDate } from 'services/helpers';
import { ArrayElement } from 'services/interfaces';
import { PatchesByIdsResponse, PatchSchema } from 'services/api';
import { byNumber, byString, byValue } from 'sort-es';

export const columns: ColumnsType<ArrayElement<PatchesByIdsResponse>> = [
  {
    key: 'title',
    title: 'Patch title',
    dataIndex: 'title',
    sorter: byValue((date: PatchSchema) => String(date.title), byString()),
    width: '70%',
    render: (value: string) => renderValue(value),
  },
  {
    key: 'kb',
    title: 'KB',
    sorter: byValue((date: PatchSchema) => String(date.kb), byString()),
    dataIndex: 'kb',
    render: (value: string) => renderValue(value),
  },
  {
    key: 'publishedDate',
    title: 'Published date',
    dataIndex: 'publishedDate',
    className: 'right-head-title',
    align: 'right',
    sorter: byValue((date: PatchSchema) => Number(date.publishedDate), byNumber()),
    defaultSortOrder: 'descend',
    showSorterTooltip: false,
    width: 160,
    render: (date: string) => (
      <Box textAlign='right' w='160px'>
        {getFormattedDate(date)}
      </Box>
    ),
  },
];
