import { ColumnsType, ColumnType, SortOrder } from 'antd/es/table/interface';
import { OrderBy } from 'services/api';
import { RowType } from 'shared/Table/CustomTable';

const getSorterType = (type: string) => {
  if (type) {
    return type === OrderBy.type.DESC ? ('descend' as SortOrder) : ('ascend' as SortOrder);
  }

  return null as SortOrder;
};

export const getTableColumns = (columns: ColumnsType<RowType>, orderBy: OrderBy) => {
  return columns.map((column: ColumnType<RowType>) =>
    column.key === orderBy.field
      ? { ...column, defaultSortOrder: getSorterType(orderBy.type || '') }
      : column,
  );
};
