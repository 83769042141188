import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  LoginRequest,
  ResetPasswordRequest,
  AuthService,
  ValidateTwoFARequest,
  OpenAPI,
  ForgotPasswordRequest,
} from 'services/api';

const login = createAsyncThunk('auth/login', async (requestBody: LoginRequest) => {
  try {
    const response = await AuthService.login({ requestBody });

    return response;
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});

const twofaData = createAsyncThunk('auth/twofa/init', async (requestBody: { token: string }) => {
  try {
    const response = await AuthService.setTwofa({ requestBody });

    return response;
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});

const twofaValidate = createAsyncThunk(
  'auth/twofa/validate',
  async (requestBody: ValidateTwoFARequest) => {
    try {
      const response = await AuthService.validateTwofa({ requestBody });

      if (response.token) OpenAPI.TOKEN = response.token;

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const resetPasswordInit = createAsyncThunk(
  'auth/resetPassword/init',
  async (requestBody: ForgotPasswordRequest) => AuthService.forgotPassword({ requestBody }),
);

const resetUserPasswordInit = createAsyncThunk(
  'admin-users/resetPassword/init',
  async (requestBody: ForgotPasswordRequest) => AuthService.forgotPassword({ requestBody }),
);

const resetPasswordConfirm = createAsyncThunk(
  'auth/resetPassword/confirm',
  async (body: { requestBody: ResetPasswordRequest; resetLink: string }) =>
    AuthService.resetPassword(body),
);

const refreshToken = createAsyncThunk('auth/token/refresh', async () => AuthService.getNewToken());

const getResetPasswordInfo = createAsyncThunk(
  'auth/resetPassword/getInfo',
  async (body: { resetLink: string }) => AuthService.getResetPasswordInfo(body),
);

const getProfile = createAsyncThunk('auth/profile/get', async () => {
  try {
    const response = await AuthService.getProfile();

    return response;
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});

export const authApi = {
  login,
  resetPasswordInit,
  resetUserPasswordInit,
  resetPasswordConfirm,
  twofaData,
  twofaValidate,
  refreshToken,
  getResetPasswordInfo,
  getProfile,
};
