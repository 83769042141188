import { useState } from 'react';
import { MessagingFileDownloadResponse } from './types';

export const initialProgressState = { stages: 10, stage: 0, percent: 0, isProcessing: false };

export const useFileDownloadMessagingProgress = () => {
  const [progress, setProgress] = useState(initialProgressState);

  function progressMessageHandler(response: MessagingFileDownloadResponse) {
    const { data } = response.message || {};
    const { status, stages, stage } = data || {};

    // eslint-disable-next-line no-console
    console.log('Message received:', data);

    const maxProgress = 100;

    // set number of stages calculated at BE
    if (status === 'start') {
      setProgress((state) => ({
        ...state,
        percent: 5, // set initial progress to 5% to show that process has started
        stages,
      }));
    }

    if (status === 'progress') {
      setProgress((state) => ({
        ...state,
        stage,
        percent: (maxProgress / state.stages) * stage,
      }));
    }
  }

  return {
    progress,
    setProgress,
    progressMessageHandler,
    initialProgressState,
  };
};
