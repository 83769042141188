import { Row } from 'antd';
import { DeleteDropdown } from 'pages/Cmdb/components/DeleteDropdown';
import { ManageTagDropdown } from 'pages/Cmdb/components/ManageTagDropdown';
import React from 'react';
import { Box, Button, Icon } from 'shared';
import { useAuth } from 'store';
import { useTheme } from 'styled-components';
import { DeleteButton, ManageTagButton } from './styled';
import { getCssVar } from '../../../styles/theme';

export interface MultyDeletionButtonsProps {
  onCancel: () => void;
  onSubmit: (soft: boolean, action?: string, tagId?: string) => void;
  onActivated: () => void;
  deleteDisabled?: boolean;
}

export const MultyDeletionButtons = ({
  onCancel,
  onSubmit,
  onActivated,
  deleteDisabled,
}: MultyDeletionButtonsProps) => {
  const { user } = useAuth();
  const [buttonsVisisble, setButtonsVisible] = React.useState(false);
  const theme = useTheme();

  if (!buttonsVisisble) {
    return (
      <Box mt='-2px'>
        <Button
          type='link'
          icon={<Icon component={theme.icons.double_done} />}
          onClick={() => {
            onActivated();
            setButtonsVisible(true);
          }}
        >
          Bulk actions
        </Button>
      </Box>
    );
  }

  return (
    <Row>
      <Button
        type='link'
        icon={<Icon component={theme.icons.close} />}
        onClick={() => {
          setButtonsVisible(false);
          onCancel();
        }}
      >
        Cancel
      </Button>
      <Box ml='12px'>
        {user.isSuperAdmin ? (
          <>
            <ManageTagDropdown onClick={onSubmit} disabled={deleteDisabled}>
              <ManageTagButton type='secondary' disabled={deleteDisabled}>
                Manage tags
                <Icon component={theme.icons.arrow_down} color={getCssVar('button.color')} />
              </ManageTagButton>
            </ManageTagDropdown>
            <DeleteDropdown onClick={onSubmit} placement='bottomCenter' disabled={deleteDisabled}>
              <DeleteButton type='primary' danger disabled={deleteDisabled}>
                Delete selected{' '}
                <Icon component={theme.icons.arrow_down} color={theme.colors.white} />
              </DeleteButton>
            </DeleteDropdown>
          </>
        ) : (
          <>
            <ManageTagDropdown onClick={onSubmit}>
              <ManageTagButton type='default' disabled={deleteDisabled}>
                Manage tags <Icon component={theme.icons.arrow_down} color={theme.colors.black} />
              </ManageTagButton>
            </ManageTagDropdown>
            <DeleteButton
              onClick={() => onSubmit(false)}
              type='primary'
              danger
              disabled={deleteDisabled}
            >
              Delete selected
            </DeleteButton>
          </>
        )}
      </Box>
    </Row>
  );
};
