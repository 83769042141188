import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuditLogsResponse,
  FindingsVulnerabilitySchema,
} from 'pages/FindingsVulnerabilityDetails/data/types';
import { penetrationTestingApi } from '../thunks';

interface InitialState {
  details: FindingsVulnerabilitySchema;
  auditLogs: AuditLogsResponse;
}

const initialState: InitialState = {
  details: {},
  auditLogs: {},
};

const extraReducers = {
  [penetrationTestingApi.getFindingsVulnerabilityDetails.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<FindingsVulnerabilitySchema>,
  ) => {
    state.details = action.payload;
  },
  [penetrationTestingApi.getFindingsVulnerabilityDetails.rejected.toString()]: (
    state: InitialState,
  ) => {
    state.details = {};
  },
  [penetrationTestingApi.getFindingsVulnerabilityDetailsAuditLogs.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<AuditLogsResponse>,
  ) => {
    state.auditLogs = action.payload;
  },
  [penetrationTestingApi.getFindingsVulnerabilityDetailsAuditLogs.rejected.toString()]: (
    state: InitialState,
  ) => {
    state.auditLogs = {};
  },
};

export const findingsVulnerabilityDetails = createSlice({
  name: 'findingsVulnerabilityDetails',
  initialState,
  reducers: {},
  extraReducers,
});
