import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Theme } from 'styles/theme/types';
import { useTheme } from 'styled-components';
import { Values } from '../types';
import { EChartsEventParams, IEChartsOption } from '../interfaces';
import { DELAY } from '../constants';

type Props = {
  values: Values;
  getOption: (values: Values, theme: Theme, hasClickHandler: boolean) => IEChartsOption;
  height?: string;
  onEvents?: Record<string, (params?: EChartsEventParams) => void>;
  chartRef: React.LegacyRef<ReactECharts>;
  isLoading?: boolean;
};

let timerId: NodeJS.Timeout;

export const SingleBar = ({
  values,
  getOption,
  height = '330px',
  onEvents,
  chartRef,
  isLoading = false,
}: Props) => {
  const theme = useTheme();
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (isLoading) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => setChartOptions(getOption(values, theme, Boolean(onEvents))), DELAY);

    return () => {
      clearTimeout(timerId);
    };
  }, [getOption, isLoading, onEvents, theme, values]);

  return (
    <ReactECharts
      option={chartOptions}
      style={{ height }}
      onEvents={onEvents}
      ref={chartRef}
      notMerge
    />
  );
};
