import React from 'react';
import { Menu, Row } from 'antd';
import { Routes } from 'services/entities';
import { ActionType } from 'shared/ActionPopup/types';
import { Permission } from 'store';
import { BackLink, Box, Icon, InnerLayoutProps as InnerLayout } from 'shared';
import { StatusLevel } from 'services/constants';
import { formatUpdatedOrCreatedTime } from 'services/helpers';
import { UserDetailsResponse, UserSchema } from 'services/api';
import { HistoryState } from 'services/interfaces';
import { backlinkRoutes, NO_VALUE_LABEL, PermissionsLevel } from 'app-constants';
import { StatusComponent } from 'shared/layouts/Inner/components';
import { getCssVar } from 'styles/theme';
import { CustomMenu } from './styled';

const getLabelStatusLevel = (status: string) => {
  switch (status) {
    case UserSchema.status.ACTIVE:
      return StatusLevel.Low;
    case UserSchema.status.ACCOUNT_LOCKED:
      return StatusLevel.High;
    default:
      return StatusLevel.Closed;
  }
};

export const getLayoutConfig = (
  userDetails: UserDetailsResponse,
  renderActions: () => JSX.Element | JSX.Element[],
  historyState: HistoryState,
  isShowActionButtons: boolean,
): InnerLayout => ({
  title: userDetails.name,
  customComponent: isShowActionButtons ? <Row>{renderActions()}</Row> : undefined,
  backLink: (
    <BackLink
      defaultRoute={Routes.USERS}
      defaultTitle={backlinkRoutes[Routes.USERS]}
      historyState={historyState}
    />
  ),
  status: (
    <StatusComponent
      level={getLabelStatusLevel(userDetails.status || '')}
      status={userDetails.status || ''}
    />
  ),
});

export const getCustomMenu = (
  actions: ActionType[],
  handleClickAction: (popupId: string) => void,
) => (
  <CustomMenu>
    {actions.map(({ name, icon, popupId, isDanger }, index) => {
      const isLast = index === actions.length - 1;

      return (
        <React.Fragment key={name}>
          {!isLast ? null : <Menu.Divider />}
          <CustomMenu.Item
            key={name}
            icon={<Icon component={icon} color={getCssVar('users.customMenuIcons')} />}
            onClick={() => handleClickAction(popupId)}
          >
            {!isDanger ? name : <Box color={getCssVar('colorHigh')}>{name}</Box>}
          </CustomMenu.Item>
        </React.Fragment>
      );
    })}
  </CustomMenu>
);

export const canEditPermission = (
  userPermission: PermissionsLevel,
  editablePermission: PermissionsLevel,
) => {
  switch (editablePermission) {
    case PermissionsLevel.ViewOnly:
      return (
        userPermission === PermissionsLevel.ViewOnly || userPermission === PermissionsLevel.Full
      );
    case PermissionsLevel.Full:
      return userPermission === PermissionsLevel.Full;
    default:
      return true;
  }
};

export const canCloneUserWithPermissions = (
  userPermissions: Permission[],
  editablePermissions: Permission[],
  isChild?: boolean,
) => {
  if (isChild) {
    return true;
  }

  return !userPermissions.some((userPermission: Permission) => {
    const editPermission = editablePermissions?.find(
      (editPermission: Permission) => editPermission.option === userPermission.option,
    );

    if (!editPermission) {
      return false;
    }

    return !canEditPermission(
      userPermission.level || PermissionsLevel.NoAccess,
      editPermission.level || PermissionsLevel.NoAccess,
    );
  });
};

export const renderDate = (date?: string | number) =>
  !date ? NO_VALUE_LABEL : formatUpdatedOrCreatedTime(date, true);
