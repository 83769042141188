import React from 'react';
import { Box, Button } from 'shared';
import { spacing } from 'styles/theme';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  isDisabled: boolean;
  isMobile?: boolean;
};

export const FormActions = ({ onCancel, onSubmit, isDisabled, isMobile }: Props) => (
  <Box
    w='167px'
    d='flex'
    justify='space-between'
    mt={!isMobile ? spacing[6] : spacing[0]}
    ml={!isMobile ? spacing['2.5'] : spacing[3]}
  >
    <Button type='primary' onClick={onSubmit} disabled={isDisabled}>
      Save
    </Button>
    <Button onClick={onCancel} disabled={isDisabled}>
      Cancel
    </Button>
  </Box>
);
