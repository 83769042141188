import { Spin } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useApp } from 'store';
import { Box, Button, Icon } from 'shared';
import { Annotations } from 'shared/charts/GradientPie/types';
import { getCssVar } from 'styles/theme';
import { useTheme } from 'styled-components';
import { Head } from './components';
import { Link } from '../../pages/Dashboard/components/styled/styled';
import { GradientPie } from '../charts';
import {
  CardBody,
  CardFooter,
  Collapsed,
  GradientPieContainer,
  SpinContainer,
} from './components/styled';
import { AccessMessage } from './components/AcessMessage/AccessMessage';
import { HistoryState } from '../../services/interfaces';

export interface IViewMore {
  text?: string;
  route: string;
  pathname?: string;
  search?: string;
  state?: HistoryState;
}

export type CardProps = {
  className?: string;
  title?: string | React.ReactNode;
  subTitle?: React.ReactNode;
  tooltipTitle?: string;
  getAccessMessage?: {
    text: string;
    href: string;
    layout: 'inline' | 'column';
  };
  showDivider?: boolean;
  layout?: 'vertical' | 'horizontal';
  valueMini?: number | null;
  width?: string;
  height?: string;
  showLoader?: boolean;
  marginBottom?: string;
  marginTop?: string;
  headAction?: React.ReactNode | JSX.Element;
  isHiddable?: boolean;
  underTitle?: JSX.Element;
  scoreAnnotations?: Annotations;
  isValueMiniLoading?: boolean;
  viewMore?: IViewMore;
  headStyles?: {
    pb?: string;
    pt?: string;
    titlePaddingRight?: string;
    titlePaddingLeft?: string;
    padding?: string;
    mt?: string;
    maxWidthTitle?: string;
    actionsDisplay?: string;
    actionsWidth?: string;
    actionsGap?: string;
    actionsJustify?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
    headAlign?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
    justify?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
    direction?: 'row' | 'column';
    zIndex?: number;
  };
  cardPadding?: string;
  bodyPadding?: string;
  titlePlacement?: 'left' | 'inside';
  valuePlacement?: 'right' | 'left' | 'inside';
};

export const Card = ({
  className,
  title,
  subTitle,
  tooltipTitle,
  layout = 'vertical',
  showDivider = true,
  valueMini,
  children,
  width,
  height,
  showLoader,
  marginBottom = '0',
  marginTop = '0',
  headAction,
  isHiddable,
  viewMore,
  headStyles,
  cardPadding,
  underTitle,
  bodyPadding,
  getAccessMessage,
  titlePlacement = 'left',
  valuePlacement = 'right',
  isValueMiniLoading,
  scoreAnnotations,
}: PropsWithChildren<CardProps>) => {
  const { sizes, spacing, icons } = useTheme();
  const isLayoutVertical = layout === 'vertical';

  const { isMobile, isChartsFontLoaded: isFontLoaded } = useApp();

  const [isOpen, setIsOpen] = useState<boolean | undefined>();

  useEffect(() => {
    setIsOpen(!isHiddable);
  }, [isHiddable]);

  return (
    <>
      {valuePlacement === 'right' && titlePlacement === 'inside' && valueMini !== undefined && (
        <GradientPieContainer isOpen={isOpen} isMobile={isMobile} isDirectionRow isProcessPieChart>
          <GradientPie
            value={valueMini}
            height='76px'
            fontSize={18}
            isLoading={isValueMiniLoading || !isFontLoaded}
          />
          {valueMini === null && !isValueMiniLoading && <Icon component={icons.medium_lock} />}
        </GradientPieContainer>
      )}
      <Box
        w={width}
        h={height}
        d='flex'
        flexDirection={isLayoutVertical ? 'column' : 'row'}
        bgColor={getCssVar('card.bg')}
        p={
          cardPadding ||
          (!isMobile
            ? `${sizes[8]} ${sizes['5.5']} ${sizes['5.5']} ${sizes['5.5']}`
            : `${sizes[5]} ${sizes[4]} ${sizes[4]} ${sizes[4]}`)
        }
        boxShadow={`0px ${spacing[1]} ${spacing[3]} ${getCssVar('card.boxShadow')}`}
        border={`${sizes['0.5']} solid ${getCssVar('card.border')}}`}
        marginBottom={marginBottom}
        marginTop={marginTop}
        className={className}
        position='relative'
      >
        {showLoader && (
          <SpinContainer>
            <Spin size='large' />
          </SpinContainer>
        )}
        {title && (
          <Head
            title={title}
            tooltipTitle={tooltipTitle}
            valueMini={valueMini}
            subTitle={subTitle}
            direction={isLayoutVertical ? 'row' : 'column'}
            showDivider={showDivider}
            headAction={headAction}
            isHiddable={isHiddable}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            headStyles={headStyles}
            isMobile={isMobile}
            underTitle={underTitle}
            valuePlacement={valuePlacement}
            titlePlacement={titlePlacement}
            getAccessMessage={getAccessMessage}
            viewMore={!isLayoutVertical && viewMore ? viewMore : undefined}
            isValueMiniLoading={isValueMiniLoading || !isFontLoaded}
            scoreAnnotations={scoreAnnotations}
          />
        )}
        <CardBody
          p={bodyPadding || spacing[3]}
          h={sizes.full}
          d={isOpen ? 'block' : 'none'}
          width='100%'
          flexDirection='row'
          color={getCssVar('textColor')}
        >
          {getAccessMessage && isMobile && (
            <AccessMessage getAccessMessage={getAccessMessage} isMobile={isMobile} />
          )}
          {children}
        </CardBody>

        {(isHiddable || viewMore) && isOpen && (
          <CardFooter
            h='50px'
            borderTop={
              isHiddable ? `${sizes.px} solid ${getCssVar('card.footerBorderTopColor')}` : 'none'
            }
            p={
              !isMobile
                ? `${spacing[4]} ${spacing[0]} ${spacing[0]} ${spacing['2.5']}`
                : `${spacing[4]} ${spacing[0]} ${spacing[0]}`
            }
            d='flex'
            justify={viewMore ? 'space-between' : 'flex-end'}
            alignItems='center'
          >
            {viewMore && isLayoutVertical && (
              <Link
                to={
                  viewMore.state
                    ? {
                        pathname: viewMore.pathname,
                        search: viewMore.search,
                        state: viewMore.state,
                      }
                    : viewMore.route
                }
              >
                {viewMore.text || 'View more'}
                <Icon component={icons.arrow_right_alt} />
              </Link>
            )}

            {isHiddable && (
              <Button type='link' className='collapse' onClick={() => setIsOpen(false)}>
                <Icon component={icons.arrow_up_close} />
                <Collapsed>Collapse</Collapsed>
              </Button>
            )}
          </CardFooter>
        )}
      </Box>
    </>
  );
};
