import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';
import { isTokenExpired } from 'services/helpers';
import { intervalTime, PermissionsLevel } from 'app-constants';
import useFontFaceObserver from 'use-font-face-observer';
import { app, auth, logoutAction, useAuth } from 'store';
import { useDispatch } from 'react-redux';
import { Routes } from 'services/entities';
import { useTheme } from 'styled-components';
import { compareTokens, getAuthTokenFromLocalStorage } from 'services/helpers/token';
import {
  checkSuperAdminPermission,
  getPermissionLevel,
  getPermissionLevelToSupportGrid,
} from './helpers';

export const AuthRouter = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const theme = useTheme();
  const { isAuthorized, authToken, user, permissions } = useAuth();
  const dispatch = useDispatch();
  const fontTimeout = 12000;

  const isFontLoaded = useFontFaceObserver(
    [
      {
        family: theme.colorSet.fontFamily,
        weight: '900',
        style: 'normal',
      },
    ],
    { timeout: fontTimeout },
  );

  React.useEffect(() => {
    dispatch(app.actions.setIsChartsFontLoaded(isFontLoaded));
  }, [isFontLoaded, dispatch]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(app.actions.setIsChartsFontLoaded(true));
    }, fontTimeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch]);

  React.useEffect(() => {
    const callback = () => {
      if (authToken) {
        const tokenFromLocalStorage = getAuthTokenFromLocalStorage();

        if (tokenFromLocalStorage && compareTokens(tokenFromLocalStorage, authToken)) {
          dispatch(auth.actions.setAuthToken(tokenFromLocalStorage));

          return;
        }

        if (isTokenExpired(authToken)) {
          dispatch(logoutAction());
        }
      }
    };

    callback();
    const checkIsTokenExpired = setInterval(() => {
      callback();
    }, intervalTime);

    return () => {
      clearInterval(checkIsTokenExpired);
    };
  }, [dispatch, authToken]);

  return isAuthorized ? (
    <>
      {React.Children.toArray(children).filter((child: typeof children) => {
        if (React.isValidElement<{ path: Routes }>(child)) {
          return (
            getPermissionLevel(child.props.path, permissions, () =>
              getPermissionLevelToSupportGrid(child.props.path, permissions),
            ) !== PermissionsLevel.NoAccess &&
            checkSuperAdminPermission(child.props.path, user.isSuperAdmin)
          );
        }

        return false;
      })}
    </>
  ) : (
    <Redirect to='/' />
  );
};
