import React from 'react';
import { SelectValue } from 'antd/lib/select';
import { Input, Select } from 'shared';
import { Pagination } from 'services/api';
import { GoToContainer, MobilePaginationContainer, PerPageContainer } from './styled';

const { Option } = Select;

export interface MobilePaginationProps {
  selections: { value: number; label: string }[];
  setMobilePagination: (value: Pagination) => void;
  pagination?: Pagination;
  count: number;
}

export const MobilePagination = ({
  selections,
  setMobilePagination,
  pagination,
  count,
}: MobilePaginationProps) => {
  const [select, setSelect] = React.useState(pagination?.count);
  const [goTo, setGoTo] = React.useState(pagination?.page);
  const [disabled, setDisabled] = React.useState(false);
  const totalPageCount = Math.ceil(count / Number(select));

  const handleInputChange: React.ChangeEventHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const page =
        Number(e.currentTarget.value) <= totalPageCount ? +e.currentTarget.value : totalPageCount;

      setGoTo(page);
      if (!e.currentTarget.value) {
        return;
      }

      setMobilePagination({
        count: select,
        page,
      });
    },
    [setGoTo, setMobilePagination, select, totalPageCount],
  );

  const handleSelectChange = React.useCallback(
    (value: SelectValue) => {
      const pageCount = Math.ceil(count / Number(value));

      setSelect(value as number);
      setMobilePagination({
        count: value as number,
        page: Number(goTo) <= pageCount ? goTo : pageCount,
      });
    },
    [goTo, count, setMobilePagination],
  );

  React.useEffect(() => {
    setDisabled(totalPageCount <= 1);
  }, [totalPageCount]);

  React.useEffect(() => {
    if (pagination?.page) {
      setGoTo(pagination.page);
    }

    setSelect(pagination?.count);
  }, [pagination?.page, pagination?.count]);

  return (
    <MobilePaginationContainer>
      <PerPageContainer>
        <span>Results per page</span>
        <Select value={select} onChange={handleSelectChange}>
          {selections.map((e: { value: number; label: string }) => {
            return (
              <Option value={e.value} key={e.value}>
                {e.label}
              </Option>
            );
          })}
        </Select>
      </PerPageContainer>
      <GoToContainer>
        <span>Go to</span>
        <Input type='number' value={goTo || ''} onChange={handleInputChange} disabled={disabled} />
      </GoToContainer>
    </MobilePaginationContainer>
  );
};
