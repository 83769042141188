import React from 'react';
import moment, { Moment } from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { dateFormat } from 'app-constants';
import { Filter } from 'services/api';
import { RangePickerStyled, RangePickerWithLabelContainer } from './styled';
import { FilterType } from '../../../Table/types';
import { useApp } from '../../../../store';
import { StyledLabel } from '../TableFiltersSelect/styled';

export type TableFiltersRangeProps = RangePickerProps &
  FilterType & {
    onDateChange: (field: string, value: string | string[], type: Filter.type) => void;
    dateValue?: string[] | string;
  };

export const TableFiltersRange = ({
  label = 'Created',
  onDateChange,
  dateValue,
  field,
  ...filterProps
}: TableFiltersRangeProps) => {
  const [date, setDate] = React.useState<{
    startDate: number;
    endDate: number;
  } | null>(
    dateValue && dateValue.length ? { startDate: +dateValue[0], endDate: +dateValue[1] } : null,
  );
  const [checked, setChecked] = React.useState(false);
  const { isMobile } = useApp();

  const handleDateChange = React.useCallback(
    (dateRange: [Moment | null, Moment | null] | null) => {
      if (dateRange && dateRange[0] && dateRange[1]) {
        setDate({ startDate: dateRange[0].valueOf(), endDate: dateRange[1].valueOf() });
        onDateChange(
          field || label?.toLowerCase(),
          [`${dateRange[0].startOf('day').valueOf()}`, `${dateRange[1].endOf('day').valueOf()}`],
          Filter.type.RANGE,
        );
      } else {
        setDate(null);
        onDateChange(field || label?.toLowerCase(), '', Filter.type.RANGE);
      }
    },
    [onDateChange, label, field],
  );

  React.useEffect(() => {
    if (dateValue && dateValue.length) {
      setDate({
        startDate: +dateValue[0],
        endDate: +dateValue[1],
      });
      setChecked(true);
    } else {
      setDate(null);
      setChecked(false);
    }
  }, [dateValue]);

  return (
    <RangePickerWithLabelContainer>
      <StyledLabel htmlFor={`id-${label}`}>{label}</StyledLabel>

      <RangePickerStyled
        id={`id-${label}`}
        value={!date ? null : [moment(date.startDate), moment(date.endDate)]}
        onChange={handleDateChange}
        ranges={{
          Today: [moment().startOf('day'), moment().endOf('day')],
          'This Month': [moment().startOf('month'), moment().endOf('day')],
        }}
        className={!checked ? '' : 'checked'}
        separator='—'
        format={dateFormat}
        inputReadOnly={isMobile}
        disabledDate={(current: Moment) => current?.isAfter(moment())}
        {...filterProps}
      />
    </RangePickerWithLabelContainer>
  );
};
