import { Descriptions as AntdDescriptions } from 'antd';
import styled from 'styled-components';

export const Descriptions = styled(AntdDescriptions)`
  .ant-descriptions-item-label {
    width: 35%;
    &:after {
      display: none;
    }
  }
`;
