import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const ShowMore = css<Props>`
  font-size: ${({ theme }: Props) => theme.sizes['4']};
  padding: 0;

  .anticon {
    margin-left: 4px;
    transition: all 0.1s ease-out;
  }

  &:not(:disabled) {
    &:hover,
    &.hover,
    &:focus,
    &:active,
    &.active {
      color: ${getCssVar('buttonShowMoreColor')};

      .anticon {
        transform: translateX(2px);
      }

      span {
        &:not(.anticon) {
          text-decoration: underline;
        }
      }
    }
  }
`;
