import React from 'react';
import { DocumentSchema, FileExtension } from 'services/api';
import { getFormattedDate } from 'services/helpers';
import { DownloadButton } from 'pages/Documents/components/DownloadButton';
import { normalizeFileSize } from 'pages/Documents/helpers';
import { Box, Icon } from 'shared';
import { ColumnsType } from 'antd/lib/table';
import { icons } from 'styles/theme';
import { emptyDataString } from 'app-constants';
import { TableDocumentContainer, TableText } from '../styled/styled';
import { LatestReportsKeys } from '../constants';

export const getTableColumns = (
  downloadClick: (name: string) => void,
  downloading: string | undefined,
): ColumnsType<DocumentSchema> => [
  {
    key: LatestReportsKeys.Name,
    title: 'Document',
    dataIndex: 'name',
    width: '30%',
    render: (text: string, row: DocumentSchema) =>
      !text ? (
        emptyDataString
      ) : (
        <TableDocumentContainer>
          {row.fileext === FileExtension.PDF && <Icon component={icons.picture_as_pdf} />}
          {text}
        </TableDocumentContainer>
      ),
  },
  {
    key: LatestReportsKeys.Size,
    title: 'Size',
    dataIndex: 'size',
    width: '20%',
    render: (size: number) => (
      <TableText>{typeof size !== 'number' ? emptyDataString : normalizeFileSize(size)}</TableText>
    ),
  },
  {
    key: LatestReportsKeys.CreatedOn,
    title: 'Created',
    width: '15%',
    dataIndex: 'createdOn',
    render: (date: string) => (
      <Box w='160px'>
        <TableText>{getFormattedDate(date)}</TableText>
      </Box>
    ),
    className: 'right-head-title',
    align: 'right',
  },
  {
    key: LatestReportsKeys.Action,
    title: '',
    dataIndex: 'action',
    render: (_: string, row: DocumentSchema) => (
      <DownloadButton
        name={row.name || ''}
        click={() => downloadClick(row.name || '')}
        downloading={downloading}
        textWhenMobile
      />
    ),
  },
];
