import { Col } from 'antd';
import styled, { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { ShowMore } from './showMore';
import { Link } from './link';
import { Primary } from './primary';
import { Button as AntdButton, ButtonProps } from './Button';
import { Secondary } from './secondary';
import { PrimaryOrDanger } from './primaryOrDanger';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const Button = styled(AntdButton)<Props>`
  align-items: center;
  color: ${getCssVar('primaryColor')};
  display: inline-flex;
  font-size: ${getCssVar('button.fontSize')};
  font-weight: ${getCssVar('button.fontWeight')};
  justify-content: center;
  text-transform: ${getCssVar('button.textTransform')};

  ${(props: ButtonProps) => {
    switch (props.type) {
      case 'primary':
        if (props.danger) {
          return css`
            ${PrimaryOrDanger}
          `;
        }

        return css`
          ${Primary}
        `;
      case 'secondary':
        return css`
          ${Secondary}
        `;
      case 'link':
        return css`
          padding-bottom: 0;
          text-transform: none;
          ${Link}
        `;
      case 'showMore':
        return css`
          ${ShowMore}
        `;
      default:
        return css``;
    }
  }}
  &.collapse {
    font-weight: ${({ theme }) => theme.fontWeights.light};
  }

  &.ant-btn-link {
    text-transform: none;
  }

  /* ghost secondary button */
  &.ant-btn:not(.ant-btn-primary):not(.secondary):not(.ant-btn-link) {
    background-color: ${getCssVar('buttonSecondary.backgroundColor')};
    border-color: ${getCssVar('buttonSimple.borderColor')};
    border-radius: ${getCssVar('button.borderRadius')};
    color: ${getCssVar('textColor')};

    &:hover,
    &.hover,
    &:focus,
    &.focus {
      &:not(:disabled) {
        background-color: ${getCssVar('buttonSimple.hover.backgroundColor')};
        border-color: ${getCssVar('buttonSimple.hover.borderColor')};
        color: ${getCssVar('buttonSimple.hover.color')};
      }
    }

    &:active,
    &.active {
      &:not(:disabled) {
        background-color: ${getCssVar('buttonSimple.active.backgroundColor')};
        border-color: ${getCssVar('buttonSimple.active.borderColor')};
        color: ${getCssVar('buttonSimple.active.color')};
      }
    }

    &:disabled,
    &:disabled:hover {
      background-color: ${getCssVar('buttonSimple.disabled.backgroundColor')};
      border-color: ${getCssVar('buttonSimple.disabled.borderColor')};
      color: ${getCssVar('buttonSimple.disabled.color')};
    }
  }
`;

export const WrapperCol = styled(Col)`
  a.ant-btn {
    padding-bottom: 0;
  }
`;
