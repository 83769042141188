import { minLengthToSearch } from 'app-constants';
import { FilterType } from 'shared/Table/types';
import {
  SupportRequestsImpact,
  SupportRequestsPriority,
  SupportRequestsStatus,
  SupportRequestsType,
  OrderBy,
  Filter,
} from 'services/api';
import { InputType } from '../../services/api/models/InputType';

export const searchPlaceholder = 'Search by subject/ticket #/submitted by';
export const searchFields = ['subject', 'ticket', 'createdBy'];

export const searchSettings = {
  fields: searchFields,
  min: minLengthToSearch,
  placeholder: searchPlaceholder,
};

export enum TableTitles {
  Priority = 'Priority',
  Ticket = 'Ticket #',
  Summary = 'Summary',
  Status = 'Status',
  Type = 'Type',
  SubmittedBy = 'Submitted by',
  Created = 'Created',
  Updated = 'Updated',
}

export enum ColumnKey {
  Priority = 'priority',
  Ticket = 'id',
  Summary = 'summary',
  Status = 'status',
  Type = 'type',
  SubmittedBy = 'profileName',
  Created = 'created',
  Updated = 'updated',
}

export enum SupportRequestStatusLabel {
  Active = 'Open',
  WithNorm = 'With norm',
  Resolved = 'Resolved',
  Closed = 'Closed',
  WithCustomer = 'With you',
}

export const typesOptions = [
  {
    value: SupportRequestsType.SECURITY_INCIDENT,
    label: SupportRequestsType.SECURITY_INCIDENT,
  },
  {
    value: SupportRequestsType.SUPPORT_INCIDENT,
    label: SupportRequestsType.SUPPORT_INCIDENT,
  },
  {
    value: SupportRequestsType.SERVICE_REQUEST,
    label: SupportRequestsType.SERVICE_REQUEST,
  },
];

export const statusesOptions = [
  { value: SupportRequestsStatus.ACTIVE, label: SupportRequestStatusLabel.Active },
  { value: SupportRequestsStatus.WITH_NORM, label: SupportRequestStatusLabel.WithNorm },
  { value: SupportRequestsStatus.RESOLVED, label: SupportRequestStatusLabel.Resolved },
  { value: SupportRequestsStatus.CLOSED, label: SupportRequestStatusLabel.Closed },
  { value: SupportRequestsStatus.WITH_CUSTOMER, label: SupportRequestStatusLabel.WithCustomer },
];

export const priorityOptions = [
  { value: SupportRequestsPriority._1, label: SupportRequestsImpact.HIGH },
  { value: SupportRequestsPriority._2, label: SupportRequestsImpact.MEDIUM },
  { value: SupportRequestsPriority._3, label: SupportRequestsImpact.LOW },
];

export const baseFilterFields: FilterType[] = [
  {
    field: 'priority',
    options: priorityOptions,
    label: TableTitles.Priority,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.VALUE,
  },
  {
    field: 'status',
    options: statusesOptions,
    label: TableTitles.Status,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.VALUE,
  },
  {
    field: 'created',
    label: TableTitles.Created,
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export const filterByType: FilterType = {
  field: 'type',
  options: typesOptions,
  label: TableTitles.Type,
  inputType: InputType.SELECTOR,
  filterType: Filter.type.VALUE,
};

export const extendedFilterFields: FilterType[] = [...baseFilterFields, filterByType];

export const initialOrderBy = {
  field: 'updated',
  type: OrderBy.type.DESC,
};

export const InternalServices = 'InternalServices';
