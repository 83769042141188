import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  ApiError,
  GridRequestParams,
  ServiceRequestsService,
  SupportRequestDetailsRequestBody,
  CreateSupportRequestRequestBody,
  ExportSupportRequestBody,
  CustomerId,
} from 'services/api';

const supportTableContent = createAsyncThunk(
  'supportRequest/data/get',
  async (requestBody: GridRequestParams) => {
    try {
      return await ServiceRequestsService.getSupportRequests(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const supportRequestDetails = createAsyncThunk(
  'supportRequest/details/get',
  async (requestBody: SupportRequestDetailsRequestBody) => {
    try {
      return await ServiceRequestsService.getSupportRequest(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const createNewSupportRequest = createAsyncThunk(
  'supportRequest/data/post',
  async (request: CreateSupportRequestRequestBody) => {
    try {
      return await ServiceRequestsService.createSupportRequest(request);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const exportCSV = createAsyncThunk(
  'supportRequest/csv/get',
  async (request: ExportSupportRequestBody) => {
    try {
      return await ServiceRequestsService.exportSupportRequestsToCsv(request);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getSecurityIncidentsStress = createAsyncThunk(
  'supportRequest/get/securityIncidentsStress',
  async (customerId: CustomerId) => {
    try {
      return await ServiceRequestsService.getSecurityIncidentsTrend({
        customerId,
        startDate: moment().subtract(1, 'month').valueOf(),
        endDate: moment().valueOf(),
      });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const supportRequestsApi = {
  supportTableContent,
  createNewSupportRequest,
  supportRequestDetails,
  exportCSV,
  getSecurityIncidentsStress,
};
