/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrderBy = {
  field?: string;
  type?: OrderBy.type;
};

export namespace OrderBy {
  export enum type {
    ASC = 'ASC',
    DESC = 'DESC',
  }
}
