import React, { memo } from 'react';
import { useScreenSizes } from 'services/hooks';
import { DesktopSidebar } from './DesktopSidebar';
import { MobileSidebar } from './MobileSidebar';

export const SidebarFactory = memo(() => {
  const [isDesktop] = useScreenSizes();

  if (isDesktop) {
    return <DesktopSidebar />;
  }

  return <MobileSidebar />;
});
