import React, { useCallback, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RequestStatus } from 'store/loading/slice';
import { useParams } from 'react-router';
import { Col } from 'antd';
import {
  AppDispatch,
  useApp,
  useAssetDetails,
  useCustomer,
  useAssetDetailsPotentialVulnerabilities,
  useLoading,
  useCMDBPotentialVulnerabilities,
} from 'store';
import { InnerLayout } from 'shared';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { HistoryState } from 'services/interfaces';
import { useAgedHostsOrCmdbRoute, useGetPermission } from 'services/hooks';
import { Routes } from 'services/entities';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { VulnerabilityStatus } from 'services/api';
import { assetDetails } from 'store/assetDetails/slice';
import { Row } from './styled';
import { getLayoutConfig } from './helpers';
import { CmdbMainContent, HostDetailsMainInfo, AssetOwner } from './components';

export const CmdbHostDetails = () => {
  const { state, pathname } = useLocation<HistoryState>();
  const ref = useRef(false);
  const [layoutConfig, setLayoutConfig] = React.useState({});
  const customer = useCustomer();
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useApp();
  const dispatch: AppDispatch = useDispatch();
  const { detailsAdditionalInformation, vulnerabilitiesCountByStatus } = useAssetDetails();
  const history = useHistory();
  const isFullAccess = useGetPermission(PermissionOptions.CMDB) === PermissionsLevel.Full;
  const isPotentialVulnerabilitiesActive = useAssetDetailsPotentialVulnerabilities();
  const isCMDBPotentialVulnerabilities = useCMDBPotentialVulnerabilities();

  const { pageRoute: backLinkRoute } = useAgedHostsOrCmdbRoute();

  const loading = useLoading([
    assetDetailsApi.getDetailsVulnerabilitiesListByHost.typePrefix,
    assetDetailsApi.getVulnerabilitiesBySeverity.typePrefix,
    assetDetailsApi.getVulnerabilitiesCountByStatus.typePrefix,
  ]);

  const getDetailsAdditionalInformation = useCallback(async () => {
    const result = await dispatch(
      assetDetailsApi.getDetailsAdditionalInformation({
        customerId: customer,
        hostId: id,
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      history.push(Routes.NOT_FOUND);
    }
  }, [dispatch, customer, id, history]);

  React.useEffect(() => {
    if (!ref.current) {
      getDetailsAdditionalInformation();
      ref.current = true;
      dispatch(
        assetDetails.actions.setShowPotentialVulnerabilities(isCMDBPotentialVulnerabilities),
      );

      return;
    }

    history.push(backLinkRoute);
  }, [
    history,
    getDetailsAdditionalInformation,
    dispatch,
    isCMDBPotentialVulnerabilities,
    backLinkRoute,
  ]);

  const onSwitchToggleButton = React.useCallback(
    (isPotentialVulnsSwitched: boolean) =>
      dispatch(assetDetails.actions.setShowPotentialVulnerabilities(isPotentialVulnsSwitched)),
    [dispatch],
  );

  const isToggleButtonLoading = Object.values(loading).some((item) => item);
  const potentialVulnerabilitiesCount =
    vulnerabilitiesCountByStatus?.find((vuln) => vuln.status === VulnerabilityStatus.POTENTIAL)
      ?.count || 0;

  const onSuccessCallback = React.useCallback(() => {
    if (state?.backTo?.route) {
      return history.push(`${state.backTo.route}${state.backTo?.search}`);
    }

    return history.push(`${backLinkRoute}`);
  }, [state, backLinkRoute, history]);

  React.useEffect(() => {
    setLayoutConfig(
      getLayoutConfig(
        onSuccessCallback,
        onSwitchToggleButton,
        backLinkRoute,
        state,
        pathname,
        detailsAdditionalInformation.hostname,
        id,
        isFullAccess,
        isPotentialVulnerabilitiesActive,
        isToggleButtonLoading,
        potentialVulnerabilitiesCount,
      ),
    );
  }, [
    state,
    pathname,
    detailsAdditionalInformation,
    history,
    id,
    isFullAccess,
    customer,
    dispatch,
    onSwitchToggleButton,
    isPotentialVulnerabilitiesActive,
    isToggleButtonLoading,
    potentialVulnerabilitiesCount,
    onSuccessCallback,
    backLinkRoute,
  ]);

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={32}>
        <Col span={32} xs={24} xxl={16} xl={16} order={isMobile ? 2 : 1}>
          <CmdbMainContent />
        </Col>

        <Col span={32} xs={24} xxl={8} xl={8} order={isMobile ? 1 : 2}>
          <HostDetailsMainInfo isMobile={isMobile} />
          <AssetOwner isMobile={isMobile} />
        </Col>
      </Row>
    </InnerLayout>
  );
};
