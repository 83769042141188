import { Link as RouterLink } from 'react-router-dom';
import { Col as AntdCol, Row as AntdRow } from 'antd';
import styled from 'styled-components';
import AntdLayout, { Content as AntdContent, Header as AntdHeader } from 'antd/lib/layout/layout';
import { Theme } from 'styles/theme/types';
import { breakpoints, getCssVar } from 'styles/theme';
import { Icon } from 'shared/Icon';

type Props = { theme: Theme; $isMobile?: boolean };

export const ContentLayout = styled(AntdContent)`
  height: 100%;
`;

export const Layout = styled(AntdLayout)`
  background-color: ${({ theme }: Props) => theme.colors.transparent};
  height: ${({ theme }: Props) => theme.sizes.full};
  min-height: ${({ theme }: Props) => theme.sizes.pageHeight};

  .ant-layout-sider,
  .ant-layout-header,
  .ant-layout-footer {
    background-color: ${getCssVar('layout.header.bg')};
  }

  .ant-layout-header {
    @media (max-width: ${breakpoints.lg}) {
      background-color: ${({ theme }: Props) => theme.colors.transparent};
    }
  }
`;

export const Row = styled(AntdRow)<{ height?: string }>`
  height: ${(props) => props.height};
`;

export const Link = styled(RouterLink)<Props>`
  color: ${({ $isMobile }) =>
    $isMobile ? getCssVar('login.altLinkColorMobile') : getCssVar('login.altLinkColor')} !important;
  display: inline-block;
  font-size: ${({ theme }) => theme.spacing[4]};
  font-weight: ${getCssVar('link.fontWeight')};
  line-height: 20px;
  min-width: 132px;

  &:hover {
    color: ${(props) => props.color};
  }
`;

export const ViewMoreLink = styled(Link)<Props>`
  color: ${getCssVar('primaryColor')} !important;
  min-width: auto;
`;

export const Header = styled(AntdHeader)`
  padding: 0 ${({ theme }) => theme.spacing[6]};
  position: absolute;
`;

export const InputsCol = styled(AntdCol)`
  width: 348px;
  @media (max-width: ${breakpoints.md}) {
    width: 88%;
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 335px;
  }
  @media (max-width: ${breakpoints.lg}) {
    .ant-form-item-explain.ant-form-item-explain-error {
      div {
        color: ${getCssVar('errorOnDark')};
      }
    }

    > div {
      a {
        color: ${({ theme }: Props) => theme.colors.brown[400]};
      }
    }
  }
`;

const StyledIcon = styled(Icon)<{ $isMobile?: boolean }>`
  height: auto;
  width: auto;
`;

const DULL_TEXT_WIDTH_DESKTOP = 1140;
const DULL_TEXT_WIDTH_MOBILE = 320;

export const DullImage = styled(StyledIcon)`
  bottom: 0;
  height: ${({ $isMobile }) => ($isMobile ? '833px' : 'auto')};
  position: absolute;
  right: 0;

  width: ${({ $isMobile }) => ($isMobile ? DULL_TEXT_WIDTH_MOBILE : DULL_TEXT_WIDTH_DESKTOP)}px;
  z-index: 1;

  path {
    fill: ${({ $isMobile }) =>
      $isMobile ? getCssVar('dull.mobileImageColor') : getCssVar('dull.imageColor')};
  }
`;

export const Reassuringly = styled(StyledIcon)`
  bottom: ${({ $isMobile }) => ($isMobile ? '45px' : '402px')};
  height: 22px;
  left: ${({ $isMobile }) => $isMobile && '2px'};
  opacity: 0.5;
  position: absolute;
  right: ${({ $isMobile }) => !$isMobile && '1065px'};
  width: 125px;

  z-index: ${({ $isMobile }) => ($isMobile ? -1 : 1)};

  path {
    fill: ${({ theme }) => theme.colors.gray[500]};
  }
`;

export const NormRect = styled(StyledIcon)`
  bottom: 109px;
  height: 196px;
  position: absolute;

  right: 50px;

  width: 358px;
  z-index: 1;

  path {
    fill: ${({ theme }) => theme.colors.brown[100]};
  }
`;
