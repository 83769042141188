import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DPLCategoriesResponse, DPLCategory } from 'services/api';
import { dplApi } from './thunks';

interface InitialState {
  downloading: string;
  categories: DPLCategory[];
}

export const dpl = createSlice({
  name: 'dpl',
  initialState: {
    categories: [] as DPLCategory[],
    downloading: '',
  },
  reducers: {
    setDownloading: (state: InitialState, action: PayloadAction<string>) => {
      return {
        ...state,
        downloading: action.payload,
      };
    },
  },
  extraReducers: {
    [dplApi.downloadFile.fulfilled.toString()]: (state: InitialState) => {
      state.downloading = '';
    },
    [dplApi.getCategories.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<DPLCategoriesResponse>,
    ) => {
      state.categories = action.payload.categories || [];
    },
    [dplApi.getCategories.rejected.toString()]: (state: InitialState) => {
      state.categories = [];
    },
    [dplApi.downloadFile.rejected.toString()]: (state: InitialState) => {
      state.downloading = '';
    },
  },
});
