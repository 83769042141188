import React from 'react';
import moment, { Moment } from 'moment';
import { Form, RadioChangeEvent } from 'antd';
import { Box, RadioButton, RadioGroup } from 'shared';
import { dateFormat } from 'app-constants';
import { IDate } from 'services/interfaces';
import { RadioButtonsDays, RadioButtonsKeys, radioButtonsValues } from './constants';
import { RadioButtonsWithRangePickerContaner, StyledRangePicker } from './styled';
import { getDefaultDateRangeValue } from './helpers';

type RadioButtonsWithRangePickerProps = {
  handleDateChange: (date: IDate) => void;
  date: IDate;
  isMobile?: boolean;
  setPeriodName?: (period: string) => void;
  defaultPeriodName?: RadioButtonsKeys | 'day';
};

type DateRange = [Moment | null, Moment | null] | null;

export const RadioButtonsWithRangePicker = ({
  handleDateChange,
  date,
  isMobile,
  setPeriodName,
  defaultPeriodName,
}: RadioButtonsWithRangePickerProps) => {
  const defaultRadioButton =
    defaultPeriodName === 'day' ? RadioButtonsKeys.Today : defaultPeriodName;
  const [radioButtonsPosition, setRadioButtonsPosition] = React.useState<string>(
    defaultRadioButton || RadioButtonsKeys.OneMonth,
  );

  const handleRangePickerChange = React.useCallback(
    (dateRange: DateRange) => {
      setPeriodName?.('period');
      const today = moment();
      const isEndDateIsToday = today.isSame(dateRange?.[1]?.valueOf(), 'd');

      if (dateRange?.[0] && dateRange?.[1]) {
        handleDateChange({
          startDate: dateRange[0].startOf('day').valueOf(),
          endDate: isEndDateIsToday ? today.valueOf() : dateRange[1].endOf('day').valueOf(),
        });
        setRadioButtonsPosition('');
      } else {
        handleDateChange(getDefaultDateRangeValue());
        setRadioButtonsPosition(RadioButtonsKeys.OneMonth);
      }
    },
    [handleDateChange, setPeriodName],
  );

  const handleChange = React.useCallback(
    (key: string) => {
      let startDate = moment().startOf('day').valueOf();
      const endDate = moment().valueOf();

      setPeriodName?.(key);
      switch (key) {
        case RadioButtonsKeys.SevenDays:
          startDate = moment()
            .subtract(RadioButtonsDays.SevenDays - 1, 'days')
            .startOf('day')
            .valueOf();
          break;
        case RadioButtonsKeys.OneMonth:
          startDate = moment().subtract(RadioButtonsDays.OneMonth, 'days').startOf('day').valueOf();
          break;
        case RadioButtonsKeys.ThreeMonths:
          startDate = moment()
            .subtract(RadioButtonsDays.ThreeMonths, 'days')
            .startOf('day')
            .valueOf();
          break;
        case RadioButtonsKeys.Year:
          startDate = moment().subtract(RadioButtonsDays.Year, 'days').startOf('day').valueOf();
          break;
        default:
          setPeriodName?.('day');
          startDate = moment().startOf('day').valueOf();
          break;
      }

      return handleDateChange({
        startDate,
        endDate,
      });
    },
    [handleDateChange, setPeriodName],
  );

  const handleRadioButtonsPositionChange = (event: RadioChangeEvent) => {
    setRadioButtonsPosition(event.target.value);
    handleChange(event.target.value);
  };

  return (
    <RadioButtonsWithRangePickerContaner>
      <RadioGroup value={radioButtonsPosition} onChange={handleRadioButtonsPositionChange}>
        {radioButtonsValues.map((radioButton: { name: string; id: number }) => (
          <RadioButton key={radioButton.id} value={radioButton.name}>
            {radioButton.name}
          </RadioButton>
        ))}
      </RadioGroup>

      <Box>
        <Form>
          <Form.Item>
            <StyledRangePicker
              value={[moment(date.startDate), moment(date.endDate)]}
              onChange={handleRangePickerChange}
              ranges={{
                'This Month': [moment().startOf('month'), moment()],
              }}
              format={dateFormat}
              separator='—'
              disabledDate={(current: Moment) => current && current.isAfter(moment())}
              inputReadOnly={isMobile}
            />
          </Form.Item>
        </Form>
      </Box>
    </RadioButtonsWithRangePickerContaner>
  );
};
