import styled from 'styled-components';
import { TableWithSmallFieldsStyled as TableStyled } from 'pages/VulnerabilityManagement/components/styled/styled';
import { Theme } from 'styles/theme/types';

export const TableWithSmallFieldsStyled = styled(TableStyled)`
  .ant-table-tbody {
    > tr {
      > td {
        height: ${({ theme }: { theme: Theme }) => theme.sizes[10]};
      }
    }
  }
`;
