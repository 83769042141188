import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import moment from 'moment';
import { useRowGap } from 'services/hooks/useRowGap';
import { useLocation } from 'react-router-dom';
import { HistoryState } from 'services/interfaces';
import { downloadDocument } from 'services/helpers/downloadDocument';
import {
  AppDispatch,
  processApi,
  useApp,
  useCustomer,
  useDocuments,
  useLoading,
  useProcess,
} from 'store';
import { InnerLayout } from 'shared';
import { CertificateStatusSchemaWithDownloadName } from 'services/api';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { useGetPermission } from 'services/hooks';
import { useTheme } from 'styled-components';
import { getLayoutConfig } from './helpers';
import { topColResponsiveProps } from './constants';
import { DataForm } from './types';
import { useSuperModal } from './useSuperModal';
import { Certificate, ExpiredCertificatesTable, Modal } from './components';
import { CustomCol, StyledCard, StyledTable } from './styled';

export const Process = () => {
  const [preparedDataForm, setPreparedDataForm] = useState({} as DataForm);
  const [clickOn, setClickOn] = useState('');

  const theme = useTheme();
  const { isMobile } = useApp();
  const customer = useCustomer();
  const { gapNumber } = useRowGap();
  const { isModalShown, isFormShown, hideModal, showSuperModal, title } = useSuperModal();
  const { state: historyState, pathname } = useLocation<HistoryState>();
  const layoutConfig = getLayoutConfig(historyState, pathname);

  const dispatch: AppDispatch = useDispatch();
  const { certificates, expiredCertificates } = useProcess();
  const { downloading } = useDocuments();

  const loading = useLoading([processApi.getAllCertificates.typePrefix]);

  const hasPermissionToBuyCertificate =
    useGetPermission(PermissionOptions.Process) === PermissionsLevel.Full;

  const openFormWithPreparedData = useCallback(
    (title: string, date?: number, vendor?: string) => {
      showSuperModal(true, title);
      setPreparedDataForm({ title, date, vendor });
    },
    [showSuperModal],
  );

  useEffect(() => {
    dispatch(processApi.getAllCertificates({ customerId: customer }));
  }, [dispatch, customer]);

  const onDownloadCertificate = async (filename: string) => {
    await downloadDocument({ dispatch, customer, filename });
  };

  const expiredCertificatesWithoutDownloadName =
    certificates?.filter(
      (certificate) => !certificate.name && moment(certificate.expirationDate).isBefore(moment()),
    ) || [];

  const combineExpiredCertificates = [
    ...expiredCertificatesWithoutDownloadName,
    ...(expiredCertificates || []),
  ];

  return (
    <>
      {isModalShown && (
        <Modal
          isModalShown={isModalShown}
          isFormShown={isFormShown}
          hideModal={hideModal}
          preparedDataForm={preparedDataForm}
          setPreparedDataForm={setPreparedDataForm}
          title={title}
        />
      )}

      <InnerLayout {...layoutConfig}>
        <Row gutter={[gapNumber, gapNumber]} wrap={true}>
          {certificates?.map((certificate: CertificateStatusSchemaWithDownloadName) => (
            <CustomCol
              key={certificate.id || certificate.title}
              span={6}
              {...topColResponsiveProps}
            >
              <StyledCard
                height='100%'
                showLoader={loading[processApi.getAllCertificates.typePrefix]}
              >
                <Certificate
                  title={certificate.title}
                  lastUpdated={certificate.lastUpdated}
                  expirationDate={certificate.expirationDate}
                  onDownload={onDownloadCertificate}
                  onSelfSertify={showSuperModal}
                  status={certificate.status}
                  id={certificate.id}
                  certificateName={certificate.name}
                  openFormWithPreparedData={openFormWithPreparedData}
                  downloading={downloading}
                  downloadName={certificate.name}
                  vendorName={certificate.vendor}
                  hasPermissionToBuyCertificate={hasPermissionToBuyCertificate}
                  setClickOn={setClickOn}
                  clickOn={clickOn}
                />
              </StyledCard>
            </CustomCol>
          ))}
          <Col span={24}>
            <StyledTable
              title='Expired certificates'
              showDivider={false}
              headStyles={
                !isMobile
                  ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes['2.5'] }
                  : { titlePaddingRight: theme.sizes[0] }
              }
              bodyPadding={theme.sizes[0]}
              cardPadding={!isMobile ? theme.sizes['5.5'] : theme.sizes[5]}
            >
              <ExpiredCertificatesTable
                expiredCertificates={combineExpiredCertificates}
                onDownloadFile={onDownloadCertificate}
                downloading={clickOn === 'table' ? downloading : ''}
                setClickOn={setClickOn}
              />
            </StyledTable>
          </Col>
        </Row>
      </InnerLayout>
    </>
  );
};
