import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTheme } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { CultureResponse } from 'services/api';
import { MultiValues } from '../types';
import { EChartsEventParams, IEChartsOption } from '../interfaces';
import { DELAY } from '../constants';

type ValuesType = MultiValues | CultureResponse;

type Props<T extends ValuesType> = {
  values: T;
  style?: React.CSSProperties;
  radius: number;
  getOption: (values: T, theme: Theme, radius: number, isMobile?: boolean) => IEChartsOption;
  onEvents?: Record<string, (params?: EChartsEventParams) => void>;
  isMobile?: boolean;
  isLoading?: boolean;
};
let timerId: NodeJS.Timeout;

export const Radar = <T extends ValuesType>({
  values,
  style,
  radius,
  getOption,
  onEvents,
  isMobile,
  isLoading = false,
}: Props<T>) => {
  const theme = useTheme();
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (isLoading) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => setChartOptions(getOption(values, theme, radius, isMobile)), DELAY);

    return () => {
      clearTimeout(timerId);
    };
  }, [getOption, isLoading, isMobile, onEvents, radius, theme, values]);

  return <ReactECharts option={chartOptions} style={style} onEvents={onEvents} notMerge />;
};
