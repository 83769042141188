/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerId } from '../models/CustomerId';
import type { MonthlyReportResponse } from '../models/MonthlyReportResponse';
import type { Timestamp } from '../models/Timestamp';
import { request as __request } from '../core/request';

export class ReportsService {
  /**
   * Get monthly report
   * Get monthly report
   * @returns MonthlyReportResponse Get Monthly Report
   * @throws ApiError
   */
  public static async getMonthlyReport({
    customerId,
    startDate,
    endDate,
    timezone,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate: Timestamp;
    /** The end date parameter **/
    endDate: Timestamp;
    /** client timezone parameter **/
    timezone: string;
  }): Promise<MonthlyReportResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/monthly-report`,
      query: {
        startDate: startDate,
        endDate: endDate,
        timezone: timezone,
      },
      errors: {
        400: `An error message when update`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Export (download) report
   * Export (download) report
   * @returns any Export (download) report
   * @throws ApiError
   */
  public static async exportMonthlyReport({
    customerId,
    generatorOptions,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Pdfgenerator options **/
    generatorOptions?: any;
    /** HTML Template for convert to PDF file **/
    requestBody?: any;
  }): Promise<any> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/export-monthly-report`,
      query: {
        generatorOptions: generatorOptions,
      },
      body: requestBody,
      errors: {
        400: `An error message when export report`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Export Cyber Resilience Score Trends
   * Get Cyber Resilience Score Trends for each customer
   * @returns any XLSX Report
   * @throws ApiError
   */
  public static async exportCyberResilienceScoreTrends({
    customerId,
    startDate,
    endDate,
    timezone,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate: Timestamp;
    /** The end date parameter **/
    endDate: Timestamp;
    /** client timezone parameter **/
    timezone: string;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/export-cyber-resilience-score-trends`,
      query: {
        startDate: startDate,
        endDate: endDate,
        timezone: timezone,
      },
      errors: {
        400: `An error message when update`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
