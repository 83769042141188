import { Timestamp } from 'services/api';

export type PenetrationTestsDataRow = {
  id?: string;
  test?: string;
  start?: Timestamp;
  end?: Timestamp;
  status?: string;
  report?: string;
};

export type PenetrationTestsData = {
  rows?: PenetrationTestsDataRow[];
  count?: number;
};

export const penetrationTestsData: PenetrationTestsData = {
  rows: [
    {
      test: 'Web application — Customer portal',
      start: 1642682010000,
      end: 1642683780000,
      status: 'To start',
      id: '1',
    },
    {
      test: 'Internal — UK',
      start: 1642682010000,
      end: 1642683780000,
      status: 'In progress',
      id: '2',
    },
    {
      test: 'External',
      start: 1642682010000,
      end: 1642683780000,
      status: 'Completed',
      report: 'External',
      id: '3',
    },
    {
      test: 'Internal — Norway',
      start: 1642682010000,
      end: 1642683780000,
      status: 'Completed',
      report: 'Internal — Norway',
      id: '4',
    },
    {
      test: 'Web application — Online store',
      start: 1642682010000,
      end: 1642683780000,
      status: 'Completed',
      report: 'Web application — Online store',
      id: '5',
    },
  ],
  count: 5,
};
