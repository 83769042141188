import { Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { PatchVendorSeverity } from 'services/api';
import { Icon } from 'shared/Icon';
import { StyledLabel } from 'shared/TableFilters/components/TableFiltersSelect/styled';
import { useVendorSeverity } from 'store';
import { vendorSeverity } from 'store/patchManagement/vendorSeverity';
import { icons } from 'styles/theme';
import { vendorSeverityFilterOption } from './constants';
import { TableFiltersContainer, TableFiltersSelect } from './styled';

export type Props = {
  onFilterChange: (value: string[]) => void;
};

export const PmSeverityFilter = () => {
  const dispatch = useDispatch();
  const severities = useVendorSeverity();

  return (
    <TableFiltersContainer>
      <Row gutter={12} justify='end' style={{ flexDirection: 'row', alignItems: 'center' }}>
        <StyledLabel htmlFor='id-Filter by Severity'>Filter by Severity</StyledLabel>
        <div style={{ width: 476 }}>
          <TableFiltersSelect
            key={vendorSeverityFilterOption.field}
            showSearch={false}
            placeholder='None'
            className={severities && 'checked'}
            value={severities || []}
            maxTagCount={vendorSeverityFilterOption.options.length}
            onSelectChange={(_: string, value?: string | string[]) => {
              dispatch(vendorSeverity.actions.setSeverities(value as PatchVendorSeverity[]));
            }}
            suffixIcon={<Icon className='ant-select-suffix' component={icons.arrow_drop_down} />}
            {...vendorSeverityFilterOption}
          />
        </div>
      </Row>
    </TableFiltersContainer>
  );
};
