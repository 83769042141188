import { useCallback, useState } from 'react';
import { useModal as useModalBase } from 'services/hooks/useModal';

export const useModalWithForm = () => {
  const [isFormShown, setIsFormShown] = useState(false);

  const hideForm = useCallback(() => {
    setIsFormShown(false);
  }, []);

  const shownForm = useCallback(() => {
    setIsFormShown(true);
  }, []);

  return { isFormShown, shownForm, hideForm };
};

export const useSuperModal = () => {
  const { isModalShown, showModal, hideModal, title, setTitle } = useModalBase();
  const { isFormShown, shownForm, hideForm } = useModalWithForm();

  const showSuperModal = useCallback(
    (withForm?: boolean, title?: string) => {
      setTitle(title);

      if (withForm) {
        showModal();
        shownForm();

        return;
      }

      showModal();
      hideForm();
    },
    [showModal, setTitle, hideForm, shownForm],
  );

  return { isModalShown, isFormShown, hideModal, showSuperModal, title };
};
