import { Box, Icon, Table as AntdTable } from 'shared';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { ThemeProps } from 'services/interfaces';

export const Table = styled(AntdTable)`
  .ant-table-thead {
    font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
    color: ${({ theme }: { theme: Theme }) => theme.colors.blue[900]};

    .ant-table-cell,
    .ant-table-cell:first-child,
    .ant-table-cell:last-child {
      padding: ${({ theme }: { theme: Theme }) => `${theme.sizes[2]} ${theme.sizes[3]}`};
    }
  }

  .ant-table-tbody {
    > tr > td {
      border-bottom: ${({ theme }: { theme: Theme }) =>
        `${theme.sizes.px} solid ${theme.colors.blue[50]}`};
      cursor: pointer;
    }

    .ant-table-row {
      .ant-table-cell,
      .ant-table-cell:first-child,
      .ant-table-cell:last-child {
        padding: ${({ theme }: { theme: Theme }) => `${theme.sizes[2]} ${theme.sizes[3]}`};
      }
    }
  }

  .ant-tag {
    margin-right: ${({ theme }: { theme: Theme }) => theme.sizes[0]};
  }

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
    .ant-table-content {
      margin-right: ${({ theme }: { theme: Theme }) => theme.sizes[6]};
    }
  }
`;

export type ChartContainerProps = {
  theme: Theme;
  isScrollable?: boolean;
};

export const ChartContainer = styled.div<ChartContainerProps>`
  @media (min-width: ${({ theme }: ChartContainerProps) => theme.breakpoints.md}) {
    overflow-y: ${({ isScrollable }: ChartContainerProps) => (isScrollable ? 'scroll' : 'hidden')};

    .echarts-for-react {
      max-height: 440px;
    }
  }
`;

export const IconDanger = styled(Icon)<ThemeProps>`
  margin-bottom: 0;
`;

export const Total = styled(Box)<ThemeProps>`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 2px;
`;

export const Title = styled(Box)`
  font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
`;

export const Count = styled(Box)`
  font-size: ${({ theme }: ThemeProps) => theme.sizes[6]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.black};
`;
