import React from 'react';
import { useDispatch } from 'react-redux';
import { SelectValue } from 'antd/lib/select';
import { Icon } from 'shared';
import { icons } from '../../../styles/theme';
import { optionsOS } from '../constants';
import { platform } from '../../../store/patchManagement/platform';
import { PatchManagementPlatform } from '../../../services/api';
import { usePlatform } from '../../../store';
import { SelectPlatform } from '../styled';

export const SelectOS = () => {
  const dispatch = useDispatch();
  const currentPlatform = usePlatform();

  return (
    <SelectPlatform
      placeholder='Please select'
      defaultValue={currentPlatform}
      suffixIcon={<Icon className='ant-select-suffix' component={icons.arrow_drop_down} />}
      options={optionsOS}
      onChange={(value: SelectValue) => {
        dispatch(platform.actions.setPlatform(value as PatchManagementPlatform));
      }}
      dropdownAlign={{
        overflow: { adjustY: true },
      }}
    />
  );
};
