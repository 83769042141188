import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Col } from 'antd';
import { useTheme } from 'styled-components';
import { ContentLayout, Row } from './styled';

type Props = {
  align?: 'top' | 'middle' | 'bottom' | 'stretch';
};

export const Content = ({ align, children }: PropsWithChildren<Props>) => {
  const FORM_COLS_FULL = 24;
  const theme = useTheme();

  return (
    <ContentLayout>
      <Row justify='center' align={align || 'middle'} height={theme.sizes.full}>
        <Col span={FORM_COLS_FULL}>{children}</Col>
      </Row>
    </ContentLayout>
  );
};
