import React from 'react';
import { newsNotificationsApi, useApp, useLoading, useNewsNotifications } from 'store';
import { useTheme } from 'styled-components';
import { NotificationMessage, NotificationProp, StyledCard, UserTitle } from '../styled';
import { getCardPadding, getHeadStyles } from '../helpers';
import { Spacer } from '../../../shared/TableFilters/styled';
import { IconType } from '../../Notifications/components/IconType';

export const UserNotificationDetails = () => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const { notificationDetails } = useNewsNotifications();
  const { title, type = '', message = '' } = notificationDetails;
  const loading = useLoading([newsNotificationsApi.getNotificationDetails.typePrefix]);

  return (
    <StyledCard
      title={
        <UserTitle>
          <IconType type={type} />
          <Spacer w='10' />
          {type}
        </UserTitle>
      }
      headStyles={getHeadStyles(isMobile, theme)}
      bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
      cardPadding={getCardPadding(isMobile, theme)}
      showLoader={loading[newsNotificationsApi.getNotificationDetails.typePrefix]}
    >
      <Spacer h='30px' />
      <NotificationProp>{title}</NotificationProp>
      <Spacer h='20px' />
      <NotificationMessage dangerouslySetInnerHTML={{ __html: message }} />
    </StyledCard>
  );
};
