import styled from 'styled-components';
import { Select, Space, Tag } from 'antd';
import { Box, Table as TableStyled } from 'shared';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

export const Table = styled(TableStyled)`
  .ant-table-tbody {
    tr {
      cursor: pointer;
    }
  }
`;

export const TagsAsset = styled(Tag)`
  background: ${getCssVar('tagAssets.bg')};
  border-color: ${getCssVar('tagAssets.borderColor')};
  border-radius: 2px;
  border-width: 1px;
  color: ${getCssVar('primaryColor')};
  font-size: ${({ theme }) => `${theme.sizes['3.6']}`};
  margin: 5px;
  min-height: 23px;

  svg {
    padding-bottom: 5px;
  }

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.lg}) {
    padding: ${({ theme }: { theme: Theme }) => `${theme.spacing['2.5']}`};
    font-size: ${({ theme }: { theme: Theme }) => `${theme.sizes['4.2']}`};
    max-height: 40px;

    svg {
      padding-bottom: 5px;
    }
  }
`;

export const AddTagButton = styled(TagsAsset)`
  background: inherit;
  border: 1px dotted ${getCssVar('primaryColor')};
  color: ${getCssVar('primaryColor')};
  cursor: pointer;
  height: 23px;
  margin-left: 5px;
  max-width: 85px;
  padding-top: 1px;

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.lg}) {
    width: 100%;
    max-width: 160px;
    height: 50px;
    padding-top: 10px;
    text-align: center;
  }
`;

export const CustomSpacer = styled(Space)`
  padding: 0 8px 4px;

  .ant-space-item {
    height: 40px;
  }
`;

export const TagSelector = styled(Select)`
  margin-left: 5px;
  min-width: 85px;
  top: 5px;

  .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
    display: block;
    height: 100%;
    line-height: 3;
    min-height: 15px;
  }

  .ant-select-selector {
    max-height: 23px;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 1;
  }

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.lg}) {
    min-width: 170px;
    top: 0;

    .ant-select-selector {
      max-height: 50px;
    }
  }
`;

export const CreateTagButton = styled(Box)`
  color: ${getCssVar('primaryColor')};
  cursor: pointer;
  font-size: ${({ theme }: { theme: Theme }) => `${theme.sizes['4']}`};
  font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
  margin-left: 5px;
  margin-top: 10px;
`;

export const BulkActionSelector = styled(Select)`
  display: flex;
  flex-direction: column;
  max-width: 255px;

  .ant-select-selector {
    display: block;
    font-size: ${({ theme }: { theme: Theme }) => `${theme.sizes['3.6']}`};
    height: 100%;
    line-height: 3;
  }

  .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
    display: block;
  }
`;

export const BulkActionLabel = styled(Box)`
  background-color: ${getCssVar('select.bg')};
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: { theme: Theme }) => `${theme.sizes['4']}`};
  font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
  padding: 8px 12px 0;
`;

export const TagColumnWrapper = styled(Box)`
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
`;

export const TagActionsWrapper = styled(Box)`
  background-color: getCssVar('select.bg');

  .ant-dropdown-menu {
    background-color: getCssVar('select.bg');
  }
`;
