import React from 'react';
import { Box, Button, Table } from 'shared';
import { Popup } from 'shared/ActionPopup/styled';
import {
  ActiveServicesItemSchema,
  ActiveServiceStatusEnum,
  ActiveServiceDataTypeStateEnum,
} from 'services/api';
import { columns } from './table-columns';

type DataTypesPopupProps = {
  record: ActiveServicesItemSchema | null;
  isVisible: boolean;
  onClose: () => void;
};

export const DataTypesPopup = ({ record, isVisible, onClose }: DataTypesPopupProps) => {
  const dataTypes: ActiveServicesItemSchema[] = record?.dataTypes
    ? record.dataTypes.map(({ name, state }) => ({
        name,
        status:
          state === ActiveServiceDataTypeStateEnum.DISABLED
            ? ActiveServiceStatusEnum.RISK
            : ActiveServiceStatusEnum.HEALTHY,
        collectedDateTime: record.collectedDateTime,
      }))
    : [];

  return (
    <Popup
      className='actions-modal'
      title={
        <Box display='flex' alignItems='center'>
          {record?.name}
        </Box>
      }
      visible={isVisible}
      onCancel={onClose}
      width='600px'
      footer={[
        <Button key='back' onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Table columns={columns} dataSource={dataTypes} tableLayout='auto' pagination={false} />
    </Popup>
  );
};
