import { InnerLayoutProps, BackLink } from 'shared';
import { Routes } from 'services/entities';
import { backlinkRoutes } from 'app-constants';
import { HeaderActionButtons, StatusTag } from './components';

type FnLayoutConfigProps = {
  serviceName?: string;
  isConnected?: boolean;
  isLoading?: boolean;
  connectedAt?: string;
};

export const getLayoutConfig = ({
  serviceName,
  isConnected,
  isLoading = false,
  connectedAt = '',
}: FnLayoutConfigProps): InnerLayoutProps => {
  return {
    title: serviceName ? `${serviceName} integration` : '',
    backLink: (
      <BackLink
        defaultRoute={Routes.SERVICES_INTEGRATIONS}
        defaultTitle={backlinkRoutes[Routes.SERVICES_INTEGRATIONS]}
      />
    ),
    status: <StatusTag style={{ marginLeft: '8px' }} isConnected={isConnected} />,
    customComponent: (
      <HeaderActionButtons
        isLoading={isLoading}
        connectedAt={connectedAt}
        serviceName={serviceName}
      />
    ),
  };
};
