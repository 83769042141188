import React from 'react';

export const TitleTooltipContent = () => {
  return (
    <p>
      The Documents folder is where you can find any sensitive documents that we need to share,
      contract information, penetration test reports, and where included in your subscription, your
      monthly service report.
    </p>
  );
};
