import { OrderBy } from 'services/api';

export enum ColumnKey {
  Name = 'name',
  DocumentType = 'documenttype',
  CreatedOn = 'createddate',
  Actions = 'actions',
}

export const initialOrderBy = {
  field: 'createddate',
  type: OrderBy.type.ASC,
};
