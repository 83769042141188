import { Filter } from 'services/api';

export const getFiltersFields = (filterFields: Filter[], filterField: Filter) => {
  const newFilters = [...filterFields];
  const filterIndex = filterFields.findIndex((filter) => filter.name === filterField.name);
  const filter = { ...filterFields.find((filter) => filter.name === filterField.name) };

  if (filterIndex !== -1) {
    filter.value = filterField.value;
    newFilters.splice(filterIndex, 1, filter);
  } else {
    newFilters.push(filterField);
  }

  return newFilters;
};
