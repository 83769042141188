import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  useApp,
  useAssetDetails,
  useAssetRecentPatchesBySeverity,
  useLoading,
  useVendorSeverity,
} from 'store';
import { Box, Card, Icon } from 'shared';
import { PatchStatus, RecentAssetPatches, TopVulnerabilitySchema } from 'services/api';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { TableProps } from 'antd';
import { LinkContainer } from 'shared/Link/LinkContainer';
import { Routes } from 'services/entities';
import { getQueryString } from 'services/api/core/request';
import { useLocation, useParams } from 'react-router';
import { HistoryState } from 'services/interfaces';
import { useTheme } from 'styled-components';
import { toLower } from 'lodash';
import { useAgedHostsOrCmdbRoute } from 'services/hooks';
import { TableWithSmallFieldsStyled } from '../styled';
import { getTableColumns } from './TableColumns';
import { NO_RECENT_PATCHES } from '../constants';
import { getRedirectParams } from './helpers';
import { CmdbTabKeys } from '../../../constants';

export const RecentPatches = ({ patchStatus }: { patchStatus: PatchStatus }) => {
  const history = useHistory();
  const theme = useTheme();
  const { isMobile } = useApp();
  const { missingRecentPatches, installedRecentPatches } = useAssetRecentPatchesBySeverity();
  const { detailsAdditionalInformation } = useAssetDetails();
  const { state } = useLocation<HistoryState>();
  const { id: hostId } = useParams<{ id: string }>();
  const data = patchStatus === PatchStatus.MISSING ? missingRecentPatches : installedRecentPatches;
  const severities = useVendorSeverity();
  const loading = useLoading([assetDetailsApi.getAssetRecentPatches.typePrefix]);
  const { pageRoute: backLinkRoute } = useAgedHostsOrCmdbRoute();

  const tableRedirectToVulnerabilityDetails = React.useCallback(
    (record: TopVulnerabilitySchema) => ({
      onClick: () => {
        history.push(`${Routes.PATCH_MANAGEMENT_PATCHES}/${record.id}`, {
          pathname: Routes.PATCH_MANAGEMENT_PATCHES,
          search: getQueryString(getRedirectParams(patchStatus, hostId as string)),
          backTo: {
            route: `${backLinkRoute}/${hostId}`,
            search: history.location.search,
            title: `Back to `,
            id: detailsAdditionalInformation.hostname,
          },
          rootBackTo: state?.rootBackTo,
          activeTabKey: CmdbTabKeys.PATCH_MANAGEMENT,
        });
      },
    }),
    [detailsAdditionalInformation.hostname, history, patchStatus, hostId, state, backLinkRoute],
  );

  return (
    <Card
      title={`Recent ${toLower(patchStatus)} patches`}
      showDivider={false}
      headStyles={{
        headAlign: 'flex-start',
        titlePaddingRight: isMobile ? theme.sizes[0] : theme.sizes[5],
      }}
      cardPadding={!isMobile ? `${theme.sizes[6]} ${theme.sizes[3]}` : theme.sizes[5]}
      bodyPadding={
        !isMobile ? `${theme.sizes[2]} ${theme.sizes['2.5']} ${theme.sizes[0]}` : theme.sizes[0]
      }
      height={theme.sizes.full}
      showLoader={loading[assetDetailsApi.getAssetRecentPatches.typePrefix]}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        h={theme.sizes.full}
      >
        <TableWithSmallFieldsStyled<React.FC<TableProps<RecentAssetPatches>>>
          columns={getTableColumns(patchStatus, isMobile)}
          dataSource={severities.length ? data?.rows : []}
          tableLayout='fixed'
          pagination={false}
          rowKey={(record: RecentAssetPatches) => record.id as React.Key}
          onRow={tableRedirectToVulnerabilityDetails}
          locale={{ emptyText: NO_RECENT_PATCHES }}
          isClickable
        />

        <LinkContainer>
          <Link
            to={{
              pathname: Routes.PATCH_MANAGEMENT_PATCHES,
              search: getQueryString(getRedirectParams(patchStatus, hostId as string)),
              state: {
                backTo: {
                  route: `${backLinkRoute}/${hostId}`,
                  search: history.location.search,
                  title: `Back to `,
                  id: detailsAdditionalInformation.hostname,
                },
                rootBackTo: state?.rootBackTo,
                activeTabKey: CmdbTabKeys.PATCH_MANAGEMENT,
              },
            }}
          >
            See all {patchStatus} patches
            <Icon component={theme.icons.arrow_right_alt} />
          </Link>
        </LinkContainer>
      </Box>
    </Card>
  );
};
