import { Themes } from 'themes/types';

const THEME_MODE_KEY = 'themeMode';

function validateThemeMode(value: null | Themes) {
  return !!(value && [Themes.DarkNorm, Themes.Norm].includes(value));
}

export function getThemeMode() {
  const themeMode = localStorage.getItem(THEME_MODE_KEY) as null | Themes;

  if (validateThemeMode(themeMode)) {
    return themeMode;
  }

  localStorage.removeItem(THEME_MODE_KEY);

  return null;
}

export function saveThemeMode(value: Themes) {
  if (validateThemeMode(value)) {
    localStorage.setItem(THEME_MODE_KEY, value);

    return;
  }

  localStorage.removeItem(THEME_MODE_KEY);
}
