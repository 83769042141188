import { Input, CustomInputProps } from 'shared';
import { useField } from 'formik';

type TextFieldProps = CustomInputProps & {
  name: string;
};

export const TextField = ({ name, label, type, ...props }: TextFieldProps): JSX.Element => {
  const [field, meta] = useField(name);

  return (
    <Input
      type={type || 'text'}
      id={name}
      label={label}
      {...field}
      {...props}
      validateStatus={meta.error && meta.touched ? 'error' : 'success'}
      help={meta.error && meta.touched ? meta.error : undefined}
    />
  );
};
