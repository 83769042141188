import styled from 'styled-components';
import { Box } from 'shared';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../../../styles/theme';

export const CultureContainer = styled(TotalCustomTooltipContainer)`
  align-items: center;
  display: flex;
  flex-direction: column;

  .purple {
    background: ${({ theme }: { theme: Theme }) => theme.colors.purple[400]};
  }

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
    .echarts-for-react {
      width: 100%;
    }

    > div:last-child {
      align-items: center;
    }
  }
`;

export const PercentageContainer = styled(Box)`
  min-width: 600px;
  position: relative;
`;

export const PercentageItemsContainer = styled(Box)`
  color: ${getCssVar('charts.axisLabel')};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes[3]};
  left: calc(50% + 5px);
  position: absolute;
  top: ${({ theme }: { theme: Theme }) => theme.sizes[5]};
`;

export const PercentageItem = styled.span`
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.sizes[3]};

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
    margin-bottom: ${({ theme }: { theme: Theme }) => theme.sizes[1]};
  }
`;
