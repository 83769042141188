import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { RequestStatus } from 'store/loading/slice';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useRowGap } from 'services/hooks/useRowGap';
import { Routes } from 'services/entities';
import { HistoryState, ParamsPage } from 'services/interfaces';
import { Filter, OrderBy, SupportRequestEmail, SupportRequestsType } from 'services/api';
import { InnerLayout } from 'shared';
import {
  alarmsApi,
  AppDispatch,
  supportRequestsApi,
  useAlarms,
  useApp,
  useCustomer,
  useLoading,
  useSupportRequests,
} from 'store';
import { useTheme } from 'styled-components';
import { defaultCurrent } from 'app-constants';
import { List } from 'shared/List/List';
import { getCardPadding, getHeadStyles, getLayoutConfig } from './helpers';
import { Description, Summary } from './components';
import { EmailsMessages, StyledCard } from './styled';
import { desktopTableRowMaxLength, mobileTableRowMaxLength } from '../ThreatDetection/constants';
import { AlarmListItem } from './components/AlarmListItem';

export const SupportRequestDetails = () => {
  const theme = useTheme();
  const { state: historyState, pathname } = useLocation<HistoryState>();
  const history = useHistory();
  const customerId = useCustomer();
  const page: ParamsPage = useParams();
  const { gapNumber } = useRowGap();
  const { isMobile } = useApp();

  const dispatch: AppDispatch = useDispatch();

  const { requestDetails } = useSupportRequests();
  const { data: alarmsData } = useAlarms();

  const loading = useLoading([supportRequestsApi.supportRequestDetails.typePrefix]);

  const { id, type, status, emails } = requestDetails || {};
  const isServiceRequest = type === SupportRequestsType.SERVICE_REQUEST;
  const title = isServiceRequest ? `Service request #${id}` : `Security incident #${id}`;

  const layoutConfig = getLayoutConfig(
    historyState,
    title,
    status,
    loading[supportRequestsApi.supportRequestDetails.typePrefix],
    pathname,
  );

  useEffect(() => {
    window.scroll(0, 0);

    dispatch(supportRequestsApi.supportRequestDetails({ customerId, recId: page.id })).then(
      (result) => {
        if (result.meta.requestStatus === RequestStatus.Rejected) {
          history.push(Routes.NOT_FOUND);
        }
      },
    );

    const count = !isMobile ? desktopTableRowMaxLength : mobileTableRowMaxLength;

    dispatch(
      alarmsApi.alarmsTableContent({
        customerId,
        orderBy: { field: 'updated', type: OrderBy.type.DESC },
        pagination: { count, page: defaultCurrent },
        filter: { fields: [{ value: id, name: 'ivantiId', type: Filter.type.VALUE }] },
      }),
    );
  }, [history, dispatch, customerId, page.id, isMobile, id]);

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} lg={15} xs={24} order={isMobile ? 2 : 1}>
          <StyledCard
            title='Activity'
            headStyles={getHeadStyles(isMobile, theme)}
            bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
            cardPadding={getCardPadding(isMobile, theme)}
            showLoader={loading[supportRequestsApi.supportRequestDetails.typePrefix]}
          >
            <Description
              summary={requestDetails?.summary || ''}
              email={requestDetails?.email || ''}
              createdTime={requestDetails?.created || ''}
              description={requestDetails?.description || ''}
            />
            {emails &&
              emails.map((email: SupportRequestEmail) => (
                <EmailsMessages key={email.recId}>
                  <Description
                    summary={email.subject || ''}
                    email={email.fromAddr || ''}
                    createdTime={email.sentOn || ''}
                    description={email.emailBody || ''}
                  />
                </EmailsMessages>
              ))}
          </StyledCard>
        </Col>
        <Col span={gapNumber} lg={9} xs={24} order={isMobile ? 1 : 2}>
          <Row gutter={[0, gapNumber]}>
            <Col span={24}>
              <StyledCard
                title={isMobile ? 'Details' : ''}
                isHiddable={isMobile}
                headStyles={getHeadStyles(isMobile, theme)}
                bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
                cardPadding={getCardPadding(isMobile, theme)}
                showLoader={loading[supportRequestsApi.supportRequestDetails.typePrefix]}
              >
                <Summary
                  requestDetails={requestDetails || {}}
                  isServiceRequest={isServiceRequest}
                />
              </StyledCard>
            </Col>
            {(alarmsData.count || 0) > 0 && (
              <Col span={24}>
                <StyledCard
                  title={isMobile ? 'Related alarms' : ''}
                  isHiddable={isMobile}
                  headStyles={getHeadStyles(isMobile, theme)}
                  bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
                  cardPadding={getCardPadding(isMobile, theme)}
                  showLoader={loading[supportRequestsApi.supportRequestDetails.typePrefix]}
                >
                  <List
                    title={isMobile ? '' : 'Related alarms'}
                    items={(alarmsData.rows || []).map((record) => ({
                      id: record.id as string,
                      component: <AlarmListItem item={record} backRouteTitle={title} />,
                    }))}
                  />
                </StyledCard>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </InnerLayout>
  );
};
