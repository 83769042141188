import { createGlobalStyle } from 'styled-components';
import { PATH_TO_FONTS } from 'app-constants';

export const mainFontFamily = 'Helvetica Now Display';
export const encryptedPasswordFireFoxFontFamily = 'Verdana';

export const FontsNorm = createGlobalStyle`

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ExtBdIta.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ExtBdIta.woff}) format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }


  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Medium.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-BlackIta.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-BlackIta.woff) format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ExtraBold.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ExtraBold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-LightIta.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-LightIta.woff) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-MedIta.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-MedIta.woff) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Light.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ThinIta.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ThinIta.woff) format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-BoldIta.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-BoldIta.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: local(${mainFontFamily}), url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Black.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Black.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ExtLtIta.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ExtLtIta.woff) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ExtLt.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-ExtLt.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Thin.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Thin.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Bold.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Bold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Regular.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: ${mainFontFamily};
    src: url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-RegIta.woff2) format('woff2'),
    url(${PATH_TO_FONTS}/norm/HelveticaNowDisplay-RegIta.woff) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
`;
