import React from 'react';
import moment from 'moment';
import { getCapitalizedString } from 'services/helpers';
import { SecuritySettingsResponse } from 'services/api';
import { Button, Icon, InnerLayoutProps as InnerLayout } from 'shared';
import { icons } from 'styles/theme';
import { emptyDataString } from 'app-constants';
import { ExpiryValues } from './constants';
import { Fields } from './types';

export const getLayoutConfig = (
  showEditMode: () => void,
  isEditModeBtnShown: boolean,
): InnerLayout => ({
  title: 'Security settings',
  customComponent: isEditModeBtnShown && (
    <Button type='link' icon={<Icon component={icons.edit} />} onClick={showEditMode}>
      Edit
    </Button>
  ),
});

export const convertMilliseconds = (milliseconds: string | undefined) => {
  if (!milliseconds) return emptyDataString;
  const duration = moment.duration(milliseconds, 'milliseconds');
  const days = duration.asDays();
  const months = Math.ceil(duration.asMonths());
  const sixMonths = 180;

  if (milliseconds === ExpiryValues.Never) {
    return getCapitalizedString(ExpiryValues.Never);
  }

  if (days >= sixMonths) {
    return `${months} months`;
  }

  return `${days} days`;
};

export const getSettingsValue = (field: Fields, settings: SecuritySettingsResponse) => {
  const { passwordMinLength, passwordComplexity, passwordReUsePrevention, passwordLock } = settings;

  if (field === 'passwordMinLength') {
    return passwordMinLength ? `${passwordMinLength} characters` : emptyDataString;
  }

  if (field === 'passwordComplexity') {
    return passwordComplexity ? `${passwordComplexity} character types` : emptyDataString;
  }

  if (field === 'passwordReUsePrevention') {
    return passwordReUsePrevention ? `Last ${passwordReUsePrevention} passwords` : emptyDataString;
  }

  if (field === 'passwordLock') {
    return passwordLock ? `After ${passwordLock} attempts` : emptyDataString;
  }

  return convertMilliseconds(settings[field]);
};
