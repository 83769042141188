import { useEffect } from 'react';
import { throttle } from 'services/helpers';
import { useFeatureFlag } from 'services/feature-flag/context';

const THROTTLE_DELAY = 5000;
const INTERVAL_UPDATE = 60000;

const getAssetManifest = async () => {
  try {
    const response = await fetch(`${window.location.origin}/asset-manifest.json`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return await response.json();
    }

    return null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while validating current version', e);

    return null;
  }
};

const validateCurrentVersion = async () => {
  try {
    const manifest = await getAssetManifest();

    if (!manifest || !manifest.files) {
      return;
    }

    const mainFile = manifest.files['main.js'];

    const hasRecentMainFile = [...document.scripts].some((scriptElement) => {
      return scriptElement.src.includes(mainFile);
    });

    // eslint-disable-next-line no-console
    console.log('Check new version...', !hasRecentMainFile);

    if (!hasRecentMainFile) {
      window.location.reload();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while validating current version', e);
  }
};

const throttledValidateCurrentVersion = throttle(validateCurrentVersion, THROTTLE_DELAY);

export const useCheckUpdate = () => {
  const checkUpdateTimer = useFeatureFlag('APP_FEATURE_FLAG_CHECK_UPDATE_TIMER');

  useEffect(() => {
    let timerId;

    window.addEventListener('focus', throttledValidateCurrentVersion);

    if (checkUpdateTimer) {
      const intervalUpdate =
        typeof checkUpdateTimer !== 'boolean' ? parseInt(checkUpdateTimer, 10) : INTERVAL_UPDATE;

      timerId = setInterval(validateCurrentVersion, intervalUpdate);
    }

    return () => {
      window.removeEventListener('focus', throttledValidateCurrentVersion);
      clearInterval(timerId);
    };
  }, [checkUpdateTimer]);
};
