import React, { useMemo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NotificationStatus, NotificationType } from 'services/api';
import { noUnreadNotificationsMessage } from 'pages/Notifications/constant';
import { IconType } from 'pages/Notifications/components/IconType';
import {
  getShortenedText,
  getStringWithoutTags,
  getTimeAgoLabel,
  MAX_CHARS_IN_SHORTED_TEXT,
  showError,
} from 'services/helpers';
import { newsNotificationsApi } from 'store/newsNotifications/thunks';
import { AppDispatch, newsNotifications, useCustomer, useNewsNotifications } from 'store';
import { RequestStatus } from 'store/loading/slice';
import { EmptyTablePreview } from 'shared/Table/EmptyTablePreview/EmptyTablePreview';
import { usePreviousValue } from 'services/hooks';
import {
  NotificationDescription,
  NotificationTime,
  NotificationTitle,
  NotificationTitleWrapper,
  NotificationWrapper,
  ReadAllButton,
  ReadButton,
  ReadButtonWrapper,
  ViewAllButton,
} from './styled';
import { Routes } from '../../../../services/entities';
import { backlinkRoutes } from '../../../../app-constants';
import { Icon } from '../../../Icon';
import { icons } from '../../../../styles/theme';
import { HistoryState } from '../../../../services/interfaces';
import { Box } from '../../../Box';

type Props = {
  closeHandler?: () => void;
  isOpen: boolean;
};

export const NotificationList = ({ closeHandler, isOpen = false }: Props) => {
  const LIMIT = 5;
  const dispatch: AppDispatch = useDispatch();
  const customerId = useCustomer();
  const history = useHistory();
  const { state: historyState } = useLocation<HistoryState>();
  const { notificationUnreadGrid } = useNewsNotifications();
  const route = history.location.pathname.split('/')[1];
  const prevRoute = usePreviousValue<string>(route, '');

  const notifications = useMemo(() => {
    return (
      notificationUnreadGrid?.rows?.filter(
        (notification) =>
          !notification.read && notification.status === NotificationStatus.PUBLISHED,
      ) || []
    );
  }, [notificationUnreadGrid]);

  const setReadNotification = (notificationId: string[]) => {
    dispatch(
      newsNotificationsApi.setReadNotification({
        customerId,
        isRead: true,
        requestBody: { notificationIds: notificationId },
      }),
    ).then((result) => {
      if (result.meta.requestStatus === RequestStatus.Rejected) {
        showError();

        return;
      }

      dispatch(newsNotifications.actions.updatedNotificationGrid(notificationId));
    });
  };

  const handleRedirectToNotification = React.useCallback(
    (id: string) => {
      dispatch(newsNotifications.actions.updatedNotificationGrid([id]));

      history.push(`${Routes.NOTIFICATIONS_DETAILS.replace(':id', id)}`, {
        backTo: {
          route: Routes.DASHBOARD,
          title: `Back to ${backlinkRoutes[Routes.DASHBOARD]}`,
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [history, historyState, dispatch],
  );

  const emptyMessage = (
    <EmptyTablePreview title={noUnreadNotificationsMessage} alignCenter marginTop='50px' />
  );

  useEffect(() => {
    if (isOpen && !!prevRoute && route !== prevRoute && typeof closeHandler === 'function') {
      closeHandler();
    }
  }, [closeHandler, route, prevRoute, isOpen]);

  return (
    <>
      <ViewAllButton
        as={Link}
        to={{
          pathname: Routes.NOTIFICATIONS,
          state: {
            backTo: {
              route: Routes.DASHBOARD,
              title: `Back to ${backlinkRoutes[Routes.DASHBOARD]}`,
            },
          },
        }}
        onClick={() => {
          if (closeHandler) {
            closeHandler();
          }
        }}
      >
        View all notifications <Icon component={icons.arrow_right_alt} />
      </ViewAllButton>

      {notifications?.length > 0 ? (
        <>
          {notifications.slice(0, LIMIT).map((notification) => (
            <NotificationWrapper key={notification.id}>
              <Box
                cursor='pointer'
                onClick={() => handleRedirectToNotification(notification.id ?? '')}
              >
                <NotificationTitleWrapper>
                  <IconType
                    type={notification.type as NotificationType}
                    style={{ marginRight: '10px', marginTop: '3px' }}
                  />
                  <NotificationTitle>{notification.title}</NotificationTitle>
                  <NotificationTime>{getTimeAgoLabel(notification.published)}</NotificationTime>
                </NotificationTitleWrapper>
                <NotificationDescription>
                  {getShortenedText(
                    getStringWithoutTags(notification?.message || ''),
                    MAX_CHARS_IN_SHORTED_TEXT,
                    '',
                  )}
                </NotificationDescription>
              </Box>
              <ReadButtonWrapper>
                <ReadButton onClick={() => setReadNotification([notification.id || ' '])}>
                  Mark as read
                </ReadButton>
              </ReadButtonWrapper>
            </NotificationWrapper>
          ))}
          <ReadAllButton onClick={() => setReadNotification([])}>Mark all as read</ReadAllButton>
        </>
      ) : (
        emptyMessage
      )}
    </>
  );
};
