import { InnerLayout } from 'shared';
import { Col, Row } from 'antd';
import { useRowGap } from 'services/hooks/useRowGap';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  managedDetectionsResponseApi,
  useApp,
  useAuth,
  useCustomer,
  useLoading,
} from 'store';
import { RequestStatus } from 'store/loading/slice';
import { showError } from 'services/helpers';
import { downloadCSV } from 'services/helpers/downloadCSV';
import moment from 'moment-timezone';
import { dateFormatHours } from 'app-constants';
import { getLayoutConfig } from './helpers';
import {
  ActiveEdrAgents,
  ActiveSecurityIncidents,
  InvestigationOverview,
  MonitoredServices,
  MTTI,
  RecentAlarms,
} from './components';

const FULL_WIDTH_COLS = 32;

export const ThreatDetection = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useLoading([managedDetectionsResponseApi.exportActiveEdrAgents.typePrefix]);
  const { isMobile } = useApp();
  const customerId = useCustomer();
  const { user } = useAuth();
  const isNormUser = user.isSuperAdmin || false;

  const onDownloadCSV = async () => {
    const result = await dispatch(
      managedDetectionsResponseApi.exportActiveEdrAgents({
        customerId,
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      showError();
    }

    downloadCSV(result.payload, `EDR Agents ${moment().format(dateFormatHours)}`);
  };

  const layoutConfig = getLayoutConfig(
    onDownloadCSV,
    loading[managedDetectionsResponseApi.exportActiveEdrAgents.typePrefix],
    isNormUser,
    isMobile,
  );
  const { gapNumber } = useRowGap();

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={FULL_WIDTH_COLS} xs={24} lg={12} xl={12} xxl={8}>
          <ActiveSecurityIncidents />
        </Col>

        <Col span={FULL_WIDTH_COLS} xs={24} lg={12} xl={12} xxl={8}>
          <ActiveEdrAgents />
        </Col>

        <Col span={FULL_WIDTH_COLS} xs={24} xxl={8}>
          <MTTI />
        </Col>

        <Col span={FULL_WIDTH_COLS} xs={24} xxl={16}>
          <InvestigationOverview />
        </Col>
        <Col span={FULL_WIDTH_COLS} xs={24} xxl={8}>
          <MonitoredServices />
        </Col>

        <Col span={FULL_WIDTH_COLS} xs={24} xxl={24}>
          <RecentAlarms />
        </Col>
      </Row>
    </InnerLayout>
  );
};
