import { createSlice } from '@reduxjs/toolkit';
import { ActiveServicesInformationResponse } from 'services/api';
import { managedDetectionsResponseApi } from '../thunks';

type ActiveServicesState = {
  activeServices: ActiveServicesInformationResponse;
};

type ActiveServicesAction = {
  payload: ActiveServicesInformationResponse;
};

const initialState: ActiveServicesState = { activeServices: {} };

export const activeServices = createSlice({
  name: 'activeServices',
  initialState,
  reducers: {},
  extraReducers: {
    [managedDetectionsResponseApi.activeServices.fulfilled.toString()]: (
      state: ActiveServicesState,
      action: ActiveServicesAction,
    ) => {
      state.activeServices = action.payload;
    },
    [managedDetectionsResponseApi.activeServices.rejected.toString()]: (
      state: ActiveServicesState,
    ) => {
      state.activeServices = initialState.activeServices;
    },
  },
});
