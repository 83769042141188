import { Box, UcFirstLetter } from 'shared';
import { getCssVar } from 'styles/theme';
import { InvestigationOverviewResponse } from 'services/api';
import { numberFormatter } from 'services/helpers';
import { LegendDivider, LegendItem, Wrapper } from './styled';
import { Count, Title } from '../styled';
import { InvestigationOverviewLegend } from './constants';

interface ICustomLegendProps {
  colors: Record<string, string>;
  data: InvestigationOverviewResponse;
  onClickHandler: (title: string) => void;
  periodName: string;
}

export const DividerLegend = ({ data, colors, onClickHandler, periodName }: ICustomLegendProps) => {
  const chartNameList = Object.keys(data).map((item) => UcFirstLetter(item));

  const getDynamicIcon = (percent = 0, hideIcon = false) => {
    if (hideIcon || percent === 0) {
      return percent;
    }

    if (percent < 1) {
      return (
        <span style={{ color: 'green' }}>
          ▼<span style={{ color: getCssVar('textColor') }}>{percent}</span>
        </span>
      );
    }

    if (percent > 1) {
      return (
        <span style={{ color: 'red' }}>
          ▲<span style={{ color: getCssVar('textColor') }}>{percent}</span>
        </span>
      );
    }

    return <span className='text-bold font-size-16'>-</span>;
  };

  return (
    <Wrapper>
      {Object.values(data).map((item, index) => {
        const title = chartNameList[index].toLowerCase();
        const isHideIcon = title === InvestigationOverviewLegend.Events;

        return (
          <LegendItem key={title} cursor='pointer' onClick={() => onClickHandler(title)}>
            <Title>{chartNameList[index]}</Title>
            <LegendDivider color={colors[title]} />
            <Count>{numberFormatter(item?.count || 0, 1)}</Count>
            <Box>
              {getDynamicIcon(item?.percentageComparison, isHideIcon)}% over the last{' '}
              {periodName.toLowerCase()}
            </Box>
          </LegendItem>
        );
      })}
    </Wrapper>
  );
};
