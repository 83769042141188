import React from 'react';
import moment from 'moment';
import { MonthlyReportResponse } from 'services/api';
import { Annotations } from 'shared/charts/GradientPie/types';
import { IDate } from 'services/interfaces';
import { ReportPageLayout } from '../components/ReportPageLayout';
import { GradientPieReport } from '../components/GradientPieReport';
import { ReportTableSection } from '../components/ReportTableSection';
import { ReportActionsSection } from '../components/ReportActionsSection';
import { TotalScoreTable } from '../components/TotalScoreTable';
import { getSortedDashboardScores } from '../helpers';

export const PdfPage1 = ({
  data,
  onChartLoaded,
  scoreAnnotations,
  reportDate,
}: {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  scoreAnnotations?: Annotations;
  reportDate: IDate;
}) => {
  const scores = data?.dashboardScores || [];
  const checkingDashboardScores = getSortedDashboardScores([...scores])[scores.length - 1];
  const totalScore = checkingDashboardScores?.total;
  const peopleScore = checkingDashboardScores?.people;
  const processScore = checkingDashboardScores?.process;
  const technologyScore = checkingDashboardScores?.technology;
  const totalScoreTable = data?.dashboardScores || [];

  return (
    <ReportPageLayout
      headerTitle='nothing exciting to report*'
      headerSubtitle={`*Apart from this, your ${moment(reportDate.startDate).format(
        'MMMM YYYY',
      )} update`}
    >
      <table className='table-section'>
        <tbody>
          <tr>
            <td width='180' className='border-rt'>
              <h4 className='h4 font-size-14 text-center'>Cyber Resilience Score</h4>
              <GradientPieReport
                score={totalScore || 0}
                height='80px'
                onLoad={onChartLoaded}
                fontSize={18}
                scoreAnnotations={scoreAnnotations}
                titleFontSize={8}
              />
              <TotalScoreTable totalScoreTable={totalScoreTable} />
            </td>
            <td>
              <div className='mt-2'>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempus, ligula nec
                  accumsan efficitur, lacus ex fringilla erat, non venenatis lorem metus vitae
                  turpis. Phasellus nec turpis ultrices, molestie purus faucibus, tincidunt elit.
                  Nulla non metus vel erat mollis sollicitudin in a elit. Fusce scelerisque lectus
                  sed lectus scelerisque lobortis.
                </p>
                <p>
                  Am fermentum mollis lectus, non varius nulla placerat faucibus. Suspendisse id
                  elit non purus aliquam hendrerit. Vestibulum sed erat a mi commodo lobortis.
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table className='table-layout-fixed'>
        <tbody>
          <tr className='row-spacing'>
            <td height='210px'>
              <ReportTableSection
                score={peopleScore}
                showScore
                title='People'
                onLoad={onChartLoaded}
              >
                <p>
                  Pellentesque condimentum porta elementum. Fusce egestas erat non sem malesuada
                  porta. Vivamus varius luctus elementum. Aliquam vulputate tristique bibendum.
                  Morbi ac maximus sem. Sed a semper nulla. Etiam luctus quam sem, ac ornare est
                  sagittis ac.
                </p>
                <p>
                  Quisque quis lectus quam. Cras ut arcu eget diam dapibus accumsan et vitae metus.
                  Morbi tincidunt ultricies pretium. Nulla facilisi.
                </p>
              </ReportTableSection>
            </td>

            <td height='210px'>
              <ReportTableSection
                score={processScore}
                showScore
                title='Process'
                onLoad={onChartLoaded}
              >
                <p>
                  Pellentesque condimentum porta elementum. Fusce egestas erat non sem malesuada
                  porta. Vivamus varius luctus elementum. Aliquam vulputate tristique bibendum.
                  Morbi ac maximus sem. Sed a semper nulla. Etiam luctus quam sem, ac ornare est
                  sagittis ac.
                </p>
                <p>
                  Quisque quis lectus quam. Cras ut arcu eget diam dapibus accumsan et vitae metus.
                  Morbi tincidunt ultricies pretium. Nulla facilisi.
                </p>
              </ReportTableSection>
            </td>
          </tr>
        </tbody>
      </table>

      <ReportTableSection
        score={technologyScore}
        showScore
        title='Technology'
        onLoad={onChartLoaded}
      >
        <p>
          Nunc elit tellus, congue vestibulum tortor sed, fringilla faucibus ligula. Pellentesque
          quis pretium arcu. Curabitur purus lectus, ullamcorper eget arcu at, efficitur
          pellentesque lacus. Maecenas sodales ante id efficitur varius. Sed ac orci nec nulla
          hendrerit venenatis. Nulla commodo quis libero non malesuada. Donec venenatis dui tellus,
          ut vestibulum libero rhoncus at. Suspendisse non nunc cursus, rhoncus odio id, ornare
          augue. Curabitur purus libero, mollis nec finibus eu, sollicitudin sed turpis. Integer vel
          mauris ultrices, luctus felis sit amet, feugiat nisl.
        </p>
        <p>
          Sed at vestibulum nisi. Duis sagittis velit a eleifend dapibus. Praesent sed accumsan
          diam. Etiam nec hendrerit augue, et facilisis ante.
        </p>
      </ReportTableSection>
      <ReportActionsSection />
    </ReportPageLayout>
  );
};
