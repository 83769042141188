import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useApp,
  useAssetDetails,
  useAssetPatchesBySeverity,
  useLoading,
  useVendorSeverity,
} from 'store';
import { isEmptyObject } from 'services/helpers';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { Card } from 'shared';
import { SegmentedPie } from 'shared/charts/SegmentedPie/SegmentedPie';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { AssetPatchesCountBySeverity, PatchStatus } from 'services/api';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { useLocation, useParams } from 'react-router';
import { HistoryState } from 'services/interfaces';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { useAgedHostsOrCmdbRoute } from 'services/hooks';
import { getRedirectParams } from './helpers';
import { vendorSeverity } from '../../../../../store/patchManagement/vendorSeverity';
import { defaultVendorSeverityValue } from '../../../../../shared/PmSeverityFilter/constants';
import { SeverityOptionLabel, SeverityOptions } from '../../../../PatchManagementPatches/constants';
import { CmdbTabKeys } from '../../../constants';
import { assetDetails } from '../../../../../store/assetDetails/slice';

export const PatchesBySeverityChart = ({ patchStatus }: { patchStatus: PatchStatus }) => {
  const history = useHistory();
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const { state } = useLocation<HistoryState>();
  const { missingPatchesBySeverity, installedPatchesBySeverity } = useAssetPatchesBySeverity();
  const data =
    patchStatus === PatchStatus.MISSING ? missingPatchesBySeverity : installedPatchesBySeverity;
  const { id: hostId } = useParams<{ id: string }>();
  const { detailsAdditionalInformation } = useAssetDetails();
  const severities = useVendorSeverity();
  const dispatch = useDispatch();
  const loading = useLoading([assetDetailsApi.getAssetPatchesBySeverity.typePrefix]);
  const { pageRoute: backLinkRoute } = useAgedHostsOrCmdbRoute();

  const chartData: Record<string, number> = {};

  const chartElements = data?.chart ? { ...data?.chart } : {};

  (severities || [])?.forEach((name: string) => {
    if (Object.prototype.hasOwnProperty.call(chartElements, name)) {
      const getSeverityOptionsLabel = SeverityOptions?.find((item) => item?.value === name);
      const chartName = getSeverityOptionsLabel?.label || name;

      chartData[chartName] = chartElements[name as keyof AssetPatchesCountBySeverity] || 0;
    }
  });

  useEffect(() => {
    return () => {
      dispatch(vendorSeverity.actions.setSeverities(defaultVendorSeverityValue));
      dispatch(assetDetails.actions.resetValues());
    };
  }, [dispatch]);

  const chartColors = {
    [SeverityOptionLabel.URGENT]: theme.colors.red[700],
    [SeverityOptionLabel.CRITICAL]: theme.colors.red[400],
    [SeverityOptionLabel.SERIOUS]: theme.colors.yellow[500],
    [SeverityOptionLabel.MEDIUM]: theme.colors.green[300],
    [SeverityOptionLabel.MINIMAL]: theme.colors.blue[100],
  };

  const historyState = React.useMemo(
    () => ({
      backTo: {
        route: `${backLinkRoute}/${hostId}`,
        search: history.location.search,
        title: `Back to `,
        id: detailsAdditionalInformation.hostname,
      },
      rootBackTo: state?.backTo,
      activeTabKey: CmdbTabKeys.PATCH_MANAGEMENT,
    }),
    [
      detailsAdditionalInformation.hostname,
      history.location.search,
      hostId,
      state?.backTo,
      backLinkRoute,
    ],
  );

  const redirectToPatches = React.useCallback(
    (params?: EChartsEventParams) => {
      const severity = SeverityOptions.find((option) => option.label === params?.name);

      if (params?.name) {
        history.push(
          `${Routes.PATCH_MANAGEMENT_PATCHES}${getQueryString(
            getRedirectParams(patchStatus, hostId as string, [severity?.value || params?.name]),
          )}`,
          historyState,
        );
      }
    },
    [history, patchStatus, hostId, historyState],
  );

  const totalClickRedirect = () => {
    history.push(
      `${Routes.PATCH_MANAGEMENT_PATCHES}${getQueryString(
        getRedirectParams(patchStatus, hostId as string, severities),
      )}`,
      historyState,
    );
  };

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToPatches(params),
    }),
    [redirectToPatches],
  );
  const renderChart = () => {
    if (isEmptyObject(chartData)) {
      return emptyMessage('No patches data');
    }

    return (
      <SegmentedPie
        style={isMobile ? { height: '240px' } : { height: '270px' }}
        values={chartData}
        colors={chartColors}
        entitiesName='patches'
        isMobile={isMobile}
        isMenuCollapsed={isMenuCollapsed}
        onEvents={onEvents}
        totalClickHandler={totalClickRedirect}
        isLoading={loading[assetDetailsApi.getAssetPatchesBySeverity.typePrefix]}
      />
    );
  };

  return (
    <Card
      title={`${patchStatus} patches by severity`}
      showDivider={false}
      height={theme.sizes.full}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[3], titlePaddingRight: theme.sizes[2] }
          : { titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={!isMobile ? theme.sizes[6] : theme.sizes[5]}
      showLoader={loading[assetDetailsApi.getAssetPatchesBySeverity.typePrefix]}
    >
      {renderChart()}
    </Card>
  );
};
