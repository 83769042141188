import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { HeaderActionWrap } from 'pages/VulnerabilityDetails/styled';
import { SuppressionStatus } from 'services/api';
import { useGetPermission } from 'services/hooks';
import { Button, Icon } from 'shared';
import { TagVulnSuppression } from 'shared/TagColoring/VulnSuppression';
import { useTheme } from 'styled-components';
import { icons } from 'styles/theme/icons';

type SuppressionHeaderProps = {
  action: () => void;
  status?: SuppressionStatus;
};

export const SuppressionHeader = ({ action, status }: SuppressionHeaderProps) => {
  const isFullAccess =
    useGetPermission(PermissionOptions.VulnerabilityManagement) === PermissionsLevel.Full;
  const theme = useTheme();

  return (
    <HeaderActionWrap>
      <TagVulnSuppression status={status} />
      {isFullAccess && (
        <Button
          onClick={action}
          type='link'
          icon={<Icon component={icons.edit} />}
          style={{
            marginLeft: `${theme.spacing[status ? '2' : '4']}`,
          }}
        >
          Update
        </Button>
      )}
    </HeaderActionWrap>
  );
};
