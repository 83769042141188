import { useEffect } from 'react';
import { app, AppState, authApi } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { clearOldPersists } from 'services/helpers/clearOldPersists';
import { AppWrapper } from 'shared';
import { unAuthorizedRoutes } from 'services/entities';
import { useCheckUpdate } from 'services/hooks/useCheckUpdate';
import { CSSVarsProvider, GlobalStyles } from 'styles/theme/globalStyles';
import { OpenAPI } from 'services/api';
import { Routes } from './shared/Router/Routes';

const loadServerStumb = async () => import('serverStumb/server');

if (process.env.NODE_ENV === 'test') {
  loadServerStumb().then(({ makeServer }) => makeServer(OpenAPI.BASE));
}

OpenAPI.BASE = process.env.REACT_APP_DEV_API_URL as string;

export function App() {
  const dispatch = useDispatch();
  const isAuthorized = useSelector((state: AppState) => state.auth.isAuthorized);

  // Check if the app has a new version delivered
  useCheckUpdate();

  useEffect(() => {
    clearOldPersists();
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(authApi.getProfile());
    }
    // need to update profile only on page refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isAuthorized &&
      !unAuthorizedRoutes.includes(`/${window.location.pathname.split('/')[1]}`)
    ) {
      dispatch(app.actions.setLastVisitedPage(window.location.pathname + window.location.search));
    }
  }, [isAuthorized, dispatch]);

  return (
    <AppWrapper>
      <CSSVarsProvider />
      <GlobalStyles />
      <Routes />
    </AppWrapper>
  );
}
