import { Routes } from 'services/entities';
import { submenus, submenusChildren } from './components/constants';

export const calculateOpenKeys = (path: Routes) => {
  return submenus.reduce((acc: string[], submenu: string) => {
    if (!submenusChildren[submenu].includes(`/${path.split('/')[1]}` as Routes)) {
      return acc;
    }

    return [...acc, `sub-${submenu}`];
  }, []);
};
