/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TagsList } from './TagsList';

export type HostSchema = {
  customerId?: string;
  criticality?: HostSchema.criticality;
  hostname?: string;
  insensitiveHostName?: string;
  ip?: string;
  os?: string;
  name?: string;
  department?: string;
  assetSource?: Array<string>;
  vulnerabilitiesCriticality1Count?: number;
  vulnerabilitiesCriticality2Count?: number;
  vulnerabilitiesCriticality3Count?: number;
  vulnerabilitiesCriticality4Count?: number;
  vulnerabilitiesCriticality5Count?: number;
  alarms?: number;
  lastScan?: number;
  lastCheckedIn?: number;
  vulnerabilitySeverity?: Array<string>;
  vulnerabilitiesQIDs?: Array<string>;
  hasVulnerabilities?: boolean;
  id?: string;
  platform?: string;
  operatingSystemType?: string;
  publisher?: string;
  product?: string;
  marketVersion?: string;
  osVersion?: string;
  tagsAssets?: Array<TagsList>;
};

export namespace HostSchema {
  export enum criticality {
    _0 = '0',
    _1 = '1',
    _2 = '2',
  }
}
