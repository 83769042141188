import { createSlice } from '@reduxjs/toolkit';
import { FilteredAlarmsResponse } from 'services/api';
import { alarmsApi } from './thunks';

type AlarmsState = {
  data: FilteredAlarmsResponse;
  alarmsByOriginCount: number;
};

type AlarmsAction = {
  payload: FilteredAlarmsResponse;
};

const initialState: AlarmsState = {
  data: {} as FilteredAlarmsResponse,
  alarmsByOriginCount: 0,
};

export const alarms = createSlice({
  name: 'alarms',
  initialState,
  reducers: {},
  extraReducers: {
    [alarmsApi.alarmsTableContent.fulfilled.toString()]: (
      state: AlarmsState,
      action: AlarmsAction,
    ) => {
      state.data = action.payload;
    },
    [alarmsApi.alarmsTableContent.rejected.toString()]: (state: AlarmsState) => {
      state.data = {};
    },
  },
});
