import { numberFormatter } from 'services/helpers';
import { IEChartsOption, LabelFormatterParams } from 'shared/charts/interfaces';
import { Theme } from 'styles/theme/types';
import { cePlusTooltips } from '../../shared/charts/formatters';

export type HardwareManufacturerProps = {
  title?: string;
  count: number;
};

const rightGridForDesktop = 30;
const rightGridForMobile = 55;

type Entry = {
  value: number;
  itemStyle: { color: string };
};

export const getOption = (
  values: HardwareManufacturerProps[],
  theme: Theme,
  height?: number,
  isMobile?: boolean,
  hasClickHandler?: boolean,
  color?: string[],
): IEChartsOption => {
  const defaultColorList = color || [];

  const entries: [string, Entry][] = values?.map(
    (event: HardwareManufacturerProps, index: number) => [
      event.title || '',
      {
        value: event.count,
        itemStyle: {
          color: defaultColorList[index] || defaultColorList[index - defaultColorList.length],
        },
      },
    ],
  );

  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => cePlusTooltips(params[0]),
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
    },
    grid: {
      top: 10,
      bottom: 20,
      left: 140,
      right: !isMobile ? rightGridForDesktop : rightGridForMobile,
      height: 300,
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => numberFormatter(value),
        color: theme.colorSet.charts.axisLabel,
      },
      min: 0,
    },
    yAxis: {
      type: 'category',
      data: entries?.map((entry: [string, Entry]) => entry[0]),
      inverse: true,
      boundaryGap: true,
      axisTick: { alignWithLabel: true },
      nameTextStyle: {
        fontSize: 13,
        fontWeight: 400,
      },
      axisLabel: {
        width: 124,
        overflow: 'break',
        color: theme.colorSet.charts.axisLabel,
      },
    },
    series: [
      {
        type: 'bar',
        animation: true,
        name: 'Total',
        data: entries?.map((entry: [string, Entry]) => entry[1]),
        label: {
          show: true,
          position: 'inside',
          formatter: (params: LabelFormatterParams) => numberFormatter(params.data.value),
          color: theme.colors.white,
          fontSize: 13,
          fontWeight: 700,
        },
        barWidth: 28,
        cursor: hasClickHandler ? 'pointer' : 'auto',
        silent: !hasClickHandler,
      },
    ],
  };
};
