import React from 'react';
import { getCapitalizedString, getFormattedDate, getRenderValue } from 'services/helpers';
import { ColumnsType } from 'antd/es/table/interface';
import { Filter, PatchDownloadMethod, PatchSchema, PatchStatus } from 'services/api';
import { Link } from 'react-router-dom';
import { Routes } from 'services/entities';
import { backlinkRoutes, initialPagination } from 'app-constants';
import { Box, Icon, TagColoring } from 'shared';
import { icons } from 'styles/theme';
import { Tooltip } from 'antd';
import { getQueryString } from 'services/api/core/request';
import { initialOrderBy as assetOrderBy } from 'pages/PatchManagementAssets/constants';
import { RowType } from 'shared/Table/CustomTable';
import { ColumnKey, TableTitles } from '../types';
import { SeverityOptionLabel, SeverityOptions } from '../constants';
import { PatchManagementTabKeys } from '../../PatchManagementPatchDetails/constants';

export const getColumns = (locationSearch: string): ColumnsType<RowType> => [
  {
    key: ColumnKey.Patch,
    title: TableTitles.Patch,
    dataIndex: ColumnKey.Patch,
    width: '40%',
    sorter: true,
    render: (value: string, row: PatchSchema) => (
      <Box d='flex' alignItems='center' gap='8px'>
        {row.downloadMethod === PatchDownloadMethod.UNAVAILABLE && (
          <Tooltip overlay='Patch not available'>
            <Icon component={icons.downloadNotAvailable} />
          </Tooltip>
        )}

        {row.downloadMethod === PatchDownloadMethod.ACQUIRE_FROM_VENDOR && (
          <Tooltip overlay='Patch must be acquired from the vendor and installed manually'>
            <Icon component={icons.availableFromVendor} />
          </Tooltip>
        )}

        <Box ml={row.downloadMethod === PatchDownloadMethod.AUTOMATIC ? '32px' : '0'}>
          {getRenderValue(value)}
        </Box>
      </Box>
    ),
  },
  {
    key: ColumnKey.VendorSeverity,
    title: TableTitles.VendorSeverity,
    dataIndex: ColumnKey.VendorSeverity,
    align: 'center',
    sorter: true,
    render: (value: string) => {
      const item = SeverityOptions.find((option) => option.value === value);

      return (
        <TagColoring title={getCapitalizedString(item?.label || SeverityOptionLabel.MINIMAL)} />
      );
    },
  },
  {
    key: ColumnKey.MissingOnAssets,
    title: TableTitles.MissingOnAssets,
    dataIndex: ColumnKey.MissingOnAssets,
    align: 'right',
    render: (value: string, row: RowType) => (
      <Link
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
        to={{
          pathname: Routes.PATCH_MANAGEMENT_ASSETS,
          search: getQueryString({
            pagination: initialPagination,
            orderBy: assetOrderBy,
            filter: {
              fields: [
                {
                  name: 'patchStatus',
                  value: [PatchStatus.MISSING],
                  type: Filter.type.MULTIPLE,
                },
                {
                  name: 'patchId',
                  value: row.id,
                  type: Filter.type.VALUE,
                },
              ],
            },
          }),
          state: {
            backTo: {
              route: Routes.PATCH_MANAGEMENT_PATCHES,
              title: `Back to ${backlinkRoutes[Routes.PATCH_MANAGEMENT_PATCHES]}`,
              search: locationSearch,
            },
          },
        }}
      >
        {getRenderValue(value)}
      </Link>
    ),
  },
  {
    key: ColumnKey.InstalledOnAssets,
    title: TableTitles.InstalledOnAssets,
    dataIndex: ColumnKey.InstalledOnAssets,
    align: 'right',
    render: (value: string, row: RowType) => (
      <Link
        to={{
          pathname: Routes.PATCH_MANAGEMENT_ASSETS,
          search: getQueryString({
            pagination: initialPagination,
            orderBy: assetOrderBy,
            filter: {
              fields: [
                {
                  name: 'patchStatus',
                  value: [PatchStatus.INSTALLED],
                  type: Filter.type.MULTIPLE,
                },
                {
                  name: 'patchId',
                  value: row.id,
                  type: Filter.type.VALUE,
                },
              ],
            },
          }),
          state: {
            backTo: {
              route: Routes.PATCH_MANAGEMENT_PATCHES,
              title: `Back to ${backlinkRoutes[Routes.PATCH_MANAGEMENT_PATCHES]}`,
              search: locationSearch,
            },
          },
        }}
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
      >
        {getRenderValue(value)}
      </Link>
    ),
  },
  {
    key: ColumnKey.Category,
    title: TableTitles.Category,
    dataIndex: ColumnKey.Category,
    align: 'center',
    render: (value: string) => <>{getRenderValue(value, '')}</>,
  },
  {
    key: ColumnKey.Vulnarabilities,
    title: TableTitles.Vulnarabilities,
    dataIndex: ColumnKey.Vulnarabilities,
    align: 'right',
    render: (value: string[], row) => {
      return (
        <Link
          to={{
            pathname: `${Routes.PATCH_MANAGEMENT_PATCHES}/${row.id}`,
            state: {
              backTo: {
                route: Routes.PATCH_MANAGEMENT_PATCHES,
                title: `Back to ${backlinkRoutes[Routes.PATCH_MANAGEMENT_PATCHES]}`,
                search: locationSearch,
              },
              activeTabKey: PatchManagementTabKeys.RESOLVED_VULNERABILITIES,
            },
          }}
          onClick={(event: React.MouseEvent) => event.stopPropagation()}
        >
          {getRenderValue(value.length, '')}
        </Link>
      );
    },
  },
  {
    key: ColumnKey.Published,
    title: TableTitles.Published,
    dataIndex: ColumnKey.Published,
    className: 'right-head-title',
    align: 'right',
    render: (value: string) => <>{getFormattedDate(value, '', true)}</>,
    sorter: true,
    showSorterTooltip: false,
  },
];
