import styled from 'styled-components';
import { RangePicker } from 'shared';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../../../styles/theme';

type Props = {
  theme: Theme;
};

export const RangePickerWithLabelContainer = styled.div`
  color: ${getCssVar('textColor')};
  margin-bottom: 8px;
  padding: 0 6px;
  width: 282px;

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    label {
      font-weight: ${({ theme }: Props) => theme.fontWeights.medium};
    }
  }
`;

export const RangePickerStyled = styled(RangePicker)`
  width: 270px;
  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    width: ${({ theme }: Props) => theme.sizes.full};
  }

  &.checked {
    background-color: ${getCssVar('selectChecked')};
    border: 2px solid ${getCssVar('input.filterBorder')};

    .ant-picker-clear {
      background-color: ${getCssVar('selectChecked')};
    }
  }
`;
