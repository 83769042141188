import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page, SidebarTextBlock, Table } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide9.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const ServiceConsumption = styled(SidebarTextBlock)`
  top: 160px;
`;

export const LicenseUsageTableWrapper = styled.div`
  left: 401px;
  padding: 10px 30px;
  position: absolute;
  top: 342px;

  width: 605px;

  p {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const LicenseUsageTable = styled(Table)`
  font-size: 13px;
  max-width: 545px;

  tr > th,
  tr > td {
    padding: 5px 10px;
  }
`;

export const ServiceHeaderCell = styled.th`
  width: 55%;
`;

export const UsedHeaderCell = styled.th`
  width: 20%;
`;

export const CurrentLimitHeaderCell = styled.th`
  width: 25%;
`;

export const EventsChart = styled.div`
  background-color: #eeede8;
  font-weight: 600;
  height: 267px;
  left: 402px;
  position: absolute;
  top: 50px;
  width: 605px;

  div {
    span {
      position: absolute;
    }
  }

  div:nth-child(2) {
    position: absolute;
    right: 110px;
  }

  text {
    stroke: none;
  }
`;

export const SubTitle = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 900;
  position: absolute;
  right: 80px;
  top: 55px;
  z-index: 999;
`;

export const SubTitlePrefix = styled.div`
  font-size: 10px;
  font-weight: 300;
  margin-right: 5px;
  position: relative;
  top: -1px;
`;

export const ChartTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  left: 432px;
  position: absolute;
  top: 62px;
  z-index: 999;
`;
