import { createSlice } from '@reduxjs/toolkit';
import { FindingsBySeverity, TestVilnerabilities } from 'pages/PenetrationTestDetails/data';
import { PenetrationTestsDataRow } from 'pages/PenetrationTestsList/data';
import { penetrationTestingApi } from '../thunks';

type PenetrationTestDetailsState = {
  testDetails: PenetrationTestsDataRow;
  findingsBySeverity: FindingsBySeverity[];
  detailsVulnerabilitiesList: TestVilnerabilities;
};

const initialState: PenetrationTestDetailsState = {
  testDetails: {},
  findingsBySeverity: [],
  detailsVulnerabilitiesList: {},
};

export const penetrationTestDetails = createSlice({
  name: 'penetrationTestDetails',
  initialState,
  reducers: {},
  extraReducers: {
    [penetrationTestingApi.getPenetrationTestDetails.fulfilled.toString()]: (
      state: PenetrationTestDetailsState,
      action: { payload: PenetrationTestsDataRow },
    ) => {
      state.testDetails = action.payload;
    },
    [penetrationTestingApi.getPenetrationTestDetails.rejected.toString()]: (
      state: PenetrationTestDetailsState,
    ) => {
      state.testDetails = {};
    },

    [penetrationTestingApi.getFindingsBySeverity.fulfilled.toString()]: (
      state: PenetrationTestDetailsState,
      action: { payload: FindingsBySeverity[] },
    ) => {
      state.findingsBySeverity = action.payload;
    },
    [penetrationTestingApi.getFindingsBySeverity.rejected.toString()]: (
      state: PenetrationTestDetailsState,
    ) => {
      state.findingsBySeverity = [];
    },

    [penetrationTestingApi.getTestDetailsVulnerabilitiesList.fulfilled.toString()]: (
      state: PenetrationTestDetailsState,
      action: { payload: TestVilnerabilities },
    ) => {
      state.detailsVulnerabilitiesList = action.payload;
    },
    [penetrationTestingApi.getTestDetailsVulnerabilitiesList.rejected.toString()]: (
      state: PenetrationTestDetailsState,
    ) => {
      state.detailsVulnerabilitiesList = {};
    },
  },
});
