import { Col, Descriptions, Spin, Table as AntTable } from 'antd';
import styled from 'styled-components';
import { breakpoints, getCssVar } from 'styles/theme';
import { Theme } from '../../styles/theme/types';

interface IProps {
  isClickable?: boolean;
}

export const Table = styled(AntTable)<IProps>`
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: ${getCssVar('card.bg')};
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .right-head-title {
    .ant-table-column-sorters {
      justify-content: flex-end;
    }
  }

  .ant-table-row-expand-icon-cell {
    padding-right: 0 !important;
  }

  .center-head-title {
    .ant-table-column-sorters {
      justify-content: center;
    }
  }

  .ant-table-column-title {
    flex: initial;
    padding-right: 8px;
    font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
  }

  .small-row > .ant-table-cell {
    height: auto;
  }

  .ant-table-thead {
    .ant-table-cell,
    .ant-table-cell:first-child,
    .ant-table-cell:last-child {
      background-color: ${getCssVar('card.bg')};
      color: ${getCssVar('textColor')};
      padding: ${({ theme }: { theme: Theme }) => `${theme.sizes['2.5']} ${theme.sizes[3]}`};
      font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
      border-color: ${getCssVar('table.borderBottom')};

      &:before {
        display: none;
      }
    }

    th {
      height: ${({ theme }: { theme: Theme }) => theme.sizes[4]};

      &.ant-table-column-sort {
        &:hover {
          background-color: ${getCssVar('card.bg')};
        }
      }

      .anticon.active {
        color: ${getCssVar('table.th.iconColor')} !important;
      }

      &:hover,
      &:focus {
        .ant-table-column-sorter-inner {
          color: ${getCssVar('table.th.color')};
        }
      }
    }

    > tr {
      > th {
        font-weight: 700;
      }
    }
  }

  tr.ant-table-expanded-row > td {
    background: ${getCssVar('table.tr.bg')} !important;
  }

  .ant-table-tbody {
    background-color: ${getCssVar('table.tr.emptyBg')};

    > tr.ant-table-placeholder:hover > td {
      background-color: ${getCssVar('table.tr.emptyBg')};
    }

    .btn-table-action {
      opacity: 0;
    }

    .ant-table-row {
      .ant-table-cell,
      .ant-table-cell:first-child,
      .ant-table-cell:last-child {
        color: ${getCssVar('textColor')};
        padding: ${({ theme }: { theme: Theme }) => `${theme.sizes[2]} ${theme.sizes[3]}`};
        border-color: ${getCssVar('table.borderBottom')};

        .ant-tag {
          margin-right: 0;
        }
      }

      &:hover {
        cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

        .btn-table-action {
          opacity: 1;
        }
      }
    }

    > :not(tr.ant-table-row-selected) {
      .ant-table-column-sort {
        background: transparent;
      }

      &:hover {
        .ant-table-column-sort {
          background: ${getCssVar('table.tr.rowBg')};
        }
      }
    }

    > tr.ant-table-row-selected {
      td {
        background: ${getCssVar('table.tr.rowBg')};
      }
    }

    > tr {
      > td {
        height: ${({ theme }: { theme: Theme }) => theme.sizes[14]};
        border-bottom-width: ${({ theme }: { theme: Theme }) => theme.sizes.px};
      }

      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }

  .ant-table-column-sorters::after {
    background-color: ${getCssVar('card.bg')};
  }

  .ant-table-column-sorter {
    z-index: 1;
  }

  .ant-table .ant-table .ant-table-tbody > :not(tr.ant-table-row-selected) .ant-table-column-sort {
    &:not(.ant-table-cell-row-hover) {
      background-color: transparent;
    }
  }
`;

type TopOfCardProps = {
  offset: number;
  theme: Theme;
};

export const TopOfCard = styled.div<TopOfCardProps>`
  border-bottom: ${(props: TopOfCardProps) =>
    props.offset ? `2px solid ${getCssVar('tableHeader')}` : 'none'};
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props: TopOfCardProps) =>
    props.offset ? props.theme.spacing[5] : props.theme.spacing['3.5']};
`;

export const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FiltersButtons = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[3]};
`;

export const Sort = styled.span`
  color: ${getCssVar('primary')};
  font-size: 15px;

  .anticon {
    height: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
  }
`;

export const Filters = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.colorSet.primary};
  font-size: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
  font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }: { theme: Theme }) => theme.spacing[5]};

  .anticon {
    height: ${({ theme }: { theme: Theme }) => theme.sizes[5]};
  }

  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing['1.5']};
  margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
`;

export const ItemsAmount = styled.span`
  color: ${getCssVar('table.tableItem.color')};
  display: flex;
  flex: 0 0 auto;
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes['3.5']};
`;

type TableColumnProps = {
  withActions?: boolean;
  theme: Theme;
};

export const TableColumn = styled(Descriptions)<TableColumnProps>`
  padding-right: ${({ withActions, theme }: TableColumnProps) =>
    withActions ? theme.spacing[6] : 0};
  position: relative;

  .ant-descriptions-header {
    margin-bottom: 0;

    .ant-descriptions-extra {
      position: absolute;
      right: -10px;
      top: -10px;
    }
  }

  .ant-descriptions-item-label:after {
    display: none;
  }

  .ant-descriptions-item-content {
    align-items: center;
    color: ${getCssVar('table.tableItem.color')};
    display: flex;
    font-size: 15px;
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }: { theme: Theme }) => theme.sizes[6]};
    margin-left: 12px;
    width: 65%;
    @media (max-width: ${breakpoints.md}) {
      width: 76%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 75%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 60%;
    }
  }

  .ant-descriptions-item-conteiner {
    display: flex;
  }

  .ant-descriptions-item-label {
    color: ${getCssVar('table.tableItem.color')};
    font-size: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
    width: 20%;
    @media (max-width: ${breakpoints.md}) {
      width: 22.5%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 25%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 32%;
    }
  }

  .ant-descriptions-row {
    &:first-child {
      .ant-descriptions-item {
        padding-top: 0;
      }
    }

    &:last-child {
      .ant-descriptions-item {
        padding-bottom: 0;
      }
    }
  }

  &:last-child {
    border: none;

    .ant-descriptions-row:last-child {
      > .ant-descriptions-item {
        padding: 0;
      }
    }
  }
`;

export const ViewTableColumn = styled(TableColumn)`
  border-bottom: 1px solid ${getCssVar('table.tableItem.borderColor')};

  &:last-child {
    border: none;
    padding: ${({ theme }: { theme: Theme }) => theme.spacing[4]} 0 0 0;
  }

  padding: ${({ theme }: { theme: Theme }) => theme.spacing[4]} 0
    ${({ theme }: { theme: Theme }) => theme.spacing[4]} 0;

  .ant-descriptions-item-label {
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
    width: 25%;
    @media (max-width: ${breakpoints.md}) {
      width: 20%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 25%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 35%;
    }
  }

  .ant-descriptions-row {
    &:last-child {
      .ant-descriptions-item {
        padding: 0;
      }
    }
  }

  .ant-descriptions-item-content {
    font-size: 15px;
    margin: 0;
    width: 75%;
    @media (max-width: ${breakpoints.md}) {
      width: 80%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 75%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 65%;
    }
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
`;

type ScrollProps = {
  width: string;
};

export const ScrollTableContainer = styled.div<ScrollProps>`
  overflow-x: scroll;
  transform: scaleY(-1);

  ::-webkit-scrollbar {
    height: 13px;
    top: 0;
  }

  .ant-table-wrapper {
    min-width: ${(props: ScrollProps) => props.width};
    transform: scaleY(-1);
  }

  .ant-table {
    min-width: ${(props: ScrollProps) => props.width};
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }: { theme: Theme }) => theme.colors.gray[100]};
    border-radius: ${({ theme }: { theme: Theme }) => theme.radius.xl};
  }

  ::-webkit-scrollbar-thumb {
    background: ${getCssVar('scrollBar')};
    border-radius: ${({ theme }: { theme: Theme }) => theme.radius.xl};
  }
`;

type ClickableColumnProps = {
  isClickable: boolean;
};

export const ClickableColumn = styled.div<ClickableColumnProps>`
  border-bottom: 1px solid ${getCssVar('table.tableItem.borderColor')};
  cursor: ${(props: ClickableColumnProps) => (props.isClickable ? 'pointer' : 'default')};
  padding: ${({ theme }: { theme: Theme }) => `${theme.spacing[4]} 0`};
  width: 100%;

  &:last-child {
    border: none;
    padding: ${({ theme }: { theme: Theme }) => `${theme.spacing[4]} 0 ${theme.spacing[3]} 0`};
  }
`;

export const TableLoader = styled(Spin)`
  margin-top: 20px;
`;

export const MobileTableWrapper = styled.div`
  .ant-descriptions-item-content {
    width: 65%;
    @media (max-width: ${breakpoints.md}) {
      width: 75%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 65%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 50%;
    }
  }

  margin-left: ${({ theme }: { theme: Theme }) => `-${theme.spacing[4]}`};
  margin-right: ${({ theme }: { theme: Theme }) => `-${theme.spacing[4]}`};

  .ant-descriptions-item-label {
    width: 20%;
    @media (max-width: ${breakpoints.md}) {
      width: 25%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 35%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 45%;
    }
  }
`;

export const MobileTableCol = styled(Col)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const ExpandedRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: { theme: Theme }) => theme.spacing[1]};
  padding-left: ${({ theme }: { theme: Theme }) => theme.spacing[8]};
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
`;
