import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { app, useApp, useAuth, useCustomer } from 'store';
import { SelectValue } from 'antd/lib/select';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { Box, Icon } from 'shared';
import { icons } from 'styles/theme';
import { TwoFACustomerSchema } from 'services/api';
import { OptionType } from 'shared/Table/types';
import { changeCustomerAction } from 'store/actionCreators';
import { StyledSelect } from './styled';

export const CustomersSelect = () => {
  const dispatch = useDispatch();
  const customer = useCustomer();
  const { customersList } = useAuth();
  const selectRef = React.useRef<HTMLDivElement>(null);
  const {
    user: { isSuperAdmin },
  } = useAuth();
  const { isDesktop } = useApp();

  const handleSelectChange = (value: SelectValue) => {
    const customerName = customersList.find(
      (customer: TwoFACustomerSchema) => customer.customerId === value,
    )?.name;

    dispatch(changeCustomerAction());
    dispatch(app.actions.setCurrentCustomerId(`${value}`));
    dispatch(app.actions.setCurrentCustomerName(customerName || ''));
  };

  useEffect(() => {
    const customerName = customersList.find(
      (listCustomer: TwoFACustomerSchema) => listCustomer.customerId === customer,
    )?.name;

    dispatch(app.actions.setCurrentCustomerName(customerName || ''));
  }, [customersList, dispatch, customer]);

  const sortedOptions = React.useMemo(() => {
    const customers = customersList
      .slice(1)
      .sort((customerA: TwoFACustomerSchema, customerB: TwoFACustomerSchema) => {
        if (customerA.name && customerB.name) {
          return customerA.name.toLowerCase().localeCompare(customerB.name.toLowerCase());
        }

        return 0;
      });

    return [customersList[0], ...customers].map((currentCustomer: TwoFACustomerSchema) => ({
      value: currentCustomer.customerId || '',
      label: isSuperAdmin
        ? `${currentCustomer.name} (${currentCustomer.customerId})`
        : currentCustomer.name || '',
    }));
  }, [customersList, isSuperAdmin]);

  const filterOption = (inputValue: string, option: OptionData | OptionGroupData | undefined) =>
    (option as OptionType).label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

  return (
    <Box position='relative' ref={selectRef}>
      <StyledSelect
        placeholder='Please select'
        suffixIcon={<Icon className='ant-select-suffix' component={icons.arrow_drop_down} />}
        value={customer}
        options={sortedOptions}
        onChange={handleSelectChange}
        showSearch
        dropdownStyle={{ minWidth: isDesktop ? '520px' : 'auto' }}
        filterOption={filterOption}
        getPopupContainer={() => selectRef.current as HTMLElement}
        dropdownAlign={{
          overflow: { adjustY: true },
        }}
      />
    </Box>
  );
};
