import React, { useCallback, useEffect, useMemo } from 'react';
import { useFilter } from 'services/hooks/useFilter';
import { Button, EmptyTablePreview, InnerLayout, MobileTable, MobileTableCol } from 'shared';
import { useDispatch } from 'react-redux';
import { Gutter } from 'antd/lib/grid/row';
import { Col, Row } from 'antd';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { CustomTable } from 'shared/Table/CustomTable';
import {
  AppDispatch,
  newsNotifications,
  newsNotificationsApi,
  useApp,
  useCustomer,
  useLoading,
  useNewsNotifications,
} from 'store';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Notification } from 'services/api';
import { useGetPermission } from 'services/hooks';
import { getLayoutConfig } from './helpers';
import {
  ColumnKey,
  emptyGridMessage,
  filterFields,
  filterFieldsNormUser,
  NormInitialOrderBy,
  searchSettings,
  userInitialOrderBy,
} from './constant';
import { getColumns, getNormUserColumns } from './components/TableColumns';
import { backlinkRoutes, PermissionOptions, PermissionsLevel } from '../../app-constants';
import { MobileActions } from './components/MobileActions';
import { HistoryState } from '../../services/interfaces';
import { Routes } from '../../services/entities';
import { RequestStatus } from '../../store/loading/slice';
import { showError } from '../../services/helpers';
import { OrderBy } from '../../services/api';
import { UpdateNotification } from '../NotificationsUpdate/constants';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const FULL_WIDTH_COLS = 32;
const defaultPageSize = 50;
const defaultCurrent = 1;

export const Notifications = () => {
  const isFullAccess = useGetPermission(PermissionOptions.Notifications) === PermissionsLevel.Full;
  const customerId = useCustomer();
  const history = useHistory();
  const { notificationGrid, createdByList, notificationUnreadGrid } = useNewsNotifications();
  const loading = useLoading([newsNotificationsApi.getNotificationGrid.typePrefix]);
  const { isMobile } = useApp();
  const { state: historyState } = useLocation<HistoryState>();
  const GUTTER: [Gutter, Gutter] = !isMobile
    ? [H_GUTTER, V_GUTTER]
    : [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

  const dispatch: AppDispatch = useDispatch();

  const unreadNotificationCount: number = notificationUnreadGrid.count || 0;
  const initialOrderBy: OrderBy = isFullAccess ? NormInitialOrderBy : userInitialOrderBy;

  useEffect(() => {
    if (isFullAccess) dispatch(newsNotificationsApi.getCreatedByList(customerId));
  }, [customerId, isFullAccess, dispatch]);

  const createdByOptions = createdByList || [];
  const [params, updateParams] = useFilter({
    apiCall: newsNotificationsApi.getNotificationGrid,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
    historyState,
  });

  const createNotification = () => {
    history.push(Routes.NOTIFICATIONS_CREATE, {
      backTo: {
        route: Routes.NOTIFICATIONS,
        search: history.location.search,
        title: `Back to ${backlinkRoutes[Routes.NOTIFICATIONS]}`,
      },
    });
  };

  const layoutConfig = getLayoutConfig(createNotification, historyState, isFullAccess);

  const getUsersTableContent = useCallback(() => {
    dispatch(newsNotificationsApi.getNotificationGrid(params));
  }, [dispatch, params]);

  const getActions = useCallback(
    (notificationDetails: Notification) => {
      return <MobileActions notification={notificationDetails} onSuccess={getUsersTableContent} />;
    },
    [getUsersTableContent],
  );

  const emptyMessage = (
    <EmptyTablePreview
      title={emptyGridMessage}
      loading={loading[newsNotificationsApi.getNotificationGrid.typePrefix]}
      withoutMarginTop
      actionButton={
        isFullAccess ? (
          <Button type='primary' onClick={() => createNotification()}>
            {UpdateNotification.CREATE}
          </Button>
        ) : undefined
      }
    />
  );

  const setReadNotification = useCallback(
    (isRead: boolean, notificationIds: string[]) => {
      dispatch(
        newsNotificationsApi.setReadNotification({
          customerId,
          isRead,
          requestBody: { notificationIds },
        }),
      ).then((result) => {
        if (result.meta.requestStatus === RequestStatus.Rejected) {
          showError();

          return;
        }

        dispatch(newsNotifications.actions.updatedNotificationGrid(notificationIds));
      });
    },
    [customerId, dispatch],
  );

  const handleTableClick = React.useCallback(
    (record: Notification) => {
      history.push(`${Routes.NOTIFICATIONS_DETAILS.replace(':id', record.id as string)}`, {
        backTo: {
          route: Routes.NOTIFICATIONS,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[Routes.NOTIFICATIONS]}`,
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [history, historyState],
  );

  const tableFilter = isFullAccess
    ? filterFieldsNormUser(createdByOptions || [])
    : filterFields(unreadNotificationCount);
  const tableColumn = isFullAccess
    ? getTableColumns(getNormUserColumns(getUsersTableContent, setReadNotification), params.orderBy)
    : getTableColumns(getColumns(getUsersTableContent, setReadNotification), params.orderBy);

  const columnsWithoutActions = useMemo(() => {
    return tableColumn.filter((column) => column.key !== ColumnKey.Actions);
  }, [tableColumn]);

  return (
    <InnerLayout {...layoutConfig}>
      {isMobile ? (
        <Row gutter={GUTTER}>
          <MobileTableCol span={FULL_WIDTH_COLS} flex='auto'>
            <MobileTable
              data={notificationGrid}
              searchSettings={searchSettings(isFullAccess)}
              columns={columnsWithoutActions}
              filterFields={tableFilter}
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              isLoadingContent={loading[newsNotificationsApi.getNotificationGrid.typePrefix]}
              getActions={isFullAccess ? getActions : undefined}
              emptyMessage={emptyMessage}
              onRowClick={handleTableClick}
            />
          </MobileTableCol>
        </Row>
      ) : (
        <Row gutter={GUTTER}>
          <Col span={FULL_WIDTH_COLS} xs={24} xxl={24}>
            <CustomTable
              data={notificationGrid}
              columns={tableColumn}
              params={params}
              setParams={updateParams}
              searchSettings={searchSettings(isFullAccess)}
              defaultPageSize={defaultPageSize}
              defaultCurrent={defaultCurrent}
              filterFields={tableFilter}
              isLoadingContent={loading[newsNotificationsApi.getNotificationGrid.typePrefix]}
              emptyMessage={emptyMessage}
              onRowClickHandle={handleTableClick}
            />
          </Col>
        </Row>
      )}
    </InnerLayout>
  );
};
