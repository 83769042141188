import { SERVICE_INTEGRATION } from 'pages/ServicesIntegrations/constants';
import { CredentialsResponseType, ServiceIntegrationType } from 'pages/ServicesIntegrations/types';
import { DefaultLayout, SentinelOneLayout } from './layouts';

export type CredentialsFactoryProps = {
  serviceName: ServiceIntegrationType;
  credentials: CredentialsResponseType;
};

export const ConnectionSettingsFactory = ({
  serviceName,
  credentials,
}: CredentialsFactoryProps) => {
  switch (serviceName) {
    case SERVICE_INTEGRATION.SENTINEL_ONE:
      return <SentinelOneLayout credentials={credentials} />;
    default:
      return <DefaultLayout credentials={credentials} />;
  }
};
