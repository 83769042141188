import React from 'react';
import { AlarmSchema } from 'services/api';
import { Link } from 'react-router-dom';
import { Routes } from 'services/entities';
import { emptyDataString } from 'app-constants';
import styled from 'styled-components';
import { TagColoring } from 'shared';
import { getCapitalizedString } from 'services/helpers';
import { useParams } from 'react-router';

type Props = {
  item: AlarmSchema;
  backRouteTitle: string;
};

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StatusWrapper = styled.div`
  position: relative;
`;

export function AlarmListItem({ item, backRouteTitle }: Props) {
  const { id } = useParams<{ id: string }>();

  return (
    <Wrapper>
      <Link
        to={{
          pathname: Routes.ALARM_DETAILS.replace(':id', item.id as string),
          state: {
            backTo: {
              route: Routes.SUPPORT_REQUESTS_DETAILS.replace(':id', id as string),
              title: `Back to ${backRouteTitle}`,
            },
          },
        }}
      >
        {item.name}
      </Link>
      <StatusWrapper>
        <>{item.risk ? <TagColoring title={getCapitalizedString(item.risk)} /> : emptyDataString}</>
      </StatusWrapper>
    </Wrapper>
  );
}
