import React, { useCallback } from 'react';
import moment from 'moment';
import { Box, Button } from 'shared';
import { CertificateName, CertificateStatusSchema } from 'services/api';
import { getCssVar, icons } from 'styles/theme';
import { useTheme } from 'styled-components';
import { ABOUT_TO_EXPIRE_MONTHS } from '../../constants';
import { CertificateFooterProp } from '../../types';
import { IconDownload, IconLeftArrow } from '../../styled';
import { isCertificateCertified, isStatusInProgress } from '../../helpers';

export const Footer = ({
  onDownload,
  onSelfSertify,
  onReorder,
  status,
  expirationDate,
  openFormWithPreparedData,
  title,
  certificateName,
  downloading,
  id,
  downloadName,
  vendorName,
  isDashboard,
  hasPermissionToBuyCertificate,
  setClickOn,
  clickOn,
}: CertificateFooterProp) => {
  const theme = useTheme();

  const currentDate = moment();
  const expiration = moment(expirationDate);

  const isCertificateSoonExpire =
    expiration && expiration.diff(currentDate, 'month') < ABOUT_TO_EXPIRE_MONTHS;

  const isStatusExpired = expiration.isBefore(currentDate);

  const isISOCertificate = title === CertificateName.ISO27001;

  const commonProps = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    mx: isDashboard ? theme.spacing[3] : undefined,
  };

  const isCertificateSelfCertified = status === CertificateStatusSchema.CERTIFIED && vendorName;

  const isStatusFailed = status === CertificateStatusSchema.FAILED;

  const isRenewalShown =
    hasPermissionToBuyCertificate &&
    ((id && !isStatusInProgress(status) && (isCertificateSoonExpire || isStatusExpired)) ||
      isStatusFailed);

  const isDefaultEmptyCertificate = !status && !id && !vendorName && !expirationDate;

  const isSelfCertifiedAndSoonExpired =
    isCertificateSelfCertified && (isCertificateSoonExpire || isStatusExpired);

  const isOrderShown =
    (isSelfCertifiedAndSoonExpired || isDefaultEmptyCertificate) &&
    !isISOCertificate &&
    hasPermissionToBuyCertificate;

  const onUpdateHandler = useCallback(() => {
    if (expirationDate) {
      openFormWithPreparedData(title || '', expirationDate, vendorName);
    }
  }, [expirationDate, openFormWithPreparedData, title, vendorName]);

  const onDownloadCertificate = () => {
    setClickOn?.('card');
    onDownload?.(certificateName || '');
  };

  const isUpdateShown =
    !id &&
    (isCertificateSoonExpire || isStatusExpired) &&
    (!isStatusInProgress(status) || (isStatusInProgress(status) && isStatusExpired)) &&
    hasPermissionToBuyCertificate;

  return (
    <Box
      d='flex'
      color={getCssVar('primary')}
      fontSize={theme.sizes[4]}
      fontWeight={theme.colorSet.link.fontWeight}
      justify='space-between'
      height='40px'
    >
      {isCertificateCertified(status) && downloadName && (
        <Button
          onClick={onDownloadCertificate}
          loading={downloading === certificateName && clickOn === 'card'}
          className='btn-table-action'
          type='link'
          icon={<IconDownload component={icons.download_small} />}
        >
          Download
        </Button>
      )}

      {isRenewalShown &&
        (!isStatusInProgress(status) || isStatusExpired) &&
        !isCertificateSelfCertified && (
          <Box {...commonProps} onClick={onReorder}>
            <Box
              color={getCssVar('primaryColor')}
              fontWeight={getCssVar('link.fontWeight')}
              mr={theme.spacing[2]}
            >
              {isStatusFailed ? 'Re-order' : 'Renewal'}
            </Box>
            <IconLeftArrow component={theme.icons.arrow_right_alt_small} />
          </Box>
        )}

      {!status && hasPermissionToBuyCertificate && (
        <Box {...commonProps} onClick={() => onSelfSertify(true, `${title} Self-certification`)}>
          {isDashboard ? (
            <Button type='primary'>Self-certify</Button>
          ) : (
            <>
              <Box
                color={getCssVar('primaryColor')}
                fontWeight={getCssVar('link.fontWeight')}
                mr={theme.spacing[2]}
              >
                Self-certify
              </Box>
              <IconLeftArrow component={theme.icons.arrow_right_alt_small} />
            </>
          )}
        </Box>
      )}

      {isUpdateShown && (
        <Box {...commonProps} onClick={onUpdateHandler}>
          <Box
            color={getCssVar('primaryColor')}
            fontWeight={getCssVar('link.fontWeight')}
            mr={theme.spacing[2]}
          >
            Update
          </Box>
          <IconLeftArrow component={theme.icons.arrow_right_alt_small} />
        </Box>
      )}

      {isOrderShown && (
        <Box {...commonProps} onClick={onReorder} fontWeight={theme.colorSet.link.fontWeight}>
          {isDashboard ? (
            <Button type='primary'>Order</Button>
          ) : (
            <>
              <Box
                color={getCssVar('primaryColor')}
                fontWeight={getCssVar('link.fontWeight')}
                mr={theme.spacing[2]}
              >
                Order
              </Box>
              <IconLeftArrow component={theme.icons.arrow_right_alt_small} />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
