import { Icon } from 'shared';
import { icons } from 'styles/theme';
import { useTheme } from 'styled-components';
import { AlarmStatuses } from './constants';

type IconColoringProps = {
  status: string;
};

export const IconColoring = ({ status }: IconColoringProps) => {
  const theme = useTheme();
  const statusType = (status: string) => {
    switch (status) {
      case AlarmStatuses.Closed:
        return theme.colors.yellow[100];
      default:
        return theme.colors.yellow[460];
    }
  };

  return (
    <Icon
      component={icons.fiber_manual_record}
      color={statusType(status)}
      dimension='10px'
      margin='0 8px 0 0'
    />
  );
};
