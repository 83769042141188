import { persistStorageKey } from 'store';

export const clearOldPersists = async () => {
  const keys = Object.keys(localStorage);

  keys.forEach((key: string) => {
    const isPersist = key.split(':')[0] === 'persist';

    if (!isPersist) {
      return;
    }

    const persistKey = key.split(':')[1];

    if (persistKey !== persistStorageKey) {
      localStorage.removeItem(key);
    }
  });
};
