import { createAsyncThunk } from '@reduxjs/toolkit';
import { data } from 'pages/Findings/data/data';
import { auditLogsData } from 'pages/FindingsVulnerabilityDetails/data/auditLogs';
import { details } from 'pages/FindingsVulnerabilityDetails/data/details';
import { ApiError } from 'services/api';
import { penetrationTestsData, PenetrationTestsDataRow } from 'pages/PenetrationTestsList/data';
import { findingsBySeverity, testVulnerabilities } from 'pages/PenetrationTestDetails/data';

const getPenetrationTests = createAsyncThunk('penetration-tests/get', async () => {
  try {
    return penetrationTestsData;
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});

const getFindingsData = createAsyncThunk('findings/get/data', async () => {
  try {
    return data;
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});

const getFindingsVulnerabilityDetails = createAsyncThunk(
  'findingsVulnerabilityDetails/get/details',
  async () => {
    try {
      return details;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getPenetrationTestDetails = createAsyncThunk(
  'penetration-test-details/get',
  async (testId: string) => {
    try {
      return penetrationTestsData.rows?.find((row: PenetrationTestsDataRow) => row.id === testId);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getFindingsBySeverity = createAsyncThunk(
  'penetration-test-details-findings-by-severity/get',
  async () => {
    try {
      return findingsBySeverity;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getFindingsVulnerabilityDetailsAuditLogs = createAsyncThunk(
  'findingsVulnerabilityDetailsAuditLogs/get/data',
  async () => {
    try {
      return auditLogsData;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTestDetailsVulnerabilitiesList = createAsyncThunk(
  'penetration-test-details-vulnerabilities/get',
  async () => {
    try {
      return testVulnerabilities;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const penetrationTestingApi = {
  getFindingsData,
  getFindingsVulnerabilityDetails,
  getFindingsVulnerabilityDetailsAuditLogs,
  getPenetrationTests,
  getPenetrationTestDetails,
  getFindingsBySeverity,
  getTestDetailsVulnerabilitiesList,
};
