import { Box } from 'shared';
import { useTheme } from 'styled-components';
import { getCssVar } from 'styles/theme';

export type StatusProp = {
  color: string | null;
  text: string;
  lowerCase?: boolean;
  customProps?: {
    [key: string]: string;
  };
};

export const Tag = ({ color, text, lowerCase, customProps = {} }: StatusProp) => {
  const theme = useTheme();

  return (
    <Box
      fontFamily={getCssVar('process.labelFontFamily')}
      fontWeight={theme.colorSet.process.labelWeight}
      fontSize={theme.sizes['3.4']}
      position='absolute'
      top={`-${theme.spacing['0.5']}`}
      left='32px'
      bgColor={color}
      color={theme.colors.white}
      py={theme.spacing['0.5']}
      px={theme.spacing['1.5']}
      borderRadius={theme.radius.sm}
      textTransform={
        lowerCase
          ? 'lowercase'
          : (getCssVar('process.labelUppercase') as 'capitalize' | 'lowercase' | 'uppercase')
      }
      {...customProps}
    >
      {text}
    </Box>
  );
};
