import { Routes } from 'services/entities';
import React, { RefObject } from 'react';
import { DropdownItem } from 'pages/Cmdb/components/DownloadDropdown';

export type MenuItemChildren = {
  path: Routes | string;
  name: string;
};

export type MenuItem = {
  path: Routes | string;
  name: string;
  icon: () => JSX.Element;
  children?: MenuItemChildren[];
};

export interface ICustomTitle {
  children: React.ReactNode;
}

export const DropdownType = 'dropdown';
export const ButtonType = 'button';
export const DownloadDocTypes = [DropdownType, ButtonType];

type TypeDownloadDocType = typeof DownloadDocTypes[number];

export interface IDownloadDoc {
  id: number;
  title: string;
  icon?: () => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDownload: (option?: any) => void;
  loading?: boolean;
  tooltip?: string;
  type?: TypeDownloadDocType;
  dropdownItems?: DropdownItem[];
}

export interface IUploadDoc {
  id: number;
  title: string;
  icon?: () => JSX.Element;
  onUpload?: (event: React.FormEvent<HTMLInputElement>) => void;
  loading?: boolean;
  tooltip?: string | React.ReactNode;
  inputRef?: RefObject<HTMLInputElement>;
  handleAttachButton?: () => void;
  accept?: string;
}

export interface IToggleButton {
  title: string;
  onChange: (checked: boolean) => void;
  isActive?: boolean;
  loading?: boolean;
  tooltipTitle?: string;
}
