/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForgotPasswordRequest } from '../models/ForgotPasswordRequest';
import type { ForgotPasswordResponse } from '../models/ForgotPasswordResponse';
import type { GetResetPasswordInfoResponse } from '../models/GetResetPasswordInfoResponse';
import type { GetTokenResponse } from '../models/GetTokenResponse';
import type { LoginRequest } from '../models/LoginRequest';
import type { LoginResponse } from '../models/LoginResponse';
import type { ProfileResponse } from '../models/ProfileResponse';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { SetTwoFARequest } from '../models/SetTwoFARequest';
import type { SetTwoFAResponse } from '../models/SetTwoFAResponse';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { ValidateTwoFARequest } from '../models/ValidateTwoFARequest';
import type { ValidateTwoFAResponse } from '../models/ValidateTwoFAResponse';
import { request as __request } from '../core/request';

export class AuthService {
  /**
   * Login user
   * Login user by email and password
   * @returns LoginResponse Create token to proceed login process with TwoFA
   * @throws ApiError
   */
  public static async login({
    requestBody,
  }: {
    /** Login information **/
    requestBody?: LoginRequest;
  }): Promise<LoginResponse> {
    const result = await __request({
      method: 'POST',
      path: `/login`,
      body: requestBody,
      errors: {
        400: `An error message when login user`,
      },
    });
    return result.body;
  }

  /**
   * Validate TwoFA code
   * Validate TwoFA code for user that set TwoFA
   * @returns ValidateTwoFAResponse Login user. Return auth (bearer) token and user
   * @throws ApiError
   */
  public static async validateTwofa({
    requestBody,
  }: {
    /** Validate code **/
    requestBody?: ValidateTwoFARequest;
  }): Promise<ValidateTwoFAResponse> {
    const result = await __request({
      method: 'POST',
      path: `/validate-twofa`,
      body: requestBody,
      errors: {
        400: `An error message when validate code`,
      },
    });
    return result.body;
  }

  /**
   * Set TwoFA
   * Set TwoFA for user
   * @returns SetTwoFAResponse Set TwoFA. Return url and secret for generation qr-code
   * @throws ApiError
   */
  public static async setTwofa({
    requestBody,
  }: {
    /** Set TwoFA **/
    requestBody?: SetTwoFARequest;
  }): Promise<SetTwoFAResponse> {
    const result = await __request({
      method: 'POST',
      path: `/set-twofa`,
      body: requestBody,
      errors: {
        400: `An error message when set TwoFA`,
      },
    });
    return result.body;
  }

  /**
   * Forgot password
   * Send reset link to user email
   * @returns ForgotPasswordResponse Return success result for any valid emails
   * @throws ApiError
   */
  public static async forgotPassword({
    requestBody,
  }: {
    /** Forgot password **/
    requestBody?: ForgotPasswordRequest;
  }): Promise<ForgotPasswordResponse> {
    const result = await __request({
      method: 'POST',
      path: `/forgot-password`,
      body: requestBody,
      errors: {
        400: `An error message when forgot password`,
      },
    });
    return result.body;
  }

  /**
   * Reset password
   * Reset password for user account by email
   * @returns SuccessResponse Reset user password. Send notification to user email
   * @throws ApiError
   */
  public static async resetPassword({
    resetLink,
    requestBody,
  }: {
    /** link from email to reset password **/
    resetLink: string;
    /** Reset password **/
    requestBody?: ResetPasswordRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/reset-password/${resetLink}`,
      body: requestBody,
      errors: {
        400: `An error message when reset password`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get reset password info
   * Get info before reset user password
   * @returns GetResetPasswordInfoResponse Reset user password. Send notification to user email
   * @throws ApiError
   */
  public static async getResetPasswordInfo({
    resetLink,
  }: {
    /** link from email to reset password **/
    resetLink: string;
  }): Promise<GetResetPasswordInfoResponse> {
    const result = await __request({
      method: 'GET',
      path: `/reset-password/${resetLink}`,
      errors: {
        400: `An error message when reset password`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * User token
   * Get new token
   * @returns GetTokenResponse Generate new token
   * @throws ApiError
   */
  public static async getNewToken(): Promise<GetTokenResponse> {
    const result = await __request({
      method: 'GET',
      path: `/get-new-token`,
      errors: {
        400: `An error message when get new token`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * User profile
   * Get user profile
   * @returns ProfileResponse Return user profile
   * @throws ApiError
   */
  public static async getProfile(): Promise<ProfileResponse> {
    const result = await __request({
      method: 'GET',
      path: `/get-profile`,
      errors: {
        400: `An error message when get profile`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
