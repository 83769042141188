import { createSlice } from '@reduxjs/toolkit';
import { MonthlyReportResponse } from 'services/api';
import { monthlyReportApi } from './thunks';

type MonthlyReportResponseState = {
  data: MonthlyReportResponse;
};

const initialState: MonthlyReportResponseState = {
  data: {},
};

type StateType = typeof initialState;

export const monthlyReport = createSlice({
  name: 'monthlyReport',
  initialState,
  reducers: {},
  extraReducers: {
    [monthlyReportApi.getMonthlyReport.fulfilled.toString()]: (
      state: StateType,
      action: { payload: MonthlyReportResponse },
    ) => {
      state.data = action.payload;
    },
    [monthlyReportApi.getMonthlyReport.rejected.toString()]: (state: StateType) => {
      state.data = {};
    },
  },
});
