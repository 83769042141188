import React, { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Col, Form, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { allowedExtensions, dplDocumentTypesOptions } from 'pages/DataProtectionLibrary/constants';
import { DPLCategory, DPLDocumentCategory, DPLDocumentType } from 'services/api';
import { DocumentStyled } from 'pages/Documents/components/Document/styled';
import { normalizeFileSize } from 'pages/Documents/helpers';
import { POPUP_WIDTH } from 'app-constants';
import { emptyFile, maxFileSizeLabel } from 'shared/UploadPopup/constants';
import { UploadPopupStyled } from 'shared/UploadPopup/styled';
import { Box, Button, Checkbox, DatePicker, Icon, Input, Select } from 'shared';
import { icons } from 'styles/theme';
import { useFormik } from 'formik';
import { SelectValue } from 'antd/lib/select';
import { FormItem, FormLabel } from './styled';

export type ValuesType = {
  documentName: string;
  documentCategory: DPLDocumentCategory;
  documentType: DPLDocumentType;
  createdDate: number | null;
  premium: boolean;
  essentialPlus: boolean;
};

const initialValues: ValuesType = {
  documentName: '',
  documentCategory: '' as DPLDocumentCategory,
  documentType: '' as DPLDocumentType,
  createdDate: null,
  premium: true,
  essentialPlus: true,
};

export type UploadPopupProps = {
  visible: boolean;
  categories: DPLCategory[];
  handleOk: (file: File, values: ValuesType) => void;
  setVisible: (state: boolean) => void;
  className?: string;
  uploadFileSize?: number;
};

export const UploadPopup = ({
  handleOk,
  visible,
  setVisible,
  categories,
  className,
  uploadFileSize,
}: UploadPopupProps) => {
  const [isFileAttached, setIsFileAttached] = React.useState(false);
  const [attachedFile, setAttachedFile] = React.useState<File>(emptyFile);
  const inputRef = React.createRef<HTMLInputElement>();

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleOk(attachedFile, values);
    },
  });

  const isUploadDisabled = useMemo(() => {
    return (
      !isFileAttached ||
      !values.createdDate ||
      !values.documentCategory ||
      !values.documentName ||
      !values.documentType
    );
  }, [values, isFileAttached]);

  const clearAttachedFile = useCallback(() => {
    setIsFileAttached(false);
    const input = inputRef.current;

    if (input) {
      input.value = '';
    }
  }, [setIsFileAttached, inputRef]);

  const clean = useCallback(() => {
    clearAttachedFile();
  }, [clearAttachedFile]);

  const handleCancel = useCallback(() => {
    clean();
    setVisible(false);
  }, [setVisible, clean]);

  const handleUploadButton = useCallback(() => {
    handleSubmit();
    handleCancel();
  }, [handleSubmit, handleCancel]);

  const disabledDate = (date: moment.Moment) => {
    return date > moment().endOf('day');
  };

  const handleFileChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const filesUploaded = event.currentTarget.files;

      if (filesUploaded) {
        setAttachedFile(filesUploaded[0]);
        setIsFileAttached(true);
      }
    },
    [setIsFileAttached, setAttachedFile],
  );

  const handleAttachButton = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  return (
    <UploadPopupStyled
      className='action-modal'
      title='Upload file'
      visible={visible}
      width={POPUP_WIDTH}
      onOk={handleUploadButton}
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          disabled={isUploadDisabled}
          onClick={handleUploadButton}
        >
          Upload file
        </Button>,
      ]}
    >
      <Col className={className}>
        <Space direction='vertical' size={4}>
          <FormLabel required>Document</FormLabel>
          {!isFileAttached ? (
            <Button
              onClick={handleAttachButton}
              type='link'
              icon={<Icon component={icons.attach_file} />}
            >
              Choose file
            </Button>
          ) : (
            <DocumentStyled file={attachedFile} clear={clearAttachedFile} />
          )}
          {!isFileAttached && (
            <span>Pdf, docx, xlsx, pptx, image (jpg, jpeg) files can be uploaded.</span>
          )}
          {!isFileAttached && (
            <span className='label'>
              Max size: {uploadFileSize ? normalizeFileSize(uploadFileSize) : maxFileSizeLabel}
            </span>
          )}
        </Space>
        <input
          hidden
          type='file'
          onChange={handleFileChange}
          ref={inputRef}
          accept={allowedExtensions}
        />
        <Form layout='vertical' className='mt' initialValues={initialValues}>
          <Box maxW='360px'>
            <FormLabel required>Document name</FormLabel>
            <Input
              type='text'
              maxLength={100}
              name='documentName'
              onChange={handleChange}
              value={values.documentName}
              placeholder='Input document name'
              required
            />
          </Box>
          <FormItem>
            <FormLabel required>Category</FormLabel>
            <Select
              options={categories.map((category: DPLCategory) => ({
                value: category.id || '',
                label: `${category.id} - ${category.title}`,
              }))}
              className='select'
              placeholder='Select category'
              onChange={(value: SelectValue) => setFieldValue('documentCategory', value)}
              value={values.documentCategory || undefined}
              suffixIcon={<Icon component={icons.arrow_drop_down} />}
            />
          </FormItem>
          <FormItem>
            <FormLabel required>Document type</FormLabel>
            <Select
              options={dplDocumentTypesOptions}
              className='select'
              placeholder='Select document type'
              onChange={(value: SelectValue) => setFieldValue('documentType', value)}
              value={values.documentType || undefined}
              suffixIcon={<Icon component={icons.arrow_drop_down} />}
            />
          </FormItem>
          <FormItem maxWidth='260px'>
            <FormLabel required>Created date</FormLabel>
            <DatePicker
              onChange={(value: Moment | null) =>
                setFieldValue('createdDate', value?.valueOf() || null)
              }
              disabledDate={disabledDate}
              value={values.createdDate ? moment(values.createdDate) : null}
            />
          </FormItem>
          <FormItem>
            <FormLabel required>Applicable product</FormLabel>
            <Box mt='8px'>
              <Checkbox
                checked={values.premium}
                value='premium'
                onChange={(e: CheckboxChangeEvent) => {
                  setFieldValue('premium', e.target.checked);
                  if (!e.target.checked) {
                    setFieldValue('essentialPlus', true);
                  }
                }}
              >
                Premium
              </Checkbox>
            </Box>

            <Box mt='8px'>
              <Checkbox
                checked={values.essentialPlus}
                value='essential'
                onChange={(e: CheckboxChangeEvent) => {
                  setFieldValue('essentialPlus', e.target.checked);
                  if (!e.target.checked) {
                    setFieldValue('premium', true);
                  }
                }}
              >
                Essential plus
              </Checkbox>
            </Box>
          </FormItem>
        </Form>
      </Col>
    </UploadPopupStyled>
  );
};
