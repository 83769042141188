import React, { useMemo } from 'react';
import { Theme } from 'styles/theme/types';
import {
  PatchVsConfigurationResponse,
  ReportTopMostVulnerableHostsItem,
  ReportTopVulnerabilitiesItem,
  VulnerabilityChangesPointSchema,
  VulnerabilityChartPointSchema,
} from 'services/api';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { getCapitalizedString } from 'services/helpers';
import { VulnerabilitiesTitles } from 'pages/VulnerabilityManagement/constants';
import {
  AllVulnerabilities,
  AverageTimeTitle,
  AverageTimeToRemediate,
  MultiColumnsWrapper,
  MustVulnerableHosts,
  PatchesVsConfiguration,
  PatchesVsConfigurationPieWrapper,
  TechnologyVulnerabilityManagementDescription,
  TechnologyVulnerabilityManagementPieChartWrapper,
  TopUserSystemModels,
  VulnerabilityManagementWrapper,
  Wrapper,
} from './styled';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { SegmentReportChartWithLegend } from '../../components/Charts/SegmentReportChartWithLegend';
import { MultiColumnsReportChart, Values } from '../../components/Charts/MultiColumnsReportChart';
import { totalPieFontSize, totalPieScoreSize } from '../config/chart';
import { CyberResilienceScoreDescriptionScoreMessage } from '../styled';

interface TechnologyVulnerabilityManagementProps {
  technologyScore: number | undefined | null;
  technologyRank: string;
  onChartLoaded: () => void;
  chart: VulnerabilityChangesPointSchema[];
  totalChange: number;
  averageDaysToRemediate: number;
  theme: Theme;
  mustVulnerableHosts?: Array<ReportTopMostVulnerableHostsItem>;
  topVulnerabilities?: Array<ReportTopVulnerabilitiesItem>;
  patchVsConfiguration?: PatchVsConfigurationResponse;
  suppressedVulnerabilities?: number;
}

const SuppressedVulnerabilities = ({ vulnerabilitiesCount }: { vulnerabilitiesCount?: number }) => {
  if (!vulnerabilitiesCount) {
    return null;
  }

  return (
    <>
      <br />
      {`Excluding ${vulnerabilitiesCount} suppressed vulnerabilities`}
    </>
  );
};

export function PdfPage7({
  technologyScore,
  technologyRank,
  onChartLoaded,
  chart,
  totalChange,
  averageDaysToRemediate,
  theme,
  patchVsConfiguration = { patch: 0, configuration: 0 },
  mustVulnerableHosts = [],
  topVulnerabilities = [],
  suppressedVulnerabilities,
}: TechnologyVulnerabilityManagementProps) {
  const allVulnerabilitiesValue = useMemo<Values>(() => {
    return [...chart]
      .sort((valueA: VulnerabilityChartPointSchema, valueB: VulnerabilityChartPointSchema) => {
        if (valueA.criticality && valueB.criticality) {
          return Number(valueB.criticality) - Number(valueA.criticality);
        }

        return 0;
      })
      .reduce<Values>((acc, value: VulnerabilityChangesPointSchema) => {
        if (!value.criticality || !value.count) {
          return { ...acc };
        }

        const key = getSeverityLevelName(value.criticality);

        return {
          ...acc,
          [key]: { value: value.count, previousDifference: value.previousDifference },
        };
      }, {});
  }, [chart]);

  const isNegative = totalChange > 0;

  return (
    <Wrapper>
      <TechnologyVulnerabilityManagementDescription>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(technologyRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt.Oviti cullanis qui bernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrum que neceari opta sinis.
          <SuppressedVulnerabilities vulnerabilitiesCount={suppressedVulnerabilities} />
        </p>
      </TechnologyVulnerabilityManagementDescription>
      <TechnologyVulnerabilityManagementPieChartWrapper>
        <GradientPieReport
          score={technologyScore}
          height={totalPieScoreSize}
          width={totalPieScoreSize}
          onFinished={onChartLoaded}
          fontSize={totalPieFontSize}
        />
      </TechnologyVulnerabilityManagementPieChartWrapper>

      <PatchesVsConfiguration>Patches vs configuration</PatchesVsConfiguration>
      <AllVulnerabilities>All Vulnerabilities</AllVulnerabilities>

      <AverageTimeTitle>
        Average time to remediate urgent and critical vulnerabilities:
      </AverageTimeTitle>

      <AverageTimeToRemediate>
        <span>{`${averageDaysToRemediate}`}</span> {`day${averageDaysToRemediate !== 1 ? 's' : ''}`}
      </AverageTimeToRemediate>

      <PatchesVsConfigurationPieWrapper>
        <SegmentReportChartWithLegend
          values={patchVsConfiguration}
          theme={theme}
          colors={{ configuration: theme.colors.purple[410], patch: theme.colors.blue[100] }}
          height='200px'
          width='240px'
          entitiesName=' '
          onChartLoaded={onChartLoaded}
        />
      </PatchesVsConfigurationPieWrapper>

      <MultiColumnsWrapper>
        <MultiColumnsReportChart
          onChartLoaded={onChartLoaded}
          theme={theme}
          values={allVulnerabilitiesValue}
          height='250px'
          width='374px'
        />

        <VulnerabilityManagementWrapper>
          Changes this month:{' '}
          <span className={`${isNegative ? 'negative' : 'positive'}`}>{`${
            isNegative ? '+' : ''
          }${totalChange}`}</span>
        </VulnerabilityManagementWrapper>
      </MultiColumnsWrapper>
      <MustVulnerableHosts>
        <h4>{VulnerabilitiesTitles.TopFiveMostVulnerableHosts}</h4>
        <ul>
          {mustVulnerableHosts.map(({ id, hostname }, index) => (
            <li key={id}>{`${index + 1} ${hostname}`}</li>
          ))}
        </ul>
      </MustVulnerableHosts>
      <TopUserSystemModels>
        <h4>{VulnerabilitiesTitles.TopFiveVulnerabilities}</h4>
        <ul>
          {topVulnerabilities.map(({ id, title }, index) => (
            <li key={id}>{`${index + 1} ${title}`}</li>
          ))}
        </ul>
      </TopUserSystemModels>
    </Wrapper>
  );
}
