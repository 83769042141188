import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide3.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
  font-size: 13px;
`;

export const CyberResilienceScoreDescription = styled.div`
  font-size: 13px;
  left: 90px;
  max-width: 855px;

  position: absolute;
  top: 125px;
`;

export const CyberResilienceScoreProcessDescription = styled.div`
  left: 90px;
  max-width: 215px;
  position: absolute;

  top: 345px;
`;

export const CyberResilienceScorePeopleDescription = styled.div`
  left: 420px;
  max-width: 215px;
  position: absolute;

  top: 345px;
`;

export const CyberResilienceScoreTechnologyDescription = styled.div`
  left: 755px;
  max-width: 215px;
  position: absolute;

  top: 345px;
`;

export const ProcessPieWrapper = styled.div`
  left: 270px;
  overflow: hidden;
  position: absolute;
  top: 275px;
`;

export const PeoplePieWrapper = styled.div`
  left: 602px;
  overflow: hidden;
  position: absolute;
  top: 275px;
`;

export const TechnologyPieWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  right: 60px;
  top: 275px;
`;

export const CenteredIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
`;
