import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Tooltip } from 'antd';
import { useGetPermissions } from 'services/hooks';
import { Routes } from 'services/entities';
import { backlinkRoutes, PermissionOptions, PermissionsLevel } from 'app-constants';
import {
  AppDispatch,
  cybersafetyApi,
  useApp,
  useCurrentTrainingStatus,
  useCustomer,
  usePhishingLastThirtyDays,
} from 'store';
import { Icon } from 'shared';
import { SegmentedPie } from 'shared/charts';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { getQueryString } from 'services/api/core/request';
import { getScore, isEmptyObject } from 'services/helpers';
import { icons, sizes } from 'styles/theme';
import {
  getChartValues,
  getRedirectParams as getRedirectParamsFromPhishing,
  getTotalClickRedirectParams,
} from 'pages/People/components/PhishingLastThirtyDays/helpers';
import { getRedirectParams as getRedirectParamsFromTraining } from 'pages/People/components/CurrentTrainingStatus/helpers';
import { useTheme } from 'styled-components';
import {
  ChartContainer,
  ChartsContainer,
  EmailsChartContainer,
  PeopleContainer,
  UsersChartConteiner,
} from './styled/styled';
import { TooltipTitles, viewMoreConfig } from '../constants';
import { PeopleStyledCard } from '../styled';
import { emptyMessage } from '../../../shared/Table/EmptyTablePreview/constants';
import { Values } from '../../../shared/charts/types';

interface PeopleProps {
  direction: 'row' | 'column';
  isLoading?: boolean;
  isEmpty?: boolean;
  score?: number;
  scoreLoading?: boolean;
}

export const People = ({ direction, isLoading, isEmpty, score, scoreLoading }: PeopleProps) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const customerId = useCustomer();
  const { isMobile, isMenuCollapsed } = useApp();
  const currentTrainingStatus = useCurrentTrainingStatus();
  const phishingLastThirtyDays = usePhishingLastThirtyDays();
  const historyState = React.useMemo(
    () => ({
      backTo: {
        route: `${Routes.DASHBOARD}`,
        title: `Back to ${backlinkRoutes[Routes.DASHBOARD]}`,
      },
    }),
    [],
  );

  const trainingColors = {
    completed: theme.colorSet.pieChart.CurrentTrainingStatusSuccess,
    started: theme.colorSet.pieChart.CurrentTrainingStatusStarted,
    notStarted: theme.colorSet.pieChart.CurrentTrainingStatusNotStarted,
  };

  const phishingLastThirtyDaysColors = {
    noActions: theme.colorSet.pieChart.CurrentTrainingStatusSuccess,
    opened: theme.colorSet.pieChart.CurrentTrainingStatusStarted,
    clicked: theme.colorSet.pieChart.CurrentRefresherTestsFailed,
    providedCredentialsOrDownloadedAnAttachment:
      theme.colorSet.pieChart.PhishingLast30DaysProvidedCredentials,
  };

  const handleTrainingChartClick = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      history.push(
        `${Routes.PEOPLE_PHISHING}${getQueryString(getRedirectParamsFromTraining(params.name))}`,
        historyState,
      );
    },
    [history, historyState],
  );

  const handlePhishingChartTotalClick = () => {
    history.push(`${Routes.PEOPLE_PHISHING}${getQueryString(getTotalClickRedirectParams())}`);
  };

  const handleTrainingChartTotalClick = () => {
    history.push(`${Routes.PEOPLE_PHISHING}`);
  };

  const handlePhishingChartClick = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      history.push(
        `${Routes.PEOPLE_PHISHING}${getQueryString(getRedirectParamsFromPhishing(params.name))}`,
        historyState,
      );
    },
    [history, historyState],
  );

  const permission = useGetPermissions([
    PermissionOptions.CybersafetyAndPhishing,
    PermissionOptions.SupportRequests,
  ]);

  React.useEffect(() => {
    dispatch(cybersafetyApi.getCurrentTrainingStatus({ customerId }));
    dispatch(
      cybersafetyApi.getPhishingLastThirtyDays({
        customerId,
        timezoneOffset: new Date().getTimezoneOffset(),
      }),
    );
  }, [dispatch, customerId]);

  return (
    <PeopleStyledCard
      title='People'
      valueMini={getScore(score)}
      isValueMiniLoading={scoreLoading}
      isHiddable={isMobile}
      viewMore={
        permission.cybersafetyAndPhishing !== PermissionsLevel.NoAccess
          ? viewMoreConfig.people
          : undefined
      }
      getAccessMessage={
        permission.cybersafetyAndPhishing === PermissionsLevel.NoAccess &&
        permission.supportRequests === PermissionsLevel.Full
          ? {
              text: 'Order Cyber Safety & Phishing',
              href: `${Routes.SERVICE_REQUEST}?type=purchase&product=Cyber+Safety+%26+Phishing`,
              layout: isMobile ? 'column' : 'inline',
            }
          : undefined
      }
      showLoader={isLoading}
      tooltipTitle={TooltipTitles.People}
      headStyles={
        !isMobile
          ? { pb: `${theme.spacing[4]}` }
          : { pb: `${theme.spacing[0]}`, titlePaddingRight: `${theme.spacing[0]}` }
      }
      bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
      cardPadding={
        !isMobile
          ? `${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[6]}`
          : `${theme.spacing[1]} ${theme.spacing[4]} ${theme.spacing[1]} ${theme.spacing[4]}`
      }
      showDivider={!isMobile}
    >
      <PeopleContainer>
        <ChartsContainer>
          <ChartContainer>
            <span>
              Current training status
              <Tooltip title={TooltipTitles.TrainingStatus} placement='right'>
                <Icon component={icons.info_outline} dimension={sizes[5]} className='tooltipInfo' />
              </Tooltip>
            </span>
            {!isEmptyObject(currentTrainingStatus) ? (
              <UsersChartConteiner>
                <SegmentedPie
                  values={currentTrainingStatus}
                  isEmpty={isEmpty}
                  isLoading={isLoading}
                  colors={trainingColors}
                  entitiesName='users'
                  direction={direction}
                  isMobile={isMobile}
                  isMenuCollapsed={isMenuCollapsed}
                  onEvents={{
                    click: handleTrainingChartClick,
                  }}
                  totalClickHandler={handleTrainingChartTotalClick}
                  style={{
                    height: isMobile ? '260px' : '290px',
                  }}
                />
              </UsersChartConteiner>
            ) : (
              emptyMessage('No training status data yet')
            )}
          </ChartContainer>
          <ChartContainer>
            <span>
              Phishing: last 30 days
              <Tooltip title={TooltipTitles.Phishing} placement='right'>
                <Icon component={icons.info_outline} dimension={sizes[5]} className='tooltipInfo' />
              </Tooltip>
            </span>
            {phishingLastThirtyDays.details && !isEmptyObject(phishingLastThirtyDays.details) ? (
              <EmailsChartContainer>
                <SegmentedPie
                  values={getChartValues(phishingLastThirtyDays) as Values}
                  isEmpty={isEmpty}
                  colors={phishingLastThirtyDaysColors}
                  entitiesName='emails'
                  isLoading={isLoading}
                  direction={direction}
                  isMobile={isMobile}
                  isMenuCollapsed={isMenuCollapsed}
                  onEvents={{
                    click: handlePhishingChartClick,
                  }}
                  totalClickHandler={handlePhishingChartTotalClick}
                  style={{
                    height: isMobile ? '260px' : '290px',
                  }}
                />
              </EmailsChartContainer>
            ) : (
              emptyMessage('No phishing data yet')
            )}
          </ChartContainer>
        </ChartsContainer>
      </PeopleContainer>
    </PeopleStyledCard>
  );
};
