import styled from 'styled-components';

export const LoadingLabelContainer = styled.span`
  cursor: default;

  .first {
    animation: dot 1.3s infinite;
    animation-delay: 0s;
    opacity: 0;
  }

  .second {
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
    opacity: 0;
  }

  .third {
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
    opacity: 0;
  }

  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
