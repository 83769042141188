import React from 'react';
import { Icon, Button } from 'shared';
import { icons } from 'styles/theme';
import { DownloadContainer } from '../../styled';

interface IProps {
  name: string;
  downloadCertificate: (name: string) => void;
  downloading: string;
  setClickOn: (clickOn: string) => void;
}

export const DownloadComponent = ({
  name,
  downloadCertificate,
  downloading,
  setClickOn,
}: IProps) => {
  const download = () => {
    setClickOn('table');
    downloadCertificate(name);
  };

  return (
    <DownloadContainer d='flex' justify='flex-end' align='center'>
      <Button
        onClick={download}
        loading={downloading === name}
        type='link'
        icon={<Icon component={icons.download} />}
      >
        Download
      </Button>
    </DownloadContainer>
  );
};
