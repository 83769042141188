import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  processApi,
  supportRequestsApi,
  useApp,
  useCustomer,
  useLoading,
  useProcess,
  useSupportRequests,
} from 'store';
import { Row } from 'antd';
import moment from 'moment';
import { RequestStatus } from 'store/loading/slice';
import { EmptyTablePreview, InnerLayout, MobileTable, MobileTableCol } from 'shared';
import { GridRequestParams, SupportRequestsType } from 'services/api';
import { getTableColumns, showError } from 'services/helpers';
import { downloadCSV } from 'services/helpers/downloadCSV';
import {
  dateFormatHours,
  defaultCurrent,
  defaultPageSize,
  GUTTER,
  PermissionOptions,
  PermissionsLevel,
} from 'app-constants';
import { Routes } from 'services/entities';
import { useGetPermissions } from 'services/hooks';
import { CustomTable } from 'shared/Table/CustomTable';
import { useLocation } from 'react-router-dom';
import { useFilter } from 'services/hooks/useFilter';
import { HistoryState } from 'services/interfaces';
import {
  baseFilterFields,
  extendedFilterFields,
  initialOrderBy,
  searchSettings,
} from './constants';
import { columns } from './components/TableColumns';
import { getLayoutConfig } from './helpers/helpers';
import { getEmptyStateText } from './helpers/getEmptyStateText';
import { SupportRequestsRow } from './types';

export const SupportRequests = () => {
  const permissions = useGetPermissions([
    PermissionOptions.SupportRequests,
    PermissionOptions.ThreatDetection,
  ]);

  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();
  const { customerProductsList } = useProcess();
  const { isMobile, isDesktop } = useApp();
  const history = useHistory();
  const { state: historyState } = useLocation<HistoryState>();

  const { data } = useSupportRequests();

  const loading = useLoading([
    supportRequestsApi.supportTableContent.typePrefix,
    supportRequestsApi.exportCSV.typePrefix,
    processApi.getProducts.typePrefix,
  ]);

  const loadingTable = loading[supportRequestsApi.supportTableContent.typePrefix];

  const [params, updateParams] = useFilter({
    apiCall: supportRequestsApi.supportTableContent,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
    page: 'SUPPORT_REQUESTS',
    historyState,
  });

  const onDownloadCSV = async () => {
    const result = await dispatch(supportRequestsApi.exportCSV(params));

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      showError();
    }

    downloadCSV(result.payload, `Support requests ${moment().format(dateFormatHours)}`);
  };

  const isShowNewSupportRequestButton =
    permissions.supportRequests === PermissionsLevel.Full && Boolean(customerProductsList?.length);

  const isButtonLoading = loading[processApi.getProducts.typePrefix];

  const layoutConfig = getLayoutConfig(
    isShowNewSupportRequestButton,
    isButtonLoading,
    onDownloadCSV,
    loading[supportRequestsApi.exportCSV.typePrefix],
    isDesktop,
    historyState,
  );

  const redirectToSupportRequestDetails = React.useCallback(
    (record: SupportRequestsRow) => {
      history.push(`${Routes.SUPPORT_REQUESTS}/${record.recId}`, {
        backTo: {
          route: Routes.SUPPORT_REQUESTS,
          search: history.location.search,
          title: 'Back to Support requests',
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [history, historyState],
  );

  const emptyMessage = <EmptyTablePreview title='No service requests yet' loading={loadingTable} />;

  useEffect(() => {
    dispatch(processApi.getProducts({ customerId }));
  }, [dispatch, customerId]);

  const getFilterFields = useMemo(() => {
    const hideSecurityIncidentFilter = extendedFilterFields.map((filter) => {
      if (filter.field === 'type') {
        const securityIncidentFilter = filter.options?.filter(
          (option) => option.label !== SupportRequestsType.SECURITY_INCIDENT,
        );

        return { ...filter, options: securityIncidentFilter };
      }

      return filter;
    });

    const hasThreatDetections =
      permissions.supportRequests === PermissionsLevel.NoAccess &&
      permissions.threatDetection !== PermissionsLevel.NoAccess;
    const hasSupportRequest =
      permissions.supportRequests !== PermissionsLevel.NoAccess &&
      permissions.threatDetection === PermissionsLevel.NoAccess;

    if (hasSupportRequest) {
      return hideSecurityIncidentFilter;
    }

    if (hasThreatDetections) {
      return baseFilterFields;
    }

    return extendedFilterFields;
  }, [permissions.supportRequests, permissions.threatDetection]);

  return (
    <InnerLayout {...layoutConfig}>
      {!isMobile ? (
        <CustomTable<SupportRequestsRow, GridRequestParams>
          data={data}
          columns={getTableColumns(columns, params.orderBy)}
          params={params}
          setParams={updateParams}
          searchSettings={searchSettings}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          filterFields={getFilterFields}
          onRowClickHandle={redirectToSupportRequestDetails}
          isLoadingContent={loadingTable}
          customEmptyMessageText={loadingTable ? {} : getEmptyStateText(params)}
          emptyMessage={emptyMessage}
        />
      ) : (
        <Row gutter={GUTTER}>
          <MobileTableCol flex='auto'>
            <MobileTable<SupportRequestsRow, GridRequestParams>
              data={data}
              columns={columns}
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              searchSettings={searchSettings}
              filterFields={getFilterFields}
              onRowClick={redirectToSupportRequestDetails}
              isLoadingContent={loading[supportRequestsApi.supportTableContent.typePrefix]}
              customEmptyMessageText={loadingTable ? {} : getEmptyStateText(params)}
              emptyMessage={emptyMessage}
            />
          </MobileTableCol>
        </Row>
      )}
    </InnerLayout>
  );
};
