import { FindingsVulnerabilitySchema } from './types';

/* eslint-disable */

export const details: FindingsVulnerabilitySchema = {
  id: '1',
  title: 'Web Application – Customer Portal',
  severity: '4',
  test: 'Web Application – Customer Portal',
  radarValues: [20, 10, 5, 16, 45],
  status: 'Assigned',
  assigned: {
    name: 'Alan Richardson',
    email: 'a.richardson@normcyber.com',
  },
  startDate: 1643806659098,
  endDate: 1643806659098,
  updated: 1643806659098,
  totalScore: 10,
  start: 'Network',
  summary:
    'The company logo file upload when users are registering for a one-day demo allows users to upload any file to the server. The application and presumably  the file upload is reviewed before the user is granted access to the demo, this could allow an attacker to upload malware onto the server resulting potentially in full control of the system',
  affectedHosts: [
    'abcltdcustomerportal.co.uk',
    'abcltdcustomerportal.co.uk1',
    'abcltdcustomerportal.co.uk2',
    'abcltdcustomerportal.co.uk3',
  ],
  remediation:
    'Implement file type validation on the back-end so that any non-png files are rejected',
  references: [
    'https://www.sans.org/blog/8-basic-rules-to-implement-secure-file-uploads',
    'https://www.opswat.com/blog/file-upload-protection-best-practices',
  ],
  testScope: {
    text:
      'NormCyber Limited was contracted to perform a web application test for ABC Limited. The target scope is defined as followed:',
    list: ['abcltdcustomerportal.co.uk'],
  },
  comments: [
    {
      message:
        'NormCyber Limited was contracted to perform a web application test for ABC Limited. Fix scheduled for release 22/03/21 20:00 hrs',
      name: 'Alan Richardson',
      date: 1643806659098,
    },
    {
      message: 'Fix scheduled for release 22/03/21 20:00 hrs',
      name: 'DoeJohn',
      date: 1643806659098,
    },
    {
      message:
        'NormCyber Limited was contracted to perform a web application test for ABC Limited. ',
      name: 'Alan Richardson',
      date: 1643806659098,
    },
    {
      message:
        'NormCyber Limited was contracted to perform a web application test for ABC Limited. Fix scheduled for release 22/03/21 20:00 hrs',
      name: 'Alan Richardson',
      date: 1643806659098,
    },
  ],
};
