import styled from 'styled-components';
import { breakpoints, fontWeights, getCssVar } from 'styles/theme';
import { Theme } from 'styles/theme/types';

type SubTitleStyledProps = {
  paddingRight?: string;
  isClickable?: boolean;
};

type ValueProp = {
  customText?: string;
  isLightTextExist?: boolean;
  theme: Theme;
};

export const SubTitleStyled = styled.div<SubTitleStyledProps>`
  align-items: flex-end;
  color: ${getCssVar('textColor')};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  width: fit-content;

  @media (max-width: ${breakpoints.md}) {
    margin-right: ${({ paddingRight }) => paddingRight};
  }

  &.exclude-filter-icon {
    justify-content: flex-end;
    width: 100%;

    & > div {
      margin-top: 2px;
    }
    span {
      margin-right: 0;
    }
  }

  .lock {
    margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[2]};

    > svg {
      filter: ${({ theme }: { theme: Theme }) => `drop-shadow(${theme.spacing.px} ${
        theme.spacing.px
      } ${theme.spacing[0]} ${getCssVar('gradientPieContainer.iconDropShadow')})
          drop-shadow(${theme.spacing[1]} ${theme.spacing[1]} ${theme.spacing[2]} ${getCssVar(
        'gradientPieContainer.iconDropShadowSecond',
      )})`};
    }
  }

  span:first-child {
    font-size: 36px;
    line-height: 32px;

    @media (max-width: ${breakpoints.md}) {
      font-size: 28px;
    }
  }

  span:nth-child(2) {
    align-items: center;
    display: flex;
    margin-top: 5px;
    @media (max-width: ${breakpoints.sm}) {
      margin-top: 0;
    }
  }

  .ant-space-item {
    height: auto;
  }
`;

export const LightTextContainer = styled.div`
  align-items: center;
  color: ${getCssVar('lightTextContainer.color')};
  display: inline-flex;
  font-size: 15px;
  font-weight: ${fontWeights.normal};
  margin-top: 6px;
  text-align: end;

  .anticon {
    height: auto;
    margin-right: 5px;
    width: auto;
  }

  @media (max-width: ${breakpoints.sm}) {
    display: flex;
    align-items: center;
    margin-top: -2px;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
`;

export const Period = styled.div``;

export const ValueContainer = styled.div<ValueProp>`
  color: ${getCssVar('textColor')};
  display: inline-flex;

  ${Period} {
    color: ${getCssVar('textColor')};
    margin-left: ${({ customText }) => (customText ? '0' : '3px')};
  }

  @media (max-width: ${breakpoints.sm}) {
    display: ${({ isLightTextExist }) => (isLightTextExist ? 'inline-flex' : 'block')};
  }
`;
