import { useEffect, useState } from 'react';
import { fetchFlags } from './helpers';
import { FeatureFlagContext } from './context';

export const FeatureFlagProvider = ({ children }) => {
  const [flags, setFlags] = useState({});

  useEffect(() => {
    fetchFlags().then((flags) => {
      setFlags(flags);
    });
  }, []);

  // eslint-disable-next-line no-console
  console.log('FeatureFlagProvider', flags);

  return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>;
};
