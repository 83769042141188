import { useCallback } from 'react';
import { backlinkRoutes } from 'app-constants/routes';
import { useHistory } from 'react-router';
import { Routes } from 'services/entities';
import { ServiceRequestType } from '../../../SupportRequestForm/constants';
import { CertificateBodyProp, CertificateFooterProp, CertificateHeadProp } from '../../types';
import { Divider } from '../../styled';
import { Head } from './Head';
import { Body } from './Body';
import { Footer } from './Footer';

type Props = CertificateHeadProp & CertificateBodyProp & CertificateFooterProp;

export const Certificate = ({
  title,
  status,
  id,
  lastUpdated,
  expirationDate,
  onDownload,
  onSelfSertify,
  openFormWithPreparedData,
  certificateName,
  downloading,
  downloadName,
  vendorName,
  hasPermissionToBuyCertificate,
  setClickOn,
  clickOn,
}: Props) => {
  const history = useHistory();

  const redirectToSupportRequest = useCallback(() => {
    history.push(
      `${Routes.SERVICE_REQUEST}?type=${ServiceRequestType.Purchase}&certificate=${title}`,
      {
        backTo: {
          route: Routes.PROCESS,
          title: `Back to ${backlinkRoutes[Routes.PROCESS]}`,
        },
      },
    );
  }, [history, title]);

  return (
    <>
      <Head
        title={title}
        lastUpdated={lastUpdated}
        status={status}
        expirationDate={expirationDate}
      />
      <Divider />
      <Body expirationDate={expirationDate} status={status} vendorName={vendorName} />
      <Footer
        onDownload={onDownload}
        onSelfSertify={onSelfSertify}
        status={status}
        expirationDate={expirationDate}
        onReorder={redirectToSupportRequest}
        title={title}
        openFormWithPreparedData={openFormWithPreparedData}
        certificateName={certificateName}
        downloading={downloading}
        id={id}
        downloadName={downloadName}
        vendorName={vendorName}
        hasPermissionToBuyCertificate={hasPermissionToBuyCertificate}
        setClickOn={setClickOn}
        clickOn={clickOn}
      />
    </>
  );
};
