import { colors } from '../colors';
import { icons } from '../icons';

export const leftMenu = {
  iconColors: colors.gray[410],
  activeSubmenuColor: colors.pink[500],
  submenuClose: colors.gray[810],
  unActiveSubmenuColor: colors.white,
  unActiveSubmenuIcon: colors.gray[410],
  activeSubmenuSelectedColor: colors.gray[810],
  headerColor: colors.black,
  logoTop: 'menu-logo-top-nasstar.svg',
  logoTopSmall: 'menu-logo-top-small-nasstar.svg',
  arrowColor: colors.gray[510],
  logoTopWidth: '189',
  logoTopWidthCollapsed: '26',
  logoTopHeight: '29',
  logoTopHeightCollapsed: '24',

  logoBottom: icons.poweredByNormMenuLogo,
  logoBottomCollapsed: icons.poweredByNormMenuLogoCollapsed,

  menuDrawerColor: colors.black,

  btnReportIncidentColor: colors.white,

  iconCloseColor: colors.gray[510],

  iconHoverSelectedColor: colors.pink[500],

  drawerColor: colors.blue[910],

  avatarColor: colors.black,

  userNameColor: colors.white,

  userAvatarColor: colors.pink[500],

  logoutColor: colors.gray[530],

  drawerFooterLogo: icons.drawerFooterLogo,
};
