import { ColumnsType } from 'antd/es/table/interface';
import { CVSSTableRow } from './types';

export const getColumns = (): ColumnsType<CVSSTableRow> => [
  {
    key: 'metricValue',
    title: 'Metric Value',
    dataIndex: 'metricValue',
  },
  {
    key: 'displayed',
    title: 'Displayed as',
    dataIndex: 'displayed',
  },
];
