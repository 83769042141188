import { spacing } from './spacing';

export const sizes = {
  ...spacing,
  pageWidth: '100vw',
  pageHeight: '100vh',
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '3xs': '14rem', // 224px
  '2xs': '16rem', // 256px
  xs: '20rem', // 320px
  sm: '23rem', // 368px
  md: '28rem', // 448px
  lg: '32rem', // 512px
  xl: '36rem', // 576px
  '2xl': '42rem', // 672px
  '3xl': '48rem', // 768px
  '4xl': '56rem', // 896px
  '5xl': '64rem', // 1024px
  '6xl': '72rem', // 1152
  '7xl': '80rem', // 1280px
  '8xl': '90rem', // 1440px
  container: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};
