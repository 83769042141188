import { createSlice } from '@reduxjs/toolkit';
import { SecuritySettingsResponse } from 'services/api';
import { securitySettingsApi } from './thunks';

type SecuritySettingsState = {
  settings: SecuritySettingsResponse;
};

const initialState: SecuritySettingsState = {
  settings: {} as SecuritySettingsResponse,
};

export const securitySettings = createSlice({
  name: 'securitySettings',
  initialState,
  reducers: {},
  extraReducers: {
    [securitySettingsApi.securitySettingsContent.rejected.toString()]: (
      state: SecuritySettingsState,
    ) => {
      state.settings = {};
    },
    [securitySettingsApi.securitySettingsContent.fulfilled.toString()]: (
      state: SecuritySettingsState,
      action: { payload: SecuritySettingsResponse },
    ) => {
      state.settings = action.payload;
    },
  },
});
