import { MutableRefObject, useEffect, useState } from 'react';

const widthOfCollapsedSider = 90;
const widthOfUnCollapsedSidebar = 285;
const widthOfCollapsedSiderWithScrollbar = 105;
const timeOut = 50;

export function useSiderWidth(element: MutableRefObject<HTMLDivElement | null>) {
  const [collapsedWidth, setCollapsedWidth] = useState<number>(widthOfCollapsedSider);

  useEffect(() => {
    const handler = () => {
      setTimeout(() => {
        if (element.current && element.current?.offsetWidth > element.current?.clientWidth) {
          setCollapsedWidth(widthOfCollapsedSiderWithScrollbar);

          return;
        }

        setCollapsedWidth(widthOfCollapsedSider);
      }, timeOut);
    };

    if (element) {
      handler();
    }

    window.addEventListener('resize', () => {
      handler();
    });

    return () => {
      window.removeEventListener('resize', () => {
        handler();
      });
    };
  }, [element]);

  return [collapsedWidth, widthOfUnCollapsedSidebar];
}
