import moment from 'moment';
import { VulnerabilityTrendPointSchema, VulnerabilityTrendPointTypeSchema } from 'services/api';
import { formatDate } from 'services/helpers';
import { IDate } from 'services/interfaces';
import { Theme } from '../../../../styles/theme/types';

export const getData = (entries: VulnerabilityTrendPointSchema[]) => {
  const seriesData: number[] = [];
  const xAxisData: number[] = [];

  entries.map((entry: VulnerabilityTrendPointSchema) => {
    if (entry?.value === undefined || entry?.date === undefined) {
      return entry;
    }

    seriesData.push(entry?.value || 0);
    xAxisData.push(entry?.date || 0);

    return entry;
  });

  if (!seriesData.length) {
    return [[0], xAxisData];
  }

  return [seriesData, xAxisData];
};

export const getOption = ({
  values,
  theme,
}: {
  values: {
    trend?: {
      pointType?: VulnerabilityTrendPointTypeSchema;
      points?: Array<VulnerabilityTrendPointSchema>;
    };
  };
  theme: Theme;
}) => {
  const [seriesData, xAxisData] = getData(values?.trend?.points || []);
  const dateRange: IDate = {
    startDate: moment().subtract(1, 'month').startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
  };

  return {
    xAxis: {
      type: 'category',
      show: false,
      boundaryGap: false,
      data: xAxisData.map((value: number) => formatDate(`${value}`, dateRange, true)),
    },
    yAxis: { show: false },
    tooltip: {
      trigger: 'axis',
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
    },
    grid: {
      left: '0%',
      right: '0%',
      top: '5%',
      bottom: '5%',
    },
    series: [
      {
        data: seriesData,
        type: 'line',
        smooth: 0.6,
        symbol: 'none',
        symbolSize: 6,
        lineStyle: {
          opacity: 1,
          color: '#84B5E3',
          width: 2,
        },
        areaStyle: {
          color: '#84B5E3',
          opacity: 0.2,
        },
        itemStyle: { borderWidth: 2 },
        markLine: {
          symbol: ['none', 'none'],
          label: { show: false },
        },
      },
    ],
  };
};
