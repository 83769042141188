import { OrderBy } from 'services/api';

export enum TableTitles {
  InstalledSoftware = 'Installed software',
  Version = 'Version',
}

export const searchSettings = {
  placeholder: 'Search by Installed software',
  fields: ['name'],
  min: 3,
};

export const initialOrderBy = {
  field: 'name',
  type: OrderBy.type.ASC,
};
