import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { Routes } from 'services/entities';
import { backlinkRoutes } from 'app-constants/routes';

export const getLayoutConfig = (): InnerLayout => ({
  title: 'Alarms',
  backLink: (
    <BackLink
      defaultRoute={Routes.THREAT_DETECTION}
      defaultTitle={backlinkRoutes[Routes.THREAT_DETECTION]}
    />
  ),
});
