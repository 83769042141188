import { spacing } from '../spacing';
import { fontWeights } from '../fontWeights';
import { colors } from '../colors';

export const button = {
  fontSize: `${spacing[4]}`,
  fontWeight: `${fontWeights.bold}`,
  textTransform: 'none',
  backgroundColor: '#BEA167',
  color: `${colors.white}`,
  border: 'none',
  borderRadius: '12px 0px',

  hover: {
    backgroundColor: `#CDB689`,
    color: `${colors.white}`,
    transform: `translateY(-${spacing[1]})`,
    boxShadow: `0px ${spacing[1]} 0 ${colors.black}`,
  },

  active: {
    backgroundColor: `#BEA167`,
    color: `${colors.white}`,
  },
};

export const buttonDisabled = {
  backgroundColor: `#323332`,
  color: `#ffffff4d`,
};

export const buttonSecondary = {
  backgroundColor: `transparent`,
  color: colors.white,
  border: `2px solid #C2BAAA`,
  borderRadius: '12px 0px',

  hover: {
    backgroundColor: `#242524`,
    color: colors.white,
    borderColor: `#C2BAAA`,
  },

  active: {
    backgroundColor: `#323332`,
    color: colors.white,
    borderColor: `#C2BAAA`,
  },

  disabled: {
    backgroundColor: `#323332`,
    color: `#ffffff4d`,
    borderColor: `#323332`,
  },
};

export const buttonDanger = {
  backgroundColor: `#9C2B23`,
  color: `${colors.white}`,

  active: {
    backgroundColor: `${colors.red[400]}`,
  },

  disabled: {
    backgroundColor: `#323332`,
    color: `#ffffff4d`,
  },
};

// secondary ghost buttons
export const buttonSimple = {
  borderColor: `#494B42`,

  hover: {
    backgroundColor: `#242524`,
    color: `${colors.white}`,
    borderColor: `#494B42`,
  },

  active: {
    backgroundColor: `#323332`,
    borderColor: `#494B42`,
    color: `${colors.white}`,
  },

  disabled: {
    backgroundColor: `transparent`,
    color: '#ffffff4d',
    borderColor: `#323332`,
  },
};

export const buttonPrimaryOrDangerDisabled = {
  backgroundColor: `#323332`,
  color: `#ffffff4d`,
  borderColor: `#323332`,
};
