import styled, { css } from 'styled-components';
import { ThemeProps } from '../../../../services/interfaces';
import { getCssVar } from '../../../../styles/theme';

export const PageContainer = styled.div<ThemeProps>`
  background-color: black;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  ${() =>
    getCssVar('notFoundBackImg') &&
    css`
      background-image: ${() => `url(${getCssVar('notFoundBackImg')})`};
      background-repeat: no-repeat;
      background-size: cover;
    `}
  svg {
    position: relative;
    top: 10px;

    path {
      fill: ${getCssVar('textColor')};
    }
  }
`;

export const BlockContainer = styled.div`
  align-items: center;
  color: ${getCssVar('notFoundTextColor')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Message = styled.div`
  align-items: center;
  backdrop-filter: blur(15px);
  background: ${getCssVar('notFoundWrapperBackground')};
  border: 8px solid ${getCssVar('notFoundBorder')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 240px;
  opacity: 0.9;
  padding: 32px 64px 34px 64px;

  a {
    color: ${getCssVar('notFoundBackLinkColor')};
    text-decoration: none;
  }

  @media (max-width: 520px) {
    margin-top: 120px;
  }
`;

export const MessageHeader = styled.div`
  font-size: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 42px;
  max-width: 650px;
  text-align: center;

  svg path {
    fill: ${getCssVar('notFoundTextColor')};
  }
`;

export const MessageText = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin-top: 18px;
  text-align: center;
`;

export const MessageFooter = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-top: 28px;
  padding-bottom: 8px;
  text-align: center;
  text-transform: uppercase;
`;

export const Logo = styled.img`
  margin-bottom: 80px;
`;
