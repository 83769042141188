import { Themes } from '../../../../themes/types';
import { Report as NormReport } from './Norm';
import { Report as NastarReport } from './Nastar';

export function getReportComponent(themeName?: Themes) {
  switch (themeName) {
    case Themes.Nasstar:
      return NastarReport;
    default:
      return NormReport;
  }
}
