import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Divider } from 'antd';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { Box, Icon, Input } from '../../../shared';
import { AppDispatch, useCustomer, useTagsManagement } from '../../../store';
import { tagsManagementApi } from '../../../store/tagsManagement/thunks';
import { OptionType } from '../../../shared/Table/types';
import { TagSchema } from '../../../services/api';
import { BulkActionLabel, BulkActionSelector, CreateTagButton, CustomSpacer } from '../styled';
import { getCssVar, icons, sizes } from '../../../styles/theme';
import { Spacer } from '../../../shared/TableFilters/styled';
import { LIST_HEIGHT_FOR_10_ITEMS } from '../constants';
import { RequestStatus } from '../../../store/loading/slice';
import { showError } from '../../../services/helpers';
import { MAX_TAG_NAME_LENGTH_DESKTOP } from '../../TagsManagement/constant';

interface ITagsSelectorProps {
  closeSelector: () => void;
  label?: string;
  hideCreateTagButton?: boolean;
  selectTag: (soft: boolean, action?: string, tagId?: string) => void;
}

export const BulkActionTagsSelector = ({
  closeSelector,
  label,
  hideCreateTagButton = true,
  selectTag,
}: ITagsSelectorProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { tagsList } = useTagsManagement();
  const customerId = useCustomer();

  useEffect(() => {
    dispatch(tagsManagementApi.getTagsList(customerId));
  }, [customerId, dispatch]);

  const [newTag, setNewTag] = useState('');
  const name = newTag.trimStart().trimEnd();

  const onTagChange: ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTag(event.target.value);
  };

  const create = async () => {
    if (!newTag) {
      return;
    }

    const api = tagsManagementApi.createTag({ customerId, requestBody: { name } });

    const response = (await dispatch(api)) as PayloadAction<
      TagSchema[],
      string,
      { requestStatus: string }
    >;

    const getTagById = response.payload?.find((item: { name?: string }) => item?.name === name)?.id;

    if (getTagById) {
      selectTag(false, label, getTagById);
      setNewTag('');
      closeSelector();
    }

    if (response.meta.requestStatus === RequestStatus.Rejected) {
      showError(
        'Tag Already Exist. The tag name you entered is already in use. Please choose a different name to ensure uniqueness and avoid conflicts with existing tags.',
      );
    }
  };

  const selectTagHandler = useCallback(
    (tagId) => {
      selectTag(false, label, tagId);

      closeSelector();
    },
    [closeSelector, label, selectTag],
  );

  const selectTagOptions = useMemo(() => {
    return tagsList?.map((item: TagSchema) => ({
      label: item?.name,
      value: item?.id,
    })) as OptionType[];
  }, [tagsList]);

  const filterOption = (inputValue: string, option: OptionData | OptionGroupData | undefined) =>
    (option as OptionType).label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

  return (
    <Box style={{ background: getCssVar('select.bg') }}>
      <BulkActionLabel>{label}</BulkActionLabel>
      <Divider style={{ margin: '8px 0', backgroundColor: getCssVar('divider.color') }} />
      <BulkActionSelector
        showSearch
        defaultOpen
        open={true}
        onSelect={selectTagHandler}
        options={selectTagOptions}
        filterOption={filterOption}
        placeholder={
          <Box display='flex' alignItems='center'>
            <Icon component={icons.search} dimension={sizes['5']} />
            <Spacer w='10' />
            Search for tag
          </Box>
        }
        listHeight={LIST_HEIGHT_FOR_10_ITEMS}
        dropdownMatchSelectWidth
        suffixIcon=' '
        dropdownRender={(menu: ReactNode) => (
          <>
            {menu}
            {hideCreateTagButton && (
              <>
                <Divider style={{ margin: '8px 0', backgroundColor: getCssVar('divider.color') }} />
                <CustomSpacer>
                  <Input
                    placeholder='New tag name'
                    style={{ maxWidth: 215, fontSize: 15, padding: '4px 8px' }}
                    value={newTag}
                    onChange={onTagChange}
                    maxLength={MAX_TAG_NAME_LENGTH_DESKTOP}
                  />
                  <CreateTagButton onClick={create}>Create</CreateTagButton>
                </CustomSpacer>
              </>
            )}
          </>
        )}
      />
    </Box>
  );
};
