import { Alert as AntdAlert } from 'antd';
import styled, { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

export const Alert = styled(AntdAlert)`
  ${(props) =>
    props.type === 'error' &&
    css`
      background-color: ${getCssVar('alert.errorBg')};
      border-color: ${getCssVar('alert.errorBorderColor')};

      .ant-alert-message {
        color: ${getCssVar('alert.errorColor')};
      }
    `};
  ${(props) =>
    props.banner &&
    css`
      background-color: ${getCssVar('alertAction')};

      .ant-alert-message {
        color: ${getCssVar('alert.messageColor')};
      }

      .ant-alert-icon {
        color: ${getCssVar('alert.iconColor')};
      }

      .ant-alert-close-text {
        color: ${getCssVar('alert.messageColor')};
        font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
        opacity: 0.15;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      .ant-btn-link {
        color: ${getCssVar('alert.linkColor')};

        &:not(:disabled) {
          &:hover,
          &:focus {
            color: ${getCssVar('alert.linkColor')};
          }
        }
      }
    `};
`;
