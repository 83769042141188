export enum TableTitles {
  Asset = 'Asset',
  OS = 'OS',
  Status = 'Status',
  MissingPatches = 'Missing patches',
  InstalledPatches = 'Installed patches',
  Scanned = 'Scanned',
}

export enum ColumnKey {
  Asset = 'name',
  OS = 'operatingSystem',
  Status = 'assetStatus',
  MissingPatches = 'missingCount',
  InstalledPatches = 'installedCount',
  Scanned = 'scanDateTime',
}
