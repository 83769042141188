import { AnyAction, Middleware, MiddlewareAPI, ThunkDispatch } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { timeFactor, tokenTimePastToUpdate } from 'app-constants';
import moment from 'moment';
import { AppState, authApi, logoutAction } from 'store';
import { RequestStatus } from 'store/loading/slice';
import { isTokenExpired } from 'services/helpers';

const tokenRefreshActionRegex = new RegExp('auth/token/refresh');

const isTokenNeedUpdate = (token: string) => {
  const decoded: { exp: number } = jwt_decode(token);

  return decoded.exp * timeFactor - moment().valueOf() < tokenTimePastToUpdate;
};

export const tokenRefreshMiddleware: Middleware<
  Record<string, unknown>,
  AppState,
  ThunkDispatch<AppState, Record<string, unknown>, AnyAction>
> = (api: MiddlewareAPI<ThunkDispatch<AppState, Record<string, unknown>, AnyAction>, AppState>) => (
  next: ThunkDispatch<AppState, Record<string, unknown>, AnyAction>,
) => (action: AnyAction) => {
  const { auth, loading } = api.getState();

  if (action.meta?.requestStatus === RequestStatus.Rejected && auth.authToken) {
    if (isTokenExpired(auth.authToken)) {
      api.dispatch(logoutAction());
    }
  }

  if (
    action.meta?.requestStatus === RequestStatus.Pending &&
    !tokenRefreshActionRegex.test(action.type)
  ) {
    if (
      !loading[authApi.refreshToken.typePrefix] &&
      auth.authToken &&
      isTokenNeedUpdate(auth.authToken)
    ) {
      api.dispatch(authApi.refreshToken());
    }
  }

  return next(action);
};
