import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { isNaN, startCase, sum, toLower, upperFirst } from 'lodash';
import { Box } from 'shared';
import { isEmptyObject } from 'services/helpers';
import { useTheme } from 'styled-components';
import { TableProps, Tooltip } from 'antd';
import { Colors, Values } from '../types';
import {
  ItemPercent,
  ItemValue,
  LegendItemTitle,
  LegendItemValue,
  LegendTable,
  LegendWrapperValue,
} from './styled';

type LegendProps = {
  values: Values;
  colors: Colors;
  direction: 'column' | 'row';
  legendLeft?: boolean | undefined;
  noValues?: boolean;
  chartRef?: React.MutableRefObject<ReactECharts | undefined>;
  actionOptions?: (name: string) => Record<string, string>;
  onAnnotationClick?: (name: string) => void;
  formatName?: (name: string) => string;
  isEmpty?: boolean;
  justifyLegendContent?: string;
  marginTop?: string;
  type?: 'circle' | 'square';
  isTruncatedText?: boolean;
  cursor?: string;
  isShowingUnchangedText?: boolean;
  styles?: CSSProperties;
  isMobile: boolean | undefined;
  isMenuCollapsed: boolean | undefined;
};

export const calcPercentage = (values: Values) => {
  const FULL_PERCENTAGE = 100;
  const total = values ? sum(Object.values(values)) : 0;
  const percentages: Values = Object.keys(values).reduce((accumulator, key: string) => {
    return { ...accumulator, [key]: Math.round((values[key] / total) * FULL_PERCENTAGE) };
  }, {});

  return percentages;
};

export const Legend = ({
  values,
  colors,
  direction,
  legendLeft,
  noValues,
  formatName,
  isEmpty,
  actionOptions,
  justifyLegendContent,
  onAnnotationClick,
  marginTop,
  chartRef,
  type = 'circle',
  isTruncatedText = false,
  cursor = 'pointer',
  isShowingUnchangedText = false,
  styles = {},
  isMobile = false,
  isMenuCollapsed = false,
}: LegendProps) => {
  const { sizes, spacing, radius, fontWeights, colors: themeColors } = useTheme();

  const initialState = useMemo(() => {
    return Object.keys(values).reduce((acc, name) => {
      return { ...acc, [name]: true };
    }, {});
  }, [values]);

  const [selection, setSelection] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setSelection(initialState);
  }, [initialState]);

  const percentages = !noValues && calcPercentage(values);
  const iconSize = sizes[3];
  const wordSpace = spacing[2];
  const gap = sizes[3];

  chartRef?.current?.getEchartsInstance().on('legendselectchanged', (params: unknown) => {
    const p = params as { selected: Record<string, boolean> };

    setSelection(p.selected);
  });

  const renderSeverityLevelIcon = (severity: string, unactive?: boolean) => {
    switch (type) {
      case 'circle':
        return (
          <Box
            bgColor={unactive ? themeColors.green[200] : colors[severity]}
            borderRadius={radius.full}
            w={iconSize}
            h={iconSize}
            mr={wordSpace}
            flexShrink={0}
          />
        );
      default:
        return (
          <Box
            bgColor={unactive ? themeColors.green[200] : colors[severity]}
            border={`1px solid ${themeColors.green[200]}`}
            w={sizes[4]}
            h={sizes[4]}
            mr={wordSpace}
            flexShrink={0}
          />
        );
    }
  };

  const renderTable = () => (
    <LegendTable<React.FC<TableProps<{ key: string }>>>
      columns={[
        {
          key: 'severity',
          dataIndex: 'severity',
          render: (severity: string) => {
            const label =
              (formatName && formatName(severity)) || upperFirst(toLower(startCase(severity)));

            const needTruncatedText = isTruncatedText ? (
              <Tooltip title={label} placement={isMobile ? 'top' : 'right'}>
                <Box
                  maxW={isMenuCollapsed ? '150px' : '100px'}
                  color={!selection[severity] ? themeColors.green[200] : undefined}
                  overflow='hidden'
                  whiteSpace='nowrap'
                  textOverflow='ellipsis'
                >
                  {label}
                </Box>
              </Tooltip>
            ) : (
              <Box color={!selection[severity] ? themeColors.green[200] : undefined}>{label}</Box>
            );

            return (
              <Box key={severity} maxW={isMobile ? '250px' : undefined} d='flex' align='center'>
                {renderSeverityLevelIcon(severity)}
                {needTruncatedText}
              </Box>
            );
          },
        },
        {
          key: 'value',
          dataIndex: 'value',
          align: 'right',
          render: (value, row: { key: string }) => (
            <Box color={!selection[row.key] ? themeColors.green[200] : undefined}>{value}</Box>
          ),
        },
        {
          key: 'percentage',
          dataIndex: 'percentage',
          align: 'right',
          render: (value, row: { key: string }) => (
            <Box color={!selection[row.key] ? themeColors.green[200] : undefined}>{value}</Box>
          ),
        },
      ]}
      onRow={(row: { key: string }) => ({
        onClick: () => {
          onAnnotationClick?.(row.key);
          chartRef?.current?.getEchartsInstance().dispatchAction({
            type: 'legendToggleSelect',
            name: row.key,
          });
        },
        onMouseEnter: () => {
          chartRef?.current?.getEchartsInstance().dispatchAction({
            type: 'highlight',
            name: row.key,
          });
        },
        onMouseLeave: () => {
          chartRef?.current?.getEchartsInstance().dispatchAction({
            type: 'downplay',
            name: row.key,
          });
        },
      })}
      dataSource={Object.entries(values).reduce(
        (
          acc: { key: string; severity: string; value: number | string; percentage: string }[],
          [name, value]: [string, number],
        ) => {
          acc.push({
            key: name,
            severity: name,
            value: !noValues && !isEmpty ? value : '',
            percentage:
              !noValues && !isEmpty
                ? `(${!percentages || isNaN(percentages[name]) ? 0 : percentages[name]}%)`
                : '',
          });

          return acc;
        },
        [],
      )}
      pagination={false}
      tableLayout='auto'
    />
  );

  return (
    <Box
      d='flex'
      justify={justifyLegendContent || legendLeft ? 'flex-start' : 'center'}
      flexDirection={direction}
      flexWrap='wrap'
      marginTop={marginTop}
      style={styles}
    >
      {legendLeft || direction === 'column'
        ? !isEmptyObject(values) && renderTable()
        : Object.entries(values).map(([name, value]: [string, number], index: number) => (
            <Box
              className='legend-item'
              maxW={isMobile ? '250px' : undefined}
              key={name}
              cursor={cursor}
              d='flex'
              onClick={() => {
                onAnnotationClick?.(name);
                chartRef?.current?.getEchartsInstance().dispatchAction({
                  type: 'legendToggleSelect',
                  name,
                });
              }}
              onMouseEnter={() => {
                chartRef?.current?.getEchartsInstance().dispatchAction({
                  type: 'highlight',
                  ...(actionOptions
                    ? actionOptions(name)
                    : {
                        name,
                        dataIndex: index,
                      }),
                });
              }}
              onMouseLeave={() => {
                chartRef?.current?.getEchartsInstance().dispatchAction({
                  type: 'downplay',
                  ...(actionOptions
                    ? actionOptions(name)
                    : {
                        name,
                        dataIndex: index,
                      }),
                });
              }}
              align='center'
              mr={gap}
              mb={gap}
            >
              {renderSeverityLevelIcon(name, !selection[name])}

              <LegendItemValue
                fontSize={sizes['3.5']}
                display='flex'
                align='center'
                justify='center'
                unactive={!selection[name]}
              >
                <LegendItemTitle d='inline-block'>
                  {isShowingUnchangedText
                    ? name
                    : (formatName && formatName(name)) || upperFirst(toLower(startCase(name)))}
                </LegendItemTitle>

                {!noValues && !isEmpty && (
                  <LegendWrapperValue
                    d='flex'
                    fontWeight={fontWeights.bold}
                    ml={wordSpace}
                    whiteSpace='nowrap'
                  >
                    <ItemValue>{`${value}`}</ItemValue>

                    {percentages && (
                      <ItemPercent ml={spacing[1]}>
                        {`(${!percentages || isNaN(percentages[name]) ? 0 : percentages[name]}%)`}
                      </ItemPercent>
                    )}
                  </LegendWrapperValue>
                )}
              </LegendItemValue>
            </Box>
          ))}
    </Box>
  );
};
