import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ActionNames,
  defaultCurrent,
  defaultPageSize,
  initialPagination,
  PermissionOptions,
} from 'app-constants';
import { useGetPermission } from 'services/hooks';
import { RequestStatus } from 'store/loading/slice';
import { showPopup } from 'pages/Documents/helpers';
import { ActionPopup } from 'shared';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { deletePopupDetails } from 'pages/Documents/components/constants';
import { getTableColumns } from 'services/helpers';
import {
  DPLDocumentSchema,
  DPLDocumentsResponse,
  Filter,
  Filters,
  GridRequestParams,
} from 'services/api';
import { CustomTable } from 'shared/Table/CustomTable';
import { AppDispatch, dpl, dplApi, useAuth, useCustomer, useDbl } from 'store';
import { downloadDocument } from 'services/helpers/downloadDocument';
import { getColumns } from './TableColumns';
import { initialOrderBy } from './constants';

type CategoryTableProps = {
  categoryId: string;
  filters?: Filters;
};

export const CategoryTable = ({ categoryId, filters }: CategoryTableProps) => {
  const { downloading } = useDbl();
  const { user } = useAuth();
  const [isActionPopupVisible, setIsActionPopupVisible] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const permission = useGetPermission(PermissionOptions.DataProtection);
  const [activePopupDetails, setActivePopupDetails] = React.useState<PopupConfigType | null>(null);
  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();
  const [data, setData] = useState<DPLDocumentsResponse>();

  const downloadFile = React.useCallback(
    async (filename: string) => {
      await downloadDocument({ dispatch, customer: customerId, filename, api: dplApi, slice: dpl });
    },
    [dispatch, customerId],
  );

  const [params, setParams] = React.useState<GridRequestParams>({
    customerId,
    orderBy: initialOrderBy,
    pagination: initialPagination,
  });

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    const result = await dispatch(
      dplApi.fetchTableContent({
        ...params,
        filter: {
          search: filters?.search,
          fields: [
            ...(filters?.fields || []),
            {
              name: 'categoryId',
              value: [categoryId],
              type: Filter.type.MULTIPLE,
            },
          ],
        },
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Fulfilled) {
      setData(result.payload as DPLDocumentsResponse);
    }

    setIsDataLoading(false);
  }, [params, categoryId, filters, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onClickActionButton = React.useCallback(
    (row: DPLDocumentSchema, popupId: ActionNames) => {
      showPopup(
        popupId,
        setActivePopupDetails,
        setIsActionPopupVisible,
        { ...row, name: row.filename },
        dispatch,
        customerId,
        dplApi,
        () => {
          if (params.pagination.page !== 1 && data?.rows?.length === 1) {
            return setParams({
              ...params,
              pagination: {
                ...params.pagination,
                page: params.pagination.page && params.pagination.page - 1,
              },
            });
          }

          return loadData();
        },
      );
    },

    [
      setIsActionPopupVisible,
      setActivePopupDetails,
      dispatch,
      params,
      data,
      loadData,
      customerId,
      setParams,
    ],
  );

  const columns = getColumns(
    downloadFile,
    downloading,
    permission,
    onClickActionButton,
    user.isSuperAdmin,
  );

  return (
    <>
      <CustomTable<DPLDocumentSchema, GridRequestParams>
        data={data}
        columns={getTableColumns(columns, params.orderBy)}
        params={params}
        setParams={setParams}
        defaultPageSize={defaultPageSize}
        defaultCurrent={defaultCurrent}
        filterFields={[]}
        isLoadingContent={isDataLoading}
      />

      {isActionPopupVisible && (
        <ActionPopup
          isVisible={isActionPopupVisible}
          popupDetails={activePopupDetails || deletePopupDetails}
          setIsVisiblePopup={setIsActionPopupVisible}
        />
      )}
    </>
  );
};
