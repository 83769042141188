import React from 'react';

export const TitleTooltipContent = () => {
  return (
    <>
      <p>
        The Software section is where you can find all the software files and associated
        installation guides to install your service so that your organisation can be protected as
        soon as possible.
      </p>
      <p>
        Remember if you need any help to implement the various elements, you can contact our team
        who will be happy to help you.
      </p>
    </>
  );
};
