import { Radio as AntdRadio } from 'antd';
import styled from 'styled-components';
import { Theme } from '../../styles/theme/types';
import { getCssVar } from '../../styles/theme';

export const Radio = styled(AntdRadio)`
  .ant-radio-inner {
    border-color: ${getCssVar('radio.border')};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }

  .ant-radio-disabled {
    .ant-radio-inner {
      border-color: ${getCssVar('radio.disabled.borderColor')} !important;
    }

    &.ant-radio-checked {
      &:after {
        display: none;
      }
    }
  }

  &:hover,
  &:focus,
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: ${getCssVar('primary')};
    }
  }

  &:hover,
  &:focus {
    .ant-radio {
      &:not(.ant-radio-checked) {
        .ant-radio-inner {
          border-color: ${getCssVar('radio.secondary')};
        }
      }
    }
  }

  .ant-radio {
    &:not(.ant-radio-disabled) {
      &:after {
        border: none;
        position: absolute;
        content: '';
        left: -7px;
        top: -7px;
        width: calc(100% + 14px);
        height: calc(100% + 14px);
        border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
        background: ${getCssVar('radio.secondary')}20;
        visibility: visible;
        transition: all 0.1s ease-out;
        animation: none;
        opacity: 0;
      }
    }
  }

  &.hover,
  &:hover,
  &:focus,
  &.active,
  &:active {
    .ant-radio {
      &:after {
        opacity: 1;
      }
    }
  }

  &.active,
  &:active {
    .ant-radio {
      &:after {
        background: ${getCssVar('radio.secondary')}33;
      }
    }
  }
`;
