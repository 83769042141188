import { Permission } from 'store';
import { PermissionOptions } from 'app-constants';
import { PermissionKeys } from 'services/interfaces';

export const checkPermission = (permissions: Permission[], pageName: string) => {
  return (
    permissions.find(
      (permission: Permission) =>
        permission.option === PermissionOptions[pageName as PermissionKeys],
    )?.level || null
  );
};
