export enum TableTitles {
  Patch = 'Patch',
  VendorSeverity = 'Vendor severity',
  MissingOnAssets = 'Missing on assets',
  InstalledOnAssets = 'Installed on assets',
  Category = 'Category',
  Vulnarabilities = 'Vulnerabilities',
  Published = 'Published',
}

export enum ColumnKey {
  Patch = 'title',
  VendorSeverity = 'vendorSeverity',
  MissingOnAssets = 'missingCount',
  InstalledOnAssets = 'installedCount',
  Category = 'category',
  Vulnarabilities = 'qids',
  Published = 'publishedDate',
}
