import { useSelector, shallowEqual } from 'react-redux';
import { isEqual } from 'lodash';
import { HostSchema } from 'services/api';
import { LoadingState } from './loading/slice';
import { AppState } from './store';

export const useConsoleStore = () =>
  useSelector((state: AppState) => {
    // eslint-disable-next-line no-console
    console.log({ state });
  });

export const useAuth = () => useSelector(({ auth }: AppState) => auth);

export const usePmPatches = () =>
  useSelector(
    ({ patchManagementPatches }: AppState) => patchManagementPatches,
    (prev, next) => isEqual(prev, next),
  );

export const useCustomer = () =>
  useSelector(
    ({ app, auth }: AppState) => app.currentCustomerId || auth.user.customer,
    (prev, next) => isEqual(prev, next),
  );

export const useCustomerName = () =>
  useSelector(
    ({ app, auth }: AppState) => app.currentCustomerName || auth.customersList[0].name,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useApp = () =>
  useSelector(
    ({ app }: AppState) => app,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useRoutes = () => useSelector(({ routes }: AppState) => routes);

export const useDocuments = () => useSelector(({ documents }: AppState) => documents);

export const useDbl = () => useSelector(({ dpl }: AppState) => dpl);

export const useSoftware = () => useSelector(({ software }: AppState) => software);

export const useLoading = (actions: string[]) =>
  useSelector(
    ({ loading }: AppState) => {
      return actions.reduce(
        (acc: LoadingState, action: string) => ({ ...acc, [action]: loading[action] }),
        {},
      );
    },
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useRequestLoading = (action: string) =>
  useSelector(({ loading }: AppState) => loading[action], shallowEqual);

export const useCustomerChange = (actions: string[]) =>
  useSelector(
    ({ customer }: AppState) => {
      return actions.reduce(
        (acc: LoadingState, action: string) => ({ ...acc, [action]: customer[action] }),
        {},
      );
    },
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useCybersafety = () => useSelector(({ cybersafety }: AppState) => cybersafety);

export const useCurrentTrainingStatus = () =>
  useSelector(
    ({ cybersafety }: AppState) => cybersafety.currentTrainingStatus,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const usePhishingLastThirtyDays = () =>
  useSelector(
    ({ cybersafety }: AppState) => cybersafety.phishingLastThirtyDays,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useRefresherRates = () =>
  useSelector(
    ({ cybersafety }: AppState) => cybersafety.refresherRates,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useLicenseUsage = () =>
  useSelector(
    ({ cybersafety }: AppState) => cybersafety.licenseUsage,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useCulture = () =>
  useSelector(
    ({ cybersafety }: AppState) => cybersafety.culture,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useAverageConfidence = () =>
  useSelector(
    ({ cybersafety }: AppState) => cybersafety.averageConfidence,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const usePhishingByMonth = () =>
  useSelector(
    ({ cybersafety }: AppState) => cybersafety.phishingByMonth,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useAlarms = () => useSelector(({ alarms }: AppState) => alarms);

export const useAdminUsers = () => useSelector(({ adminUsers }: AppState) => adminUsers);

export const useNotifications = () => useSelector(({ notifications }: AppState) => notifications);

export const useVulnerability = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const usePotentialVulnerabilities = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.showPotentialVulnerabilities,
    (prev, next) => prev === next,
  );
export const useExcludedVulnerabilitiesStatusFilter = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.excludedVulnerabilitiesStatusFilter,
    (prev, next) => prev === next,
  );

export const usePlatform = () =>
  useSelector(
    ({ platform }: AppState) => platform.platform,
    (prev, next) => prev === next,
  );

export const useVendorSeverity = () =>
  useSelector(
    ({ severities }: AppState) => severities.severities,
    (prev, next) => prev === next,
  );

export const useCMDBPotentialVulnerabilities = () =>
  useSelector(
    ({ cmdb }: AppState) => cmdb.showPotentialVulnerabilities,
    (prev, next) => prev === next,
  );

export const useAssetDetailsPotentialVulnerabilities = () =>
  useSelector(
    ({ assetDetails }: AppState) => assetDetails.showPotentialVulnerabilities,
    (prev, next) => prev === next,
  );

export const useVulnerabilitiesTrendsByCriticalities = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.vulnerabilitiesTrendsByCriticalities,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useAllVulnerabilitiesTrend = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.allVulnerabilitiesTrend,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useCurrentVulnerabilities = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.currentVulnerabilities,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useLatestVulnerabilitiesWithAffectedHosts = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.latestVulnerabilitiesWithAffectedHosts,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useVulnerabilitiesWithMostAffectedHosts = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.vulnerabilitiesWithMostAffectedHosts,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useVulnerabilitySuppressionGrid = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.suppressionGrid,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useMostVulnerableHosts = () =>
  useSelector(
    ({ vulnerability }: AppState) => vulnerability.mostVulnerableHosts,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  );

export const useAssetDetails = () => useSelector(({ assetDetails }: AppState) => assetDetails);

export const useSecuritySettings = () =>
  useSelector(({ securitySettings }: AppState) => securitySettings);

export const useAudit = () => useSelector(({ audit }: AppState) => audit);

export const useActiveServices = () =>
  useSelector(({ activeServices }: AppState) => activeServices);

export const useActiveEDRAgents = () =>
  useSelector(({ activeEDRAgents }: AppState) => activeEDRAgents);

export const useEvents = () => useSelector(({ events }: AppState) => events);

export const useEventsByServices = () =>
  useSelector(({ eventsByServices }: AppState) => eventsByServices);

export const useSupportRequests = () =>
  useSelector(({ supportRequests }: AppState) => supportRequests);

export const useCmdb = () => useSelector(({ cmdb }: AppState) => cmdb);

export const useCmdbHost = (id: HostSchema['id']) =>
  useSelector(({ cmdb }: AppState) => {
    return cmdb.data.rows?.find((host) => host.id === id);
  });

export const useProcess = () => useSelector(({ process }: AppState) => process);

export const useDashboard = () => useSelector(({ dashboard }: AppState) => dashboard);

export const usePenetrationTests = () =>
  useSelector(({ penetrationTests }: AppState) => penetrationTests);

export const usePenetrationTestDetails = () =>
  useSelector(({ penetrationTestDetails }: AppState) => penetrationTestDetails);

export const useFindings = () => useSelector(({ findings }: AppState) => findings);

export const useFindingsVulnerabilityDetails = () =>
  useSelector(({ findingsVulnerabilityDetails }: AppState) => findingsVulnerabilityDetails);

export const useMonthlyReport = () => useSelector(({ monthlyReport }: AppState) => monthlyReport);

export const usePatchManagementAssets = () =>
  useSelector(({ patchManagementAssets }: AppState) => patchManagementAssets);

export const usePatchManagement = () =>
  useSelector(({ patchManagementDashboard }: AppState) => patchManagementDashboard);

export const useAssetPatchesBySeverity = () =>
  useSelector(
    ({ assetDetails: { missingPatchesBySeverity, installedPatchesBySeverity } }: AppState) => ({
      missingPatchesBySeverity,
      installedPatchesBySeverity,
    }),
    (prev, next) => isEqual(prev, next),
  );
export const useAssetRecentPatchesBySeverity = () =>
  useSelector(
    ({ assetDetails: { missingRecentPatches, installedRecentPatches } }: AppState) => ({
      missingRecentPatches,
      installedRecentPatches,
    }),
    (prev, next) => isEqual(prev, next),
  );

export const useCEPlus = () =>
  useSelector(({ cyberEssentialPlus }: AppState) => cyberEssentialPlus);

export const useMaintenanceSettings = () => useSelector(({ maintenance }: AppState) => maintenance);

export const useTagsManagement = () =>
  useSelector(({ tagsManagement }: AppState) => tagsManagement);

export const useNewsNotifications = () =>
  useSelector(
    ({ newsNotifications }: AppState) => newsNotifications,
    (prev, next) => isEqual(prev, next),
  );

export const useServicesIntegrations = () =>
  useSelector(({ servicesIntegrations }: AppState) => servicesIntegrations.services, shallowEqual);

export const useServiceIntegrationDetails = () =>
  useSelector(
    ({ servicesIntegrations }: AppState) => servicesIntegrations.serviceDetails,
    shallowEqual,
  );
