import React, { useMemo } from 'react';
import {
  PhishingLastThirtyDaysResponse,
  RefresherRatesResponse,
  TrainingStatusResponse,
} from 'services/api';
import {
  CurrentRefresherTests,
  CurrentTrainingStatus,
  PeopleHeader,
  PhishingLastMonth,
  Wrapper,
} from './styled';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { getCapitalizedString, isEmptyObject } from '../../../../../../../services/helpers';
import { emptyCurrentTrainingStatusObject, emptyRefresherStatistics } from '../../../../constants';
import { pieChart } from '../../../../../../../styles/theme/Norm/pieChart';
import { getChartValues } from '../../../../../../People/components/PhishingLastThirtyDays/helpers';
import { Values } from '../../../../../../../shared/charts/types';
import { CyberResilienceScoreDescriptionScoreMessage, TotalScorePieWrapper } from '../styled';
import { totalPieFontSize, totalPieScoreSize } from '../config/chart';
import { SegmentReportChartWithLegend } from '../../components/Charts/SegmentReportChartWithLegend';
import { Theme } from '../../../../../../../styles/theme/types';

type Props = {
  onChartLoaded: () => void;
  peopleScore: number | undefined | null;
  peopleRank: string;
  phishing?: PhishingLastThirtyDaysResponse;
  trainingStatus?: TrainingStatusResponse;
  refresherStatistics?: RefresherRatesResponse;
  theme: Theme;
};

const trainingColors = {
  completed: pieChart.CurrentTrainingStatusSuccess,
  started: pieChart.CurrentTrainingStatusStarted,
  notStarted: pieChart.CurrentTrainingStatusNotStarted,
};

const phishingLastThirtyDaysColors = {
  noActions: pieChart.CurrentTrainingStatusSuccess,
  opened: pieChart.CurrentTrainingStatusStarted,
  clicked: pieChart.CurrentRefresherTestsFailed,
  providedCredentialsOrDownloadedAnAttachment: pieChart.PhishingLast30DaysProvidedCredentials,
};

const refreshingTestsColors = {
  passed: pieChart.CurrentTrainingStatusSuccess,
  ignored: pieChart.CurrentRefresherTestsIgnored,
  failed: pieChart.CurrentRefresherTestsFailed,
};

const PIE_CHART_WIDTH = '280px';
const PIE_CHART_HEIGHT = '220px';

export function PdfPage6({
  onChartLoaded,
  peopleScore,
  peopleRank,
  theme,
  phishing = {},
  trainingStatus = {},
  refresherStatistics = {},
}: Props) {
  const trainingStatusValues = useMemo(
    () => (!isEmptyObject(trainingStatus) ? trainingStatus : emptyCurrentTrainingStatusObject),
    [trainingStatus],
  );

  const phishingValues = useMemo(
    () =>
      !isEmptyObject(phishing.details)
        ? (getChartValues(phishing) as Values)
        : emptyCurrentTrainingStatusObject,
    [phishing],
  );

  const refresherStatisticsValues = useMemo(
    () => (!isEmptyObject(refresherStatistics) ? refresherStatistics : emptyRefresherStatistics),
    [refresherStatistics],
  );

  return (
    <Wrapper>
      <PeopleHeader>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(peopleRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque neceari opta sinis
          excestiate comni nonsedi velibeate coriti as quas mo exerspe rumql et ipsum utem nos eos
          et landanti con ni ad ut que eum quis et aperuptatur?
        </p>
      </PeopleHeader>
      <TotalScorePieWrapper>
        <GradientPieReport
          score={peopleScore}
          height={totalPieScoreSize}
          width={totalPieScoreSize}
          onFinished={onChartLoaded}
          fontSize={totalPieFontSize}
        />
      </TotalScorePieWrapper>

      {peopleScore && peopleScore >= 0 && (
        <>
          <CurrentTrainingStatus>
            <h4>Current training status</h4>
            <SegmentReportChartWithLegend
              values={trainingStatusValues}
              theme={theme}
              colors={trainingColors}
              width={PIE_CHART_WIDTH}
              height={PIE_CHART_HEIGHT}
              onChartLoaded={onChartLoaded}
              entitiesName='users'
            />
          </CurrentTrainingStatus>
          <PhishingLastMonth>
            <h4>Phishing: last 30 days</h4>
            <SegmentReportChartWithLegend
              values={phishingValues}
              theme={theme}
              colors={phishingLastThirtyDaysColors}
              width={PIE_CHART_WIDTH}
              height={PIE_CHART_HEIGHT}
              onChartLoaded={onChartLoaded}
              entitiesName='emails'
            />
          </PhishingLastMonth>
          <CurrentRefresherTests>
            <h4>Current refresher tests</h4>
            <SegmentReportChartWithLegend
              values={refresherStatisticsValues}
              theme={theme}
              colors={refreshingTestsColors}
              width={PIE_CHART_WIDTH}
              height={PIE_CHART_HEIGHT}
              onChartLoaded={onChartLoaded}
              entitiesName='tests'
            />
          </CurrentRefresherTests>
        </>
      )}
    </Wrapper>
  );
}
