import styled from 'styled-components';
import { Theme } from 'styles/theme/types';

export const RecentPenetrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  .ant-table-wrapper {
    width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  }
  .ant-table-cell:last-child {
    text-align: right;
  }
`;
