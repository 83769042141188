import React from 'react';
import { Tooltip } from 'antd';
import { useTheme } from 'styled-components';
import { ClipboardIcon } from '../styled';
import { tooltipTime } from '../constants';

type CopyToClipboardProps = {
  field?: { property?: string; value?: string };
};

export const CopyToClipboard = ({ field }: CopyToClipboardProps) => {
  const theme = useTheme();

  const [isTooltipVisible, setIsTooltipVisible] = React.useState('');

  const copyToClipboard = (text: string, property: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsTooltipVisible(property);
      setTimeout(() => setIsTooltipVisible(''), tooltipTime);
    });
  };

  return (
    <Tooltip title='Copied!' visible={isTooltipVisible === field?.property}>
      <ClipboardIcon
        onClick={() => copyToClipboard(String(field?.value), field?.property || '')}
        component={theme.icons.clipboard}
      />
    </Tooltip>
  );
};
