import React from 'react';
import { InnerLayoutProps as InnerLayout } from 'shared';
import { SelectOS } from './components/SelectOS';
import { numberFormatter } from '../../services/helpers';

export const getLayoutConfig = (isMobile: boolean): InnerLayout => ({
  title: 'Patch management',
  customComponent: <SelectOS />,
  isCustomComponentNearTitle: !isMobile,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLegendValues = <T extends { [key: string]: any }>(
  data: T[] = [],
  chartTitle = 'title',
  limit?: number,
) => {
  const legendValues: Record<string, number> = {};

  const limitData = data.slice(0, limit);
  const otherPart = data.slice(limit);

  limitData.forEach((item: T) => {
    legendValues[item[chartTitle]] = item?.count;
  });

  if (limit && otherPart.length) {
    legendValues.Other = otherPart.reduce(
      (accumulator: number, { count = 0 }) => accumulator + count,
      0,
    );
  }

  return legendValues;
};

// get permanent colors with dynamic data
export const getLegendColors = (listOfValues: Record<string, number>, listOfColors: string[]) => {
  const legendColors: Record<string, string> = {};

  Object.keys(listOfValues).forEach((item, index) => {
    legendColors[item] = listOfColors[index];
  });

  return legendColors;
};

export const subTitleFormatterSum = (data: number[], float = 0): number => {
  const sum = data?.reduce((accumulator: number, value = 0) => accumulator + value, 0);

  return numberFormatter(sum, float) as number;
};

export const getRndInteger = (min = 0, max = 1) => Math.floor(Math.random() * (max - min)) + min;
