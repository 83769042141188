import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { alarmsApi, useAlarms, useApp, useCustomer, useLoading } from 'store';
import { Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { AlarmSchema, GridRequestParams } from 'services/api';
import { defaultPageSize, defaultCurrent, backlinkRoutes } from 'app-constants';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { EmptyTablePreview, InnerLayout, MobileTable, MobileTableCol } from 'shared';
import { CustomTable } from 'shared/Table/CustomTable';
import { useHistory } from 'react-router-dom';
import { useFilter } from 'services/hooks/useFilter';
import { Routes } from 'services/entities';
import { getColumns } from './components/TableColumns';
import { getLayoutConfig } from './helpers';
import { searchSettings, initialOrderBy, filterFields } from './constants';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const FULL_WIDTH_COLS = 32;

export const Alarms = () => {
  const layoutConfig = getLayoutConfig();
  const { data } = useAlarms();
  const customerId = useCustomer();
  const loading = useLoading([alarmsApi.alarmsTableContent.typePrefix]);
  const { isMobile } = useApp();
  const history = useHistory();

  const GUTTER: [Gutter, Gutter] = !isMobile
    ? [H_GUTTER, V_GUTTER]
    : [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

  const dispatch = useDispatch();

  const [params, updateParams] = useFilter({
    apiCall: alarmsApi.alarmsTableContent,
    dispatch,
    isMobile,
    customerId,
    initialOrderBy,
  });

  const emptyMessage = (
    <EmptyTablePreview
      title='No alarms yet'
      loading={loading[alarmsApi.alarmsTableContent.typePrefix]}
    />
  );

  const onRowClickHandler = useCallback(
    (record: AlarmSchema) => {
      history.push(Routes.ALARM_DETAILS.replace(':id', record.id as string), {
        backTo: {
          route: `${Routes.ALARMS}`,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[Routes.ALARMS]}`,
        },
      });
    },
    [history],
  );

  return (
    <InnerLayout {...layoutConfig}>
      {isMobile ? (
        <Row gutter={GUTTER}>
          <MobileTableCol span={FULL_WIDTH_COLS} flex='auto'>
            <MobileTable<AlarmSchema, GridRequestParams>
              data={data}
              searchSettings={searchSettings}
              columns={getColumns(isMobile)}
              filterFields={filterFields}
              params={params}
              setParams={updateParams}
              initialOrderBy={initialOrderBy}
              isLoadingContent={loading[alarmsApi.alarmsTableContent.typePrefix]}
              emptyMessage={emptyMessage}
              onRowClick={onRowClickHandler}
            />
          </MobileTableCol>
        </Row>
      ) : (
        <CustomTable<AlarmSchema, GridRequestParams>
          data={data}
          columns={getTableColumns(getColumns(isMobile), params.orderBy)}
          params={params}
          setParams={updateParams}
          searchSettings={searchSettings}
          defaultPageSize={defaultPageSize}
          defaultCurrent={defaultCurrent}
          filterFields={filterFields}
          isLoadingContent={loading[alarmsApi.alarmsTableContent.typePrefix]}
          emptyMessage={emptyMessage}
          onRowClickHandle={onRowClickHandler}
        />
      )}
    </InnerLayout>
  );
};
