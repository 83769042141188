import React from 'react';
import { getRenderValue } from 'services/helpers';
import { ColumnsType } from 'antd/es/table/interface';
import { RowType } from 'shared/Table/CustomTable';
import { Box } from 'shared';
import { Link } from 'react-router-dom';
import { ColumnKey, TableTitles } from '../constant';
import { DropdownActions } from './DropdownActions';
import { Routes } from '../../../services/entities';
import { getQueryString } from '../../../services/api/core/request';
import { backlinkRoutes, initialPagination } from '../../../app-constants';
import { initialOrderBy as patchesOrderBy } from '../../PatchManagementPatches/constants';
import { Filter, TagSchema } from '../../../services/api';
import { EditNameForm } from './EditNameForm';
import { truncateTextWithTooltip } from '../../Cmdb/helpers';
import { EditableTag } from '../../../services/interfaces';

export const getColumns = (
  onSuccess: () => void,
  editableTag: EditableTag | null,
  isFullAccess: boolean,
  isMobile = false,
  search: string,
): ColumnsType<RowType> => [
  {
    key: ColumnKey.TagName,
    title: TableTitles.TagName,
    dataIndex: ColumnKey.TagName,
    width: '35%',
    sorter: true,
    render: (name: string, row: TagSchema & RowType) => {
      if (row.id === editableTag?.id) {
        return <EditNameForm editableTag={editableTag} />;
      }

      return <>{truncateTextWithTooltip(name, isMobile)}</>;
    },
  },
  {
    key: ColumnKey.TaggedAssets,
    title: TableTitles.TaggedAssets,
    dataIndex: ColumnKey.TaggedAssets,
    width: '200px',
    align: isFullAccess ? 'right' : 'center',
    sorter: true,
    render: (count: number, row: RowType) => {
      if (!count) {
        return <Box style={{ paddingRight: 3 }}>{getRenderValue(count)}</Box>;
      }

      return (
        <Link
          to={{
            pathname: Routes.CMDB,
            search: getQueryString({
              pagination: initialPagination,
              orderBy: patchesOrderBy,
              filter: {
                fields: [
                  {
                    name: 'tagsAssets',
                    value: [row.id],
                    type: Filter.type.MULTIPLE,
                  },
                ],
              },
            }),
            state: {
              backTo: {
                route: Routes.TAGS_MANAGEMENT,
                title: `Back to ${backlinkRoutes[Routes.TAGS_MANAGEMENT]}`,
                search,
              },
            },
          }}
          onClick={(event: React.MouseEvent) => event.stopPropagation()}
          style={{ paddingRight: 3 }}
        >
          {getRenderValue(count)}
        </Link>
      );
    },
  },
  {
    key: ColumnKey.AgedTaggedAssets,
    title: TableTitles.AgedTaggedAssets,
    dataIndex: ColumnKey.AgedTaggedAssets,
    width: '195px',
    align: isFullAccess ? 'right' : 'center',
    sorter: true,
    render: (count: number, row: RowType) => {
      if (!count) {
        return <Box style={{ paddingRight: 3 }}>{getRenderValue(count)}</Box>;
      }

      return (
        <Link
          to={{
            pathname: Routes.CMDB_AGED_HOSTS,
            search: getQueryString({
              pagination: initialPagination,
              orderBy: patchesOrderBy,
              filter: {
                fields: [
                  {
                    name: 'tagsAssets',
                    value: [row.id],
                    type: Filter.type.MULTIPLE,
                  },
                ],
              },
            }),
            state: {
              backTo: {
                route: Routes.TAGS_MANAGEMENT,
                title: `Back to ${backlinkRoutes[Routes.TAGS_MANAGEMENT]}`,
                search,
              },
            },
          }}
          onClick={(event: React.MouseEvent) => event.stopPropagation()}
          style={{ paddingRight: 3 }}
        >
          {getRenderValue(count)}
        </Link>
      );
    },
  },
  {
    key: ColumnKey.Actions,
    title: TableTitles.Action,
    dataIndex: ColumnKey.Actions,
    render: (_: string, record: TagSchema) => (
      <Box onClick={(event: React.MouseEvent) => event.stopPropagation()}>
        <DropdownActions tag={record} onSuccess={onSuccess} />
      </Box>
    ),
    align: 'right',
  },
];
