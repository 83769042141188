import React from 'react';
import { useDispatch } from 'react-redux';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { ActionPopup } from 'shared';
import { useAuth, useCustomer } from 'store';
import { UserSchema } from 'services/api';
import { Popup } from './styled';
import { filterActionsByStatus, showPopup } from '../helpers';
import { getCustomMenu } from './UserDetails/helpers';

export interface MobileActionsPopupProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  userData: UserSchema;
  onSuccess: () => void;
}

export const MobileActionsPopup = ({
  isVisible,
  setIsVisible,
  userData,
  onSuccess,
}: MobileActionsPopupProps) => {
  const customer = useCustomer();
  const { user } = useAuth();
  const userId = userData.id || '';

  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const dispatch = useDispatch();

  const filteredActionsByStatus = filterActionsByStatus(userData.status);

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleClickAction = (popupId: string) => {
    handleCancel();
    if (userData && userData.name && userData.email) {
      const userDetails = { id: userId, name: userData.name, email: userData.email };

      showPopup({
        popupId,
        setPopupDetails,
        setIsVisiblePopup,
        userData: userDetails,
        dispatch,
        customerId: customer,
        onSuccess,
        user,
      });
    }
  };

  return (
    <>
      {isVisible && (
        <Popup
          className='mobile-actions-modal'
          title='Actions'
          visible={isVisible}
          width={240}
          footer={null}
          onCancel={handleCancel}
          closable={false}
        >
          {getCustomMenu(filteredActionsByStatus, handleClickAction)}
        </Popup>
      )}
      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
        />
      )}
    </>
  );
};
