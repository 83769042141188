import { SeverityLevel } from 'services/api';

export type FindingsBySeverity = {
  id?: string;
  count?: number;
  severity?: SeverityLevel;
};

export type TestVilnerabilityRow = {
  id?: string;
  vulnerability?: string;
  severity?: SeverityLevel;
  status?: string;
};

export type TestVilnerabilities = {
  rows?: TestVilnerabilityRow[];
  count?: number;
};

export const findingsBySeverity: FindingsBySeverity[] = [
  {
    id: '1',
    count: 2,
    severity: SeverityLevel._3,
  },
  {
    id: '1',
    count: 2,
    severity: SeverityLevel._4,
  },
  {
    id: '1',
    count: 3,
    severity: SeverityLevel._5,
  },
];

export const testVulnerabilities: TestVilnerabilities = {
  rows: [
    {
      id: '1',
      vulnerability: 'No validation on file upload',
      severity: SeverityLevel._5,
      status: 'Open',
    },
    {
      id: '2',
      vulnerability: 'Wordpress exposure',
      severity: SeverityLevel._4,
      status: 'Open',
    },
    {
      id: '3',
      vulnerability: 'Regular expression denial of service (ReDoS)',
      severity: SeverityLevel._4,
      status: 'Open',
    },
    {
      id: '4',
      vulnerability: 'TLS version 1.0 protocol detection',
      severity: SeverityLevel._3,
      status: 'Open',
    },
    {
      id: '5',
      vulnerability: 'Browsable web directions',
      severity: SeverityLevel._3,
      status: 'Open',
    },
    {
      id: '6',
      vulnerability: 'Unsupported Windows OS (remote)',
      severity: SeverityLevel._5,
      status: 'Open',
    },
    {
      id: '7',
      vulnerability: 'Microsoft RDP RCE (CVE-2019-0708) (BlueKeep) (uncredentialed check)',
      severity: SeverityLevel._5,
      status: 'Open',
    },
  ],
  count: 7,
};
