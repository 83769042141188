import { CertificateStatusSchema } from 'services/api';

export const ABOUT_TO_EXPIRE_MONTHS = 3;

export enum Status {
  CERTIFIED = 'CERTIFIED',
  IN_PROGRESS = 'IN_PROGRESS',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  BLOCKED = 'BLOCKED',
  OTHER = 'OTHER',
}

export const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 8,
  xl: 8,
  xxl: 8,
};

export enum CertificateIcons {
  CyberEssentials = 'cyber_essentials',
  ActiveCyberEssentials = 'cyber_essentials_active',
  SelfAssessment = 'self_assessment',
  ActiveSelfAssessment = 'self_assessment_active',
  ISO = 'iso',
  ActiveISO = 'iso_active',
}

export const FailedCertificateStatuses = [
  CertificateStatusSchema.FAILED,
  CertificateStatusSchema.CANCELLED,
];
