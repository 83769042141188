import * as React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { ActionNames, emptyDataString, PermissionsLevel } from 'app-constants';
import { getFormattedDate } from 'services/helpers';
import { SoftwareDocumentSchema } from 'services/api';
import { UcFirstLetter } from 'shared';
import { DeleteButton } from 'shared/buttons/DeleteButton';
import { DownloadButton } from '../../Documents/components/DownloadButton';
import { normalizeFileSize } from '../../Documents/helpers';

type GetColumnsProps = (
  downloadClick: (name: string) => void,
  actionClick: (row: SoftwareDocumentSchema, popupId: ActionNames) => void,
  downloading: string,
  isMobile: boolean,
  permission?: PermissionsLevel | null,
) => ColumnsType<SoftwareDocumentSchema>;

export const getColumns: GetColumnsProps = (
  downloadClick,
  actionClick,
  downloading,
  isMobile,
  permission,
) => [
  {
    key: 'name',
    title: 'Document name',
    dataIndex: 'name',
    render: (text: string) => <>{text}</>,
  },
  {
    key: 'servicetype',
    title: 'Service type',
    dataIndex: 'servicetype',
    render: (text: string) => <>{text}</>,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    key: 'documenttype',
    title: 'Document type',
    dataIndex: 'documenttype',
    showSorterTooltip: false,
    render: (text: string) => <>{!text ? '' : UcFirstLetter(text.toLowerCase())}</>,
    sorter: true,
  },
  {
    key: 'size',
    title: 'Size',
    dataIndex: 'size',
    showSorterTooltip: false,
    render: (size: number) => (
      <>{typeof size !== 'number' ? emptyDataString : normalizeFileSize(size)}</>
    ),
    sorter: true,
  },
  {
    key: 'createdOn',
    title: 'Uploaded date',
    dataIndex: 'createdOn',
    showSorterTooltip: false,
    render: (date: string) => <>{getFormattedDate(date)}</>,
    sorter: true,
    className: 'right-head-title',
    align: 'right',
  },
  !isMobile
    ? {
        key: 'actions',
        title: 'Actions',
        dataIndex: 'actions',
        render: (_: string, row: SoftwareDocumentSchema) => (
          <>
            <DownloadButton click={downloadClick} downloading={downloading} name={row.name || ''} />
            {permission === PermissionsLevel.Full && !row.shared && (
              <DeleteButton onClick={() => actionClick(row, ActionNames.Delete)} />
            )}
          </>
        ),
        align: 'right',
        className: 'right-head-title',
      }
    : {},
];
