import { getHowManyDaysAgo } from 'services/helpers';
import { oneMonth, threeMonths } from 'app-constants';

export enum RadioButtonsKeys {
  Today = 'Today',
  SevenDays = '7 days',
  OneMonth = 'Month',
  ThreeMonths = '3 months',
  Year = 'Year',
}

export const radioButtonsValues = [
  { name: RadioButtonsKeys.Today, id: 1 },
  { name: RadioButtonsKeys.SevenDays, id: 2 },
  { name: RadioButtonsKeys.OneMonth, id: 3 },
  { name: RadioButtonsKeys.ThreeMonths, id: 4 },
  { name: RadioButtonsKeys.Year, id: 5 },
];

export enum RadioButtonsDays {
  SevenDays = 7,
  OneMonth = getHowManyDaysAgo(oneMonth),
  ThreeMonths = getHowManyDaysAgo(threeMonths),
  Year = 365,
}
