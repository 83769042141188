import styled from 'styled-components';
import { Button } from 'shared';
import { ThemeProps } from 'services/interfaces';
import { getCssVar } from '../../../../styles/theme';

export const StyledButton = styled(Button)<ThemeProps>`
  span:first-child {
    font-size: ${({ theme }) => theme.spacing[4]};
    font-weight: ${getCssVar('link.fontWeight')};
  }
`;

export const BoostScoreTitle = styled.span<ThemeProps>`
  font-size: ${({ theme }) => theme.spacing[4]} !important;
  font-weight: ${({ theme }) => theme.fontWeights.bold} !important;
`;

export const BoostScoreSubTitle = styled.span<ThemeProps>`
  font-size: ${({ theme }) => theme.spacing['3.6']} !important;
  font-weight: ${getCssVar('link.fontWeight')} !important;
`;

export const BoostScoreDate = styled.span<ThemeProps>`
  color: ${getCssVar('textColor')} !important;
`;
