import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TableProps } from 'antd';
import {
  useApp,
  useCustomer,
  useExcludedVulnerabilitiesStatusFilter,
  useLoading,
  useVulnerabilitiesWithMostAffectedHosts,
  useVulnerability,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { initialPagination } from 'app-constants';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { Filter, OrderBy, SeverityLevel, TopVulnerabilitySchema } from 'services/api';
import {
  emptyMessageConfig,
  severityValues,
  TooltipTitles,
  TOP_COUNT,
  TotalRowKey,
  VulnerabilitiesTitles,
} from 'pages/VulnerabilityManagement/constants';
import {
  getParamsFromVulnerabilitiesStatusFilter,
  getTableColumns,
  getTotalRow,
} from 'pages/VulnerabilityManagement/helpers';
import { Box, Card, Icon } from 'shared';
import { confirmedVulnerabilitiesFilter } from 'pages/VulnerabilitiesList/constants';
import { useTheme } from 'styled-components';
import { LinkContainer } from 'shared/Link/LinkContainer';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { IFilterField } from 'pages/CEPlus/constants';
import { validateField, validateFieldValue } from 'pages/CEPlus/helpers';
import { TableWithSmallFieldsStyled } from '../styled/styled';
import { ExcludeFilterTooltip } from '../ExcludeFilterTooltip';

export const VulnerabilitiesWithMostAffectedHosts = () => {
  const theme = useTheme();
  const { selectedTags } = useVulnerability();
  const vulnerabilitiesWithMostAffectedHosts = useVulnerabilitiesWithMostAffectedHosts();
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useLoading([
    vulnerabilitiesApi.getVulnerabilitiesWithMostAffectedHosts.typePrefix,
  ]);
  const vulnerabilitiesFilterbyStatus = useExcludedVulnerabilitiesStatusFilter() as string[];

  // const vulnerabilitiesParams = {
  //   orderBy: {
  //     field: 'affectedHosts',
  //     type: OrderBy.type.DESC,
  //   },
  //   pagination: initialPagination,
  //   filter: { ...confirmedVulnerabilitiesFilter },
  //   tab: 'active',
  // };
  const vulnerabilitiesParams = useMemo(() => {
    const filterFields: IFilterField[] = [
      {
        name: 'severity',
        value: [severityValues.Urgent, severityValues.Critical],
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'tagsAssets',
        value: selectedTags || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
      { ...confirmedVulnerabilitiesFilter.fields[0] },
    ];

    return {
      orderBy: {
        field: 'affectedHosts',
        type: OrderBy.type.DESC,
      },
      pagination: initialPagination,
      filter: {
        fields: validateField(filterFields),
      },
      tab: 'active',
    };
  }, [selectedTags]);
  const getParamsForVulnerabilityDetails = (tags?: string[], isSuppressed = false) => {
    const filterFields: IFilterField[] = [
      {
        name: 'tagsAssets',
        value: tags,
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
    ];
    const fields = validateField(filterFields);

    return {
      filter: {
        fields,
        ...(isSuppressed && { suppressedVulnerabilities: true }),
      },
    };
  };

  const tableRedirectToVulnerabilityDetails = React.useCallback(
    (record: TopVulnerabilitySchema) => ({
      onClick: () => {
        if (record.id === TotalRowKey.Impact) {
          return;
        }

        const queryString = getQueryString(
          getParamsForVulnerabilityDetails(selectedTags, record.suppressed),
        );

        history.push(`${Routes.VULNERABILITIES_LIST}/${record.id}${queryString}`, {
          backTo: {
            route: Routes.VULNERABILITY_MANAGEMENT,
            title: 'Back to Vulnerability management',
          },
        });
      },
    }),
    [history, selectedTags],
  );

  React.useEffect(() => {
    dispatch(
      vulnerabilitiesApi.getVulnerabilitiesWithMostAffectedHosts({
        customerId,
        count: TOP_COUNT,
        criticalities: [SeverityLevel._4, SeverityLevel._5],
        tagIds: selectedTags || [],
        ...getParamsFromVulnerabilitiesStatusFilter(vulnerabilitiesFilterbyStatus),
      }),
    );
  }, [dispatch, customerId, vulnerabilitiesFilterbyStatus, selectedTags]);

  return (
    <Card
      title={VulnerabilitiesTitles.TopTenVulnerabilities}
      showDivider={false}
      tooltipTitle={TooltipTitles.TopTenVulnerabilities}
      headStyles={{
        headAlign: 'flex-start',
        titlePaddingRight: isMobile ? theme.sizes[0] : theme.sizes[5],
      }}
      cardPadding={!isMobile ? `${theme.sizes[6]} ${theme.sizes[3]}` : theme.sizes[5]}
      bodyPadding={
        !isMobile ? `${theme.sizes[2]} ${theme.sizes['2.5']} ${theme.sizes[0]}` : theme.sizes[0]
      }
      height={theme.sizes.full}
      showLoader={loading[vulnerabilitiesApi.getVulnerabilitiesWithMostAffectedHosts.typePrefix]}
      subTitle={
        <SubTitle className='exclude-filter-icon'>
          <ExcludeFilterTooltip />
        </SubTitle>
      }
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        h={theme.sizes.full}
      >
        <TableWithSmallFieldsStyled<React.FC<TableProps<TopVulnerabilitySchema>>>
          columns={getTableColumns(
            vulnerabilitiesWithMostAffectedHosts.qualysHostsCount || 1,
            vulnerabilitiesWithMostAffectedHosts.affectedHostsVulnerabilitiesCount,
            vulnerabilitiesWithMostAffectedHosts.totalActiveVulnerabilitiesCount,
          )}
          dataSource={
            vulnerabilitiesWithMostAffectedHosts &&
            vulnerabilitiesWithMostAffectedHosts.vulnerabilities?.length
              ? [getTotalRow(), ...vulnerabilitiesWithMostAffectedHosts.vulnerabilities]
              : undefined
          }
          tableLayout='fixed'
          pagination={false}
          rowKey={(record: TopVulnerabilitySchema) => record.id as React.Key}
          onRow={tableRedirectToVulnerabilityDetails}
          rowClassName={(record: TopVulnerabilitySchema) =>
            record.id === TotalRowKey.Impact ? 'table-row-dark' : ''
          }
          locale={{ emptyText: emptyMessageConfig }}
          isClickable
        />

        <LinkContainer>
          <Link to={`${Routes.VULNERABILITIES_LIST}${getQueryString(vulnerabilitiesParams)}`}>
            See all vulnerabilities
            <Icon component={theme.icons.arrow_right_alt} />
          </Link>
        </LinkContainer>
      </Box>
    </Card>
  );
};
