import { OrderBy } from 'services/api';

export const BUSINESS_REASON_MAX_LENGTH = 512;
export const sectionTitle = 'Suppression status';
export const suppressionTooltip =
  'Please ensure you review suppressed vulnerabilities every 90 days for security maintenance';
export const emptyTableContent = { textContent: 'No suppression history' };

export const suppressionLabels = {
  suppressed: 'Suppressed',
  reviewed: 'Reviewed',
  unsuppressed: 'Not suppressed',
};

export enum FieldNames {
  Status = 'status',
  BusinessReason = 'businessReason',
}

export const FormFields = {
  status: {
    name: 'status',
    label: 'Suppression status',
  },
  businessReason: {
    name: 'businessReason',
    label: 'Business reason',
    required: true,
    maxLength: BUSINESS_REASON_MAX_LENGTH,
    placeholder: 'Please provide a business reason for suppression status update',
    height: '64px',
  },
};

export const formErrorMessages: Record<FieldNames, Record<string, string>> = {
  [FieldNames.Status]: {
    isRequired: 'Status is required',
  },
  [FieldNames.BusinessReason]: {
    isRequired: 'Business Reason field is required',
    maxLength: `Max length - ${BUSINESS_REASON_MAX_LENGTH}`,
  },
};

export const suppressionModalTitle = 'Suppression status update';

export enum TableTitles {
  Status = 'Update',
  SuppressedBy = 'Updated by',
  BusinessReason = 'Business reason',
  UpdatedAt = 'Updated at',
}

export enum ColumnKey {
  Status = 'status',
  SuppressedBy = 'suppressedBy',
  BusinessReason = 'businessReason',
  UpdatedAt = 'date',
}

export const initialOrderBy = {
  field: 'updated',
  type: OrderBy.type.DESC,
};

export const defaultPageSize = 10;
export const defaultCurrent = 1;
