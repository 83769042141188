import styled from 'styled-components';
import { Descriptions as SharedDescriptions } from 'shared';
import { Theme } from 'styles/theme/types';

type Props = {
  theme: Theme;
};

export const Descriptions = styled(SharedDescriptions)`
  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    padding-top: ${({ theme }: Props) => theme.sizes[4]};
  }
  .ant-descriptions-row {
    .ant-descriptions-item {
      padding: ${({ theme }: Props) => `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[7]}`};

      .ant-descriptions-item-label {
        width: ${({ theme }: Props) => theme.sizes[36]};
        align-items: center;
        font-weight: ${({ theme }: Props) => theme.fontWeights.medium};
        color: ${({ theme }: Props) => theme.colors.blue[900]};

        &:after {
          display: none;
        }
      }

      .anticon {
        color: ${({ theme }: Props) => theme.colors.brown[500]};
      }
    }

    &:last-child {
      .ant-descriptions-item {
        padding-bottom: ${({ theme }: Props) => theme.sizes[0]};

        @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
          padding-bottom: ${({ theme }: Props) => theme.sizes[4]};
        }
      }
    }
  }
`;
