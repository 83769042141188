import React from 'react';
import { Modal as GeneralModal } from 'shared/Modal/Modal';
import { Form } from './Form/Form';
import { DataForm } from '../types';

interface IProps {
  isModalShown: boolean;
  isFormShown: boolean;
  hideModal: () => void;
  preparedDataForm?: DataForm;
  setPreparedDataForm?: (value: DataForm) => void;
  title?: string;
}

export const Modal = ({
  isModalShown,
  isFormShown,
  hideModal,
  preparedDataForm,
  setPreparedDataForm,
  title,
}: IProps) => {
  return (
    <GeneralModal
      width={isFormShown ? '720px' : '800px'}
      title={title}
      visible={isModalShown}
      onCancel={hideModal}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        hideModal={hideModal}
        preparedDataForm={preparedDataForm}
        setPreparedDataForm={setPreparedDataForm}
        title={title}
      />
    </GeneralModal>
  );
};
