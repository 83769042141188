import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { RangePicker } from '../DatePicker/styled';

export const RadioButtonsWithRangePickerContaner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }: { theme: Theme }) =>
    `${theme.sizes[3]} ${theme.sizes[0]} ${theme.sizes[0]}`};

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    margin: ${({ theme }: { theme: Theme }) =>
      `${theme.sizes['2.5']} ${theme.sizes[0]} ${theme.sizes[0]}`};

    .ant-radio-group {
      .ant-radio-button-wrapper {
        padding: ${({ theme }: { theme: Theme }) =>
          `${theme.sizes[0]} ${theme.sizes[3]} !important`};
        font-size: ${({ theme }: { theme: Theme }) => theme.sizes['3.5']};
      }
    }

    > div:last-child {
      order: -1;
    }
  }
`;

export const StyledRangePicker = styled(RangePicker)`
  width: 270px;
`;
