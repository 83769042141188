import React from 'react';
import { TableProps } from 'antd';
import { EmptyTablePreview, Table } from 'shared';
import { processApi, useLoading } from 'store';
import { DownloadSchema } from 'services/api';
import { getTableColumns } from './TableColumns';
import { ExpiredCertificatesRow } from '../../types';

interface IProps {
  onDownloadFile: (name: string) => void;
  expiredCertificates?: DownloadSchema[];
  downloading: string;
  setClickOn: (clickOn: string) => void;
}

export const ExpiredCertificatesTable = ({
  expiredCertificates,
  onDownloadFile,
  downloading,
  setClickOn,
}: IProps) => {
  const loading = useLoading([processApi.getAllCertificates.typePrefix]);

  const emptyMessage = (
    <EmptyTablePreview
      title='No expired certificates yet'
      loading={loading[processApi.getAllCertificates.typePrefix]}
      marginTop='20px'
      withoutMarginTop
    />
  );

  return (
    <Table<React.FC<TableProps<ExpiredCertificatesRow>>>
      columns={getTableColumns(onDownloadFile, downloading, setClickOn)}
      dataSource={expiredCertificates}
      pagination={false}
      tableLayout='auto'
      rowKey={(record) => record.name || record.lastUpdated || ''}
      loading={loading[processApi.getAllCertificates.typePrefix]}
      locale={{ emptyText: emptyMessage }}
    />
  );
};
