import { Row } from 'antd';
import styled from 'styled-components';
import { ThemeProps } from 'services/interfaces';
import { getCssVar } from 'styles/theme';

type Props = ThemeProps & {
  $withoutMarginTop?: boolean;
};

type TextContentProps = ThemeProps & {
  alignCenter?: boolean;
};

export const EmptyTablePreviewContainer = styled(Row)<Props>`
  justify-content: center;
  text-align: center;

  .ant-space {
    align-items: center;

    .anticon {
      width: auto;
      height: auto;
    }

    h2 {
      color: ${getCssVar('textColor')};
      font-weight: 700;
      margin-top: ${({ theme, $withoutMarginTop }) =>
        $withoutMarginTop ? theme.spacing[0] : theme.spacing[3]};
      margin-bottom: ${({ theme, $withoutMarginTop }) => $withoutMarginTop && theme.spacing[1]};
    }

    .ant-btn > span {
      font-size: ${({ theme }) => theme.sizes[4]};
    }

    p {
      color: ${getCssVar('table.tr.emptyColor')};

      font-size: 15px;
      margin-bottom: 0;
    }
  }
`;

export const TextContent = styled.p<TextContentProps>`
  color: ${getCssVar('table.tr.emptyColor')};
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')};
`;

export const Title = styled.h2<ThemeProps>`
  color: ${getCssVar('table.tableItem.color')};
  font-size: ${({ theme }) => theme.sizes['5']};
`;
