import { Filter, OrderBy } from 'services/api';
import { Theme } from 'styles/theme/types';
import { Routes } from '../../services/entities';
import { backlinkRoutes } from '../../app-constants';
import { CEPlusEntryType, cePlusPieChartsTooltips } from '../../shared/charts/formatters';

export interface IVulnerabilitiesDateParams {
  fromFirstFound?: number;
  toFirstFound?: number;
}

export interface IFilterField {
  value: unknown;
  name: string;
  type: Filter.type;
  negate?: boolean;
  validate?: (value: unknown) => boolean;
}

export interface ICMDBRedirectProps {
  osType?: string;
  publisher?: string;
  product?: string;
  osVersion?: string;
  tagsAssets?: string[];
}

export enum CEStatus {
  Compliant = 'Compliant',
  NotCompliant = 'Not compliant',
}

export const opacityStatusColor = {
  green: '#43a04733',
  red: '#eb644a33',
};

export const tooltipTitles = {
  openVulnerabilities: 'All active vulnerabilities with a CVSS score of 7 or above',
  totalAssetsInScope:
    'The number of assets where vulnerabilities with a CVSS score of 7 or above have been found in the last 365 days - highlighting those that have not yet been fixed',
  totalVulnerabilitiesInScope:
    'The number of vulnerabilities with a CVSS score of 7 or above that have been found in the last 365 days - highlighting those that have not yet been fixed',
  vendorPatchAvailable:
    'The percentage of active vulnerabilities that can be resolved by deploying a vendor-supplied patch',
  operatingSystemDistribution:
    'The quantity of different operating systems being used in your environment',
  topClientOperatingSystem: 'The most common operating systems installed on your end-user devices',
  topServerOperatingSystem: 'The most common operating systems installed on your servers',
  windows10Versions: 'Your most commonly installed versions of Windows 10',
  hardwareManufacturer: 'Your devices sorted by hardware manufacturer',
  topClientHardware: 'Your most common end-user devices sorted by system model',
  topServerHardware: 'Your most common server devices sorted by system model',
};

export const getTopClientOperatingSystemChartColors = (theme: Theme) => [
  theme.colors.green[100],
  theme.colors.red[300],
  theme.colors.purple[300],
  theme.colors.blue[400],
  theme.colors.red[600],
];

export const getTopServerOperatingSystemChartColors = (theme: Theme) => [
  theme.colors.purple[400],
  theme.colors.red[600],
  theme.colors.red[300],
  theme.colors.blue[400],
  theme.colors.green[300],
];

export const DEFAULT_OPERATING_SYSTEM = 'Windows 10';

export const DAYS_ON_WEEK = 7;

export const DEFAULT_DAYS_ON_MONTH = 30;

export const DEFAULT_DAYS_ON_YEAR = 365;

export const DEFAULT_LIMIT = 5;

export const DEFAULT_CVSS_SCORE = 7;

// We need this empty filter field to correct reset filters if have just filters, without filter fields
// todo refactor in future
export const HIDDEN_FILTER_EMPTY_FIELD = {};

export enum VulnerabilityDetectionsGap {
  LAST_WEEK = 'lastWeek',
  PENULTIMATE_WEEK = 'penultimateWeek',
  MORE_THEN_TWO_WEEKS = 'moreThenTwoWeeks',
}

export enum VulnerabilityDaysGap {
  LAST_WEEK = '0-7 days',
  PENULTIMATE_WEEK = '8-14 days',
  MORE_THEN_TWO_WEEKS = '14+ days',
}

export const initialVulnerabilityOrderBy = {
  field: 'severity',
  type: OrderBy.type.DESC,
};

export const initialCMDBOrderBy = {
  field: '_vulnerabilities',
  type: OrderBy.type.DESC,
};

export const cePlusBackRoute = {
  backTo: {
    route: Routes.CE_PLUS,
    title: `Back to ${backlinkRoutes[Routes.CE_PLUS]}`,
  },
};

export const totalAssetsInScopeQueryParams = {
  fields: [
    {
      name: 'countVulnerabilitiesWithBaseScoreMoreThen7',
      value: 'true',
      type: Filter.type.VALUE,
    },
  ],
};
export const totalVulnerabilitiesInScopeQueryParams = {
  cvssScore: 7,
  fields: [HIDDEN_FILTER_EMPTY_FIELD],
};

export const vendorPatchAvailableQueryParams = {
  fields: [
    {
      name: 'countVulnerabilitiesWithBaseScoreMoreThen7',
      value: 'true',
      type: Filter.type.VALUE,
    },
    {
      name: 'patchable',
      value: 'patch',
      type: Filter.type.VALUE,
    },
  ],
};

export const fixedVendorPatchAvailableQueryParams = {
  fields: [
    {
      name: 'countFixedVulnerabilitiesWithBaseScoreMoreThen7',
      value: 'true',
      type: Filter.type.VALUE,
    },
    {
      name: 'patchable',
      value: 'patch',
      type: Filter.type.VALUE,
    },
  ],
};

export const MIN_CHART_DATA_VALUE = 0;
export const MAX_CHART_TEXT_LENGTH = 15;
export const DOT_LENGTH = 3;

export const MAX_EVENTS_COUNT = 10;
export const MIN_CHART_HEIGHT = 350;
export const ITEM_CHART_HEIGHT = 50;

export const IGNORE_DEFAULT_FILED_NAME = 'ignore_field';

export const CustomPieChartTooltip = (theme: Theme) => ({
  tooltip: {
    trigger: 'item',
    formatter: (params: CEPlusEntryType) => cePlusPieChartsTooltips(params),
    axisPointer: {
      type: 'shadow',
    },
    textStyle: {
      color: theme.colorSet.charts.tooltip.color,
    },
    backgroundColor: theme.colorSet.charts.tooltip.bg,
    borderColor: theme.colorSet.charts.tooltip.bg,
  },
});
