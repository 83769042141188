import { Box } from 'shared';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';

type Props = { theme: Theme };

export const FilterRadioContainer = styled(Box)`
  align-items: center;
  display: flex;
  height: 69px;
  justify-content: center;
  padding: 10px 5px 0 5px;

  label {
    padding-top: 10px;
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.xs}) {
    padding: 0 5px 10px 5px;
  }
`;
