import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAuth, useCustomer } from 'store';
import { DeleteDropdown } from 'pages/Cmdb/components/DeleteDropdown';
import { ActionPopup, Button, Icon } from 'shared';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { icons } from 'styles/theme';
import { showDeletePopup } from 'pages/Cmdb/helpers';
import { popupConfig as staticPopupConfig } from 'pages/Cmdb/constants';
import { ButtonContainer } from './styled';

type RemoveAssetProps = {
  hostData: {
    id?: string;
    hostName?: string;
  };
  onSuccess: () => void;
};

export const RemoveAsset = ({ hostData, onSuccess }: RemoveAssetProps) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupConfig, setPopupConfig] = useState<PopupConfigType>(staticPopupConfig.delete);
  const customerId = useCustomer();
  const { user } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const showModal = useCallback(
    (soft?: boolean) => {
      showDeletePopup({
        setPopupConfig,
        setIsPopupVisible,
        hostData: [hostData],
        dispatch,
        customerId,
        onSuccess,
        isNormUser: user.isSuperAdmin,
        soft,
      });
    },
    [customerId, dispatch, hostData, onSuccess, user.isSuperAdmin],
  );

  return (
    <>
      <ButtonContainer>
        {user.isSuperAdmin ? (
          <DeleteDropdown
            onClick={(soft: boolean) => {
              showModal(soft);
            }}
          >
            <Button type='link' icon={<Icon component={icons.delete} />}>
              Delete
            </Button>
          </DeleteDropdown>
        ) : (
          <Button type='link' icon={<Icon component={icons.delete} />} onClick={() => showModal()}>
            Delete
          </Button>
        )}
      </ButtonContainer>
      {isPopupVisible && (
        <ActionPopup
          isVisible={isPopupVisible}
          setIsVisiblePopup={setIsPopupVisible}
          popupDetails={popupConfig}
        />
      )}
    </>
  );
};
