import React from 'react';
import { NewActions, NewGoals, Wrapper } from './styled';

export function PdfPage14() {
  return (
    <Wrapper>
      <NewGoals>
        <p>
          Goal 1: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid animi beatae,
          cumque distinctio dolore enim fugit harum.
        </p>
        <p>
          Goal 2: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid animi beatae,
          cumque distinctio dolore enim fugit harum.
        </p>
        <p>
          Goal 3: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid animi beatae,
          cumque distinctio dolore enim fugit harum.
        </p>
        <p>
          Goal 4: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid animi beatae,
          cumque distinctio dolore enim fugit harum.
        </p>
      </NewGoals>
      <NewActions>
        <ul>
          <li>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad amet aut commodi deserunt
            ea eius excepturi fuga harum ipsa molestiae necessitatibus nesciunt, nisi nostrum
            officiis placeat, quibusdam reiciendis veniam voluptatum! Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Ad amet aut commodi deserunt ea eius excepturi fuga harum
            ipsa molestiae necessitatibus nesciunt, nisi nostrum officiis placeat, quibusdam
            reiciendis veniam voluptatum! Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Ad amet aut commodi deserunt ea eius excepturi fuga harum ipsa molestiae necessitatibus
            nesciunt, nisi nostrum officiis placeat, quibusdam reiciendis veniam voluptatum.
          </li>
          <li>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad amet aut commodi deserunt
            ea eius excepturi fuga harum ipsa molestiae necessitatibus nesciunt, nisi nostrum
            officiis placeat, quibusdam reiciendis veniam voluptatum! Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Ad amet aut commodi deserunt ea eius excepturi fuga harum
            ipsa molestiae necessitatibus nesciunt, nisi nostrum officiis placeat, quibusdam
            reiciendis veniam voluptatum! Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </li>
        </ul>
      </NewActions>
    </Wrapper>
  );
}
