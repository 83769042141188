export const cvssV2BaseScoreMetrics = [
  { metricValue: 'Access Vector (AV)', displayed: '' },
  { metricValue: 'Local', displayed: 'L' },
  { metricValue: 'Adjacent Network', displayed: 'A' },
  { metricValue: 'Network', displayed: 'N' },
  { metricValue: 'Access Complexity (AC)', displayed: '' },
  { metricValue: 'Low', displayed: 'L' },
  { metricValue: 'Medium  ', displayed: 'M' },
  { metricValue: 'High', displayed: 'H' },
  { metricValue: 'Authentication (Au)', displayed: '' },
  { metricValue: 'None', displayed: 'N' },
  { metricValue: 'Single', displayed: 'S' },
  { metricValue: 'Multiple', displayed: 'M' },
  { metricValue: 'Confidentiality Impact (C)', displayed: '' },
  { metricValue: 'None', displayed: 'N' },
  { metricValue: 'Partial', displayed: 'P' },
  { metricValue: 'Complete', displayed: 'C' },
  { metricValue: 'Integrity Impact (I)', displayed: '' },
  { metricValue: 'None', displayed: 'N' },
  { metricValue: 'Partial', displayed: 'P' },
  { metricValue: 'Complete', displayed: 'C' },
  { metricValue: 'Availability Impact (A)', displayed: '' },
  { metricValue: 'None', displayed: 'N' },
  { metricValue: 'Partial', displayed: 'P' },
  { metricValue: 'Complete', displayed: 'C' },
];

export const cvssV2TemporalScoreMetrics = [
  { metricValue: 'Exploitability (E)', displayed: '' },
  { metricValue: 'Not Defined', displayed: 'ND' },
  { metricValue: 'Unproven', displayed: 'U' },
  { metricValue: 'Proof-of-Concept', displayed: 'POC' },
  { metricValue: 'Functional  ', displayed: 'F' },
  { metricValue: 'High', displayed: 'H' },
  { metricValue: 'Remediation Level (RL)', displayed: '' },
  { metricValue: 'Not Defined', displayed: 'ND' },
  { metricValue: 'Official Fix  ', displayed: 'OF' },
  { metricValue: 'Temporary Fix', displayed: 'TF' },
  { metricValue: 'Workaround', displayed: 'W' },
  { metricValue: 'Unavailable', displayed: 'U' },
  { metricValue: 'Report Confidence (RC)', displayed: '' },
  { metricValue: 'Not Defined', displayed: 'ND' },
  { metricValue: 'Unconfirmed', displayed: 'UC' },
  { metricValue: 'Uncorroborated', displayed: 'UR' },
  { metricValue: 'Confirmed', displayed: 'C' },
];

export const cvssV3BaseScoreMetrics = [
  { metricValue: 'Attack Vector (AV)', displayed: '' },
  { metricValue: 'Network', displayed: 'N' },
  { metricValue: 'Adjacent Network', displayed: 'A' },
  { metricValue: 'Local', displayed: 'L' },
  { metricValue: 'Physical', displayed: 'P' },
  { metricValue: 'Attack Complexity (AC)', displayed: '' },
  { metricValue: 'Low', displayed: 'L' },
  { metricValue: 'High  ', displayed: 'H' },
  { metricValue: 'Privileges Required (PR)', displayed: '' },
  { metricValue: 'None', displayed: 'N' },
  { metricValue: 'Low', displayed: 'L' },
  { metricValue: 'High', displayed: 'H' },
  { metricValue: 'User Interaction (UI)', displayed: '' },
  { metricValue: 'None', displayed: 'N' },
  { metricValue: 'Required', displayed: 'R' },
  { metricValue: 'Scope', displayed: '' },
  { metricValue: 'Unchanged', displayed: 'U' },
  { metricValue: 'Changed', displayed: 'C' },
  { metricValue: 'Confidentiality Impact (C)', displayed: '' },
  { metricValue: 'None  ', displayed: 'N' },
  { metricValue: 'Low', displayed: 'L' },
  { metricValue: 'High', displayed: 'H' },
  { metricValue: 'Integrity Impact (I)', displayed: '' },
  { metricValue: 'None  ', displayed: 'N' },
  { metricValue: 'Low', displayed: 'L' },
  { metricValue: 'High', displayed: 'H' },
  { metricValue: 'Availability Impact (A)', displayed: '' },
  { metricValue: 'None', displayed: 'N' },
  { metricValue: 'Low', displayed: 'L' },
  { metricValue: 'High', displayed: 'H' },
];

export const cvssV3TemporalScoreMetrics = [
  { metricValue: 'Exploit Code Maturity (E)', displayed: '' },
  { metricValue: 'Not Defined', displayed: 'X' },
  { metricValue: 'Unproven', displayed: 'U' },
  { metricValue: 'Proof-of-Concept', displayed: 'P' },
  { metricValue: 'Functional', displayed: 'F' },
  { metricValue: 'High', displayed: 'H' },
  { metricValue: 'Remediation Level (RL)', displayed: '' },
  { metricValue: 'Not Defined', displayed: 'X' },
  { metricValue: 'Official Fix', displayed: 'O' },
  { metricValue: 'Temporary Fix', displayed: 'T' },
  { metricValue: 'Workaround  ', displayed: 'W' },
  { metricValue: 'Unavailable', displayed: 'U' },
  { metricValue: 'Report Confidence (RC)', displayed: '' },
  { metricValue: 'Not Defined  ', displayed: 'X' },
  { metricValue: 'Unknown', displayed: 'U' },
  { metricValue: 'Reasonable  ', displayed: 'R' },
  { metricValue: 'Confirmed', displayed: 'C' },
];
