import React from 'react';
import { Chart, PenetrationTestingText, Wrapper } from './styled';

export function PdfPage11() {
  return (
    <Wrapper>
      <PenetrationTestingText>
        <b>Summary of Penetration Test Results</b>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet deserunt facilis iste
          praesentium quas veritatis. Atque aut consequuntur doloremque ea eius eligendi, error
          expedita natus quia repellat, vitae voluptas voluptates. Lorem ipsum dolor sit amet,
          consectetur adipisicing elit. Amet deserunt facilis iste praesentium quas veritatis. Atque
          aut consequuntur doloremque ea eius eligendi, error expedita natus quia repellat, vitae
          voluptas voluptates. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet
          deserunt facilis iste praesentium quas veritatis. Atque aut consequuntur doloremque ea.
        </p>
      </PenetrationTestingText>
      <Chart>
        <b>Coming soon</b>
      </Chart>
    </Wrapper>
  );
}
