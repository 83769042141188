import React, { useCallback } from 'react';
import { allowedUploadOnlyPDF, maxFileSizeLabel } from 'shared/UploadPopup/constants';
import { Button, Icon } from 'shared';
import { getCssVar, icons } from 'styles/theme';
import { showMessage } from 'services/helpers';
import { MessageType } from 'store';
import { MaxSize, SpaceStyled } from '../styled';
import { DocumentStyled } from '../../Documents/components/Document/styled';

interface IProps {
  isFileAttached: boolean;
  attachedFile: File;
  setAttachedFile: (file: File) => void;
  setIsFileAttached: (isFileAttached: boolean) => void;
}

export const Uploader = ({
  isFileAttached,
  attachedFile,
  setAttachedFile,
  setIsFileAttached,
}: IProps) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const clearAttachedFile = useCallback(() => {
    setIsFileAttached(false);
    const input = inputRef.current;

    if (input) {
      input.value = '';
    }
  }, [setIsFileAttached, inputRef]);

  const handleAttachButton = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  const onHandleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const fileUploaded = event.currentTarget.files;

      if (fileUploaded?.[0].type !== 'application/pdf') {
        showMessage({ content: 'Pdf (only) files can be uploaded.' }, MessageType.Error);

        return;
      }

      if (fileUploaded) {
        setAttachedFile(fileUploaded[0]);
        setIsFileAttached(true);
      }
    },
    [setIsFileAttached, setAttachedFile],
  );

  return (
    <>
      <SpaceStyled direction='vertical' size={4}>
        {!isFileAttached ? (
          <Button
            onClick={handleAttachButton}
            type='link'
            icon={<Icon component={icons.attach_file} />}
          >
            Choose File
          </Button>
        ) : (
          <DocumentStyled file={attachedFile} clear={clearAttachedFile} />
        )}

        {!isFileAttached && (
          <span style={{ color: getCssVar('textColor') }}>Pdf files can be uploaded.</span>
        )}
        {!isFileAttached && <MaxSize className='label'>Max size: {maxFileSizeLabel}</MaxSize>}
      </SpaceStyled>
      <input
        hidden
        type='file'
        onChange={onHandleChange}
        ref={inputRef}
        accept={allowedUploadOnlyPDF}
      />
    </>
  );
};
