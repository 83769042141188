import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  PatchManagementPlatform,
  PatchManagementService,
  PatchStatusFilter,
} from 'services/api';

const getAssetsAwaitingRebootCount = createAsyncThunk(
  'patch-management/awaitingReboot/get',
  async (requestBody: {
    customerId: string;
    platform: PatchManagementPlatform;
    isPendingReboot: boolean;
  }) => {
    try {
      return await PatchManagementService.getAssetsCount(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getAllAssets = createAsyncThunk(
  'patch-management/allAssets/get',
  async (requestBody: { customerId: string; platform: PatchManagementPlatform }) => {
    try {
      return await PatchManagementService.getAssetsCount(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getMissingPatchesByPlatform = createAsyncThunk(
  'patch-management/missingAssets/get',
  async (requestBody: { customerId: string; platform: PatchManagementPlatform }) => {
    try {
      return await PatchManagementService.getAssetsMissingPatchesByPlatform(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getPatchesByOSStatus = createAsyncThunk(
  'patch-management/patchesByOSStatus/get',
  async (requestBody: { customerId: string; platform: PatchManagementPlatform }) => {
    try {
      return await PatchManagementService.getPatchesCountByStatus(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getPatchesByStatus = createAsyncThunk(
  'patch-management/patchesByStatus/get',
  async (requestBody: {
    customerId: string;
    patchStatus: PatchStatusFilter;
    platform: PatchManagementPlatform;
  }) => {
    try {
      return await PatchManagementService.getPatchesCount(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getPatchesGroupedBy = createAsyncThunk(
  'patch-management/patchesGroupedBy/get',
  async (requestBody: {
    customerId: string;
    patchStatus: PatchStatusFilter;
    groupBy: string[];
    platform: PatchManagementPlatform;
  }) => {
    try {
      return await PatchManagementService.getPatchesCountGrouppedby(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const patchDashboardApi = {
  getAllAssets,
  getAssetsAwaitingRebootCount,
  getMissingPatchesByPlatform,
  getPatchesByOSStatus,
  getPatchesByStatus,
  getPatchesGroupedBy,
};
