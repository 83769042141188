import { AppDispatch, documentsApi } from 'store';
import { RequestStatus } from 'store/loading/slice';
import { CertificateName, DocumentType } from 'services/api';
import { showError } from 'services/helpers';
import { chunkSize } from '../../shared/UploadPopup/constants';

interface IProps {
  dispatch: AppDispatch;
  customerId: string;
  attachedFile: File;
  documentType: DocumentType;
  certificateName?: string | undefined;
  setLoading?: (loading: boolean) => void;
}

export const uploadDocument = ({
  dispatch,
  customerId,
  attachedFile,
  documentType,
  certificateName,
  setLoading,
}: IProps) => {
  return new Promise<void>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(attachedFile);
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const blob = new Blob([e?.target?.result as ArrayBuffer], {
        type: 'application/octet-stream',
      });
      const totalChunks = Math.ceil(blob.size / chunkSize);

      const result = await dispatch(
        documentsApi.uploadFile({
          currentChunkIndex: 0,
          fileSize: blob.size,
          totalChunks,
          customerId,
          filename: attachedFile.name,
          documentType,
          requestBody: blob,
          ...(certificateName && { certificateName: certificateName as CertificateName }),
        }),
      );

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        showError();
        setLoading?.(false);
        reject();
      }

      resolve();
    };
  });
};
