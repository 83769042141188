import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { ThemeProps } from '../services/interfaces';

interface IChartWrapper {
  isCursorPointer?: boolean;
}

type Props = { theme: Theme; withoutPaddingBottom?: boolean };

export const TimeFromLastUpdate = styled.div<ThemeProps>`
  font-size: 14px;
  opacity: 0.5;
`;

export const ChartWrapper = styled.div<IChartWrapper>`
  svg,
  canvas {
    cursor: ${({ isCursorPointer }) => isCursorPointer && 'pointer'};
  }
`;

export const TimeAgoWrap = styled.span`
  color: ${({ theme }: Props) => theme.colors.blue[900]};
  font-size: ${({ theme }: Props) => `${theme.sizes['3.5']}`};
  opacity: 50%;

  &.expired {
    color: ${({ theme }: Props) => theme.colors.red[700]};
    opacity: 1;
  }
`;
