export enum PatchDetailsTitle {
  Description = 'Description',
  Type = 'Type',
  Architecture = 'Architecture',
  BulletinID = 'Bulletin ID',
  KB = 'KB',
  Published = 'Published',
  Modified = 'Modified',
  Rollback = 'Rollback',
  QualysPatchable = 'Qualys patchable',
  DownloadMethod = 'Download method',
  CVEs = 'CVEs',
  Reboot = 'Reboot may be required',
  ServicePack = 'Service pack',
  URLs = 'URLs',
}
