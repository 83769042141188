import React from 'react';
import { List as AntdList } from 'antd';
import { ListTitle, StyledList } from './List.styled';

type Props = {
  title?: string;
  items: Array<{ id: string; component: React.ReactElement }>;
};

export function List({ title, items }: Props) {
  return (
    <StyledList>
      {title && <ListTitle>{title}</ListTitle>}
      <AntdList>
        {items.map(({ id, component }) => (
          <AntdList.Item key={id}>{component}</AntdList.Item>
        ))}
      </AntdList>
    </StyledList>
  );
}
