import {
  CustomerProductsListSchema,
  SupportRequestsImpact,
  SupportRequestsPriority,
  SupportRequestsType,
} from 'services/api';

interface IProps {
  isServiceRequest?: boolean;
  purchaseProduct?: string | null;
  purchaseCertificate?: string | null;
  customerProductsList?: CustomerProductsListSchema[];
}

export const getInitialValues = ({
  isServiceRequest,
  purchaseProduct,
  purchaseCertificate,
  customerProductsList,
}: IProps) => ({
  ...(isServiceRequest && {
    product: purchaseProduct || purchaseCertificate || customerProductsList?.[0] || '',
  }),
  ...(!isServiceRequest && { priority: SupportRequestsPriority._1 }),
  ...(!isServiceRequest && { impact: SupportRequestsImpact.HIGH }),
  summary:
    purchaseProduct || purchaseCertificate
      ? `Request to purchase ${purchaseProduct || purchaseCertificate}`
      : '',
  description: '',
  type: isServiceRequest
    ? SupportRequestsType.SERVICE_REQUEST
    : SupportRequestsType.SECURITY_INCIDENT,
});
