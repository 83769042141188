import React from 'react';
import { VulnerabilityChangesPointSchema } from 'services/api';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { startCase, toLower, upperFirst } from 'lodash';

type VulnerabilitiesChangesProps = {
  chart?: Array<VulnerabilityChangesPointSchema>;
  totalChange?: number;
};

export const VulnerabilityManagement = ({ chart, totalChange }: VulnerabilitiesChangesProps) => {
  const sortValues = (values: VulnerabilityChangesPointSchema[]) =>
    [...values].sort(
      (valueA: VulnerabilityChangesPointSchema, valueB: VulnerabilityChangesPointSchema) => {
        if (valueA.criticality && valueB.criticality) {
          return Number(valueB.criticality) - Number(valueA.criticality);
        }

        return 0;
      },
    );

  return (
    <>
      <table className='mt-2'>
        <tbody>
          <tr>
            <td>
              <h4>VULNERABILITY CHANGES THIS MONTH</h4>
            </td>
            <td className='text-right'>
              <h4>
                <span>TOTAL CHANGE: </span>
                {totalChange && (
                  <span className={totalChange > 0 ? 'color-red' : 'color-green'}>
                    {totalChange > 0 && '+'}
                    {totalChange}
                  </span>
                )}
              </h4>
            </td>
          </tr>
        </tbody>
      </table>

      <table className='table table-border-vr text-center '>
        <tbody>
          <tr>
            {chart &&
              sortValues(chart).map((item) => (
                <td key={item.criticality}>
                  <p className='h3 text-normal mb-1 text-center'>
                    {item.count} (
                    {item.previousDifference && (
                      <span className={item.previousDifference > 0 ? 'color-red' : 'color-green'}>
                        {item.previousDifference > 0 && '+'}
                        {item.previousDifference}
                      </span>
                    )}
                    )
                  </p>
                  <p className='text-center'>
                    {upperFirst(toLower(startCase(getSeverityLevelName(item.criticality || ''))))}
                  </p>
                </td>
              ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};
