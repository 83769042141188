/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CustomerProductsListSchema {
  CYBERSAFETY_ESSENTIALS = 'Cybersafety Essentials',
  PENETRATION_TESTING = 'Penetration Testing',
  THREAT_DETECTION_RESPONSE = 'Threat Detection & Response',
  CSIRT = 'CSIRT',
  CYBER_SAFETY_AND_PHISHING = 'Cyber Safety and Phishing',
  GDPR = 'GDPR',
  VULNERABILITY_SCANNING = 'Vulnerability Scanning',
}
