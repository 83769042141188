import React from 'react';
import { Icon } from 'shared';
import { icons } from 'styles/theme';
import { useTheme } from 'styled-components';
import { PhishingActivity } from '../constants';

type IconColoringProps = {
  status: string;
};

export const PhishingActivityIcon = ({ status }: IconColoringProps) => {
  const theme = useTheme();

  const statusType = (status: string) => {
    switch (status) {
      case PhishingActivity.noActions:
        return theme.colors.green[300];
      case PhishingActivity.Opened:
        return theme.colors.blue[100];
      case PhishingActivity.Clicked:
        return theme.colors.yellow[500];
      case PhishingActivity.providedCredentials:
        return theme.colors.red[400];
      default:
        return theme.colors.yellow[500];
    }
  };

  return (
    <Icon
      component={icons.fiber_manual_record}
      color={statusType(status)}
      dimension='10px'
      margin='0 8px 2px 0'
    />
  );
};
