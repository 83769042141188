import React from 'react';
import _ from 'lodash';
import { getFormattedDate, getRenderValue } from 'services/helpers';
import { HostSchema, HostSource } from 'services/api';
import { DeleteButton } from 'shared/buttons/DeleteButton';
import { emptyDataString, NO_VALUE_LABEL, PermissionsLevel } from 'app-constants';
import { Box } from 'shared';
import { ColumnsType } from 'antd/lib/table';
import { ColumnKey, optionsCriticalityStatus, TableTitles } from '../constants';
import { DeleteDropdown } from './DeleteDropdown';
import { StatusTagColoring } from '../../../shared/TagColoring/StatusTagColoring';
import { TagsColumn } from './TagsColumn';
import { VulnerabilitiesBar } from '../../../shared/VulnerabilitiesBar/VulnerabilitiesBar';
import { StatusLevel } from '../../../services/constants';

const shouldRenderVulnerabilititesBar = (assetSource: string[] = []) => {
  if (assetSource.length === 0) {
    return false;
  }

  const allowedSources = assetSource.filter(
    (source) =>
      ![
        HostSource.EDR_AGENT_TRELLIX_,
        HostSource.EDR_AGENT_DEFENDER_,
        HostSource.EDR_AGENT_SENTINEL_ONE_,
      ].includes(source as HostSource),
  );

  return allowedSources.length > 0;
};

export const getColumns = (
  deleteClick: (row: HostSchema, soft?: boolean) => void,
  permission: PermissionsLevel | null,
  isMobile: boolean,
  isNormUser?: boolean,
): ColumnsType<HostSchema> => [
  {
    key: ColumnKey.Priority,
    title: TableTitles.Priority,
    dataIndex: ColumnKey.Priority,
    render: (tag: string) => (
      <Box>
        {tag ? (
          <StatusTagColoring title={optionsCriticalityStatus[tag]} style={{ marginLeft: 0 }} />
        ) : (
          emptyDataString
        )}
      </Box>
    ),
    sorter: true,
    showSorterTooltip: false,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.HostName,
    title: TableTitles.Hostname,
    dataIndex: ColumnKey.HostName,
    sorter: true,
    showSorterTooltip: false,
    render: (text: string, row: HostSchema) => (
      <Box display='flex' flexDirection='column' w='110px'>
        <Box>{getRenderValue(text)}</Box>
        <Box className='caption' opacity='0.5'>
          {row.ip || NO_VALUE_LABEL}
        </Box>
      </Box>
    ),
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.Tags,
    title: TableTitles.Tags,
    dataIndex: ColumnKey.Tags,
    sorter: true,
    showSorterTooltip: false,
    render: (_, row) => <TagsColumn tagAssets={row.tagsAssets || []} hostId={row.id as string} />,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.OS,
    title: TableTitles.OS,
    dataIndex: ColumnKey.OS,
    sorter: true,
    showSorterTooltip: false,
    render: (text: string) => <Box w='200px'>{getRenderValue(text)}</Box>,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.Name,
    title: TableTitles.Name,
    dataIndex: ColumnKey.Name,
    sorter: true,
    render: (name: string) => <>{getRenderValue(name)}</>,
    showSorterTooltip: false,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.AssetSource,
    title: TableTitles.AssetSource,
    dataIndex: ColumnKey.AssetSource,
    render: (assetSource: string[]) => (
      <Box width='125px'>{!assetSource?.length ? emptyDataString : assetSource.join(', ')}</Box>
    ),
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.Vulnerabilities,
    title: TableTitles.Vulnerability,
    dataIndex: ColumnKey.Vulnerabilities,
    sorter: true,
    showSorterTooltip: false,
    width: 200,
    render: (_: string, row: HostSchema) => (
      <Box w='180px' backgroundColor='transparent'>
        {!shouldRenderVulnerabilititesBar(row.assetSource) ? (
          emptyDataString
        ) : (
          <VulnerabilitiesBar
            values={{
              [StatusLevel.Critical]: row.vulnerabilitiesCriticality5Count || 0,
              [StatusLevel.High]: row.vulnerabilitiesCriticality4Count || 0,
              [StatusLevel.Medium]: row.vulnerabilitiesCriticality3Count || 0,
              [StatusLevel.Low]: row.vulnerabilitiesCriticality2Count || 0,
              [StatusLevel.Informational]: row.vulnerabilitiesCriticality1Count || 0,
            }}
          />
        )}
      </Box>
    ),
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.Alarms,
    title: TableTitles.Alarms,
    dataIndex: ColumnKey.Alarms,
    sorter: true,
    showSorterTooltip: false,
    render: (alarmsCount: number) => (
      <Box w='60px'>
        {alarmsCount || alarmsCount === 0 ? `${alarmsCount} active` : emptyDataString}
      </Box>
    ),
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.LastScan,
    title: TableTitles.LastScan,
    dataIndex: ColumnKey.LastScan,
    sorter: true,
    showSorterTooltip: false,
    render: (date: string) => (
      <Box w='110px' whiteSpace='nowrap'>
        {getFormattedDate(date)}
      </Box>
    ),
    className: 'right-head-title',
    align: 'right',
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.LastCheckedIn,
    title: TableTitles.LastCheckedIn,
    dataIndex: ColumnKey.LastCheckedIn,
    sorter: true,
    showSorterTooltip: false,
    render: (date: string) => (
      <Box w='140px' whiteSpace='nowrap'>
        {getFormattedDate(date)}
      </Box>
    ),
    className: 'right-head-title',
    align: 'right',
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  permission === PermissionsLevel.Full && !isMobile
    ? {
        key: ColumnKey.Actions,
        title: TableTitles.Actions,
        dataIndex: ColumnKey.Actions,
        render: (_: string, row: HostSchema) => (
          <Box onClick={(event: React.MouseEvent) => event.stopPropagation()}>
            {isNormUser ? (
              <DeleteDropdown onClick={(soft: boolean) => deleteClick(row, soft)}>
                <DeleteButton onClick={() => {}} />
              </DeleteDropdown>
            ) : (
              <DeleteButton onClick={() => deleteClick(row)} />
            )}
          </Box>
        ),
        align: 'right',
        className: 'right-head-title',
        shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
      }
    : {},
];
