/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PhishingEmailsStatusSchema {
  _0 = '0',
  _1 = '1',
  _2 = '2',
  _3 = '3',
}
