import * as React from 'react';
import { Row, Col, Space, Card, Button } from 'antd';
import { Icon } from '../../../../shared';
import { icons } from '../../../../styles/theme';
import { documentNameNormalSize, nameStart } from '../constants';
import { normalizeFileSize } from '../../helpers';

export type DocumentProps = {
  file: File;
  clear: () => void;
  className?: string;
};

export const Document = ({ file, clear, className }: DocumentProps) => {
  const [normalSizeName, setNormalSizeName] = React.useState('');
  const [normalizedSize, setNormalizedSize] = React.useState('');

  React.useEffect(() => {
    setNormalizedSize(normalizeFileSize(file.size));
  }, [setNormalizedSize, file]);

  React.useEffect(() => {
    let splitName = file.name.split('.');
    const format = splitName[splitName.length - 1];

    splitName = splitName.slice(0, splitName.length - 1);
    const name = splitName.join('.');

    if (name.length > documentNameNormalSize) {
      setNormalSizeName(`${name.slice(nameStart, documentNameNormalSize)}...${format}`);
    } else {
      setNormalSizeName(file.name);
    }
  }, [file, setNormalSizeName]);

  return (
    <Card className={className}>
      <Row>
        <Col>
          <Icon component={icons.document} />
          <Space direction='vertical' size={0}>
            <span className='title'>{normalSizeName}</span>
            <span className='size'>{normalizedSize}</span>
          </Space>
        </Col>
        <Col>
          <Button
            className='btn-table-action'
            type='link'
            icon={<Icon component={icons.delete} />}
            onClick={() => clear()}
          />
        </Col>
      </Row>
    </Card>
  );
};
