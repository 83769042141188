import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { HostSource } from 'services/api';
import { breakpoints } from 'styles/theme';

export const ContainerForScroll = styled.div`
  width: 100%;
  @media (max-width: ${breakpoints.md}) {
    overflow-x: scroll;
  }

  .echarts-for-react {
    min-width: 600px;
  }

  ::-webkit-scrollbar {
    height: 13px;
    bottom: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }: { theme: Theme }) => theme.colorSet.scrollBar};
    border-radius: 10px;
  }
`;

export const TotalCustomTooltipContainer = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    background: ${({ theme }: { theme: Theme }) => theme.colors.blue[600]};
    flex-shrink: 0;
  }

  .title {
    white-space: pre-wrap;
  }

  .value {
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
  }

  .single-value {
    font-weight: bold;
  }
`;

export const GradientPieContainer = styled.div`
  canvas {
    border-radius: 100%;
  }

  height: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  position: relative;
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};

  .ant-spin {
    left: calc(50% - 10px);
    position: absolute;
    top: calc(50% - 8px);
  }
`;

export const CriticalHighCustomTooltipContainer = styled(TotalCustomTooltipContainer)`
  .high {
    background: ${({ theme }: { theme: Theme }) => theme.colorSet.severity.high};
  }

  .critical {
    background: ${({ theme }: { theme: Theme }) => theme.colorSet.severity.critical};
  }
`;

export const ActiveEDRAgentCustomTooltipContainer = styled(TotalCustomTooltipContainer)`
  .trellix {
    background: ${({ theme }: { theme: Theme }) =>
      theme.colorSet.activeEDR[HostSource.EDR_AGENT_TRELLIX_]};
  }

  .forti {
    background: ${({ theme }: { theme: Theme }) =>
      theme.colorSet.activeEDR[HostSource.EDR_AGENT_FORTI_EDR_]};
  }

  .defender {
    background: ${({ theme }: { theme: Theme }) =>
      theme.colorSet.activeEDR[HostSource.EDR_AGENT_DEFENDER_]};
  }

  .sentinelOne {
    background: ${({ theme }: { theme: Theme }) =>
      theme.colorSet.activeEDR[HostSource.EDR_AGENT_SENTINEL_ONE_]};
  }
`;

export const LockContainer = styled.div`
  position: absolute;
  top: 50px;

  svg {
    filter: ${({
      theme,
    }: {
      theme: Theme;
    }) => `drop-shadow(${theme.spacing.px} ${theme.spacing.px} ${theme.spacing[0]} ${theme.colors.black})
          drop-shadow(${theme.spacing[1]} ${theme.spacing[1]} ${theme.spacing[2]} ${theme.colors.blackAlpha[200]})`};
  }
`;

export const PhishingByMonthCustomTooltipContainer = styled(TotalCustomTooltipContainer)`
  .opened {
    background: ${({ theme }: { theme: Theme }) =>
      theme.colorSet.pieChart.CurrentRefresherTestsIgnored};
  }

  .clicked {
    background: ${({ theme }: { theme: Theme }) =>
      theme.colorSet.pieChart.CurrentTrainingStatusStarted};
  }

  .provided {
    background: ${({ theme }: { theme: Theme }) =>
      theme.colorSet.pieChart.PhishingLast30DaysProvidedCredentials};
  }
`;
