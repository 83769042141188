import { isEmptyObject } from 'services/helpers';
import { useApp } from 'store';
import { Link } from 'react-router-dom';
import { NO_VALUE_LABEL } from 'app-constants';
import { Routes } from 'services/entities';
import { Cvss } from 'services/api';
import { useTheme } from 'styled-components';
import { CVSSTitle } from '../constants';
import { CardStyled, Descriptions, ItemContainer } from '../styled';
import { CopyToClipboard } from './CopyToClipboard';

type CVSSProps = {
  cvss: Cvss;
  cvssV3: Cvss;
  isLoading?: boolean;
  qid?: string;
  title?: string;
};

export const CVSS = ({ cvss, cvssV3, isLoading, title, qid }: CVSSProps) => {
  const theme = useTheme();
  const { isMobile } = useApp();

  const renderCVSS = ({ base, temporal, vector_string }: Cvss) => (
    <Descriptions column={1}>
      <Descriptions.Item key='base' label={CVSSTitle.Base}>
        {base}
      </Descriptions.Item>

      <Descriptions.Item key='temporal' label={CVSSTitle.Temporal}>
        {temporal}
      </Descriptions.Item>

      <Descriptions.Item key='vector' label={CVSSTitle.Vector}>
        <ItemContainer>
          {vector_string ? (
            <Link
              to={{
                pathname: Routes.CVSS_VECTOR_STRINGS,
                state: {
                  backTo: {
                    route: `${Routes.VULNERABILITIES_LIST}/${qid}`,
                    title: `Back to ${title}`,
                  },
                },
              }}
            >
              {vector_string}
            </Link>
          ) : (
            NO_VALUE_LABEL
          )}
          {vector_string && (
            <CopyToClipboard field={{ property: CVSSTitle.Vector, value: vector_string }} />
          )}
        </ItemContainer>
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <CardStyled
      title={!isEmptyObject(cvssV3) ? 'CVSS V3' : 'CVSS'}
      isHiddable={isMobile}
      headStyles={
        !isMobile
          ? { pb: theme.sizes[5], titlePaddingRight: theme.sizes['2.5'] }
          : { pb: theme.sizes['2.5'], titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={
        !isMobile ? `${theme.sizes[6]} ${theme.sizes['2.5']} ${theme.sizes['2.5']}` : theme.sizes[0]
      }
      cardPadding={
        !isMobile
          ? `${theme.sizes[7]} ${theme.sizes['5.5']} ${theme.sizes['5.5']}`
          : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes['1.5']}`
      }
      showLoader={isLoading}
    >
      {!isEmptyObject(cvssV3) ? renderCVSS(cvssV3) : renderCVSS(cvss)}
    </CardStyled>
  );
};
