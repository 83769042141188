import styled from 'styled-components';
import { getCssVar } from '../../styles/theme';

export const Wrapper = styled.div`
  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-color: ${getCssVar('select.borderColor')};
  }

  .ck.ck-icon {
    color: ${getCssVar('textColor')};
  }

  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border-color: ${getCssVar('select.borderColor')};
  }

  .ck-editor {
    height: 500px;

    &__top {
      .ck-sticky-panel .ck-toolbar {
        height: 56px;
      }
    }

    &__main {
      height: calc(100% - 56px);
    }

    .ck-content {
      height: 100%;
      background-color: ${getCssVar('select.bg')};
      color: ${getCssVar('textColor')};

      .table {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border-color: ${getCssVar('select.borderColor')};
    }

    strong {
      font-weight: bold;
    }

    .ck-placeholder {
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0;
      text-align: left;
      color: ${getCssVar('wysiwyg.placeholderColor')};
    }

    .ck.ck-toolbar {
      background: transparent;
      padding: 0;
    }

    .ck.ck-toolbar > .ck-toolbar__items > :not(.ck-toolbar__line-break) {
      min-width: auto;
      padding: 0;

      margin-left: 10px;
      margin-right: 10px;

      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }

      &.ck-toolbar__separator {
        width: 0;
        background: transparent !important;
      }
    }

    .ck-sticky-panel {
      &__content {
        background: ${getCssVar('wysiwyg.bg')};
      }
    }
  }
`;
