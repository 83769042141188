import React from 'react';
import { MonthlyReportResponse } from 'services/api';
import moment from 'moment';
import { IDate } from 'services/interfaces';
import { ReportPageLayout } from '../components/ReportPageLayout';
import { GradientPieReport } from '../components/GradientPieReport';
import { ReportTableSection } from '../components/ReportTableSection';
import { ProcessList } from '../components/ProcessList';
import { CybersafetyAndPhishingTable } from '../components/CybersafetyAndPhishingTable';
import { getSortedDashboardScores } from '../helpers';

export const PdfPage2 = ({
  data,
  processPermission,
  onChartLoaded,
  reportDate,
}: {
  data: MonthlyReportResponse;
  processPermission: string;
  onChartLoaded: () => void;
  reportDate: IDate;
}) => {
  const scores = data?.dashboardScores || [];
  const checkingDashboardScores = getSortedDashboardScores([...scores])[scores.length - 1];
  const peopleScore = checkingDashboardScores?.people;
  const processScore = checkingDashboardScores?.process;
  const dataServiceRequests = data?.supportRequests?.serviceRequests;

  const certificates = data.certificates || [];

  const phishing = data.people?.phishing || {};
  const trainingStatus = data.people?.trainingStatus || {};
  const refresherStatistics = data.people?.refresherStatistics || {};

  return (
    <ReportPageLayout
      headerSubtitle={`Your ${moment(reportDate.startDate).format(
        'MMMM YYYY',
      )} update. Service Requests, People, Process`}
    >
      <ReportTableSection title='Service Requests' onLoad={onChartLoaded}>
        <table className='table-border-vr text-center'>
          <tbody>
            <tr>
              <td width='33%'>
                <p className='mb-1 font-size-16 text-center'>
                  {dataServiceRequests?.opened || 0} <span>Opened</span>
                </p>
              </td>
              <td width='33%'>
                <p className='mb-1 font-size-16 color-green text-center'>
                  {dataServiceRequests?.closed || 0} <span>Closed</span>
                </p>
              </td>
              <td width='33%'>
                <p className='mb-1 font-size-16 color-red text-center'>
                  {dataServiceRequests?.active || 0} <span>Active</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </ReportTableSection>

      <table className='table-section'>
        <tbody>
          <tr>
            <td className='border-rt'>
              <h3>People</h3>
              <p>
                Sed consequat posuere blandit. Mauris ac est dolor. Phasellus a massa at est
                imperdiet egestas. Nam placerat ultricies dapibus. Morbi mattis suscipit dui. Aenean
                tincidunt leo eget odio dapibus auctor. Sed fermentum est sapien. Mauris malesuada
                magna quis ex egestas lobortis. Fusce non ipsum magna. Etiam rutrum libero risus,
                quis posuere metus tincidunt vel. Mauris eget facilisis dui. Aenean a augue sodales,
                aliquam justo nec, fringilla sapien. Donec venenatis arcu non tortor placerat, vel
                ultrices augue sagittis. Vivamus tempus accumsan placerat. Praesent iaculis suscipit
                velit, quis elementum magna ultrices suscipit.
              </p>
              <p>
                Curabitur scelerisque, risus quis dignissim pretium, ex augue dignissim lacus, eget
                euismod turpis ex sed tortor. Etiam sit amet interdum tortor. Ut sollicitudin libero
                ligula, vitae vestibulum est rutrum eu.
              </p>
              <p>
                Maecenas sodales elit vitae rhoncus tincidunt. Vestibulum congue nulla vitae lorem
                porta tincidunt. Praesent vitae est vitae ante blandit ullamcorper. Suspendisse
                vestibulum, leo quis malesuada consequat, sem risus sagittis ex, vitae ultricies est
                urna vel leo.
              </p>
            </td>
            <td width='170'>
              <GradientPieReport score={peopleScore} right onLoad={onChartLoaded} />
              <CybersafetyAndPhishingTable
                trainingStatus={trainingStatus}
                refresherStatistics={refresherStatistics}
                phishing={phishing}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <ReportTableSection score={processScore} showScore title='Process' onLoad={onChartLoaded}>
        <ProcessList certificates={certificates} permission={processPermission} />
        <p>
          Vivamus sit amet vehicula neque. Donec in vehicula lacus. Pellentesque habitant morbi
          tristique senectus et netus et malesuada fames ac turpis egestas. Ut eleifend nisi tortor,
          quis consequat tellus semper eget. Cras sit amet risus a enim faucibus iaculis vitae ac
          ligula. Quisque iaculis vel ligula quis posuere. Integer sed hendrerit velit.
        </p>
        <p>Quisque elementum suscipit justo, sed maximus purus pulvinar nec. Mauris neque justo.</p>
      </ReportTableSection>
    </ReportPageLayout>
  );
};
