/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AlarmRiskEnum {
  MINIMAL = 'Minimal',
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  CRITICAL = 'Critical',
}
