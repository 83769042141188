import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, GridRequestParams, ThreatDetectionService } from 'services/api';

const alarmsRequest = async (requestBody: GridRequestParams) => {
  try {
    const response = await ThreatDetectionService.getAlarms(requestBody);

    return response;
  } catch (error) {
    const { body } = error as ApiError;

    throw body;
  }
};

const alarmsTableContent = createAsyncThunk('alarms/data/get', alarmsRequest);

export const alarmsApi = {
  alarmsTableContent,
};
