import React from 'react';
import { Box, Button, Icon } from 'shared';
import { Popup } from 'shared/ActionPopup/styled';
import { Spin, Tooltip } from 'antd';
import { EventByName } from 'pages/MonitoredServiceDetails/components/EventByName/EventByName';
import { useTheme } from 'styled-components';
import { managedDetectionsResponseApi, useApp, useLoading } from 'store';
import { TooltipTitles } from 'pages/Dashboard/constants';
import { SpinContainer } from 'shared/Card/components/styled';

export const AllEventsPopup = ({ isVisible, setIsVisible, selectedDate, periodName }) => {
  const onCancel = React.useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const { sizes, icons } = useTheme();
  const { isMobile } = useApp();

  const loading = useLoading([managedDetectionsResponseApi.events.typePrefix]);

  return (
    <Popup
      className='actions-modal'
      title={
        <Box display='flex' alignItems='center'>
          All events{' '}
          <Tooltip
            title={TooltipTitles.ThreatDetection}
            placement={isMobile ? 'top' : 'right'}
            trigger={isMobile ? 'click' : 'hover'}
          >
            <Icon component={icons.info_outline} dimension={sizes[6]} className='tooltipInfo' />
          </Tooltip>
        </Box>
      }
      visible={isVisible}
      onCancel={onCancel}
      width='1000px'
      footer={[
        <Button key='back' onClick={() => setIsVisible(false)}>
          Close
        </Button>,
      ]}
    >
      {loading[managedDetectionsResponseApi.events.typePrefix] && (
        <SpinContainer>
          <Spin size='large' />
        </SpinContainer>
      )}
      <EventByName selectedDate={selectedDate} periodName={periodName} />
    </Popup>
  );
};
