import React, { useEffect } from 'react';
import { TwoFAValidation } from 'pages/Login/components';
import { checkLoginTokenExpired } from 'pages/Login/helpers';
import { useDispatch } from 'react-redux';
import { ValidateTwoFARequest } from 'services/api';
import { Routes, TwoFASteps } from 'services/entities';
import { Box, SuppressedVulnsSnackBarNotification } from 'shared';
import {
  AppDispatch,
  MessageType,
  auth,
  authApi,
  useApp,
  useAuth,
  useMaintenanceSettings,
} from 'store';
import { Location } from 'history';
import { Redirect, useHistory } from 'react-router';
import { spacing } from 'styles/theme';
import { RequestStatus } from 'store/loading/slice';
import { useTheme } from 'styled-components';
import { showMessage } from 'services/helpers';
import { maintenanceSettingsApi } from '../../store/maintenance/thunks';
import { showSnackBarText } from '../MaintenancePageSettings/helpers';

export const Verify = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { themeName } = useApp();
  const { requestError, isPending, twofa, token } = useAuth();
  const { toggleNotifyUsers, snackbarText } = useMaintenanceSettings();

  useEffect(() => {
    dispatch(maintenanceSettingsApi.getMaintenanceSettingsSnackBarText());
  }, [dispatch]);

  const validate2FACode = React.useCallback(
    async (requestBody: ValidateTwoFARequest) => {
      if (!token || checkLoginTokenExpired(token, dispatch)) {
        return;
      }

      const result = await dispatch(authApi.twofaValidate(requestBody));

      const requestFulfilledAndIsNotifyUsers =
        result.meta.requestStatus === RequestStatus.Fulfilled && toggleNotifyUsers;

      const showVulnerabilitiesReviewMessage =
        result.meta.requestStatus === RequestStatus.Fulfilled &&
        // @ts-ignore
        result.payload?.expiredSuppressedVulnerabilities;

      if (requestFulfilledAndIsNotifyUsers) {
        showSnackBarText(snackbarText, themeName);
      }

      if (showVulnerabilitiesReviewMessage) {
        showMessage(
          { content: <SuppressedVulnsSnackBarNotification />, duration: 8 },
          MessageType.Warning,
        );
      }
    },
    [dispatch, snackbarText, themeName, toggleNotifyUsers, token],
  );

  React.useEffect(() => {
    history.listen((location: Location<unknown>) => {
      if (location.pathname === Routes.ROOT) {
        dispatch(auth.actions.setStepToInitial());
      }
    });
    dispatch(auth.actions.setError(''));

    return () => {
      history.listen(() => {});
    };
  }, [dispatch, history]);

  if (twofa.step !== TwoFASteps.CONFIRM) {
    return <Redirect to={Routes.ROOT} />;
  }

  return (
    <Box textAlign='center' mb={`-${spacing[6]}`}>
      <TwoFAValidation
        isPending={isPending}
        onSubmit={validate2FACode}
        isRequestError={!!requestError.message}
      />
      <Box
        color={theme.colors.red[700]}
        fontSize={theme.sizes['4.2']}
        mt={`-${theme.spacing[6]}`}
        pb={theme.spacing[5]}
      >
        {requestError.message}
      </Box>
    </Box>
  );
};
