import { createSlice } from '@reduxjs/toolkit';
import {
  AllVulnerabilitiesTrendSchema,
  AssetVulnerabilitySchema,
  ByCriticalitiesVulnerabilitiesTrendSchema,
  CurrentVulnerabilitiesByEachCriticalitySchema,
  MostVulnerableHostsSchema,
  PatchVsConfigurationResponse,
  VulnerabilitiesCountByStatusResponse,
  VulnerabilitiesResponse,
  VulnerabilitiesWithAffectedHostsSchema,
  VulnerabilitiesWithCurrentAndTotalAffectedHostsSchema,
  VulnerabilitySchema,
  SuppressionGridResponse,
  SuppressedVulnerabilitiesResponse,
} from 'services/api';
import { vulnerabilitiesApi } from './thunks';

interface VulnerabilitiesState {
  vulnerabilityDetails: VulnerabilitySchema;
  patchVsConfiguration: PatchVsConfigurationResponse;
  assetVulnerabilityDetails: AssetVulnerabilitySchema;
  vulnerabilities: VulnerabilitiesResponse;
  allVulnerabilitiesTrend: AllVulnerabilitiesTrendSchema;
  vulnerabilitiesTrendsByCriticalities: ByCriticalitiesVulnerabilitiesTrendSchema;
  currentVulnerabilities: CurrentVulnerabilitiesByEachCriticalitySchema;
  latestVulnerabilitiesWithAffectedHosts: VulnerabilitiesWithAffectedHostsSchema;
  vulnerabilitiesWithMostAffectedHosts: VulnerabilitiesWithCurrentAndTotalAffectedHostsSchema;
  mostVulnerableHosts: MostVulnerableHostsSchema;
  categories: string[];
  showPotentialVulnerabilities: boolean;
  vulnerabilitiesCountByStatus: VulnerabilitiesCountByStatusResponse;
  selectedTags?: string[];
  suppressionGrid?: SuppressionGridResponse;
  activeVulnerabilities: VulnerabilitiesResponse;
  potentialVulnerabilities: VulnerabilitiesResponse;
  suppressedVulnerabilities?: VulnerabilitiesResponse;
  suppressVulnerabilitiesUsers?: string[];
  excludedVulnerabilitiesStatusFilter?: string[];
}

export const vulnerabilityInitialState: VulnerabilitiesState = {
  vulnerabilityDetails: {},
  patchVsConfiguration: {},
  assetVulnerabilityDetails: {},
  vulnerabilities: {},
  allVulnerabilitiesTrend: {},
  vulnerabilitiesTrendsByCriticalities: {},
  currentVulnerabilities: {},
  latestVulnerabilitiesWithAffectedHosts: {},
  vulnerabilitiesWithMostAffectedHosts: {},
  mostVulnerableHosts: {},
  categories: [],
  vulnerabilitiesCountByStatus: [],
  showPotentialVulnerabilities: false,
  selectedTags: [],
  suppressionGrid: {},
  activeVulnerabilities: {},
  potentialVulnerabilities: {},
  suppressedVulnerabilities: {},
  suppressVulnerabilitiesUsers: [],
  excludedVulnerabilitiesStatusFilter: ['potential', 'suppressed'],
};

export const vulnerability = createSlice({
  name: 'vulnerability',
  initialState: vulnerabilityInitialState,
  reducers: {
    setShowPotentialVulnerabilities: (
      state: VulnerabilitiesState,
      action: { payload: boolean },
    ) => ({
      ...state,
      showPotentialVulnerabilities: action.payload,
    }),
    setFilterTags: (state: VulnerabilitiesState, action: { payload: string[] }) => ({
      ...state,
      selectedTags: action.payload,
    }),
    setExcludedVulnerabilitiesStatusFilter: (
      state: VulnerabilitiesState,
      action: { payload: string[] },
    ) => ({
      ...state,
      excludedVulnerabilitiesStatusFilter: action.payload,
    }),
  },
  extraReducers: {
    [vulnerabilitiesApi.vulnerabilityDetails.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: VulnerabilitySchema },
    ) => {
      state.vulnerabilityDetails = action.payload;
    },
    [vulnerabilitiesApi.vulnerabilityDetails.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.vulnerabilityDetails = {};
    },

    [vulnerabilitiesApi.assetVulnerabilityDetails.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: AssetVulnerabilitySchema },
    ) => {
      state.assetVulnerabilityDetails = action.payload;
    },
    [vulnerabilitiesApi.assetVulnerabilityDetails.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.assetVulnerabilityDetails = {};
    },

    [vulnerabilitiesApi.vulnerabilities.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: VulnerabilitiesResponse },
    ) => {
      state.vulnerabilities = action.payload;
    },
    [vulnerabilitiesApi.vulnerabilities.rejected.toString()]: (state: VulnerabilitiesState) => {
      state.vulnerabilities = {};
    },

    [vulnerabilitiesApi.getAllVulnerabilitiesTrend.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: AllVulnerabilitiesTrendSchema },
    ) => {
      state.allVulnerabilitiesTrend = action.payload;
    },
    [vulnerabilitiesApi.getAllVulnerabilitiesTrend.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.allVulnerabilitiesTrend = {};
    },

    [vulnerabilitiesApi.getVulnerabilitiesTrendsByCriticalities.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: ByCriticalitiesVulnerabilitiesTrendSchema },
    ) => {
      state.vulnerabilitiesTrendsByCriticalities = action.payload;
    },
    [vulnerabilitiesApi.getVulnerabilitiesTrendsByCriticalities.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.vulnerabilitiesTrendsByCriticalities = {};
    },

    [vulnerabilitiesApi.getCurrentVulnerabilities.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: CurrentVulnerabilitiesByEachCriticalitySchema },
    ) => {
      state.currentVulnerabilities = action.payload;
    },
    [vulnerabilitiesApi.getCurrentVulnerabilities.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.currentVulnerabilities = {};
    },

    [vulnerabilitiesApi.getLatestVulnerabilitiesWithAffectedHosts.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: VulnerabilitiesWithAffectedHostsSchema },
    ) => {
      state.latestVulnerabilitiesWithAffectedHosts = action.payload;
    },
    [vulnerabilitiesApi.getLatestVulnerabilitiesWithAffectedHosts.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.latestVulnerabilitiesWithAffectedHosts = {};
    },

    [vulnerabilitiesApi.getVulnerabilitiesWithMostAffectedHosts.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: VulnerabilitiesWithCurrentAndTotalAffectedHostsSchema },
    ) => {
      state.vulnerabilitiesWithMostAffectedHosts = action.payload;
    },
    [vulnerabilitiesApi.getVulnerabilitiesWithMostAffectedHosts.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.vulnerabilitiesWithMostAffectedHosts = {};
    },

    [vulnerabilitiesApi.getMostVulnerableHosts.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: MostVulnerableHostsSchema },
    ) => {
      state.mostVulnerableHosts = action.payload;
    },
    [vulnerabilitiesApi.getMostVulnerableHosts.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.mostVulnerableHosts = {};
    },

    [vulnerabilitiesApi.getPatchVsConfiguration.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: PatchVsConfigurationResponse },
    ) => {
      state.patchVsConfiguration = action.payload;
    },
    [vulnerabilitiesApi.getPatchVsConfiguration.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.patchVsConfiguration = {};
    },
    [vulnerabilitiesApi.getVulnerabilitiesCategories.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: { categories: string[] } },
    ) => {
      state.categories = action.payload.categories;
    },
    [vulnerabilitiesApi.getMostVulnerableHosts.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.categories = [];
    },

    [vulnerabilitiesApi.getVulnerabilitiesCountByStatus.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: VulnerabilitiesCountByStatusResponse },
    ) => {
      state.vulnerabilitiesCountByStatus = action.payload;
    },
    [vulnerabilitiesApi.getVulnerabilitiesCountByStatus.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.vulnerabilitiesCountByStatus = [];
    },

    [vulnerabilitiesApi.getVulnerabilitySuppressionGrid.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: SuppressionGridResponse },
    ) => {
      state.suppressionGrid = action.payload;
    },
    [vulnerabilitiesApi.getVulnerabilitiesCountByStatus.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.suppressionGrid = {};
    },

    [vulnerabilitiesApi.getActiveVulnerabilities.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: VulnerabilitiesResponse },
    ) => {
      state.activeVulnerabilities = action.payload;
    },
    [vulnerabilitiesApi.vulnerabilities.rejected.toString()]: (state: VulnerabilitiesState) => {
      state.activeVulnerabilities = {};
    },
    [vulnerabilitiesApi.getPotentialVulnerabilities.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: VulnerabilitiesResponse },
    ) => {
      state.potentialVulnerabilities = action.payload;
    },
    [vulnerabilitiesApi.getPotentialVulnerabilities.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.potentialVulnerabilities = {};
    },
    [vulnerabilitiesApi.getSuppressedVulnerabilities.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: SuppressedVulnerabilitiesResponse },
    ) => {
      state.suppressedVulnerabilities = action.payload;
    },
    [vulnerabilitiesApi.getSuppressedVulnerabilities.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.suppressedVulnerabilities = {};
    },
    [vulnerabilitiesApi.getSuppressVulnerabilitiesUsers.fulfilled.toString()]: (
      state: VulnerabilitiesState,
      action: { payload: { suppressVulnerabilitiesUsers: string[] } },
    ) => {
      state.suppressVulnerabilitiesUsers = action.payload.suppressVulnerabilitiesUsers;
    },
    [vulnerabilitiesApi.getSuppressVulnerabilitiesUsers.rejected.toString()]: (
      state: VulnerabilitiesState,
    ) => {
      state.suppressVulnerabilitiesUsers = [];
    },
  },
});
