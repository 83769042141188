import * as React from 'react';
import { Box } from 'shared';
import { DullImage, NormRect, Reassuringly } from 'shared/layouts/Outer/components/styled';
import { useTheme } from 'styled-components';

export const Dull = ({
  isMobile = false,
  isTablet,
  width = '0px',
}: {
  isMobile?: boolean;
  isTablet?: boolean;
  width?: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      position='fixed'
      r='0'
      t='0'
      overflow='hidden'
      height={theme.sizes.full}
      w={width || theme.sizes.pageWidth}
      backgroundColor={
        isMobile ? theme.colorSet.dull.mobileBackgroundColor : theme.colorSet.dull.backgroundColor
      }
    >
      <DullImage
        $isMobile={isMobile}
        component={isMobile ? theme.icons.dullMobile : theme.icons.dull}
      />

      {!isMobile && !isTablet && <NormRect component={theme.icons.normRect} />}
      <Reassuringly component={theme.icons.reassuringly} $isMobile={isMobile} />
    </Box>
  );
};
