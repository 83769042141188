import styled from 'styled-components';
import { Modal } from 'shared/Modal/Modal';
import { Theme } from 'styles/theme/types';
import { Menu } from 'antd';
import { Box, Button } from '../../../shared';
import { getCssVar } from '../../../styles/theme';

type Props = {
  theme: Theme;
  $isRead?: boolean;
};

export const Popup = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      display: flex;
      justify-content: center;
      border: none;
      border-radius: 4px;

      .ant-modal-title {
        color: ${({ theme }: Props) => theme.colors.blue[900]};
        font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
        font-size: 16px;
      }
    }

    .ant-modal-body {
      padding: 0;

      .ant-menu-item {
        display: flex;
        justify-content: start;
        align-items: center;
        height: 48px;
        padding: 0 12px;
      }

      .ant-menu-item-selected {
        background-color: ${({ theme }: Props) => `${theme.colors.brown[100]}${theme.opacity[12]}`};
        color: currentColor;
      }
    }
  }
`;

export const CustomMenu = styled(Menu)`
  border-radius: 4px;

  .ant-dropdown-menu-item {
    width: 180px;

    &:hover {
      background-color: ${({ theme }: Props) => `${theme.colors.brown[100]}${theme.opacity[12]}`};
    }
  }
`;

export const SaveEditButton = styled(Button)`
  background-color: ${({ theme }: Props) => `${theme.colors.brown[500]}`};
  max-width: 40px;
  width: 100%;

  &.ant-btn:not(.ant-btn-primary):not(.secondary):not(.ant-btn-link) {
    border-radius: initial;

    &:disabled {
      background-color: gainsboro;

      &:hover {
        background-color: gainsboro;
      }
    }
  }
`;

export const ColumnDateWrapper = styled(Box)`
  align-items: end;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 120px;
`;

export const ColumnTitle = styled(Box)<Props>`
  font-weight: ${({ $isRead, theme }: Props) =>
    $isRead ? theme.fontWeights.normal : theme.fontWeights.extrabold};
`;

export const UserActionWrapper = styled(Box)`
  color: ${getCssVar('primary')};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

/* stylelint-disable */
export const NotificationMessageColumn = styled(Box)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  max-height: 42px;
  overflow: hidden;
`;
/* stylelint-enable */
