import { Tabs } from 'antd';
import { TabsStyled } from 'pages/CmdbHostDetails/styled';
import React from 'react';
import { Card } from 'shared';
import { useApp } from 'store';
import { useTheme } from 'styled-components';
import { AuditLogs } from '../AuditLogs/AuditLogs';
import { Notes } from '../Notes/Notes';
import { Overview } from '../Overview/Overview';
import { TabTitles } from './constants';

type MainContentProps = {
  loading: boolean;
};

export const MainContent = ({ loading }: MainContentProps) => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const { TabPane } = Tabs;

  return (
    <Card
      height={theme.sizes.full}
      bodyPadding={!isMobile ? undefined : theme.sizes[0]}
      cardPadding={
        !isMobile ? theme.sizes['2.5'] : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes[5]}`
      }
      showLoader={loading}
    >
      <TabsStyled className='customTabs'>
        <TabPane tab={TabTitles.Overview} key='1'>
          <Overview />
        </TabPane>

        <TabPane tab={TabTitles.Notes} key='2'>
          <Notes />
        </TabPane>

        <TabPane tab={TabTitles.AuditLogs} key='3'>
          <AuditLogs />
        </TabPane>
      </TabsStyled>
    </Card>
  );
};
