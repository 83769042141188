import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  GridRequestParams,
  PatchManagementPlatform,
  PatchManagementService,
} from 'services/api';

const getAssetsList = createAsyncThunk(
  'patch-management/assets/get',
  async (requestBody: GridRequestParams & { platform: PatchManagementPlatform }) => {
    try {
      const data = await PatchManagementService.getAssets(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const patchManagementApi = {
  getAssetsList,
};
