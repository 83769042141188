import React from 'react';
import { Icon } from 'shared';
import { icons } from 'styles/theme/icons';
import {
  ArrowContainer,
  LightTextContainer,
  Period,
  SubTitleStyled,
  ValueContainer,
} from './styled';

type SubTitleProps = {
  boldText?: React.ReactNode;
  lightText?: string;
  arrow?: 'up' | 'down';
  isLocked?: boolean;
  className?: string;
  period?: string;
  customText?: string;
  paddingRight?: string;
  onPress?: () => void;
  isEnabledArrow?: boolean;
  children?: React.ReactNode;
};

export const SubTitle = ({
  boldText,
  lightText,
  arrow,
  className,
  isLocked,
  period = 'last period',
  customText,
  paddingRight,
  onPress,
  isEnabledArrow = true,
  children,
}: SubTitleProps) => {
  const isUp = arrow === 'up';
  const isShownPlus = isUp && !isLocked;
  const isVisibleArrowIcon = arrow && !isLocked;

  return (
    <SubTitleStyled
      isClickable={!!onPress}
      onClick={() => onPress?.()}
      className={className}
      paddingRight={paddingRight}
    >
      {!isLocked ? <span>{boldText}</span> : <Icon component={icons.big_lock} className='lock' />}
      <LightTextContainer>
        {isEnabledArrow && (
          <ArrowContainer>
            {isVisibleArrowIcon && <Icon component={isUp ? icons.arrow_up : icons.arrow_down} />}
          </ArrowContainer>
        )}
        <ValueContainer customText={customText} isLightTextExist={!lightText}>
          {isShownPlus && '+ '}

          {lightText && boldText && (
            <>
              {lightText} vs <Period>{period}</Period>
            </>
          )}
          {customText}
          {children}
        </ValueContainer>
      </LightTextContainer>
    </SubTitleStyled>
  );
};
