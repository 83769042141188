import React, { useMemo, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { Box, Icon } from 'shared';
import { useTheme } from 'styled-components';
import { Colors, SegmentedPieData, Values } from '../types';
import { Legend } from '../Legend/Legend';
import { getOption } from './helpers';
import { LockContainer } from '../styled';
import { ICustomPieChartTooltipProps } from '../formatters';
import { DELAY } from '../constants';

type Props = {
  values: Values;
  colors: Colors;
  direction?: 'column' | 'row';
  style?: React.CSSProperties;
  entitiesName: string;
  onEvents?: Record<string, (event: { name: string }) => void>;
  isMobile?: boolean;
  isEmpty?: boolean;
  noLegend?: boolean;
  noPercentageLabel?: boolean;
  totalClickHandler?: () => void;
  formatName?: (name: string) => string;
  isTruncatedText?: boolean;
  isShowingUnchangedText?: boolean;
  getCustomDataHandler?: (values: Values, colors?: Colors) => SegmentedPieData;
  tooltip?: ICustomPieChartTooltipProps;
  onLoad?: () => void;
  isMenuCollapsed: boolean | undefined;
  isLoading?: boolean;
  isAsyncAnimation?: boolean;
  customTotal?: string;
  minShowLabelAngle?: number;
  startAngle?: number;
};

export const SegmentedPie = ({
  values,
  colors,
  style,
  entitiesName,
  direction,
  isMobile,
  isEmpty,
  onEvents,
  noLegend,
  noPercentageLabel,
  totalClickHandler,
  formatName,
  getCustomDataHandler,
  isTruncatedText = false,
  isShowingUnchangedText = false,
  tooltip,
  onLoad,
  isMenuCollapsed,
  isLoading = false,
  isAsyncAnimation = false,
  customTotal,
  minShowLabelAngle,
  startAngle,
}: Props) => {
  const theme = useTheme();
  const ref = useRef<ReactECharts>();

  const option = useMemo(async () => {
    if (isLoading) {
      return {};
    }

    return getOption({
      values,
      theme,
      colors,
      entitiesName,
      isMobile,
      noPercentageLabel,
      hasClickHandler: Boolean(onEvents),
      getCustomDataHandler,
      tooltip,
      customTotal,
      minShowLabelAngle,
      startAngle,
    });
  }, [
    isLoading,
    values,
    theme,
    colors,
    entitiesName,
    isMobile,
    noPercentageLabel,
    onEvents,
    getCustomDataHandler,
    tooltip,
    customTotal,
    minShowLabelAngle,
    startAngle,
  ]);

  const asyncOption = useMemo(async () => {
    if (isLoading) {
      return {};
    }

    return setTimeout(
      () =>
        ref.current?.getEchartsInstance().setOption(
          getOption({
            values,
            theme,
            colors,
            entitiesName,
            isMobile,
            noPercentageLabel,
            hasClickHandler: Boolean(onEvents),
            getCustomDataHandler,
            tooltip,
            customTotal,
            minShowLabelAngle,
            startAngle,
          }),
          true,
        ),
      DELAY,
    );
  }, [
    isLoading,
    values,
    theme,
    colors,
    entitiesName,
    isMobile,
    noPercentageLabel,
    onEvents,
    getCustomDataHandler,
    tooltip,
    customTotal,
    minShowLabelAngle,
    startAngle,
  ]);

  return (
    <>
      {!isEmpty ? (
        <Box className='chart-container' position='relative' style={style}>
          <Box
            w='100px'
            height='100px'
            borderRadius='100%'
            zIndex={100}
            cursor={totalClickHandler ? 'pointer' : 'default'}
            left='calc(50% - 50px)'
            top='calc(50% - 50px)'
            position='absolute'
            onClick={totalClickHandler}
          />
          <ReactECharts
            style={style}
            option={isAsyncAnimation ? asyncOption : option}
            onEvents={onEvents}
            onChartReady={onLoad}
            ref={(r: ReactECharts) => {
              ref.current = r;
            }}
            opts={{ renderer: 'canvas' }}
            notMerge
          />
        </Box>
      ) : (
        <Box w={theme.sizes.full} d='flex' justify='center' alignItems='center'>
          <Box
            width='240px'
            height='240px'
            position='relative'
            borderRadius={theme.radius.full}
            border={`35px solid ${theme.colors.gray[100]}`}
            d='flex'
            justify='center'
            alignItems='center'
          >
            <Box fontSize={theme.sizes['4.5']} mt={theme.spacing[8]}>{`Total ${entitiesName}`}</Box>
            <LockContainer>
              <Icon component={theme.icons.big_lock} />
            </LockContainer>
          </Box>
        </Box>
      )}
      {!noLegend && (
        <Legend
          values={values}
          colors={colors}
          chartRef={ref}
          direction={direction || 'row'}
          formatName={formatName}
          isEmpty={isEmpty}
          isTruncatedText={isTruncatedText}
          isShowingUnchangedText={isShowingUnchangedText}
          isMobile={isMobile}
          isMenuCollapsed={isMenuCollapsed}
        />
      )}
    </>
  );
};
