import React from 'react';
import { Card, ScrollContainer, Table } from 'shared';
import { useApp } from 'store';
import { PatchDetailsResponse } from 'services/api';
import { useTheme } from 'styled-components';
import { columns } from './TableColumns';

interface AffectedApplicationsProps {
  details: PatchDetailsResponse;
}

export const AffectedApplications = ({ details }: AffectedApplicationsProps) => {
  const theme = useTheme();
  const { isMobile } = useApp();

  return (
    <Card cardPadding={isMobile ? theme.sizes[5] : theme.sizes['5.5']} bodyPadding={theme.sizes[0]}>
      <ScrollContainer width='400px' $isScrollTop>
        <Table
          columns={columns}
          dataSource={details.product?.map((app) => ({ applicationName: app }))}
          tableLayout='auto'
          style={{ minHeight: '300px' }}
          pagination={false}
        />
      </ScrollContainer>
    </Card>
  );
};
