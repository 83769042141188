import { useCallback, useMemo } from 'react';
import { cmdb, useApp, useCEPlus, useVulnerability, useVulnerabilitySuppressionGrid } from 'store';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Icon } from 'shared';
import { formatUpdatedOrCreatedTime, isEmptyObject } from 'services/helpers';
import {
  Filter,
  Link,
  OrderBy,
  SuppressionStatus,
  VulnerabilitySchema,
  VulnerabilityStatus,
} from 'services/api';
import { initialPagination, NO_VALUE_LABEL } from 'app-constants';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { useLocation } from 'react-router';
import { useTheme } from 'styled-components';
import { Tooltip } from 'antd';
import { assetDetails } from 'store/assetDetails/slice';
import { hiddenFilterTooltip } from 'shared/TableFilters/constants';
import { AffectedHostsWrap, CardStyled, Descriptions, ItemContainer } from '../styled';
import { CopyToClipboard } from './CopyToClipboard';
import { DetailsTitle } from '../constants';
import { renderValue, getVulnerabilitiesFilters } from '../helpers';
import { HistoryState } from '../../../services/interfaces';
import { validateField, validateFieldValue } from '../../CEPlus/helpers';
import { IFilterField } from '../../CEPlus/constants';
import { icons } from '../../../styles/theme';

type DetailsProps = {
  qid?: string;
  title?: string;
  type?: string;
  category?: string;
  vendorReference?: Link[];
  hosts?: number;
  pci?: VulnerabilitySchema.pci_flag;
  patch?: VulnerabilitySchema.patchable;
  normPatchable?: VulnerabilitySchema.norm_patchable;
  publishedDate?: string;
  isLoading?: boolean;
  isAsset?: boolean;
  status?: VulnerabilityStatus;
};

export const Details = ({
  qid,
  title,
  type,
  category,
  vendorReference = [],
  hosts,
  pci,
  patch,
  normPatchable,
  publishedDate,
  isAsset,
  isLoading,
  status,
}: DetailsProps) => {
  const { isMobile } = useApp();
  const history = useHistory();
  const dispatch = useDispatch();
  const { state: historyState, search } = useLocation<HistoryState>();
  const { selectedTags: vulnerabilityTags } = useVulnerability();
  const { selectedTags: cePlusTags } = useCEPlus();
  const vulnerabilitySuppression = useVulnerabilitySuppressionGrid();
  const suppressionStatus = vulnerabilitySuppression?.rows?.[0]?.status;
  const isSuppressed = !!suppressionStatus && suppressionStatus !== SuppressionStatus.UNSUPPRESSED;

  const theme = useTheme();

  const vulnerabilitiesFilters = useMemo(() => getVulnerabilitiesFilters(search, isSuppressed), [
    search,
    isSuppressed,
  ]);

  const CMDBParams = useMemo(() => {
    const tagsList =
      historyState?.rootBackTo?.route === Routes.CE_PLUS
        ? cePlusTags || []
        : vulnerabilityTags || [];
    const CMDBFilterFields: IFilterField[] = [
      { name: 'vulnerabilitiesQIDs', value: [qid], type: Filter.type.MULTIPLE },
      {
        name: 'tagsAssets',
        value: tagsList || [],
        validate: (value) => validateFieldValue(value as string[]),
        type: Filter.type.MULTIPLE,
      },
    ];

    return {
      orderBy: {
        field: 'vulnerabilities',
        type: OrderBy.type.ASC,
      },
      pagination: initialPagination,
      filter: {
        ...vulnerabilitiesFilters,
        fields: validateField(CMDBFilterFields),
      },
    };
  }, [vulnerabilitiesFilters, qid, cePlusTags, historyState?.rootBackTo?.route, vulnerabilityTags]);

  const keys = Object.keys(vulnerabilitiesFilters);
  const hasTagAssetsParam =
    // @ts-ignore
    CMDBParams?.filter?.fields.find((field) => field.name === 'tagsAssets')?.value.length > 0;
  const shoudRenderAffectedHostsTooltip =
    (keys.length && keys.filter((key) => key !== 'suppressedVulnerabilities').length > 0) ||
    hasTagAssetsParam;

  const redirectToCMDBList = useCallback(() => {
    return history.push(`${Routes.CMDB}${getQueryString(CMDBParams)}`, {
      backTo: {
        route: `${Routes.VULNERABILITIES_LIST}/${qid}`,
        search,
        title: `Back to ${title}`,
      },
    });
  }, [history, CMDBParams, qid, search, title]);

  const setIsPotentialVulnerability = useCallback(() => {
    if (status === VulnerabilityStatus.POTENTIAL) {
      dispatch(cmdb.actions.setShowPotentialVulnerabilities(true));
      dispatch(assetDetails.actions.setShowPotentialVulnerabilities(true));
    }
  }, [dispatch, status]);

  const renderIcon = (value: string | undefined) => {
    if (!value) {
      return '';
    }

    return (
      <Icon
        component={
          value === VulnerabilitySchema.patchable._1 ? theme.icons.done : theme.icons.close
        }
        color={
          value === VulnerabilitySchema.patchable._1
            ? theme.colorSet.iconCheck
            : theme.colors.gray[500]
        }
      />
    );
  };

  const renderVendorReference = (vendor: Link) => (
    <Box d='flex' key={vendor.id} m={`${theme.spacing[2]} 0`} w={theme.sizes.full}>
      {renderValue(vendor)}
      <CopyToClipboard field={{ property: DetailsTitle.VendorReference, value: vendor.id }} />
    </Box>
  );

  return (
    <CardStyled
      title={!isMobile ? undefined : 'Details'}
      isHiddable={isMobile}
      headStyles={!isMobile ? {} : { pb: theme.sizes['2.5'], titlePaddingRight: theme.sizes[0] }}
      bodyPadding={!isMobile ? theme.sizes['2.5'] : theme.sizes[0]}
      cardPadding={
        !isMobile ? theme.sizes['5.5'] : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes['1.5']}`
      }
      showLoader={isLoading}
    >
      <Descriptions column={1}>
        <Descriptions.Item key='type' label={DetailsTitle.Type}>
          {type || NO_VALUE_LABEL}
        </Descriptions.Item>

        <Descriptions.Item key='category' label={DetailsTitle.Category}>
          {category || NO_VALUE_LABEL}
        </Descriptions.Item>

        <Descriptions.Item key='vendorReference' label={DetailsTitle.VendorReference}>
          <ItemContainer>
            {vendorReference.length === 0 && NO_VALUE_LABEL}
            {vendorReference.map((vendor: Link) =>
              !isEmptyObject(vendor) ? renderVendorReference(vendor) : NO_VALUE_LABEL,
            )}
          </ItemContainer>
        </Descriptions.Item>

        {!isAsset && (
          <Descriptions.Item key='affectedHosts' label={DetailsTitle.AffectedHosts}>
            {hosts ? (
              <AffectedHostsWrap alignItems='center' display='flex'>
                <Button
                  type='link'
                  onClick={() => {
                    setIsPotentialVulnerability();
                    redirectToCMDBList();
                  }}
                >
                  {hosts}
                </Button>
                {!!shoudRenderAffectedHostsTooltip && (
                  <Tooltip title={hiddenFilterTooltip} placement='top'>
                    <Icon
                      component={icons.hidden_filter}
                      dimension='20px'
                      className='tooltipInfo'
                      color={theme.colorSet.table.filters.hiddenFilterIcon}
                    />
                  </Tooltip>
                )}
              </AffectedHostsWrap>
            ) : (
              NO_VALUE_LABEL
            )}
          </Descriptions.Item>
        )}

        <Descriptions.Item key='pci' label={DetailsTitle.PCIVulnerability}>
          {renderIcon(pci)}
        </Descriptions.Item>

        <Descriptions.Item key='patch' label={DetailsTitle.PatchAvailable}>
          {renderIcon(patch)}
        </Descriptions.Item>

        <Descriptions.Item key='normPatchable' label={DetailsTitle.NormPatchAvailable}>
          {renderIcon(normPatchable)}
        </Descriptions.Item>

        <Descriptions.Item key='publishedDate' label={DetailsTitle.PublishedDate}>
          {formatUpdatedOrCreatedTime(publishedDate || '')}
        </Descriptions.Item>
      </Descriptions>
    </CardStyled>
  );
};
