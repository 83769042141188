import styled from 'styled-components';
import { Col, Form } from 'antd';
import { Card, Descriptions as DescriptionsShared, Select as SelectShared } from 'shared';
import { breakpoints } from 'styles/theme/breakpoints';
import { ThemeProps } from 'services/interfaces';
import { getCssVar } from '../../styles/theme';

export const Descriptions = styled(DescriptionsShared)<ThemeProps>`
  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
  }

  .ant-descriptions-item-container {
    align-items: center;
  }

  .ant-descriptions-item {
    padding-bottom: 30px;
  }

  tr:last-child {
    .ant-descriptions-item {
      padding-bottom: 5px;
    }
  }

  .ant-descriptions-item-label {
    align-items: center;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    width: 260px;

    @media (max-width: ${breakpoints.lg}) {
      max-width: 50%;
      margin-right: 20px;
    }
    @media (max-width: ${breakpoints.sm}) {
      max-width: 73%;
      margin-right: 3px;
    }
  }
`;

export const InputLabel = styled(Form.Item)<ThemeProps>`
  margin-bottom: 22px;

  label {
    font-weight: ${getCssVar('securitySettingsWeight')};
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: ${({ theme }) => theme.colors.red[700]};
    margin-right: 0;
    position: relative;
    top: -2px;
  }

  .ant-form-item-label {
    margin-bottom: 5px;
  }

  .ant-form-item-tooltip,
  svg {
    height: 1.5rem;
    width: 1.5rem;

    :hover {
      fill: ${getCssVar('arrow')};
    }
  }
`;

export const Select = styled(SelectShared)`
  && {
    width: 348px;
  }

  @media (max-width: ${breakpoints.sm}) {
    && {
      width: 100%;
    }
  }
`;

export const StyledCard = styled(Card)`
  .ant-descriptions-item-label {
    font-weight: ${getCssVar('securitySettingsWeight')};
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 10px 8px;
  }
`;

export const ColStyled = styled(Col)`
  @media (max-width: ${breakpoints.sm}) {
    padding: 0 !important;
  }
`;
