import { GuidanceItem } from 'services/api';
import { useFormik } from 'formik';
import { Editor, Button } from 'shared';
import { getStringWithoutTags, showError, showSuccess } from 'services/helpers';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { Routes } from 'services/entities';
import { servicesIntegrationsApi, AppDispatch, useRequestLoading, PayloadActionType } from 'store';
import { GuidanceType } from 'pages/ServicesIntegrations/types';
import { useDispatch } from 'react-redux';
import { RequestStatus } from 'store/loading/slice';
import { SpinContainer } from 'shared/Card/components/styled';
import { Spin } from 'antd';
import {
  EditorCounterText,
  EditorCounterWrapper,
  EditorWrapper,
  EditorErrorMessage,
  EditorButtonWrapper,
} from '../styled';

export const MESSAGE_MAX_LENGTH = 10000;

const validationSchema = Yup.object({
  text: Yup.string()
    .required('Please add description')
    .test('max', `Text should be less then ${MESSAGE_MAX_LENGTH} characters`, (value = '') => {
      return MESSAGE_MAX_LENGTH > getStringWithoutTags(value as string).length;
    }),
});

type EditFormProps = {
  serviceId: string;
  serviceName?: string;
  customerId: string;
  type: GuidanceType;
  data?: GuidanceItem;
};

export const EditForm = ({ serviceId, serviceName, customerId, type, data }: EditFormProps) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const goBack = () => {
    history.push({
      pathname: Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', serviceId),
      search: `?tab=${type}`,
    });
  };

  const submitHandler = (isDraft: boolean) => async (values: { text: string }) => {
    const result = (await dispatch(
      servicesIntegrationsApi.updateServiceGuidanceDetails({
        customerId,
        serviceId,
        requestBody: {
          data: values.text,
          isDraft,
          type,
          serviceName,
        },
      }),
    )) as PayloadActionType<GuidanceItem['text']>;

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      showError();
    } else {
      showSuccess(isDraft ? 'Draft successfully saved' : 'Published successfully');

      goBack();
    }
  };

  const { values, setFieldValue, setFieldTouched, errors, dirty } = useFormik({
    initialValues: {
      text: data?.draft || data?.text || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler(false),
  });

  const loading = useRequestLoading(
    servicesIntegrationsApi.updateServiceGuidanceDetails.typePrefix,
  );

  const textLength = getStringWithoutTags(values.text).length;

  return (
    <>
      <EditorWrapper isError={!!errors.text}>
        {loading && (
          <SpinContainer>
            <Spin size='large' />
          </SpinContainer>
        )}
        <Editor
          onChange={(value: string) => setFieldValue('text', value)}
          onBlur={() => setFieldTouched('text', true)}
          initialValue={values.text || ''}
          placeholder='Please enter text'
        />
        <EditorCounterWrapper>
          <EditorErrorMessage>{errors.text}</EditorErrorMessage>
          <EditorCounterText>
            {textLength} / {MESSAGE_MAX_LENGTH}
          </EditorCounterText>
        </EditorCounterWrapper>
      </EditorWrapper>

      <EditorButtonWrapper>
        <Button
          type='primary'
          onClick={() => submitHandler(false)(values)}
          disabled={data?.draft && !dirty ? false : !dirty || !!errors.text}
        >
          Publish now
        </Button>
        <Button
          type='secondary'
          onClick={() => submitHandler(true)(values)}
          disabled={!dirty || !!errors.text}
        >
          Save as draft
        </Button>
        <Button type='secondary' onClick={goBack}>
          Cancel
        </Button>
      </EditorButtonWrapper>
    </>
  );
};
