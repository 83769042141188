import React, { useCallback, useEffect, FunctionComponent } from 'react';
import { Divider, RadioChangeEvent, Space } from 'antd';
import { Box, Icon, InnerLayout, Radio } from 'shared';
import { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spacer } from '../../shared/TableFilters/styled';
import { getLayoutConfig, isSystemThemeModeActive } from './helpers';
import { getCardPadding, getHeadStyles } from '../NotificationsUpdate/helpers';
import { StyledCard } from '../../shared/Notification/styled';
import { getCssVar } from '../../styles/theme';
import { app, useApp } from '../../store';
import { HistoryState } from '../../services/interfaces';
import { defaultThemeMode, themeModes } from './constants';
import { ModeWrapper } from './styled';
import { Themes } from '../../themes/types';
import { useSystemThemeSwitcher } from './useThemeSwitcher';

export const Appearance = () => {
  const theme = useTheme();
  const { isMobile, currentThemeMode } = useApp();
  const { systemTheme } = useSystemThemeSwitcher(isSystemThemeModeActive);

  const dispatch = useDispatch();
  const { state: historyState } = useLocation<HistoryState>();
  const [themeMode, setThemeMode] = React.useState(currentThemeMode || defaultThemeMode);

  const layoutConfig = getLayoutConfig(historyState);

  useEffect(() => {
    const currentTheme = themeMode === defaultThemeMode ? systemTheme : themeMode;

    dispatch(app.actions.setTheme(currentTheme as Themes));
    dispatch(app.actions.setMode(themeMode));
  }, [dispatch, systemTheme, themeMode]);

  const setRadio = useCallback((event: RadioChangeEvent) => {
    setRadio(event.target.value);
  }, []);

  return (
    <InnerLayout {...layoutConfig}>
      <StyledCard
        headStyles={getHeadStyles(isMobile, theme)}
        bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
        cardPadding={getCardPadding(isMobile, theme)}
      >
        <Box fontSize='20px' fontWeight={700}>
          Theme
        </Box>
        <Spacer h={isMobile ? '16px' : '24px'} />
        <Divider style={{ margin: '8px 0', backgroundColor: getCssVar('divider.color') }} />
        <Spacer h={isMobile ? '16px' : '24px'} />
        <Radio.Group value={themeMode} onChange={setRadio}>
          <Space direction={isMobile ? 'vertical' : 'horizontal'}>
            {themeModes.map((e: { value: string; icon: FunctionComponent; label: string }) => (
              <ModeWrapper
                key={e.value}
                isSelected={e.value === themeMode}
                onClick={() => setThemeMode(e.value)}
              >
                <Icon component={e.icon} />
                <Radio value={e.value}>{e.label}</Radio>
              </ModeWrapper>
            ))}
          </Space>
        </Radio.Group>
      </StyledCard>
    </InnerLayout>
  );
};
