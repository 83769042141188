import styled from 'styled-components';
import { Tabs } from 'antd';
import { ShadowTabsProps } from './types';

export const ShadowTabsWrapper = styled.div<Omit<ShadowTabsProps, 'children'>>`
  .ant-tabs,
  .ant-tabs-nav-list,
  .ant-tabs-nav-wrap {
    overflow: visible !important;
  }

  .ant-tabs-nav-list {
    height: ${({ tabHeight }) => tabHeight || 'auto'};
    margin-left: ${({ tabMargin }) => tabMargin || '0px'};
  }
`;

export const ShadowTabsStyled = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;

    &:before {
      display: none;
    }

    .ant-tabs-tab {
      border-radius: ${({ theme }) =>
        `${theme.sizes['1.5']} ${theme.sizes['1.5']} ${theme.sizes[0]} ${theme.sizes[0]}`};
      color: ${({ theme }) => theme.colorSet.tabsNavigation.inactiveTabColor};
      font-size: ${({ theme }) => theme.sizes['3.5']};
      padding: ${({ theme }) => `${theme.sizes[2]} ${theme.sizes[4]}`};

      &:hover {
        color: ${({ theme }) => theme.colorSet.tabsNavigation.inactiveTabHoverColor};
      }
    }
    .ant-tabs-tab-active {
      background: ${({ theme }) => theme.colorSet.tabsNavigation.activeTabBg};
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
      color: ${({ theme }) => theme.colorSet.tabsNavigation.activeTabColor};
    }
    .ant-tabs-ink-bar {
      opacity: 0;
    }
  }
`;
