import React from 'react';
import { HistoryState } from 'services/interfaces';
import { BackLink, InnerLayoutProps } from 'shared';

export const getLayoutConfig = (
  historyState?: HistoryState,
  pathname?: string,
): InnerLayoutProps => ({
  title: <>CVSS Vector Strings</>,
  titleTooltip: (
    <>
      CVSS Base and Temporal scores are represented as a numeric value and also as a vector string.
      The vector string is a textual representation of the metric values used to determine the
      score.
    </>
  ),
  backLink:
    historyState && historyState?.backTo?.route !== pathname ? (
      <BackLink historyState={historyState} />
    ) : null,
});
