import {
  Action,
  ActionReducerMapBuilder,
  combineReducers,
  configureStore,
  createAction,
  createReducer,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { tokenRefreshMiddleware } from 'store/middlewares/tokenRefresh.middleware';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { auth } from './auth/slice';
import { app } from './app/slice';
import { cybersafety } from './people/slice';
import { documents } from './documents/slice';
import { alarms } from './alarms/slice';
import { loading } from './loading/slice';
import { software } from './software/slice';
import { adminUsers } from './adminUsers/slice';
import { notifications } from './notifications/slice';
import { vulnerability, vulnerabilityInitialState } from './vulnerability/slice';
import { assetDetails } from './assetDetails/slice';
import { securitySettings } from './securitySettings/slice';
import { audit } from './audit/slice';
import {
  activeEDRAgents,
  activeServices,
  events,
  eventsByServices,
} from './managedDetectionsResponse';
import { supportRequests } from './supportRequests/slice';
import { process as processSlice } from './process/slice';
import { cmdb } from './cmdb/slice';
import { dashboard } from './dashboard/slice';
import { findingsVulnerabilityDetails } from './penetrationTesting/findingsVulnerabilityDetails/slice';
import { changeCustomerAction } from './actionCreators';
import { customer } from './customer/slice';
import { findings, penetrationTestDetails, penetrationTests } from './penetrationTesting';
import { monthlyReport } from './monthlyReport/slice';
import { dpl } from './dataProtectionLibrary/slice';
import { patches, patchManagementAssets, patchManagementDashboard } from './patchManagement';
import { platform } from './patchManagement/platform';
import { vendorSeverity } from './patchManagement/vendorSeverity';
import { routes } from './routes/slice';
import { cyberEssentialPlus } from './cyberEssentialPlus/slice';
import { cyberResilienceScoreTrends } from './cyberResilienceScoreTrends/slices';
import { maintenanceSetting } from './maintenance/slice';
import { tagsManagement } from './tagsManagement/slice';
import { newsNotifications } from './newsNotifications/slice';
import { mdr } from './managedDetectionsResponse/mdr/slice';
import { servicesIntegrations } from './servicesIntegrations/slice';

export const persistStorageKey = process.env.REACT_APP_VERSION || 'root';

const persistConfig = {
  key: persistStorageKey,
  storage,
  blacklist: [
    'monthlyReport',
    'cyberResilienceScoreTrends',
    'cybersafety',
    'patchManagementDashboard',
    'servicesIntegrations',
  ],
};

export const logoutAction = createAction('USER_LOGOUT', (byUser?: boolean) => ({
  payload: Boolean(byUser),
}));

const appReducer = combineReducers({
  app: app.reducer,
  auth: auth.reducer,
  cybersafety: cybersafety.reducer,
  documents: documents.reducer,
  software: software.reducer,
  alarms: alarms.reducer,
  adminUsers: adminUsers.reducer,
  notifications: notifications.reducer,
  loading: loading.reducer,
  vulnerability: vulnerability.reducer,
  assetDetails: assetDetails.reducer,
  securitySettings: securitySettings.reducer,
  audit: audit.reducer,
  activeServices: activeServices.reducer,
  activeEDRAgents: activeEDRAgents.reducer,
  events: events.reducer,
  eventsByServices: eventsByServices.reducer,
  supportRequests: supportRequests.reducer,
  cmdb: cmdb.reducer,
  process: processSlice.reducer,
  dashboard: dashboard.reducer,
  customer: customer.reducer,
  penetrationTests: penetrationTests.reducer,
  penetrationTestDetails: penetrationTestDetails.reducer,
  findings: findings.reducer,
  findingsVulnerabilityDetails: findingsVulnerabilityDetails.reducer,
  monthlyReport: monthlyReport.reducer,
  cyberResilienceScoreTrends: cyberResilienceScoreTrends.reducer,
  patchManagementPatches: patches.reducer,
  platform: platform.reducer,
  severities: vendorSeverity.reducer,
  dpl: dpl.reducer,
  patchManagementAssets: patchManagementAssets.reducer,
  patchManagementDashboard: patchManagementDashboard.reducer,
  routes: routes.reducer,
  cyberEssentialPlus: cyberEssentialPlus.reducer,
  maintenance: maintenanceSetting.reducer,
  tagsManagement: tagsManagement.reducer,
  newsNotifications: newsNotifications.reducer,
  mdr: mdr.reducer,
  servicesIntegrations: servicesIntegrations.reducer,
});

export type AppState = ReturnType<typeof appReducer>;

const rootReducer = createReducer({} as AppState, (builder: ActionReducerMapBuilder<AppState>) => {
  builder
    .addCase(logoutAction, (state: AppState, action: Action) => ({
      ...appReducer(undefined, action),
      app: { ...state?.app, lastVisitedPage: '', currentCustomerId: '', currentCustomerName: '' },
    }))
    .addCase(changeCustomerAction, (state: AppState, action: Action) => {
      return appReducer(
        {
          ...appReducer(undefined, action),
          auth: state?.auth || {},
          app: state?.app || {},
          vulnerability: {
            ...vulnerabilityInitialState,
            allVulnerabilitiesTrend: state?.vulnerability.allVulnerabilitiesTrend,
            vulnerabilitiesTrendsByCriticalities:
              state?.vulnerability.vulnerabilitiesTrendsByCriticalities,
          },
          events: state?.events,
          activeEDRAgents: state?.activeEDRAgents,
          loading: state?.loading || {},
        },
        action,
      );
    })
    .addDefaultCase((state: AppState, action: Action) => appReducer(state, action));
});

// combine persisted config with root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }).concat(tokenRefreshMiddleware),
  ],
});

if (window.Cypress) {
  window.store = store;
}

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
