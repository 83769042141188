import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cybersafetyApi, useApp, useAverageConfidence, useCustomer, useLoading } from 'store';
import { Routes } from 'services/entities';
import { isEmptyObject } from 'services/helpers';
import { getQueryString } from 'services/api/core/request';
import { Card } from 'shared';
import { Area } from 'shared/charts';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { useTheme } from 'styled-components';
import { AverageConfidenceLabels, TooltipTitles } from '../../constants';
import { getOption, getRedirectParams } from './helpers';
import { ScrollContainer } from '../styled';

export const AverageConfidence = () => {
  const history = useHistory();
  const theme = useTheme();
  const { isMobile } = useApp();
  const customerId = useCustomer();
  const dispatch = useDispatch();
  const averageConfidence = useAverageConfidence();
  const loading = useLoading([cybersafetyApi.getAverageConfidence.typePrefix]);

  const redirectToPhishing = React.useCallback(
    (params: EChartsEventParams) => {
      if (
        !params.name ||
        params.name === AverageConfidenceLabels.InitialAssessments ||
        params.name === AverageConfidenceLabels.LatestRefresher
      ) {
        return;
      }

      history.push(`${Routes.PEOPLE_PHISHING}${getQueryString(getRedirectParams(params.name))}`);
    },
    [history],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToPhishing(params),
    }),
    [redirectToPhishing],
  );

  React.useEffect(() => {
    dispatch(
      cybersafetyApi.getAverageConfidence({
        customerId,
      }),
    );
  }, [dispatch, customerId]);

  return (
    <Card
      title='Average confidence'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles.AverageConfidence}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2] }
          : { titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={
        !isMobile ? theme.sizes[2] : `${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[0]}`
      }
      cardPadding={
        !isMobile
          ? theme.sizes[6]
          : `${theme.sizes[5]} ${theme.sizes[0]} ${theme.sizes[5]} ${theme.sizes[5]}`
      }
      showLoader={loading[cybersafetyApi.getAverageConfidence.typePrefix]}
    >
      {averageConfidence && !isEmptyObject(averageConfidence) ? (
        <ScrollContainer>
          <TotalCustomTooltipContainer>
            <Area
              values={averageConfidence}
              getOption={getOption}
              height={300}
              onEvents={onEvents}
              isMobile={isMobile}
            />
          </TotalCustomTooltipContainer>
        </ScrollContainer>
      ) : (
        emptyMessage('No average confidence data yet')
      )}
    </Card>
  );
};
