import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { LogoPlaceholder as StyledLogoPlaceholder, Page } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/titleBg.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const LogoPlaceholder = styled(StyledLogoPlaceholder)`
  bottom: 180px;
  left: 90px;
`;

export const PageTitleDate = styled.div`
  bottom: 90px;
  font-size: 16px;

  left: 90px;
  position: absolute;
`;
