/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DPLDocumentType {
  GUIDES = 'GUIDES',
  CHECKLISTS = 'CHECKLISTS',
  POLICIES = 'POLICIES',
  NOTICES = 'NOTICES',
  OTHER = 'OTHER',
}
