import { Box, Button, Icon, InnerLayoutProps as InnerLayout } from 'shared';
import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ActionType } from '../../shared/ActionPopup/types';
import { CreateModalMode } from '../Users/types';
import { showError, showMessage } from '../../services/helpers';
import { initialPagination, loadingNotificationMessage } from '../../app-constants';
import { MessageType } from '../../store';
import { RequestStatus } from '../../store/loading/slice';
import { popupConfig } from './constant';
import { CustomMenu } from './components/styled';
import { PopupParams } from './types';
import { tagsManagementApi } from '../../store/tagsManagement/thunks';
import { Routes } from '../../services/entities';
import { getQueryString } from '../../services/api/core/request';
import { initialOrderBy as patchesOrderBy } from '../PatchManagementPatches/constants';
import { Filter } from '../../services/api';
import { tagsManagement } from '../../store/tagsManagement/slice';
import { getCssVar } from '../../styles/theme';

export const getLayoutConfig = (setVisible: () => void, isFullAccess: boolean): InnerLayout => ({
  title: 'Tags management',
  customComponent: isFullAccess ? (
    <Box mt='4px'>
      <Button type='primary' onClick={() => setVisible()}>
        Create tag
      </Button>
    </Box>
  ) : undefined,
});

export const showPopup = ({
  popupId,
  setPopupDetails,
  setIsVisiblePopup,
  tagData,
  dispatch,
  customerId,
  onSuccess,
  isMobile,
}: PopupParams) => {
  switch (popupId) {
    case popupConfig.edit.id:
      dispatch(tagsManagement.actions.setEditTags(tagData));
      break;
    case popupConfig.delete.id:
      setPopupDetails({
        ...popupConfig.delete,
        title: `${popupConfig.delete.title} ‘${tagData.name}’?`,
        text: (
          <Box>
            Deleting the tag will remove it from all{' '}
            <Link
              target='_blank'
              to={{
                pathname: Routes.CMDB,
                search: getQueryString({
                  pagination: initialPagination,
                  orderBy: patchesOrderBy,
                  filter: {
                    fields: [
                      {
                        name: 'tagsAssets',
                        value: [tagData.id],
                        type: Filter.type.MULTIPLE,
                      },
                    ],
                  },
                }),
              }}
            >
              associated assets
            </Link>
            . {isMobile && <br />}
            Please review the assets and their associated <br />
            tags before proceeding with the deletion. <br />
            <br />
            This action can't be undone.
          </Box>
        ),
        onApply: async (comment = '') => {
          showMessage(loadingNotificationMessage, MessageType.Loading);

          const result = await dispatch(
            tagsManagementApi.deleteTag({
              customerId,
              tagId: tagData.id,
              requestBody: { comment },
            }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          showMessage({ content: `${tagData.name} has been deleted` }, MessageType.Success);
          onSuccess();
        },
      });
      setIsVisiblePopup(true);
      break;
    default:
      setPopupDetails(null);
  }
};

export const getCreateModalTitle = (mode: CreateModalMode) => {
  switch (mode) {
    case CreateModalMode.Create:
      return 'Add tag';
    case CreateModalMode.Edit:
      return 'Edit user';
    default:
      return '';
  }
};

export const getCreateModalButtonLabel = (mode: CreateModalMode) => {
  switch (mode) {
    case CreateModalMode.Create:
      return 'Create tag';
    case CreateModalMode.Edit:
      return 'Save';
    default:
      return '';
  }
};

export const getCustomMenu = (
  actions: ActionType[],
  handleClickAction: (popupId: string) => void,
) => (
  <CustomMenu>
    {actions.map(({ name, icon, popupId, isDanger }, index) => {
      const isLast = index === actions.length - 1;

      return (
        <React.Fragment key={name}>
          {!isLast ? null : (
            <Menu.Divider style={{ backgroundColor: getCssVar('divider.color') }} />
          )}
          <CustomMenu.Item
            key={name}
            icon={<Icon component={icon} color={getCssVar('users.customMenuIcons')} />}
            onClick={() => handleClickAction(popupId)}
          >
            {!isDanger ? name : <Box color={getCssVar('colorHigh')}>{name}</Box>}
          </CustomMenu.Item>
        </React.Fragment>
      );
    })}
  </CustomMenu>
);
