import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SegmentedButton } from '../../../../Segmented';
import { ThemeSwitcherWrapper } from './styled';
import { defaultThemeMode, themeModes } from '../../../../../pages/Appearance/constants';
import { useSystemThemeSwitcher } from '../../../../../pages/Appearance/useThemeSwitcher';
import { isSystemThemeModeActive } from '../../../../../pages/Appearance/helpers';
import { app, useApp } from '../../../../../store';
import { Themes } from '../../../../../themes/types';

export const MobileThemeSwitcher = () => {
  const { systemTheme } = useSystemThemeSwitcher(isSystemThemeModeActive);
  const dispatch = useDispatch();
  const { themeName, currentThemeMode } = useApp();
  const [selectedTheme, setSelectedTheme] = useState(currentThemeMode || defaultThemeMode);

  const isHasThemeSwitcher = themeName !== Themes.Nasstar;

  useEffect(() => {
    const currentTheme = selectedTheme === defaultThemeMode ? systemTheme : selectedTheme;

    dispatch(app.actions.setTheme(currentTheme as Themes));
    dispatch(app.actions.setMode(selectedTheme));
  }, [dispatch, systemTheme, selectedTheme]);

  if (!isHasThemeSwitcher) {
    return null;
  }

  return (
    <ThemeSwitcherWrapper>
      Theme
      <SegmentedButton
        options={themeModes}
        defaultValue={selectedTheme}
        onChange={setSelectedTheme}
      />
    </ThemeSwitcherWrapper>
  );
};
