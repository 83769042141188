import React from 'react';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { SupportRequestDetailsResponse } from 'services/api';
import { HistoryState } from 'services/interfaces';
import { Routes } from 'services/entities';
import { formatUpdatedOrCreatedTime } from 'services/helpers';
import { backlinkRoutes } from 'app-constants/routes';
import { Theme } from 'styles/theme/types';
import { StatusTagColoring } from 'shared/TagColoring/StatusTagColoring';
import { StatusComponent } from 'shared/layouts/Inner/components';
import { IRowTableType } from './types';
import { getLabelPriority, getNormalizedStatus } from '../SupportRequests/helpers/helpers';
import { Hyphen } from './styled';

export const getLayoutConfig = (
  historyState: HistoryState | undefined,
  title: string,
  priority = '',
  isLoadingContent = true,
  pathname?: string,
): InnerLayout => ({
  title,
  backLink: (
    <BackLink
      historyState={
        historyState?.backTo && pathname !== historyState?.backTo?.route ? historyState : undefined
      }
      defaultRoute={Routes.SUPPORT_REQUESTS}
      defaultTitle={backlinkRoutes[Routes.SUPPORT_REQUESTS]}
    />
  ),
  status: <StatusComponent level={getNormalizedStatus(priority) || ''} />,
  isLoadingContent,
});

export const getRowsForSummaryTable = (
  isServiceRequest: boolean,
  request: SupportRequestDetailsResponse,
) => {
  const { type, profileName, created, updated, priority, impact, status } = request;

  const dataForTableWithoutPriority: Array<IRowTableType> = [
    { title: 'Type', description: type || '-' },
    {
      title: 'Status',
      description: status || '-',
    },
    { title: 'Created by', description: profileName || '-' },
    {
      title: 'Created',
      description: request && created ? formatUpdatedOrCreatedTime(created || '') : '-',
    },
    {
      title: 'Updated',
      description: request && updated ? formatUpdatedOrCreatedTime(updated || '') : '-',
    },
  ];

  if (!isServiceRequest) {
    const priorityRow = [
      {
        title: 'Priority',
        description: priority ? (
          <>
            <span>{priority}</span>
            <Hyphen>-</Hyphen>
            <StatusTagColoring title={getLabelPriority(priority || '')} />
          </>
        ) : (
          '-'
        ),
      },
      {
        title: 'Impact',
        description: impact ? <StatusTagColoring title={impact} /> : '-',
      },
    ];

    const TypeAndStatus: Array<IRowTableType> = dataForTableWithoutPriority.slice(0, 2);
    const RestInfoRequest: Array<IRowTableType> = dataForTableWithoutPriority.slice(2);

    const dataForTableWithPriority: Array<IRowTableType> = [
      ...TypeAndStatus,
      ...priorityRow,
      ...RestInfoRequest,
    ];

    return dataForTableWithPriority;
  }

  return dataForTableWithoutPriority;
};

export const getHeadStyles = (isMobile: boolean, theme: Theme) =>
  !isMobile
    ? { pb: `${theme.spacing[4]}` }
    : { pb: `${theme.spacing[2]}`, titlePaddingRight: `${theme.spacing[0]}` };

export const getCardPadding = (isMobile: boolean, theme: Theme) =>
  !isMobile
    ? `${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[2]} ${theme.spacing[6]}`
    : `${theme.spacing['3.5']} ${theme.spacing[5]} ${theme.spacing[2]} ${theme.spacing[5]}`;
