import React from 'react';
import { Icon } from 'shared';
import { getCssVar, icons } from 'styles/theme';
import { NotificationStatus } from '../../../services/api';

type IconColoringProps = {
  status: string;
};

export const IconColoring = ({ status }: IconColoringProps) => {
  const statusType = (status: string) => {
    switch (status) {
      case NotificationStatus.PUBLISHED:
        return getCssVar('activeGreen');
      default:
        return getCssVar('unActiveArrow');
    }
  };

  return (
    <Icon
      component={icons.fiber_manual_record}
      color={statusType(status)}
      dimension='10px'
      margin='0 8px 4px 0'
    />
  );
};
