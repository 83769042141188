import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  CmdbService,
  OrderBy,
  Pagination,
  Filters,
  HostAssetOwnerSchema,
  HostId,
  CustomerId,
  VulnerabilityStatus,
  PatchVendorSeverityFilter,
  PatchManagementService,
  PatchStatus,
} from 'services/api';

const getVulnerabilitiesBySeverity = createAsyncThunk(
  'vulnerabilities-by-severity/get',
  async (requestBody: {
    customerId: CustomerId;
    hostId: HostId;
    status?: VulnerabilityStatus.CONFIRMED | VulnerabilityStatus.POTENTIAL | undefined;
  }) => {
    try {
      return await CmdbService.getDetailsVulnerabilitiesBySeverityLevel(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getDetailsVulnerabilitiesListByHost = createAsyncThunk(
  'details-vulnerabilities-list-by-host/get',
  async (requestBody: {
    customerId: CustomerId;
    hostId: HostId;
    orderBy: OrderBy;
    pagination: Pagination;
    filter?: Filters;
  }) => {
    try {
      return await CmdbService.getDetailsVulnerabilitiesListByHost(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getDetailsSuppressedVulnerabilitiesListByHost = createAsyncThunk(
  'details-suppressed-vulnerabilities-list-by-host/get',
  async (requestBody: {
    customerId: CustomerId;
    hostId: HostId;
    orderBy: OrderBy;
    pagination: Pagination;
    filter?: Filters;
  }) => {
    try {
      return await CmdbService.getDetailsSuppressedVulnerabilitiesListByHost(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getDetailsAdditionalInformation = createAsyncThunk(
  'details-additional-information/get',
  async (requestBody: { customerId: CustomerId; hostId: HostId }) => {
    try {
      return await CmdbService.getDetailsAdditionalInformation(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const setsAssetOwner = createAsyncThunk(
  'asset-owner/post',
  async (requestBody: {
    customerId: CustomerId;
    hostId: HostId;
    requestBody?: HostAssetOwnerSchema;
  }) => {
    try {
      return await CmdbService.setsAssetOwner(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getAssetDetails = createAsyncThunk(
  'asset-owner/get',
  async (requestBody: { customerId: CustomerId; hostId: HostId }) => {
    try {
      return await CmdbService.getAssetDetails(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getInterfaceDetails = createAsyncThunk(
  'interface-details/get',
  async (requestBody: { customerId: CustomerId; hostId: HostId }) => {
    try {
      return await CmdbService.getInterfaceDetails(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getInstalledSoftware = createAsyncThunk(
  'installed-software/get',
  async (requestBody: {
    customerId: CustomerId;
    hostId: HostId;
    orderBy: OrderBy;
    pagination: Pagination;
    filter?: Filters;
  }) => {
    try {
      return await CmdbService.getInstalledSoftware(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getVulnerabilitiesCountByStatus = createAsyncThunk(
  'asset-vulnerabilities-count-by-status/get',
  async (requestBody: { customerId: CustomerId; hostId: HostId }) => {
    try {
      return await CmdbService.getAssetVulnerabilitiesCountByStatus(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getAssetPatchesBySeverity = createAsyncThunk(
  'asset-patches-by-severity/get',
  async (requestBody: {
    customerId: CustomerId;
    assetId: HostId;
    patchStatus: PatchStatus;
    vendorSeverity?: PatchVendorSeverityFilter;
  }) => {
    try {
      const data = await PatchManagementService.getAssetPatchesBySeverity(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getAssetRecentPatches = createAsyncThunk(
  'asset-recent-patches-by-severity/get',
  async (requestBody: {
    customerId: CustomerId;
    assetId: HostId;
    patchStatus: PatchStatus;
    vendorSeverity?: PatchVendorSeverityFilter;
    limit?: number;
  }) => {
    try {
      const data = await PatchManagementService.getAssetRecentPatches(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const assetDetailsApi = {
  getVulnerabilitiesBySeverity,
  getDetailsVulnerabilitiesListByHost,
  getDetailsSuppressedVulnerabilitiesListByHost,
  getDetailsAdditionalInformation,
  setsAssetOwner,
  getAssetDetails,
  getInterfaceDetails,
  getInstalledSoftware,
  getVulnerabilitiesCountByStatus,
  getAssetPatchesBySeverity,
  getAssetRecentPatches,
};
