import { ReactNode } from 'react';
import * as S from './styled';

type ConnectionDetailsItemProps = {
  label?: string;
  value?: ReactNode;
};

export const ConnectionDetailsItem = ({ label, value }: ConnectionDetailsItemProps) => {
  return (
    <S.DetailsItemWrapper>
      <S.DetailsItemLabel className='details-item-label'>{label}</S.DetailsItemLabel>
      <S.DetailsItemValue className='details-item-value'>{value}</S.DetailsItemValue>
    </S.DetailsItemWrapper>
  );
};
