import { capitalize, startCase } from 'lodash';
import { Colors, Values } from '../types';
import { LabelFormatterParams } from '../interfaces';
import './style.css';
import { Theme } from '../../../styles/theme/types';

export const getOption = (
  values: Values,
  colors: Colors,
  fullInformation: boolean | undefined,
  isMobile: boolean,
  hasClickHandler: boolean,
  theme: Theme,
) => {
  const seriesList = Object.entries(values).map(([name, value]) => {
    const container = {
      type: 'bar',
      stack: 'total',
      barWidth: '100%',
      name: startCase(name),
      data: [value],
      label: fullInformation
        ? null
        : {
            color: '#fff',
            minWidth: 15,
            fontSize: 13,
            fontWeight: 'bold',
            lineHeight: 1,
            show: value !== 0,
            fontFamily: theme.colorSet.fontFamily,
          },
      emphasis: {
        label: {
          show: value !== 0,
        },
      },
      itemStyle: {
        color: colors[name],
      },
      cursor: hasClickHandler ? 'pointer' : 'auto',
      silent: !hasClickHandler,
    };

    return container;
  });

  return {
    tooltip:
      !fullInformation && !isMobile
        ? {
            appendToBody: true,
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            textStyle: {
              color: theme.colorSet.charts.tooltip.color,
            },
            backgroundColor: theme.colorSet.charts.tooltip.bg,
            borderColor: theme.colorSet.charts.tooltip.bg,
            extraCssText: isMobile && 'width:250px',
            formatter: (name: LabelFormatterParams[]) =>
              name
                .map(
                  (item: LabelFormatterParams) => `
              <div class="horizontal-bar-tooltip-wrapper">
                <span class="horizontal-bar-tooltip-circle" style="background-color: ${
                  item.color
                }"></span>
                <div class="horizontal-bar-tooltip-title">${capitalize(
                  item.seriesName.toLowerCase(),
                )}</div>
                <span class="horizontal-bar-tooltip-value">${item.data}</span>
              </div>`,
                )
                .join(''),
          }
        : null,
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      containLabel: false,
    },
    xAxis: {
      splitLine: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      max: Object.values(values).reduce((a, b) => a + b, 0),
    },
    yAxis: {
      type: 'category',
      data: [''],
    },
    series: seriesList,
  };
};
