import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Gutter } from 'antd/lib/grid/row';
import { Col, Row } from 'antd';
import { MobileTable, MobileTableCol } from 'shared';
import { CustomTable } from 'shared/Table/CustomTable';
import { excludeAgedHostTag } from 'services/helpers';
import { Routes } from 'services/entities';
import {
  Filter,
  GridRequestParams,
  SuppressedVulnerabilitiesResponse,
  TagSchema,
  VulnerabilitySchema,
} from 'services/api';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { useFilter } from 'services/hooks/useFilter';
import {
  AppDispatch,
  useApp,
  useCustomer,
  useLoading,
  useTagsManagement,
  useVulnerability,
  vulnerability,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { defaultCurrent, defaultPageSize } from 'app-constants';
import { backlinkRoutes } from 'app-constants/routes';

import { HistoryState } from 'services/interfaces';
import { tagsManagementApi } from 'store/tagsManagement/thunks';
import { getQueryString } from 'services/api/core/request';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { InputType } from '../../../../services/api';
import { columns } from './table-columns';
import {
  suppressedFilterFields as staticFilterFields,
  suppressedItialOrderBy as initialOrderBy,
  suppressedSearchSettings as searchSettings,
  tabsKeys,
} from '../../constants';
import { OptionType } from '../../../../shared/Table/types';
import { excludeFilterFields } from '../../helpers';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const FULL_WIDTH_COLS = 32;

export const SuppressedVulnerabilitiesTab = ({
  updateQueryParams,
}: {
  updateQueryParams: (params: Partial<GridRequestParams>) => void;
}) => {
  const customerId = useCustomer();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { state } = useLocation<HistoryState>();
  const {
    suppressedVulnerabilities,
    suppressVulnerabilitiesUsers,
    selectedTags,
  } = useVulnerability();
  const { tagsList } = useTagsManagement();

  const historyState = useMemo(() => {
    return state;
    // eslint-disable-next-line
  }, []);

  const { isMobile } = useApp();
  const loading = useLoading([
    vulnerabilitiesApi.getSuppressedVulnerabilities.typePrefix,
    vulnerabilitiesApi.exportVulnerability.typePrefix,
    vulnerabilitiesApi.getSuppressVulnerabilitiesUsers.typePrefix,
    tagsManagementApi.getTagsList.typePrefix,
  ]);

  const selectTagOptions = useMemo(() => {
    return tagsList
      ?.filter(excludeAgedHostTag)
      .map((item: TagSchema) => ({ label: item?.name, value: item?.id }));
  }, [tagsList]);

  const filterFields = useMemo(() => {
    return [
      {
        field: 'suppressedBy',
        options: suppressVulnerabilitiesUsers?.map((user: string) => ({
          label: user,
          value: user,
        })),
        label: 'Suppressed by',
        inputType: InputType.SELECTOR,
        filterType: Filter.type.MULTIPLE,
      },

      ...staticFilterFields,
      {
        field: 'tagsAssets',
        options: selectTagOptions as OptionType[],
        label: 'Tags',
        inputType: InputType.SELECTOR,
        filterType: Filter.type.MULTIPLE,
      },
    ];
  }, [suppressVulnerabilitiesUsers, selectTagOptions]);

  const GUTTER: [Gutter, Gutter] = !isMobile
    ? [H_GUTTER, V_GUTTER]
    : [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

  const [params, updateParams] = useFilter({
    apiCall: vulnerabilitiesApi.getSuppressedVulnerabilities,
    dispatch,
    isMobile,
    customerId,
    filter: {},
    initialOrderBy,
    page: 'VULNERABILITIES_LIST',
    historyState: state,
  });

  const filteredFields = excludeFilterFields(params?.filter?.fields, ['expiredSuppression']);

  const queryString = params?.filter
    ? getQueryString({
        filter: { ...params?.filter, fields: filteredFields, suppressedVulnerabilities: true },
        tab: tabsKeys.suppressed,
      })
    : '';

  const handleTableClick = React.useCallback(
    (record: VulnerabilitySchema) => {
      const selectedFilterTags = params.filter?.fields?.find(
        (filter) => filter.name === 'tagsAssets',
      );

      dispatch(vulnerability.actions.setFilterTags((selectedFilterTags?.value as string[]) || []));

      history.push(`${Routes.VULNERABILITIES_LIST}/${record.id}${queryString}`, {
        backTo: {
          route: `${Routes.VULNERABILITIES_LIST}`,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[Routes.VULNERABILITIES_LIST]}`,
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [dispatch, history, historyState?.backTo, params.filter?.fields, queryString],
  );

  useEffect(() => {
    dispatch(tagsManagementApi.getTagsList(customerId));
  }, [dispatch, customerId, selectedTags]);

  useEffect(() => {
    dispatch(vulnerabilitiesApi.getSuppressVulnerabilitiesUsers(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    updateQueryParams(params);
    // eslint-disable-next-line
  }, [params]);

  const emptyDataRows = emptyMessage('No vulnerabilities present');

  return (
    <Row gutter={GUTTER}>
      {!isMobile ? (
        <Col span={FULL_WIDTH_COLS} xs={24} xxl={24}>
          <CustomTable<VulnerabilitySchema, GridRequestParams>
            data={suppressedVulnerabilities}
            columns={getTableColumns(columns, params.orderBy)}
            params={params}
            setParams={updateParams}
            searchSettings={searchSettings}
            filterFields={filterFields}
            defaultPageSize={defaultPageSize}
            defaultCurrent={defaultCurrent}
            onRowClickHandle={handleTableClick}
            isLoadingContent={loading[vulnerabilitiesApi.getSuppressedVulnerabilities.typePrefix]}
            emptyMessage={emptyDataRows}
          />
        </Col>
      ) : (
        <MobileTableCol span={FULL_WIDTH_COLS} flex='auto'>
          <MobileTable<VulnerabilitySchema, GridRequestParams>
            data={suppressedVulnerabilities as SuppressedVulnerabilitiesResponse}
            columns={columns}
            params={params}
            setParams={updateParams}
            searchSettings={searchSettings}
            filterFields={filterFields}
            onRowClick={handleTableClick}
            initialOrderBy={initialOrderBy}
            isLoadingContent={loading[vulnerabilitiesApi.getSuppressedVulnerabilities.typePrefix]}
            emptyMessage={emptyDataRows}
          />
        </MobileTableCol>
      )}
    </Row>
  );
};
