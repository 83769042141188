import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page } from '../styled';

export const PageWrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide12.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const SectionTitle = styled.h4`
  font-size: 16px !important;
  left: 90px;
  position: absolute;
  top: 213px;
`;

export const List = styled.ul`
  font-size: 15px;
  left: 88px;
  list-style: none;
  position: absolute;
  top: 238px;

  > li {
    font-weight: 300;
    padding: 5px 11px;
    position: relative;

    span {
      font-size: 12px;
      left: 2px;
      position: absolute;
      top: 6px;
    }
  }
`;
