import styled from 'styled-components';
import { Divider as AntDivider, Dropdown as AntdDropdown } from 'antd';
import { breakpoints } from 'styles/theme';

export const Divider = styled(AntDivider)`
  margin: 0;
`;

export const Dropdown = styled(AntdDropdown)`
  align-items: end;
  display: flex;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 16px;
  }
`;
