import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FindingsData } from 'pages/Findings/types';
import { penetrationTestingApi } from '../thunks';

interface InitialState {
  data: FindingsData;
}

const initialState: InitialState = {
  data: {},
};

const extraReducers = {
  [penetrationTestingApi.getFindingsData.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<FindingsData>,
  ) => {
    state.data = action.payload;
  },
  [penetrationTestingApi.getFindingsData.rejected.toString()]: (state: InitialState) => {
    state.data = {};
  },
};

export const findings = createSlice({
  name: 'findings',
  initialState,
  reducers: {},
  extraReducers,
});
