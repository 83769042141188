import styled from 'styled-components';
import { Button } from 'antd';
import { Theme } from 'styles/theme/types';

type Props = { theme: Theme; checked?: boolean };

export const StyledButton = styled(Button)<Props>`
  background-color: ${({ theme }: Props) => theme.colors.yellow[500]};
  border-radius: 50%;
  box-shadow: ${({ theme }: Props) =>
    `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[0]} ${theme.colors.yellow[500]}${theme.opacity[70]}`};
  height: 44px;
  width: 44px;

  &:hover,
  &.hover,
  &:focus,
  &:active,
  &.active {
    background-color: ${({ theme }: Props) => theme.colors.yellow[500]};
    border-radius: 50%;
    height: 44px;
    width: 44px;
  }

  ${({ checked, theme }: Props) =>
    checked &&
    `&:after {
      align-self: self-start;
      position: absolute;
      top: ${theme.sizes['0.5']};
      content: '';
      width: ${theme.sizes['2.5']};
      height: ${theme.sizes['2.5']};
      border-radius: 50%;
      background: ${theme.colors.red[700]};
    }`}

  &.pulsing-button {
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;

    &:hover {
      animation: none;
    }
  }

  @-webkit-keyframes pulsing {
    to {
      box-shadow: ${({ theme }: Props) =>
        `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[4]} ${theme.colors.yellow[500]}${theme.opacity[0]}`};
    }
  }

  @-moz-keyframes pulsing {
    to {
      box-shadow: ${({ theme }: Props) =>
        `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[4]} ${theme.colors.yellow[500]}${theme.opacity[0]}`};
    }
  }

  @-ms-keyframes pulsing {
    to {
      box-shadow: ${({ theme }: Props) =>
        `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[4]} ${theme.colors.yellow[500]}${theme.opacity[0]}`};
    }
  }

  @keyframes pulsing {
    to {
      box-shadow: ${({ theme }: Props) =>
        `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[4]} ${theme.colors.yellow[500]}${theme.opacity[0]}`};
    }
  }
`;
