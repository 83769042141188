import React from 'react';
import { MonthlyReportResponse } from 'services/api';
import moment from 'moment/moment';
import {
  Column,
  FirstColumn,
  MoreInformationTextBlock,
  NormTeamTable,
  SecondColumn,
  SectionDescriptionBlock,
  SupportTextBlock,
  Wrapper,
  YourContactsTable,
} from './styled';
import { getSupportEmail, getSupportPhone } from '../../../../../../../themes/helpers';
import { Themes } from '../../../../../../../themes/types';
import { IDate } from '../../../../../../../services/interfaces';

type Props = { themeName: Themes; data: MonthlyReportResponse; reportDate: IDate };

export function PdfPage15({ themeName, data: { contactDetails }, reportDate }: Props) {
  const focalAnalyst = contactDetails?.focalAnalyst;
  const accountManager = contactDetails?.accountManager;

  const startDate = moment(reportDate.startDate);
  const endDate = moment(reportDate.endDate + 1);

  return (
    <Wrapper>
      <SectionDescriptionBlock>
        This report covers the areas of your organisation that norm has visibility of. The data used
        to compile this report was collected between the {startDate.format('Do of MMMM')} and the{' '}
        {endDate.format('Do of MMMM YYYY')}.
      </SectionDescriptionBlock>

      <SupportTextBlock>
        The norm team assigned to your organisation is listed below, and each has their own
        understanding of your organisation <br />
        relative to their business role:
      </SupportTextBlock>

      <MoreInformationTextBlock>
        More information about your norm contacts as well as their roles and responsibilities can be
        found in your customer handbook. Should you need to escalate and query, the norm <br />{' '}
        escalation schedules are available in the same handbook, or on request from any of the
        contacts listed above.
      </MoreInformationTextBlock>

      <YourContactsTable>
        <tbody>
          <tr>
            <FirstColumn>Tier 1 Support</FirstColumn>
            <SecondColumn>{getSupportEmail(themeName)}</SecondColumn>
            <Column>{getSupportPhone(themeName)}</Column>
          </tr>
        </tbody>
      </YourContactsTable>

      <NormTeamTable>
        <tbody>
          <tr>
            <FirstColumn>Customer Experience</FirstColumn>
            <SecondColumn>customer.experience@normcyber.com</SecondColumn>
            <Column>+ 44 (0) 20 3855 6215</Column>
          </tr>
          <tr>
            <FirstColumn>Your Focal Analyst</FirstColumn>
            <SecondColumn>{focalAnalyst?.email}</SecondColumn>
            <Column>{focalAnalyst?.phone}</Column>
          </tr>
          <tr>
            <FirstColumn>Your Account Manager</FirstColumn>
            <SecondColumn>{accountManager?.email}</SecondColumn>
            <Column>{accountManager?.phone}</Column>
          </tr>
        </tbody>
      </NormTeamTable>
    </Wrapper>
  );
}
