import React, { PropsWithChildren } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAuth } from 'store';
import { useScreenSizes } from 'services/hooks';
import { Routes } from 'services/entities';
import { Layout } from '../layouts/Layout/Layout';
import { AuthRouter } from '../AuthRouter/AuthRouter';
import { MainHeader } from '../layouts/Inner/components';
import { SidebarFactory } from '../Sidebar/SidebarFactory';

export function ProtectedRouteGroup({
  children,
  ...props
}: PropsWithChildren<RouteProps>): React.ReactElement {
  const [isDesktop] = useScreenSizes();
  const { user, isAuthorized } = useAuth();

  if (!isAuthorized) {
    return <Redirect to={Routes.ROOT} />;
  }

  return (
    <Route {...props}>
      <Layout
        header={isDesktop ? <MainHeader userName={user?.name || ''} /> : undefined}
        sidebar={<SidebarFactory />}
      >
        <AuthRouter>{children}</AuthRouter>
      </Layout>
    </Route>
  );
}
