import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { Typography } from 'antd';
import { useScreenSizes } from 'services/hooks/useScreenSizes';
import { authApi, useApp, useAuth } from 'store';
import { ValidateTwoFARequest } from 'services/api';
import { Box } from 'shared';
import { Routes, TwoFASteps } from 'services/entities';
import { useTheme } from 'styled-components';
import { FirstStep, SecondStep, TwoFAValidation } from './steps';
import { checkLoginTokenExpired } from '../helpers';

export const TwoFASetup = () => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const [isDesktop] = useScreenSizes();
  const dispatch = useDispatch();
  const { token, twofa, isPending, requestError, isAuthorized } = useAuth();
  const textColor = isDesktop ? theme.colorSet.textColor : theme.colors.white;

  const submitCode = (data: ValidateTwoFARequest) => {
    if (!token || checkLoginTokenExpired(token, dispatch)) {
      return;
    }

    dispatch(authApi.twofaValidate(data));
  };

  useEffect(() => {
    if (!token || checkLoginTokenExpired(token, dispatch)) {
      return;
    }

    dispatch(authApi.twofaData({ token }));
  }, [dispatch, token]);

  if (twofa.step !== TwoFASteps.SETUP) {
    return <Redirect to={Routes.ROOT} />;
  }

  if (isAuthorized) {
    return <Redirect to={Routes.DASHBOARD} />;
  }

  return twofa.isValid ? (
    <Redirect to={Routes.DASHBOARD} />
  ) : (
    <Box textAlign='center'>
      <Typography.Paragraph>
        <Box color={textColor} mb={theme.spacing[8]}>
          Once configured, we&apos;ll ask for both your password and a verification code on each
          login.
        </Box>
      </Typography.Paragraph>

      {twofa.data.base32 && (
        <>
          <FirstStep isMobile={isMobile} />
          <SecondStep data={twofa.data} />
          <TwoFAValidation
            isPending={isPending}
            onSubmit={submitCode}
            isOnSetup
            isRequestError={!!requestError.message}
          />
        </>
      )}
    </Box>
  );
};
