import { persistStorageKey } from 'store';
import { timeFactor } from 'app-constants';
import jwt_decode from 'jwt-decode';

export function getAuthTokenFromLocalStorage(): string | null {
  try {
    const result = JSON.parse(localStorage.getItem(`persist:${persistStorageKey}`) as string);

    if (typeof result?.auth === 'string') {
      return JSON.parse(result.auth)?.authToken || null;
    }

    return null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('getAuthTokenFromLocalStorage error: ', error);

    return null;
  }
}

export function compareTokens(token: string, compare: string) {
  const tokenDecoded: { exp: number } = jwt_decode(token);
  const compareDecoded: { exp: number } = jwt_decode(compare);

  return tokenDecoded.exp > compareDecoded.exp;
}

export const isTokenExpired = (token: string) => {
  const { exp }: { exp: number } = jwt_decode(token);

  return Date.now() > exp * timeFactor;
};
