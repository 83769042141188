import { isEmpty } from 'lodash';
import React from 'react';
import { emptyMessage } from './Table/EmptyTablePreview/constants';
import { ChartWrapper } from './styled';

interface IProps<Values, EmptyValues> {
  values?: Values[] | Values;
  emptyValues?: EmptyValues;
  emptyText?: string;
  isShowEmptyChart?: boolean;
  children: JSX.Element;
  onPress?: () => void;
}

export const RenderChartWrapper = <Values, EmptyValues>({
  values,
  emptyValues,
  emptyText = '',
  isShowEmptyChart,
  children,
  onPress,
}: IProps<Values, EmptyValues>) => {
  if (((Array.isArray(values) && !values?.length) || isEmpty(values)) && !isShowEmptyChart) {
    return emptyMessage(emptyText);
  }

  const chartValues =
    (Array.isArray(values) && values?.length) || !isEmpty(values) ? values : emptyValues;

  const isCursorPointer = !!onPress;

  return (
    <ChartWrapper onClick={() => onPress?.()} isCursorPointer={isCursorPointer}>
      {React.cloneElement(children, { values: chartValues })}
    </ChartWrapper>
  );
};
