import { Filter, GridRequestParams } from 'services/api';

export const getEmptyStateText = (params: GridRequestParams) => {
  const isSecurityIncidentFilter = params?.filter?.fields?.some(
    (field: Filter) => field.name === 'type' && field.value === 'Security incident',
  );

  const isStatusActive = params?.filter?.fields?.some(
    (field: Filter) => field.name === 'status' && field.value === 'Active',
  );

  const isSearchFilter = params?.filter?.search;
  const isApplyedTwoFilters = params?.filter?.fields?.length === 2;

  if (isSecurityIncidentFilter && isStatusActive && isApplyedTwoFilters && !isSearchFilter) {
    return {
      title: '',
      textContent: 'No current open security incidents….and that’s a good thing',
    };
  }

  return null;
};
