import React from 'react';
import { useDispatch } from 'react-redux';
import { Filter, InputType } from 'services/api';
import { vulnerability, useExcludedVulnerabilitiesStatusFilter, useApp } from '../../../store';
import { icons } from '../../../styles/theme';
import { Icon, TableFiltersSelect } from '../../../shared';
import { FilterLabel, FilterWrapper } from './styled/styled';
import { excludedVulnerabilitiesFilterOptions } from '../constants';

export const FilterVulnerabilitiesByStatus = () => {
  const dispatch = useDispatch();
  const { isMobile } = useApp();

  const selectedFilterOptions = (useExcludedVulnerabilitiesStatusFilter() as string[]) || [];
  const onReset = () => {
    dispatch(
      vulnerability.actions.setExcludedVulnerabilitiesStatusFilter(
        excludedVulnerabilitiesFilterOptions.map(({ value }) => value),
      ),
    );
  };

  const onClear = () => {
    dispatch(vulnerability.actions.setExcludedVulnerabilitiesStatusFilter([]));
  };

  return (
    <FilterWrapper ml={isMobile ? '0' : '30px'} className='exclude-vulns-select'>
      <FilterLabel>Exclude:</FilterLabel>
      <TableFiltersSelect
        label=''
        inputType={InputType.SELECTOR}
        filterType={Filter.type.MULTIPLE}
        field='exclude'
        placeholder='None'
        value={selectedFilterOptions}
        suffixIcon={
          selectedFilterOptions.length < excludedVulnerabilitiesFilterOptions.length ? (
            <Icon margin='1px' component={icons.replay} onClick={onReset} />
          ) : (
            <Icon margin='4px' component={icons.select_clear} onClick={onClear} />
          )
        }
        options={excludedVulnerabilitiesFilterOptions}
        onSelectChange={(_, value) =>
          dispatch(vulnerability.actions.setExcludedVulnerabilitiesStatusFilter(value as string[]))
        }
        dropdownAlign={{
          overflow: { adjustY: true },
        }}
        maxTagCount={2}
      />
    </FilterWrapper>
  );
};
