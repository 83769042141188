import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  CustomerId,
  GridRequestParams,
  OrderBy,
  Pagination,
  SeverityLevel,
  TagId,
  Timestamp,
  UpdateVulnerabilitySuppressionRequest,
  VulnerabilityDetailsRequestBody,
  VulnerabilityManagementService,
  VulnerabilityStatus,
  VulnerabilityTrendPointTypeSchema,
} from 'services/api';
import { Count } from 'services/api/models/Count';

const vulnerabilityDetails = createAsyncThunk(
  'vulnerability-details/details/get',
  async (requestBody: VulnerabilityDetailsRequestBody) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilityDetails(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getVulnerabilitiesCategories = createAsyncThunk(
  'vulnerabilities/categories/get',
  async (customerId: CustomerId) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilitiesCategories({ customerId });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const assetVulnerabilityDetails = createAsyncThunk(
  'asset-vulnerability-details/details/get',
  async (requestBody: VulnerabilityDetailsRequestBody) => {
    try {
      return await VulnerabilityManagementService.getAssetVulnerabilityDetails(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const exportVulnerability = createAsyncThunk(
  'vulnerabilities/export',
  async (requestBody: GridRequestParams) => {
    try {
      return await VulnerabilityManagementService.exportVulnerabilities(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const vulnerabilities = createAsyncThunk(
  'vulnerabilities/get',
  async (requestBody: GridRequestParams) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilities(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getActiveVulnerabilities = createAsyncThunk(
  'active-vulnerabilities/get',
  async (requestBody: GridRequestParams) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilities(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);
const getPotentialVulnerabilities = createAsyncThunk(
  'potential-vulnerabilities/get',
  async (requestBody: GridRequestParams) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilities(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getAllVulnerabilitiesTrend = createAsyncThunk(
  'all-vulnerabilities-trend/get',
  async (requestBody: {
    customerId: CustomerId;
    startDate: Timestamp;
    endDate: Timestamp;
    pointType: VulnerabilityTrendPointTypeSchema;
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    tagIds?: string[];
    includeSuppressedVulnerabilities?: boolean;
  }) => {
    try {
      return await VulnerabilityManagementService.getAllVulnerabilitiesTrend(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getVulnerabilitiesTrendsByCriticalities = createAsyncThunk(
  'vulnerabilities-trends-by-criticalities/get',
  async (requestBody: {
    customerId: CustomerId;
    startDate: Timestamp;
    endDate: Timestamp;
    pointType: VulnerabilityTrendPointTypeSchema;
    criticalities: Array<SeverityLevel>;
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    tagIds?: string[];
    includeSuppressedVulnerabilities?: boolean;
  }) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilitiesTrendsByCriticalities(
        requestBody,
      );
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getCurrentVulnerabilities = createAsyncThunk(
  'current-vulnerabilities/get',
  async (requestBody: {
    customerId: CustomerId;
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    tagIds?: string[];
    includeSuppressedVulnerabilities?: boolean;
  }) => {
    try {
      return await VulnerabilityManagementService.getCurrentVulnerabilities(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getLatestVulnerabilitiesWithAffectedHosts = createAsyncThunk(
  'latest-vulnerabilities-with-affected-hosts/get',
  async (requestBody: {
    customerId: CustomerId;
    count: Count;
    criticalities: Array<SeverityLevel>;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: TagId[];
    includeSuppressedVulnerabilities?: boolean;
  }) => {
    try {
      return await VulnerabilityManagementService.getLatestVulnerabilitiesWithAffectedHosts(
        requestBody,
      );
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getVulnerabilitiesWithMostAffectedHosts = createAsyncThunk(
  'vulnerabilities-with-most-affected-hosts/get',
  async (requestBody: {
    customerId: CustomerId;
    count: Count;
    criticalities: Array<SeverityLevel>;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: TagId[];
    includeSuppressedVulnerabilities?: boolean;
  }) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilitiesWithMostAffectedHosts(
        requestBody,
      );
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getMostVulnerableHosts = createAsyncThunk(
  'most-vulnerable-hosts/get',
  async (requestBody: {
    customerId: CustomerId;
    orderBy: OrderBy;
    pagination: Pagination;
    vulnerabilityStatus: Array<VulnerabilityStatus>;
    tagIds?: Array<TagId>;
    includeSuppressedVulnerabilities?: boolean;
  }) => {
    try {
      return await VulnerabilityManagementService.getMostVulnerableHosts(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getVulnerabilitiesCountByStatus = createAsyncThunk(
  'get-vulnerabilities-count-by-status/get',
  async (requestBody: { customerId: CustomerId; tagIds?: string[] }) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilitiesCountByStatus(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getPatchVsConfiguration = createAsyncThunk(
  'patch-vs-configuration/get',
  async (requestBody: {
    customerId: CustomerId;
    vulnerabilityStatus: VulnerabilityStatus[];
    tagIds?: TagId[];
    includeSuppressedVulnerabilities?: boolean;
  }) => {
    try {
      return await VulnerabilityManagementService.getPatchVsConfiguration(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const setVulnerabilitySuppression = createAsyncThunk(
  'vulnerability-suppression/post',
  async (requestBody: {
    customerId: CustomerId;
    vulnerabilityId: string;
    requestBody: UpdateVulnerabilitySuppressionRequest;
  }) => {
    try {
      return await VulnerabilityManagementService.updateVulnerabilitySuppression(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);
const getVulnerabilitySuppressionGrid = createAsyncThunk(
  'vulnerability-suppression-grid/get',
  async (requestBody: {
    customerId: CustomerId;
    vulnerabilityId: string;
    pagination: Pagination;
  }) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilitySuppressionGrid(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);
const getSuppressedVulnerabilities = createAsyncThunk(
  'suppressed-vulnerabilities/get',
  async (requestBody: GridRequestParams) => {
    try {
      return await VulnerabilityManagementService.getVulnerabilities(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);
const getSuppressVulnerabilitiesUsers = createAsyncThunk(
  'suppress-vulnerabilities-users/get',
  async (customerId: CustomerId) => {
    try {
      return await VulnerabilityManagementService.getSuppressVulnerabilitiesUsers({ customerId });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const vulnerabilitiesApi = {
  vulnerabilityDetails,
  assetVulnerabilityDetails,
  vulnerabilities,
  getAllVulnerabilitiesTrend,
  getVulnerabilitiesTrendsByCriticalities,
  getCurrentVulnerabilities,
  getLatestVulnerabilitiesWithAffectedHosts,
  getVulnerabilitiesWithMostAffectedHosts,
  getMostVulnerableHosts,
  exportVulnerability,
  getPatchVsConfiguration,
  getVulnerabilitiesCategories,
  getVulnerabilitiesCountByStatus,
  setVulnerabilitySuppression,
  getVulnerabilitySuppressionGrid,
  getSuppressedVulnerabilities,
  getActiveVulnerabilities,
  getPotentialVulnerabilities,
  getSuppressVulnerabilitiesUsers,
};
