import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import ReactECharts from 'echarts-for-react';

type Props = {
  theme: Theme;
};

export const ReactEChartsStyled = styled(ReactECharts)`
  border-radius: ${({ theme }: Props) => theme.radius.sm};
  height: ${({ theme }: Props) => theme.sizes[5]} !important;
  overflow: hidden;
`;
