import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BoostScoreItemSchema,
  CertificateSchema,
  CertificateStatusSchemaWithDownloadName,
  CustomerProductsListResponse,
  CustomerProductsListSchema,
  DownloadSchema,
  GetAllBoostScoreItemsResponse,
  GetAllCertificatesResponse,
} from 'services/api';
import { processApi } from './thunks';
import { getCertificatesForTable, getDownloadName } from './helper';

type ProcessState = {
  certificates?: CertificateStatusSchemaWithDownloadName[];
  expiredCertificates?: DownloadSchema[];
  boostScores?: BoostScoreItemSchema[];
  customerProductsList?: CustomerProductsListSchema[];
};

const initialState: ProcessState = {
  certificates: [] as CertificateStatusSchemaWithDownloadName[],
  expiredCertificates: [] as DownloadSchema[],
  boostScores: [] as BoostScoreItemSchema[],
  customerProductsList: [],
};

export const process = createSlice({
  name: 'process',
  initialState,
  reducers: {},
  extraReducers: {
    [processApi.getAllCertificates.fulfilled.toString()]: (
      state: ProcessState,
      action: { payload: GetAllCertificatesResponse },
    ) => {
      state.certificates = action.payload.certificates?.map((certificate: CertificateSchema) => ({
        ...certificate,
        name: getDownloadName(certificate.title, action.payload.downloads),
      }));

      state.expiredCertificates = getCertificatesForTable(
        action.payload.downloads,
        action.payload.certificates,
      );
    },
    [processApi.getAllCertificates.rejected.toString()]: (state: ProcessState) => {
      state.certificates = [];
      state.expiredCertificates = [];
    },
    [processApi.getBoostScores.fulfilled.toString()]: (
      state: ProcessState,
      action: PayloadAction<GetAllBoostScoreItemsResponse>,
    ) => {
      state.boostScores = action.payload.boostScoreItems;
    },
    [processApi.getBoostScores.rejected.toString()]: (state: ProcessState) => {
      state.boostScores = [];
    },
    [processApi.getProducts.fulfilled.toString()]: (
      state: ProcessState,
      action: PayloadAction<CustomerProductsListResponse>,
    ) => {
      state.customerProductsList = action.payload.customerProductsList;
    },
    [processApi.getProducts.rejected.toString()]: (state: ProcessState) => {
      state.customerProductsList = [];
    },
  },
});
