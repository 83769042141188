import { Switch as AntdSwitch } from 'antd';
import styled from 'styled-components';
import { getCssVar } from '../../styles/theme';

export const Switch = styled(AntdSwitch)`
  bottom: -2px;
  margin-right: 8px;
  min-height: 18px;
  min-width: 37px !important;
  width: 37px;

  &:not(.ant-switch-checked) {
    background-color: ${getCssVar('switch.color')};
  }

  .ant-click-animating-node {
    display: none;
  }

  &:hover,
  &:focus,
  &.hover,
  &:focus:hover,
  &.hover:hover {
    box-shadow: 0 0 0 2px ${getCssVar('switch.color')}30;
  }

  &:active,
  &.active {
    &,
    &:hover {
      box-shadow: 0 0 0 4px ${getCssVar('switch.color')}30;
    }
  }

  .anticon-loading {
    margin-top: 1px;
  }

  .ant-switch-loading,
  .ant-switch-disabled {
    opacity: 0.5;
  }

  &.ant-switch-checked {
    .ant-switch-handle {
      left: calc(100% - 14px - 2px);
    }
  }

  .ant-switch-handle {
    height: 14px;
    width: 14px;
  }
`;
