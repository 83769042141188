import React, { useEffect, useState } from 'react';
import { MonthlyReportResponse } from 'services/api';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { IDate } from 'services/interfaces';

import { Annotations } from 'shared/charts/GradientPie/types';
import { PdfBox } from './styled';
import { PageTitle } from './pages/PageTitle';
import { PdfPage1 } from './pages/PdfPage1';
import { PdfPage2 } from './pages/PdfPage2';
import { PdfPage3 } from './pages/PdfPage3';
import { PdfPage4 } from './pages/PdfPage4';
import { PdfPage5 } from './pages/PdfPage5';
import { CHARTS_COUNT } from './constants';

export const Report = ({
  data,
  reportDate,
  processPermission,
  onChartsReady,
  theme,
  companyName,
  scoreAnnotations,
}: {
  data: MonthlyReportResponse;
  processPermission: string;
  reportDate: IDate;
  companyName: string;
  theme: Theme;
  onChartsReady: () => void;
  scoreAnnotations?: Annotations;
}) => {
  const [loadedChartsCount, setLoadedChartsCount] = useState(0);

  useEffect(() => {
    if (loadedChartsCount === CHARTS_COUNT) {
      onChartsReady();
      setLoadedChartsCount(0);
    }
  }, [loadedChartsCount, onChartsReady]);

  return (
    <ThemeProvider theme={theme}>
      <PdfBox>
        <PageTitle reportDate={reportDate} companyName={companyName} />
        <PdfPage1
          data={data}
          reportDate={reportDate}
          scoreAnnotations={scoreAnnotations}
          onChartLoaded={() => setLoadedChartsCount((prev) => prev + 1)}
        />
        <PdfPage2
          data={data}
          processPermission={processPermission}
          onChartLoaded={() => setLoadedChartsCount((prev) => prev + 1)}
          reportDate={reportDate}
        />
        <PdfPage3
          data={data}
          onChartLoaded={() => setLoadedChartsCount((prev) => prev + 1)}
          reportDate={reportDate}
        />
        <PdfPage4
          data={data}
          reportDate={reportDate}
          onChartLoaded={() => setLoadedChartsCount((prev) => prev + 1)}
        />
        <PdfPage5
          data={data}
          reportDate={reportDate}
          onChartLoaded={() => setLoadedChartsCount((prev) => prev + 1)}
        />
      </PdfBox>
    </ThemeProvider>
  );
};
