import { Button, Icon } from 'shared';
import { icons } from 'styles/theme';
import { useUserPermissions } from 'pages/ServicesIntegrations/hooks';
import { StatusComponent } from 'shared/layouts/Inner/components';
import { useHistory, useParams } from 'react-router';
import { Routes } from 'services/entities';
import { useCallback } from 'react';
import { GuidanceType } from 'pages/ServicesIntegrations/types';
import { GUIDANCE_TYPE } from 'pages/ServicesIntegrations/constants';
import { HeaderActionsWrapper, ButtonWrapper } from './styled';
import { LastUpdated } from '../LastUpdated';

type TabContentHeaderActionsProps = {
  hasDraft?: boolean;
  updatedAt?: string;
  updatedBy?: string;
  guidanceType: GuidanceType;
};

export const TabContentHeaderActions = ({
  hasDraft,
  updatedAt,
  updatedBy,
  guidanceType,
}: TabContentHeaderActionsProps) => {
  const { canSetupGuide, isNormUser } = useUserPermissions();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const route =
    guidanceType === GUIDANCE_TYPE.OVERVIEW
      ? Routes.SERVICE_INTEGRATION_OVERVIEW_EDIT
      : Routes.SERVICE_INTEGRATION_INSTRUCTIONS_EDIT;

  const editHandler = useCallback(() => {
    history.push(route.replace(':id', id), {
      backTo: {
        route: `${Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', id)}`,
        search: history.location.search,
      },
    });
  }, [history, id, route]);

  return (
    <HeaderActionsWrapper>
      <ButtonWrapper>
        {hasDraft && canSetupGuide && (
          <StatusComponent level='Drafts' status='Unpublished changes' style={{ margin: '0px' }} />
        )}

        {canSetupGuide && (
          <Button type='link' icon={<Icon component={icons.edit} />} onClick={editHandler}>
            Edit
          </Button>
        )}
      </ButtonWrapper>

      <LastUpdated details={{ updatedAt, updatedBy: isNormUser ? updatedBy : '' }} />
    </HeaderActionsWrapper>
  );
};
