import * as Yup from 'yup';

const maxSummary = 200;
const maxDescription = 5000;

const commonValidationFields = {
  summary: Yup.string()
    .trim()
    .required('Summary is a required field')
    .max(maxSummary, 'Summary must be at most 200 characters'),
  description: Yup.string()
    .trim()
    .required('Description is a required field')
    .max(maxDescription, 'Description must be at most 5000 characters'),
};

export const validationSchema = {
  service: Yup.object({
    ...commonValidationFields,
    product: Yup.string().required(),
  }),
  incident: Yup.object({
    ...commonValidationFields,
    priority: Yup.string().required(),
    impact: Yup.string().required(),
  }),
};
