import styled from 'styled-components';
import { Theme } from 'styles/theme/types';

type Props = {
  theme: Theme;
};

export const InfoSectionHeader = styled.span<Props>`
  font-size: ${({ theme }: Props) => theme.sizes[4]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
`;

export const InfoSectionContent = styled.div<Props>`
  font-size: 15px;
  margin: ${({ theme }: Props) => `${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[0]}`};

  width: ${({ theme }: Props) => theme.sizes.full};
  word-wrap: break-word;
`;

export const InfoSection = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }: Props) => `${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[6]}`};
  width: 70%;
  &:last-child {
    margin-bottom: ${({ theme }: Props) => theme.sizes['0.5']};
  }
`;

export const List = styled.ul`
  padding-left: ${({ theme }: Props) => theme.spacing[6]};
`;
