import styled from 'styled-components';
import { Input as AntdInput } from 'antd';
import { breakpoints, getCssVar } from 'styles/theme';
import { Box, Button, Input as InputShared } from 'shared';
import { Theme } from 'styles/theme/types';
import { ThemeProps } from '../../../services/interfaces';

type FormProps = {
  validateStatus: '' | 'success' | 'warning' | 'error' | 'validating';
};

export const Input = styled(AntdInput)`
  input {
    font-size: ${({ theme }) => theme.sizes[6]};
    font-weight: ${({ theme }) => theme.fontWeights.black};
    height: ${({ theme }) => theme.sizes[17]};
    letter-spacing: ${({ theme }) => theme.spacing['2.5']};
    text-align: center;
  }
`;

type ButtonProps = {
  $isHidden?: boolean;
};

export const StyledButton = styled(Button)<ButtonProps>`
  display: ${({ $isHidden }) => $isHidden && 'none'};
  height: 44px;
  @media (max-width: ${breakpoints.lg}) {
    height: 48px;
  }
`;

export const InputEmail = styled(InputShared)<FormProps>`
  .ant-input-group {
    .ant-input-group-addon,
    input {
      border-color: ${({ validateStatus }) =>
        validateStatus === 'error' && `${getCssVar('errorColor')} !important`};
    }

    input.ant-input {
      border-color: ${({ validateStatus }) =>
        validateStatus === 'error' && `${getCssVar('errorColor')} !important`};

      &:hover,
      &:focus {
        border-color: ${({ validateStatus }) =>
          validateStatus === 'error' && `${getCssVar('errorColor')} !important`};
        box-shadow: none !important;
      }
    }
  }

  .ant-input-wrapper:focus-within {
    .ant-input-group-addon {
      border-color: ${({ validateStatus }) =>
        validateStatus === 'error' && `${getCssVar('errorColor')} !important`};
    }
  }

  .ant-input-wrapper:hover {
    .ant-input-group-addon {
      border-color: ${({ validateStatus }) =>
        validateStatus === 'error' && `${getCssVar('errorColor')} !important`};
    }
  }
`;

export const InputPassword = styled(InputShared)<FormProps>`
  .ant-input-group {
    .ant-input-group-addon,
    .ant-input-affix-wrapper {
      border-color: ${({ validateStatus }) =>
        validateStatus === 'error' && `${getCssVar('errorColor')} !important`};
    }
  }

  .ant-input-wrapper:focus-within {
    .ant-input-group-addon,
    .ant-input-affix-wrapper {
      border-color: ${({ validateStatus }) =>
        validateStatus === 'error' && `${getCssVar('errorColor')} !important`};
      box-shadow: none !important;
    }
  }

  .ant-input-wrapper:hover {
    .ant-input-group-addon,
    .ant-input-affix-wrapper {
      border-color: ${({ validateStatus }) =>
        validateStatus === 'error' && `${getCssVar('errorColor')} !important`};
    }
  }

  input {
    font-size: ${({ theme }) => theme.sizes['3.6']};
  }

  .ant-input-affix-wrapper {
    max-height: 40px;

    &:focus {
      border-color: ${({ validateStatus }) =>
        validateStatus === 'error' && `${getCssVar('errorColor')} !important`};
    }

    @media (max-width: ${breakpoints.lg}) {
      max-height: 48px;
    }
  }
`;

type InputTwoFAProps = {
  $isRequestError: boolean;
  theme: Theme;
};

export const InputTwoFA = styled(Input)<InputTwoFAProps>`
  border-color: ${({ $isRequestError }: InputTwoFAProps) =>
    $isRequestError && `${getCssVar('errorColor')} !important`};

  input {
    color: ${getCssVar('textColor')};
    height: 100%;

    &:disabled {
      background-color: inherit !important;
      border: none !important;
    }
  }

  height: 68px;

  &:hover {
    border-color: ${({ $isRequestError }: InputTwoFAProps) =>
      $isRequestError && `${getCssVar('errorColor')} !important`};
  }
`;

export const TwoFAText = styled.span<ThemeProps>`
  font-weight: ${({ theme }) => theme.colorSet.twoFAText};
`;

export const TwoFAWrapper = styled(Box)<ThemeProps>`
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonCopy = styled(Button)<ThemeProps>`
  font-size: ${({ theme }) => theme.spacing[4]};
  font-weight: ${({ theme }) => theme.colorSet.filterLabelWeight};
`;
