import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { breakpoints, getCssVar } from 'styles/theme';
import { Theme } from 'styles/theme/types';
import {
  ItemPercent,
  ItemValue,
  LegendItemTitle,
  LegendItemValue,
  LegendWrapperValue,
} from 'shared/charts/Legend/styled';

export const ChartsContainer = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
  justify-content: space-between;
  margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
  width: 100%;
`;

export const PeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: ${({ theme }: { theme: Theme }) => `-${theme.spacing[4]}`};
  }

  > .anticon {
    margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
  }
`;

export const UsersChartConteiner = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.xxl}) {
    flex-direction: column;
    align-items: center;
  }
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};

  .chart-container {
    width: 65%;

    @media (max-width: ${breakpoints.xl}) {
      width: 80%;
    }
  }

  .echarts-for-react {
    width: 100%;
    @media (max-width: ${breakpoints.xxl}) {
      width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
    }
  }

  @media (min-width: ${breakpoints.xxl}) {
    ${LegendItemValue} {
      ${LegendItemTitle} {
        width: 80px;
      }

      ${LegendWrapperValue} {
        ${ItemValue}, ${ItemPercent} {
          margin-right: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
        }
      }
    }
  }

  ${LegendItemValue} {
    ${LegendWrapperValue} {
      ${ItemValue}, ${ItemPercent} {
        margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[0]};
        margin-right: ${({ theme }: { theme: Theme }) => theme.spacing['1.2']};
      }
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    ${LegendItemValue} {
      ${LegendWrapperValue} {
        ${ItemValue}, ${ItemPercent} {
          margin-right: ${({ theme }: { theme: Theme }) => theme.spacing['1.2']};
        }
      }
    }
  }
`;

export const TechChartContainer = styled.div`
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  @media (max-width: ${breakpoints.md}) {
    overflow-x: scroll;
  }

  .echarts-for-react {
    min-width: 600px;
  }

  ::-webkit-scrollbar {
    height: 13px;
    bottom: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
  }

  ::-webkit-scrollbar-thumb {
    background: ${getCssVar('scrollBar')};
    border-radius: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
  }
`;

export const ChartContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 55%;
  @media (max-width: ${breakpoints.md}) {
    width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
    padding-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
    border-bottom: 1px solid ${getCssVar('card.footerBorderTopColor')};
    &:last-child {
      border: none;
      margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[0]};
    }

    margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[6]};
  }

  > span {
    font-size: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
  }

  &:first-child {
    @media (max-width: ${breakpoints.md}) {
      width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
      padding-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
      border-bottom: 1px solid ${getCssVar('card.footerBorderTopColor')};
      margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[6]};
    }
  }
`;

export const EmailsChartContainer = styled.div`
  display: flex;
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  @media (max-width: ${breakpoints.xxl}) {
    flex-direction: column;
    align-items: center;
  }

  .chart-container {
    width: 80%;
  }

  .echarts-for-react {
    width: 100%;
    @media (max-width: ${breakpoints.xxl}) {
      width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
    }
  }

  @media (min-width: ${breakpoints.xxl}) {
    ${LegendItemValue} {
      ${LegendItemTitle} {
        width: 167px;
      }
    }

    ${LegendWrapperValue} {
      text-align: end;

      ${ItemValue} {
        margin-right: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
        width: 25px;
      }

      ${ItemPercent} {
        width: 45px;
      }
    }
  }

  ${LegendItemValue} {
    ${LegendWrapperValue} {
      ${ItemValue}, ${ItemPercent} {
        margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[0]};
        margin-right: ${({ theme }: { theme: Theme }) => theme.spacing['1.2']};
      }
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    .legend-item:last-child {
      ${LegendItemValue} {
        ${LegendItemTitle} {
          width: 170px;
        }
      }
    }
  }
`;

type StressContainerProps = {
  order: number;
  $collapsed?: boolean;
};

export const StressContainer = styled.div<StressContainerProps>`
  align-items: center;
  display: flex;
  @media (max-width: ${breakpoints.md}) {
    align-items: flex-start;
    margin: ${({ theme }: { theme: Theme }) => `${theme.spacing[1]} 0`};
  }
  order: ${({ order }: StressContainerProps) => order};

  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};

  .empty-chart {
    max-width: 336px;

    path {
      fill: ${getCssVar('charts.emptyChart')};
    }
  }

  .arrow {
    margin-left: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
    @media (max-width: ${breakpoints.xl}) {
      margin-left: ${({ theme, $collapsed }) => ($collapsed ? theme.spacing[4] : theme.spacing[0])};
    }
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 340px;
    width: ${({ theme }: { theme: Theme }) => theme.sizes.full};

    .echarts-for-react {
      width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
      @media (max-width: ${breakpoints.md}) {
        display: none;
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
    }

    > div:first-child {
      @media (max-width: ${breakpoints.md}) {
        display: flex;
        flex-direction: column;
      }

      > span:first-child {
        align-items: center;
        display: flex;
        font-size: 22px;
        font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.black};
        margin-right: ${({ theme }: { theme: Theme }) => theme.spacing[2]};

        svg {
          @media (max-width: ${breakpoints.md}) {
            margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing['2.5']};
          }
          filter: ${({
            theme,
          }: {
            theme: Theme;
          }) => `drop-shadow(${theme.spacing.px} ${theme.spacing.px} ${theme.spacing[0]} ${theme.colors.black})
          drop-shadow(${theme.spacing[1]} ${theme.spacing[1]} ${theme.spacing[2]} ${theme.colors.blackAlpha[200]})`};
          margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
          margin-right: ${({ theme }: { theme: Theme }) => theme.spacing['0.5']};
        }
      }

      > span:last-child {
        align-items: center;
        display: flex;
        font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
        font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.semibold};
        padding-right: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
      }
    }
  }

  span:last-child.anticon {
    align-items: center;
    align-self: center;
    display: flex;
    height: auto;
    width: auto;
  }
`;

interface BoostScoreItemContainerProps {
  color: string;
  isExpired: boolean;
  theme: Theme;
}

export const BoostScoreItemContainer = styled.div<BoostScoreItemContainerProps>`
  align-items: center;
  display: flex;
  margin: 5px 0;
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  @media (max-width: ${breakpoints.md}) {
    padding: 15px 0;
    margin: 0;
    border-bottom: 1px solid ${getCssVar('card.footerBorderTopColor')};
  }

  &:last-child {
    border-bottom: none;
  }

  > span:first-child {
    background-color: ${({ color }: BoostScoreItemContainerProps) => color};
  }

  > span {
    border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
    height: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
    margin: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
    width: ${({ theme }: { theme: Theme }) => theme.spacing[2]};
  }

  > div {
    display: flex;
    flex-direction: column;

    .ant-btn {
      align-items: center;
      display: flex;
      font-size: 15px;
      height: 25px;
      justify-content: flex-start;
    }

    > span {
      font-size: 15px;
      font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.semibold};
    }

    > div {
      display: flex;

      .circle {
        background: ${({ theme, isExpired }: BoostScoreItemContainerProps) =>
          isExpired ? theme.colors.red[400] : theme.colors.gray[500]};
        border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
        height: 6px;
        margin: auto 6px;
        opacity: 0.4;
        width: 6px;
      }

      span:first-child {
        @media (max-width: ${breakpoints.md}) {
          font-size: 13px;
        }
      }

      span:last-child {
        color: ${({ isExpired }: BoostScoreItemContainerProps) =>
          isExpired ? getCssVar('colorCritical') : 'inherit'};
        @media (max-width: ${breakpoints.md}) {
          font-size: 13px;
        }
      }
    }
  }
`;

export const DataProtectionItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }: { theme: Theme }) => theme.spacing[2]} 0;
  width: 95%;
  @media (max-width: ${breakpoints.md}) {
    padding: 20px 10px 20px 10px;
    border-bottom: 1px solid ${getCssVar('card.footerBorderTopColor')};
    &:last-child {
      border: none;
    }

    margin: 0;
    width: 100%;
  }

  > span {
    font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.semibold};
    width: 50%;
    @media (max-width: ${breakpoints.md}) {
      width: 70%;
    }
  }

  > span:last-child {
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.black};
    @media (max-width: ${breakpoints.md}) {
      text-align: end;
      width: 30%;
    }
  }

  > div {
    width: 50%;
    @media (max-width: ${breakpoints.md}) {
      text-align: end;
      width: 30%;
    }

    span:first-child {
      font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
      font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.black};
    }

    span:last-child {
      font-size: 15px;
      margin-left: 5px;
      opacity: 0.4;
    }
  }
`;

export const TableText = styled.span`
  font-size: 15px;
  opacity: 0.5;
`;

export const Link = styled(RouterLink)`
  color: ${getCssVar('primaryColor')};
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
  font-weight: ${getCssVar('link.fontWeight')};
`;

export const TableDocumentContainer = styled.div`
  align-items: center;
  display: flex;

  .anticon {
    color: #c2baaa;
    margin-right: 5px;
  }
`;

interface ProcessItemContainerProps {
  color?: string;
  dateColor: string;
  isIcon: boolean;
  isLocked?: boolean;
}

export const ProcessItemContainer = styled.div<ProcessItemContainerProps>`
  height: ${({ theme }: { theme: Theme }) => theme.sizes[36]};
  width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  @media (max-width: ${breakpoints.md}) {
    padding: ${({ theme }: { theme: Theme }) => theme.spacing[4]} 0;
    height: ${({ theme }: { theme: Theme }) => theme.sizes[40]};
    border-bottom: 1px solid ${getCssVar('card.footerBorderTopColor')};
    &:last-child {
      border: none;
      padding-bottom: 6px;
    }
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: ${({ theme }: { theme: Theme }) => theme.sizes.full};
    padding: ${({ theme }: { theme: Theme }) => theme.spacing[2]} 0;
    width: ${({ theme }: { theme: Theme }) => theme.sizes.full};

    @media (max-width: ${breakpoints.md}) {
      > div:last-child {
        min-height: 10px;
      }
    }

    .ant-btn {
      margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[3]};
    }

    > .icon {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 50px;
      position: relative;
      width: 65px;

      :after {
        filter: ${({
          theme,
        }: {
          theme: Theme;
        }) => `drop-shadow(${theme.spacing.px} ${theme.spacing.px} ${theme.spacing[0]} ${theme.colors.black})
          drop-shadow(${theme.spacing['0.5']} ${theme.spacing['0.5']} ${theme.spacing[1]} ${theme.colors.blackAlpha[50]})`};
      }

      > .anticon {
        display: flex;
        height: 80%;
        justify-content: center;
        width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
      }

      > .lockicon {
        filter: ${({
          theme,
        }: {
          theme: Theme;
        }) => `drop-shadow(${theme.spacing.px} ${theme.spacing.px} ${theme.spacing[0]} ${theme.colors.black})
          drop-shadow(${theme.spacing['0.5']} ${theme.spacing['0.5']} ${theme.spacing[1]} ${theme.colors.blackAlpha[50]})`};
        height: 10px;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        width: 10px;
      }

      &::after {
        background-color: ${(props: ProcessItemContainerProps) => props.color};
        border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
        content: '';
        display: ${(props: ProcessItemContainerProps) => (props.isIcon ? 'none' : 'block')};
        height: ${({ theme }: { theme: Theme }) => theme.sizes[2]};
        left: 2px;
        position: absolute;
        top: 2px;
        width: ${({ theme }: { theme: Theme }) => theme.sizes[2]};
      }
    }

    .title {
      font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
      font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.semibold};
      opacity: ${({ isLocked }: ProcessItemContainerProps) => (isLocked ? '0.3' : 1)};
    }

    .date {
      color: ${(props: ProcessItemContainerProps) => props.dateColor};
      font-size: ${({ theme }: { theme: Theme }) => theme.sizes['3.6']};
      margin-bottom: 5px;
    }
  }

  &:hover {
    background-color: ${getCssVar('process.hoverBg')};
  }
`;

export const PopupBodyContainer = styled.div`
  display: flex;
  flex-direction: column;

  input,
  .ant-picker {
    width: 60%;
  }

  h3 {
    &:before {
      color: red;
      content: '*';
    }

    margin-top: ${({ theme }: { theme: Theme }) => theme.spacing[4]};
  }
`;
