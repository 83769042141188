import React from 'react';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { Routes } from '../../services/entities';
import { backlinkRoutes } from '../../app-constants';
import { HistoryState } from '../../services/interfaces';

export const getLayoutConfig = (
  { customComponent }: InnerLayout,
  historyState?: HistoryState,
): InnerLayout => ({
  title: 'Assets',
  customComponent,
  backLink: (
    <BackLink
      preferRoot
      historyState={historyState}
      defaultRoute={Routes.PATCH_MANAGEMENT_ASSETS}
      defaultTitle={backlinkRoutes[Routes.PATCH_MANAGEMENT_ASSETS]}
    />
  ),
});
