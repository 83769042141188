import { ProcessListWrapper, ProcessPieWrapper, ProcessText, Wrapper } from './styled';
import { CertificateSchema } from '../../../../../../../services/api';
import { ProcessList } from '../../components/ProcessList';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { getCapitalizedString } from '../../../../../../../services/helpers';
import { CyberResilienceScoreDescriptionScoreMessage } from '../styled';

type Props = {
  certificates: Array<CertificateSchema>;
  processPermission: string;
  processScore: number | undefined | null;
  onChartLoaded: () => void;
  processRank: string;
};

export function PdfPage5({
  certificates,
  processPermission,
  processScore,
  processRank,
  onChartLoaded,
}: Props) {
  return (
    <Wrapper>
      <ProcessText>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(processRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque neceari opta sinis
          excestiate comni nonsedi velibeate coriti as quas mo exerspe rumql et ipsum utem nos eos
          et landanti con ni ad ut que eum quis et aperuptatur? Lam, odit rumql et ipsum utem nos
          eos et landanti ad ut que eum?
        </p>
        <p>
          Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit pereped ut qui
          quisimi, tem dolorro vidunde lenderor aut estrumque neceari opta sinis excestiate comni
          nonsedi velibeate coriti as quas mo exerspe rumquae rnationest et mos eati tem et quatur
          rem aut exerfer uptions editatquam as et odit faccat omnima sequiam sam, quaessi
          tassinctore pa ation nit voluptat volut offic tecabor emolore rempos dolore ad et, omniene
          sit explique sitatiis dolore maiore nis quatiur, ut aute minverest occaeperspel id expel
          et ipsum utem nos eos et landanti con ni ad ut que eum quis et aperuptatur?
        </p>
      </ProcessText>

      <ProcessPieWrapper>
        <GradientPieReport
          score={processScore}
          onFinished={onChartLoaded}
          width='310px'
          height='310px'
          fontSize={60}
        />
      </ProcessPieWrapper>
      <ProcessListWrapper>
        <ProcessList
          certificates={certificates.filter(({ title }) => title !== 'IASME Governance Audited')}
          permission={processPermission}
        />
      </ProcessListWrapper>
    </Wrapper>
  );
}
