import styled from 'styled-components';
import { Modal } from 'shared/Modal/Modal';
import { Theme } from 'styles/theme/types';

type Props = {
  theme: Theme;
};

export const Popup = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      display: flex;
      justify-content: center;
      border: none;
      border-radius: 4px;
      .ant-modal-title {
        color: ${({ theme }: Props) => theme.colors.blue[900]};
        font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
        font-size: 16px;
      }
    }

    .ant-modal-body {
      padding: 0;

      .ant-menu-item {
        display: flex;
        justify-content: start;
        align-items: center;
        height: 48px;
        padding: 0 12px;
      }

      .ant-menu-item-selected {
        background-color: ${({ theme }: Props) => `${theme.colors.brown[100]}${theme.opacity[12]}`};
        color: currentColor;
      }
    }
  }
`;
