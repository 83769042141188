import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { VulnerabilitySchema } from 'services/api';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { Card, ScrollContainer, Table } from 'shared';
import { useApp, useCustomer, useLoading, usePmPatches } from 'store';
import { patches, patchesApi } from 'store/patchManagement';
import { useTheme } from 'styled-components';
import { columns } from './TableColumns';

export const ResolvedVulnerabilities = () => {
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const dispatch = useDispatch();
  const { id: patchId } = useParams<{ id: string }>();
  const history = useHistory();
  const { resolvedVulnerabilities } = usePmPatches();
  const loading = useLoading([patchesApi.getResolvedVulnerabilities.typePrefix])[
    patchesApi.getResolvedVulnerabilities.typePrefix
  ];
  const { state: historyState } = useLocation<HistoryState>();
  const { details } = usePmPatches();
  const theme = useTheme();

  React.useEffect(() => {
    dispatch(patches.actions.resetValues());
    if (details?.patchId === patchId && details?.qids?.length) {
      dispatch(
        patchesApi.getResolvedVulnerabilities({
          customerId,
          requestBody: { qids: details?.qids },
        }),
      );
    }
  }, [customerId, details, dispatch, patchId]);

  const handleTableClick = React.useCallback(
    (vulnerability: VulnerabilitySchema) => {
      history.push(`${Routes.VULNERABILITIES_LIST}/${vulnerability.id}`, {
        backTo: {
          title: `Back to `,
          id: details.title,
          route: `${Routes.PATCH_MANAGEMENT_PATCHES}/${patchId}`,
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [history, historyState, patchId, details],
  );

  return (
    <Card cardPadding={isMobile ? theme.sizes[5] : theme.sizes['5.5']} bodyPadding={theme.sizes[0]}>
      <ScrollContainer width='400px' $isScrollTop>
        <Table
          columns={columns}
          isClickable={true}
          onRow={(record: VulnerabilitySchema) => ({
            onClick: () => {
              handleTableClick(record);
            },
          })}
          dataSource={resolvedVulnerabilities.rows}
          tableLayout='auto'
          pagination={false}
          scroll={!isMobile ? { x: 400 } : {}}
          rowKey='qid'
          loading={loading}
        />
      </ScrollContainer>
    </Card>
  );
};
