import { Button, Modal, ProgressBar } from 'shared';

type PopupWithProgressBarProps = {
  visible: boolean;
  percent: number;
  cancelLoading?: boolean;
  handleCancel: () => void;
  cancelDisabled?: boolean;
  title?: string;
};

export const PopupWithProgressBar = ({
  visible,
  percent,
  handleCancel,
  title,
  cancelLoading,
  cancelDisabled,
}: PopupWithProgressBarProps) => {
  const footer = [
    <Button key='cancel' onClick={handleCancel} loading={cancelLoading} disabled={cancelDisabled}>
      Cancel
    </Button>,
  ];

  return (
    <Modal title={title} visible={visible} footer={footer} closable={false} centered={true}>
      <ProgressBar percent={percent} />
    </Modal>
  );
};
