import { isEmpty } from 'lodash';
import React from 'react';
import { TableColumnsType, TableProps, Tooltip } from 'antd';
import {
  Box,
  Card,
  EmptyTablePreview,
  EmptyTablePreviewProps,
  Icon,
  MobilePagination,
  SortPopup,
} from 'shared';
import { initialPagination, NO_VALUE_LABEL } from 'app-constants';
import { paginationSelections } from 'shared/TableFilters/constants';
import { MobileTableFiltersDrawer } from 'shared/TableFilters';
import { OptionType } from 'shared/Table/types';
import { ColumnType } from 'antd/lib/table/interface';
import { Filters as FiltersType, GridRequestParams, OrderBy, Pagination } from 'services/api';
import { SearchInputContainer } from 'shared/TableFilters/styled';
import { getCssVar, icons } from 'styles/theme';
import { useTheme } from 'styled-components';
import { DebouncedInput } from './components/DebouncedInput';
import {
  ClickableColumn,
  Filters,
  FiltersButtons,
  HeaderInfo,
  ItemsAmount,
  ScrollTableContainer,
  Sort,
  Table,
  TableBody,
  TableColumn,
  TableLoader,
  TopOfCard,
} from './styled';
import { FilterType, SearchSettings, SetParamsType } from './types';
import { RowType } from './CustomTable';
import { noMathCriteria } from './EmptyTablePreview/constants';

type ParamsType = GridRequestParams;

export const noSortingField = 'No sorting';

export interface MobileTableProps<T extends RowType, T2 extends ParamsType> {
  searchSettings?: SearchSettings;
  data: {
    rows?: T[];
    count?: number;
  };
  columns: TableColumnsType<T>;
  filterFields?: FilterType[];
  scroll?: string;
  noFilters?: boolean;
  params: T2;
  setParams: (updatedParams: SetParamsType<T2>) => void;
  initialOrderBy: OrderBy;
  onRowClick?: (record: T) => void;
  isLoadingContent?: boolean;
  getActions?: (row: T) => JSX.Element;
  customEmptyMessageText?: EmptyTablePreviewProps | null;
  emptyMessage?: React.ReactNode;
  noPagination?: boolean;
  sortable?: boolean;
}

export const MobileTable = <T extends RowType, T2 extends ParamsType>({
  searchSettings,
  data,
  columns,
  filterFields,
  scroll,
  noFilters,
  params,
  setParams,
  initialOrderBy,
  onRowClick,
  isLoadingContent,
  getActions,
  customEmptyMessageText,
  emptyMessage,
  noPagination,
  sortable = true,
}: MobileTableProps<T, T2>) => {
  const theme = useTheme();

  const [selectedSortField, setSelectedSortField] = React.useState(
    params.orderBy.field || noSortingField,
  );

  const [currentPagination, setCurrentPagination] = React.useState<Pagination>(
    params.pagination || initialPagination,
  );
  const [activeFiltersCount, setActiveFiltersCount] = React.useState(
    params.filter?.fields?.length || 0,
  );
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);
  const isInit = React.useRef(true);
  const [isMobileResetButtonClick, setMobileResetButtonClick] = React.useState(false);

  const sortFields = React.useMemo(() => {
    return columns
      .filter((column: ColumnType<T>) => {
        return column.sorter;
      })
      .map((column: ColumnType<T>) => {
        return { value: column.key as string, label: column.title as string };
      });
  }, [columns]);

  const getSortLabel = () =>
    sortFields.find((field: OptionType) => field.value === selectedSortField)?.label;

  const renderPaginationTotal = () => {
    if (!data.count) {
      return null;
    }

    let startValue = 1;
    let endValue = data.count;

    if (currentPagination && currentPagination.page && currentPagination.count) {
      startValue = (currentPagination.page - 1) * currentPagination.count + 1;
      if (data && data.count && data.count > currentPagination.page * currentPagination.count) {
        endValue = currentPagination.count * currentPagination.page;
      }
    }

    return <ItemsAmount>{`${startValue}-${endValue} of ${data.count} items`}</ItemsAmount>;
  };

  const updateOrderBy = React.useCallback(
    (orderBy: OrderBy) => {
      if (orderBy.field && orderBy.type) {
        setParams((prevParams: T2) => ({
          ...prevParams,
          orderBy,
        }));
      }
    },
    [setParams],
  );

  const updateSelectedSortField = React.useCallback(
    (sortField: string) => {
      if (sortField) {
        setSelectedSortField(sortField);
        setCurrentPagination({ count: params.pagination.count, page: initialPagination.page });
      }
    },
    [setCurrentPagination, params.pagination.count],
  );

  const updatePagination = React.useCallback(
    (pagination: Pagination) => {
      if (pagination.page && pagination.count && !isInit.current) {
        setCurrentPagination(pagination);
        setParams((prevParams: T2) => ({
          ...prevParams,
          pagination,
        }));
      }
    },
    [setParams],
  );

  const handleFiltersChange = React.useCallback(
    (filterFields: FiltersType | null, isResetClick?: boolean) => {
      setCurrentPagination((prevPagination: Pagination) => {
        return {
          count: prevPagination.count,
          page: initialPagination.page,
        };
      });
      if (!filterFields?.fields) {
        setActiveFiltersCount(0);

        return setParams((prevParams: T2) => {
          const { customerId, orderBy, pagination, filter } = prevParams;

          if (filter?.search && !isResetClick) {
            return {
              customerId,
              orderBy,
              pagination: { count: pagination?.count, page: initialPagination.page },
              filter: { search: filter?.search },
            } as T2;
          }

          return {
            customerId,
            orderBy,
            pagination: { count: pagination?.count, page: initialPagination.page },
          } as T2;
        });
      }

      if (filterFields?.fields) {
        setActiveFiltersCount(filterFields.fields.length);

        return setParams((prevParams: T2) => {
          if (prevParams.filter && prevParams.filter.search && !isResetClick) {
            return {
              ...prevParams,
              filter: { search: prevParams.filter.search, fields: filterFields.fields },
              pagination: { count: prevParams.pagination.count, page: initialPagination.page },
            } as T2;
          }

          return {
            ...prevParams,
            filter: { fields: filterFields.fields },
            pagination: { count: prevParams.pagination.count, page: initialPagination.page },
          } as T2;
        });
      }

      return null;
    },
    [setParams],
  );

  const handleDebouncedInputChange = React.useCallback(
    (value: string, isResetClick?: boolean) => {
      if (!value && !isResetClick) {
        return null;
      }

      if (!value && !isInit.current) {
        return setParams((prevParams: T2) => {
          const { customerId, orderBy, pagination, filter } = prevParams;

          setCurrentPagination({ count: pagination.count, page: initialPagination.page });
          if (filter?.fields) {
            return {
              ...prevParams,
              filter: { fields: filter.fields },
              pagination: { count: pagination.count, page: initialPagination.page },
            } as T2;
          }

          return {
            customerId,
            orderBy,
            pagination: { count: pagination.count, page: initialPagination.page },
          } as T2;
        });
      }

      if (searchSettings?.min && value.length >= searchSettings?.min) {
        return setParams((prevParams: T2) => {
          const { customerId, orderBy, pagination, filter } = prevParams;

          setCurrentPagination({ count: pagination.count, page: initialPagination.page });
          if (filter?.fields) {
            return {
              ...prevParams,
              pagination: { count: pagination?.count, page: initialPagination.page },
              filter: { fields: filter.fields, search: { value, fields: searchSettings?.fields } },
            } as T2;
          }

          return {
            customerId,
            orderBy,
            pagination: { count: pagination?.count, page: initialPagination.page },
            filter: { search: { value, fields: searchSettings?.fields } },
          } as T2;
        });
      }

      return null;
    },
    [setParams, searchSettings?.fields, searchSettings?.min],
  );

  React.useEffect(() => {
    isInit.current = false;
  }, []);

  if (!isLoadingContent && !data?.count && !params.filter && emptyMessage) {
    return <Card bodyPadding='0'>{emptyMessage}</Card>;
  }

  return (
    <>
      {searchSettings && (
        <SearchInputContainer>
          <DebouncedInput
            searchSettings={searchSettings}
            onDebouncedInputChange={handleDebouncedInputChange}
            inputValue={params.filter?.search?.value}
            filterFields={params.filter}
            isResetFiltersClick={isMobileResetButtonClick}
          />
          {searchSettings.tooltip && (
            <Box mt='6px' alignSelf='flex-start'>
              <Tooltip title={searchSettings.tooltip} placement='right'>
                <Icon component={icons.info_outline} dimension='27px' className='tooltipInfo' />
              </Tooltip>
            </Box>
          )}
        </SearchInputContainer>
      )}

      <Card bodyPadding='0' className='mobile-table-card-wrapper'>
        <TopOfCard offset={!scroll ? 1 : 0}>
          <HeaderInfo>
            {renderPaginationTotal()}

            <FiltersButtons>
              {sortable && (
                <Sort onClick={() => setIsPopupVisible(true)}>
                  <Icon
                    component={icons.sort}
                    color={
                      selectedSortField !== noSortingField
                        ? getCssVar('primary')
                        : getCssVar('textColor')
                    }
                  />
                  {selectedSortField !== noSortingField ? getSortLabel() : ''}
                </Sort>
              )}
              {!noFilters && (
                <Filters>
                  <Icon component={icons.filter} onClick={() => setIsDrawerVisible(true)} />
                  {activeFiltersCount ? `(${activeFiltersCount})` : ''}
                </Filters>
              )}
            </FiltersButtons>
          </HeaderInfo>

          {!noPagination && (
            <MobilePagination
              selections={paginationSelections}
              pagination={currentPagination}
              setMobilePagination={updatePagination}
              count={data.count || 0}
            />
          )}
        </TopOfCard>

        {!scroll ? (
          <TableBody>
            {!isLoadingContent &&
              data.rows?.map((row: T) => {
                return (
                  <ClickableColumn
                    key={row.id as React.Key}
                    isClickable={!!onRowClick}
                    onClick={() => onRowClick && onRowClick(row)}
                  >
                    <TableColumn
                      column={1}
                      extra={!getActions ? null : getActions(row)}
                      withActions={!!getActions}
                    >
                      {columns.map((column: ColumnType<T>, index: number) => {
                        const value = row[column.dataIndex as keyof T];

                        if (Object.keys(column).length) {
                          return (
                            <TableColumn.Item key={column.key} label={column?.title}>
                              {column.render
                                ? column.render(value, row, index)
                                : value || NO_VALUE_LABEL}
                            </TableColumn.Item>
                          );
                        }

                        return null;
                      })}
                    </TableColumn>
                  </ClickableColumn>
                );
              })}

            {!isLoadingContent && !data.rows?.length && (
              <EmptyTablePreview
                title={isEmpty(customEmptyMessageText) ? '' : customEmptyMessageText?.title}
                textContent={
                  isEmpty(customEmptyMessageText)
                    ? noMathCriteria
                    : customEmptyMessageText?.textContent
                }
                marginTop={theme.spacing['5']}
                alignCenter
              />
            )}

            {isLoadingContent && <TableLoader size='large' />}
          </TableBody>
        ) : (
          <ScrollTableContainer width={scroll}>
            <Table<React.FC<TableProps<T>>>
              columns={columns.map((column) => {
                const { sorter, ...otherParams } = column;

                return otherParams;
              })}
              dataSource={data.rows}
              pagination={false}
              tableLayout='auto'
              rowKey={(record: T) => record.id as React.Key}
              onRow={(record: T) => ({
                onClick: () => {
                  if (onRowClick) {
                    onRowClick(record);
                  }
                },
              })}
              loading={isLoadingContent}
            />
          </ScrollTableContainer>
        )}
        {!noPagination && (
          <MobilePagination
            selections={paginationSelections}
            pagination={currentPagination}
            setMobilePagination={updatePagination}
            count={data.count || 0}
          />
        )}
        {filterFields && !noFilters && (
          <MobileTableFiltersDrawer
            visible={isDrawerVisible}
            setVisible={setIsDrawerVisible}
            onFiltersChange={handleFiltersChange}
            currentFilter={params.filter || null}
            filterFields={filterFields}
            setMobileResetButtonClick={setMobileResetButtonClick}
          />
        )}

        {isPopupVisible && (
          <SortPopup
            isVisible={isPopupVisible}
            setIsVisible={setIsPopupVisible}
            fields={sortFields}
            orderBy={params.orderBy}
            setOrderBy={updateOrderBy}
            defaultSort={initialOrderBy}
            selectedSortField={selectedSortField}
            setSelectedSortField={updateSelectedSortField}
          />
        )}
      </Card>
    </>
  );
};
