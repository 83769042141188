import { MutableRefObject, useEffect, useRef } from 'react';

export const usePreviousValue = <T>(value: T, initialState: T): T => {
  const ref: MutableRefObject<T> = useRef<T>(initialState);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
