import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  AppState,
  useApp,
  useCustomer,
  useCustomerChange,
  useExcludedVulnerabilitiesStatusFilter,
  useLoading,
  useVulnerability,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { RadioButtonsWithRangePicker } from 'shared/RadioButtonsWithRangePicker/RadioButtonsWithRangePicker';
import { Line } from 'shared/charts';
import { Box, Card, Icon } from 'shared';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { getDefaultDateRangeValue } from 'shared/RadioButtonsWithRangePicker/helpers';
import { initialPagination } from 'app-constants';
import { Routes } from 'services/entities';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { IDate } from 'services/interfaces';
import { getSubTitleArrow } from 'services/helpers';
import { getQueryString } from 'services/api/core/request';
import { Filter, VulnerabilityTrendPointSchema } from 'services/api';
import {
  emptyValuesAllVulnerabilities,
  FIXED_VALUE,
  TooltipTitles,
  VulnerabilitiesTitles,
} from 'pages/VulnerabilityManagement/constants';
import {
  confirmedVulnerabilitiesStatusFilter,
  initialOrderBy,
  tabsKeys as vulnerabilitiesListTabs,
} from 'pages/VulnerabilitiesList/constants';
import {
  getParamsFromVulnerabilitiesStatusFilter,
  getPointType,
} from 'pages/VulnerabilityManagement/helpers';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { useTheme } from 'styled-components';
import { ScrollContainer } from '../styled/styled';
import { getBodyPadding, getCardPadding, getHeadStyles, getOption } from './helpers';
import { EmptyValuesAllVulnerabilities } from '../../types';
import { validateField, validateFieldValue } from '../../../CEPlus/helpers';
import { IFilterField } from '../../../CEPlus/constants';
import { ExcludeFilterTooltip } from '../ExcludeFilterTooltip';

export const AllVulnerabilities = () => {
  const theme = useTheme();
  const [date, setDate] = useState<IDate>(getDefaultDateRangeValue());
  const { selectedTags } = useVulnerability();

  const allVulnerabilitiesTrend = useSelector(
    (state: AppState) => state.vulnerability.allVulnerabilitiesTrend,
  );

  const customer = useCustomer();
  const customerChange = useCustomerChange([
    vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix,
  ]);

  const isShowEmptyChartInAllVulnerabilitiesTrend =
    customerChange[vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix];

  const { isMobile } = useApp();

  const dispatch = useDispatch();
  const loading = useLoading([vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix]);

  const updateDate = useCallback((selectedDate: IDate) => {
    setDate(selectedDate);
  }, []);

  const vulnerabilitiesParams = useMemo(() => {
    const filterFields: IFilterField[] = [
      {
        name: 'tagsAssets',
        value: selectedTags || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
      { ...confirmedVulnerabilitiesStatusFilter.fields[0] },
    ];

    return {
      orderBy: initialOrderBy,
      pagination: initialPagination,
      tab: vulnerabilitiesListTabs.active,
      filter: {
        fields: validateField(filterFields),
      },
    };
  }, [selectedTags]);
  const vulnerabilitiesFilterbyStatus = useExcludedVulnerabilitiesStatusFilter() as string[];

  useEffect(() => {
    const { startDate, endDate } = date;

    dispatch(
      vulnerabilitiesApi.getAllVulnerabilitiesTrend({
        customerId: customer,
        startDate,
        endDate,
        pointType: getPointType(startDate, endDate),
        tagIds: selectedTags || [],
        ...getParamsFromVulnerabilitiesStatusFilter(vulnerabilitiesFilterbyStatus),
      }),
    );
  }, [dispatch, customer, date, vulnerabilitiesFilterbyStatus, selectedTags]);

  const boldText =
    allVulnerabilitiesTrend.count || allVulnerabilitiesTrend.count === 0
      ? allVulnerabilitiesTrend.count.toLocaleString('ru')
      : '';

  const lightText =
    allVulnerabilitiesTrend.percentageComparison ||
    allVulnerabilitiesTrend.percentageComparison === 0
      ? `${allVulnerabilitiesTrend.percentageComparison.toFixed(FIXED_VALUE)}%`
      : '';

  const subTitle = (allVulnerabilitiesTrend.count || allVulnerabilitiesTrend.count === 0) && (
    <SubTitle
      boldText={
        <Box display='flex' alignItems='center' gap={`${theme.sizes['1.2']}`}>
          <ExcludeFilterTooltip />
          {boldText}
        </Box>
      }
      lightText={lightText}
      arrow={getSubTitleArrow(allVulnerabilitiesTrend.percentageComparison)}
      paddingRight={theme.sizes[8]}
    />
  );

  return (
    <Card
      title={VulnerabilitiesTitles.AllVulnerabilities}
      showDivider={false}
      subTitle={subTitle}
      tooltipTitle={TooltipTitles.AllVulnerabilities}
      headStyles={getHeadStyles(isMobile, theme)}
      cardPadding={getCardPadding(isMobile, theme)}
      bodyPadding={getBodyPadding(isMobile, theme)}
      showLoader={loading[vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix]}
      height={theme.sizes.full}
      headAction={
        <Link
          to={`${Routes.VULNERABILITIES_LIST}${getQueryString(vulnerabilitiesParams)}`}
          style={{ fontSize: theme.sizes[4], paddingTop: theme.sizes['2.5'] }}
        >
          See all vulnerabilities
          <Icon component={theme.icons.arrow_right_alt} />
        </Link>
      }
    >
      <ScrollContainer className='default-cursor-chart'>
        <RadioButtonsWithRangePicker
          handleDateChange={updateDate}
          date={date}
          isMobile={isMobile}
        />

        <TotalCustomTooltipContainer>
          <RenderChartWrapper<VulnerabilityTrendPointSchema, EmptyValuesAllVulnerabilities>
            values={allVulnerabilitiesTrend.trend?.points}
            emptyValues={emptyValuesAllVulnerabilities}
            emptyText='No vulnerabilities yet'
            isShowEmptyChart={isShowEmptyChartInAllVulnerabilitiesTrend}
          >
            <Line
              getOption={getOption}
              values={allVulnerabilitiesTrend.trend?.points || emptyValuesAllVulnerabilities}
              date={date}
              isMobile={isMobile}
              height={{ chart: '320px', grid: 'auto' }}
            />
          </RenderChartWrapper>
        </TotalCustomTooltipContainer>
      </ScrollContainer>
    </Card>
  );
};
