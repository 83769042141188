import { PopupConfigType } from 'shared/ActionPopup/types';
import { AppDispatch, UserProps } from 'store';
import { FieldNames } from './constants';

export type FieldValues = Record<FieldNames, string>;

export type PopupParams = {
  popupId: string;
  setPopupDetails: (popupDetails: PopupConfigType | null) => void;
  setIsVisiblePopup: (isVisible: boolean) => void;
  userData: { id: string; name: string; email: string };
  dispatch: AppDispatch;
  customerId: string;
  onSuccess: () => void;
  user: UserProps;
};

export enum CreateModalMode {
  Edit = 'Edit',
  Create = 'Create',
}
