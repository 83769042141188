import React from 'react';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { backlinkRoutes, emptyDataString } from 'app-constants';
import { PhishingEmailsStatusSchema } from 'services/api';
import { HistoryState } from 'services/interfaces';
import { Routes } from 'services/entities';
import { completed, PhishingActivity } from './constants';

export const getLayoutConfig = (historyState?: HistoryState | undefined): InnerLayout => ({
  title: 'Phishing and training activity',
  backLink: (
    <BackLink
      historyState={historyState?.backTo ? historyState : undefined}
      defaultRoute={Routes.PEOPLE}
      defaultTitle={backlinkRoutes[Routes.PEOPLE]}
    />
  ),
});

export const getTrainingStatus = (status: number) => {
  switch (status) {
    case 0:
      return 'Not started';
    case completed:
      return 'Completed';
    default:
      return status ? `${status}%` : emptyDataString;
  }
};

export const getPhishingActivityStatus = (status: string) => {
  switch (status) {
    case PhishingEmailsStatusSchema._1:
      return PhishingActivity.Opened;
    case PhishingEmailsStatusSchema._2:
      return PhishingActivity.Clicked;
    case PhishingEmailsStatusSchema._3:
      return PhishingActivity.providedCredentials;
    case PhishingEmailsStatusSchema._0:
      return PhishingActivity.noActions;
    default:
      return PhishingActivity.noActions;
  }
};
