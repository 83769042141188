import React from 'react';
import moment from 'moment/moment';
import {
  EmailSecurity,
  Encryption,
  Image,
  MobileDeviceManagement,
  MultiFactorAuthentication,
  RestEasyTextBlock,
  Wrapper,
} from './styled';
import { BoostScoreItemSchema, MonthlyReportResponse } from '../../../../../../../services/api';
import { getSortedDashboardScores } from '../../helpers';
import { Score } from '../styled';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from '../../../../../../../app-constants';

const imageOk = `${PATH_TO_MONTHLY_REPORT_IMAGES}/image_ok.png`;
const imageCancel = `${PATH_TO_MONTHLY_REPORT_IMAGES}/image_cancel.png`;

enum BoostScoreTitles {
  ENCRYPTION = 'Encryption',
  MULTI_FACTOR_AUTHENTICATION = 'Multi-factor Authentication',
  EMAIL_SECURITY = 'Email security',
  MOBILE_DEVICE_MANAGEMENT = 'Mobile Device Management',
}

const componentMapper = {
  [BoostScoreTitles.ENCRYPTION]: Encryption,
  [BoostScoreTitles.MULTI_FACTOR_AUTHENTICATION]: MultiFactorAuthentication,
  [BoostScoreTitles.EMAIL_SECURITY]: EmailSecurity,
  [BoostScoreTitles.MOBILE_DEVICE_MANAGEMENT]: MobileDeviceManagement,
};

type Props = {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
};

export function PdfPage10({ data, onChartLoaded }: Props) {
  const scores = data?.dashboardScores || [];
  const checkingDashboardScores = getSortedDashboardScores([...scores])[scores.length - 1];
  const boostScore = checkingDashboardScores?.boost;
  const { boostScoreItems } = data;

  const getImageSrc = (boostType: BoostScoreItemSchema) =>
    moment().valueOf() > moment(boostType?.expirationDate).valueOf() || !boostType?.vendor
      ? imageCancel
      : imageOk;

  return (
    <Wrapper>
      <Score>
        <GradientPieReport
          height='110px'
          width='110px'
          fontSize={25}
          score={boostScore || 0}
          onFinished={onChartLoaded}
        />
      </Score>
      <RestEasyTextBlock>
        There are several easy to implement controls that form the basis of a good security posture
        for any business. In norm's experience, the implementation of these four basic controls
        would have prevented the vast majority of the breaches we have seen. To improve your cyber
        resilience score, implement these controls and update the "Boost score" section on the
        smartbloc.&trade;[LIVE] home page.
      </RestEasyTextBlock>

      {boostScoreItems?.map((score: BoostScoreItemSchema) => {
        const Component = componentMapper[score?.title as BoostScoreTitles] ?? 'div';

        return (
          <Component key={`td-${score.expirationDate}`}>
            <Image src={getImageSrc(score)} alt={score.title} />
          </Component>
        );
      })}
    </Wrapper>
  );
}
