import React, { Children, forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';
import {
  EndIcon,
  StartIcon,
  StepContainer,
  StepItemBody,
  StepItemContainer,
} from './Stepper.styled';
import { stepperIcons } from './icons';

type StepItemProps = PropsWithChildren<{
  onClick?: () => void;
  onHover?: () => void;
  index?: number;
  active?: boolean;
  className?: string;
}>;

type StepperProps = PropsWithChildren<{
  activeItem?: string;
  className?: string;
}>;

export const Stepper = forwardRef<HTMLDivElement, StepperProps>(({ children, className }, ref) => {
  return (
    <StepContainer ref={ref} className={classNames('stepper', className)}>
      {Children.map(children, (child, index) => {
        if (React.isValidElement<StepItemProps>(child)) {
          return React.cloneElement(child, { index });
        }

        return child;
      })}
    </StepContainer>
  );
});

export function StepperItem({
  className,
  children,
  onClick,
  onHover,
  index = 0,
  active = false,
}: StepItemProps) {
  return (
    <StepItemContainer
      className={classNames('stepper-item', className)}
      onClick={onClick}
      onMouseEnter={onHover}
    >
      {index > 0 && <StartIcon component={stepperIcons.start} active={active} />}
      <StepItemBody active={active}>
        <span>{children}</span>
      </StepItemBody>
      <EndIcon component={stepperIcons.end} active={active} />
    </StepItemContainer>
  );
}
