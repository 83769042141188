import React from 'react';
import { Colors, Values } from 'shared/charts/types';
import styled from 'styled-components';
import { startCase, toLower, upperFirst } from 'lodash';
import { calcPercentage } from '../../../../../../../shared/charts';

type Props = {
  values: Values;
  colors: Colors;
};

const Wrapper = styled.div`
  margin-top: -10px;
`;

const Circle = styled.span<{ color: string }>`
  background: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin-right: 2px;
  width: 8px;
`;

const Row = styled.div`
  display: inline-block;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.7;
  margin-right: 5px;

  b {
    font-weight: 500;
  }
`;

export function Legend({ values, colors }: Props) {
  const percentages = calcPercentage(values);

  return (
    <Wrapper>
      {Object.entries(values).map(([key, value]) => (
        <Row key={key}>
          <Circle color={colors[key]} /> {upperFirst(toLower(startCase(key)))}{' '}
          <strong>
            {value} ({percentages[key] || 0}%)
          </strong>
        </Row>
      ))}
    </Wrapper>
  );
}
