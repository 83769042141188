import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentsResponse, DPLCategoriesResponse, SoftwareDocumentsResponse } from 'services/api';
import { DplApiType } from 'store/dataProtectionLibrary/thunks';
import { SoftwareApiType } from 'store/software/thunks';
import { documentsApi, DocumentsApiType } from './thunks';

export interface InitialState {
  tableContent: DocumentsResponse | SoftwareDocumentsResponse;
  latestReports: DocumentsResponse;
  downloading: string;
  categories?: DPLCategoriesResponse;
  requestError: {
    message: string;
  };
}

export const initialState: InitialState = {
  tableContent: {},
  latestReports: {},
  downloading: '',
  requestError: {
    message: '',
  },
};

export const setDownloading = (state: InitialState, action: PayloadAction<string>) => {
  return {
    ...state,
    downloading: action.payload,
  };
};

export const makeExtraReducers = <T extends SoftwareApiType | DocumentsApiType | DplApiType>(
  api: T,
) => {
  return {
    [api.fetchTableContent.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<DocumentsResponse | SoftwareDocumentsResponse>,
    ) => {
      state.tableContent = action.payload;
    },
    [api.fetchTableContent.rejected.toString()]: (state: InitialState) => {
      state.tableContent = {};
    },
    [api.downloadFile.fulfilled.toString()]: (state: InitialState) => {
      state.downloading = '';
    },
    [api.downloadFile.rejected.toString()]: (state: InitialState) => {
      state.downloading = '';
    },
    [documentsApi.getLatestReports.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<DocumentsResponse>,
    ) => {
      state.latestReports = action.payload;
    },
    [documentsApi.getLatestReports.rejected.toString()]: (state: InitialState) => {
      state.latestReports = {};
    },
  };
};

export const documents = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDownloading,
    setTableContent: (state: InitialState, action: PayloadAction<DocumentsResponse>) => {
      return {
        ...state,
        tableContent: action.payload,
      };
    },
  },
  extraReducers: makeExtraReducers(documentsApi),
});
