import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Grid, Tooltip, Typography } from 'antd';
import { useTheme } from 'styled-components';
import { app, AppState, routes } from 'store';
import { useRowGap, useScreenSizes } from 'services/hooks';
import { Box, Button, Icon, Switch } from 'shared';
import { icons, sizes } from 'styles/theme';
import { PATH_TO_ICONS } from 'app-constants';
import { ICustomTitle, IDownloadDoc, IToggleButton, IUploadDoc } from './types';
import {
  Blur,
  Content,
  DownloadIcon,
  MenuWrapper,
  RowTitle,
  StyledTitle,
  ToggleButtonTitle,
  WrapperTitle,
} from './components';
import { HistoryState } from '../../../services/interfaces';
import { DownloadButtonsColumn } from './components/DownloadButtonsColumn';
import { Factory } from '../../DownloadDoc/Factory';

export type InnerLayoutProps = {
  uploadDoc?: IUploadDoc[];
  downloadDoc?: IDownloadDoc[];
  title?: string | JSX.Element;
  CustomTitle?: (children: ICustomTitle) => JSX.Element;
  titleTooltip?: React.ReactNode;
  customComponent?: React.ReactNode;
  backLink?: React.ReactNode;
  isLoadingContent?: boolean;
  status?: React.ReactNode;
  severity?: React.ReactNode;
  toggleButton?: IToggleButton;
  isCustomComponentNearTitle?: React.ReactNode;
  mobileCustomComponentStyle?: React.CSSProperties;
  desktopCustomComponentStyle?: React.CSSProperties;
  beforeToggleComponentStyle?: React.CSSProperties;
  isBeforeToggleComponent?: React.ReactNode;
  vulnerabilitySuppression?: React.ReactNode;
};

export const InnerLayout = ({
  title,
  titleTooltip,
  downloadDoc,
  children,
  customComponent,
  backLink,
  CustomTitle,
  isLoadingContent = false,
  status,
  severity,
  toggleButton,
  isCustomComponentNearTitle,
  mobileCustomComponentStyle,
  desktopCustomComponentStyle,
  beforeToggleComponentStyle = {},
  isBeforeToggleComponent,
  uploadDoc,
  vulnerabilitySuppression,
}: PropsWithChildren<InnerLayoutProps>) => {
  const theme = useTheme();
  const { Title } = Typography;
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const { gapString } = useRowGap();
  const [isDesktop, isTablet, isMobile, isLargeDesktop] = useScreenSizes();
  const dispatch = useDispatch();
  const location = useLocation<HistoryState>();
  const blured = useSelector(({ app: { blured } }: AppState) => blured);

  React.useMemo(
    () => ({
      small: `${PATH_TO_ICONS}/${theme.colorSet.leftMenu.logoTopSmall}`,
      regular: `${PATH_TO_ICONS}/${theme.colorSet.leftMenu.logoTop}`,
    }),
    [theme.colorSet.leftMenu.logoTop, theme.colorSet.leftMenu.logoTopSmall],
  );

  React.useEffect(() => {
    if (
      isDesktop !== undefined &&
      isTablet !== undefined &&
      isMobile !== undefined &&
      isLargeDesktop !== undefined
    ) {
      dispatch(app.actions.setScreenSizes({ isLargeDesktop, isDesktop, isTablet, isMobile }));
    }
  }, [dispatch, isDesktop, isTablet, isMobile, isLargeDesktop]);

  React.useEffect(() => {
    if (location?.state?.shouldResetHistory) {
      dispatch(routes.actions.resetRoutes());
    }
  }, [dispatch, location?.state?.shouldResetHistory]);

  React.useEffect(() => {
    if (breakpoints.xl !== undefined && !breakpoints.xl) {
      dispatch(app.actions.setIsMenuCollapsed(!breakpoints.xl));
    }
  }, [dispatch, breakpoints.xl]);

  const TitleComponent = CustomTitle || Title;

  return (
    <Content className='site-layout-background' padding={gapString}>
      <RowTitle gutter={32} align={!isDesktop && !!status ? 'top' : 'middle'}>
        <Col flex='auto'>
          <MenuWrapper>
            {title && !isLoadingContent && (
              <WrapperTitle isMobile={!isDesktop} isLabelStatus={!!status}>
                {backLink}
                <TitleComponent
                  level={2}
                  style={
                    isCustomComponentNearTitle && isDesktop
                      ? { display: 'flex', alignItems: 'center' }
                      : {}
                  }
                >
                  <StyledTitle>{title}</StyledTitle>
                  {titleTooltip && (
                    <Tooltip
                      title={titleTooltip}
                      placement={isMobile ? 'bottomLeft' : 'right'}
                      overlayStyle={{ minWidth: isDesktop ? '400px' : 'auto' }}
                    >
                      <Icon
                        component={icons.info_outline}
                        className='tooltipInfo'
                        style={{ verticalAlign: 'middle' }}
                      />
                    </Tooltip>
                  )}
                  {severity}
                  {status}
                  {vulnerabilitySuppression}
                  {isDesktop && isCustomComponentNearTitle && (
                    <Box
                      ml={isDesktop ? '15px' : '0'}
                      mt={isDesktop ? '0' : '16px'}
                      display='flex'
                      style={isDesktop ? desktopCustomComponentStyle : mobileCustomComponentStyle}
                    >
                      {isCustomComponentNearTitle}
                    </Box>
                  )}
                  {!isDesktop && customComponent && (
                    <Box mt='16px' style={mobileCustomComponentStyle}>
                      {customComponent}
                    </Box>
                  )}
                </TitleComponent>
              </WrapperTitle>
            )}
          </MenuWrapper>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
          flex={!isDesktop ? '100%' : 'none'}
        >
          <Box
            display='flex'
            justifyContent='flex-end'
            flexDirection={isDesktop ? 'row' : 'column'}
          >
            {isBeforeToggleComponent && (
              <Box
                style={{
                  ...{ paddingBottom: !isDesktop ? '16px' : 0, maxWidth: '410px' },
                  ...beforeToggleComponentStyle,
                }}
                pr='10px'
              >
                {isBeforeToggleComponent}
              </Box>
            )}
            {toggleButton && (
              <Box d='flex' justify='flex-end' pt='16px'>
                <Switch
                  size='small'
                  onChange={toggleButton.onChange}
                  checked={toggleButton.isActive}
                  loading={toggleButton.loading}
                />
                <ToggleButtonTitle>
                  {toggleButton.title || 'Show potential vulnerabilities'}
                </ToggleButtonTitle>
                {toggleButton.tooltipTitle && (
                  <Tooltip
                    title={toggleButton.tooltipTitle}
                    placement={isMobile ? 'top' : 'right'}
                    trigger={isMobile ? 'click' : 'hover'}
                    overlayStyle={{ minWidth: isDesktop ? '400px' : 'auto' }}
                  >
                    <Icon
                      component={icons.info_outline}
                      dimension={sizes[6]}
                      className='tooltipInfo'
                      style={{ verticalAlign: 'middle', marginTop: '-1px' }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
        </Col>
        {(uploadDoc || downloadDoc) && (
          <DownloadButtonsColumn isDesktop={isDesktop}>
            {uploadDoc &&
              uploadDoc.map((button) => (
                <Box display='flex' alignItems='end' key={button.id}>
                  <Button
                    type='link'
                    style={{ height: '24px' }}
                    icon={<DownloadIcon component={button.icon || icons.download} />}
                    onClick={button.handleAttachButton}
                    loading={button.loading}
                  >
                    {button.title || 'Upload'}
                  </Button>
                  <input
                    hidden
                    type='file'
                    onChange={button.onUpload}
                    ref={button.inputRef}
                    accept={button.accept}
                  />
                  {button.tooltip && (
                    <Tooltip
                      title={button.tooltip}
                      placement={isMobile ? 'top' : 'right'}
                      trigger={isMobile ? 'click' : 'hover'}
                      overlayStyle={{ minWidth: isDesktop ? '400px' : 'auto' }}
                    >
                      <Icon
                        component={icons.info_outline}
                        dimension={sizes[6]}
                        className='tooltipInfo'
                        style={{ verticalAlign: 'middle', marginTop: '-1px' }}
                      />
                    </Tooltip>
                  )}
                </Box>
              ))}
            {(downloadDoc || []).map((button) => (
              <Factory key={button.id} {...button} />
            ))}
          </DownloadButtonsColumn>
        )}
        {isDesktop && customComponent && <Col>{customComponent}</Col>}
      </RowTitle>
      {children}
      {blured && <Blur />}
    </Content>
  );
};
