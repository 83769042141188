import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, CustomerId, ReportsService } from '../../services/api';

const exportCyberResilienceScoreTrends = createAsyncThunk(
  '/customer/get/cyber-resilience-score-trends-report',
  async (requestBody: {
    customerId: CustomerId;
    startDate: number;
    endDate: number;
    timezone: string;
  }) => {
    try {
      return await ReportsService.exportCyberResilienceScoreTrends(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const cyberResilienceScoreTrendsApi = {
  exportCyberResilienceScoreTrends,
};
