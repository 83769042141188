import { minLengthToSearch } from 'app-constants';
import { Status } from 'pages/PenetrationTesting/constants';
import { OrderBy, Filter } from 'services/api';
import { ActionType } from 'shared/ActionPopup/types';
import { OptionType } from 'shared/Table/types';
import { icons } from 'styles/theme';
import { InputType } from '../../services/api/models/InputType';

export const searchPlaceholder = 'Search by test name';
export const searchFields = ['test'];

export const searchSettings = {
  fields: searchFields,
  min: minLengthToSearch,
  placeholder: searchPlaceholder,
};

export enum TableTitles {
  Test = 'Test',
  Start = 'Start',
  End = 'End',
  Status = 'Status',
  Report = 'Report',
}

export enum ColumnKey {
  Test = 'test',
  Start = 'start',
  End = 'end',
  Status = 'status',
  Report = 'report',
}

export const optionsStatus: OptionType[] = [
  { value: Status.ToStart, label: Status.ToStart },
  { value: Status.InProgress, label: Status.InProgress },
  { value: Status.Completed, label: Status.Completed },
];

export const filterFields = [
  {
    field: ColumnKey.Status,
    options: optionsStatus,
    label: TableTitles.Status,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: ColumnKey.End,
    label: TableTitles.End,
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export const initialOrderBy = {
  field: ColumnKey.End,
  type: OrderBy.type.DESC,
};

export const actions: ActionType[] = [
  {
    popupId: 'Download',
    name: 'Download',
    icon: icons.download,
  },
];
