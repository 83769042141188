import React from 'react';
import { TableBody } from 'shared';
import { TableColumn } from './styled';
import { IRowTableType } from '../../types';

interface IProps {
  tableData: Array<IRowTableType>;
}

export const Table = ({ tableData }: IProps) => {
  return (
    <TableBody>
      <TableColumn column={1}>
        {tableData &&
          tableData.map((row: IRowTableType) => (
            <TableColumn.Item key={row.title} label={row.title}>
              {row.description}
            </TableColumn.Item>
          ))}
      </TableColumn>
    </TableBody>
  );
};
