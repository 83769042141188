import { lowerFirst, toLower } from 'lodash';
import { useAuth } from 'store';
import { checkPermission, trimSpaces } from 'services/helpers';
import { PermissionOptions, PermissionsLevel } from 'app-constants';

enum PermissionKeys {
  Process = 'process',
  CybersafetyAndPhishing = 'cybersafetyAndPhishing',
  VulnerabilityManagement = 'vulnerabilityManagement',
  ThreatDetection = 'threatDetection',
  SecurityIncidents = 'securityIncidents',
  PenetrationTesting = 'penetrationTesting',
  ThreatDetections = 'threatDetections',
  EmailSecurity = 'emailSecurity',
  DataProtection = 'dataProtection',
  CMDB = 'cmdb',
  SupportRequests = 'supportRequests',
  Documents = 'documents',
  ManageSoftware = 'manageSoftware',
  AdminUsers = 'adminUsers',
  SecuritySettings = 'securitySettings',
  AuditLogs = 'auditLogs',
  Report = 'report',
  ServicesIntegrations = 'servicesIntegrations',
}

type PermissionState = {
  [key in PermissionKeys]: PermissionsLevel | null;
};

export const useGetPermissions = (pageNames: PermissionOptions[]) => {
  const { permissions } = useAuth();

  return pageNames.reduce((acc: PermissionState, pageName: string) => {
    const permission = checkPermission(permissions, trimSpaces(pageName));

    const normalizedPageName = lowerFirst(trimSpaces(toLower(pageName)));

    return {
      ...acc,
      [normalizedPageName]: permission,
    };
  }, {} as PermissionState);
};

export const useGetPermission = (pageName: PermissionOptions) => {
  const { permissions } = useAuth();

  return checkPermission(permissions, trimSpaces(pageName));
};
