export const SERVICE_INTEGRATION = {
  SENTINEL_ONE: 'SentinelOne',
} as const;

export const CONNECTION_STATUS = {
  CONNECTED: 'Connected',
  NOT_CONNECTED: 'Not connected',
};

export const CONNECTION_STATUS_COLOR = {
  CONNECTED: 'success',
  NOT_CONNECTED: 'errorColor',
};

export const SERVICE_CONNECTION = {
  STATUS: CONNECTION_STATUS,
  COLOR: CONNECTION_STATUS_COLOR,
};

export const GUIDANCE_TYPE = {
  OVERVIEW: 'overview',
  INSTRUCTIONS: 'instructions',
} as const;

export const GUIDANCE_LABEL = {
  OVERVIEW: 'Overview',
  INSTRUCTIONS: 'Instructions',
};
