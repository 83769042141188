import { Menu } from 'antd';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';

type Props = {
  theme: Theme;
};

export const CustomMenu = styled(Menu)`
  border-radius: 4px;

  .ant-dropdown-menu-item {
    width: 180px;

    &:hover {
      background-color: ${({ theme }: Props) => `${theme.colors.brown[100]}${theme.opacity[12]}`};
    }
  }
`;
