/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerId } from '../models/CustomerId';
import type { CustomerMTTIResponse } from '../models/CustomerMTTIResponse';
import type { LicenseUsageSchema } from '../models/LicenseUsageSchema';
import { request as __request } from '../core/request';

export class MdrService {
  /**
   * Get the customer's MTTI
   * Get the customer's MTTI
   * @returns CustomerMTTIResponse Get the customer's MTTI
   * @throws ApiError
   */
  public static async getCustomerMtti({
    customerId,
    startDate,
    endDate,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate: number;
    /** The end date parameter **/
    endDate: number;
  }): Promise<CustomerMTTIResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/mtti`,
      query: {
        startDate: startDate,
        endDate: endDate,
      },
      errors: {
        400: `An error message when update`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get the customer's active edr agent statistic
   * Get the customer's active edr agent statistic
   * @returns LicenseUsageSchema Get the customer's active edr agent statistic
   * @throws ApiError
   */
  public static async getActiveEdrAgentsStatistic({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<LicenseUsageSchema> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/edr/active-agents-statistic`,
      errors: {
        400: `An error message when update`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
