import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Gutter } from 'antd/lib/grid/row';
import { Col, Row } from 'antd';
import { EmptyTablePreview, MobileTable, MobileTableCol } from 'shared';
import { CustomTable } from 'shared/Table/CustomTable';
import { excludeAgedHostTag } from 'services/helpers';
import { Routes } from 'services/entities';
import { Filter, GridRequestParams, TagSchema, VulnerabilitySchema } from 'services/api';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { useFilter } from 'services/hooks/useFilter';
import {
  AppDispatch,
  useApp,
  useCustomer,
  useLoading,
  useTagsManagement,
  useVulnerability,
  vulnerability,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { defaultCurrent, defaultPageSize } from 'app-constants';
import { backlinkRoutes } from 'app-constants/routes';

import { HistoryState } from 'services/interfaces';
import { tagsManagementApi } from 'store/tagsManagement/thunks';
import { getQueryString } from 'services/api/core/request';
import { columns } from '../../components/TableColumns';
import {
  filterFields as staticFilterFields,
  initialOrderBy,
  searchSettings,
  tabsKeys,
} from '../../constants';
import { OptionType } from '../../../../shared/Table/types';
import { InputType } from '../../../../services/api';

const V_GUTTER = 32;
const H_GUTTER = 32;
const V_GUTTER_MOBILE = 24;
const H_GUTTER_MOBILE = 32;
const FULL_WIDTH_COLS = 32;

export const ActiveVulnerabilitiesTab = ({
  updateQueryParams,
}: {
  updateQueryParams: (params: Partial<GridRequestParams>) => void;
}) => {
  const customerId = useCustomer();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { state } = useLocation<HistoryState>();
  const { activeVulnerabilities, categories, selectedTags } = useVulnerability();
  const { tagsList } = useTagsManagement();

  const historyState = useMemo(() => {
    return state;
    // eslint-disable-next-line
  }, []);

  const { isMobile } = useApp();
  const loading = useLoading([
    vulnerabilitiesApi.getActiveVulnerabilities.typePrefix,
    vulnerabilitiesApi.exportVulnerability.typePrefix,
    tagsManagementApi.getTagsList.typePrefix,
  ]);
  const loadingData = loading[vulnerabilitiesApi.getActiveVulnerabilities.typePrefix];

  const selectTagOptions = useMemo(() => {
    return tagsList
      ?.filter(excludeAgedHostTag)
      .map((item: TagSchema) => ({ label: item?.name, value: item?.id }));
  }, [tagsList]);

  const filterFields = useMemo(() => {
    return [
      {
        field: 'category',
        options: categories?.map((category: string) => ({ label: category, value: category })),
        label: 'Category',
        inputType: InputType.SELECTOR,
        filterType: Filter.type.MULTIPLE,
      },

      ...staticFilterFields,
      {
        field: 'tagsAssets',
        options: selectTagOptions as OptionType[],
        label: 'Tags',
        inputType: InputType.SELECTOR,
        filterType: Filter.type.MULTIPLE,
      },
    ];
  }, [categories, selectTagOptions]);

  const GUTTER: [Gutter, Gutter] = !isMobile
    ? [H_GUTTER, V_GUTTER]
    : [H_GUTTER_MOBILE, V_GUTTER_MOBILE];

  const [params, updateParams] = useFilter({
    apiCall: vulnerabilitiesApi.getActiveVulnerabilities,
    dispatch,
    isMobile,
    customerId,
    filter: {},
    initialOrderBy,
    page: 'VULNERABILITIES_LIST',
    historyState: state,
  });

  const handleTableClick = React.useCallback(
    (record: VulnerabilitySchema) => {
      const selectedFilterTags = params.filter?.fields?.find(
        (filter) => filter.name === 'tagsAssets',
      );

      const queryString = params?.filter
        ? getQueryString({
            filter: {
              ...params?.filter,
              fields: [...(params.filter?.fields || [])],
            },
            tab: tabsKeys.active,
          })
        : '';

      dispatch(vulnerability.actions.setFilterTags((selectedFilterTags?.value as string[]) || []));

      history.push(`${Routes.VULNERABILITIES_LIST}/${record.id}${queryString}`, {
        backTo: {
          route: `${Routes.VULNERABILITIES_LIST}`,
          search: history.location.search,
          title: `Back to ${backlinkRoutes[Routes.VULNERABILITIES_LIST]}`,
        },
        rootBackTo: historyState?.backTo,
      });
    },
    [dispatch, history, historyState?.backTo, params],
  );

  useEffect(() => {
    dispatch(tagsManagementApi.getTagsList(customerId));
    dispatch(vulnerabilitiesApi.getVulnerabilitiesCategories(customerId));
  }, [dispatch, customerId, selectedTags]);
  useEffect(() => {
    updateQueryParams(params);
    // eslint-disable-next-line
  }, [params]);

  const emptyMessage = (
    <EmptyTablePreview title='No vulnerabilities present' loading={loadingData} />
  );

  return (
    <Row gutter={GUTTER}>
      {!isMobile ? (
        <Col span={FULL_WIDTH_COLS} xs={24} xxl={24}>
          <CustomTable<VulnerabilitySchema, GridRequestParams>
            data={activeVulnerabilities}
            columns={getTableColumns(columns, params.orderBy)}
            params={params}
            setParams={updateParams}
            searchSettings={searchSettings}
            filterFields={filterFields}
            defaultPageSize={defaultPageSize}
            defaultCurrent={defaultCurrent}
            onRowClickHandle={handleTableClick}
            isLoadingContent={loading[vulnerabilitiesApi.getActiveVulnerabilities.typePrefix]}
            emptyMessage={emptyMessage}
          />
        </Col>
      ) : (
        <MobileTableCol span={FULL_WIDTH_COLS} flex='auto'>
          <MobileTable<VulnerabilitySchema, GridRequestParams>
            data={activeVulnerabilities}
            columns={columns}
            params={params}
            setParams={updateParams}
            searchSettings={searchSettings}
            filterFields={filterFields}
            onRowClick={handleTableClick}
            initialOrderBy={initialOrderBy}
            isLoadingContent={loading[vulnerabilitiesApi.getActiveVulnerabilities.typePrefix]}
            emptyMessage={emptyMessage}
          />
        </MobileTableCol>
      )}
    </Row>
  );
};
