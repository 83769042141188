import moment from 'moment';
import React from 'react';
import { getData } from 'pages/VulnerabilityManagement/helpers';
import {
  CertificateStatusSchema,
  DashboardScores,
  VulnerabilityTrendPointSchema,
} from 'services/api';
import { Box } from 'shared';
import { OptionParams } from 'shared/charts';
import { IEChartsOption } from 'shared/charts/interfaces';
import { Theme } from 'styles/theme/types';
import { thirtyDays } from 'app-constants';
import { FailedCertificateStatuses } from 'pages/Process/constants';

export const getHtmlReport = (html: string, styles: string) => {
  return `<!DOCTYPE html>
  <html lang="en">
      <head>
          <meta charset="UTF-8" />
          <title>Monthly Report</title>
          ${styles}
      </head>
      <body>
        ${html}
      </body>
  </html>`;
};

export const getSortedDashboardScores = (scores: DashboardScores): DashboardScores => {
  return scores.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
};

export const getReportProccessStatus = (
  expired: boolean | null,
  isCertificateSoonExpired: boolean,
  status: string,
  theme: Theme,
) => {
  if (!status || FailedCertificateStatuses.includes(status as CertificateStatusSchema)) {
    return (
      <Box color={theme.colorSet.colorCritical} fontWeight={theme.fontWeights.black}>
        Inactive
      </Box>
    );
  }

  if (expired) {
    return (
      <Box color={theme.colorSet.colorCritical} fontWeight={theme.fontWeights.black}>
        Expired
      </Box>
    );
  }

  if (isCertificateSoonExpired) {
    return (
      <Box color={theme.colorSet.colorCritical} fontWeight={theme.fontWeights.black}>
        About to expire
      </Box>
    );
  }

  return (
    <Box color={theme.colorSet.activeGreen} fontWeight={theme.fontWeights.black}>
      Active
    </Box>
  );
};

export const getOption = ({
  values,
  theme,
  height,
  top,
  bottom,
  left,
  right,
}: OptionParams<VulnerabilityTrendPointSchema[]>): IEChartsOption => {
  const [seriesData, xAxisData] = getData(values);
  const color = [theme.colors.blue[600]];

  return {
    grid: {
      left: left || '10px',
      top: top || '35px',
      right,
      bottom: bottom || '10px',
      containLabel: true,
      height: height || '260px',
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, '10%'],
      axisLabel: {
        interval: thirtyDays,
      },
      data: xAxisData.map((value) => moment(Number(value)).format('MMM')),
    },
    yAxis: {
      type: 'value',
      min: 0,
    },
    series: [
      {
        type: 'line',
        name: 'Total',
        data: seriesData,
        showSymbol: seriesData.length <= 1 && seriesData[0] !== 0,
        symbolSize: 6,
      },
    ],
    color,
  };
};
