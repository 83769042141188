import React from 'react';
import { Box, Button, Icon } from 'shared';
import { icons } from 'styles/theme';
import { ActionNames } from 'app-constants';
import { ActionClickHandlersType } from 'services/interfaces';
import { DocumentSchema, SoftwareDocumentSchema } from 'services/api';
import { Popup } from 'pages/Users/components/styled';
import { getCustomMenu } from 'pages/Users/components/UserDetails/helpers';
import { actionsId } from '../constants';

type ActionsPopupWithButtonProps<T> = {
  actionsClickHandlers: ActionClickHandlersType<T>;
  row: DocumentSchema | SoftwareDocumentSchema;
  isFullAccess: boolean;
};

const actionsViewOnly = [
  { name: ActionNames.Download, icon: icons.download, popupId: actionsId.download },
];

const getActionsWithFullAccess = (shared?: boolean) =>
  shared
    ? [...actionsViewOnly]
    : [
        ...actionsViewOnly,
        { name: ActionNames.Delete, icon: icons.delete, popupId: actionsId.delete, isDanger: true },
      ];

export const ActionsPopupWithButton = <T extends DocumentSchema | SoftwareDocumentSchema>({
  actionsClickHandlers,
  row,
  isFullAccess,
}: ActionsPopupWithButtonProps<T>) => {
  const [isVisibleActionsPopup, setIsVisibleActionsPopup] = React.useState(false);

  const handleClickAction = async (popupId: string) => {
    await actionsClickHandlers[popupId as ActionNames](row as T, popupId as ActionNames);
    setIsVisibleActionsPopup(false);
  };

  return (
    <>
      <Box onClick={(event: React.MouseEvent) => event.stopPropagation()}>
        <Button
          className='btn-table-action'
          type='link'
          icon={<Icon component={icons.more_vert} onClick={() => setIsVisibleActionsPopup(true)} />}
        />
      </Box>

      {isVisibleActionsPopup && (
        <Popup
          className='actions-modal'
          title='Actions'
          visible={isVisibleActionsPopup}
          bodyStyle={{ width: '100%' }}
          width={240}
          footer={null}
          onCancel={() => setIsVisibleActionsPopup(false)}
          closable={false}
        >
          {getCustomMenu(
            isFullAccess ? getActionsWithFullAccess(row.shared) : actionsViewOnly,
            handleClickAction,
          )}
        </Popup>
      )}
    </>
  );
};
