import React from 'react';
import { useHistory } from 'react-router';
import { useApp, useCEPlus, useLoading } from 'store';
import { Box, Card } from 'shared';
import { useTheme } from 'styled-components';
import { EChartsEventParams } from '../../../../shared/charts/interfaces';
import { SubTitle } from '../../../../shared/Card/components/SubTitle/SubTitle';
import { SegmentedPie } from '../../../../shared/charts';
import { VulnerabilitiesByDay } from './VulnerabilitiesByDay';
import { VulnerabilityWrapper } from '../../styled';
import {
  getLegendColors,
  getLegendValues,
  subTitleFormatterSum,
} from '../../../PatchManagement/helpers';
import {
  createVulnerabilityList,
  getVulnerabilityDetectionsFilter,
  validateField,
  validateFieldValue,
} from '../../helpers';
import {
  cePlusBackRoute,
  DEFAULT_CVSS_SCORE,
  HIDDEN_FILTER_EMPTY_FIELD,
  IFilterField,
  initialVulnerabilityOrderBy,
  IVulnerabilitiesDateParams,
  tooltipTitles,
} from '../../constants';
import { initialPagination } from '../../../../app-constants';
import { getQueryString } from '../../../../services/api/core/request';
import { Routes } from '../../../../services/entities';
import { isEmptyLegendValuesObject } from '../../../../services/helpers';
import { cyberEssentialPlusApi } from '../../../../store/cyberEssentialPlus/thunks';
import { Filter } from '../../../../services/api';

export const OpenVulnerabilities = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile, isMenuCollapsed } = useApp();
  const loading = useLoading([
    cyberEssentialPlusApi.getLastWeekDetections.typePrefix,
    cyberEssentialPlusApi.getPenultimateWeekDetections.typePrefix,
    cyberEssentialPlusApi.getDetectionsAfterTwoWeeks.typePrefix,
  ]);
  const {
    detectionsLastWeek,
    detectionsPenultimateWeek,
    detectionsAfterTwoWeeks,
    selectedTags,
  } = useCEPlus();

  const legendValues = getLegendValues([
    detectionsLastWeek,
    detectionsPenultimateWeek,
    detectionsAfterTwoWeeks,
  ]);

  const getVulnerabilitiesParams = (
    { fromFirstFound, toFirstFound }: IVulnerabilitiesDateParams,
    tagsList: string[],
  ) => {
    const openVulnerabilitiesFields: IFilterField[] = [
      HIDDEN_FILTER_EMPTY_FIELD as IFilterField,
      {
        name: 'tagsAssets',
        value: tagsList || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
    ];

    return {
      orderBy: initialVulnerabilityOrderBy,
      pagination: initialPagination,
      filter: {
        cvssScore: DEFAULT_CVSS_SCORE,
        fromFirstFound,
        toFirstFound,
        fields: validateField(openVulnerabilitiesFields),
      },
    };
  };

  const redirectToVulnerabilityGrid = React.useCallback(
    (params?: string) => {
      history.push(
        `${Routes.VULNERABILITIES_LIST}${getQueryString({
          ...getVulnerabilitiesParams(getVulnerabilityDetectionsFilter(params), selectedTags),
          tab: 'active',
        })}`,
        { ...cePlusBackRoute, ignoreDefaultBehavior: true },
      );
    },
    [history, selectedTags],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => {
        if (!params.name) {
          return;
        }

        redirectToVulnerabilityGrid(params.name);
      },
    }),
    [redirectToVulnerabilityGrid],
  );

  const vulnerabilitiesColors = [
    theme.colors.green[300],
    theme.colors.yellow[500],
    theme.colors.red[400],
  ];

  const totalSumDetections = Object.values(legendValues).reduce(
    (accumulator: number, value = 0) => accumulator + value,
    0,
  );

  const vulnerabilityDaysList = createVulnerabilityList(
    detectionsLastWeek.count,
    detectionsPenultimateWeek.count,
    detectionsAfterTwoWeeks.count,
    totalSumDetections,
  );

  return (
    <Card
      title='Open vulnerabilities'
      tooltipTitle={tooltipTitles.openVulnerabilities}
      subTitle={
        <SubTitle
          onPress={() => redirectToVulnerabilityGrid()}
          boldText={subTitleFormatterSum(Object.values(legendValues), 1)}
          customText='Total'
        />
      }
      showDivider={false}
      height={theme.sizes.full}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[4]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[3]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getDetectionsAfterTwoWeeks.typePrefix]}
    >
      <VulnerabilityWrapper
        $isEmptyPieChart={isEmptyLegendValuesObject(legendValues)}
        $collapsed={isMenuCollapsed}
      >
        {vulnerabilityDaysList.map((params) => {
          const { total, percent, days, filterName } = params;

          return (
            <Box onClick={() => redirectToVulnerabilityGrid(filterName)} key={days}>
              <VulnerabilitiesByDay total={total} percent={percent} days={days} />
            </Box>
          );
        })}
        {!isEmptyLegendValuesObject(legendValues) && (
          <SegmentedPie
            values={legendValues || {}}
            colors={getLegendColors(legendValues, vulnerabilitiesColors)}
            direction={isMobile ? 'column' : 'row'}
            entitiesName=''
            style={
              isMobile
                ? {
                    height: '165px',
                    width: '400px',
                    marginTop: `-${theme.spacing[2]}`,
                    marginLeft: `-${theme.spacing[2]}`,
                  }
                : {
                    height: '200px',
                    width: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    bottom: '65px',
                  }
            }
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
            onEvents={onEvents}
            isLoading={loading[cyberEssentialPlusApi.getDetectionsAfterTwoWeeks.typePrefix]}
            noLegend
          />
        )}
      </VulnerabilityWrapper>
    </Card>
  );
};
