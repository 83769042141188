import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Card, InnerLayout } from 'shared';
import {
  AppDispatch,
  AppState,
  managedDetectionsResponseApi,
  useCustomer,
  useEvents,
  useLoading,
} from 'store';
import { useRowGap } from 'services/hooks';
import { useLocation } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { RequestStatus } from 'store/loading/slice';
import { Col, Row } from 'antd';
import { getLayoutConfig } from './helpers';
import { EventByName } from './components/EventByName/EventByName';
import { DescriptionItems } from '../AlarmDetails/components/DescriptionItems';
import { ServiceDetails } from './constants';
import { SubTitle } from '../../shared/Card/components/SubTitle/SubTitle';
import { subTitleFormatterSum } from '../PatchManagement/helpers';

export const MonitoredServiceDetails = () => {
  const isMobile = useSelector((state: AppState) => state.app.isMobile);
  const theme = useTheme();
  const history = useHistory();
  const { name: serviceName } = useParams<{ name: string }>();
  const dispatch: AppDispatch = useDispatch();
  const { state: historyState } = useLocation<HistoryState>();
  const loading = useLoading([managedDetectionsResponseApi.getMonitoredServiceDetails.typePrefix]);
  const { gapNumber } = useRowGap();
  const customerId = useCustomer();
  const layoutConfig = getLayoutConfig(historyState);
  const [periodName, setPeriodName] = useState('Month');
  const {
    eventsData: { trendingPercent, allEventsCount = 0 },
  } = useEvents();

  useEffect(() => {
    if (historyState?.data?.name) {
      dispatch(
        managedDetectionsResponseApi.getMonitoredServiceDetails({
          customerId,
          name: historyState?.data?.name,
        }),
      ).then((result) => {
        if (result.meta.requestStatus === RequestStatus.Rejected) {
          history.push(Routes.THREAT_DETECTION);
        }
      });
    }
  }, [dispatch, customerId, historyState?.data?.name, history]);

  const monitoredServiceDetails = useSelector(
    ({ mdr: { monitoredServiceDetails } }: AppState) => monitoredServiceDetails,
  );
  const backLink = {
    route: `${Routes.MONITORED_SERVICE_DETAILS.replace(':name', serviceName)}`,
    title: `Back to ${monitoredServiceDetails.host?.name} service details`,
  };

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={32} md={24} lg={24} xxl={16}>
          <Card
            title='Events over time'
            subTitle={
              <SubTitle
                boldText={subTitleFormatterSum([allEventsCount], 1)}
                lightText={trendingPercent ? `${trendingPercent}%` : ''}
                period={`last ${periodName.toLowerCase()}`}
              />
            }
            showDivider={false}
            headStyles={
              !isMobile
                ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[3], headAlign: 'flex-start' }
                : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
            }
            height={theme.sizes.full}
            cardPadding={`${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes[5]}`}
            showLoader={loading[managedDetectionsResponseApi.getMonitoredServiceDetails.typePrefix]}
          >
            <EventByName
              setPeriodName={setPeriodName}
              eventService={monitoredServiceDetails.host?.name}
            />
          </Card>
        </Col>
        <Col xxl={8}>
          <Card
            height={theme.sizes['56']}
            cardPadding={`${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes[5]}`}
            showLoader={loading[managedDetectionsResponseApi.getMonitoredServiceDetails.typePrefix]}
          >
            <DescriptionItems
              listOfFields={ServiceDetails}
              title=''
              data={monitoredServiceDetails}
              backLink={backLink}
            />
          </Card>
        </Col>
      </Row>
    </InnerLayout>
  );
};
