import React from 'react';
import { Filters } from 'services/api';
import { TableFilters } from 'shared';
import { FilterType } from 'shared/Table/types';
import { FiltersDrawer } from './styled';

export type MobileTableFiltersDrawerProps = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onFiltersChange: (filter: Filters | null) => void;
  filterFields?: FilterType[];
  currentFilter?: Filters | null;
  setMobileResetButtonClick?: (value: boolean) => void;
};

export const MobileTableFiltersDrawer = ({
  visible,
  setVisible,
  onFiltersChange,
  filterFields,
  currentFilter,
  setMobileResetButtonClick,
}: MobileTableFiltersDrawerProps) => {
  return (
    <FiltersDrawer closable={false} visible={visible} width='90%'>
      {filterFields && (
        <TableFilters
          onFiltersChange={onFiltersChange}
          currentFilter={currentFilter}
          filterFields={filterFields}
          setIsDrawerVisible={setVisible}
          isMobile
          setMobileResetButtonClick={setMobileResetButtonClick}
          isDrawerVisible={visible}
        />
      )}
    </FiltersDrawer>
  );
};
