import styled from 'styled-components';
import { Modal } from 'shared/Modal/Modal';
import { Theme } from 'styles/theme/types';
import { breakpoints, fontWeights, getCssVar } from 'styles/theme';

type Props = {
  theme: Theme;
};

export const Popup = styled(Modal)<Props>`
  max-width: 100vw;

  .ant-modal-content {
    .ant-modal-header {
      padding: 20px 60px 20px 32px;

      @media (max-width: ${breakpoints.md}) {
        padding: 20px 60px 20px 20px;
      }

      .ant-modal-title {
        color: ${getCssVar('popup.titleColor')};
        font-weight: ${fontWeights.bold};
      }
    }

    p {
      white-space: pre;
    }

    .ant-modal-body {
      padding: 20px 32px 5px;

      @media (max-width: ${breakpoints.md}) {
        padding: 20px 20px 5px;
      }

      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }

    .ant-modal-footer {
      padding: 20px 32px;

      @media (max-width: ${breakpoints.md}) {
        padding: 20px;
      }
    }
  }
`;
