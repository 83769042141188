/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { CustomerId } from '../models/CustomerId';
import type { FilteredUsersResponse } from '../models/FilteredUsersResponse';
import type { Filters } from '../models/Filters';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { UpdateUserPermissionRequest } from '../models/UpdateUserPermissionRequest';
import type { UserDetailsResponse } from '../models/UserDetailsResponse';
import type { UserPermissionsResponse } from '../models/UserPermissionsResponse';
import { request as __request } from '../core/request';

export class AdminUsersService {
  /**
   * Users list
   * Get filtered, searchable and sortable list of users
   * @returns FilteredUsersResponse Filtered, searchable and sortable list of users
   * @throws ApiError
   */
  public static async getUsers({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<FilteredUsersResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/users`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when get users list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * User details
   * Get user details
   * @returns UserDetailsResponse User details
   * @throws ApiError
   */
  public static async getUserDetails({
    customerId,
    userId,
  }: {
    /** Customer id **/
    customerId: string;
    /** User id **/
    userId: string;
  }): Promise<UserDetailsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/user/${userId}/details`,
      errors: {
        400: `An error message when get user details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * User permissions
   * Get user permissions
   * @returns UserPermissionsResponse User details
   * @throws ApiError
   */
  public static async getUserPermissions({
    customerId,
    userId,
  }: {
    /** Customer id **/
    customerId: string;
    /** User id **/
    userId: string;
  }): Promise<UserPermissionsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/user/${userId}/permissions`,
      errors: {
        400: `An error message when get user list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * User permissions
   * Update user permissions
   * @returns SuccessResponse Update user permissions
   * @throws ApiError
   */
  public static async updateUserPermissions({
    customerId,
    userId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** User id **/
    userId: string;
    /** Update permissions **/
    requestBody?: UpdateUserPermissionRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'PUT',
      path: `/customer/${customerId}/user/${userId}/permissions`,
      body: requestBody,
      errors: {
        400: `An error message when get user permissions`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Create user
   * Create user in Ivanti
   * @returns SuccessResponse Create user
   * @throws ApiError
   */
  public static async createUser({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** Name, surname, email, phone **/
    requestBody?: CreateUserRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/create-user`,
      body: requestBody,
      errors: {
        400: `An error message when create user`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Update user details
   * Update user details in Ivanti
   * @returns SuccessResponse Update user details
   * @throws ApiError
   */
  public static async updateUser({
    customerId,
    userId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** User id **/
    userId: string;
    /** Name, surname, phone, email **/
    requestBody?: CreateUserRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/user/${userId}/update-user`,
      body: requestBody,
      errors: {
        400: `An error message when update user`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Lock user
   * Update user as locked
   * @returns SuccessResponse Lock user
   * @throws ApiError
   */
  public static async lockUser({
    customerId,
    userId,
    comments,
  }: {
    /** Customer id **/
    customerId: string;
    /** User id **/
    userId: string;
    /** Commets parameter **/
    comments?: string;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/user/${userId}/lock`,
      query: {
        comments: comments,
      },
      errors: {
        400: `An error message when lock user`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Unlock user
   * Update user as unlocked
   * @returns SuccessResponse Unlock user
   * @throws ApiError
   */
  public static async unlockUser({
    customerId,
    userId,
  }: {
    /** Customer id **/
    customerId: string;
    /** User id **/
    userId: string;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/user/${userId}/unlock`,
      errors: {
        400: `An error message when lock user`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete user
   * Update user as deleted
   * @returns SuccessResponse Delete user
   * @throws ApiError
   */
  public static async deleteUser({
    customerId,
    userId,
    comments,
  }: {
    /** Customer id **/
    customerId: string;
    /** User id **/
    userId: string;
    /** Commets parameter **/
    comments?: string;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/user/${userId}`,
      query: {
        comments: comments,
      },
      errors: {
        400: `An error message when lock user`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Reset TwoFA fro user
   * Delete TwoFA for user. Update flags and connected fields
   * @returns SuccessResponse Reset TwoFA for user
   * @throws ApiError
   */
  public static async resetUserTwofa({
    customerId,
    userId,
  }: {
    /** Customer id **/
    customerId: string;
    /** User id **/
    userId: string;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/user/${userId}/reset-user-twofa`,
      errors: {
        400: `An error message when lock user`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
