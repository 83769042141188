import React from 'react';
import { IconColoring } from 'pages/PenetrationTesting/components/IconColoring';
import { ColumnsType } from 'antd/lib/table';
import { Box } from 'shared';
import { getFormattedDate, getRenderValue } from 'services/helpers';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { ColumnKey, TableTitles } from '../constants';
import { FindingsDataRow } from '../types';

export const columns: ColumnsType<FindingsDataRow> = [
  {
    key: ColumnKey.Vulnerability,
    title: TableTitles.Vulnerability,
    dataIndex: ColumnKey.Vulnerability,
    render: (text: string) => <>{getRenderValue(text, NO_VALUE_LABEL)}</>,
    sorter: false,
  },
  {
    key: ColumnKey.Severity,
    title: TableTitles.Severity,
    dataIndex: ColumnKey.Severity,
    render: (text: string) => <>{getRenderValue(text)}</>,
    showSorterTooltip: false,
    sorter: true,
  },
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    render: (text: string) =>
      !text ? (
        emptyDataString
      ) : (
        <>
          <IconColoring status={text} />
          <span>{text}</span>
        </>
      ),
    showSorterTooltip: false,
    sorter: true,
  },
  {
    key: ColumnKey.Test,
    title: TableTitles.Test,
    dataIndex: ColumnKey.Test,
    render: (text: string) => <>{getRenderValue(text)}</>,
    sorter: false,
  },
  {
    key: ColumnKey.StartDate,
    title: TableTitles.StartDate,
    dataIndex: ColumnKey.StartDate,
    render: (date: number) => <Box w='160px'>{getFormattedDate(date)}</Box>,
    showSorterTooltip: false,
    sorter: true,
    width: '160px',
    className: 'right-head-title',
    align: 'right',
  },
  {
    key: ColumnKey.EndDate,
    title: TableTitles.EndDate,
    dataIndex: ColumnKey.EndDate,
    width: '160px',
    render: (date: number) => <Box w='160px'>{getFormattedDate(date)}</Box>,
    showSorterTooltip: false,
    sorter: true,
    align: 'right',
    className: 'right-head-title',
  },
];
