/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserSchema = {
  id?: string;
  name?: string;
  insensitiveName?: string;
  email?: string;
  status?: UserSchema.status;
  type?: UserSchema.type;
  comments?: string;
  updatedDate?: number;
};

export namespace UserSchema {
  export enum status {
    ACTIVE = 'Active',
    AWAITING_FIRST_LOGIN = 'Awaiting first login',
    ACCOUNT_LOCKED = 'Account locked',
  }

  export enum type {
    ADMINISTRATOR = 'Administrator',
    USER = 'User',
    AUTHORISED_CONTACT = 'Authorised Contact',
  }
}
