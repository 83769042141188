import React from 'react';
import { AlarmDetailsResponse, EventServiceDetailsResponse } from 'services/api';
import { Descriptions } from 'pages/CmdbHostDetails/components/AssetDetails/styled';
import { commonDataMapper } from '../helpers';
import { TBackLink } from '../types';
import { DescriptionItem } from './styled';

interface IDescriptionItem {
  title: string;
  listOfFields: Record<string, string>;
  data: AlarmDetailsResponse | EventServiceDetailsResponse | Record<string, string>;
  backLink?: TBackLink;
  hasPermission?: boolean;
}

export const DescriptionItems = ({
  title,
  listOfFields,
  data,
  backLink,
  hasPermission = true,
}: IDescriptionItem) => {
  return (
    <Descriptions title={title} column={1}>
      {Object.entries(listOfFields).map(([property, label]) => {
        const descriptionValue = commonDataMapper[property]
          ? commonDataMapper[property](data[property], backLink, hasPermission)
          : data[property];

        return (
          <DescriptionItem
            label={label as string}
            key={label as string}
            contentStyle={{ display: 'block' }}
          >
            {descriptionValue || '-'}
          </DescriptionItem>
        );
      })}
    </Descriptions>
  );
};
