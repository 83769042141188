import { IEChartsOption } from 'shared/charts/interfaces';
import { Values } from 'shared/charts/types';
import { Theme } from 'styles/theme/types';

export const getOption = (
  values: Values,
  theme: Theme,
  hasClickHandler: boolean,
): IEChartsOption => {
  const BAR_WIDTH = 90;
  const seriesBasics = {
    type: 'bar',
    barWidth: BAR_WIDTH,
    animation: true,
    stack: 'total',
    cursor: hasClickHandler ? 'pointer' : 'auto',
  };

  return {
    xAxis: {
      data: [''],
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      min: 0,
      axisLabel: {
        fontSize: theme.sizes['3.4'],
        color: theme.colorSet.charts.axisLabel,
      },
    },
    grid: {
      left: 40,
      right: 0,
      top: 10,
      height: 280,
    },
    legend: { show: false },
    series: [
      {
        ...seriesBasics,
        name: 'used',
        data: [
          {
            value: values.used,
            itemStyle: {
              color: theme.colorSet.pieChart.CurrentRefresherTestsIgnored,
            },
            cursor: 'default',
          },
        ],
      },
      {
        ...seriesBasics,
        name: 'free',
        data: [
          {
            value: values.free,
            itemStyle: { color: theme.colors.blue[50] },
            cursor: 'default',
          },
        ],
      },
    ],
  };
};
