import styled from 'styled-components';
import { Box, TableColumn as SharedTableColumn } from 'shared';
import { Descriptions as AntdDescriptions } from 'antd';
import { Theme } from 'styles/theme/types';
import { breakpoints, getCssVar } from 'styles/theme';

export const TableColumn = styled(SharedTableColumn)`
  .ant-descriptions-item-label {
    font-weight: ${getCssVar('accentTableColumn')};
    font-size: 15px;
    width: 45%;
    color: ${getCssVar('textColor')};
    @media (max-width: ${breakpoints.md}) {
      width: 25%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 30%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 40%;
    }
  }

  .ant-descriptions-title {
    margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing[6]};
  }

  .ant-descriptions-item-content {
    width: 55%;
    color: ${getCssVar('textColor')};
    @media (max-width: ${breakpoints.md}) {
      width: 75%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 70%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 60%;
    }
  }

  .ant-descriptions-item-conteiner {
    display: flex;
  }
`;

type DescriptionsProps = { theme: Theme; isLast?: boolean };

export const Descriptions = styled(AntdDescriptions)<DescriptionsProps>`
  .ant-descriptions-header {
    margin-bottom: ${({ theme }: DescriptionsProps) => theme.spacing[7]};

    .ant-descriptions-title {
      color: ${getCssVar('textColor')};
      font-size: ${({ theme }: DescriptionsProps) => theme.spacing[4]};
    }
  }

  .ant-descriptions-row {
    .ant-descriptions-item {
      padding: ${({ theme }: DescriptionsProps) =>
        `${theme.sizes[0]} ${theme.sizes[0]} ${theme.sizes[6]}`};

      .ant-descriptions-item-label {
        width: ${({ theme }: DescriptionsProps) => theme.sizes[36]};
        align-items: center;
        font-weight: ${getCssVar('filterLabelWeight')};
        color: ${getCssVar('textColor')};

        &:after {
          display: none;
        }
      }
    }

    &:last-child {
      .ant-descriptions-item {
        padding-bottom: ${({ theme, isLast }: DescriptionsProps) =>
          !isLast ? theme.sizes[10] : theme.sizes[0]};
      }
    }
  }
`;

export const LimitTagsMessage = styled(Box)`
  background: ${getCssVar('tagAssets.limit.bg')};
  color: ${getCssVar('tagAssets.limit.color')};
  display: flex;
  font-size: ${({ theme }) => theme.sizes['3.6']};
  font-size: ${({ theme }) => theme.sizes['3.6']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin: 0 5px 22px 5px;
  padding: 10px;
`;
