import {
  ANDROID_AUTHY_URL,
  ANDROID_GOOGLE_AUTHENTICATOR_URL,
  IOS_AUTHY_URL,
  IOS_GOOGLE_AUTHENTICATOR_URL,
} from 'app-constants';
import * as React from 'react';
import { Box } from 'shared';
import { fontWeights, sizes } from 'styles/theme';
import { TwoFAStep } from '../TwoFAStep';

export const FirstStep = ({ isMobile }: { isMobile: boolean }) => (
  <TwoFAStep
    title={
      <Box fontSize={sizes[4]} fontWeight={fontWeights.bold}>
        Step 1. Get the app
      </Box>
    }
    description={
      <>
        <Box whiteSpace={isMobile ? undefined : 'nowrap'}>
          Download and install Google Authenticator (
          <a rel='noreferrer' href={IOS_GOOGLE_AUTHENTICATOR_URL} target='_blank'>
            iOS&nbsp;
          </a>
          or&nbsp;
          <a rel='noreferrer' href={ANDROID_GOOGLE_AUTHENTICATOR_URL} target='_blank'>
            Android
          </a>
          )
        </Box>
        or Authy (
        <a rel='noreferrer' href={IOS_AUTHY_URL} target='_blank'>
          iOS&nbsp;
        </a>
        or&nbsp;
        <a rel='noreferrer' href={ANDROID_AUTHY_URL} target='_blank'>
          Android
        </a>
        ).
      </>
    }
  />
);
