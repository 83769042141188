import React from 'react';
import { SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Switch } from 'shared';
import { FilterType } from 'shared/Table/types';
import { Filter } from 'services/api';
import { FilterRadioContainer } from './styled';

export type TableFiltersRadioProps = SelectProps<SelectValue> &
  FilterType & {
    value?: string[] | string;
    onFilterChange: (field: string, value: string[] | string, type: Filter.type) => void;
  };

export const TableFilterRadio = ({
  label,
  field,
  onFilterChange,
  filterType,
  loading,
  count = 0,
  value,
}: TableFiltersRadioProps) => {
  const MAX_COUNT_RADIO_FILTER = 9;
  const counter = count > MAX_COUNT_RADIO_FILTER ? '9+' : count;

  const handleSelectChange = React.useCallback(
    (selectValue: boolean) => {
      const radioButtonOn = selectValue;

      if (radioButtonOn) {
        onFilterChange(field || label?.toLowerCase(), `${!selectValue}`, filterType);
      } else {
        onFilterChange(field || label?.toLowerCase(), '', filterType);
      }
    },
    [onFilterChange, field, label, filterType],
  );

  return (
    <FilterRadioContainer>
      <Switch
        size='small'
        onChange={handleSelectChange}
        checked={value === 'false'}
        loading={loading}
      />
      <label htmlFor={`id-${label}`}>
        {label} ({counter})
      </label>
    </FilterRadioContainer>
  );
};
