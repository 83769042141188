import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { Box, Card } from 'shared/index';
import { CardBody } from 'shared/Card/components/styled';
import { InputLabel } from '../../pages/SecuritySettings/styled';
import { getCssVar } from '../../styles/theme';

type Props = { theme: Theme; firstRowBold?: boolean };

export const Summary = styled.h4`
  font-size: ${({ theme }: Props) => theme.sizes[4]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }: Props) => theme.spacing[1]};
`;
export const StyledCard = styled(Card)`
  ${CardBody} {
    margin-bottom: ${({ theme }: Props) => theme.spacing['1.5']};
  }
`;

export const EditorCustomLabel = styled(InputLabel)`
  .ant-form-item-explain-error {
    display: none;
  }
`;

interface WysiwygProps {
  isError?: boolean;
}

export const EditorWrapper = styled.div<WysiwygProps>`
  margin-bottom: 10px;
  max-width: 700px;

  .ck-editor {
    border: ${({ isError }: WysiwygProps) =>
      isError ? `2px solid ${getCssVar('errorColor')}` : 'none'};
  }
`;

export const CounterWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
`;

export const CounterInner = styled(Box)`
  color: ${getCssVar('textColor')};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  opacity: 50%;
`;

export const MessageErrorWrapper = styled(Box)`
  color: ${getCssVar('errorColor')};
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 10px;

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.xs}) {
    flex-direction: column;
  }
`;
