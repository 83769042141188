import { useCallback, useEffect } from 'react';
import { Icon } from 'shared';
import { icons, spacing } from 'styles/theme';
import { Routes } from 'services/entities';
import { backlinkRoutes } from 'app-constants/routes';
import { useDispatch } from 'react-redux';
import { IBackLinkProps, routes, useApp, useRoutes } from 'store';
import { StyledLink } from './styled';

export const BackLink = ({ onClick, ...backLinkProps }: IBackLinkProps) => {
  const dispatch = useDispatch();
  const { routesList } = useRoutes();
  const isPreferRoot = backLinkProps.preferRoot || false;
  const lastRoute = [...routesList].pop();
  const { isMobile } = useApp();

  useEffect(() => {
    dispatch(routes.actions.addRoute(backLinkProps));
  }, [dispatch, backLinkProps]);

  const onClickHandler = useCallback(() => dispatch(routes.actions.removeLast()), [dispatch]);

  if (
    !lastRoute ||
    lastRoute.historyState?.shouldResetHistory ||
    (!lastRoute.historyState && !lastRoute.defaultRoute)
  ) {
    return null;
  }

  const { historyState, defaultRoute, defaultTitle, mainTitle } = lastRoute as IBackLinkProps;

  const backTo = isPreferRoot
    ? historyState?.rootBackTo || historyState?.backTo
    : historyState?.backTo;

  return (
    <StyledLink
      to={{
        pathname: backTo?.route || defaultRoute || Routes.DASHBOARD,
        search: backTo?.search,
        state: {
          ...historyState,
          backTo,
        },
      }}
      onClick={onClick || onClickHandler}
      style={isMobile ? { marginBottom: '15px' } : {}}
    >
      <Icon component={icons.arrow_left_alt} height={spacing['6.9']} />
      {mainTitle || backTo?.title || `Back to ${defaultTitle || backlinkRoutes[Routes.DASHBOARD]}`}
      {backTo?.id}
    </StyledLink>
  );
};
