import { Typography } from 'antd';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useScreenSizes } from 'services/hooks/useScreenSizes';
import { Box } from 'shared';
import { useApp } from 'store';
import { useTheme } from 'styled-components';

type Props = {
  title: string | JSX.Element;
  description: string | JSX.Element | string[];
  isLast?: boolean;
};

export const TwoFAStep = ({ title, description, isLast, children }: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const [isDesktop] = useScreenSizes();
  const { isMobile } = useApp();
  const stepHeaderProps = {
    fontSize: theme.colorSet.login.titleFontSize,
    fontWeight: theme.colorSet.login.titleFontWeight,
  };
  const textColor = !isDesktop ? theme.colors.white : theme.colorSet.textColor;

  return (
    <Box pb={isLast ? undefined : theme.spacing[5]}>
      <Typography.Paragraph>
        <Box {...stepHeaderProps} color={isMobile ? theme.colors.white : textColor}>
          {title}
        </Box>
        {Array.isArray(description) ? (
          description.map((line) => (
            <React.Fragment key={line}>
              <Box color={textColor}>{line}</Box>
            </React.Fragment>
          ))
        ) : (
          <Box color={textColor}>{description}</Box>
        )}
        <Box color={textColor}>{children}</Box>
      </Typography.Paragraph>
    </Box>
  );
};
