import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SupportRequestsResponse,
  SupportRequestDetailsResponse,
  CreateSupportRequestResponse,
  ExportSupportRequestsResponse,
  SecurityIncidentsTrendResponse,
} from 'services/api';
import { supportRequestsApi } from './thunks';

type SupportRequestsState = {
  data: SupportRequestsResponse;
  securityIncidentsStress?: SecurityIncidentsTrendResponse;
  requestDetails?: SupportRequestDetailsResponse;
  newSupportRequest: CreateSupportRequestResponse;
  csv: ExportSupportRequestsResponse;
};

const initialState: SupportRequestsState = {
  data: [] as SupportRequestsResponse,
  securityIncidentsStress: undefined,
  requestDetails: {} as SupportRequestDetailsResponse,
  newSupportRequest: {} as CreateSupportRequestResponse,
  csv: {} as ExportSupportRequestsResponse,
};

export const supportRequests = createSlice({
  name: 'supportRequests',
  initialState,
  reducers: {},
  extraReducers: {
    [supportRequestsApi.supportTableContent.fulfilled.toString()]: (
      state: SupportRequestsState,
      action: { payload: SupportRequestsResponse },
    ) => {
      state.data = action.payload;
    },
    [supportRequestsApi.supportTableContent.rejected.toString()]: (state: SupportRequestsState) => {
      state.data = initialState.data;
    },
    [supportRequestsApi.supportRequestDetails.fulfilled.toString()]: (
      state: SupportRequestsState,
      action: { payload: SupportRequestDetailsResponse },
    ) => {
      state.requestDetails = action.payload;
    },
    [supportRequestsApi.supportRequestDetails.rejected.toString()]: (
      state: SupportRequestsState,
    ) => {
      state.requestDetails = undefined;
    },
    [supportRequestsApi.createNewSupportRequest.fulfilled.toString()]: (
      state: SupportRequestsState,
      action: { payload: CreateSupportRequestResponse },
    ) => {
      state.newSupportRequest = action.payload;
    },
    [supportRequestsApi.getSecurityIncidentsStress.fulfilled.toString()]: (
      state: SupportRequestsState,
      action: PayloadAction<SecurityIncidentsTrendResponse>,
    ) => {
      state.securityIncidentsStress = action.payload;
    },
    [supportRequestsApi.getSecurityIncidentsStress.rejected.toString()]: (
      state: SupportRequestsState,
    ) => {
      state.securityIncidentsStress = undefined;
    },
    [supportRequestsApi.exportCSV.fulfilled.toString()]: (
      state: SupportRequestsState,
      action: { payload: ExportSupportRequestsResponse },
    ) => {
      state.csv = action.payload;
    },
  },
});
