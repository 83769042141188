/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DPLDocumentCategory {
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5',
  C6 = 'C6',
  C7 = 'C7',
  C8 = 'C8',
  C9 = 'C9',
  C10 = 'C10',
}
