export const TitleTooltipContent = () => (
  <p>
    List of Assets that have been discovered within your environment, either by an EDR agent,
    Vulnerability agent or Network scan.
  </p>
);

export const TitleTooltipContentAgedHosts = () => (
  <>
    <p>
      List of Assets that have been discovered within your environment, but that have not recently
      checked in.
    </p>
  </>
);
