import moment from 'moment';
import React, { createRef, useCallback, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  cmdbApi,
  cybersafetyApi,
  dashboardApi,
  documentsApi,
  MessageType,
  monthlyReportApi,
  processApi,
  supportRequestsApi,
  useApp,
  useCmdb,
  useCustomer,
  useCustomerName,
  useCybersafety,
  useDashboard,
  useDocuments,
  useLoading,
  useMonthlyReport,
  useProcess,
  useSupportRequests,
} from 'store';
import { InnerLayout } from 'shared';
import { Col, Row } from 'antd';
import { useRowGap } from 'services/hooks/useRowGap';
import {
  BoostScoreItemSchema,
  MonthlyReportResponse,
  SeverityLevel,
  Timestamp,
} from 'services/api';
import tz from 'moment-timezone';
import { saveAs } from 'file-saver';
import { IDate } from 'services/interfaces';
import { useGetPermissions } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { ServerStyleSheet, useTheme } from 'styled-components';
import { Modal } from 'pages/Process/components';
import { useSuperModal } from 'pages/Process/useSuperModal';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { RequestStatus } from 'store/loading/slice';
import { showError, showMessage } from 'services/helpers';
import { DataForm } from 'pages/Process/types';
import { getFilteredCertificatesScore, getLayoutConfig, getStressScores } from './helpers';
import { Process } from './components/Process/Process';
import { LatestReports } from './components/LatestReports/LatestReports';
import { ColWithCustomBorderBottom, HiddenContainer } from './styled';
import { People } from './components/People';
import { BoostScore } from './components/BoostScore/BoostScore';
import { Technology } from './components/Technology/Technology';
import { PopUp } from './components/PopUp';
import { StressScore } from './components/StressScore/StressScore';
import { PopupConfig, TotalStressScore } from './types';
import { getScoreAnnotations, THIRTEEN_MONTHS, topColResponsiveProps } from './constants';
import { getHtmlReport } from './components/Report/Norm/helpers';
import { ReportModal } from './components/ReportModa/ReportModal';
import { cyberResilienceScoreTrendsApi } from '../../store/cyberResilienceScoreTrends/thunks';
import { getReportComponent } from './components/Report/reportFactory';

const sheet = new ServerStyleSheet();

export const Dashboard = () => {
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const breakpoints = useBreakpoint();
  const customer = useCustomer();

  const { isModalShown, isFormShown, hideModal, showSuperModal, title } = useSuperModal();
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const { gapNumber } = useRowGap();
  const { isMobile, currentCustomerName, themeName } = useApp();
  const permissions = useGetPermissions([
    PermissionOptions.Process,
    PermissionOptions.Report,
    PermissionOptions.CybersafetyAndPhishing,
    PermissionOptions.CMDB,
    PermissionOptions.SupportRequests,
  ]);
  const reportRef = createRef<HTMLDivElement>();
  const [reportDate, setReportDate] = useState<IDate>({ startDate: 0, endDate: 0 });

  const isProcessBlockShown =
    permissions.process === PermissionsLevel.Full ||
    permissions.process === PermissionsLevel.ViewOnly;

  const loading = useLoading([
    processApi.updateBoostScore.typePrefix,
    processApi.getBoostScores.typePrefix,
    cmdbApi.getIsolatedDevices.typePrefix,
    cmdbApi.getHostsTrendsByCriticalities.typePrefix,
    processApi.getAllCertificates.typePrefix,
    supportRequestsApi.getSecurityIncidentsStress.typePrefix,
    cybersafetyApi.getIncompleteTraining.typePrefix,
    documentsApi.getLatestReports.typePrefix,
    dashboardApi.getPeopleScore.typePrefix,
    dashboardApi.getBoostScore.typePrefix,
    dashboardApi.getProcessScore.typePrefix,
    dashboardApi.getTechnologyScore.typePrefix,
    monthlyReportApi.getMonthlyReport.typePrefix,
    cyberResilienceScoreTrendsApi.exportCyberResilienceScoreTrends.typePrefix,
  ]);

  const { incompleteTraining } = useCybersafety();
  const { boostScore, processScore, peopleScore, technologyScore } = useDashboard();
  const { isolatedDevices, hostsCountBySeverity } = useCmdb();
  const { securityIncidentsStress } = useSupportRequests();
  const { boostScores, certificates } = useProcess();
  const { latestReports, downloading } = useDocuments();

  const customerName = useCustomerName();
  const annotations = getScoreAnnotations(customerName || '');

  const [totalStressScore, setTotalStressScore] = useState<TotalStressScore[]>([]);
  const [preparedDataForm, setPreparedDataForm] = useState({} as DataForm);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupConfig, setPopupConfig] = useState<PopupConfig>(null);

  const { data: reportData } = useMonthlyReport();
  const [isReportModalShown, setIsReportModalShown] = useState(false);

  const layout = isMobile ? 'vertical' : 'horizontal';

  React.useEffect(() => {
    dispatch(dashboardApi.getBoostScore({ customerId: customer }));
    dispatch(dashboardApi.getPeopleScore({ customerId: customer }));
    dispatch(dashboardApi.getProcessScore({ customerId: customer }));
    dispatch(dashboardApi.getTechnologyScore({ customerId: customer }));
    dispatch(cmdbApi.getIsolatedDevices({ customerId: customer }));
    dispatch(
      cmdbApi.getHostsTrendsByCriticalities({
        customerId: customer,
        criticalities: [SeverityLevel._4, SeverityLevel._5],
      }),
    );
    dispatch(processApi.getBoostScores({ customerId: customer }));
    dispatch(processApi.getAllCertificates({ customerId: customer }));
    dispatch(cybersafetyApi.getIncompleteTraining(customer));
    dispatch(supportRequestsApi.getSecurityIncidentsStress(customer));
    dispatch(documentsApi.getLatestReports(customer));
  }, [dispatch, customer]);

  const openFormWithPreparedData = React.useCallback(
    (title: string, date?: number, vendor?: string) => {
      showSuperModal(true, title);
      setPreparedDataForm({ title, date, vendor });
    },
    [showSuperModal],
  );

  const handleDownloadReportClick = React.useCallback(
    async (startDate: Timestamp, endDate: Timestamp) => {
      setReportDate({ startDate, endDate });
      const result = await dispatch(
        monthlyReportApi.getMonthlyReport({
          customerId: customer,
          startDate,
          endDate,
          timezone: tz.tz.guess(),
        }),
      );

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        setIsReportModalShown(false);
        setIsDownloadingReport(false);
        showError();

        return;
      }

      const Component = getReportComponent(themeName);

      ReactDomServer.renderToString(
        sheet.collectStyles(
          <Component
            data={result.payload as MonthlyReportResponse}
            reportDate={reportDate}
            companyName={currentCustomerName || ''}
            processPermission={permissions.process || ''}
            theme={theme}
            onChartsReady={() => {}}
            scoreAnnotations={!isMobile ? annotations : undefined}
            themeName={themeName}
          />,
        ),
      );
      setIsDownloadingReport(true);
    },
    [
      dispatch,
      customer,
      reportDate,
      currentCustomerName,
      permissions.process,
      theme,
      isMobile,
      annotations,
      themeName,
    ],
  );

  const downloadScoreTrendsReport = useCallback(async () => {
    const result = await dispatch(
      cyberResilienceScoreTrendsApi.exportCyberResilienceScoreTrends({
        customerId: customer,
        startDate: moment()
          .subtract(THIRTEEN_MONTHS, 'months')
          .startOf('day')
          .startOf('month')
          .valueOf(),
        endDate: moment().subtract(1, 'month').endOf('month').valueOf(),
        timezone: tz.tz.guess(),
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      showError();

      return;
    }

    saveAs(
      new Blob([result.payload], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      `${moment().format('YYYYMMDD')} – smartbloc live trends.xlsx`,
    );
  }, [customer, dispatch]);

  const layoutConfig = getLayoutConfig({
    downloadReport: () => setIsReportModalShown(true),
    downloadScoreTrendsReport,
    hasReportPermission:
      permissions.report === PermissionsLevel.ViewOnly ||
      permissions.report === PermissionsLevel.Full,
    loading: loading[cyberResilienceScoreTrendsApi.exportCyberResilienceScoreTrends.typePrefix],
  });

  const dowloadReport = useCallback(async () => {
    const styles = sheet.getStyleTags();
    const result = await dispatch(
      monthlyReportApi.exportMonthlyReport({
        themeName,
        customerId: customer,
        requestBody: getHtmlReport(reportRef?.current?.innerHTML || '', styles),
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      setIsReportModalShown(false);
      setIsDownloadingReport(false);
      showError();

      return;
    }

    saveAs(
      new Blob([result.payload], {
        type: 'text/plain;charset=utf-8',
      }),
      `${currentCustomerName} ${moment(reportDate.startDate).format('YYYY-MM')} Report.pdf`,
    );
    setIsReportModalShown(false);
    setIsDownloadingReport(false);
  }, [dispatch, themeName, customer, reportRef, currentCustomerName, reportDate.startDate]);

  const handleBoostScoreUpdate = React.useCallback(
    async (vendor: string | undefined, product: string | undefined, expiry: number | undefined) => {
      const result = await dispatch(
        processApi.updateBoostScore({
          customerId: customer,
          requestBody: { vendor, product, expiry },
        }),
      );

      if (result.meta.requestStatus === RequestStatus.Rejected) {
        showError();

        return;
      }

      setIsPopupVisible(false);
      showMessage(
        { content: `Product ${popupConfig?.title} successfully updated.` },
        MessageType.Success,
      );
      dispatch(processApi.getBoostScores({ customerId: customer }));
    },
    [dispatch, customer, popupConfig?.title],
  );

  React.useEffect(() => {
    setTotalStressScore(
      getStressScores({
        isolatedDevices:
          permissions.cmdb === PermissionsLevel.NoAccess ? undefined : isolatedDevices,
        hostsCountBySeverity:
          permissions.cmdb === PermissionsLevel.NoAccess ? undefined : hostsCountBySeverity,
        certificates:
          permissions.process === PermissionsLevel.NoAccess
            ? undefined
            : getFilteredCertificatesScore(certificates),
        securityIncidents:
          permissions.securityIncidents === PermissionsLevel.NoAccess
            ? undefined
            : securityIncidentsStress,
        incompleteTraining:
          permissions.cybersafetyAndPhishing === PermissionsLevel.NoAccess
            ? undefined
            : incompleteTraining,
      }),
    );
  }, [
    isolatedDevices,
    hostsCountBySeverity,
    certificates,
    securityIncidentsStress,
    incompleteTraining,
    permissions.process,
    permissions.cmdb,
    permissions.securityIncidents,
    permissions.cybersafetyAndPhishing,
  ]);

  const Component = getReportComponent(themeName);

  return (
    <InnerLayout {...layoutConfig}>
      <Row justify='center'>
        <Col xs={24} xl={24} xxl={12} sm={24} md={24} lg={24}>
          {isDownloadingReport && (
            <HiddenContainer ref={reportRef}>
              <Component
                data={reportData}
                companyName={currentCustomerName || ''}
                reportDate={reportDate}
                processPermission={permissions.process || ''}
                theme={theme}
                scoreAnnotations={!isMobile ? annotations : undefined}
                onChartsReady={dowloadReport}
                themeName={themeName}
              />
            </HiddenContainer>
          )}
        </Col>
      </Row>

      <Row gutter={[gapNumber, gapNumber]} data-testid='cyber-resilience-score'>
        <ColWithCustomBorderBottom span={24} $isMobile={isMobile}>
          <StressScore
            data={totalStressScore}
            layout={layout}
            score={
              (peopleScore?.points || 0) +
              (technologyScore?.points || 0) +
              (processScore?.points || 0) +
              (boostScore?.points || 0)
            }
            isLoading={
              loading[cmdbApi.getIsolatedDevices.typePrefix] ||
              loading[cmdbApi.getHostsTrendsByCriticalities.typePrefix] ||
              loading[processApi.getAllCertificates.typePrefix] ||
              loading[supportRequestsApi.getSecurityIncidentsStress.typePrefix] ||
              loading[cybersafetyApi.getIncompleteTraining.typePrefix]
            }
            scoreLoading={
              loading[dashboardApi.getBoostScore.typePrefix] ||
              loading[dashboardApi.getPeopleScore.typePrefix] ||
              loading[dashboardApi.getProcessScore.typePrefix] ||
              loading[dashboardApi.getTechnologyScore.typePrefix]
            }
          />
        </ColWithCustomBorderBottom>
        {isProcessBlockShown && (
          <ColWithCustomBorderBottom span={24} $isMobile={isMobile} data-testid='process-score'>
            <Process
              layout={layout}
              score={processScore?.score}
              openFormWithPreparedData={openFormWithPreparedData}
              onSelfSertify={showSuperModal}
              certificates={certificates}
              isLoading={loading[processApi.getAllCertificates.typePrefix]}
              scoreLoading={loading[dashboardApi.getProcessScore.typePrefix]}
            />
          </ColWithCustomBorderBottom>
        )}

        <ColWithCustomBorderBottom span={24} $isMobile={isMobile} data-testid='people-score'>
          <People
            isEmpty={permissions.cybersafetyAndPhishing === PermissionsLevel.NoAccess}
            direction={breakpoints.xxl ? 'column' : 'row'}
            isLoading={loading[cybersafetyApi.getIncompleteTraining.typePrefix]}
            score={peopleScore?.score}
            scoreLoading={loading[dashboardApi.getPeopleScore.typePrefix]}
          />
        </ColWithCustomBorderBottom>
        <ColWithCustomBorderBottom span={24} $isMobile={isMobile} data-testid='technology-score'>
          <Technology
            customerId={customer}
            score={technologyScore?.score}
            scoreLoading={loading[dashboardApi.getTechnologyScore.typePrefix]}
          />
        </ColWithCustomBorderBottom>
        <ColWithCustomBorderBottom
          $isMobile={isMobile}
          {...topColResponsiveProps}
          data-testid='boost-score'
        >
          <BoostScore
            boostScores={boostScores}
            score={boostScore?.score}
            isLoading={loading[processApi.getAllCertificates.typePrefix]}
            boostScoreOnClick={(score: BoostScoreItemSchema) => {
              setPopupConfig({
                vendor: score.vendor || '',
                date: score.expirationDate,
                title: score.title || '',
              });
              setIsPopupVisible(true);
            }}
            scoreLoading={loading[dashboardApi.getBoostScore.typePrefix]}
          />
        </ColWithCustomBorderBottom>
        <Col xs={24} xl={24} xxl={12} sm={24} md={24} lg={24}>
          <LatestReports
            data={latestReports}
            isLoading={loading[documentsApi.getLatestReports.typePrefix]}
            customerId={customer}
            downloading={downloading}
          />
        </Col>
      </Row>
      {isPopupVisible && (
        <PopUp
          closePopup={() => {
            setPopupConfig(null);
            setIsPopupVisible(false);
          }}
          isVisible={isPopupVisible}
          loading={loading[processApi.updateBoostScore.typePrefix]}
          popupConfig={popupConfig}
          onOk={handleBoostScoreUpdate}
          isMobile={isMobile}
        />
      )}

      {isReportModalShown && (
        <ReportModal
          isVisible={isReportModalShown}
          closePopup={() => {
            setIsReportModalShown(false);
          }}
          loading={loading[monthlyReportApi.getMonthlyReport.typePrefix] || isDownloadingReport}
          isMobile={isMobile}
          handleOk={handleDownloadReportClick}
        />
      )}

      {isModalShown && (
        <Modal
          isModalShown={isModalShown}
          isFormShown={isFormShown}
          hideModal={hideModal}
          preparedDataForm={preparedDataForm}
          setPreparedDataForm={setPreparedDataForm}
          title={title}
        />
      )}
    </InnerLayout>
  );
};
