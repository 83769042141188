import React from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { colors } from 'styles/theme';
import { getCapitalizedString, getRenderValue } from 'services/helpers';
import { Box, TagColoring } from 'shared';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { TestVilnerabilityRow } from 'pages/PenetrationTestDetails/data';
import { IconColoring } from './IconColoring';
import { TableTitles } from './constants';

export const columns: ColumnsType<TestVilnerabilityRow> = [
  {
    key: 'vulnerability',
    title: TableTitles.Vulnerability,
    dataIndex: 'vulnerability',
    render: (subject: string) => (
      <Box color={colors.brown[500]} w='280px'>
        <span>{getRenderValue(subject, NO_VALUE_LABEL)}</span>
      </Box>
    ),
  },
  {
    key: 'severity',
    title: TableTitles.Severity,
    dataIndex: 'severity',
    render: (severity: string) => (
      <>
        {!severity ? (
          emptyDataString
        ) : (
          <TagColoring title={getCapitalizedString(getSeverityLevelName(severity))} />
        )}
      </>
    ),
    sorter: true,
    showSorterTooltip: false,
  },
  {
    key: 'status',
    title: TableTitles.Status,
    dataIndex: 'status',
    sorter: true,
    showSorterTooltip: false,
    render: (status: string) =>
      !status ? (
        emptyDataString
      ) : (
        <>
          <IconColoring status={status} />
          <span>{status}</span>
        </>
      ),
    align: 'left',
  },
];
