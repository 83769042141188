import React from 'react';
import { LoadingLabelContainer } from './styled';

export type LoadingDotsProps = {
  children: React.ReactNode;
};

export const LoadingDots = ({ children }: LoadingDotsProps) => {
  return (
    <LoadingLabelContainer>
      <span>{children} </span>
      <span className='first'>.</span>
      <span className='second'>.</span>
      <span className='third'>.</span>
    </LoadingLabelContainer>
  );
};
