import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TableProps } from 'antd';
import {
  useApp,
  useCustomer,
  useExcludedVulnerabilitiesStatusFilter,
  useLoading,
  useMostVulnerableHosts,
  useVulnerability,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { backlinkRoutes, defaultCurrent, PermissionOptions, PermissionsLevel } from 'app-constants';
import { Routes } from 'services/entities';
import { OrderBy, VulnerableHostSchema } from 'services/api';
import { useGetPermission } from 'services/hooks';
import {
  emptyMessageConfig,
  TooltipTitles,
  TOP_COUNT,
  VulnerabilitiesTitles,
} from 'pages/VulnerabilityManagement/constants';
import { Box, Card, Icon } from 'shared';
import { useTheme } from 'styled-components';
import { LinkContainer } from 'shared/Link/LinkContainer';
import { getParamsFromVulnerabilitiesStatusFilter } from 'pages/VulnerabilityManagement/helpers';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { TableWithSmallFields } from '../styled/styled';
import { getColumns } from './TableColumns';
import { ExcludeFilterTooltip } from '../ExcludeFilterTooltip';

export const MostVulnerableHosts = () => {
  const theme = useTheme();
  const mostVulnerableHosts = useMostVulnerableHosts();
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useLoading([vulnerabilitiesApi.getMostVulnerableHosts.typePrefix]);
  const hasAccessToHostsRedirection =
    useGetPermission(PermissionOptions.CMDB) !== PermissionsLevel.NoAccess;
  const { selectedTags } = useVulnerability();
  const vulnerabilitiesFilterbyStatus = useExcludedVulnerabilitiesStatusFilter() as string[];

  const tableRedirectToAssetDetails = React.useCallback(
    (record: VulnerableHostSchema) => ({
      onClick: () => {
        history.push(`${Routes.CMDB}/${record.id}`, {
          backTo: {
            route: Routes.VULNERABILITY_MANAGEMENT,
            title: 'Back to Vulnerability management',
          },
        });
      },
    }),
    [history],
  );

  React.useEffect(() => {
    dispatch(
      vulnerabilitiesApi.getMostVulnerableHosts({
        customerId,
        orderBy: {
          field: '_vulnerabilities',
          type: OrderBy.type.DESC,
        },
        tagIds: selectedTags || [],
        pagination: { count: TOP_COUNT, page: defaultCurrent },
        ...getParamsFromVulnerabilitiesStatusFilter(vulnerabilitiesFilterbyStatus),
      }),
    );
  }, [dispatch, customerId, vulnerabilitiesFilterbyStatus, selectedTags]);

  return (
    <Card
      title={VulnerabilitiesTitles.TopTenVulnerableHosts}
      showDivider={false}
      tooltipTitle={TooltipTitles.TopTenVulnerableHosts}
      headStyles={{
        headAlign: 'flex-start',
        titlePaddingRight: isMobile ? theme.sizes[0] : theme.sizes[5],
      }}
      cardPadding={!isMobile ? `${theme.sizes[6]} ${theme.sizes[3]}` : theme.sizes[5]}
      bodyPadding={
        !isMobile ? `${theme.sizes[2]} ${theme.sizes['2.5']} ${theme.sizes[0]}` : theme.sizes[0]
      }
      height={theme.sizes.full}
      showLoader={loading[vulnerabilitiesApi.getMostVulnerableHosts.typePrefix]}
      subTitle={
        <SubTitle className='exclude-filter-icon'>
          <ExcludeFilterTooltip />
        </SubTitle>
      }
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        h={theme.sizes.full}
      >
        <TableWithSmallFields<React.FC<TableProps<VulnerableHostSchema>>>
          columns={getColumns(tableRedirectToAssetDetails)}
          dataSource={mostVulnerableHosts && mostVulnerableHosts.rows}
          tableLayout='fixed'
          pagination={false}
          rowKey={(record: VulnerableHostSchema) => record.id as React.Key}
          onRow={hasAccessToHostsRedirection ? tableRedirectToAssetDetails : undefined}
          locale={{ emptyText: emptyMessageConfig }}
          isClickable
        />

        {hasAccessToHostsRedirection && (
          <LinkContainer>
            <Link
              to={{
                pathname: Routes.CMDB,
                state: {
                  backTo: {
                    route: Routes.VULNERABILITY_MANAGEMENT,
                    title: `Back to ${backlinkRoutes[Routes.VULNERABILITY_MANAGEMENT]}`,
                  },
                },
              }}
            >
              See all hosts
              <Icon component={theme.icons.arrow_right_alt} />
            </Link>
          </LinkContainer>
        )}
      </Box>
    </Card>
  );
};
