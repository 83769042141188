import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, DashboardScoresService, RequestWithOnlyCustomer } from 'services/api';

const getBoostScore = createAsyncThunk(
  '/dashboard/get/boostScore',
  async (requestBody: RequestWithOnlyCustomer) => {
    try {
      return await DashboardScoresService.getBoostScore(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getProcessScore = createAsyncThunk(
  '/dashboard/get/processScore',
  async (requestBody: RequestWithOnlyCustomer) => {
    try {
      return await DashboardScoresService.getProcessScore(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getTechnologyScore = createAsyncThunk(
  '/dashboard/get/technologyScore',
  async (requestBody: RequestWithOnlyCustomer) => {
    try {
      return await DashboardScoresService.getTechnologyScore(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getPeopleScore = createAsyncThunk(
  '/dashboard/get/peopleScore',
  async (requestBody: RequestWithOnlyCustomer) => {
    try {
      return await DashboardScoresService.getPeopleScore(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const dashboardApi = {
  getBoostScore,
  getProcessScore,
  getTechnologyScore,
  getPeopleScore,
};
