/* eslint-disable no-param-reassign */
import React from 'react';
import { useDispatch } from 'react-redux';
import { camelCase } from 'lodash';
import { RadioChangeEvent, Tooltip } from 'antd';
import { useGetPermissions } from 'services/hooks';
import { useModal } from 'services/hooks/useModal';
import { Box, Button, Card, Icon, Radio } from 'shared';
import { customerPermissionsSchema } from 'services/api';
import { ColumnsType } from 'antd/es/table/interface';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import {
  adminUsersApi,
  AppDispatch,
  MessageType,
  Permission,
  useAdminUsers,
  useAuth,
  useLoading,
} from 'store';
import { showError, showMessage } from 'services/helpers';
import { RequestStatus } from 'store/loading/slice';
import { CreateModalMode } from 'pages/Users/types';
import { useTheme } from 'styled-components';
import { ButtonStyled, Table } from './styled';
import { FormActions } from '../FormActions';
import { columnsNames, functionTitles, TableTitles } from './constants';
import { getTableColumns, PermissionType } from './TableColumns';
import { canCloneUserWithPermissions, canEditPermission } from '../helpers';
import { CreateUserPopup } from '../../CreateUserPopup';

type PermissionsProps = {
  setDisabledActions: (disabled: boolean) => void;
  customerId: string;
  currentCustomerId: string;
  userId: string;
  email?: string;
  isMobile: boolean;
};

export const Permissions = ({
  setDisabledActions,
  customerId,
  currentCustomerId,
  userId,
  email,
  isMobile,
}: PermissionsProps) => {
  const theme = useTheme();
  const { permissions, userDetails } = useAdminUsers();
  const createUserModal = useModal();
  const userPermissions = useGetPermissions(Object.values(PermissionOptions));
  const [isEditing, setIsEditing] = React.useState(false);
  const [columns, setColumns] = React.useState(getTableColumns());
  const [updatedPermissions, setUpdatedPermissions] = React.useState<PermissionType[]>([]);
  const { permissions: authPermissions, user } = useAuth();
  const scroll = !isMobile ? { x: 400 } : undefined;
  const isEditingNormUser = user.isSuperAdmin && currentCustomerId === customerId;

  const dispatch: AppDispatch = useDispatch();
  const loading = useLoading([
    adminUsersApi.userPermissions.typePrefix,
    adminUsersApi.updateUserPermissions.typePrefix,
  ]);

  const setTransformedPermissions = React.useCallback(
    (userPermissions: PermissionType[] | undefined) => {
      const superPermissions: string[] = [
        PermissionOptions.AuditLogs,
        PermissionOptions.Report,
        PermissionOptions.MaintenanceSettings,
        PermissionOptions.Notifications,
      ];

      if (!userPermissions) {
        return [] as PermissionType[];
      }

      return userPermissions
        .reduce((acc: PermissionType[], permission: PermissionType) => {
          if (permission.option) {
            if (
              (superPermissions.includes(permission.option) && !isEditingNormUser) ||
              (Object.prototype.hasOwnProperty.call(
                permissions.customerPermissions,
                permission.option,
              ) &&
                !permissions.customerPermissions?.[
                  permission.option as keyof customerPermissionsSchema
                ])
            ) {
              return acc;
            }

            acc.push({
              option: permission.option,
              level: permission.level,
              title: functionTitles[permission.option].title,
              disabled: false,
              fullAccessDisabled: false,
            });
          }

          return acc;
        }, [])
        .sort((a: PermissionType, b: PermissionType) => {
          if (a.option && b.option) {
            return functionTitles[a.option].order - functionTitles[b.option].order;
          }

          return 0;
        });
    },
    [isEditingNormUser, permissions.customerPermissions],
  );

  const enableEditingMode = React.useCallback(() => {
    setIsEditing(true);
    setDisabledActions(true);
  }, [setDisabledActions]);

  const disableEditMode = React.useCallback(() => {
    setIsEditing(false);
    setDisabledActions(false);
  }, [setDisabledActions]);

  const handleCancel = React.useCallback(() => {
    setUpdatedPermissions(setTransformedPermissions(permissions.userPermissions));
    disableEditMode();
  }, [permissions.userPermissions, disableEditMode, setTransformedPermissions]);

  const handleSubmit = React.useCallback(async () => {
    const result = await dispatch(
      adminUsersApi.updateUserPermissions({
        customerId,
        userId,
        requestBody: { permissions: updatedPermissions },
      }),
    );

    if (result.meta.requestStatus === RequestStatus.Rejected) {
      showError();

      return;
    }

    showMessage(
      {
        content: 'Success',
      },
      MessageType.Success,
    );
    disableEditMode();
    dispatch(adminUsersApi.userPermissions({ customerId: currentCustomerId, userId }));
  }, [customerId, disableEditMode, dispatch, updatedPermissions, userId, currentCustomerId]);

  const renderCloneUserButton = () => {
    return (
      <ButtonStyled
        type='link'
        icon={<Icon component={theme.icons.add} />}
        disabled={
          userPermissions.adminUsers !== PermissionsLevel.Full ||
          !canCloneUserWithPermissions(
            authPermissions,
            setTransformedPermissions(permissions.userPermissions) as Permission[],
            permissions.isChild,
          )
        }
        onClick={() => createUserModal.showModal()}
      >
        <Box overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' ml={theme.spacing[1]}>
          Create new user with these permissions
        </Box>
      </ButtonStyled>
    );
  };

  const renderHeaderActions = () => {
    if (!isEditing) {
      return (
        <>
          <Box ml={theme.spacing[3]} mr={theme.spacing[4]}>
            {user.email !== email && (
              <Button
                type='link'
                icon={<Icon component={theme.icons.edit} />}
                onClick={enableEditingMode}
              >
                Edit
              </Button>
            )}
          </Box>
          <Box maxW='100%'>
            {!canCloneUserWithPermissions(
              authPermissions,
              setTransformedPermissions(permissions.userPermissions) as Permission[],
              permissions.isChild,
            ) ? (
              <Tooltip title='You do not have permission to create a user with this level of access'>
                {renderCloneUserButton()}
              </Tooltip>
            ) : (
              renderCloneUserButton()
            )}
          </Box>
        </>
      );
    }

    if (isEditing && isMobile) {
      return (
        <FormActions
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          isDisabled={loading[adminUsersApi.updateUserPermissions.typePrefix]}
          isMobile={isMobile}
        />
      );
    }

    return null;
  };

  const onRadioChange = React.useCallback((event: RadioChangeEvent) => {
    const { name, value } = event.target;

    setUpdatedPermissions((prevPermissions: Array<PermissionType>) => {
      const CMDBIndex = prevPermissions.findIndex(
        (item: PermissionType) => item.option === PermissionOptions.CMDB,
      );
      const isPatchManagement = name === PermissionOptions.PatchManagement;

      if (isPatchManagement) {
        if (value === PermissionsLevel.NoAccess) {
          prevPermissions[CMDBIndex].disabled = false;
          prevPermissions[CMDBIndex].fullAccessDisabled = false;
        } else if (value === PermissionsLevel.ViewOnly) {
          if (prevPermissions[CMDBIndex].level !== PermissionsLevel.Full) {
            prevPermissions[CMDBIndex].level = value;
          }

          prevPermissions[CMDBIndex].disabled = true;
          prevPermissions[CMDBIndex].fullAccessDisabled = false;
        } else if (value === PermissionsLevel.Full) {
          if (prevPermissions[CMDBIndex].level !== PermissionsLevel.Full) {
            prevPermissions[CMDBIndex].level = value;
            prevPermissions[CMDBIndex].disabled = true;
            prevPermissions[CMDBIndex].fullAccessDisabled = true;
          } else {
            prevPermissions[CMDBIndex].fullAccessDisabled = true;
          }
        } else {
          prevPermissions[CMDBIndex].disabled = false;
          prevPermissions[CMDBIndex].fullAccessDisabled = false;
        }
      }

      return prevPermissions.map((permission: PermissionType) => {
        if (permission.option === name) {
          return {
            option: permission.option,
            level: value,
            title: permission.title,
            disabled: permission.disabled,
            fullAccessDisabled: permission.fullAccessDisabled,
          };
        }

        return { ...permission };
      });
    });
  }, []);

  const getEditingColumns = React.useCallback(
    () =>
      columnsNames.reduce(
        (acc: ColumnsType<PermissionType>, columnName: string) => {
          acc.push({
            key: camelCase(columnName),
            title: columnName,
            dataIndex: 'level',
            render: (level: PermissionsLevel, record: PermissionType) => {
              const disabledCMDBNoAccess =
                record.option === PermissionOptions.CMDB &&
                columnName === PermissionsLevel.NoAccess &&
                record.disabled;

              const disabledCMDBViewOnly =
                record.option === PermissionOptions.CMDB &&
                columnName !== PermissionsLevel.Full &&
                record.fullAccessDisabled;

              return (
                <Radio
                  name={record.option}
                  value={columnName}
                  checked={level === columnName}
                  onChange={onRadioChange}
                  disabled={Boolean(
                    (record.option === PermissionOptions.ManageSoftware &&
                      columnName === PermissionsLevel.Full &&
                      !isEditingNormUser) ||
                      (record.option &&
                        !permissions.isChild &&
                        !canEditPermission(
                          userPermissions[
                            camelCase(record.option) as keyof typeof userPermissions
                          ] as PermissionsLevel,
                          columnName as PermissionsLevel,
                        )) ||
                      disabledCMDBViewOnly ||
                      disabledCMDBNoAccess,
                  )}
                />
              );
            },
            align: 'center',
          });

          return acc;
        },
        [
          {
            key: 'function',
            title: TableTitles.Function,
            dataIndex: 'title',
          },
        ],
      ),
    // eslint-disable-next-line
    [onRadioChange, isEditingNormUser, permissions],
  );

  React.useEffect(() => {
    setUpdatedPermissions(setTransformedPermissions(permissions.userPermissions));
    setUpdatedPermissions((prevPermissions: Array<PermissionType>) => {
      const CMDBIndex = prevPermissions?.findIndex(
        (item: PermissionType) => item?.option === PermissionOptions.CMDB,
      );
      const patchManagement = prevPermissions?.find(
        (item: PermissionType) => item?.option === PermissionOptions.PatchManagement,
      );

      if (patchManagement && CMDBIndex) {
        if (patchManagement?.level === PermissionsLevel.NoAccess) {
          prevPermissions[CMDBIndex].disabled = false;
          prevPermissions[CMDBIndex].fullAccessDisabled = false;
        } else if (patchManagement?.level === PermissionsLevel.ViewOnly) {
          prevPermissions[CMDBIndex].disabled = true;
          prevPermissions[CMDBIndex].fullAccessDisabled = false;
        } else if (patchManagement?.level === PermissionsLevel.Full) {
          if (prevPermissions[CMDBIndex].level !== PermissionsLevel.Full) {
            prevPermissions[CMDBIndex].disabled = true;
            prevPermissions[CMDBIndex].fullAccessDisabled = true;
          } else {
            prevPermissions[CMDBIndex].fullAccessDisabled = true;
          }
        } else {
          prevPermissions[CMDBIndex].disabled = false;
          prevPermissions[CMDBIndex].fullAccessDisabled = false;
        }
      }

      return prevPermissions.map((permission: PermissionType) => {
        return {
          option: permission.option,
          level: permission.level,
          title: permission.title,
          disabled: permission.disabled || false,
          fullAccessDisabled: permission.fullAccessDisabled || false,
        };
      });
    });
  }, [permissions.userPermissions, setTransformedPermissions]);

  React.useEffect(() => {
    if (!isEditing) {
      setColumns(getTableColumns());
    } else {
      setColumns(getEditingColumns());
    }
  }, [isEditing, getEditingColumns]);

  return (
    <Card
      title='Permissions'
      showDivider={isMobile}
      headAction={renderHeaderActions()}
      bodyPadding={theme.spacing[0]}
      cardPadding={!isMobile ? theme.spacing['5.5'] : theme.spacing[5]}
      headStyles={
        !isMobile
          ? { titlePaddingRight: theme.spacing['2.5'] }
          : { pb: theme.spacing[4], titlePaddingRight: theme.spacing[0] }
      }
    >
      <Table
        columns={columns}
        dataSource={updatedPermissions}
        rowKey={(record: PermissionType) => record.option || ''}
        tableLayout='auto'
        scroll={scroll}
        pagination={false}
        loading={loading[adminUsersApi.userPermissions.typePrefix]}
      />

      {isEditing && !isMobile && (
        <FormActions
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          isDisabled={loading[adminUsersApi.updateUserPermissions.typePrefix]}
        />
      )}
      {createUserModal.isModalShown && (
        <CreateUserPopup
          isVisible={createUserModal.isModalShown}
          setIsVisible={(value: boolean) =>
            value ? createUserModal.showModal() : createUserModal.hideModal()
          }
          mode={CreateModalMode.Create}
          currentContactId={userDetails.id}
          onSuccess={() => {}}
        />
      )}
    </Card>
  );
};
