import React from 'react';
import styled from 'styled-components';
import { ActionPopup, Box, Icon } from 'shared';
import { AppDispatch, useCustomer, useNewsNotifications } from 'store';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getCssVar, icons } from '../../../styles/theme';
import { Spacer } from '../../../shared/TableFilters/styled';
import { PopupConfigType } from '../../../shared/ActionPopup/types';
import { showPopup } from '../../Notifications/helpers';
import { popupConfig } from '../../Notifications/constant';
import { Routes } from '../../../services/entities';
import { NotificationStatus } from '../../../services/api';

export const AdminButton = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;

export const AdminButtons = () => {
  const history = useHistory();
  const customerId = useCustomer();
  const dispatch = useDispatch<AppDispatch>();
  const { notificationDetails } = useNewsNotifications();
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const isDraft = notificationDetails.status === NotificationStatus.DRAFTS;

  const changeStatusClickHandler = () => {
    showPopup({
      popupId: isDraft ? popupConfig.publish.id : popupConfig.unPublish.id,
      setPopupDetails,
      setIsVisiblePopup,
      notificationDetails,
      dispatch,
      customerId,
      onSuccess: () => {},
      history,
    });
  };

  const deleteClickHandler = () => {
    showPopup({
      popupId: popupConfig.delete.id,
      setPopupDetails,
      setIsVisiblePopup,
      notificationDetails,
      dispatch,
      customerId,
      onSuccess: () => history.push(Routes.NOTIFICATIONS),
      history,
    });
  };

  return (
    <>
      <Box display='flex' flexDirection='row'>
        <AdminButton onClick={() => changeStatusClickHandler()}>
          <Icon
            component={isDraft ? icons.file_upload : icons.uploadNotAvailable}
            color={getCssVar('primaryColor')}
            margin={isDraft ? '5px 0 0 0' : '0'}
          />
          <Box fontSize='16px' fontWeight={700} color={getCssVar('primaryColor')}>
            {isDraft ? 'Publish' : 'Unpublish'}
          </Box>
        </AdminButton>
        <Spacer w='10px' />
        <AdminButton onClick={() => deleteClickHandler()}>
          <Icon component={icons.delete} color={getCssVar('errorSecondaryColor')} />
          <Box fontSize='16px' fontWeight={700} color={getCssVar('errorSecondaryColor')}>
            Delete
          </Box>
        </AdminButton>
      </Box>
      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
        />
      )}
    </>
  );
};
