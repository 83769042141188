import { ShadowTabs } from 'shared';
import { Tabs } from 'antd';
import { useTheme } from 'styled-components';
import { GuidanceDetails as GuidanceDetailsResponse } from 'services/api';
import { GUIDANCE_TYPE, GUIDANCE_LABEL } from 'pages/ServicesIntegrations/constants';
import { TabContent } from './TabContent';
import { TabContentHeaderActions } from './TabContentHeaderActions';
import { useGuidanceDetailsTabs } from '../../hooks';
import { GuidanceTextWrapper } from './styled';

type GuidanceDetailsProps = GuidanceDetailsResponse;

export const GuidanceDetails = ({ overview, instructions }: GuidanceDetailsProps) => {
  const { sizes } = useTheme();
  const { tab, setActiveTab } = useGuidanceDetailsTabs();

  return (
    <ShadowTabs
      tabMargin={sizes['0.5']}
      activeKey={tab || GUIDANCE_TYPE.OVERVIEW}
      onChange={setActiveTab}
    >
      <Tabs.TabPane tab={GUIDANCE_LABEL.OVERVIEW} key={GUIDANCE_TYPE.OVERVIEW}>
        <TabContent
          headAction={
            <TabContentHeaderActions
              guidanceType={GUIDANCE_TYPE.OVERVIEW}
              updatedAt={overview?.updatedAt}
              updatedBy={overview?.updatedBy}
              hasDraft={!!overview?.draft}
            />
          }
          title='Configuration overview'
        >
          <GuidanceTextWrapper
            dangerouslySetInnerHTML={{
              __html: overview?.text as string,
            }}
          />
        </TabContent>
      </Tabs.TabPane>

      <Tabs.TabPane tab={GUIDANCE_LABEL.INSTRUCTIONS} key={GUIDANCE_TYPE.INSTRUCTIONS}>
        <TabContent
          headAction={
            <TabContentHeaderActions
              guidanceType={GUIDANCE_TYPE.INSTRUCTIONS}
              updatedAt={instructions?.updatedAt}
              updatedBy={instructions?.updatedBy}
              hasDraft={!!instructions?.draft}
            />
          }
          title='Configuration instructions'
        >
          <GuidanceTextWrapper
            dangerouslySetInnerHTML={{
              __html: instructions?.text as string,
            }}
          />
        </TabContent>
      </Tabs.TabPane>
    </ShadowTabs>
  );
};
