import React from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { UserSchema } from 'services/api';
import { Box } from 'shared';
import { formatUpdatedOrCreatedTime, getRenderValue } from 'services/helpers';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { RowType } from 'shared/Table/CustomTable';
import { ColumnKey, TableTitles } from '../constants';
import { IconColoring } from './IconColoring';
import { DropdownActions } from './DropdownActions';
import { getCssVar } from '../../../styles/theme';

export const getColumns = (onSuccess: () => void, isShowActions: boolean): ColumnsType<RowType> => [
  {
    key: ColumnKey.Name,
    title: TableTitles.Name,
    dataIndex: ColumnKey.Name,
    sorter: true,
    showSorterTooltip: false,
    render: (name: string) =>
      !name ? (
        NO_VALUE_LABEL
      ) : (
        <Box w='280px' color={getCssVar('primary')}>
          <span>{name}</span>
        </Box>
      ),
  },
  {
    key: ColumnKey.Email,
    title: TableTitles.Email,
    dataIndex: ColumnKey.Email,
    render: (email: string) => (
      <Box w='260px'>
        <span>{getRenderValue(email, NO_VALUE_LABEL)}</span>
      </Box>
    ),
  },
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    sorter: true,
    showSorterTooltip: false,
    render: (status: string) =>
      !status ? (
        emptyDataString
      ) : (
        <Box flexDirection='row' w='140px'>
          <IconColoring status={status} />
          <span>{status}</span>
        </Box>
      ),
    width: 200,
  },
  {
    key: ColumnKey.Type,
    title: <Box d='inline-flex'>{TableTitles.Type}</Box>,
    dataIndex: ColumnKey.Type,
    sorter: true,
    showSorterTooltip: false,
    render: (type: string) => <>{getRenderValue(type)}</>,
  },
  {
    key: ColumnKey.Updated,
    title: TableTitles.Updated,
    dataIndex: ColumnKey.Updated,
    sorter: true,
    showSorterTooltip: false,
    render: (date: string) =>
      !date ? emptyDataString : <Box w='160px'>{formatUpdatedOrCreatedTime(date)}</Box>,
  },
  {
    key: ColumnKey.Comments,
    title: TableTitles.Comments,
    dataIndex: ColumnKey.Comments,
    render: (comment: string) => <Box w='150px'>{!comment ? '—' : comment}</Box>,
  },

  isShowActions
    ? {
        key: ColumnKey.Actions,
        title: TableTitles.Actions,
        dataIndex: ColumnKey.Actions,
        render: (_: string, record: UserSchema) => (
          <Box onClick={(event: React.MouseEvent) => event.stopPropagation()}>
            <DropdownActions userData={record} onSuccess={onSuccess} />
          </Box>
        ),
        align: 'right',
      }
    : {},
];
