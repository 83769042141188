/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PatchCategory {
  SECURITY_PATCHES = 'Security Patches',
  NON_SECURITY_PATCHES = 'Non-Security Patches',
  SECURITY_TOOLS = 'Security Tools',
  SOFTWARE_DISTRIBUTION = 'Software Distribution',
  CUSTOM_ACTIONS = 'Custom Actions',
}
