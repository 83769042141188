import { ShadowTabsWrapper, ShadowTabsStyled } from './styled';
import { ShadowTabsProps } from './types';

const DEFAULT_HEIGHT = '40px';

export const ShadowTabs = ({
  children,
  tabHeight = DEFAULT_HEIGHT,
  tabMargin,
  ...props
}: ShadowTabsProps) => {
  return (
    <ShadowTabsWrapper tabHeight={tabHeight} tabMargin={tabMargin}>
      <ShadowTabsStyled {...props}>{children}</ShadowTabsStyled>
    </ShadowTabsWrapper>
  );
};
