import React from 'react';
import { useDispatch } from 'react-redux';
import { useAuth, useCustomer } from 'store';
import { ActionPopup, Button, Icon } from 'shared';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { Dropdown } from 'antd';
import { icons } from 'styles/theme';
import { useHistory } from 'react-router';
import { useGetPermission } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { getActions, getCustomMenu, showPopup } from '../helpers';
import { Notification, NotificationStatus } from '../../../services/api';
import { ActionTypeName } from '../constant';

export type DropdownActionsProps = {
  notification: Notification;
  onSuccess: () => void;
};

export const DropdownActions = ({ notification, onSuccess }: DropdownActionsProps) => {
  const customerId = useCustomer();
  const { user } = useAuth();
  const isNormUser = user.isSuperAdmin;
  const isFullAccess = useGetPermission(PermissionOptions.Notifications) === PermissionsLevel.Full;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const readButtonSwitcher = notification?.read
    ? [ActionTypeName.MarkAsRead]
    : [ActionTypeName.MarkAsUnread];
  const isRead =
    notification.status !== NotificationStatus.DRAFTS
      ? readButtonSwitcher
      : [ActionTypeName.MarkAsRead, ActionTypeName.MarkAsUnread];
  const isPublished =
    notification?.status === NotificationStatus.PUBLISHED
      ? ActionTypeName.Publish
      : ActionTypeName.UnPublish;
  const excludeActionName = [...isRead, isPublished];

  const handleClickAction = (popupId: string) => {
    if (notification.id) {
      const notificationDetails = notification;

      showPopup({
        popupId,
        setPopupDetails,
        setIsVisiblePopup,
        notificationDetails,
        dispatch,
        customerId,
        onSuccess,
        history,
      });
    }
  };

  return (
    <>
      <Dropdown
        overlay={getCustomMenu(
          getActions((isNormUser && isFullAccess) as boolean, excludeActionName),
          handleClickAction,
        )}
        trigger={['click']}
      >
        <Button
          className='btn-table-action'
          type='link'
          icon={<Icon component={icons.more_vert} />}
        />
      </Dropdown>
      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
        />
      )}
    </>
  );
};
