import styled from 'styled-components';
import { Divider as AntDivider } from 'antd';
import { getCssVar } from 'styles/theme';
import { Icon } from 'shared';

export const TitleWrapper = styled.div`
  padding: 16px 10px;
`;

export const TitleAndIconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
`;

export const IconWrappaer = styled.div`
  > span {
    height: 100%;
    width: 100%;

    > svg {
      height: 40px;
      width: auto;
    }
  }

  margin-right: 16px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.sizes['5']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.sizes['5']};
`;

export const LastUpdatedWrapper = styled.div`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }) => theme.sizes['3.6']};
  opacity: 0.5;
  text-align: right;
`;

export const Divider = styled(AntDivider)`
  background-color: ${getCssVar('divider.color')};
  margin: 0;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  margin-top: 26px;
  padding: 0px 10px;
`;

export const ToggleText = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? getCssVar('disabled') : getCssVar('textColor'))};
  font-size: ${({ theme }) => theme.sizes['3.6']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const ConfigureIntegrationWrapper = styled.div<{ disabled?: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  margin-top: 26px;

  > div,
  span {
    color: ${({ disabled }) => (disabled ? getCssVar('disabled') : getCssVar('primaryColor'))};
  }
`;

export const LikText = styled.div`
  font-weight: ${getCssVar('link.fontWeight')};
  margin-right: ${({ theme }) => theme.spacing[2]};
`;

export const IconLeftArrow = styled(Icon)`
  height: 8px;
  width: 16px;
`;
