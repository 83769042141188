import React from 'react';
import { UserSchema } from 'services/api';
import { showError, showMessage } from 'services/helpers';
import { RequestStatus } from 'store/loading/slice';
import { Box, Button, InnerLayoutProps as InnerLayout } from 'shared';
import { ActionType } from 'shared/ActionPopup/types';
import { adminUsersApi, authApi, MessageType } from 'store';
import { loadingNotificationMessage } from 'app-constants';
import { actions, ActionTypeName, popupConfig } from './constants';
import { CreateModalMode, PopupParams } from './types';

export const getLayoutConfig = (setVisible: () => void, isShowButton: boolean): InnerLayout => ({
  title: 'Users',
  customComponent: !isShowButton ? null : (
    <Box mt='4px'>
      <Button type='primary' onClick={() => setVisible()}>
        Create user
      </Button>
    </Box>
  ),
});

export const filterActionsByStatus = (status: string | undefined) => {
  if (status === UserSchema.status.ACCOUNT_LOCKED) {
    return actions.filter((action: ActionType) => action.name !== ActionTypeName.Lock);
  }

  return actions.filter((action: ActionType) => action.name !== ActionTypeName.Unlock);
};

export const showPopup = ({
  popupId,
  setPopupDetails,
  setIsVisiblePopup,
  userData,
  dispatch,
  customerId,
  onSuccess,
  user,
}: PopupParams) => {
  switch (popupId) {
    case popupConfig.resetPassword.id:
      setPopupDetails({
        ...popupConfig.resetPassword,
        title: `${popupConfig.resetPassword.title} for ‘${userData.name}’?`,
        onApply: async () => {
          showMessage(loadingNotificationMessage, MessageType.Loading);
          const result = await dispatch(
            authApi.resetUserPasswordInit({ email: userData.email, actorEmail: user.email }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          showMessage(
            { content: `Email with password reset instructions has been sent to ${userData.name}` },
            MessageType.Success,
          );
        },
      });
      break;
    case popupConfig.reset2FA.id:
      setPopupDetails({
        ...popupConfig.reset2FA,
        title: `${popupConfig.reset2FA.title} for ‘${userData.name}’?`,
        onApply: async () => {
          showMessage(loadingNotificationMessage, MessageType.Loading);
          const result = await dispatch(
            adminUsersApi.resetUserTwofa({ customerId, userId: userData.id }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          showMessage({ content: `2FA for ${userData.name} has been reset` }, MessageType.Success);
          onSuccess();
        },
      });
      break;
    case popupConfig.lock.id:
      setPopupDetails({
        ...popupConfig.lock,
        title: `${popupConfig.lock.title} ‘${userData.name}’?`,
        onApply: async (comments?: string) => {
          showMessage(loadingNotificationMessage, MessageType.Loading);
          const result = await dispatch(
            adminUsersApi.lockUser({ customerId, userId: userData.id, comments }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          showMessage({ content: `${userData.name} has been deactivated` }, MessageType.Success);
          onSuccess();
        },
      });
      break;
    case popupConfig.unlock.id:
      setPopupDetails({
        ...popupConfig.unlock,
        title: `${popupConfig.unlock.title} ‘${userData.name}’?`,
        onApply: async () => {
          showMessage(loadingNotificationMessage, MessageType.Loading);
          const result = await dispatch(
            adminUsersApi.unlockUser({ customerId, userId: userData.id }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          showMessage({ content: `${userData.name} has been activated` }, MessageType.Success);
          onSuccess();
        },
      });
      break;
    case popupConfig.delete.id:
      setPopupDetails({
        ...popupConfig.delete,
        title: `${popupConfig.delete.title} ‘${userData.name}’?`,
        onApply: async (comments?: string) => {
          showMessage(loadingNotificationMessage, MessageType.Loading);
          const result = await dispatch(
            adminUsersApi.deleteUser({ customerId, userId: userData.id, comments }),
          );

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          showMessage({ content: `${userData.name} has been deleted` }, MessageType.Success);
          onSuccess();
        },
      });
      break;
    default:
      setPopupDetails(null);
  }

  setIsVisiblePopup(true);
};

export const getCreateModalTitle = (mode: CreateModalMode) => {
  switch (mode) {
    case CreateModalMode.Create:
      return 'Create user';
    case CreateModalMode.Edit:
      return 'Edit user';
    default:
      return '';
  }
};

export const getCreateModalButtonLabel = (mode: CreateModalMode) => {
  switch (mode) {
    case CreateModalMode.Create:
      return 'Create user';
    case CreateModalMode.Edit:
      return 'Save';
    default:
      return '';
  }
};
