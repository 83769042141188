import React from 'react';
import styled from 'styled-components';
import { Table as TableStyled } from 'shared';
import { CustomTable as CustomTableStyled, Props } from 'shared/Table/CustomTable';
import { Theme } from 'styles/theme/types';
import { GridRequestParams, PhishingAndTrainingActivitySchema } from 'services/api';

export const Table = styled(TableStyled)`
  .ant-table-tbody {
    > tr {
      > td {
        height: ${({ theme }: { theme: Theme }) => theme.sizes[10]};
      }
    }
  }
`;

export const CustomTable = styled<
  React.ComponentType<Props<PhishingAndTrainingActivitySchema, GridRequestParams>>
>(CustomTableStyled)`
  .ant-table-cell {
    height: ${({ theme }: { theme: Theme }) => theme.sizes[10]} !important;
  }
`;
