export const breakpoints = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xml: '1440px',
  xxl: '1600px',
  xsMax: '479px',
  smMax: '575px',
  mdMax: '767px',
  lgMax: '991px',
  xlMax: '1199px',
  xxlMax: '1599px',
  xxlSuperMax: '1770px',
};
