import { MessagingService } from 'services/api';
import { WebPubSubClient } from '@azure/web-pubsub-client';
import jwt_decode from 'jwt-decode';
import { ConnectionInfoType, MessagingClientListenerType, MessagingClientType } from './types';

const initialStateConnectionInfo = {
  url: '',
  token: '',
  baseUrl: '',
  userId: '',
  connectionId: '',
  expiresOn: {
    timestamp: 0,
    date: '',
  },
} as ConnectionInfoType;

export const initialState = {
  client: null,
  connectionInfo: initialStateConnectionInfo,
  listener: () => {},
  isConnected: false,
  isListenerAdded: false,
} as MessagingClient;

export class MessagingClient {
  client: MessagingClientType = null;

  connectionInfo: ConnectionInfoType = initialStateConnectionInfo;

  listener: MessagingClientListenerType = () => {};

  isConnected = false;

  // Currently only one listener is supported
  // TODO: add ability to have multiple listeners
  isListenerAdded = false;

  // connect to remote messaging service
  async connect() {
    try {
      await this.getConnectionInfo();

      this.client = new WebPubSubClient(this.connectionInfo.url as string);

      this.getTokenExpiryDate();

      this.client?.start();

      this.client?.on('disconnected', () => {
        this.isConnected = false;
        // eslint-disable-next-line no-console
        console.log(
          '%cDisconnected from messaging service',
          'color:#fff;background:red;padding:5px;',
        );
      });

      return await new Promise<void>((resolve) => {
        this.client?.on('connected', (e) => {
          this.connectionInfo.connectionId = e.connectionId;
          this.isConnected = true;
          // @ts-ignore
          window.AzureWebPubSubService = this;

          // eslint-disable-next-line no-console
          console.log(
            '%cConnected to messaging service:',
            'color:#fff;background:blue;padding:5px;',
            this,
          );
          resolve();
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Couldn't connect to messaging service", e);

      this.stop();

      return Promise.resolve();
    }
  }

  // get token and url for service connection
  async getConnectionInfo() {
    const connectionInfo = await MessagingService.getConnectionInfo();

    if (connectionInfo?.token) {
      this.connectionInfo = { ...connectionInfo, connectionId: '' };
    }
  }

  // establish connection with the messaging service
  async validateConnection() {
    const isExpired = this.isTokenExpired();

    if (!this.client || isExpired || !this.isConnected) {
      this.stop();

      await this.connect();
    }
  }

  /**
   * Add callback to listen messages from the service
   * @returns Connection status and connectionId (should be used on server side when need to send messages directly to this connection)
   */
  async listen(listener) {
    if (!this.checkListener(listener)) {
      throw new Error('Listener is required');
    }

    await this.validateConnection();

    // Currently only one listener is supported
    // TODO: add ability to have multiple listeners
    if (this.isListenerAdded) {
      this.removeListener();
    }

    this.listener = listener;
    this.addListener();

    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  checkListener(listener) {
    return typeof listener === 'function';
  }

  addListener() {
    if (!this.isListenerAdded && this.listener) {
      this.client?.on('server-message', this.listener);
      this.isListenerAdded = true;
    }
  }

  removeListener() {
    this.client?.off('server-message', this.listener);
    this.isListenerAdded = false;
  }

  stop() {
    this.client?.stop();
    this.isListenerAdded = false;
    this.isConnected = false;
    this.connectionInfo = initialStateConnectionInfo;
  }

  isTokenExpired() {
    const extraTime = 600000; // 10 minutes (approximate processing time for the request)

    return Date.now() + extraTime > (this.connectionInfo?.expiresOn?.timestamp as number);
  }

  getTokenExpiryDate() {
    const { exp }: { exp: number } = jwt_decode(this.connectionInfo.token as string);
    const timeFactor = 1000;
    const timestamp = exp * timeFactor;
    const date = new Date(timestamp).toString();

    this.connectionInfo.expiresOn = {
      timestamp,
      date,
    };
  }
}
