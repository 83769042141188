import { useState } from 'react';
import { useCustomer } from 'store';
import { useParams } from 'react-router';
import { UnmaskedWrapper } from './styled';
import { Popup } from './Popup';

type UnmaskedTextProps = {
  defaultValue: string;
  name: string;
  label: string;
};

export const UnmaskedCredential = ({ defaultValue, name, label }: UnmaskedTextProps) => {
  const customerId = useCustomer();
  const { id: serviceId } = useParams<{ id: string }>();
  const [value, setValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <UnmaskedWrapper
        style={{ cursor: !value ? 'pointer' : 'default' }}
        onClick={() => {
          if (!value) {
            setShowPopup(true);
          }
        }}
      >
        {value || defaultValue}
      </UnmaskedWrapper>

      <Popup
        isVisible={showPopup}
        customerId={customerId}
        serviceId={serviceId}
        name={name}
        label={label}
        onCancel={() => setShowPopup(false)}
        onApply={(value) => setValue(value)}
      />
    </>
  );
};
