import React from 'react';
import { Box, Button, Icon } from 'shared';
import { useAuth } from 'store';
import { getCustomMenu } from 'pages/Users/components/UserDetails/helpers';
import { Popup } from 'pages/Users/components/styled';
import { useTheme } from 'styled-components';
import { getActions, popupConfig } from '../constants';

export interface MobileActionsProps {
  onAction: (soft: boolean) => void;
}

export const MobileActions = ({ onAction }: MobileActionsProps) => {
  const theme = useTheme();
  const { user } = useAuth();
  const [isVisibleActionsPopup, setIsVisibleActionsPopup] = React.useState(false);

  const handleCancel = () => {
    setIsVisibleActionsPopup(false);
  };

  const handleActionClick = (popupId: string) => {
    handleCancel();
    onAction(popupId === popupConfig.softDelete.id);
  };

  return (
    <Box onClick={(event: React.MouseEvent) => event.stopPropagation()}>
      <Button
        className='btn-table-action'
        type='link'
        icon={
          <Icon component={theme.icons.more_vert} onClick={() => setIsVisibleActionsPopup(true)} />
        }
      />
      {isVisibleActionsPopup && (
        <Popup
          className='mobile-actions-modal'
          title='Actions'
          visible={isVisibleActionsPopup}
          width={240}
          footer={null}
          onCancel={handleCancel}
          closable={false}
        >
          {getCustomMenu(getActions(Boolean(user.isSuperAdmin)), handleActionClick)}
        </Popup>
      )}
    </Box>
  );
};
