/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssetStatus {
  SCANNED = 'Scanned',
  FAILED = 'Failed',
  READY = 'Ready',
  PENDING_REBOOT = 'Pending Reboot',
  ASSIGNED = 'Assigned',
  REBOOTED = 'Rebooted',
  CONFIGURED = 'Configured',
  ASSIGNING = 'Assigning',
  PREPARING = 'Preparing',
}
