import React from 'react';
import moment from 'moment';
import { BoostScoreItemSchema } from 'services/api';
import { checkRoundedImageUrl, warningImageUrl } from '../constants';

type BoostScoreTableProps = {
  boostScoreItems?: Array<BoostScoreItemSchema>;
};

export const BoostScoreTable = ({ boostScoreItems }: BoostScoreTableProps) => {
  return (
    <table className='table text-center'>
      <thead>
        <tr>
          {boostScoreItems?.map((score: BoostScoreItemSchema) => (
            <th key={`th-${score.expirationDate}`}>
              <div className='boost-th-title'>{score.title || ''}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {boostScoreItems?.map((score: BoostScoreItemSchema) => (
            <td key={`td-${score.expirationDate}`}>
              <img
                src={
                  moment().valueOf() > moment(score.expirationDate).valueOf() || !score.vendor
                    ? warningImageUrl
                    : checkRoundedImageUrl
                }
                alt={score.title}
              />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
