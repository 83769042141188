import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { changeCustomerAction } from 'store/actionCreators';
import { LoadingState, RequestStatus } from '../loading/slice';
import { vulnerabilitiesApi } from '../vulnerability/thunks';
import { managedDetectionsResponseApi } from '../managedDetectionsResponse/thunks';

const initialState = {} as LoadingState;

const listOfServerRequests = [
  vulnerabilitiesApi.getAllVulnerabilitiesTrend.typePrefix,
  vulnerabilitiesApi.getVulnerabilitiesTrendsByCriticalities.typePrefix,
  vulnerabilitiesApi.getCurrentVulnerabilities.typePrefix,
  managedDetectionsResponseApi.activeEDRAgents.typePrefix,
  managedDetectionsResponseApi.events.typePrefix,
  managedDetectionsResponseApi.eventsByServices.typePrefix,
];

export const customer = createSlice({
  name: 'customerChange',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<LoadingState>) => {
    builder
      .addCase(changeCustomerAction, (state: LoadingState) => {
        listOfServerRequests.forEach((requestName) => {
          state[requestName] = true;
        });
      })
      .addMatcher(
        (action: AnyAction) => {
          return (
            action.type.endsWith(`/${RequestStatus.Fulfilled}`) ||
            action.type.endsWith(`/${RequestStatus.Rejected}`)
          );
        },
        (state: LoadingState, action: AnyAction) => {
          const { type } = action;
          const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(type);

          if (!matches) return;
          const [, requestName, requestState] = matches;

          const requestWithDataForCharts = listOfServerRequests.find(
            (request) => request === requestName,
          );

          if (requestWithDataForCharts && state[requestName] === true) {
            state[requestName] = requestState === RequestStatus.Pending;
          }
        },
      );
  },
});
