import * as React from 'react';
import { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTheme } from 'styled-components';
import { getOptions } from './helpers';

type Props = {
  value: number;
  max: number;
  height?: string;
  isLoading?: boolean;
  // onEvents?: Record<string, (params?: EChartsEventParams) => void>;
};

export const GradientBar = ({ value = 0, height, isLoading, max }: Props) => {
  const theme = useTheme();

  const option = useMemo(() => {
    if (isLoading) {
      return {};
    }

    return getOptions(value, max, theme);
  }, [isLoading, theme, value, max]);

  return (
    <ReactECharts
      style={{ height, width: '100%' }}
      opts={{ renderer: 'canvas' }}
      option={option}
      notMerge
    />
  );
};
