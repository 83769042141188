import React, { createRef, useEffect, useState } from 'react';
import { Box, Icon } from 'shared';
import ReactECharts from 'echarts-for-react';
import { getOptions } from 'shared/charts/GradientPie/helpers';
import { Annotations } from 'shared/charts/GradientPie/types';
import { icons } from 'styles/theme';
import { useTheme } from 'styled-components';
import { GradientPieReportContainer } from '../styled';

type GradientPieReportContainerProps = {
  score?: number | null;
  height?: string;
  fontSize?: number;
  right?: boolean;
  onLoad: () => void;
  scoreAnnotations?: Annotations;
  titleFontSize?: number;
  fontFamily?: string;
};

const FONT_SIZE_DEFAULT = 14;

export const GradientPieReport = ({
  score,
  height = '60px',
  fontSize = FONT_SIZE_DEFAULT,
  right,
  onLoad,
  scoreAnnotations,
  titleFontSize,
  fontFamily = 'Helvetica, Arial, sans-serif',
}: GradientPieReportContainerProps) => {
  const theme = useTheme();
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = createRef<ReactECharts>();

  const option = getOptions(
    score || 0,
    fontSize,
    theme,
    false,
    scoreAnnotations,
    titleFontSize,
    fontFamily,
  );

  useEffect(() => {
    if (ref && isLoaded) {
      const instance = ref.current?.getEchartsInstance();

      if (instance.getDataURL({ type: 'png' })) {
        setDataUrl(instance.getDataURL({ type: 'png', pixelRatio: 4 }));
        onLoad?.();
      }
    }
    // eslint-disable-next-line
  }, [isLoaded]);

  return (
    <>
      <GradientPieReportContainer height={height} right={right}>
        <Box position='absolute' left='1000%' width={height}>
          <ReactECharts
            style={{ height }}
            ref={ref}
            onChartReady={(instance) => {
              instance.on('finished', () => {
                setIsLoaded(true);
              });
            }}
            opts={{ renderer: 'canvas' }}
            option={option}
          />
        </Box>
        {!!dataUrl && <img height={height} src={dataUrl} alt='score' />}
        {(score === undefined || score === null) && (
          <Icon style={{ bottom: '38%' }} component={icons.medium_lock} />
        )}
      </GradientPieReportContainer>
    </>
  );
};
