import React from 'react';
import { HistoryState } from '../../services/interfaces';
import { BackLink, InnerLayoutProps as InnerLayout } from '../../shared';
import { Routes } from '../../services/entities';
import { backlinkRoutes } from '../../app-constants';

export const getLayoutConfig = (historyState?: HistoryState): InnerLayout => ({
  title: `${historyState?.data?.name || 'Monitored'} service details`,
  backLink: (
    <BackLink
      defaultRoute={Routes.THREAT_DETECTION}
      defaultTitle={backlinkRoutes[Routes.THREAT_DETECTION]}
    />
  ),
});
