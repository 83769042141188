import * as React from 'react';
import { Col } from 'antd';
import { useTheme } from 'styled-components';
import { Box, Title as AntdTitle } from 'shared';
import { Row } from './styled';
import { getCssVar } from '../../../../styles/theme';

export const Title: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Row justify='center'>
      <Col>
        <AntdTitle
          fontWeight={theme.fontWeights.black}
          level={3}
          style={{ fontSize: theme.sizes[6] }}
        >
          <Box textAlign='center' color={getCssVar('textColor')}>
            {children}
          </Box>
        </AntdTitle>
      </Col>
    </Row>
  );
};
