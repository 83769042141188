import React from 'react';
import { TableProps } from 'antd';
import { Card, ScrollContainer, Table } from 'shared';
import { useApp, useAssetDetails } from 'store';
import { HostNetworkInterfaceSchema } from 'services/api';
import { useTheme } from 'styled-components';
import { columns } from './TableColumns';

export const InterfaceDetails = () => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const { interfaceDetails } = useAssetDetails();

  return (
    <Card cardPadding={isMobile ? theme.sizes[5] : theme.sizes['5.5']} bodyPadding={theme.sizes[0]}>
      <ScrollContainer width='400px' $isScrollTop>
        <Table<React.FC<TableProps<HostNetworkInterfaceSchema>>>
          columns={columns}
          dataSource={interfaceDetails}
          tableLayout='auto'
          pagination={false}
          scroll={!isMobile ? { x: 400 } : {}}
          rowKey='address'
        />
      </ScrollContainer>
    </Card>
  );
};
