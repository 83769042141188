import { InvestigationOverviewDetailsSchema } from 'services/api/models/InvestigationOverviewDetailsSchema';

type ChartDataType = [string, InvestigationOverviewDetailsSchema | undefined];

export const getLegendChartData = (data: Array<ChartDataType>) => {
  const legendValues: Record<string, number> = {};

  data.forEach(([key, value]) => {
    if (value?.count) {
      legendValues[key] = value.count;
    }
  });

  return legendValues;
};

export const getLegendPolarColors = (
  data: Array<ChartDataType>,
  colors: Record<string, string>,
) => {
  const legendColors: Record<string, string> = {};

  data.forEach(([key, value]) => {
    if (colors[key] && value?.count) {
      legendColors[key] = colors[key];
    }
  });

  return legendColors;
};
