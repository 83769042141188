import React from 'react';
import moment from 'moment';
import { Theme } from 'styles/theme/types';
import { Filter, PhishingLastThirtyDaysResponse } from 'services/api';
import { initialPagination } from 'app-constants';
import { Box, Icon } from 'shared';
import { initialOrderBy } from 'pages/PeoplePhishing/constants';
import { getPhishingActivityFilterValue } from '../PhishingByMonth/helpers';

const getPhishingDateFilterValue = () => {
  const THIRTY_DAYS = 30;
  const HOURS_IN_DAY = 24;
  const MINUTES_IN_HOUR = 60;
  const SECONDS_IN_MINUTE = 60;
  const MILLISECONDS = 1000;
  const THIRTY_DAYS_TIME =
    THIRTY_DAYS * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS;
  const endDate = moment().valueOf();
  const startDate = endDate - THIRTY_DAYS_TIME;

  return [`${startDate}`, `${endDate}`];
};

export const getRedirectParams = (name: string) => ({
  orderBy: initialOrderBy,
  pagination: initialPagination,
  filter: {
    fields: [
      {
        name: 'phishingActivity',
        value: [getPhishingActivityFilterValue(name)],
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'phishingDate',
        value: getPhishingDateFilterValue(),
        type: Filter.type.RANGE,
      },
    ],
  },
});

export const getTotalClickRedirectParams = () => ({
  orderBy: initialOrderBy,
  pagination: initialPagination,
  filter: {
    fields: [
      {
        name: 'phishingDate',
        value: getPhishingDateFilterValue(),
        type: Filter.type.RANGE,
      },
    ],
  },
});

const getSubTitleIcon = (theme: Theme, isMobile: boolean, percentage: number) => {
  const criticality = {
    critical: { maxValue: 25, color: theme.colorSet.subTitleIconCritical },
    high: { maxValue: 50, color: theme.colorSet.subTitleIconHigh },
    medium: { maxValue: 75, color: theme.colorSet.subTitleIconMedium },
  };

  let iconColor = criticality.critical.color;

  if (percentage > criticality.medium.maxValue) {
    return null;
  }

  if (percentage > criticality.high.maxValue && percentage <= criticality.medium.maxValue) {
    iconColor = criticality.medium.color;
  }

  if (percentage > criticality.critical.maxValue && percentage <= criticality.high.maxValue) {
    iconColor = criticality.high.color;
  }

  return (
    <Icon
      component={theme.icons.warning}
      color={iconColor}
      dimension={theme.spacing[9]}
      margin={
        !isMobile
          ? `0 ${theme.spacing['2.5']} 0 0`
          : `0 ${theme.spacing[2]} ${theme.spacing['0.5']} 0`
      }
    />
  );
};

export const getPhishingSubTitle = (theme: Theme, isMobile: boolean, percentage?: number) => {
  if (percentage === undefined) {
    return null;
  }

  return (
    <Box d='flex' alignItems='center' justifyContent='flex-end'>
      {getSubTitleIcon(theme, isMobile, percentage)}
      <Box>{(percentage || 0).toFixed(2)}%</Box>
    </Box>
  );
};

export const getChartValues = (phishingLastThirtyDays: PhishingLastThirtyDaysResponse) => {
  if (phishingLastThirtyDays.details?.providedCredentials !== undefined) {
    const { providedCredentials, ...otherValues } = phishingLastThirtyDays.details;

    return {
      ...otherValues,
      providedCredentialsOrDownloadedAnAttachment: providedCredentials,
    };
  }

  return phishingLastThirtyDays.details || {};
};
