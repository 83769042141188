import { OrderBy } from 'services/api';

export enum TableKeys {
  Document = 'document',
  Status = 'status',
  Comments = 'comments',
  Actions = 'actions',
}

export enum TableTitles {
  Document = 'Document',
  Status = 'Status',
  Comments = 'Comments',
  Actions = 'Actions',
}

export enum Status {
  NotDefined = 'We don’t know if you have this',
  NotReviewed = 'We know you have it, but haven’t reviewed it',
  ReviewedAndCompilant = 'Reviewed and compliant',
}

export const initialOrderBy = {
  field: TableKeys.Document,
  type: OrderBy.type.DESC,
};

export const searchSettings = {
  placeholder: 'Search',
  fields: [TableKeys.Document],
  min: 2,
};
