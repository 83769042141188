import { createAsyncThunk } from '@reduxjs/toolkit';
import { SecuritySettingsService, SecuritySettingsResponse, ApiError } from 'services/api';

const securitySettingsContent = createAsyncThunk(
  'security-settings/data/get',
  async (requestBody: { customerId: string }) => {
    try {
      const response = await SecuritySettingsService.getSecuritySettings(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const updateSecuritySettings = createAsyncThunk(
  'security-settings/data/put',
  async (request: { requestBody: SecuritySettingsResponse; customerId: string }) => {
    try {
      const response = await SecuritySettingsService.updateSecuritySettings(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const securitySettingsApi = {
  securitySettingsContent,
  updateSecuritySettings,
};
