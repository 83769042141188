import { Button, Icon, Box } from 'shared';
import { Tooltip } from 'antd';
import { useCustomer, servicesIntegrationsApi, AppDispatch, PayloadActionType } from 'store';
import { icons } from 'styles/theme';
import { useHistory, useParams } from 'react-router';
import { Routes } from 'services/entities';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from 'services/helpers';
import { DisconnectPopup } from 'pages/ServiceIntegrationDetails/components/DisconnectPopup';
import { TestServiceConnectionResponse } from 'services/api';
import { RequestStatus } from 'store/loading/slice';
import { FormButtonsWrapper, FormButtonsContainer, ConnectionStatus } from '../../styled';

export const FormActionButtons = ({ values, onSave, onReset, canSubmit, connectedAt }) => {
  const customerId = useCustomer();
  const history = useHistory();
  const { id: serviceId } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const [showPopup, setShowPopup] = useState(false);

  const [connectionStatus, setConnectionStatus] = useState<boolean | null>(null);

  const goBack = () => {
    onReset();
    history.push(Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', serviceId));
  };

  const showConnectionStatus = connectionStatus !== null;
  const isSaveEnabled = canSubmit && connectionStatus;

  return (
    <>
      <FormButtonsContainer>
        <FormButtonsWrapper>
          <Tooltip
            title='Test connection before saving'
            placement='top'
            trigger={isSaveEnabled ? 'none' : 'hover'}
          >
            <Box>
              <Button type='primary' onClick={onSave} disabled={!isSaveEnabled} block>
                Save settings
              </Button>
            </Box>
          </Tooltip>

          <Button type='secondary' onClick={goBack}>
            Cancel
          </Button>

          {connectedAt && (
            <Button
              type='link'
              icon={<Icon component={icons.close} />}
              onClick={() => setShowPopup(true)}
            >
              Disconnect
            </Button>
          )}
        </FormButtonsWrapper>
        <FormButtonsWrapper>
          {showConnectionStatus && (
            <ConnectionStatus isConnected={connectionStatus}>
              {connectionStatus ? 'Connection OK' : 'Connection Failed'}
            </ConnectionStatus>
          )}
          <Button
            type='secondary'
            onClick={async () => {
              const result = (await dispatch(
                servicesIntegrationsApi.testConnection({
                  customerId,
                  serviceId,
                  requestBody: {
                    credentials: values,
                  },
                }),
              )) as PayloadActionType<TestServiceConnectionResponse>;

              if (result.meta.requestStatus === RequestStatus.Rejected) {
                showError();
                setConnectionStatus(false);
              } else {
                setConnectionStatus(result.payload.status as boolean);

                if (result.payload.status) {
                  showSuccess('Connection OK');
                } else {
                  showError('Connection failed');
                }
              }
            }}
            disabled={!canSubmit}
          >
            Test connection
          </Button>
        </FormButtonsWrapper>
      </FormButtonsContainer>

      {showPopup && (
        <DisconnectPopup
          customerId={customerId}
          serviceId={serviceId}
          onCancel={() => setShowPopup(false)}
          onApply={goBack}
        />
      )}
    </>
  );
};
