import React from 'react';
import { useDispatch } from 'react-redux';
import {
  managedDetectionsResponseApi,
  useApp,
  useCustomer,
  useCustomerChange,
  useEvents,
} from 'store';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { RadioButtonsWithRangePicker } from 'shared/RadioButtonsWithRangePicker/RadioButtonsWithRangePicker';
import { emptyValues } from 'pages/ThreatDetection/constants';
import { EmptyValues, IEvents } from 'pages/ThreatDetection/types';
import { Area } from 'shared/charts/Area/Area';
import { getDefaultDateRangeValue } from 'shared/RadioButtonsWithRangePicker/helpers';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { IDate } from 'services/interfaces';
import { RadioButtonsKeys } from 'shared/RadioButtonsWithRangePicker/constants';
import { ScrollContainer } from 'shared';
import { getOption } from './helpers';

interface EventByNameProps {
  eventService?: string;
  setPeriodName?: (period: string) => void;
  selectedDate?: IDate;
  periodName?: RadioButtonsKeys;
}

export const EventByName = ({
  eventService,
  setPeriodName,
  selectedDate,
  periodName,
}: EventByNameProps) => {
  const customer = useCustomer();
  const { isMobile } = useApp();
  const [date, setDate] = React.useState<IDate>(selectedDate || getDefaultDateRangeValue());
  const dispatch = useDispatch();
  const { eventsData } = useEvents();

  const customerChange = useCustomerChange([managedDetectionsResponseApi.events.typePrefix]);
  const isShowEmptyChart = customerChange[managedDetectionsResponseApi.events.typePrefix];

  const updateDate = React.useCallback((selectedDate: IDate) => {
    setDate(selectedDate);
  }, []);

  React.useEffect(() => {
    const { startDate, endDate } = date;

    if (startDate && endDate) {
      dispatch(
        managedDetectionsResponseApi.events({
          customerId: customer,
          startDate,
          endDate,
          eventService,
        }),
      );
    }
  }, [dispatch, customer, date, eventService]);

  return (
    <ScrollContainer width='600px'>
      <RadioButtonsWithRangePicker
        setPeriodName={setPeriodName}
        handleDateChange={updateDate}
        date={date}
        isMobile={isMobile}
        defaultPeriodName={periodName}
      />
      <TotalCustomTooltipContainer>
        <RenderChartWrapper<EmptyValues[], EmptyValues[]>
          values={eventsData.allEvents}
          emptyValues={emptyValues}
          emptyText='No events yet'
          isShowEmptyChart={isShowEmptyChart}
        >
          <Area
            values={emptyValues as IEvents[]}
            isMobile={isMobile}
            getOption={getOption}
            date={date}
            height={420}
          />
        </RenderChartWrapper>
      </TotalCustomTooltipContainer>
    </ScrollContainer>
  );
};
