/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DocumentType {
  CONTRACT = 'CONTRACT',
  TEMPLATE = 'TEMPLATE',
  REPORT = 'REPORT',
  OTHER = 'OTHER',
  CERTIFICATE = 'CERTIFICATE',
  USER_GUIDE = 'USER GUIDE',
}
