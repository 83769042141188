import React, { RefObject, useEffect } from 'react';
import { useApp } from '../../store';

const widthForThreeFilters = 1550;
const widthForFourFilters = 1850;
const threeFilters = 3;
const fourFilters = 4;

export const useGetMaxVisiableFilters = (
  ref: RefObject<HTMLDivElement>,
  maxFilterFieldsLengthDefault?: number,
) => {
  const [width, setWidth] = React.useState(0);
  const [maxFilterFieldsLength, setMaxFilterFieldsLength] = React.useState(2);
  const { isMenuCollapsed } = useApp();

  useEffect(() => {
    const updateSize = () => {
      setWidth(ref.current?.clientWidth || 0);
    };

    if (ref.current && !maxFilterFieldsLengthDefault) {
      setWidth(ref.current?.clientWidth);

      if (width <= widthForThreeFilters) {
        setMaxFilterFieldsLength(2);
      }

      if (width >= widthForThreeFilters) {
        setMaxFilterFieldsLength(threeFilters);
      }

      if (width >= widthForFourFilters) {
        setMaxFilterFieldsLength(fourFilters);
      }
    }

    if (maxFilterFieldsLengthDefault) {
      setMaxFilterFieldsLength(maxFilterFieldsLengthDefault);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [ref, isMenuCollapsed, width, maxFilterFieldsLengthDefault]);

  return maxFilterFieldsLength;
};
