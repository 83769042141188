import { Filter, OrderBy, VulnerabilityStatus } from 'services/api';
import { severityOptions } from 'pages/VulnerabilitiesList/constants';
import { InputType } from '../../../../services/api/models/InputType';

export enum TableTitles {
  Subject = 'Vulnerability title',
  Severity = 'Severity',
  SuppressedBy = 'Suppressed by',
  BusinessReason = 'Business reason for suppression',
  SuppressedAt = 'Suppressed at',
}

export const searchSettings = {
  placeholder: 'Search by title, name, reason',
  fields: ['subject', 'suppressedBy', 'suppressReason'],
  min: 3,
};

export const filterFields = [
  {
    field: 'severity',
    options: severityOptions,
    label: TableTitles.Severity,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];

export const initialOrderBy = {
  field: 'suppressedAt',
  type: OrderBy.type.ASC,
};

export const defaultVulnStatusValue = VulnerabilityStatus.CONFIRMED;

export const confirmedVulnerabilitiesFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [defaultVulnStatusValue],
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const potentialVulnerabilitiesFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.CONFIRMED, VulnerabilityStatus.POTENTIAL],
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const emptyVulnStatus = '';
