/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveSecurityIncidentsCountResponse } from '../models/ActiveSecurityIncidentsCountResponse';
import type { CustomerId } from '../models/CustomerId';
import { request as __request } from '../core/request';

export class SecurityIncidentsService {
  /**
   * Security Incidents. Get Active Security Incidents count
   * Get Active Security Incidents Count
   * @returns ActiveSecurityIncidentsCountResponse Active Security Incidents count
   * @throws ApiError
   */
  public static async getActiveSecurityIncidentsCount({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<ActiveSecurityIncidentsCountResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/security-incidents/active-incidents-count`,
      errors: {
        400: `An error message when getting active security incidents count`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
