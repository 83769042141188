import { SuppressionStatus } from 'services/api';

export const getSuppressionStatusOptions = (status?: string) => {
  if (!status || status === SuppressionStatus.UNSUPPRESSED) {
    return [
      {
        label: 'Suppressed',
        value: 'suppressed',
      },
    ];
  }

  return [
    {
      label: 'Reviewed',
      value: 'reviewed',
    },
    {
      label: 'Not suppressed',
      value: 'unsuppressed',
    },
  ];
};
