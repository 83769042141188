import { CertificateName, CertificateSchema, DownloadSchema } from 'services/api';
import moment from 'moment';

export const getDownloadName = (
  certificateName: string | undefined,
  downloads: DownloadSchema[] | undefined,
) => {
  return downloads
    ?.filter(
      (uploadedCertificate: DownloadSchema) => uploadedCertificate?.title === certificateName,
    )
    .sort((first: DownloadSchema, second: DownloadSchema) =>
      moment.utc(second.created).diff(moment.utc(first.created)),
    )[0]?.name;
};

export const getCertificatesForTable = (
  downloads: DownloadSchema[] | undefined,
  certificates: CertificateSchema[] | undefined,
) => {
  const getExpiredDate = (certificateName: string | undefined) => {
    return certificates?.find(
      (uploadedCertificate: DownloadSchema) => uploadedCertificate?.title === certificateName,
    )?.expirationDate;
  };

  const certificatesWithExpiredDate = downloads?.map((certificate: DownloadSchema) => ({
    ...certificate,
    expiredDate: getExpiredDate(certificate.title),
  }));

  const nameOfCertificates = Object.values(CertificateName);

  return nameOfCertificates.reduce((accumulator: DownloadSchema[], certificateName: string) => {
    const groupOfCertificates = certificatesWithExpiredDate
      ?.filter((downloadedCertificates) => downloadedCertificates.title === certificateName)
      .sort((first: DownloadSchema, second: DownloadSchema) =>
        moment.utc(second.created).diff(moment.utc(first.created)),
      );

    if (groupOfCertificates) {
      const isFirstCertificateExpired = moment(groupOfCertificates?.[0]?.expiredDate).isBefore(
        moment(),
      );

      return isFirstCertificateExpired
        ? [...accumulator, ...groupOfCertificates]
        : [...accumulator, ...groupOfCertificates.slice(1)];
    }

    return [...accumulator];
  }, []);
};
