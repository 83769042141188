import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide6.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const PeopleHeader = styled.div`
  font-size: 13px;
  left: 90px;
  max-width: 500px;
  position: absolute;
  top: 125px;
  word-wrap: break-word;
`;

const SegmentedPieWrapper = styled.div`
  left: 340px;
  position: absolute;
  text-align: center;

  top: 270px;
  width: 320px;

  h4 {
    font-size: 13px;
    margin-bottom: 0;
  }

  > div {
    margin-top: -10px;
  }
`;

export const CurrentTrainingStatus = styled(SegmentedPieWrapper)`
  left: 50px;
`;

export const PhishingLastMonth = styled(SegmentedPieWrapper)`
  left: 370px;
`;

export const CurrentRefresherTests = styled(SegmentedPieWrapper)`
  left: 690px;
`;
