import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ServicesIntegrationsService,
  ApiError,
  EnableServiceRequest,
  UpdateGuidanceDetailsRequest,
  ConnectionCredentials,
} from 'services/api';

const getServices = createAsyncThunk(
  'services-integrations/get',
  async (requestBody: { customerId: string }) => {
    try {
      const response = await ServicesIntegrationsService.getServices(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getServiceDetails = createAsyncThunk(
  'services-integrations/service/get',
  async (request: { serviceId: string; customerId: string }) => {
    try {
      const response = await ServicesIntegrationsService.getServiceDetails(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const enableService = createAsyncThunk(
  'services-integrations/service/activate',
  async (request: { requestBody: EnableServiceRequest; customerId: string }) => {
    try {
      const response = await ServicesIntegrationsService.enableService(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const updateServiceGuidanceDetails = createAsyncThunk(
  'services-integrations/service/guidance/update',
  async (request: {
    requestBody: UpdateGuidanceDetailsRequest;
    customerId: string;
    serviceId: string;
  }) => {
    try {
      const response = await ServicesIntegrationsService.updateServiceGuidanceDetails(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const updateServiceConnectionDetails = createAsyncThunk(
  'services-integrations/service/connection/update',
  async (request: {
    requestBody: ConnectionCredentials;
    customerId: string;
    serviceId: string;
  }) => {
    try {
      const response = await ServicesIntegrationsService.updateServiceConnectionDetails(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const disconnectService = createAsyncThunk(
  'services-integrations/service/disconnect',
  async (request: { customerId: string; serviceId: string }) => {
    try {
      const response = await ServicesIntegrationsService.disconnectService(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const testConnection = createAsyncThunk(
  'services-integrations/service/test-connection',
  async (request: {
    customerId: string;
    serviceId: string;
    requestBody: ConnectionCredentials;
  }) => {
    try {
      const response = await ServicesIntegrationsService.testServiceConnection(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const testExistingConnection = createAsyncThunk(
  'services-integrations/service/test-existing-connection',
  async (request: { customerId: string; serviceId: string }) => {
    try {
      const response = await ServicesIntegrationsService.testExistingServiceConnection(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getConnectionCredential = createAsyncThunk(
  'services-integrations/service/test-existing-connection',
  async (request: { customerId: string; serviceId: string; credentialName: string }) => {
    try {
      const response = await ServicesIntegrationsService.getServiceConnectionCredential(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const servicesIntegrationsApi = {
  getServices,
  enableService,
  getServiceDetails,
  updateServiceGuidanceDetails,
  updateServiceConnectionDetails,
  disconnectService,
  testConnection,
  testExistingConnection,
  getConnectionCredential,
};
