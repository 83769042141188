import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const Primary = css<Props>`
  background-color: ${getCssVar('button.backgroundColor')};
  border: ${getCssVar('button.border')};
  border-radius: ${getCssVar('button.borderRadius')};
  color: ${getCssVar('button.color')};

  text-transform: ${getCssVar('button.textTransform')};

  &:hover {
    background-color: ${getCssVar('button.hover.backgroundColor')};
    box-shadow: ${getCssVar('button.hover.boxShadow')};
    color: ${getCssVar('button.hover.color')};
    transform: ${getCssVar('button.hover.transform')};
  }

  &:hover,
  &.hover,
  &:focus {
    &:not(.ant-btn-dangerous) {
      background-color: ${getCssVar('button.hover.backgroundColor')};
      color: ${getCssVar('button.hover.color')};
    }
  }

  &:active,
  &.active {
    &:not(.ant-btn-dangerous):not(:disabled) {
      background-color: ${getCssVar('button.active.backgroundColor')};
      color: ${getCssVar('button.active.color')};
    }
  }

  &.ant-btn-loading,
  &:disabled {
    background-color: ${getCssVar('buttonDisabled.backgroundColor')} !important;
    color: ${getCssVar('buttonDisabled.color')} !important;

    &:hover {
      background-color: ${getCssVar('buttonDisabled.backgroundColor')} !important;
      box-shadow: none;
      transform: none;
    }
  }
`;
