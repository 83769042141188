import { Permission } from 'store';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { routesNeedSuperAdmin, routesToPermissionName } from './constants';

export const getPermissionLevelToSupportGrid = (path: string, permissions: Permission[]) => {
  if (path === '/support_requests') {
    const supportPermissions = permissions.filter(
      (item: Permission) => item.option === PermissionOptions.SupportRequests,
    );

    const hasAccessToSupports = supportPermissions.some(
      (item) => item.level !== PermissionsLevel.NoAccess,
    );

    if (hasAccessToSupports) {
      return PermissionsLevel.ViewOnly;
    }
  }

  return undefined;
};

export const getPermissionLevel = (
  path: string,
  permissions: Permission[],
  additionalCheck?: () => PermissionsLevel | undefined,
) => {
  if (additionalCheck && additionalCheck() !== undefined) {
    return additionalCheck();
  }

  return permissions.find((permission: Permission) => {
    const permissionByRoute = routesToPermissionName[path];

    if (!permissionByRoute) {
      return undefined;
    }

    const value = Array.isArray(permissionByRoute) ? permissionByRoute : [permissionByRoute];

    return value.includes(permission.option as PermissionOptions);
  })?.level;
};

export const checkSuperAdminPermission = (path: string, isSuperAdmin: boolean | undefined) =>
  routesNeedSuperAdmin.includes(path) ? isSuperAdmin : true;
