export enum CmdbTabsTitles {
  Vulnerabilities = 'Vulnerabilities',
  AssetDetails = 'Asset details',
  InterfaceDetails = 'Interface details',
  InstalledSoftware = 'Installed software',
  PatchManagement = 'Patch management',
  Alarms = 'Alarms',
  SuppressedVulnerabilities = 'Suppressed Vulnerabilities',
}

export enum CardTitles {
  AssetOwner = 'Asset owner',
}

export enum CmdbTabKeys {
  VULNERABILITIES = 'VULNERABILITIES',
  ASSETS_DETAILS = 'ASSETS_DETAILS',
  INTERFACE_DETAILS = 'INTERFACE_DETAILS',
  INSTALLED_SOFTWARE = 'INSTALLED_SOFTWARE',
  PATCH_MANAGEMENT = 'PATCH_MANAGEMENT',
  ALARMS = 'ALARMS',
  SUPPRESSED_VULNERABILITIES = 'SUPPRESSED_VULNERABILITIES',
}
