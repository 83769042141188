import React from 'react';
import { VulnerabilitiesTable } from './VulnerabilitiesTable';
import { VulnerabilitiesLevelChart } from './VulnerabilitiesLevelChart';

export const Vulnerabilities = () => {
  return (
    <>
      <VulnerabilitiesLevelChart />
      <VulnerabilitiesTable />
    </>
  );
};
