import { Col, Row, Tabs } from 'antd';
import { initialPagination } from 'app-constants';
import { renderValue } from 'pages/CmdbHostDetails/helpers';
import {
  BoxIcon,
  CardStyled,
  DescriptionBox,
  Descriptions,
  PatchSummaryLink,
  TabsStyled,
  Title,
} from 'pages/CmdbHostDetails/styled';
import { initialOrderBy as assetOrderBy } from 'pages/PatchManagementAssets/constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Filter, PatchStatus } from 'services/api';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { Card, Icon, InnerLayout } from 'shared';
import { useApp, useCustomer, useLoading, usePmPatches } from 'store';
import { patchesApi } from 'store/patchManagement';
import { icons } from 'styles/theme';
import { useTheme } from 'styled-components';
import { AffectedApplications } from './components/AffectedApplications/AffectedApplications';
import { PatchDetails } from './components/PatchDetails/PatchDetails';
import { ResolvedVulnerabilities } from './components/ResolvedVulnerabilities/ResolvedVulnerabilities';
import { Supersedes } from './components/Supersedes/Supersedes';
import { getLayoutConfig } from './helpers';
import { MainInfoTitles, SummaryTitles, TabTitles } from './types';
import { SeverityOptions } from '../PatchManagementPatches/constants';
import { PatchManagementTabKeys } from './constants';

const { TabPane } = Tabs;

export const PatchManagementPatchDetails = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { state: historyState, pathname } = useLocation<HistoryState>();
  const { id } = useParams<{ id: string }>();
  const loading = useLoading([patchesApi.getPatchDetails.typePrefix])[
    patchesApi.getPatchDetails.typePrefix
  ];
  const { details } = usePmPatches();
  const customerId = useCustomer();
  const { isMobile } = useApp();

  const severity = SeverityOptions.find((item) => item.value === details.vendorSeverity);

  const layoutConfig = getLayoutConfig(details.title, severity?.label, historyState, pathname);

  useEffect(() => {
    dispatch(patchesApi.getPatchDetails({ customerId, patchId: id }));
  }, [dispatch, customerId, id]);

  const routeParams = (patchStatus: string) => ({
    pathname: Routes.PATCH_MANAGEMENT_ASSETS,
    search: getQueryString({
      pagination: initialPagination,
      orderBy: assetOrderBy,
      filter: {
        fields: [
          {
            name: 'patchStatus',
            value: [patchStatus],
            type: Filter.type.MULTIPLE,
          },
          {
            name: 'patchId',
            value: id,
            type: Filter.type.VALUE,
          },
        ],
      },
    }),
    state: {
      backTo: {
        route: `${Routes.PATCH_MANAGEMENT_PATCHES}/${id}`,
        title: `Back to ${details.title}`,
      },
    },
  });

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={32}>
        <Col span={32} xs={24} xxl={16} xl={16} order={isMobile ? 2 : 1}>
          <Card
            height={theme.sizes.full}
            bodyPadding={!isMobile ? undefined : theme.sizes[0]}
            cardPadding={
              !isMobile
                ? theme.sizes['2.5']
                : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes[5]}`
            }
            showLoader={loading}
          >
            <TabsStyled
              className='customTabs'
              defaultActiveKey={historyState?.activeTabKey || PatchManagementTabKeys.PATCH_DETAILS}
            >
              <TabPane tab={TabTitles.PatchDetails} key={PatchManagementTabKeys.PATCH_DETAILS}>
                <PatchDetails details={details} />
              </TabPane>

              <TabPane
                tab={`${TabTitles.AffectedApplications} (${details.product?.length || 0})`}
                key={PatchManagementTabKeys.AFFECTED_APPLICATIONS}
              >
                <AffectedApplications details={details} />
              </TabPane>

              <TabPane
                tab={`${TabTitles.Superseded} (${details.supersedes?.length || 0})`}
                key={PatchManagementTabKeys.SUPERSEDES}
              >
                <Supersedes />
              </TabPane>
              <TabPane
                tab={`${TabTitles.ResolvedVulns} (${details.qids?.length || 0})`}
                key={PatchManagementTabKeys.RESOLVED_VULNERABILITIES}
              >
                <ResolvedVulnerabilities />
              </TabPane>
            </TabsStyled>
          </Card>
        </Col>

        <Col span={32} xs={24} xxl={8} xl={8} order={isMobile ? 2 : 1}>
          <CardStyled
            marginBottom={isMobile ? theme.spacing[4] : theme.spacing[8]}
            headStyles={
              !isMobile ? {} : { pb: theme.sizes['2.5'], titlePaddingRight: theme.sizes[0] }
            }
            bodyPadding={!isMobile ? theme.sizes['2.5'] : theme.sizes[0]}
            cardPadding={
              !isMobile
                ? theme.sizes['5.5']
                : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes['1.5']}`
            }
            showLoader={loading}
          >
            <Descriptions column={1}>
              <Descriptions.Item label={MainInfoTitles.Vendor}>
                {renderValue(details.vendor)}
              </Descriptions.Item>

              <Descriptions.Item label={MainInfoTitles.OS}>
                {renderValue(details.platform)}
              </Descriptions.Item>
            </Descriptions>
          </CardStyled>
          <CardStyled
            marginBottom={isMobile ? theme.spacing[4] : theme.spacing[8]}
            isHiddable={isMobile}
            title='Patch summary'
            headStyles={
              !isMobile
                ? { pb: theme.spacing[4], titlePaddingRight: theme.spacing['2.5'] }
                : { pb: theme.sizes['2.5'], titlePaddingRight: theme.sizes[0] }
            }
            bodyPadding={
              !isMobile
                ? `${theme.spacing[6]} ${theme.spacing['2.5']} ${theme.spacing['2.5']}`
                : theme.spacing[0]
            }
            cardPadding={
              !isMobile
                ? theme.sizes['5.5']
                : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes['1.5']}`
            }
            showLoader={loading}
          >
            <PatchSummaryLink to={routeParams(PatchStatus.MISSING)}>
              <DescriptionBox>
                <Title>{SummaryTitles.Missing}</Title>
                <BoxIcon>
                  <span>{details.missingOnAssets}</span>
                  <Icon className='arrow' margin='8px 0 0 0' component={icons.arrow_right} />
                </BoxIcon>
              </DescriptionBox>
            </PatchSummaryLink>
            <PatchSummaryLink to={routeParams(PatchStatus.INSTALLED)}>
              <DescriptionBox withoutPaddingBottom>
                <Title>{SummaryTitles.Installed}</Title>
                <BoxIcon>
                  <span>{details.installedOnAssets}</span>
                  <Icon className='arrow' margin='8px 0 0 0' component={icons.arrow_right} />
                </BoxIcon>
              </DescriptionBox>
            </PatchSummaryLink>
          </CardStyled>
        </Col>
      </Row>
    </InnerLayout>
  );
};
