import styled from 'styled-components';
import { Table } from 'antd';
import { Theme } from 'styles/theme/types';
import { Box, Card } from 'shared';
import { CardBody } from 'shared/Card/components/styled';

type Props = { theme: Theme };

export const StyledCard = styled(Card)<Props>`
  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    ${CardBody} {
      position: relative;
      :before {
        position: absolute;
        content: '';
        border-bottom: ${({ theme }) => `${theme.sizes['0.5']}  solid ${theme.colors.gray[100]}`};
        width: 90%;
        top: ${({ theme }) => theme.sizes[0]};
        z-index: 1;
      }
    }

    .gradient-pie-container {
      position: relative;
    }
  }
`;

export const DocumentsInfoContainer = styled(Box)<Props>`
  display: flex;
  flex-direction: column;
`;

export const DocumentsTitle = styled(Box)<Props>`
  font-size: ${({ theme }: Props) => theme.sizes[5]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
`;

export const DocumentsTable = styled(Table)<Props>`
  padding: ${({ theme }: Props) => `${theme.sizes[14]} ${theme.sizes[0]}`};

  .ant-table-thead {
    display: none;
  }

  .ant-table-tbody {
    > tr > td {
      border-bottom: none;
    }

    .ant-table-row {
      .ant-table-cell {
        font-size: ${({ theme }: Props) => theme.sizes[6]};
        font-weight: ${({ theme }: Props) => theme.fontWeights.black};
      }

      .ant-table-cell:last-child {
        font-size: ${({ theme }: Props) => theme.sizes[4]};
        font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
      }
    }
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    padding: ${({ theme }: Props) => `${theme.sizes[4]} ${theme.sizes[0]}`};
  }
`;

export const LinkContainer = styled(Box)<Props>`
  @media (min-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    padding-left: ${({ theme }: Props) => theme.sizes['2.5']};
  }
`;
