import * as React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { DPLCategory } from 'services/api';

type GetColumnsProps = () => ColumnsType<DPLCategory>;

export const getColumns: GetColumnsProps = () => {
  return [
    {
      key: 'title',
      title: 'Category',
      dataIndex: 'title',
      render: (category: string, row: DPLCategory) => <>{`${row.id} - ${category}`}</>,
    },
  ];
};
