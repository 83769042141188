export function register() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./sw.js').then((reg) => {
      // eslint-disable-next-line
      reg.onupdatefound = () => {
        const installingWorker = reg.installing;

        if (installingWorker) {
          installingWorker.onstatechange = () => {};
        }
      };
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}

export function unregisterAll() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
  }
}

export function cleanCache() {
  if ('caches' in window) {
    caches.keys().then((keys) => {
      keys.forEach((key) => {
        caches.delete(key);
      });
    });
  }
}

// Need to call this function to remove legacy service worker (we stop to use SW)
export const removeServiceWorker = () => {
  try {
    unregisterAll();
    cleanCache();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to remove service worker', error);
  }
};
