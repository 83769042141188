import { createGlobalStyle, css } from 'styled-components';
import {
  breakpoints,
  colors,
  createDarkCSSVarsFromObject,
  createNasstarCSSVarsFromObject,
  createNormCSSVarsFromObject,
  fontWeights,
  getCssVar,
  spacing,
} from 'styles/theme/';
import React from 'react';
import { useApp } from '../../store';
import { Themes } from '../../themes/types';
import { ThemeProps } from '../../services/interfaces';

const antCssVarsOverride = css`
  body {
    font-family: ${getCssVar('lessFontFamily')};
  }

  --ant-error-color: #ff4d4f;
  --ant-error-color-active: #d9363e;
  --ant-error-color-deprecated-bg: #fff2f0;
  --ant-error-color-deprecated-border: #ffccc7;
  --ant-error-color-hover: #ff7875;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);
  --ant-info-color: #1890ff;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #91d5ff;
  --ant-primary-1: #e6f7ff;
  --ant-primary-2: #bae7ff;
  --ant-primary-3: #91d5ff;
  --ant-primary-4: #69c0ff;
  --ant-primary-5: #40a9ff;
  --ant-primary-6: #1890ff;
  --ant-primary-7: #096dd9;
  --ant-primary-color: ${getCssVar('primaryColor')};
  --ant-primary-color-active: ${getCssVar('primaryColor')};
  --ant-primary-color-active-deprecated-d-02: #dcf4ff;
  --ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --ant-primary-color-deprecated-l-20: #7ec1ff;
  --ant-primary-color-deprecated-l-35: #cbe6ff;
  --ant-primary-color-deprecated-t-20: #46a6ff;
  --ant-primary-color-deprecated-t-50: #8cc8ff;
  --ant-primary-color-hover: ${getCssVar('primaryColor')};
  --ant-primary-color-outline: ${getCssVar('primaryColor')};
  --ant-success-color: #52c41a;
  --ant-success-color-active: #389e0d;
  --ant-success-color-deprecated-bg: #f6ffed;
  --ant-success-color-deprecated-border: #b7eb8f;
  --ant-success-color-hover: #73d13d;
  --ant-success-color-outline: rgba(82, 196, 26, 0.2);
  --ant-warning-color: #faad14;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-deprecated-bg: #fffbe6;
  --ant-warning-color-deprecated-border: #ffe58f;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);
`;

const GlobalCSSVarsDark = createGlobalStyle`
  :root {
    ${createDarkCSSVarsFromObject()}
    ${antCssVarsOverride}
  }
`;

const GlobalCSSVarsLight = createGlobalStyle`
  :root {
    ${createNormCSSVarsFromObject()}
    ${antCssVarsOverride}
  }
`;

const GlobalCSSVarsNasstar = createGlobalStyle`
  :root {
    ${createNasstarCSSVarsFromObject()}
    ${antCssVarsOverride}
  }
`;

const ThemeMap = {
  [Themes.DarkNorm]: GlobalCSSVarsDark,
  [Themes.Norm]: GlobalCSSVarsLight,
  [Themes.Nasstar]: GlobalCSSVarsNasstar,
};

export const CSSVarsProvider = () => {
  const { themeName } = useApp();

  return React.createElement(ThemeMap[themeName || Themes.Norm]);
};

export const GlobalStyles = createGlobalStyle`
  .text-uppercase {
    text-transform: uppercase;
  }

  /* datePicker */
  .ant-form-item-has-error .ant-input-number:not([disabled]):hover, .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    background-color: ${getCssVar('datePicker.bg')};
  }

  .ant-picker-panel-container {
    background-color: ${getCssVar('datePicker.bg')};
  }

  .ant-picker-panel {
    border-color: ${getCssVar('divider.color')};

    .ant-picker-footer {
      border-color: ${getCssVar('divider.color')};
    }
  }


  /* svg icon clear */
  .ant-picker-clear {
    background-color: ${getCssVar('datePicker.bg')};

    svg {
      fill: ${getCssVar('datePicker.clearIconColor')};
      width: 1.15em;
      height: 1.15em;
    }
  }


  .ant-picker-header {
    color: ${getCssVar('textColor')};
    border-color: ${getCssVar('divider.color')};

    button {
      color: ${getCssVar('datePicker.textColor')};

      &:hover {
        color: ${getCssVar('primaryColor')};

      }
    }
  }

  .ant-picker-content th {
    color: ${getCssVar('textColor')};
  }

  .ant-picker-cell {
    color: ${getCssVar('textColor')};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color: ${getCssVar('datePicker.innerBg')};
  }

  .ant-picker-cell-disabled::before {
    background-color: ${getCssVar('datePicker.disabledRangeBG')};
  }

  .ant-picker-cell-disabled .ant-picker-cell-inner {
    color: ${getCssVar('textColor')};
    opacity: 0.3;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: ${getCssVar('datePicker.cellRangeBg')};
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: ${getCssVar('datePicker.cellRangeBg')};
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${getCssVar('datePicker.innerBg')};
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: ${getCssVar('datePicker.innerBg')};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-color: ${getCssVar('datePicker.rangeHoverBorderColor')};
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-color: ${getCssVar('datePicker.rangeHoverBorderColor')};
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-color: ${getCssVar('datePicker.rangeHoverBorderColor')};
  }

  .ant-form-item-has-error .ant-input-number, .ant-form-item-has-error .ant-picker {
    background-color: ${getCssVar('select.bg')};
  }

  /* input counter */
  .ant-input-textarea-show-count::after {
    color: ${getCssVar('textColor')};
  }

  /* textarea */
  .ant-form-item-control-input-content textarea {
    background-color: ${getCssVar('select.bg')};
    border-color: ${getCssVar('select.borderColor')};
    color: ${getCssVar('textColor')};

    &:hover {
      border-color: ${getCssVar('select.multiple.borderColor')};
    }
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: ${getCssVar('select.bg')};
  }


  /* input form */
  .ant-form-item-control-input-content input {
    background-color: ${getCssVar('select.bg')};
    border-color: ${getCssVar('select.borderColor')};
    color: ${getCssVar('textColor')};

    &:focus {
      border-color: ${getCssVar('input.hoverBorderColor')};
      box-shadow: none;
    }
  }

  .ant-input[disabled] {
    background-color: ${getCssVar('input.disable.bg')} !important;
    border: 2px solid ${getCssVar('input.disable.borderColor')} !important;
    color: ${getCssVar('input.disable.color')} !important;
  }

  .ant-input {
    &:-webkit-autofill {
      -webkit-text-fill-color: ${getCssVar('textColor')};
      box-shadow: 0 0 0 1000px ${getCssVar('input.bg')} inset;
      transition: background-color 5000s ease-in-out 0s;
      border-color: ${getCssVar('input.borderColor')};

      &::selection {
        -webkit-text-fill-color: ${getCssVar('input.autofillTextColor')};
      }


      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border-color: ${getCssVar('input.hoverBorderColor')};
      }
    }
  }

  .ant-input-affix-wrapper {
    background-color: ${getCssVar('select.bg')};
    border-color: ${getCssVar('input.borderColor')};
    color: ${getCssVar('textColor')};

    input {
      background-color: ${getCssVar('select.bg')};
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    &:hover {
      border-color: ${getCssVar('select.multiple.borderColor')};
    }
  }

  .ant-input-wrapper:hover {
    border-color: ${getCssVar('select.multiple.borderColor')} !important;


    input.ant-input {
      border-color: ${getCssVar('select.multiple.borderColor')} !important;
    }

    .ant-input-group-addon {
      border-color: ${getCssVar('select.multiple.borderColor')} !important;
    }
  }

  /* menu */
  .ant-dropdown-menu {
    background-color: ${getCssVar('select.bg')};
  }

  .ant-menu-sub .ant-menu-item-selected .ant-menu-title-content a:hover {
    color: ${getCssVar('menu.item.selected.color')};
  }


  /* input group select */
  .ant-cascader-menu-item {
    background-color: ${getCssVar('select.bg')};
    border-color: ${getCssVar('select.borderColor')};
    color: ${getCssVar('textColor')};
  }

  /* input select */
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${getCssVar('selectChecked')};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border-color: ${getCssVar('primaryColor')};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${getCssVar('select.bg')};
    border-color: ${getCssVar('select.borderColor')};

    .ant-select-selection-placeholder {
      color: ${getCssVar('input.placeholderColor')};
    }


    &:hover {
      border-color: ${getCssVar('select.multiple.borderColor')};
    }
  }

  .ant-select-selection-item {
    color: ${getCssVar('textColor')};
  }

  .ant-select-dropdown {
    border-radius: 4px;
    background-color: ${getCssVar('select.bg')};

    .ant-select-item {
      color: ${getCssVar('textColor')};
    }
  }

  .ant-select-arrow svg {
    fill: ${getCssVar('select.iconColor')};
  }

  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    color: ${getCssVar('textColor')};

    .anticon {
      svg {
        fill: ${getCssVar('tooltipColor')} !important;
      }
    }

    &:hover {
      background-color: ${getCssVar('select.hoverBg')};
    }
  }


  .ant-dropdown-menu-item-divider {
    background-color: ${getCssVar('divider.color')};
  }


  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${getCssVar('input.disable.bg')};
    border-color: ${getCssVar('input.disable.borderColor')};

    .ant-select-selection-item {
      color: ${getCssVar('input.disable.color')};
    }
  }

  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${getCssVar('select.bg')};
  }

  /* snackbar style */
  .ant-message-notice {
    color: ${getCssVar('textColor')};

    .ant-message-notice-content {
      background-color: ${getCssVar('snackBar.bg')};
    }
  }

  .ant-progress-text {
    color: ${getCssVar('textColor')};
  }

  /* tabs style */
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom-color: ${getCssVar('tabs.borderBottomColor')};
  }


  /* modal */

  .ant-modal {
    .ant-menu {
      background-color: ${colors.white};
    }
  }

  .ant-modal-title {
    font-weight: ${fontWeights.bold};
    font-size: ${spacing[5]};
    color: ${({ theme }) => theme.colorSet.textColor} !important;
  }

  .ant-modal-content {
    background-color: ${getCssVar('layout.modal.bg')};
    color: ${getCssVar('textColor')};

    .ant-modal-header {
      background-color: ${getCssVar('layout.modal.bg')};
      color: ${getCssVar('textColor')};
      border-color: ${getCssVar('modal.borderColor')};
    }

    .ant-menu-item {
      .ant-menu-title-content {
        font-weight: ${fontWeights.normal};
      }
    }

    .ant-modal-footer {
      border-color: ${getCssVar('modal.borderColor')};
    }

    .ant-modal-close-x {
      color: ${getCssVar('modal.closeIcon')};
    }

    .ant-modal-body {
      .ant-menu {
        border: transparent;
        background-color: ${getCssVar('layout.modal.bg')};
        color: ${getCssVar('textColor')};

        .ant-menu-item-divider {
          border-color: ${getCssVar('divider.color')};
        }
      }
    }
  }

  /* table */
  .ant-table {
    background-color: ${getCssVar('card.bg')};
  }

  .ant-empty-description {
    color: ${getCssVar('table.tr.emptyColor')};
  }

  .ant-table-placeholder > td {
    color: ${getCssVar('table.tr.emptyColor')};
  }

  .ant-table-cell-scrollbar {
    box-shadow: 0 2px transparent;
  }

  .ant-table-tbody > tr > td {
    border-bottom-color: ${getCssVar('divider.color')};
  }

  .ant-table-cell-row-hover {
    background-color: ${getCssVar('table.tr.hover.bg')} !important;
  }


  .ant-table-row-expand-icon {
    color: ${getCssVar('table.expandIcon')};
    background-color: ${getCssVar('input.bg')};
    border-color: ${getCssVar('input.borderColor')};
  }

  /* table pagination */
  .ant-pagination-options-quick-jumper input {
    background-color: ${getCssVar('select.bg')};
    border-color: ${getCssVar('select.borderColor')};
    color: ${getCssVar('textColor')};

    &:hover {
      border-color: ${getCssVar('select.multiple.borderColor')};
    }
  }

  .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: ${getCssVar('tooltipColor')} !important;
  }

  .ant-pagination-item:hover,
  .ant-pagination-item-link:hover {
    background-color: ${getCssVar('input.hoverBorderColor')} !important;
    border-color: ${getCssVar('input.hoverBorderColor')} !important;
  }


  /* description item style */
  .ant-descriptions-item-content {
    color: ${getCssVar('textColor')};
  }

  .text-primary {
    color: ${getCssVar('textColor')};
  }

  .ant-menu {
    background-color: ${getCssVar('menu.sidebar.bg')};
  }

  .ant-menu-item-selected {
    background-color: ${getCssVar('menu.item.selected.bg')} !important;
  }

  .ant-menu-submenu-popup li:hover {
    background: ${getCssVar('menu.submenu.hoverBg')};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${getCssVar('menu.submenu.hoverBg')};
  }

  /* upload card */
  .ant-card-bordered {
    background-color: ${getCssVar('select.bg')};
    color: ${getCssVar('textColor')};
    border-color: ${getCssVar('card.border')};
  }


  /* drawer */
  .ant-drawer-content {
    background-color: ${getCssVar('filterMobile')};

    .ant-drawer-body .ant-row label {
      color: ${getCssVar('textColor')} !important;
    }
  }


  /* radio */

  .ant-radio-inner {
    background-color: transparent;
    border-color: ${getCssVar('radio.inner.borderColor')};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px ${getCssVar('radio.secondary')} !important;
  }

  .ant-radio-disabled .ant-radio-inner {
    background-color: ${getCssVar('radio.inner.bg')} !important;
  }


  /* checkbox */
  .ant-checkbox-inner {
    background-color: ${getCssVar('radio.inner.bg')};
    border-color: ${getCssVar('radio.inner.borderColor')};
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${getCssVar('checkBox.borderColorAfter')};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${getCssVar('checkBox.indeterminate.bg')};
    border-color: ${getCssVar('checkBox.indeterminate.borderColor')};
  }


  /* typography */

  .ant-typography {
    h1,
    h1& {
      letter-spacing: 0.01em;
      line-height: 1.17;
      font-weight: 900;
    }

    h2,
    h2& {
      letter-spacing: 0.01em;
      line-height: 1.1;
      font-weight: 700;
    }

    h3,
    h3& {
      letter-spacing: 0.03em;
      line-height: 1;
      font-weight: 900;
    }

    h4,
    h4& {
      line-height: 1.25;
      font-weight: 700;
    }
  }

  b {
    font-weight: 800;
  }

  strong {
    font-weight: 400;
  }

  .caption {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
  }

  /* tooltip */

  .tooltipInfo {
    margin-left: 6px;
    color: ${({ theme }: ThemeProps) => theme.colorSet.tooltipColor};
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${({ theme }: ThemeProps) => theme.colorSet.tooltip.hover};
    }
  }

  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 3px;
  }

  .ant-tooltip-inner {
    border-radius: 4px;
    padding: 10px;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  /* dataPicker */

  .ant-picker-dropdown {
    .ant-tag {
      &.ant-tag-blue {
        border-color: ${({ theme }: ThemeProps) => theme.colors.gray[300]};
        background-color: ${getCssVar('datePicker.pickerPanelBg')} !important;
        border-width: 1px;
      }
    }

    .ant-picker-panel-container {
      .ant-picker-panel {
        border-bottom-width: 1px;
      }

      .ant-picker-header {
        border-bottom-width: 1px;
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme.colorSet.input.datePicker};
  }

  /* radio */
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px transparent;
  }

  /* Select */

  .ant-select-selection-search {
    color: ${getCssVar('textColor')}
  }

  .ant-select {
    &.checked .ant-select-selector {
      background-color: ${getCssVar('select.checked.bg')};
      border: 2px solid ${getCssVar('select.checked.borderColor')};

      &:hover {
        border: 2px solid ${getCssVar('select.checked.borderColor')};
      }
    }
  }

  /* input */

  .ant-form {
    .ant-form-item-label, .ant-form-item-label label {
      font-weight: ${({ theme }) => theme.colorSet.filterLabelWeight} !important;
      color: ${({ theme }) => theme.colorSet.textColor} !important;
    }
  }

  .ant-select-item {
    line-height: 28px;
  }

  /* Pagination */

  .ant-pagination {
    .ant-select-single {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          height: 36px;

          .ant-select-selection-search-input {
            height: 34px;
          }
        }
      }
    }

    .ant-select-selector {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 32px;
      }
    }

    .ant-pagination-options-quick-jumper {
      height: 36px;
      line-height: 36px;

      input {
        height: 36px;
        box-shadow: none;

        &:focus,
        &:hover {
          box-shadow: none;
        }

        &:focus,
        &-focused {
          border-color: ${getCssVar('input.hoverBorderColor')};
        }
      }
    }
  }

  .anticon svg {
    display: block;
  }

  .ant-dropdown-trigger {
    .anticon-down {
      width: auto;
      height: auto;
    }
  }

  /* status */

  .status {
    display: flex;
    align-items: center;
  }

  .ant-message-notice.black-notification {
    .anticon {
      font-size: 20px;
    }

    .ant-message-custom-content {
      display: flex;
      align-items: center;
    }

    .ant-message-notice-content {
      color: ${colors.white};
      background-color: ${colors.gray[800]};
      border-radius: 2px;
      box-shadow: 0px 8px 20px rgba(${colors.blackAlpha[200]});

      p {
        text-align: left;
        padding: 0;
        margin: 0;
      }
    }
  }

  /* Message */

  .ant-message-notice .ant-message-custom-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* left-menu */

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .ant-menu-item {
    .ant-menu-title-content {
      font-weight: 700;
    }
  }

  .ant-menu-submenu {
    font-weight: 700;
  }


  .ant-menu-sub {
    .ant-menu-title-content {
      font-weight: 500;
    }

    .ant-menu-item:first-child {
      @media (max-width: ${breakpoints.lg}) {
        margin-top: 8px;
      }
    }

    .ant-menu-item:last-child {
      @media (max-width: ${breakpoints.lg}) {
        margin-bottom: 8px;
      }
    }


    .ant-menu-item-selected {
      background-color: ${({ theme }: ThemeProps) =>
        `${theme.colorSet.leftMenu.activeSubmenuSelectedColor}`};

      .ant-menu-title-content {
        a {
          color: ${({ theme }: ThemeProps) => theme.colorSet.leftMenu.unActiveSubmenuColor};
        }
      }
    }
  }

  .ant-menu-dark .ant-menu-item:hover {
    background-color: ${({ theme }: ThemeProps) =>
      theme.colorSet.leftMenu.activeSubmenuSelectedColor};
  }

  /* icon upload */

  svg.icon_upload_centering {
    display: inline-block !important;
  }

  /* description */

  .ant-descriptions-item-label {
    font-weight: ${({ theme }) => theme.colorSet.filterLabelWeight} !important;
    color: ${({ theme }) => theme.colorSet.textColor} !important;
  }

  .vulnerability-tooltip {
    transform: translateY(20px) !important;
    padding-bottom: 5px;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      background-color: ${({ theme }) => theme.colorSet.charts.tooltip.bg};
      border-color: ${({ theme }) => theme.colorSet.charts.tooltip.bg};
      color: ${({ theme }) => theme.colorSet.charts.tooltip.color};
      font: 14px / 21px sans-serif;

      ul {
        padding-left: 0;
        list-style-type: none;
        max-width: 250px;
        margin-bottom: 0;

        > li {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;
