import React from 'react';
import { mapValues } from 'lodash';

const systemIcons = {
  light: (
    <svg
      width='152'
      height='108'
      viewBox='0 0 152 108'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='152' height='108' fill='#EEEDE8' />
      <rect x='12' y='12' width='32' height='8' fill='#866930' />
      <rect x='12' y='28' width='32' height='8' fill='#C2BAAA' />
      <rect x='12' y='44' width='32' height='8' fill='#C2BAAA' />
      <rect x='12' y='60' width='32' height='8' fill='#C2BAAA' />
      <rect x='12' y='88' width='32' height='8' fill='#C2BAAA' />
      <rect x='52' y='12' width='32' height='8' fill='#C2BAAA' />
      <rect x='120' y='12' width='8' height='8' fill='#C2BAAA' />
      <rect x='132' y='12' width='8' height='8' fill='#866930' />
      <rect x='53' y='29' width='86' height='66' stroke='#C2BAAA' strokeWidth='2' />
    </svg>
  ),
  dark: (
    <svg
      width='152'
      height='108'
      viewBox='0 0 152 108'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='152' height='108' fill='#161716' />
      <rect x='12' y='12' width='32' height='8' fill='#BEA167' />
      <rect x='12' y='28' width='32' height='8' fill='#C2BAAA' />
      <rect x='12' y='44' width='32' height='8' fill='#C2BAAA' />
      <rect x='12' y='60' width='32' height='8' fill='#C2BAAA' />
      <rect x='12' y='88' width='32' height='8' fill='#C2BAAA' />
      <rect x='52' y='12' width='32' height='8' fill='#C2BAAA' />
      <rect x='120' y='12' width='8' height='8' fill='#C2BAAA' />
      <rect x='132' y='12' width='8' height='8' fill='#BEA167' />
      <rect x='53' y='29' width='86' height='66' stroke='#C2BAAA' strokeWidth='2' />
    </svg>
  ),
  system: (
    <svg
      width='160'
      height='116'
      viewBox='0 0 160 116'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_14802_14913)' filter='url(#filter0_d_14802_14913)'>
        <g clipPath='url(#clip1_14802_14913)'>
          <rect width='79' height='108' transform='translate(80 4)' fill='#161716' />
          <rect x='59' y='16' width='32' height='8' fill='#C2BAAA' />
          <rect x='127' y='16' width='8' height='8' fill='#C2BAAA' />
          <rect x='139' y='16' width='8' height='8' fill='#BEA167' />
          <rect x='60' y='33' width='86' height='66' stroke='#C2BAAA' strokeWidth='2' />
        </g>
        <rect width='152' height='108' transform='translate(4 4)' fill='#161716' />
        <rect x='16' y='16' width='32' height='8' fill='#BEA167' />
        <rect x='16' y='32' width='32' height='8' fill='#C2BAAA' />
        <rect x='16' y='48' width='32' height='8' fill='#C2BAAA' />
        <rect x='16' y='64' width='32' height='8' fill='#C2BAAA' />
        <rect x='16' y='92' width='32' height='8' fill='#C2BAAA' />
        <rect x='56' y='16' width='32' height='8' fill='#C2BAAA' />
        <rect x='124' y='16' width='8' height='8' fill='#C2BAAA' />
        <rect x='136' y='16' width='8' height='8' fill='#BEA167' />
        <rect x='57' y='33' width='86' height='66' stroke='#C2BAAA' strokeWidth='2' />
        <g clipPath='url(#clip2_14802_14913)'>
          <rect width='76' height='108' transform='translate(4 4)' fill='#EEEDE8' />
          <rect x='16' y='16' width='32' height='8' fill='#866930' />
          <rect x='16' y='32' width='32' height='8' fill='#C2BAAA' />
          <rect x='16' y='48' width='32' height='8' fill='#C2BAAA' />
          <rect x='16' y='64' width='32' height='8' fill='#C2BAAA' />
          <rect x='16' y='92' width='32' height='8' fill='#C2BAAA' />
          <rect x='56' y='16' width='32' height='8' fill='#C2BAAA' />
          <rect x='57' y='33' width='86' height='66' stroke='#C2BAAA' strokeWidth='2' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_14802_14913'
          x='0'
          y='0'
          width='160'
          height='116'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_14802_14913'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.2 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_14802_14913' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_14802_14913'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_14802_14913'>
          <rect width='152' height='108' fill='white' transform='translate(4 4)' />
        </clipPath>
        <clipPath id='clip1_14802_14913'>
          <rect width='79' height='108' fill='white' transform='translate(80 4)' />
        </clipPath>
        <clipPath id='clip2_14802_14913'>
          <rect width='76' height='108' fill='white' transform='translate(4 4)' />
        </clipPath>
      </defs>
    </svg>
  ),
};

const jsxToFC = (jsx: JSX.Element) => () => jsx;
const modeIcons = mapValues(systemIcons, jsxToFC);

export { modeIcons };
