/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerId } from '../models/CustomerId';
import type { PeopleScoreResponse } from '../models/PeopleScoreResponse';
import type { ScoreResponse } from '../models/ScoreResponse';
import type { TechnologyScoreResponse } from '../models/TechnologyScoreResponse';
import { request as __request } from '../core/request';

export class DashboardScoresService {
  /**
   * Calculated score for boost in dashboard
   * Get calculated score for boost in dashboard
   * @returns ScoreResponse Customer score for boost in dashboard
   * @throws ApiError
   */
  public static async getBoostScore({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<ScoreResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/boost-score`,
      errors: {
        400: `An error message when get scores`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Calculated score for process in dashboard
   * Get calculated score for process in dashboard
   * @returns ScoreResponse Customer score for process in dashboard
   * @throws ApiError
   */
  public static async getProcessScore({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<ScoreResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/process-score`,
      errors: {
        400: `An error message when get scores`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Calculated score for people in dashboard
   * Get calculated score for people in dashboard
   * @returns PeopleScoreResponse Customer score for people in dashboard
   * @throws ApiError
   */
  public static async getPeopleScore({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<PeopleScoreResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/people-score`,
      errors: {
        400: `An error message when get scores`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Calculated score for technology in dashboard
   * Get calculated score for technology in dashboard
   * @returns TechnologyScoreResponse Customer score for technology in dashboard
   * @throws ApiError
   */
  public static async getTechnologyScore({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<TechnologyScoreResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/technology-score`,
      errors: {
        400: `An error message when get scores`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
