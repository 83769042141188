import React from 'react';

export const FIXED_VALUE = 2;

type CybersafetyAndPhishingTableProps = {
  trainingStatus?: ITrainingStatusResponse;
  refresherStatistics?: IRefresherRatesResponse;
  phishing?: IPhishingLastThirtyDaysResponse;
};

interface ThKeys {
  [key: string]: number | undefined | IPhishingStatisticsThirtyDaysSchema;
}

interface ITrainingStatusResponse extends ThKeys {
  completed?: number;
  started?: number;
  notStarted?: number;
}

interface IRefresherRatesResponse extends ThKeys {
  passed?: number;
  failed?: number;
  ignored?: number;
}

interface IPhishingStatisticsThirtyDaysSchema extends ThKeys {
  providedCredentials?: number;
  opened?: number;
  noActions?: number;
  clicked?: number;
}

interface ThKeysIPhishingStatisticsThirtyDaysSchema {
  [key: string]: number | undefined | IPhishingStatisticsThirtyDaysSchema;
}

interface IPhishingLastThirtyDaysResponse extends ThKeysIPhishingStatisticsThirtyDaysSchema {
  details?: IPhishingStatisticsThirtyDaysSchema;
}

export type RowTitle = Record<string, string>;

const phishingDetailsRowsTitle: RowTitle = {
  totalEmails: 'Total Emails',
  noActions: 'No Action',
  clicked: 'Clicked',
  opened: 'Opened',
  providedCredentials: 'Entered Data',
};

const trainingStatusRowsTitle: RowTitle = {
  totalUsers: 'Total Users',
  completed: 'Completed',
  started: 'Started',
  notStarted: 'Not started',
};

const refresherStatisticsRowsTitle: RowTitle = {
  passed: 'Passed',
  failed: 'Failed',
  ignored: 'Ignored',
};

export const CybersafetyAndPhishingTable = ({
  phishing,
  trainingStatus,
  refresherStatistics,
}: CybersafetyAndPhishingTableProps) => {
  const phishingDetails = phishing?.details || {};

  const phishingDetailsRowsTitleSum = Object.values(phishing?.details || 0).reduce(
    (acc: number, a: number) => acc + a,
    0,
  );

  const trainingStatusRowsTitleSum = Object.values(trainingStatus || 0).reduce(
    (acc: number, a: number) => acc + a,
    0,
  );

  return (
    <div className='people-score-tables'>
      <table className='people-tb'>
        <tbody>
          <tr>
            <td colSpan={3}>
              <p className='h4 mb-1'>Phishing: last 30 Days</p>
            </td>
          </tr>
          {phishingDetails &&
            Object.keys(phishingDetailsRowsTitle).map((key) => (
              <tr key={key}>
                <td>{phishingDetailsRowsTitle[key] || ''}</td>
                <td>-</td>
                <td className='text-center'>
                  {key === 'totalEmails' ? phishingDetailsRowsTitleSum : phishingDetails[key] || 0}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <table className='people-tb'>
        <tbody>
          <tr>
            <td colSpan={3}>
              <p className='h4 mb-1'>Current Training Status </p>
            </td>
          </tr>
          {trainingStatus &&
            Object.keys(trainingStatusRowsTitle).map((key) => (
              <tr key={key}>
                <td>{trainingStatusRowsTitle[key] || ''}</td>
                <td>-</td>
                <td className='text-center'>
                  {key === 'totalUsers' ? trainingStatusRowsTitleSum : trainingStatus[key] || 0}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <table className='people-tb'>
        <tbody>
          <tr>
            <td colSpan={3}>
              <p className='h4 mb-1'>Refresher Tests: total </p>
            </td>
          </tr>
          {refresherStatistics &&
            Object.keys(refresherStatisticsRowsTitle).map((key) => (
              <tr key={key}>
                <td>{refresherStatisticsRowsTitle[key] || ''}</td>
                <td>-</td>
                <td className='text-center'>{refresherStatistics[key] || 0}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
