import { Tooltip } from 'antd';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)``;

export const TooltipWrapper = styled.div`
  .ant-tooltip-inner {
    background-color: ${({ theme }) => theme.colorSet.charts.tooltip.bg};
    border-color: ${({ theme }) => theme.colorSet.charts.tooltip.bg};
    color: ${({ theme }) => theme.colorSet.charts.tooltip.color};

    ul {
      list-style-type: none;
      padding-left: 0;
      width: 250px;

      > li {
        align-items: center;
        display: flex;
      }
    }
  }
`;
