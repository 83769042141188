import moment from 'moment';
import { getData } from 'pages/VulnerabilityManagement/helpers';
import {
  CertificateStatusSchema,
  DashboardScores,
  VulnerabilityTrendPointSchema,
} from 'services/api';
import { OptionParams } from 'shared/charts';
import { IEChartsOption } from 'shared/charts/interfaces';
import { Theme } from 'styles/theme/types';
import { thirtyDays } from 'app-constants';
import { FailedCertificateStatuses } from 'pages/Process/constants';

type ReportProccessStatusNameAndColorType = {
  expired: boolean | null;
  isCertificateSoonExpired: boolean | null;
  status?: string;
  theme: Theme;
  expirationDate?: number;
};

export const getHtmlReport = (html: string, styles: string) => {
  return `<!DOCTYPE html>
  <html lang="en">
      <head>
          <meta charset="UTF-8" />
          <title>Monthly Report</title>
          ${styles}
      </head>
      <body>
        ${html}
      </body>
  </html>`;
};

export const getSortedDashboardScores = (scores: DashboardScores): DashboardScores => {
  return scores.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
};

export const getReportProccessStatusNameAndColor = ({
  expired,
  isCertificateSoonExpired,
  status,
  theme,
  expirationDate,
}: ReportProccessStatusNameAndColorType) => {
  if (
    !status ||
    !expirationDate ||
    FailedCertificateStatuses.includes(status as CertificateStatusSchema)
  ) {
    return {
      name: 'Inactive',
      color: theme.colorSet.process.expired,
    };
  }

  if (expired) {
    return {
      name: 'Expired',
      color: theme.colorSet.process.expired,
    };
  }

  if (isCertificateSoonExpired) {
    return {
      name: 'About to expire',
      color: theme.colorSet.process.inProgress,
    };
  }

  return {
    name: 'Active',
    color: theme.colorSet.process.success,
  };
};

export const getOption = ({
  values,
  theme,
  height,
  top,
  bottom,
  left,
  right,
}: OptionParams<VulnerabilityTrendPointSchema[]>): IEChartsOption => {
  const [seriesData, xAxisData] = getData(values);
  const color = [theme.colors.blue[600]];

  return {
    grid: {
      left: left || '10px',
      top: top || '35px',
      right,
      bottom: bottom || '10px',
      containLabel: true,
      height: height || '260px',
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, '10%'],
      axisLabel: {
        interval: thirtyDays,
      },
      data: xAxisData.map((value) => moment(Number(value)).format('MMM')),
    },
    yAxis: {
      type: 'value',
      min: 0,
    },
    series: [
      {
        type: 'line',
        name: 'Total',
        data: seriesData,
        showSymbol: seriesData.length <= 1 && seriesData[0] !== 0,
        symbolSize: 6,
      },
    ],
    color,
  };
};
