import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import { ThemeProvider } from 'themes';

import { PersistGate } from 'redux-persist/integration/react';
import { FeatureFlagProvider } from 'services/feature-flag';
import { App } from './App';
import { removeServiceWorker } from './sw-registration';

ReactDOM.render(
  <FeatureFlagProvider>
    <Provider store={store}>
      {/* This component ensures that your app waits for the persisted state to be retrieved before rendering */}
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </FeatureFlagProvider>,

  document.getElementById('root'),
);

removeServiceWorker();
