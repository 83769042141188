/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InputType {
  SELECTOR = 'SELECTOR',
  DATE = 'DATE',
  GROUP_SELECTOR = 'GROUP_SELECTOR',
  RADIO = 'RADIO',
}
