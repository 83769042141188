import { Dropdown } from 'antd';
import React from 'react';
import { Icon } from 'shared';
import { useTheme } from 'styled-components';
import { CustomMenuItem, Menu } from 'shared/Menu/Menu.styled';
import { manageTagDropDownItems, ManageTagDropdownLabels } from '../constants';
import { Divider } from '../../Process/styled';
import { BulkActionTagsSelector } from './BulkActionTagsSelector';
import { getCssVar } from '../../../styles/theme';

type IManageTagDropdownProps = {
  children: JSX.Element | JSX.Element[];
  placement?: 'bottomLeft' | 'bottomCenter';
  disabled?: boolean;
  onClick: (soft: boolean, action?: string, selectedTag?: string) => void;
};

type DropdownItem = {
  key: string;
  label: string;
  danger?: boolean;
};

export const ManageTagDropdown = ({
  children,
  onClick,
  placement,
  disabled,
}: IManageTagDropdownProps) => {
  const theme = useTheme();
  const [showTagLabel, setShowLabel] = React.useState('');
  const handleItemClick = ({ key }: { key: React.Key }) => {
    setShowLabel(key as string);
  };

  React.useEffect(() => {
    return () => {
      setShowLabel('');
    };
  }, []);

  const defaultDropDownOptions = (
    <Menu onClick={handleItemClick} style={{ background: getCssVar('select.bg') }}>
      {manageTagDropDownItems.map((item: DropdownItem) => (
        <>
          {item.danger && <Divider />}
          <Menu.Item key={item.key} danger={item.danger}>
            <Icon component={item.danger ? theme.icons.delete : theme.icons.add} />{' '}
            <span style={{ color: item.danger ? getCssVar('errorColor') : getCssVar('textColor') }}>
              {item.label}
            </span>
          </Menu.Item>
        </>
      ))}
    </Menu>
  );

  const dropDownActions = (
    <Menu>
      <CustomMenuItem>
        <BulkActionTagsSelector
          closeSelector={() => setShowLabel('')}
          label={showTagLabel}
          hideCreateTagButton={showTagLabel === ManageTagDropdownLabels.AddTags}
          selectTag={onClick}
        />
      </CustomMenuItem>
    </Menu>
  );

  const dropdownProps: {
    visible?: boolean;
  } = {};

  if (showTagLabel) {
    dropdownProps.visible = true;
  }

  return (
    <Dropdown
      overlay={showTagLabel ? dropDownActions : defaultDropDownOptions}
      trigger={['click']}
      disabled={disabled}
      placement={placement || 'bottomLeft'}
      {...dropdownProps}
    >
      {children}
    </Dropdown>
  );
};
