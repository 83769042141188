import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { Box } from 'shared';
import { useScreenSizes } from 'services/hooks/useScreenSizes';
import { auth, useApp, useAuth } from 'store';
import { ValidateTwoFARequest } from 'services/api';
import { useTheme } from 'styled-components';
import { TwoFAStep } from '../TwoFAStep';
import { InputTwoFA, TwoFAText, TwoFAWrapper } from '../styled';

type Props = {
  isPending: boolean;
  isRequestError?: boolean;
  onSubmit: (requestBody: ValidateTwoFARequest) => void;
  isOnSetup?: boolean;
};

export const TwoFAValidation = ({ isPending, isRequestError, onSubmit, isOnSetup }: Props) => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const [isDesktop] = useScreenSizes();
  const [code, setCode] = React.useState('');
  const { token } = useAuth();
  const { user } = useAuth();
  const is2FASetted = user ? user.twoFA : false;
  const title = `${!is2FASetted ? 'Step 3. ' : ''}Verify code`;
  const inputEl = React.useRef<Input>(null);

  const dispatch = useDispatch();

  const handleCodeChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const MAX_LENGTH = 6;
    const isInRange = value.length <= MAX_LENGTH;
    const isFull = value.length === MAX_LENGTH;

    if (isInRange) {
      dispatch(auth.actions.setError(''));
      setCode(value);
    }

    if (isFull && token) {
      const requestBody = { code: value, token };

      onSubmit(requestBody);
    }
  };

  React.useEffect(() => {
    dispatch(auth.actions.setError(''));
  }, [dispatch]);

  React.useEffect(() => {
    if (inputEl.current && isRequestError) {
      inputEl.current.focus();
    }
  }, [isRequestError]);

  return (
    <TwoFAStep
      title={
        is2FASetted ? (
          title
        ) : (
          <Box
            fontSize={theme.sizes[4]}
            fontWeight={theme.colorSet.login.titleFontWeight}
            color={theme.colorSet.textColor}
          >
            {title}
          </Box>
        )
      }
      description={
        <TwoFAWrapper>
          <Box
            textAlign={isMobile ? 'center' : 'left'}
            whiteSpace={isMobile ? undefined : 'nowrap'}
            ml='-16px'
            mt='16px'
            color={!isDesktop ? theme.colors.white : theme.colorSet.textColor}
          >
            Enter the <strong>6-digit</strong> code generated by your authentication app
          </Box>
          {!isOnSetup && (
            <Box color={!isDesktop ? theme.colors.white : theme.colorSet.textColor}>
              (use <TwoFAText>Google Authenticator</TwoFAText> or <TwoFAText>Authy</TwoFAText>).
            </Box>
          )}
        </TwoFAWrapper>
      }
      isLast={!is2FASetted}
    >
      <Box mt={theme.spacing[4]}>
        <InputTwoFA
          ref={inputEl}
          value={code}
          autoFocus
          $isRequestError={!!isRequestError}
          onChange={handleCodeChange}
          suffix={isPending ? <LoadingOutlined /> : <span />}
          disabled={isPending}
        />
      </Box>
    </TwoFAStep>
  );
};
