import { Card, Button } from 'shared';
import { useTheme } from 'styled-components';
import {
  useApp,
  useCustomer,
  servicesIntegrationsApi,
  AppDispatch,
  PayloadActionType,
} from 'store';
import {
  ConnectionDetails as ConnectionDetailsResponse,
  TestServiceConnectionResponse,
} from 'services/api';
import { formatDate, renderValue } from 'pages/ServicesIntegrations/helpers';
import { ServiceIntegrationType } from 'pages/ServicesIntegrations/types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { RequestStatus } from 'store/loading/slice';
import { showError, showSuccess } from 'services/helpers';
import { useUserPermissions } from 'pages/ServicesIntegrations/hooks';
import { StatusTag } from '../StatusTag';
import { ConnectionSettingsFactory } from './ConnectionSettingsFactory';
import { ConnectionDetailsItem } from './ConnectionDetailsItem';
import { DetailsStatusWrapper } from './styled';
import { GUIDANCE_DETAILS_TAB_HEIGHT } from '../../constants';

type ConnectionDetailsProps = ConnectionDetailsResponse & {
  serviceName: ServiceIntegrationType;
};

export const ConnectionDetails = ({
  credentials,
  status,
  connectedAt,
  connectedBy,
  lastSyncDate,
  serviceName,
}: ConnectionDetailsProps) => {
  const { sizes } = useTheme();
  const { isMobile, isLargeDesktop } = useApp();
  const customerId = useCustomer();
  const { id: serviceId } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const { canSetupConnection } = useUserPermissions();

  const marginTop = isLargeDesktop ? GUIDANCE_DETAILS_TAB_HEIGHT : '0px';

  return (
    <Card cardPadding={!isMobile ? sizes['5.5'] : `${sizes[4]} ${sizes[5]}`} marginTop={marginTop}>
      <ConnectionDetailsItem
        label='Status'
        value={
          <DetailsStatusWrapper>
            <StatusTag isConnected={status} style={{ margin: '0' }} />
            {canSetupConnection && (
              <Button
                type='secondary'
                size='small'
                style={{ fontSize: '14px' }}
                disabled={!connectedAt}
                onClick={async () => {
                  let newStatus = status;

                  const result = (await dispatch(
                    servicesIntegrationsApi.testExistingConnection({ customerId, serviceId }),
                  )) as PayloadActionType<TestServiceConnectionResponse>;

                  if (result.meta.requestStatus === RequestStatus.Rejected) {
                    // eslint-disable-next-line no-console
                    console.log('testExistingServiceConnection', result.error);

                    showError();
                  } else {
                    newStatus = result.payload.status;

                    if (newStatus) {
                      showSuccess('Connection OK');
                    } else {
                      showError('Connection failed');
                    }
                  }

                  if (status !== newStatus) {
                    dispatch(servicesIntegrationsApi.getServices({ customerId }));
                    dispatch(servicesIntegrationsApi.getServiceDetails({ customerId, serviceId }));
                  }
                }}
              >
                Test connection
              </Button>
            )}
          </DetailsStatusWrapper>
        }
      />

      <ConnectionSettingsFactory serviceName={serviceName} credentials={credentials} />

      <ConnectionDetailsItem label='Connected at' value={formatDate(connectedAt, true, true)} />
      <ConnectionDetailsItem label='Connected by' value={renderValue(connectedBy)} />
      <ConnectionDetailsItem label='Last sync' value={formatDate(lastSyncDate, true, true)} />
    </Card>
  );
};
