import { saveAs } from 'file-saver';

export const downloadZip = (data: Blob | string, name: string) => {
  saveAs(
    new Blob([data], {
      type: 'application/octet-stream;',
    }),
    `${name}.zip`,
  );
};
