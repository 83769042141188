import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  ApiError,
  CMDBReportType,
  CmdbService,
  CustomerId,
  Filters,
  GridRequestParams,
  HostId,
  OrderBy,
  RequestWithOnlyCustomer,
  SeverityLevel,
} from '../../services/api';

const fetchData = createAsyncThunk('cmdb/fetch', async (requestBody: GridRequestParams) => {
  try {
    const data = await CmdbService.getCmdb(requestBody);

    return data;
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});
const getHostsTrendsByCriticalities = createAsyncThunk(
  'cmdb/get/criticalities',
  async (requestBody: RequestWithOnlyCustomer & { criticalities: SeverityLevel[] }) => {
    try {
      const data = await CmdbService.getHostsTrendsByCriticalities({
        ...requestBody,
        startDate: moment().subtract(1, 'month').valueOf(),
        endDate: moment().valueOf(),
      });

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getIsolatedDevices = createAsyncThunk(
  'cmdb/get/isolatedDevices',
  async (requestBody: RequestWithOnlyCustomer) => {
    try {
      const data = await CmdbService.getIsolatedDevicesTrend({
        customerId: requestBody.customerId,
        startDate: moment().subtract(1, 'month').valueOf(),
        endDate: moment().valueOf(),
      });

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const deleteHosts = createAsyncThunk(
  'cmdb/host/delete',
  async (requestBody: { customerId: CustomerId; hostIds: HostId[]; soft?: boolean }) => {
    try {
      const response = await Promise.all(
        requestBody.hostIds.map((hostId: HostId) =>
          CmdbService.deleteHost({
            customerId: requestBody.customerId,
            hostId,
            soft: requestBody.soft || undefined,
          }),
        ),
      );

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getCSVReport = createAsyncThunk(
  'cmdb/get/csv',
  async (requestBody: {
    customerId: CustomerId;
    orderBy: OrderBy;
    filter?: Filters;
    reportType?: CMDBReportType;
    agedHosts?: boolean;
    connectionId?: string;
  }) => {
    try {
      const response = await CmdbService.exportCmdb(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const setTag = createAsyncThunk(
  'cmdb/tags/set',
  async (requestBody: { customerId: CustomerId; hostId: string; tagId: string }) => {
    try {
      const response = await CmdbService.setTag(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const removeTag = createAsyncThunk(
  'cmdb/tags/remove',
  async (requestBody: { customerId: CustomerId; hostId: string; tagId: string }) => {
    try {
      const response = await CmdbService.removeTag(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const uploadTags = createAsyncThunk(
  'cmdb/upload-tags',
  async (requestBody: { customerId: CustomerId; requestBody: Blob }) => {
    try {
      const response = await CmdbService.importTags(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const cmdbApi = {
  fetchData,
  getIsolatedDevices,
  getHostsTrendsByCriticalities,
  deleteHosts,
  getCSVReport,
  setTag,
  removeTag,
  uploadTags,
};

export type CmdbApiType = typeof cmdbApi;
