import styled from 'styled-components';
import { ThemeProps } from 'services/interfaces';
import { Box } from 'shared';
import { getCssVar } from 'styles/theme';
import { Count } from '../styled';

export const MTTIWrapper = styled(Box)`
  align-items: end;
  display: flex;
  justify-content: center;
  margin: 40px 0 67px 0;
`;

export const Total = styled(Box)<ThemeProps>`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 2px;
`;

export const TimeCount = styled(Count)`
  font-size: ${({ theme }: ThemeProps) => theme.sizes[9]};
  margin-left: 5px;
`;

export const TimeName = styled(Box)`
  padding: 5px 5px 7px;
`;

export const Empty = styled(Box)`
  color: ${getCssVar('textColor')};
  display: flex;
  height: 33px;
  justify-content: center;
  opacity: 0.5;
`;
