import { OrderBy } from 'services/api';

export const getOrderType = (order: string) => {
  switch (order) {
    case 'ascend':
      return OrderBy.type.ASC;
    default:
      return OrderBy.type.DESC;
  }
};
