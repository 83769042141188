import React from 'react';
import { cookieImageUrl } from '../constants';

const actionSteps = [
  'Donec luctus nulla nibh, eleifend accumsan.',
  'Maecenas ut tellus ante. Vivamus tellus lorem, mattis nec odio vitae.',
  'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices.',
  'Vestibulum rhoncus quis augue eu sagittis. Id rutrum velit.',
];

export const ReportActionsSection = () => {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <img width='155' height='120' alt='CookieImage' src={cookieImageUrl} />
          </td>
          <td>
            <table className='action-stepper'>
              <tbody>
                <tr className='row-steps'>
                  {actionSteps.map((item, index) => (
                    <td key={item}>
                      <div className={`action ac-${index + 1}`}>Action {index + 1}</div>
                      <div className='line-point'>
                        <span className='point' />
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className='row-text'>
                  {actionSteps.map((item) => (
                    <td key={item}>
                      <p>{item}</p>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
