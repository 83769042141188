import React from 'react';
import { Box, Button, Icon } from 'shared';
import { UserSchema } from 'services/api';
import { useTheme } from 'styled-components';
import { MobileActionsPopup } from './MobileActionsPopup';

type MobileUserActionsProps = {
  usersRow: UserSchema;
  onSuccess: () => void;
};

export const MobileUserActions = ({ usersRow, onSuccess }: MobileUserActionsProps) => {
  const theme = useTheme();
  const [isVisibleActionsPopup, setIsVisibleActionsPopup] = React.useState(false);

  return (
    <Box onClick={(event: React.MouseEvent) => event.stopPropagation()}>
      <Button
        className='btn-table-action'
        type='link'
        icon={
          <Icon component={theme.icons.more_vert} onClick={() => setIsVisibleActionsPopup(true)} />
        }
      />
      <MobileActionsPopup
        isVisible={isVisibleActionsPopup}
        setIsVisible={setIsVisibleActionsPopup}
        userData={usersRow}
        onSuccess={onSuccess}
      />
    </Box>
  );
};
