import React, { useMemo } from 'react';
import { Empty } from 'antd';
import { isEmptyObject } from 'services/helpers';
import { useTheme } from 'styled-components';
import { Values } from '../charts/types';
import { Bar, BarItem } from './VulnerabilitiesBar.styled';
import { Tooltip } from './Tooltip';

type Props = {
  values: Values;
};

const oneHundred = 100;

export function VulnerabilitiesBar({ values }: Props) {
  const theme = useTheme();

  const defaultColors = {
    urgent: theme.colorSet.severity.critical,
    critical: theme.colorSet.severity.high,
    serious: theme.colorSet.severity.meduim,
    medium: theme.colorSet.severity.low,
    minimal: theme.colorSet.severity.informational,
    analysing: theme.colorSet.severity.analysis,
  };

  const [entries, sizes] = useMemo(() => {
    const entries = Object.entries(values).filter(([, value]) => value > 0);
    const sum: number = entries.reduce((result, [, currentValue]) => {
      return result + currentValue;
    }, 0);

    return [
      entries,
      entries.reduce((result, [key, value]) => {
        return {
          ...result,
          [key]: ((value / sum) * oneHundred).toFixed(2),
        };
      }, {}),
    ];
  }, [values]);

  if (isEmptyObject(values)) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No vulnerabilities present' />;
  }

  return (
    <Tooltip values={values} colors={defaultColors}>
      <Bar>
        {entries.length === 0 && <BarItem isEmpty />}
        {entries.map(([key, value]) => {
          if (value > 0) {
            return (
              <BarItem
                key={key}
                style={{ width: `${sizes[key]}%`, backgroundColor: defaultColors[key] }}
              >
                {value}
              </BarItem>
            );
          }

          return null;
        })}
      </Bar>
    </Tooltip>
  );
}
