import React from 'react';
import { Tag, UcFirstLetter } from 'shared';
import { TagProps } from 'antd';
import { VulnerabilityStatus } from 'services/api';
import { getCssVar } from '../../styles/theme';

type CustomTagProps = TagProps & {
  title: string;
};

export const TagVulnStatus = ({ title, ...TagProps }: CustomTagProps) => {
  const tagType = (title: string) => {
    switch (title) {
      case VulnerabilityStatus.CONFIRMED:
        return getCssVar('vulnStatus.confirmed');
      case VulnerabilityStatus.POTENTIAL:
        return getCssVar('vulnStatus.potential');

      default:
        return getCssVar('vulnStatus.confirmed');
    }
  };

  return (
    <Tag color={tagType(title)} key={title} {...TagProps} title={title}>
      {UcFirstLetter(title)}
    </Tag>
  );
};
