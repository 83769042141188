import styled from 'styled-components';
import { Box } from '../../../../Box';
import { getCssVar } from '../../../../../styles/theme';

export const ThemeSwitcherWrapper = styled(Box)`
  align-items: center;
  color: ${getCssVar('textColor')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;
