import { createAsyncThunk } from '@reduxjs/toolkit';
import { initialPagination } from 'app-constants';
import { initialOrderBy } from 'pages/Documents/components/constants';
import {
  ApiError,
  CertificateName,
  CustomerId,
  DocumentsService,
  DocumentType,
  Filter,
  GridRequestParams,
} from '../../services/api';

const fetchTableContent = createAsyncThunk(
  'documents/fetch',
  async (requestBody: GridRequestParams) => {
    try {
      const data = await DocumentsService.getDocuments(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getLatestReports = createAsyncThunk(
  'documents/get/latestReports',
  async (customerId: CustomerId) => {
    try {
      const data = await DocumentsService.getDocuments({
        customerId,
        pagination: { page: initialPagination.page, count: 5 },
        orderBy: initialOrderBy,
        filter: {
          fields: [
            { name: 'documenttype', value: [DocumentType.REPORT], type: Filter.type.MULTIPLE },
          ],
        },
      });

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const uploadFile = createAsyncThunk(
  'document/upload',
  async (requestBody: {
    customerId: string;
    filename: string;
    certificateName?: CertificateName;
    documentType: DocumentType;
    fileSize: number;
    currentChunkIndex: number;
    totalChunks: number;
    requestBody?: Blob;
  }) => {
    try {
      const data = await DocumentsService.importDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const deleteFile = createAsyncThunk(
  'document/delete',
  async (requestBody: { customerId: string; filename: string }) => {
    try {
      const data = await DocumentsService.deleteDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const downloadFile = createAsyncThunk(
  'document/download',
  async (requestBody: { customerId: string; filename: string }) => {
    try {
      const data = await DocumentsService.exportDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const documentsApi = {
  fetchTableContent,
  uploadFile,
  deleteFile,
  downloadFile,
  getLatestReports,
};

export type DocumentsApiType = typeof documentsApi;
