import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { MobileTable } from 'shared';
import { defaultPageSize, defaultCurrent, initialPagination } from 'app-constants';
import { CustomTable } from 'shared/Table/CustomTable';
import { GridRequestParams, HostSoftwareSchema } from 'services/api';
import { useCustomer, useApp, useAssetDetails } from 'store';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { getTableColumns } from 'services/helpers/getTableColumns';
import { columns } from './TableColumns';
import { initialOrderBy, searchSettings } from './constants';

export const InstalledSoftware = () => {
  const customer = useCustomer();
  const { isMobile } = useApp();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { installedSoftware } = useAssetDetails();
  const [params, setParams] = React.useState<GridRequestParams>({
    customerId: customer,
    orderBy: initialOrderBy,
    pagination: initialPagination,
  });

  React.useEffect(() => {
    dispatch(assetDetailsApi.getInstalledSoftware({ ...params, hostId: id }));
  }, [dispatch, params, id]);

  if (isMobile) {
    return (
      <MobileTable<HostSoftwareSchema, GridRequestParams>
        data={installedSoftware}
        columns={columns}
        searchSettings={searchSettings}
        params={params}
        setParams={setParams}
        initialOrderBy={initialOrderBy}
        scroll='1000px'
        noFilters
      />
    );
  }

  return (
    <CustomTable<HostSoftwareSchema, GridRequestParams>
      columns={getTableColumns(columns, params.orderBy)}
      data={installedSoftware}
      params={params}
      setParams={setParams}
      filterFields={[]}
      searchSettings={searchSettings}
      defaultPageSize={defaultPageSize}
      defaultCurrent={defaultCurrent}
    />
  );
};
