/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EdrState {
  RUNNNING = 'Runnning',
  DISCONNECTED = 'Disconnected',
  DISABLED = 'Disabled',
  DEGRADED = 'Degraded',
  PENDING_REBOOT = 'Pending Reboot',
  ISOLATED = 'Isolated',
  EXPIRED = 'Expired',
  MIGRATED = 'Migrated',
  PENDING_MIGRATION = 'Pending Migration',
}
