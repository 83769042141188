import React, { useMemo } from 'react';
import {
  CyberResilienceScoreText,
  ScoreDifferenceMonth,
  ScoreDifferenceQuarter,
  ScoreWrapper,
  TotalWrapper,
  Wrapper,
} from './styled';
import { MonthlyReportResponse } from '../../../../../../../services/api';
import { getSortedDashboardScores } from '../../helpers';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { TotalScoreTable } from '../../components/TotalScoreTable';
import { getCapitalizedString } from '../../../../../../../services/helpers';
import { CyberResilienceScoreDescriptionScoreMessage } from '../styled';

const getDifferenceBetweenMonths = (month = 0, secondMonth = 0) => {
  const result = month - secondMonth;

  if (result > 0) {
    return `+${result}`;
  }

  return result;
};

const MONTH_ON_QUARTER = 4;

type Props = {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  totalRank: string;
};

export function PdfPage4({ data, onChartLoaded, totalRank }: Props) {
  const scores = data?.dashboardScores || [];
  const sortedDashboardScores = getSortedDashboardScores([...scores]);

  const checkingDashboardScores = sortedDashboardScores[scores.length - 1];
  const totalScore = checkingDashboardScores?.total || 0;
  const totalScoreTable = data?.dashboardScores || [];

  const differenceForMonth = useMemo(
    () =>
      getDifferenceBetweenMonths(totalScore, sortedDashboardScores[scores.length - 2]?.total || 0),
    [scores.length, sortedDashboardScores, totalScore],
  );

  const differenceForQuarter = useMemo(
    () =>
      getDifferenceBetweenMonths(
        totalScore,
        sortedDashboardScores[scores.length - MONTH_ON_QUARTER]?.total || 0,
      ),
    [scores.length, sortedDashboardScores, totalScore],
  );

  return (
    <Wrapper>
      <CyberResilienceScoreText>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(totalRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque neceari opta sinis
          excestiate comni nonsedi velibeate coriti as quas mo exerspe rumql et ipsum utem nos eos
          et landanti con ni ad ut que eum quis et aperuptatur? Lam, odit rumql et ipsum utem nos
          eos et landanti ad ut que eum?
        </p>
        <p>
          Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit pereped ut qui
          quisimi, tem dolorro vidunde lenderor aut estrumque neceari opta sinis excestiate comni
          nonsedi velibeate coriti as quas mo exerspe rumquae rnationest et mos eati tem et quatur
          rem aut exerfer uptions editatquam as et odit faccat omnima sequiam sam, quaessi
          tassinctore pa ation nit voluptat volut offic tecabor emolore rempos dolore ad et, omniene
          sit explique sitatiis dolore maiore nis quatiur, ut aute minverest occaeperspel id expel
          et ipsum utem nos eos et landanti con ni ad ut que eum quis et aperuptatur?{' '}
        </p>
      </CyberResilienceScoreText>
      <ScoreDifferenceMonth>{differenceForMonth} on last month</ScoreDifferenceMonth>
      <ScoreDifferenceQuarter>{differenceForQuarter} on last quarter</ScoreDifferenceQuarter>
      <ScoreWrapper>
        <GradientPieReport
          score={totalScore || 0}
          width='305px'
          height='305px'
          onFinished={onChartLoaded}
          fontSize={56}
          titleFontSize={8}
        />
      </ScoreWrapper>
      <TotalWrapper>
        <TotalScoreTable totalScoreTable={totalScoreTable} />
      </TotalWrapper>
    </Wrapper>
  );
}
