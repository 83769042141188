import React from 'react';
import { useDispatch } from 'react-redux';
import { useCustomer } from 'store';
import { ActionPopup, Button, Icon } from 'shared';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { Dropdown } from 'antd';
import { icons } from 'styles/theme';
import { getCustomMenu, showPopup } from '../helpers';
import { actions } from '../constant';
import { TagSchema } from '../../../services/api';

export type DropdownActionsProps = {
  tag: TagSchema;
  onSuccess: () => void;
};

export const DropdownActions = ({ tag, onSuccess }: DropdownActionsProps) => {
  const customerId = useCustomer();
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);

  const dispatch = useDispatch();

  const handleClickAction = (popupId: string) => {
    if (tag.id && tag.name) {
      const tagDetails = { id: tag.id, name: tag.name };

      showPopup({
        popupId,
        setPopupDetails,
        setIsVisiblePopup,
        tagData: tagDetails,
        dispatch,
        customerId,
        onSuccess,
      });
    }
  };

  return (
    <>
      <Dropdown overlay={getCustomMenu(actions, handleClickAction)} trigger={['click']}>
        <Button
          className='btn-table-action'
          type='link'
          icon={<Icon component={icons.more_vert} />}
        />
      </Dropdown>
      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
          inputType='textarea'
        />
      )}
    </>
  );
};
