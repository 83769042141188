import React, { createRef, useCallback, useMemo, useState } from 'react';
import { ServerStyleSheet, useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import moment from 'moment/moment';
import ReactDomServer from 'react-dom/server';
import {
  companyName,
  processPermission,
  reportData,
  reportDate,
} from '../Dashboard/components/Report/Norm/mocks/report';

import { StyledButton } from './styled/localReport.styled';
import { AppDispatch, monthlyReportApi, useApp } from '../../store';
import { getHtmlReport } from '../Dashboard/components/Report/Norm/helpers';
import { RequestStatus } from '../../store/loading/slice';
import { showError } from '../../services/helpers';
import { getReportComponent } from '../Dashboard/components/Report/reportFactory';
import { getScoreAnnotations } from '../Dashboard/constants';

const sheet = new ServerStyleSheet();

export function LocalReportPage() {
  const theme = useTheme();
  const { themeName } = useApp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const reportRef = createRef<HTMLDivElement>();
  const annotations = getScoreAnnotations('');

  const report = useMemo(() => {
    const Component = getReportComponent(themeName);

    return (
      <Component
        data={reportData}
        processPermission={processPermission}
        reportDate={reportDate}
        companyName={companyName}
        theme={theme}
        onChartsReady={() => {}}
        themeName={themeName}
        scoreAnnotations={annotations}
      />
    );
  }, [annotations, theme, themeName]);

  const onClickHandler = useCallback(() => {
    setIsLoading(true);
    ReactDomServer.renderToString(sheet.collectStyles(report));

    dispatch(
      monthlyReportApi.exportMonthlyReport({
        themeName,
        customerId: '1003',
        requestBody: getHtmlReport(reportRef?.current?.innerHTML || '', sheet.getStyleTags()),
      }),
    )
      .then((result) => {
        if (result.meta.requestStatus === RequestStatus.Rejected) {
          showError();

          return;
        }

        saveAs(
          new Blob([result.payload], {
            type: 'text/plain;charset=utf-8',
          }),
          `${moment(reportDate.startDate).format('YYYY-MM')} Report.pdf`,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, report, reportRef, themeName]);

  return (
    <>
      <StyledButton onClick={onClickHandler} loading={isLoading}>
        Download Report
      </StyledButton>
      <div ref={reportRef}>{report}</div>
    </>
  );
}
