import React from 'react';
import { backlinkRoutes } from 'app-constants';
import { Routes } from 'services/entities';
import { HistoryState } from 'services/interfaces';
import { BackLink } from 'shared';
import { getCapitalizedString } from 'services/helpers';
import { PMTagColoring } from '../PatchManagementPatches/styled';

export const getLayoutConfig = (
  title?: string,
  severity = '',
  historyState?: HistoryState | undefined,
  pathname?: string,
) => {
  return {
    title,
    backLink: (
      <BackLink
        historyState={historyState?.backTo?.route !== pathname ? historyState : undefined}
        defaultRoute={Routes.PATCH_MANAGEMENT_PATCHES}
        defaultTitle={backlinkRoutes[Routes.PATCH_MANAGEMENT_PATCHES]}
      />
    ),
    status: <PMTagColoring title={getCapitalizedString(severity)} />,
  };
};
