import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  useApp,
  useCurrentVulnerabilities,
  useCustomer,
  useCustomerChange,
  useExcludedVulnerabilitiesStatusFilter,
  useLoading,
  useVulnerability,
} from 'store';
import { vulnerabilitiesApi } from 'store/vulnerability/thunks';
import { initialPagination } from 'app-constants';
import { MultyColumns } from 'shared/charts';
import { Routes } from 'services/entities';
import {
  CurrentVulnerabilitiesByEachCriticalitySchema,
  Filter,
  VulnerabilityChartPointSchema,
} from 'services/api';
import { getQueryString } from 'services/api/core/request';
import { Severity } from 'services/constants';
import {
  getCurrentVulnerabilitiesBySeverityColors,
  severityValues,
  TooltipTitles,
  VulnerabilitiesTitles,
} from 'pages/VulnerabilityManagement/constants';
import { Box, Card } from 'shared';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import {
  getParamsFromVulnerabilitiesStatusFilter,
  getSeverityLevelName,
} from 'pages/VulnerabilityManagement/helpers';
import {
  confirmedVulnerabilitiesStatusFilter,
  initialOrderBy,
} from 'pages/VulnerabilitiesList/constants';
import { startCase } from 'lodash';
import { useTheme } from 'styled-components';
import { ChartContaner } from '../styled/styled';
import { emptyStateActiveVulnerabilitiesBySeverity } from '../../constants';
import { EmptyValues } from '../../types';
import { IFilterField } from '../../../CEPlus/constants';
import { validateField, validateFieldValue } from '../../../CEPlus/helpers';
import { ExcludeFilterTooltip } from '../ExcludeFilterTooltip';

export const ActiveVulnerabilitiesBySeverity = () => {
  const theme = useTheme();
  const { selectedTags = [] } = useVulnerability();
  const currentVulnerabilities = useCurrentVulnerabilities();
  const customerId = useCustomer();
  const { isMobile, isMenuCollapsed } = useApp();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useLoading([vulnerabilitiesApi.getCurrentVulnerabilities.typePrefix]);
  const excludedVulnerabilitiesFilter = useExcludedVulnerabilitiesStatusFilter() as string[];

  const customerChange = useCustomerChange([
    vulnerabilitiesApi.getCurrentVulnerabilities.typePrefix,
  ]);

  const isShowEmptyChartInActiveVulnerabilitiesBySeverity =
    customerChange[vulnerabilitiesApi.getCurrentVulnerabilities.typePrefix];

  const getVulnerabilitiesParams = (value: Severity, tags?: string[]) => {
    const filterFields: IFilterField[] = [
      {
        name: 'severity',
        value: [severityValues[value]],
        type: Filter.type.MULTIPLE,
      },
      {
        name: 'tagsAssets',
        value: tags,
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
      { ...confirmedVulnerabilitiesStatusFilter.fields[0] },
    ];

    return {
      orderBy: initialOrderBy,
      pagination: initialPagination,
      tab: 'active',
      filter: {
        fields: validateField(filterFields),
      },
    };
  };

  const redirectToVulnerabilitiesList = useCallback(
    (params: EChartsEventParams) => {
      if (!params.name || params.name === Severity.Analysing) {
        return;
      }

      history.push(
        `${Routes.VULNERABILITIES_LIST}${getQueryString(
          getVulnerabilitiesParams(params.name as Severity, selectedTags),
        )}`,
      );
    },
    [history, selectedTags],
  );

  const handleAnnotationClick = (name: string) => {
    history.push(
      `${Routes.VULNERABILITIES_LIST}${getQueryString(
        getVulnerabilitiesParams(startCase(name) as Severity, selectedTags),
      )}`,
    );
  };

  const getValues = (values: VulnerabilityChartPointSchema[]) =>
    [...values]
      .sort((valueA: VulnerabilityChartPointSchema, valueB: VulnerabilityChartPointSchema) => {
        if (valueA.criticality && valueB.criticality) {
          return Number(valueB.criticality) - Number(valueA.criticality);
        }

        return 0;
      })
      .reduce((acc: { [x: string]: number }, value: VulnerabilityChartPointSchema) => {
        if (!value.criticality || !value.count) {
          return { ...acc };
        }

        const key = getSeverityLevelName(value.criticality);

        return { ...acc, [key]: value.count };
      }, {});

  React.useEffect(() => {
    dispatch(
      vulnerabilitiesApi.getCurrentVulnerabilities({
        customerId,
        ...getParamsFromVulnerabilitiesStatusFilter(excludedVulnerabilitiesFilter),
        tagIds: selectedTags || [],
      }),
    );
  }, [dispatch, customerId, excludedVulnerabilitiesFilter, selectedTags]);

  const memoOnEvents = useMemo(() => {
    return { click: (params: EChartsEventParams) => redirectToVulnerabilitiesList(params) };
  }, [redirectToVulnerabilitiesList]);

  const boldText =
    currentVulnerabilities && (currentVulnerabilities.count || currentVulnerabilities.count === 0)
      ? currentVulnerabilities.count.toLocaleString('ru')
      : '';

  return (
    <Card
      title={VulnerabilitiesTitles.ActiveVulnerabilitiesBySeverity}
      height={theme.sizes.full}
      showDivider={false}
      subTitle={
        <SubTitle
          boldText={
            <Box display='flex' alignItems='center' gap={`${theme.sizes['1.2']}`}>
              <ExcludeFilterTooltip />
              {boldText}
            </Box>
          }
        />
      }
      tooltipTitle={TooltipTitles.ActiveVulnerabilitiesBySeverity}
      headStyles={{
        headAlign: 'flex-start',
        titlePaddingRight: isMobile ? theme.sizes[0] : theme.sizes[2],
        maxWidthTitle: isMobile ? '150px' : 'auto',
      }}
      cardPadding={!isMobile ? theme.sizes[6] : theme.sizes[5]}
      bodyPadding={
        !isMobile ? `${theme.sizes[2]} ${theme.sizes[2]} ${theme.sizes[0]}` : theme.sizes[0]
      }
      showLoader={loading[vulnerabilitiesApi.getCurrentVulnerabilities.typePrefix]}
    >
      <ChartContaner>
        <RenderChartWrapper<CurrentVulnerabilitiesByEachCriticalitySchema, EmptyValues>
          values={getValues(currentVulnerabilities?.chart || [])}
          emptyValues={emptyStateActiveVulnerabilitiesBySeverity}
          emptyText='No vulnerabilities yet'
          isShowEmptyChart={isShowEmptyChartInActiveVulnerabilitiesBySeverity}
        >
          <MultyColumns
            values={emptyStateActiveVulnerabilitiesBySeverity}
            colors={getCurrentVulnerabilitiesBySeverityColors(theme)}
            onEvents={memoOnEvents}
            onAnnotationClick={handleAnnotationClick}
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
            isLoading={loading[vulnerabilitiesApi.getCurrentVulnerabilities.typePrefix]}
          />
        </RenderChartWrapper>
      </ChartContaner>
    </Card>
  );
};
