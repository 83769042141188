import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const PrimaryOrDanger = css<Props>`
  border-radius: ${getCssVar('button.borderRadius')};

  &:hover,
  &.hover {
    background-color: ${getCssVar('button.hover.backgroundColor')};
    box-shadow: ${getCssVar('button.hover.boxShadow')};
    color: ${getCssVar('button.hover.color')};

    &:not(&:disabled) {
      transform: ${getCssVar('button.hover.transform')};
    }
  }

  &:active,
  &.active,
  &:focus {
    background-color: ${getCssVar('button.hover.backgroundColor')};
    box-shadow: ${getCssVar('button.hover.boxShadow')};
    color: ${getCssVar('button.hover.color')};

    &:not(&:disabled) {
      transform: ${getCssVar('button.hover.transform')};
    }
  }

  &:disabled {
    &,
    &:hover {
      background: ${getCssVar('buttonPrimaryOrDangerDisabled.backgroundColor')};
      border-color: ${getCssVar('buttonPrimaryOrDangerDisabled.borderColor')};
      color: ${getCssVar('buttonPrimaryOrDangerDisabled.color')};
    }
  }

  &.ant-btn-dangerous {
    background-color: ${getCssVar('buttonDanger.backgroundColor')};
    color: ${getCssVar('buttonDanger.color')};

    &.ant-btn-primary {
      &:hover,
      &.hover,
      &:focus,
      &.focus {
        &:not(:disabled) {
          background-color: ${getCssVar('buttonDanger.backgroundColor')};
          border-color: ${getCssVar('buttonDanger.backgroundColor')};
        }
      }
    }

    &:active,
    &.active {
      &:not(:disabled) {
        background-color: ${getCssVar('buttonDanger.active.backgroundColor')} !important;
        border-color: ${getCssVar('buttonDanger.active.backgroundColor')} !important;
      }
    }

    &:disabled,
    &:disabled:hover {
      background-color: ${getCssVar('buttonDanger.disabled.backgroundColor')};
      border-color: ${getCssVar('buttonDanger.disabled.backgroundColor')};
      color: ${getCssVar('buttonDanger.disabled.color')};
    }
  }
`;
