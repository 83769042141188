import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  GridRequestParams,
  SoftwareDocumentSchema,
  SoftwareDocumentsService,
} from '../../services/api';

const fetchTableContent = createAsyncThunk(
  'software/fetch',
  async (requestBody: GridRequestParams) => {
    try {
      const data = await SoftwareDocumentsService.getSoftwareDocuments(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const uploadFile = createAsyncThunk(
  'software/upload',
  async (requestBody: {
    customerId: string;
    filename: string;
    documentType: SoftwareDocumentSchema.documenttype;
    serviceType: SoftwareDocumentSchema.servicetype;
    fileSize: number;
    currentChunkIndex: number;
    totalChunks: number;
    requestBody?: Blob;
  }) => {
    try {
      const data = await SoftwareDocumentsService.importSoftwareDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const deleteFile = createAsyncThunk(
  'software/delete',
  async (requestBody: { customerId: string; filename: string }) => {
    try {
      const data = await SoftwareDocumentsService.deleteSoftwareDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const downloadFile = createAsyncThunk(
  'software/download',
  async (requestBody: { customerId: string; filename: string }) => {
    try {
      const data = await SoftwareDocumentsService.exportSoftwareDocument(requestBody);

      return data;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const softwareApi = {
  fetchTableContent,
  uploadFile,
  deleteFile,
  downloadFile,
};

export type SoftwareApiType = typeof softwareApi;
