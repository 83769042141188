import React, { useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import { Modal } from 'shared/Modal/Modal';
import { Button, DatePicker, Input } from 'shared';
import { dateFormat } from 'app-constants';
import { Form } from 'antd';
import { PopupBodyContainer } from './styled/styled';
import { ExpiryDate } from '../../Process/styled';

interface PopUpProps {
  closePopup: () => void;
  isVisible: boolean;
  popupConfig: {
    vendor: string;
    title: string;
    date?: number;
  } | null;
  onOk: (
    vendor: string | undefined,
    product: string | undefined,
    expiry: number | undefined,
  ) => void;
  loading?: boolean;
  isMobile?: boolean;
}

const validationSchema = Yup.object({
  vendor: Yup.string().trim().required('Vendor is a required field'),
  date: Yup.string().required('Service expiry is a required field').nullable(),
});

export const PopUp = ({
  closePopup,
  popupConfig,
  isVisible,
  loading,
  onOk,
  isMobile,
}: PopUpProps) => {
  const initialValues = {
    date: popupConfig?.date ? popupConfig?.date : null,
    vendor: '',
  };
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setValues,
    setFieldValue,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: FormikValues) => {
      const now = moment();

      const modifiedTime = moment(values.date)
        .set({
          hour: now.hour(),
          minute: now.minute(),
          second: now.second(),
        })
        .valueOf();

      onOk(values.vendor, popupConfig?.title, modifiedTime);
    },
  });

  useEffect(() => {
    setValues({
      vendor: popupConfig?.vendor || '',
      date: popupConfig?.date || null,
    });
  }, [popupConfig, setValues]);

  if (!popupConfig) {
    return null;
  }

  const onPickDate = (value: Moment | null) => setFieldValue('date', value?.valueOf());

  return (
    <Modal
      title='Add Third Party Certification/Security Details'
      visible={isVisible}
      width={720}
      onCancel={() => {
        closePopup();
        resetForm(initialValues as FormikValues);
      }}
      footer={[
        <Button
          key='back'
          onClick={() => {
            closePopup();
            resetForm(initialValues as FormikValues);
          }}
        >
          Cancel
        </Button>,
        <Button
          key='enter'
          type='primary'
          disabled={!isValid || !values.date || !values.vendor}
          loading={loading}
          onClick={() => handleSubmit()}
        >
          Confirm certification
        </Button>,
      ]}
    >
      <Form layout='vertical'>
        <PopupBodyContainer>
          <p>
            Improve your security score by providing <b>{popupConfig.title}</b> details:
          </p>
          <Input
            label='Vendor'
            type='text'
            name='vendor'
            value={values.vendor}
            onBlur={handleBlur}
            onChange={handleChange}
            validateStatus={errors.vendor && touched.vendor ? 'error' : 'success'}
            help={touched.vendor && errors.vendor}
            required
          />
          <ExpiryDate
            name='date'
            label='Service Expiry'
            validateStatus={errors.date && touched.date ? 'error' : 'success'}
            help={touched.date && errors.date}
            required
          >
            <DatePicker
              onChange={onPickDate}
              onBlur={handleBlur}
              format={dateFormat}
              inputReadOnly={isMobile}
            />
          </ExpiryDate>
        </PopupBodyContainer>
      </Form>
    </Modal>
  );
};
