import { spacing } from '../spacing';
import { fontWeights } from '../fontWeights';
import { colors } from '../colors';

export const button = {
  fontSize: `${spacing['3.6']}`,
  fontWeight: `${fontWeights.semibold}`,
  textTransform: 'uppercase',
  backgroundColor: `${colors.yellow[460]}`,
  color: `${colors.black}`,
  borderRadius: 0,
  border: 'none',

  hover: {
    backgroundColor: `${colors.black}`,
    color: `${colors.white}`,
    transform: 'none',
    boxShadow: 'none',
  },

  active: {
    backgroundColor: `${colors.yellow[560]}`,
    color: `${colors.black}`,
  },
};

export const buttonSecondary = {
  borderRadius: '0',
  backgroundColor: `${colors.white}`,
  border: `2px solid ${colors.black}`,
  borderColor: `${colors.black}`,

  hover: {
    backgroundColor: `${colors.black}`,
    color: `${colors.white}`,
  },

  active: {
    backgroundColor: `${colors.gray[110]}`,
    color: `${colors.black}`,
  },

  disabled: {
    backgroundColor: `${colors.white}`,
    color: `${colors.gray[390]}`,
    borderColor: `${colors.gray[390]}`,
  },
};

export const buttonDisabled = {
  backgroundColor: `${colors.gray[390]}`,
  color: colors.white,
};

export const buttonDanger = {
  backgroundColor: `${colors.purple[550]}`,
  color: `${colors.white}`,

  active: {
    backgroundColor: `${colors.pink[100]}`,
  },

  disabled: {
    backgroundColor: `${colors.gray[390]}`,
    color: `${colors.white}`,
  },
};

export const buttonSimple = {
  borderColor: `${colors.gray[120]}`,

  hover: {
    backgroundColor: `${colors.yellow[460]}`,
    color: `${colors.black}`,
    borderColor: `${colors.yellow[460]}`,
  },

  active: {
    backgroundColor: `${colors.gray[110]}`,
    borderColor: `${colors.gray[410]}`,
    color: `${colors.black}`,
  },

  disabled: {
    backgroundColor: `${colors.white}`,
    color: `${colors.gray[390]}`,
    borderColor: `${colors.gray[390]}`,
  },
};

export const buttonPrimaryOrDangerDisabled = {
  backgroundColor: `${colors.brown[100]}`,
  color: `${colors.white}`,
  borderColor: `${colors.brown[100]}`,
};
