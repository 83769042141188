import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cybersafetyApi, useApp, useCustomer, useLoading, usePhishingLastThirtyDays } from 'store';
import { Routes } from 'services/entities';
import { isEmptyObject } from 'services/helpers';
import { getQueryString } from 'services/api/core/request';
import { Card } from 'shared';
import { SegmentedPie } from 'shared/charts';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { Values } from 'shared/charts/types';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { useTheme } from 'styled-components';
import { TooltipTitles } from '../../constants';
import { getChartValues, getRedirectParams, getTotalClickRedirectParams } from './helpers';

export const PhishingLastThirtyDays = () => {
  const history = useHistory();
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const customerId = useCustomer();
  const dispatch = useDispatch();
  const phishingLastThirtyDays = usePhishingLastThirtyDays();
  const loading = useLoading([cybersafetyApi.getPhishingLastThirtyDays.typePrefix]);

  const phishingLastThirtyDaysColors = {
    noActions: theme.colorSet.pieChart.CurrentTrainingStatusSuccess,
    opened: theme.colorSet.pieChart.CurrentTrainingStatusStarted,
    clicked: theme.colorSet.pieChart.CurrentRefresherTestsFailed,
    providedCredentialsOrDownloadedAnAttachment:
      theme.colorSet.pieChart.PhishingLast30DaysProvidedCredentials,
  };

  const redirectToPhishing = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      history.push(`${Routes.PEOPLE_PHISHING}${getQueryString(getRedirectParams(params.name))}`);
    },
    [history],
  );

  const totalClickRedirect = React.useCallback(() => {
    history.push(`${Routes.PEOPLE_PHISHING}${getQueryString(getTotalClickRedirectParams())}`);
  }, [history]);

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToPhishing(params),
    }),
    [redirectToPhishing],
  );

  React.useEffect(() => {
    dispatch(
      cybersafetyApi.getPhishingLastThirtyDays({
        customerId,
        timezoneOffset: new Date().getTimezoneOffset(),
      }),
    );
  }, [dispatch, customerId]);

  return (
    <Card
      title='Phishing: last 30 days'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles.Phishing}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start', maxWidthTitle: '80%' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : `${theme.sizes[4]} ${theme.sizes[0]}`}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cybersafetyApi.getPhishingLastThirtyDays.typePrefix]}
    >
      {phishingLastThirtyDays.details && !isEmptyObject(phishingLastThirtyDays.details) ? (
        <SegmentedPie
          values={getChartValues(phishingLastThirtyDays) as Values}
          colors={phishingLastThirtyDaysColors}
          entitiesName='emails'
          style={
            isMobile ? { height: '240px', marginTop: `-${theme.spacing[4]}` } : { height: '300px' }
          }
          isMobile={isMobile}
          isMenuCollapsed={isMenuCollapsed}
          onEvents={onEvents}
          totalClickHandler={totalClickRedirect}
        />
      ) : (
        emptyMessage('No phishing data yet')
      )}
    </Card>
  );
};
