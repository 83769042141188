import React from 'react';
import moment from 'moment';
import { dateFormat } from 'app-constants';
import { getIconName, getStatusColor } from 'pages/Process/helpers';
import { CertificateStatusSchemaWithDownloadName } from 'services/api';
import { Icon } from 'shared';
import { icons } from 'styles/theme/icons';
import { ABOUT_TO_EXPIRE_MONTHS } from 'pages/Process/constants';
import { useTheme } from 'styled-components';
import { ProcessItemContainer } from '../styled';
import { getReportProccessStatus } from '../helpers';

interface IProcessItemProps extends CertificateStatusSchemaWithDownloadName {
  isLocked?: boolean;
}

export const ProcessItem = ({ status, title, expirationDate, isLocked }: IProcessItemProps) => {
  const theme = useTheme();
  const expiration = expirationDate ? moment(expirationDate) : null;
  const expired = status && expiration ? Number(expiration) < moment().valueOf() : null;
  const isCertificateSoonExpired =
    expiration && expiration.diff(moment(), 'month') < ABOUT_TO_EXPIRE_MONTHS;

  return (
    <td>
      <ProcessItemContainer
        color={getStatusColor(expiration, isCertificateSoonExpired, status, theme) || undefined}
        isIcon={!status}
        isLocked={isLocked || !status}
        dateColor={expiration?.isBefore(moment()) ? theme.colorSet.colorCritical : 'inherit'}
      >
        <div>
          <div className='title'>
            <span>{title}</span>
          </div>
          <div className='icon'>
            {!status && <Icon component={icons.small_lock} className='lockicon' />}
            <Icon component={icons[getIconName(title, status)]} />
          </div>
          {getReportProccessStatus(expired, Boolean(isCertificateSoonExpired), status || '', theme)}
          {expirationDate && <div className='date'>Exp. {expiration?.format(dateFormat)}</div>}
        </div>
      </ProcessItemContainer>
    </td>
  );
};
