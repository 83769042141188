import * as React from 'react';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Chart, ContainerStyled, LegendContainerStyled } from './styled';
import { Legend } from '../Legend/Legend';
import { Colors, Values } from '../types';
import { getOption } from './helpers';
import { ContainerForScroll } from '../styled';
import { EChartsEventParams } from '../interfaces';

type Props = {
  values: Values;
  colors: Colors;
  onAnnotationClick?: (name: string) => void;
  onEvents?: Record<string, (params: EChartsEventParams) => void>;
  isMobile: boolean;
  isMenuCollapsed: boolean | undefined;
  isLoading?: boolean;
};

export const MultyColumns = ({
  values,
  colors,
  onEvents,
  onAnnotationClick,
  isMobile,
  isMenuCollapsed,
  isLoading = false,
}: Props) => {
  const theme = useTheme();
  const option = useMemo(() => {
    if (isLoading) {
      return {};
    }

    return getOption(values, colors, Boolean(onEvents), theme);
  }, [colors, isLoading, onEvents, values, theme]);

  return (
    <ContainerStyled>
      <ContainerForScroll>
        <Chart option={option} onEvents={onEvents} notMerge />
      </ContainerForScroll>
      <LegendContainerStyled>
        <Legend
          values={values}
          colors={colors}
          direction='column'
          onAnnotationClick={onAnnotationClick}
          isMobile={isMobile}
          isMenuCollapsed={isMenuCollapsed}
        />
      </LegendContainerStyled>
    </ContainerStyled>
  );
};
