import React from 'react';
import moment from 'moment';
import { MonthlyReportResponse } from 'services/api';
import { Line } from 'shared/charts';
import { IDate } from 'services/interfaces';
import { ReportPageLayout } from '../components/ReportPageLayout';
import { ReportTableSection } from '../components/ReportTableSection';
import { VulnerabilityManagement } from '../components/VulnerabilityManagement';
import { getOption, getSortedDashboardScores } from '../helpers';

const NUMBER_ROUNDING_MILLION = 6;
const NUMBER_ROUNDING_MILLION_SLICE = -3;

export const PdfPage3 = ({
  data,
  onChartLoaded,
  reportDate,
}: {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  reportDate: IDate;
}) => {
  const {
    dashboardScores,
    vulnerabilityScanning,
    alarmsCount,
    eventsCount,
    supportRequests,
  } = data;

  const scores = dashboardScores || [];
  const checkingDashboardScores = getSortedDashboardScores([...scores])[scores.length - 1];
  const technologyScore = checkingDashboardScores?.technology;
  const vulnerabilitiesTrendsOptions =
    vulnerabilityScanning?.vulnerabilitiesTrends?.trend?.points || [];
  const vulnerabilitiesChangesChart = vulnerabilityScanning?.vulnerabilitiesChanges?.chart;

  const vulnerabilitiesChangesTotalChange =
    vulnerabilityScanning?.vulnerabilitiesChanges?.totalChange;

  const securityIncidentsClosed = supportRequests?.securityIncidents?.closed || 0;
  const securityIncidentsActive = supportRequests?.securityIncidents?.active || 0;
  const securityIncidentsOpened = supportRequests?.securityIncidents?.opened || 0;

  const numberFormatting = (num: number) => {
    const numString = num.toString();
    const regEx = /\B(?=(\d{3})+(?!\d))/g;

    if (numString.length > NUMBER_ROUNDING_MILLION) {
      const newNum = numString.slice(0, NUMBER_ROUNDING_MILLION_SLICE).replace(regEx, '.');

      return `${newNum}M`;
    }

    return numString.replace(regEx, ',');
  };

  return (
    <ReportPageLayout
      headerSubtitle={`Your ${moment(reportDate.startDate).format(
        'MMMM YYYY',
      )} update. Technology.`}
    >
      <ReportTableSection
        onLoad={onChartLoaded}
        score={technologyScore}
        showScore
        title='Vulnerability Scanning'
      >
        <Line
          getOption={getOption}
          isMobile={false}
          values={vulnerabilitiesTrendsOptions || []}
          date={reportDate}
          height={{ chart: '180px', grid: 'auto' }}
          top='10px'
          left='5px'
          onLoad={onChartLoaded}
          right='10px'
          bottom='10px'
          width='100%'
        />
        <p>
          Fusce gravida arcu sagittis auctor cursus. Donec nisi leo, rutrum non nibh eget, ultrices
          consectetur ante. Praesent scelerisque felis eget augue tempus, nec lobortis purus
          accumsan. Integer elementum fringilla dolor. Praesent laoreet feugiat consectetur. Nunc
          diam odio, scelerisque consectetur lobortis ut, venenatis vel est. Nulla aliquet lorem
          gravida, vehicula est sodales, convallis leo. Mauris sed vulputate tellus. Curabitur
          scelerisque sapien ac consequat bibendum. Morbi ornare erat ut eleifend varius. Proin
          tempor velit eget odio consectetur mollis. Vivamus ornare scelerisque ex, a viverra lorem
          posuere consectetur. Curabitur et libero vitae turpis convallis semper. Orci varius
          natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam viverra
          vestibulum diam.
        </p>

        <VulnerabilityManagement
          chart={vulnerabilitiesChangesChart}
          totalChange={vulnerabilitiesChangesTotalChange}
        />
        <hr />

        <table>
          <tbody>
            <tr className='row-spacing'>
              <td>
                <h3>Threat Detection &amp; Response </h3>
                <table className='td-statistic'>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <div className='td-statistic-item td-events'>
                          {numberFormatting(eventsCount || 0)} Events
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className='td-statistic-item td-alerts'>
                          {numberFormatting(alarmsCount || 0)} Alerts
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className='td-statistic-item td-incidents'>
                          {numberFormatting(securityIncidentsOpened)} Incidents
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='td-statistic-item td-closet'>
                          {numberFormatting(securityIncidentsClosed)} Closed
                        </div>
                      </td>
                      <td>
                        <div className='td-statistic-item td-active'>
                          {numberFormatting(securityIncidentsActive)} Active
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <p>
                  Duis maximus est neque. Cras facilisis dolor vel leo pretium aliquam. Integer
                  dapibus purus a malesuada euismod. Maecenas placerat ac ex ut consectetur. Nullam
                  rhoncus ligula vel purus tristique, vel maximus ante viverra. Proin tortor nibh,
                  tincidunt in nisi eget, auctor tempor diam. Proin non lectus in nisi vulputate
                  eleifend nec nec ligula. Pellentesque et pellentesque est. Cras enim elit, rhoncus
                  id lobortis in, ullamcorper id augue. Maecenas ac magna vel elit aliquet cursus
                  porttitor a lorem.
                </p>
                <p>
                  Duis at dolor odio. Vestibulum ante ipsum primis in faucibus orci luctus et
                  ultrices posuere cubilia curae; Maecenas euismod facilisis ex sit amet laoreet.
                  Cras sit amet enim ex. Fusce et magna ac orci consectetur porttitor et eu risus.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </ReportTableSection>
    </ReportPageLayout>
  );
};
