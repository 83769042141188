import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  CustomerId,
  GridRequestParams,
  TagId,
  TagRequest,
  TagsManagementService,
} from 'services/api';

interface IDeleteTag {
  customerId: CustomerId;
  tagId: TagId;
  requestBody: TagRequest;
}

type IUpdateTag = IDeleteTag;

type ICreateTag = Omit<IUpdateTag, 'tagId'>;

const getTagsList = createAsyncThunk('tags-management/tags', async (customerId: CustomerId) => {
  try {
    return await TagsManagementService.getTagsList({ customerId });
  } catch (e) {
    const { body } = e as ApiError;

    throw body;
  }
});

const getTagsGrid = createAsyncThunk(
  'tags-management/tags-grid',
  async (requestBody: GridRequestParams) => {
    try {
      return await TagsManagementService.getTagsGrid(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const createTag = createAsyncThunk(
  'tags-management/tag-create',
  async ({ customerId, requestBody }: ICreateTag) => {
    try {
      return await TagsManagementService.createTag({ customerId, requestBody });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const editTagById = createAsyncThunk(
  'tags-management/tag-edit',
  async ({ customerId, tagId, requestBody }: IUpdateTag) => {
    try {
      return await TagsManagementService.editTag({ customerId, tagId, requestBody });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const deleteTag = createAsyncThunk(
  'tags-management/tag-delete',
  async ({ customerId, tagId, requestBody }: IDeleteTag) => {
    try {
      return await TagsManagementService.deleteTag({ customerId, tagId, requestBody });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const tagsManagementApi = {
  getTagsList,
  getTagsGrid,
  createTag,
  editTagById,
  deleteTag,
};
