import React from 'react';
import { Box, Button, Icon } from 'shared';
import { useTheme } from 'styled-components';
import { MobileActionsPopup } from './MobileActionsPopup';
import { TagSchema } from '../../../services/api';

type MobileUserActionsProps = {
  tagsRow: TagSchema;
  onSuccess: () => void;
  editableTag?: null | { name?: string; id?: string };
};

export const MobileTagActions = ({ tagsRow, onSuccess, editableTag }: MobileUserActionsProps) => {
  const theme = useTheme();
  const [isVisibleActionsPopup, setIsVisibleActionsPopup] = React.useState(false);

  if (tagsRow.id === editableTag?.id) {
    return null;
  }

  return (
    <Box onClick={(event: React.MouseEvent) => event.stopPropagation()}>
      <Button
        className='btn-table-action'
        type='link'
        icon={
          <Icon component={theme.icons.more_vert} onClick={() => setIsVisibleActionsPopup(true)} />
        }
      />
      <MobileActionsPopup
        isVisible={isVisibleActionsPopup}
        setIsVisible={setIsVisibleActionsPopup}
        tag={tagsRow}
        onSuccess={onSuccess}
      />
    </Box>
  );
};
