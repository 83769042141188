import React from 'react';
import { Col, Dropdown, Menu, Row, message } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { backlinkRoutes } from 'app-constants/routes';
import { Routes } from 'services/entities';
import { ParamsPage } from 'services/interfaces';
import { icons } from 'styles/theme';
import { Button, Icon, WrapperCol } from 'shared';
import { useGetPermission } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, logoutAction } from 'store';
import { Themes } from 'themes/types';
import { CustomersSelect } from '../CustomersSelect/CustomersSelect';
import { getNameCapitalLetters } from '../helpers';
import { VerticalDivider } from '../styled';
import { NotificationsHeader } from '../NotificationsHeader';
import { DropDownAvatar, DropDownLink, StyledHeader } from './MainHeader.styled';

type MainHeaderProps = {
  userName: string;
};

export const MainHeader = ({ userName }: MainHeaderProps) => {
  const permission = useGetPermission(PermissionOptions.SupportRequests);

  const {
    params: { id: pageId },
    url: currentUrl,
    path: pathForBacklink,
  } = useRouteMatch<ParamsPage>();

  const dispatch = useDispatch();

  const handleLogout = React.useCallback(() => {
    dispatch(logoutAction(true));
    message.destroy();
  }, [dispatch]);

  const themeName = useSelector(({ app: { themeName } }: AppState) => themeName);

  const isShowModeSwitcher = themeName !== Themes.Nasstar;

  const menu = (
    <Menu>
      {isShowModeSwitcher && (
        <Menu.Item>
          <Link to='/appearance'>Appearance</Link>
        </Menu.Item>
      )}
      <Menu.Item key='logout'>
        <Link to='/' onClick={handleLogout}>
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledHeader className='site-layout-background'>
      <Row>
        <Col flex='auto'>
          <CustomersSelect />
        </Col>
        <WrapperCol flex='none'>
          {permission === PermissionsLevel.Full && (
            <Button
              as={Link}
              to={{
                pathname: Routes.REPORT_INCIDENT,
                state: {
                  backTo: {
                    route: currentUrl,
                    title: `Back to ${backlinkRoutes[pathForBacklink]}`,
                    id: pageId && ` ${pageId}`,
                  },
                },
              }}
              type='secondary'
              className='ant-btn'
            >
              Report incident
            </Button>
          )}
          <VerticalDivider />
          <NotificationsHeader />

          <VerticalDivider />

          <Dropdown overlay={menu}>
            <DropDownLink to='/' className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
              <DropDownAvatar size={40}>{getNameCapitalLetters(userName)}</DropDownAvatar>
              {userName} <Icon className='anticon-down' component={icons.arrow_down_short} />
            </DropDownLink>
          </Dropdown>
        </WrapperCol>
      </Row>
    </StyledHeader>
  );
};
