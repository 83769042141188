import { colors } from '../colors';

export const leftMenu = {
  iconColors: colors.gray[500],
  activeSubmenuColor: colors.brown[400],
  unActiveSubmenuColor: colors.gray[300],
  submenuClose: '#323332',
  unActiveSubmenuIcon: colors.gray[500],
  activeSubmenuSelectedColor: '#323332',
  headerColor: 'rgba(22, 23, 22, 0.7)',
  logoTop: 'menu-logo-top-dark-norm.svg',
  logoTopSmall: 'menu-logo-top-small-dark-norm.svg',
  arrowColor: colors.white,
  logoTopWidth: '110',
  logoTopWidthCollapsed: '28',
  logoTopHeight: '26',
  logoTopHeightCollapsed: '25',

  logoBottom: undefined,
  logoBottomCollapsed: undefined,

  menuDrawerColor: 'rgba(22, 23, 22, 0.7)',

  btnReportIncidentColor: colors.white,

  iconCloseColor: colors.white,

  iconHoverSelectedColor: colors.gray[500],

  drawerColor: '#363635',

  avatarColor: '#BEA167',

  userNameColor: colors.white,

  userAvatarColor: colors.black,

  logoutColor: colors.gray[500],

  drawerFooterLogo: undefined,
};
