/* eslint-disable @typescript-eslint/no-magic-numbers */

import moment from 'moment/moment';
import {
  CertificateStatusSchema,
  MonthlyReportResponse,
  SeverityLevel,
  SupportRequestsPriority,
  SupportRequestsStatus,
  VulnerabilityTrendPointTypeSchema,
} from '../../../../../../services/api';
import { Data } from '../pages';

export const companyName = 'Test Company';

export const processPermission = 'Full Access';

export const reportDate = {
  startDate: moment().subtract(1, 'month').startOf('month').valueOf(),
  endDate: moment().subtract(1, 'month').endOf('month').valueOf(),
};

export const serviceChanges: Data[] = [
  {
    id: 1,
    name: 'Dublin office closing',
    status: 'Pending',
    eta: 'October 2023',
  },
  {
    id: 2,
    name: 'Replace end-of-life firewalls',
    status: 'On Hold',
    eta: 'Late Q4',
  },
  {
    id: 3,
    name: 'Dublin office closing',
    status: 'Pending',
    eta: 'October 2023',
  },
  {
    id: 4,
    name: 'Replace end-of-life firewalls',
    status: 'On Hold',
    eta: 'Late Q4',
  },
  {
    id: 5,
    name: 'Dublin office closing',
    status: 'Pending',
    eta: 'October 2023',
  },
  {
    id: 6,
    name: 'Replace end-of-life firewalls',
    status: 'On Hold',
    eta: 'Late Q4',
  },
  {
    id: 7,
    name: 'Dublin office closing',
    status: 'Pending',
    eta: 'October 2023',
  },
];

export const reportData: MonthlyReportResponse = {
  scoreRanks: {
    boostRank: 'top 10% of norm customers',
    technologyRank: 'top 50% of norm customers',
    peopleRank: 'top 10% of norm customers',
    processRank: 'top 10% of norm customers',
    totalRank: 'top 10% of norm customers',
  },
  dashboardScores: [
    {
      timestamp: 1672531200000,
      boost: 67,
      technology: 80,
      people: 82,
      process: 100,
      total: 83,
    },
    {
      timestamp: 1675209600000,
      boost: 0,
      technology: 74,
      people: 82,
      process: 100,
      total: 76,
    },
    {
      timestamp: 1677628800000,
      boost: 0,
      technology: 74,
      people: 81,
      process: 100,
      total: 75,
    },
    {
      timestamp: 1680307200000,
      boost: 100,
      technology: 80,
      people: 79,
      process: 100,
      total: 84,
    },
    {
      timestamp: 1682899200000,
      boost: 100,
      technology: 78,
      people: 79,
      process: 100,
      total: 83,
    },
    {
      timestamp: 1685577600000,
      boost: 100,
      technology: 79,
      people: 81,
      process: 100,
      total: 84,
    },
    {
      timestamp: 1688169600000,
      boost: 100,
      technology: 68,
      people: 79,
      process: 67,
      total: 72,
    },
  ],
  supportRequests: {
    securityIncidents: {
      active: 0,
      closed: 62,
      opened: 67,
      openedList: [
        {
          id: '26654',
          priority: '2' as SupportRequestsPriority,
          summary: 'User Submission: Phish Mail 6/28/2023 9:05:59 AM',
          status: 'Closed' as SupportRequestsStatus,
          created: moment(1688027848000).toISOString(),
          updated: moment(1688386666000).toISOString(),
        },
        {
          id: '26645',
          priority: '3' as SupportRequestsPriority,
          summary: 'Message - Pawan | Only B2B | 13375160469',
          status: 'Closed' as SupportRequestsStatus,
          created: moment(1687957071000).toISOString(),
          updated: moment(1688130746000).toISOString(),
        },
        {
          id: '26643',
          priority: '3' as SupportRequestsPriority,
          summary: "RE: Teams won't connect",
          status: 'Closed' as SupportRequestsStatus,
          created: moment(1687954856000).toISOString(),
          updated: moment(1688130944000).toISOString(),
        },
        {
          id: '26627',
          priority: '3' as SupportRequestsPriority,
          summary: "Rule tuning for 'Network Memory Warning'",
          status: 'Closed' as SupportRequestsStatus,
          created: moment(1687883250000).toISOString(),
          updated: moment(1688054452000).toISOString(),
        },
        {
          id: '26617',
          priority: '3' as SupportRequestsPriority,
          summary: 'Message - Barry | Sky Blue Education | 01691 886886',
          status: 'Resolved' as SupportRequestsStatus,
          created: moment(1687857980000).toISOString(),
          updated: moment(1688032469000).toISOString(),
        },
      ],
    },
    serviceRequests: {
      active: 1,
      closed: 21,
      opened: 14,
    },
  },
  people: {
    trainingStatus: {
      completed: 40,
      started: 2,
      notStarted: 0,
    },
    refresherStatistics: {
      passed: 212,
      ignored: 289,
      failed: 129,
    },
    phishing: {
      details: {
        providedCredentials: 0,
        opened: 20,
        noActions: 118,
        clicked: 31,
      },
    },
  },
  certificates: [
    {
      title: 'Cyber Essentials',
      vendor: undefined,
      lastUpdated: 1677755046000,
      expirationDate: 1707436800000,
      status: CertificateStatusSchema.DELIVERED,
      id: 'CEIA1813',
    },
    {
      title: 'Cyber Essentials Plus',
      vendor: undefined,
      lastUpdated: 1683120767000,
      expirationDate: 1713567600000,
      status: CertificateStatusSchema.DELIVERED,
      id: 'CEPL1818',
    },
    {
      title: 'IASME Governance Audited',
      vendor: undefined,
      lastUpdated: 1665164047000,
      expirationDate: 1693609200000,
      status: CertificateStatusSchema.DELIVERED,
      id: 'IAAU2597',
    },
    {
      title: 'ISO27001',
      vendor: 'British Assessment Bureau',
      lastUpdated: 1640005443000,
      expirationDate: 1687640400000,
      status: CertificateStatusSchema.CERTIFIED,
      id: undefined,
    },
  ],
  vulnerabilityScanning: {
    vulnerabilitiesChanges: {
      chart: [
        {
          criticality: SeverityLevel._5,
          count: 16,
          previousDifference: 0,
        },
        {
          criticality: SeverityLevel._2,
          count: 76,
          previousDifference: 2,
        },
        {
          criticality: SeverityLevel._1,
          count: 1,
          previousDifference: 0,
        },
        {
          criticality: SeverityLevel._4,
          count: 192,
          previousDifference: 49,
        },
        {
          criticality: SeverityLevel._3,
          count: 61,
          previousDifference: 16,
        },
      ],
      count: 346,
      totalChange: 67,
    },
    vulnerabilitiesTrends: {
      trend: {
        pointType: VulnerabilityTrendPointTypeSchema.DAY,
        points: [
          {
            date: 1659657600000,
            value: 232,
          },
          {
            date: 1659744000000,
            value: 232,
          },
          {
            date: 1659830400000,
            value: 232,
          },
          {
            date: 1659916800000,
            value: 232,
          },
          {
            date: 1660003200000,
            value: 235,
          },
          {
            date: 1660089600000,
            value: 279,
          },
          {
            date: 1660176000000,
            value: 267,
          },
          {
            date: 1660262400000,
            value: 260,
          },
          {
            date: 1660348800000,
            value: 260,
          },
          {
            date: 1660435200000,
            value: 264,
          },
          {
            date: 1660521600000,
            value: 250,
          },
          {
            date: 1660608000000,
            value: 245,
          },
          {
            date: 1660694400000,
            value: 239,
          },
          {
            date: 1660780800000,
            value: 240,
          },
          {
            date: 1660867200000,
            value: 264,
          },
          {
            date: 1660953600000,
            value: 262,
          },
          {
            date: 1661040000000,
            value: 255,
          },
          {
            date: 1661126400000,
            value: 244,
          },
          {
            date: 1661212800000,
            value: 239,
          },
          {
            date: 1661299200000,
            value: 237,
          },
          {
            date: 1661385600000,
            value: 228,
          },
          {
            date: 1661472000000,
            value: 228,
          },
          {
            date: 1661558400000,
            value: 232,
          },
          {
            date: 1661644800000,
            value: 232,
          },
          {
            date: 1661731200000,
            value: 232,
          },
          {
            date: 1661817600000,
            value: 233,
          },
          {
            date: 1661904000000,
            value: 237,
          },
          {
            date: 1661990400000,
            value: 247,
          },
          {
            date: 1662076800000,
            value: 247,
          },
          {
            date: 1662163200000,
            value: 247,
          },
          {
            date: 1662249600000,
            value: 247,
          },
          {
            date: 1662336000000,
            value: 248,
          },
          {
            date: 1662422400000,
            value: 267,
          },
          {
            date: 1662508800000,
            value: 263,
          },
          {
            date: 1662595200000,
            value: 260,
          },
          {
            date: 1662681600000,
            value: 200,
          },
          {
            date: 1662768000000,
            value: 252,
          },
          {
            date: 1662854400000,
            value: 256,
          },
          {
            date: 1662940800000,
            value: 256,
          },
          {
            date: 1663027200000,
            value: 256,
          },
          {
            date: 1663113600000,
            value: 290,
          },
          {
            date: 1663200000000,
            value: 292,
          },
          {
            date: 1663286400000,
            value: 294,
          },
          {
            date: 1663372800000,
            value: 293,
          },
          {
            date: 1663459200000,
            value: 288,
          },
          {
            date: 1663545600000,
            value: 288,
          },
          {
            date: 1663632000000,
            value: 287,
          },
          {
            date: 1663718400000,
            value: 290,
          },
          {
            date: 1663804800000,
            value: 289,
          },
          {
            date: 1663891200000,
            value: 282,
          },
          {
            date: 1663977600000,
            value: 282,
          },
          {
            date: 1664064000000,
            value: 282,
          },
          {
            date: 1664236800000,
            value: 281,
          },
          {
            date: 1664323200000,
            value: 291,
          },
          {
            date: 1664409600000,
            value: 299,
          },
          {
            date: 1664496000000,
            value: 293,
          },
          {
            date: 1664582400000,
            value: 291,
          },
          {
            date: 1664668800000,
            value: 291,
          },
          {
            date: 1664755200000,
            value: 289,
          },
          {
            date: 1664841600000,
            value: 288,
          },
          {
            date: 1664928000000,
            value: 298,
          },
          {
            date: 1665014400000,
            value: 288,
          },
          {
            date: 1665100800000,
            value: 285,
          },
          {
            date: 1665187200000,
            value: 284,
          },
          {
            date: 1665273600000,
            value: 277,
          },
          {
            date: 1665360000000,
            value: 278,
          },
          {
            date: 1665446400000,
            value: 280,
          },
          {
            date: 1665532800000,
            value: 306,
          },
          {
            date: 1665619200000,
            value: 321,
          },
          {
            date: 1665705600000,
            value: 304,
          },
          {
            date: 1665792000000,
            value: 293,
          },
          {
            date: 1665878400000,
            value: 291,
          },
          {
            date: 1665964800000,
            value: 289,
          },
          {
            date: 1666051200000,
            value: 295,
          },
          {
            date: 1666137600000,
            value: 301,
          },
          {
            date: 1666224000000,
            value: 292,
          },
          {
            date: 1666310400000,
            value: 288,
          },
          {
            date: 1666396800000,
            value: 288,
          },
          {
            date: 1666483200000,
            value: 288,
          },
          {
            date: 1666569600000,
            value: 295,
          },
          {
            date: 1666656000000,
            value: 293,
          },
          {
            date: 1666742400000,
            value: 295,
          },
          {
            date: 1666828800000,
            value: 300,
          },
          {
            date: 1666915200000,
            value: 301,
          },
          {
            date: 1667001600000,
            value: 245,
          },
          {
            date: 1667088000000,
            value: 246,
          },
          {
            date: 1667174400000,
            value: 251,
          },
          {
            date: 1667260800000,
            value: 305,
          },
          {
            date: 1667347200000,
            value: 247,
          },
          {
            date: 1667433600000,
            value: 288,
          },
          {
            date: 1667520000000,
            value: 293,
          },
          {
            date: 1667606400000,
            value: 293,
          },
          {
            date: 1667692800000,
            value: 293,
          },
          {
            date: 1667779200000,
            value: 291,
          },
          {
            date: 1667865600000,
            value: 295,
          },
          {
            date: 1667952000000,
            value: 338,
          },
          {
            date: 1668038400000,
            value: 327,
          },
          {
            date: 1668124800000,
            value: 290,
          },
          {
            date: 1668211200000,
            value: 290,
          },
          {
            date: 1668297600000,
            value: 285,
          },
          {
            date: 1668384000000,
            value: 284,
          },
          {
            date: 1668470400000,
            value: 285,
          },
          {
            date: 1668556800000,
            value: 281,
          },
          {
            date: 1668643200000,
            value: 278,
          },
          {
            date: 1668729600000,
            value: 281,
          },
          {
            date: 1668816000000,
            value: 281,
          },
          {
            date: 1668902400000,
            value: 281,
          },
          {
            date: 1668988800000,
            value: 280,
          },
          {
            date: 1669075200000,
            value: 280,
          },
          {
            date: 1669161600000,
            value: 280,
          },
          {
            date: 1669248000000,
            value: 278,
          },
          {
            date: 1669334400000,
            value: 279,
          },
          {
            date: 1669420800000,
            value: 285,
          },
          {
            date: 1669507200000,
            value: 285,
          },
          {
            date: 1669593600000,
            value: 289,
          },
          {
            date: 1669680000000,
            value: 289,
          },
          {
            date: 1669766400000,
            value: 287,
          },
          {
            date: 1669852800000,
            value: 285,
          },
          {
            date: 1669939200000,
            value: 279,
          },
          {
            date: 1670025600000,
            value: 278,
          },
          {
            date: 1670112000000,
            value: 278,
          },
          {
            date: 1670198400000,
            value: 278,
          },
          {
            date: 1670284800000,
            value: 290,
          },
          {
            date: 1670371200000,
            value: 289,
          },
          {
            date: 1670457600000,
            value: 289,
          },
          {
            date: 1670544000000,
            value: 280,
          },
          {
            date: 1670630400000,
            value: 283,
          },
          {
            date: 1670716800000,
            value: 283,
          },
          {
            date: 1670803200000,
            value: 282,
          },
          {
            date: 1670889600000,
            value: 281,
          },
          {
            date: 1670976000000,
            value: 247,
          },
          {
            date: 1671062400000,
            value: 262,
          },
          {
            date: 1671148800000,
            value: 262,
          },
          {
            date: 1671235200000,
            value: 264,
          },
          {
            date: 1671321600000,
            value: 264,
          },
          {
            date: 1671408000000,
            value: 264,
          },
          {
            date: 1671494400000,
            value: 264,
          },
          {
            date: 1671580800000,
            value: 264,
          },
          {
            date: 1671667200000,
            value: 256,
          },
          {
            date: 1671753600000,
            value: 248,
          },
          {
            date: 1671840000000,
            value: 247,
          },
          {
            date: 1671926400000,
            value: 247,
          },
          {
            date: 1672012800000,
            value: 247,
          },
          {
            date: 1672099200000,
            value: 246,
          },
          {
            date: 1672185600000,
            value: 242,
          },
          {
            date: 1672272000000,
            value: 243,
          },
          {
            date: 1672358400000,
            value: 243,
          },
          {
            date: 1672444800000,
            value: 243,
          },
          {
            date: 1672531200000,
            value: 244,
          },
          {
            date: 1672617600000,
            value: 242,
          },
          {
            date: 1672704000000,
            value: 241,
          },
          {
            date: 1672790400000,
            value: 228,
          },
          {
            date: 1672876800000,
            value: 229,
          },
          {
            date: 1672963200000,
            value: 221,
          },
          {
            date: 1673049600000,
            value: 221,
          },
          {
            date: 1673136000000,
            value: 217,
          },
          {
            date: 1673222400000,
            value: 218,
          },
          {
            date: 1673308800000,
            value: 218,
          },
          {
            date: 1673395200000,
            value: 274,
          },
          {
            date: 1673481600000,
            value: 257,
          },
          {
            date: 1673568000000,
            value: 236,
          },
          {
            date: 1673654400000,
            value: 237,
          },
          {
            date: 1673740800000,
            value: 236,
          },
          {
            date: 1673827200000,
            value: 237,
          },
          {
            date: 1673913600000,
            value: 265,
          },
          {
            date: 1674000000000,
            value: 216,
          },
          {
            date: 1674086400000,
            value: 213,
          },
          {
            date: 1674172800000,
            value: 213,
          },
          {
            date: 1674259200000,
            value: 219,
          },
          {
            date: 1674345600000,
            value: 216,
          },
          {
            date: 1674432000000,
            value: 204,
          },
          {
            date: 1674518400000,
            value: 213,
          },
          {
            date: 1674604800000,
            value: 223,
          },
          {
            date: 1674691200000,
            value: 218,
          },
          {
            date: 1674777600000,
            value: 209,
          },
          {
            date: 1674864000000,
            value: 209,
          },
          {
            date: 1674950400000,
            value: 209,
          },
          {
            date: 1675036800000,
            value: 209,
          },
          {
            date: 1675123200000,
            value: 217,
          },
          {
            date: 1675209600000,
            value: 223,
          },
          {
            date: 1675296000000,
            value: 220,
          },
          {
            date: 1675382400000,
            value: 214,
          },
          {
            date: 1675468800000,
            value: 214,
          },
          {
            date: 1675555200000,
            value: 220,
          },
          {
            date: 1675641600000,
            value: 219,
          },
          {
            date: 1675728000000,
            value: 221,
          },
          {
            date: 1675814400000,
            value: 217,
          },
          {
            date: 1675900800000,
            value: 226,
          },
          {
            date: 1675987200000,
            value: 218,
          },
          {
            date: 1676073600000,
            value: 237,
          },
          {
            date: 1676160000000,
            value: 238,
          },
          {
            date: 1676246400000,
            value: 241,
          },
          {
            date: 1676332800000,
            value: 234,
          },
          {
            date: 1676419200000,
            value: 244,
          },
          {
            date: 1676505600000,
            value: 271,
          },
          {
            date: 1676592000000,
            value: 254,
          },
          {
            date: 1676678400000,
            value: 248,
          },
          {
            date: 1676764800000,
            value: 246,
          },
          {
            date: 1676851200000,
            value: 245,
          },
          {
            date: 1677024000000,
            value: 243,
          },
          {
            date: 1677110400000,
            value: 244,
          },
          {
            date: 1677196800000,
            value: 266,
          },
          {
            date: 1677456000000,
            value: 262,
          },
          {
            date: 1677628800000,
            value: 209,
          },
          {
            date: 1677801600000,
            value: 161,
          },
          {
            date: 1677888000000,
            value: 171,
          },
          {
            date: 1677974400000,
            value: 155,
          },
          {
            date: 1678060800000,
            value: 150,
          },
          {
            date: 1678233600000,
            value: 167,
          },
          {
            date: 1678320000000,
            value: 164,
          },
          {
            date: 1678406400000,
            value: 152,
          },
          {
            date: 1678492800000,
            value: 165,
          },
          {
            date: 1678579200000,
            value: 165,
          },
          {
            date: 1678665600000,
            value: 159,
          },
          {
            date: 1678752000000,
            value: 158,
          },
          {
            date: 1678838400000,
            value: 280,
          },
          {
            date: 1678924800000,
            value: 247,
          },
          {
            date: 1679011200000,
            value: 210,
          },
          {
            date: 1679097600000,
            value: 208,
          },
          {
            date: 1679184000000,
            value: 206,
          },
          {
            date: 1679270400000,
            value: 170,
          },
          {
            date: 1679356800000,
            value: 167,
          },
          {
            date: 1679443200000,
            value: 151,
          },
          {
            date: 1679529600000,
            value: 160,
          },
          {
            date: 1679616000000,
            value: 159,
          },
          {
            date: 1679702400000,
            value: 159,
          },
          {
            date: 1679788800000,
            value: 160,
          },
          {
            date: 1679875200000,
            value: 163,
          },
          {
            date: 1679961600000,
            value: 177,
          },
          {
            date: 1680048000000,
            value: 178,
          },
          {
            date: 1680134400000,
            value: 181,
          },
          {
            date: 1680220800000,
            value: 168,
          },
          {
            date: 1680307200000,
            value: 158,
          },
          {
            date: 1680393600000,
            value: 168,
          },
          {
            date: 1680480000000,
            value: 168,
          },
          {
            date: 1680566400000,
            value: 168,
          },
          {
            date: 1680652800000,
            value: 166,
          },
          {
            date: 1680739200000,
            value: 209,
          },
          {
            date: 1680825600000,
            value: 212,
          },
          {
            date: 1680912000000,
            value: 196,
          },
          {
            date: 1680998400000,
            value: 199,
          },
          {
            date: 1681084800000,
            value: 199,
          },
          {
            date: 1681171200000,
            value: 207,
          },
          {
            date: 1681257600000,
            value: 256,
          },
          {
            date: 1681344000000,
            value: 241,
          },
          {
            date: 1681430400000,
            value: 227,
          },
          {
            date: 1681516800000,
            value: 241,
          },
          {
            date: 1681603200000,
            value: 242,
          },
          {
            date: 1681689600000,
            value: 239,
          },
          {
            date: 1681776000000,
            value: 238,
          },
          {
            date: 1681862400000,
            value: 224,
          },
          {
            date: 1681948800000,
            value: 224,
          },
          {
            date: 1682035200000,
            value: 234,
          },
          {
            date: 1682121600000,
            value: 234,
          },
          {
            date: 1682208000000,
            value: 234,
          },
          {
            date: 1682294400000,
            value: 234,
          },
          {
            date: 1682380800000,
            value: 234,
          },
          {
            date: 1682467200000,
            value: 269,
          },
          {
            date: 1682553600000,
            value: 255,
          },
          {
            date: 1682640000000,
            value: 248,
          },
          {
            date: 1682726400000,
            value: 238,
          },
          {
            date: 1682812800000,
            value: 235,
          },
          {
            date: 1682899200000,
            value: 235,
          },
          {
            date: 1682985600000,
            value: 226,
          },
          {
            date: 1683072000000,
            value: 212,
          },
          {
            date: 1683158400000,
            value: 213,
          },
          {
            date: 1683244800000,
            value: 211,
          },
          {
            date: 1683331200000,
            value: 205,
          },
          {
            date: 1683417600000,
            value: 205,
          },
          {
            date: 1683504000000,
            value: 190,
          },
          {
            date: 1683590400000,
            value: 200,
          },
          {
            date: 1683676800000,
            value: 254,
          },
          {
            date: 1683763200000,
            value: 250,
          },
          {
            date: 1683849600000,
            value: 216,
          },
          {
            date: 1683936000000,
            value: 214,
          },
          {
            date: 1684022400000,
            value: 213,
          },
          {
            date: 1684108800000,
            value: 211,
          },
          {
            date: 1684195200000,
            value: 211,
          },
          {
            date: 1684281600000,
            value: 201,
          },
          {
            date: 1684368000000,
            value: 195,
          },
          {
            date: 1684454400000,
            value: 193,
          },
          {
            date: 1684540800000,
            value: 195,
          },
          {
            date: 1684627200000,
            value: 198,
          },
          {
            date: 1684713600000,
            value: 204,
          },
          {
            date: 1684800000000,
            value: 264,
          },
          {
            date: 1684886400000,
            value: 247,
          },
          {
            date: 1684972800000,
            value: 270,
          },
          {
            date: 1685059200000,
            value: 301,
          },
          {
            date: 1685145600000,
            value: 306,
          },
          {
            date: 1685232000000,
            value: 306,
          },
          {
            date: 1685318400000,
            value: 306,
          },
          {
            date: 1685404800000,
            value: 303,
          },
          {
            date: 1685491200000,
            value: 303,
          },
          {
            date: 1685577600000,
            value: 271,
          },
          {
            date: 1685664000000,
            value: 263,
          },
          {
            date: 1685750400000,
            value: 265,
          },
          {
            date: 1685836800000,
            value: 262,
          },
          {
            date: 1685923200000,
            value: 271,
          },
          {
            date: 1686009600000,
            value: 277,
          },
          {
            date: 1686096000000,
            value: 296,
          },
          {
            date: 1686182400000,
            value: 282,
          },
          {
            date: 1686268800000,
            value: 267,
          },
          {
            date: 1686355200000,
            value: 265,
          },
          {
            date: 1686441600000,
            value: 265,
          },
          {
            date: 1686528000000,
            value: 265,
          },
          {
            date: 1686614400000,
            value: 263,
          },
          {
            date: 1686700800000,
            value: 331,
          },
          {
            date: 1686787200000,
            value: 310,
          },
          {
            date: 1686873600000,
            value: 289,
          },
          {
            date: 1686960000000,
            value: 282,
          },
          {
            date: 1687046400000,
            value: 280,
          },
          {
            date: 1687132800000,
            value: 274,
          },
          {
            date: 1687219200000,
            value: 291,
          },
          {
            date: 1687305600000,
            value: 295,
          },
          {
            date: 1687392000000,
            value: 281,
          },
          {
            date: 1687478400000,
            value: 290,
          },
          {
            date: 1687564800000,
            value: 292,
          },
          {
            date: 1687651200000,
            value: 289,
          },
          {
            date: 1687737600000,
            value: 294,
          },
          {
            date: 1687824000000,
            value: 292,
          },
          {
            date: 1687910400000,
            value: 317,
          },
          {
            date: 1687996800000,
            value: 305,
          },
          {
            date: 1688083200000,
            value: 279,
          },
          {
            date: 1688169600000,
            value: 287,
          },
          {
            date: 1688256000000,
            value: 286,
          },
          {
            date: 1688342400000,
            value: 295,
          },
          {
            date: 1688428800000,
            value: 292,
          },
          {
            date: 1688515200000,
            value: 293,
          },
          {
            date: 1688601600000,
            value: 288,
          },
          {
            date: 1688688000000,
            value: 286,
          },
          {
            date: 1688774400000,
            value: 286,
          },
          {
            date: 1688860800000,
            value: 287,
          },
          {
            date: 1688947200000,
            value: 286,
          },
          {
            date: 1689033600000,
            value: 286,
          },
          {
            date: 1689120000000,
            value: 368,
          },
          {
            date: 1689206400000,
            value: 370,
          },
          {
            date: 1689292800000,
            value: 358,
          },
          {
            date: 1689379200000,
            value: 359,
          },
          {
            date: 1689465600000,
            value: 357,
          },
          {
            date: 1689552000000,
            value: 359,
          },
          {
            date: 1689638400000,
            value: 350,
          },
          {
            date: 1689724800000,
            value: 343,
          },
          {
            date: 1689811200000,
            value: 352,
          },
          {
            date: 1689897600000,
            value: 338,
          },
          {
            date: 1689984000000,
            value: 338,
          },
          {
            date: 1690070400000,
            value: 335,
          },
          {
            date: 1690156800000,
            value: 338,
          },
          {
            date: 1690243200000,
            value: 345,
          },
          {
            date: 1690329600000,
            value: 364,
          },
          {
            date: 1690416000000,
            value: 357,
          },
          {
            date: 1690502400000,
            value: 347,
          },
          {
            date: 1690588800000,
            value: 348,
          },
          {
            date: 1690675200000,
            value: 344,
          },
          {
            date: 1690761600000,
            value: 348,
          },
        ],
      },
    },
    topVulnerabilities: [
      {
        id: '378915',
        title: 'Microsoft Edge Based on Chromium Prior to 117.0.2045.55 Multiple Vulnerabilities',
        affectedHosts: 17,
      },
      {
        id: '92053',
        title: 'Microsoft Windows Defender Elevation of Privilege Vulnerability for August 2023',
        affectedHosts: 15,
      },
      {
        id: '378332',
        title: 'Microsoft WinVerifyTrust Signature Validation Vulnerability',
        affectedHosts: 11,
      },
      {
        id: '92038',
        title:
          'Microsoft Office and Windows HTML Remote Code Execution Vulnerability (Zero Day) for July 2023',
        affectedHosts: 5,
      },
      {
        id: '376730',
        title: 'Node.js Multiple Vulnerabilities (JULY 2022)',
        affectedHosts: 3,
      },
    ],
    topMostVulnerableHosts: [
      {
        id: 1,
        vulnerabilitiesCount: 1,
        hostname: 'prd-app01',
      },
      {
        id: 2,
        vulnerabilitiesCount: 1,
        hostname: 'desktop-fqd8p90',
      },
      {
        id: 3,
        vulnerabilitiesCount: 1,
        hostname: 'desktop-mp7I16c',
      },
      {
        id: 4,
        vulnerabilitiesCount: 1,
        hostname: 'desktop-6jsb91o',
      },
      {
        id: 5,
        vulnerabilitiesCount: 1,
        hostname: 'dev-app01',
      },
    ],
    patchVsConfiguration: {
      patch: 10,
      configuration: 55,
    },
  },
  alarmsCount: 461,
  eventsCount: 55563167,
  eventsChart: {
    allEvents: [
      {
        count: 1166444,
        collectedDateTime: 1688158800000,
      },
      {
        count: 1147601,
        collectedDateTime: 1688245200000,
      },
      {
        count: 2030547,
        collectedDateTime: 1688331600000,
      },
      {
        count: 2322104,
        collectedDateTime: 1688418000000,
      },
      {
        count: 2023331,
        collectedDateTime: 1688504400000,
      },
      {
        count: 2123366,
        collectedDateTime: 1688590800000,
      },
      {
        count: 1762943,
        collectedDateTime: 1688677200000,
      },
      {
        count: 1241025,
        collectedDateTime: 1688763600000,
      },
      {
        count: 1241715,
        collectedDateTime: 1688850000000,
      },
      {
        count: 2160560,
        collectedDateTime: 1688936400000,
      },
      {
        count: 2248093,
        collectedDateTime: 1689022800000,
      },
      {
        count: 2074663,
        collectedDateTime: 1689109200000,
      },
      {
        count: 2075261,
        collectedDateTime: 1689195600000,
      },
      {
        count: 1980962,
        collectedDateTime: 1689282000000,
      },
      {
        count: 1181040,
        collectedDateTime: 1689368400000,
      },
      {
        count: 1127014,
        collectedDateTime: 1689454800000,
      },
      {
        count: 2023187,
        collectedDateTime: 1689541200000,
      },
      {
        count: 2240939,
        collectedDateTime: 1689627600000,
      },
      {
        count: 1907280,
        collectedDateTime: 1689714000000,
      },
      {
        count: 1807781,
        collectedDateTime: 1689800400000,
      },
      {
        count: 1735535,
        collectedDateTime: 1689886800000,
      },
      {
        count: 1288869,
        collectedDateTime: 1689973200000,
      },
      {
        count: 1264849,
        collectedDateTime: 1690059600000,
      },
      {
        count: 2354029,
        collectedDateTime: 1690146000000,
      },
      {
        count: 2107911,
        collectedDateTime: 1690232400000,
      },
      {
        count: 2344322,
        collectedDateTime: 1690318800000,
      },
      {
        count: 2094102,
        collectedDateTime: 1690405200000,
      },
      {
        count: 1937234,
        collectedDateTime: 1690491600000,
      },
      {
        count: 1234792,
        collectedDateTime: 1690578000000,
      },
      {
        count: 1213562,
        collectedDateTime: 1690664400000,
      },
      {
        count: 2102106,
        collectedDateTime: 1690750800000,
      },
    ],
    allEventsCount: 55563167,
    trendingPercent: 5.2,
  },
  boostScoreItems: [
    {
      title: 'Encryption',
      vendor: 'Bit locker',
      expirationDate: 1704050603000,
    },
    {
      title: 'Multi-factor Authentication',
      vendor: 'Microsoft MFA',
      expirationDate: 1704050616350,
    },
    {
      title: 'Email security',
      vendor: 'FireEye ETP',
      expirationDate: 1704050624000,
    },
    {
      title: 'Mobile Device Management',
      vendor: 'Microsoft InTune',
      expirationDate: 1704050636588,
    },
  ],
  licenseUsage: {
    cyberSafetyAndPhishing: {
      used: 42,
      currentLimit: 21,
    },
    vulnerabilityAgents: {
      used: 56,
      currentLimit: 0,
    },
    threatDetectionAgents: {
      used: 0,
      currentLimit: 37,
    },
    threatDetectionServices: {
      used: 11,
      currentLimit: 3,
    },
  },
  hasPenetrationTestingProduct: true,
};
