export enum Routes {
  ROOT = '/',
  FORGOT_PASSWORD = '/forgot_password',
  RESET_PASSWORD = '/reset-password/:code',
  PRIVACY = '/privacy',
  TERMS = '/terms',
  DASHBOARD = '/dashboard',
  PROCESS = '/process',
  PEOPLE = '/people',
  DATA_PROTECTION_LIBRARY = '/data_protection_library',
  PEOPLE_PHISHING = '/people/people_phishing',
  VULNERABILITY_MANAGEMENT = '/vulnerability_management',
  VULNERABILITIES_LIST = '/vulnerability_management/list',
  VULNERABILITY_DETAILS = '/vulnerability_management/list/:id',
  PATCH_MANAGEMENT_PATCHES = '/patch_management/patches',
  PATCH_MANAGEMENT_PATCH_DETAILS = '/patch_management/patches/:id',
  THREAT_MAP = '/threat_map',
  THREAT_DETECTION = '/threat_detection',
  MONITORED_SERVICE_DETAILS = '/threat_detection/monitored-services/:name',
  ALARMS = '/threat_detection/alarms',
  ALARM_DETAILS = '/threat_detection/alarms/:id',
  PENETRATION_TESTING = '/penetration_testing',
  PENETRATION_TESTS_LIST = '/penetration_testing/tests',
  EMAIL_SECURITY = '/email_security',
  DATA_PROTECTION = '/data_protection',
  CMDB = '/cmdb',
  CMDB_HOST_DETAILS = '/cmdb/:id',
  CMDB_AGED_HOSTS = '/aged-hosts',
  CMDB_AGED_HOSTS_DETAILS = '/aged-hosts/:id',
  SUPPORT_REQUESTS = '/support_requests',
  SUPPORT_REQUESTS_DETAILS = '/support_requests/:id',
  DOCUMENTS = '/documents',
  SOFTWARE = '/software',
  USERS = '/users',
  USERS_DETAILS = '/users/:id',
  AUDIT_LOG = '/audit_log',
  SECURITY_SETTINGS = '/security_settings',
  REPORT_INCIDENT = '/report_incident',
  SERVICE_REQUEST = '/service_request',
  FINDINGS = '/penetration_testing/findings',
  FINDINGS_VULNERABILITY_DETAILS = '/penetration_testing/findings/:id',
  DATA_PROTECTION_DOCUMENTS = '/data_protection/documents',
  PATCH_MANAGEMENT = '/patch_management',
  PATCH_MANAGEMENT_ASSETS = '/patch_management/pm_assets',
  CVSS_VECTOR_STRINGS = '/cvss_vector_strings',
  CE_PLUS = '/ce_plus',
  TAGS_MANAGEMENT = '/tags_management',
  NOT_FOUND = '/404',
  MAINTENANCE_SETTINGS = '/maintenance_settings',
  MAINTENANCE_PAGE_VIEW_MODE = '/503',
  NOTIFICATIONS = '/notifications',
  NOTIFICATIONS_CREATE = '/notifications/create',
  NOTIFICATIONS_EDIT = '/notifications/:id/edit',
  NOTIFICATIONS_DETAILS = '/notifications/:id',
  APPEARANCE = '/appearance',
  SERVICES_INTEGRATIONS = '/services-integrations',
  SERVICE_INTEGRATION_DETAILS = '/services-integrations/:id',
  SERVICE_INTEGRATION_CONNECTION_EDIT = '/services-integrations/:id/edit-connection',
  SERVICE_INTEGRATION_OVERVIEW_EDIT = '/services-integrations/:id/edit-overview',
  SERVICE_INTEGRATION_INSTRUCTIONS_EDIT = '/services-integrations/:id/edit-instructions',
  // for non-production environments only
  UI_KIT = '/ui-kit',
  MONTHLY_REPORT = '/monthly-report',
}

export const unAuthorizedRoutes = [
  Routes.ROOT,
  Routes.FORGOT_PASSWORD,
  `/${Routes.RESET_PASSWORD.split('/')[1]}`,
];

export enum RestorePasswordSteps {
  INITIAL,
  INSTRUCTIONS_SENT,
  RESET,
  DONE,
}

export enum TwoFASteps {
  INITIAL,
  SETUP,
  CONFIRM,
}

export enum ResponseCodes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
}
