export enum TableTitles {
  Alarm = 'Alarm',
  Risk = 'Risk',
  Origin = 'Origin',
  Status = 'Status',
  Updated = 'Updated',
}

export enum AlarmStatuses {
  Open = 'Open',
  TuningRequired = 'Tuning Required',
  Investigating = 'Investigating',
  Closed = 'Closed',
  WithCustomer = 'With customer',
  ReOpened = 'Re-Opened',
}
