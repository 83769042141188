import { colors } from '../colors';

export const severity = {
  critical: colors.red[650],
  high: colors.red[450],
  meduim: colors.yellow[460],
  low: colors.green[700],
  informational: colors.blue[650],
  analysis: colors.gray[120],
  healthy: colors.green[700],
  none: colors.blue[900],
  unhealthy: colors.pink[100],
  active: colors.pink[100],
};
