export const colors = {
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.02)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.15)',
    600: 'rgba(41, 41, 51, 0.6)',
  },

  whiteAlpha: {
    600: 'rgba(255, 255, 255, 0.6)',
  },

  blueAlpha: {
    200: 'rgba(224, 227, 232, 0.2)',
  },

  gray: {
    10: '#f1f1f1',
    50: '#f9fafc',
    60: '#E3E6E3',
    70: '#E0E3E8',
    80: '#EEECEC',
    100: '#eeede8',
    130: '#D9D9D9',
    110: '#D7D5D5',
    120: '#D3D1CF',
    150: '#BFBDBD',
    200: '#d3cfcc',
    300: '#d7d7cf',
    310: '#D6D6D4',
    390: '#BFBDBD',
    400: '#a1a1a1',
    410: '#A6A6A6',
    500: '#A69E99',
    510: '#7F7F7F',
    520: '#686466',
    530: '#555555',
    700: '#494b42',
    800: '#34372B',
    810: '#2F2F2F',
  },

  blue: {
    50: '#e0e3e8',
    100: '#84b5e3',
    400: '#73C0DE',
    410: '#95B8FF',
    500: '#3a89f3',
    550: '#2A26F9',
    600: '#5470C6',
    650: '#95B8FF',
    700: '#5277FC',
    750: '#5654C6',
    900: '#292933',
    910: '#1C1C1C',
  },

  purple: {
    300: '#D45CC8',
    400: '#9A60B4',
    410: '#9E5CB3',
    500: '#BF1F4F',
    550: '#9C233F',
  },

  green: {
    100: '#3BA272',
    150: '#65CC98',
    200: '#babaab',
    300: '#9ccc65',
    310: '#64C273',
    320: '#4EB38D',
    400: '#48bb78',
    450: '#6FAC46',
    500: '#43a047',
    600: '#61C8AF',
    700: '#38A078',
  },

  brown: {
    100: '#BABAAB',
    200: '#BCB5A8',
    400: '#BEA167',
    500: '#866930',
  },

  red: {
    100: '#f8d7da',
    200: '#B9A5A0',
    300: '#FC8452',
    400: '#eb644a',
    450: '#EB4A70',
    500: '#ff8770',
    600: '#EE6666',
    650: '#9C233F',
    700: '#9c2b23',
    800: '#C00000',
  },

  pink: {
    500: '#E03B92',
    300: '#FFADAD',
    200: '#D13B5F',
    100: '#EB4A70',
    50: '#EB4A84',
  },

  yellow: {
    100: '#C2BAAA',
    300: '#B4AC60',
    400: '#BEA167',
    450: '#DB9E23',
    460: '#FFB548',
    500: '#f1ae27',
    550: '#F5CB8C',
    560: '#D9932B',
  },

  orange: {
    100: '#E3D9CF',
    200: '#D7C2B0',
    400: '#FC8452',
  },
};

export const opacity = {
  0: '00',
  5: '0C',
  10: '19',
  12: '1E',
  15: '26',
  20: '33',
  25: '3F',
  30: '4C',
  40: '66',
  50: '7F',
  60: '99',
  75: 'C0',
  70: 'B2',
  80: 'CC',
  90: 'E5',
  100: 'FF',
};
