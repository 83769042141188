import { createAsyncThunk } from '@reduxjs/toolkit';
import { GridRequestParams, LogsService, RequestWithOnlyCustomer } from 'services/api';

const getAuditLogs = createAsyncThunk('audit/get', async (requestBody: GridRequestParams) => {
  const response = await LogsService.getAuditLogs(requestBody);

  return response;
});

const getAuditActionTypes = createAsyncThunk(
  'audit/get/action-types',
  async (requestBody: RequestWithOnlyCustomer) => {
    const response = await LogsService.getActionTypes(requestBody);

    return response;
  },
);

export const auditApi = { getAuditLogs, getAuditActionTypes };
