import styled from 'styled-components';
import { Form } from 'antd';
import { Box, Card } from 'shared';
import { breakpoints } from 'styles/theme/breakpoints';
import { ThemeProps } from 'services/interfaces';
import { Theme } from '../../styles/theme/types';
import { getCssVar } from '../../styles/theme';

export const InputLabel = styled(Form.Item)<ThemeProps>`
  margin-bottom: 22px;

  label {
    font-weight: ${({ theme }) => theme.colorSet.securitySettingsWeight};
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: ${({ theme }) => theme.colors.red[700]};
    margin-right: 0;
    position: relative;
    top: -2px;
  }

  .ant-form-item-label {
    margin-bottom: 5px;
  }

  .ant-form-item-tooltip,
  svg {
    height: 1.5rem;
    width: 1.5rem;

    :hover {
      fill: ${({ theme }) => theme.colorSet.arrow};
    }
  }
`;

export const StyledCard = styled(Card)`
  .ant-descriptions-item-label {
    font-weight: ${getCssVar('securitySettingsWeight')};
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 10px 8px;
  }
`;

export const NotifyText = styled.span`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes['3.6']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const PreviewText = styled.span`
  color: ${getCssVar('primaryColor')};
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes['4']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-bottom: 7px;
`;

export const ExampleText = styled(NotifyText)`
  color: ${getCssVar('textColor')};
  opacity: 0.5;
`;

export const SmartBlockText = styled(NotifyText)`
  color: ${({ theme }) => theme.colors.brown[100]};
  font-weight: ${({ theme }) => theme.fontWeights.extrabold};
  margin-top: 8px;
  padding-left: 10px;
`;

interface EditProps {
  isError?: boolean;
}

export const EditModeWrapper = styled(Box)<EditProps>`
  align-items: flex-start;
  background-color: ${getCssVar('select.bg')};
  border: ${({ isError }: EditProps) =>
    isError
      ? `2px solid ${getCssVar('errorColor')} !important`
      : `2px solid ${getCssVar('input.disable.borderColor')}`};
  display: flex;
  flex-direction: row;
  justify-content: start;

  textarea {
    color: ${getCssVar('textColor')};
  }

  &:hover {
    border-color: ${getCssVar('input.hoverBorderColor')};
  }
`;

export const ViewModeWrapper = styled(Box)`
  span {
    &:first-child {
      font-weight: ${({ theme }) => theme.fontWeights.extrabold};
    }
  }

  div {
    margin-top: 8px;
  }

  a {
    text-decoration: none;
  }
`;

export const SnackBarEditModeMessage = styled(Box)`
  color: ${getCssVar('textColor')};
  padding: 0 50px;
  text-align: center;

  div {
    margin-top: 10px;
  }

  @media (max-width: ${breakpoints.xml}) {
    padding: 0;
  }
`;

export const MaintenanceEditModeMessage = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 380px;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}) {
    height: 300px;
  }
`;
