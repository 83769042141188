import { useEffect, useRef } from 'react';
import { MessagingClient, initialState } from './messaging-client';

export const useMessagingClient = () => {
  const clientRef = useRef<MessagingClient>(initialState);

  useEffect(() => {
    clientRef.current = new MessagingClient();
  }, []);

  return clientRef.current;
};
