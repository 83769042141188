import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AdminUsersService,
  ApiError,
  CreateUserRequest,
  GridRequestParams,
  UpdateUserPermissionRequest,
} from 'services/api';

const adminUsersTableContent = createAsyncThunk(
  'admin-users/data/get',
  async (requestBody: GridRequestParams) => {
    try {
      const response = await AdminUsersService.getUsers(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const userDetails = createAsyncThunk(
  'user/details/get',
  async (requestBody: { customerId: string; userId: string }) => {
    try {
      const response = await AdminUsersService.getUserDetails(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const userPermissions = createAsyncThunk(
  'user/permissions/get',
  async (requestBody: { customerId: string; userId: string }) => {
    try {
      const response = await AdminUsersService.getUserPermissions(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const updateUserPermissions = createAsyncThunk(
  'user/permissions/put',
  async (request: {
    customerId: string;
    userId: string;
    requestBody: UpdateUserPermissionRequest;
  }) => {
    try {
      const response = await AdminUsersService.updateUserPermissions(request);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const lockUser = createAsyncThunk(
  'admin-users/user/lock',
  async (requestBody: { customerId: string; userId: string; comments?: string }) => {
    try {
      const response = await AdminUsersService.lockUser(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const unlockUser = createAsyncThunk(
  'admin-users/user/unlock',
  async (requestBody: { customerId: string; userId: string }) => {
    try {
      const response = await AdminUsersService.unlockUser(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const deleteUser = createAsyncThunk(
  'admin-users/user/delete',
  async (requestBody: { customerId: string; userId: string; comments?: string }) => {
    try {
      const response = await AdminUsersService.deleteUser(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const resetUserTwofa = createAsyncThunk(
  'admin-users/user/reset-twofa',
  async (requestBody: { customerId: string; userId: string }) => {
    try {
      const response = await AdminUsersService.resetUserTwofa(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const createUser = createAsyncThunk(
  'admin-users/user/create',
  async (requestBody: { customerId: string; requestBody?: CreateUserRequest }) => {
    try {
      const response = await AdminUsersService.createUser(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const updateUser = createAsyncThunk(
  'admin-users/user/update',
  async (requestBody: { customerId: string; userId: string; requestBody?: CreateUserRequest }) => {
    try {
      const response = await AdminUsersService.updateUser(requestBody);

      return response;
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const adminUsersApi = {
  adminUsersTableContent,
  userDetails,
  userPermissions,
  updateUserPermissions,
  lockUser,
  unlockUser,
  deleteUser,
  resetUserTwofa,
  createUser,
  updateUser,
};
