import moment from 'moment';
import { emptyDataString } from 'app-constants';

const SECONDS_THRESHOLD = 60;
const DAY_THRESHOLD = 1;
const MONTH_THRESHOLD = 1;
const YEAR_THRESHOLD = 1;

export const formatUpdatedOrCreatedTime = (date: string | number, withoutTime?: boolean) => {
  const format = withoutTime ? 'DD-MMM-YYYY' : 'DD-MMM-YYYY HH:mm';

  return moment(!+date ? date : +date).format(format);
};

export const getFormattedDate = (
  date?: string | number,
  emptyValue?: string,
  withoutTime?: boolean,
) => {
  if (!date) {
    return emptyValue || emptyDataString;
  }

  return formatUpdatedOrCreatedTime(date, withoutTime);
};

export const getTimeFromLastUpdateOrCreate = (date: string) => {
  return moment(!+date ? date : +date).fromNow();
};

export const getHowManyDaysAgo = (months: number): number => {
  const now = moment();
  const end = moment().subtract(months, 'months');
  const duration = moment.duration(now.diff(end));

  return duration.asDays();
};

export const getTimeAgoLabel = (date?: string | number) => {
  if (!date) {
    return '-';
  }

  const now = moment();
  const targetTime = moment(date);
  const duration = moment.duration(now.diff(targetTime));

  const seconds = duration.asSeconds();
  const days = duration.asDays();
  const months = duration.asMonths();
  const years = duration.asYears();
  const isYesterday = now.clone().subtract(1, 'days').isSame(targetTime, 'day');

  switch (true) {
    case seconds < SECONDS_THRESHOLD:
      return 'Now';
    case isYesterday:
      return 'Yesterday';
    case days < DAY_THRESHOLD:
      return 'Today';
    case days < 2:
      return '1 day ago';
    case months < MONTH_THRESHOLD:
      return `${Math.floor(days)} days ago`;
    case months < 2:
      return '1 month ago';
    case years < YEAR_THRESHOLD:
      return `${Math.floor(months)} months ago`;
    case years < 2:
      return '1 year ago';
    default:
      return `${Math.floor(years)} years ago`;
  }
};

export const isMoreThanNumberOfDaysAgo = (numberOfDays: number, date: string) => {
  const numberOfDaysAgo = moment().subtract(numberOfDays, 'days');
  const inputDate = moment(date);

  return inputDate.isBefore(numberOfDaysAgo);
};
