import { colors } from '../colors';

export const severity = {
  critical: colors.red[700],
  high: colors.red[400],
  meduim: colors.yellow[500],
  low: colors.green[300],
  informational: colors.blue[100],
  analysis: colors.brown[200],
  healthy: colors.green[300],
  none: colors.blue[900],
  unhealthy: colors.red[400],
  active: colors.red[400],
};
