import React from 'react';
import { Box, InnerLayout } from 'shared';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Col } from 'antd';
import { DarkCard, MapLegend, MapWrapper } from './styled';
import { getLayoutConfig, legendItems } from './helpers';

export const ThreatMap = () => {
  const layoutConfig = getLayoutConfig();
  const isMobile = useSelector((state: AppState) => state.app.isMobile);
  const theme = useTheme();

  return (
    <InnerLayout {...layoutConfig}>
      <Col xxl={24}>
        <DarkCard
          showDivider={false}
          height={theme.sizes['3xl']}
          headStyles={
            !isMobile
              ? { pt: theme.sizes[2], titlePaddingRight: theme.sizes['2.5'] }
              : { titlePaddingRight: theme.sizes[0] }
          }
          bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
          cardPadding={!isMobile ? theme.sizes[6] : `${theme.sizes[5]}`}
        >
          <MapWrapper>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe src='https://threatmap.fortiguard.com/' />
          </MapWrapper>
          <MapLegend>
            {legendItems.map(({ title, img }) => {
              return (
                <Box key={title}>
                  <img src={img} alt={title} />
                  <p>{title}</p>
                </Box>
              );
            })}
          </MapLegend>
        </DarkCard>
      </Col>
    </InnerLayout>
  );
};
