import { createSlice } from '@reduxjs/toolkit';
import { TagRequest, TagSchema } from 'services/api';
import { tagsManagementApi } from './thunks';

export type TagsManagementResponse = {
  rows?: Array<TagSchema>;
  count?: number;
};

interface TagsManagementState {
  tagsGrid: TagsManagementResponse;
  tagsList?: TagSchema[];
  tag?: TagSchema;
  editableTag: null | TagSchema;
}

export const tagsManagementInitialState: TagsManagementState = {
  tagsGrid: {},
  tagsList: [],
  tag: {},
  editableTag: null,
};

const updateTagsList = (tags: TagSchema[], editedTag: TagSchema): TagSchema[] => {
  if (tags.length === 0) {
    return [];
  }

  const index = tags.findIndex((tag) => tag.id === editedTag.id);
  const copy = [...tags];

  if (index >= 0) {
    copy[index] = {
      ...copy[index],
      ...editedTag,
      // count: copy[index].count || 0,
    };

    return copy;
  }

  return copy;
};

export const tagsManagement = createSlice({
  name: 'tagsManagement',
  initialState: tagsManagementInitialState,
  reducers: {
    setEditTags: (state: TagsManagementState, action: { payload: null | TagSchema }) => ({
      ...state,
      editableTag: action.payload,
    }),
  },
  extraReducers: {
    [tagsManagementApi.getTagsList.fulfilled.toString()]: (
      state: TagsManagementState,
      action: { payload: TagSchema[] },
    ) => {
      state.tagsList = action.payload;
    },
    [tagsManagementApi.getTagsList.rejected.toString()]: (state: TagsManagementState) => {
      state.tagsList = [];
    },
    [tagsManagementApi.editTagById.fulfilled.toString()]: (
      state: TagsManagementState,
      action: { payload: TagSchema },
    ) => {
      state.editableTag = null;

      state.tagsList = updateTagsList(state.tagsList || [], action.payload);
      state.tagsGrid = {
        ...state.tagsGrid,
        rows: updateTagsList(state.tagsGrid.rows || [], action.payload),
      };
    },
    [tagsManagementApi.editTagById.rejected.toString()]: (state: TagsManagementState) => {
      state.tagsList = [];
    },
    [tagsManagementApi.getTagsGrid.fulfilled.toString()]: (
      state: TagsManagementState,
      action: { payload: TagsManagementResponse },
    ) => {
      state.tagsGrid = action.payload;
    },
    [tagsManagementApi.getTagsGrid.rejected.toString()]: (state: TagsManagementState) => {
      state.tagsGrid = {};
    },
    [tagsManagementApi.createTag.fulfilled.toString()]: (
      state: TagsManagementState,
      action: { payload: TagRequest },
    ) => {
      state.tag = action.payload;
    },
    [tagsManagementApi.createTag.rejected.toString()]: (state: TagsManagementState) => {
      state.tag = {};
    },
  },
});
