import { formatDate } from 'pages/ServicesIntegrations/helpers';
import { GuidanceItem } from 'services/api';
import { LastUpdatedText } from './styled';

type LastUpdatedProps = {
  details?: GuidanceItem;
};

export const LastUpdated = ({ details }: LastUpdatedProps) => {
  if (!details?.updatedAt) {
    return null;
  }

  const updatedBy = details?.updatedBy ? ` by ${details?.updatedBy}` : '';

  return (
    <LastUpdatedText>
      Last updated {formatDate(details?.updatedAt, false, true)}
      {updatedBy}
    </LastUpdatedText>
  );
};
