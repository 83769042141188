import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, managedDetectionsResponseApi, useApp, useCustomer, useLoading } from 'store';
import { IDate } from 'services/interfaces';
import { Card } from 'shared';
import { RadioButtonsWithRangePicker } from 'shared/RadioButtonsWithRangePicker/RadioButtonsWithRangePicker';
import { getDefaultDateRangeValue } from 'shared/RadioButtonsWithRangePicker/helpers';
import {
  ManagedDetectionsResponseTitles,
  mdrBackRoute,
  tooltipTitles,
  viewMoreConfig,
} from 'pages/ThreatDetection/constants';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { useTheme } from 'styled-components';
import { GroupedPie } from 'shared/charts';
import { useHistory } from 'react-router';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { isEmptyObject } from 'services/helpers';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { Routes } from 'services/entities';
import { getQueryString } from 'services/api/core/request';
import { DividerLegend } from './DividerLegend';
import { ChartWrapper } from './styled';
import { getLegendChartData, getLegendPolarColors } from './helpers';
import { InvestigationOverviewLegend } from './constants';
import { AllEventsPopup } from './AllEventsPopup';

export const InvestigationOverview = () => {
  const history = useHistory();
  const theme = useTheme();
  const customer = useCustomer();
  const { isMobile } = useApp();
  const [date, setDate] = React.useState<IDate>(getDefaultDateRangeValue());
  const [periodName, setPeriodName] = useState('Month');
  const [isVisibleAllEventsPopup, setIsVisibleAllEventsPopup] = useState(false);
  const dispatch = useDispatch();
  const chartData = useSelector(
    ({ mdr: { investigationOverview } }: AppState) => investigationOverview,
  );
  const loading = useLoading([managedDetectionsResponseApi.getInvestigationOverview.typePrefix]);
  const colors = {
    events: '#98A9DD',
    alarms: theme.colors.yellow[500],
    incidents: theme.colors.red[700],
  };

  const legendValues = getLegendChartData(Object.entries(chartData));

  React.useEffect(() => {
    const { startDate, endDate } = date;

    if (startDate && endDate) {
      dispatch(
        managedDetectionsResponseApi.getInvestigationOverview({
          customerId: customer,
          startDate,
          endDate,
        }),
      );
    }
  }, [dispatch, customer, date]);

  const updateDate = React.useCallback((selectedDate: IDate) => {
    setDate(selectedDate);
  }, []);

  const onLegendClickHandler = useCallback(
    (legendItem: string) => {
      const { startDate, endDate } = date;

      if (legendItem === InvestigationOverviewLegend.Alarms) {
        history.push(
          `${Routes.ALARMS}${getQueryString(
            viewMoreConfig.investigationOverview.alarms({ startDate, endDate }),
          )}`,
          mdrBackRoute,
        );
      }

      if (legendItem === InvestigationOverviewLegend.Incidents) {
        history.push(
          `${Routes.SUPPORT_REQUESTS}${getQueryString(
            viewMoreConfig.investigationOverview.incidents({ startDate, endDate }),
          )}`,
          mdrBackRoute,
        );
      }

      if (legendItem === InvestigationOverviewLegend.Events) {
        setIsVisibleAllEventsPopup(true);
      }
    },
    [date, history],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params?: EChartsEventParams) => {
        if (!params?.name) {
          return null;
        }

        return onLegendClickHandler(params.name);
      },
    }),
    [onLegendClickHandler],
  );

  const isLoadingContent =
    loading[managedDetectionsResponseApi.getInvestigationOverview.typePrefix];

  return (
    <>
      <Card
        title={ManagedDetectionsResponseTitles.InvestigationOverview}
        tooltipTitle={tooltipTitles.investigationOverview}
        showDivider={false}
        height={theme.sizes.full}
        headStyles={
          !isMobile
            ? { pt: theme.sizes[2], titlePaddingRight: theme.sizes['2.5'] }
            : { titlePaddingRight: theme.sizes[0] }
        }
        bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
        cardPadding={
          !isMobile
            ? theme.sizes[6]
            : `${theme.sizes[5]} ${theme.sizes[0]} ${theme.sizes[5]} ${theme.sizes[5]}`
        }
        showLoader={isLoadingContent}
      >
        <RadioButtonsWithRangePicker
          handleDateChange={updateDate}
          date={date}
          isMobile={isMobile}
          setPeriodName={setPeriodName}
        />
        <RenderChartWrapper values={chartData} isShowEmptyChart={true}>
          {!isEmptyObject(legendValues) ? (
            <ChartWrapper>
              <GroupedPie
                values={legendValues}
                colors={getLegendPolarColors(Object.entries(chartData), colors)}
                entitiesName='Events'
                isMenuCollapsed={false}
                onEvents={onEvents}
                noLegend
              />
              <DividerLegend
                data={chartData}
                onClickHandler={onLegendClickHandler}
                colors={colors}
                periodName={periodName}
              />
            </ChartWrapper>
          ) : (
            emptyMessage('No investigation overview data yet')
          )}
        </RenderChartWrapper>
      </Card>
      {isVisibleAllEventsPopup && (
        <AllEventsPopup
          isVisible={isVisibleAllEventsPopup}
          setIsVisible={setIsVisibleAllEventsPopup}
          selectedDate={date}
          periodName={periodName}
        />
      )}
    </>
  );
};
