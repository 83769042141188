import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Divider } from 'antd';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { Icon, Input } from '../../../shared';
import {
  AppDispatch,
  cmdbApi,
  useApp,
  useCustomer,
  useLoading,
  useTagsManagement,
} from '../../../store';
import { CreateTagButton, CustomSpacer, TagSelector } from '../styled';
import { tagsManagementApi } from '../../../store/tagsManagement/thunks';
import { getCssVar, icons, sizes } from '../../../styles/theme';
import { OptionType } from '../../../shared/Table/types';
import { TagSchema } from '../../../services/api';
import { LIST_HEIGHT_FOR_10_ITEMS } from '../constants';
import { RequestStatus } from '../../../store/loading/slice';
import { showError } from '../../../services/helpers';
import { MAX_TAG_NAME_LENGTH_DESKTOP } from '../../TagsManagement/constant';

interface ITagsSelectorProps {
  rowId?: string;
  closeSelector: () => void;
  onChanged?: () => void;
}

export const TagsSelector = ({ rowId, closeSelector, onChanged }: ITagsSelectorProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { tagsList = [] } = useTagsManagement();
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const loading = useLoading([tagsManagementApi.getTagsList.typePrefix]);

  useEffect(() => {
    dispatch(tagsManagementApi.getTagsList(customerId));
  }, [customerId, dispatch]);

  const [newTag, setNewTag] = useState('');
  const name = newTag.trimStart().trimEnd();

  const onTagChange: ChangeEventHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewTag(event.target.value);
  };

  const create = async () => {
    if (!newTag) {
      return;
    }

    const api = tagsManagementApi.createTag({ customerId, requestBody: { name } });

    const response = (await dispatch(api)) as PayloadAction<
      TagSchema[],
      string,
      { requestStatus: string }
    >;

    const getTagById = response.payload?.find((item: { name?: string }) => item?.name === name)?.id;

    if (getTagById && rowId) {
      dispatch(cmdbApi.setTag({ customerId, hostId: rowId, tagId: getTagById }));
      setNewTag('');
      closeSelector();
    }

    if (response.meta.requestStatus === RequestStatus.Rejected) {
      showError(
        'Tag Already Exist. The tag name you entered is already in use. Please choose a different name to ensure uniqueness and avoid conflicts with existing tags.',
      );

      return;
    }

    if (onChanged) {
      onChanged();
    }
  };

  const selectTagHandler = useCallback(
    async (tagId) => {
      if (rowId) {
        await dispatch(cmdbApi.setTag({ customerId, hostId: rowId, tagId }));

        if (onChanged) {
          onChanged();
        }
      }

      closeSelector();
    },
    [closeSelector, customerId, dispatch, onChanged, rowId],
  );

  const selectTagOptions = useMemo(() => {
    return tagsList?.map((item: TagSchema) => ({
      label: item?.name,
      value: item?.id,
    })) as OptionType[];
  }, [tagsList]);

  const filterOption = (inputValue: string, option: OptionData | OptionGroupData | undefined) =>
    (option as OptionType).label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

  return (
    <TagSelector
      defaultOpen
      dropdownStyle={isMobile ? { minWidth: 230 } : { minWidth: 206 }}
      showSearch
      onSelect={selectTagHandler}
      options={selectTagOptions}
      filterOption={filterOption}
      suffixIcon={
        <Icon component={icons.search} dimension={isMobile ? sizes['4'] : sizes['3.5']} />
      }
      onDropdownVisibleChange={(open: boolean) => {
        if (!open) {
          closeSelector();
        }
      }}
      loading={loading[tagsManagementApi.getTagsList.typePrefix]}
      listHeight={LIST_HEIGHT_FOR_10_ITEMS}
      dropdownRender={(menu: ReactNode) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0', backgroundColor: getCssVar('divider.color') }} />
          <CustomSpacer>
            <Input
              placeholder='New tag name'
              style={{ maxWidth: 150, fontSize: 15, padding: '4px 8px' }}
              value={newTag}
              onChange={onTagChange}
              maxLength={MAX_TAG_NAME_LENGTH_DESKTOP}
            />
            <CreateTagButton onClick={create}>Create</CreateTagButton>
          </CustomSpacer>
        </>
      )}
    />
  );
};
