import React from 'react';
import { useTheme } from 'styled-components';
import { ReactComponent as Smartbloc } from './icons/smartbloc.svg';
import { PATH_TO_ICONS } from '../../../../app-constants';
import { getSupportEmail, getSupportPhone } from '../../../../themes/helpers';
import { useApp, useMaintenanceSettings } from '../../../../store';
import {
  BlockContainer,
  Logo,
  Message,
  MessageFooter,
  MessageHeader,
  MessageText,
  PageContainer,
} from './style';

export const MaintenancePageViewMode = () => {
  const theme = useTheme();
  const { themeName } = useApp();
  const { maintenanceSettings, maintenancePageMessageViewMode } = useMaintenanceSettings();

  return (
    <PageContainer>
      <BlockContainer>
        <Message>
          <MessageHeader>
            <Smartbloc />
            {maintenancePageMessageViewMode || maintenanceSettings?.maintenancePageMessage}
          </MessageHeader>

          <MessageText>
            For any urgent issues please contact the support desk on{' '}
            <a href={`tel:${getSupportPhone(themeName)}`}>{getSupportPhone(themeName)}</a> or drop
            us an email at{' '}
            <a href={`mailto:${getSupportEmail(themeName)}`}>{getSupportEmail(themeName)}</a>
          </MessageText>

          <MessageFooter>Apologies for any inconvenience</MessageFooter>
        </Message>
        <Logo
          src={`${PATH_TO_ICONS}/${theme.colorSet.login.logoDesktop}`}
          width={theme.colorSet.login.logoWidth}
          height={theme.colorSet.login.logoHeight}
          alt='logo'
        />
      </BlockContainer>
    </PageContainer>
  );
};
