import { InnerLayout, Card, BackLink } from 'shared';
import { Routes } from 'services/entities';
import { useLocation, useParams } from 'react-router-dom';
import { HistoryState } from 'services/interfaces';
import { useApp, servicesIntegrationsApi } from 'store';
import { useTheme } from 'styled-components';
import { ServiceIntegrationType } from 'pages/ServicesIntegrations/types';
import { useIsAnyRequestLoading } from 'shared/hooks';
import { EditConnectionSettingsFactory } from './components';
import { useGetServiceDetails } from './hooks';

export const EditConnectionDetails = () => {
  const { isMobile } = useApp();
  const { sizes } = useTheme();
  const { connectionDetails } = useGetServiceDetails();
  const { state: historyState, pathname } = useLocation<HistoryState>();
  const { id } = useParams<{ id: string }>();

  const isLoading = useIsAnyRequestLoading([
    servicesIntegrationsApi.disconnectService.typePrefix,
    servicesIntegrationsApi.testConnection.typePrefix,
    servicesIntegrationsApi.getServiceDetails.typePrefix,
    servicesIntegrationsApi.updateServiceConnectionDetails.typePrefix,
  ]);

  return (
    <InnerLayout
      title={historyState?.data?.serviceName}
      backLink={
        <BackLink
          defaultRoute={`${Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', id)}`}
          defaultTitle={`${historyState?.data?.serviceName} integration`}
          historyState={historyState?.backTo?.route !== pathname ? historyState : undefined}
        />
      }
    >
      <Card
        title='Connection settings'
        showDivider
        showLoader={isLoading}
        cardPadding={!isMobile ? sizes['5.5'] : `${sizes[4]} ${sizes[5]}`}
        headStyles={{
          pb: isMobile ? sizes[2] : sizes[4],
          titlePaddingRight: isMobile ? sizes[0] : sizes['2.5'],
        }}
        bodyPadding='0px'
      >
        <EditConnectionSettingsFactory
          connectionDetails={connectionDetails}
          serviceName={historyState?.data?.serviceName as ServiceIntegrationType}
        />
      </Card>
    </InnerLayout>
  );
};
