import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Box } from 'shared';
import { Page, Table } from '../styled';
import { StatusTagColoring } from '../../../../../../../shared/TagColoring/StatusTagColoring';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide8.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const TextWrapper = styled.div`
  left: 93px;
  position: absolute;
  top: 120px;
  width: 800px;

  h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
`;

export const StatisticsEvents = styled(Box)`
  color: black;
  font-size: 14px;
  font-weight: 600;
  left: 50px;
  padding: 15px 20px;
  position: absolute;
  text-align: center;
  top: 280px;
  width: 374px;
`;

export const StatisticsAlerts = styled(StatisticsEvents)`
  top: 339px;
`;

export const StatisticsIncidents = styled(StatisticsEvents)`
  color: #fff;
  top: 398px;
`;

export const ClosedState = styled(StatisticsEvents)`
  left: 50px;
  padding: 16px 20px;
  top: 456px;
  width: 180px;
`;

export const ActiveState = styled(ClosedState)`
  background: transparent;
  left: 244px;
`;

export const TableWrapper = styled.div`
  background: white;
  position: absolute;
  right: 50px;
  top: 278px;
  width: 560px;

  h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const SecurityIncidenceTable = styled(Table)`
  font-size: 10px;
  margin-top: -7px;

  tr > td:not(:first-child),
  tr > th:not(:first-child) {
    text-align: left;
  }

  tr > th,
  tr > td {
    font-weight: 500;
  }

  tr > th {
    padding: 9px 5px;
  }

  tr > td {
    padding: 0 5px;
    vertical-align: middle;
  }
`;

export const PriorityCell = styled.th`
  width: 50px;
`;

export const TicketCell = styled.th`
  text-align: center !important;
  width: 50px;
`;

export const SummaryCell = styled.div`
  align-items: center;
  display: flex;
  height: 35px;
  max-width: 240px;

  > span {
    max-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StatusCell = styled(TicketCell)`
  text-align: left !important;
  width: 60px;
`;

export const CreatedAtCell = styled.th`
  text-align: right !important;
  width: 75px;
`;
export const UpdatedAtCell = styled(CreatedAtCell)``;

export const StyledStatusTagColoring = styled(StatusTagColoring)`
  color: #fff;
  display: block;
  font-size: 10px;
  line-height: 13px;
  min-width: 50px;
  padding: 0;
  text-align: center;
  width: 50px;
`;

export const IconCircle = styled.span`
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  margin-right: 3px;
  position: relative;
  top: -1px;
  width: 5px;
`;
