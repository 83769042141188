import { NO_VALUE_LABEL } from 'app-constants';
import { Card } from 'shared';
import { useApp } from 'store';
import { useTheme } from 'styled-components';
import { parseHtmlAsString, parseHtml } from 'services/helpers';
import { MainCardBlock, MainCardContent, MainCardContentHeader } from '../styled';

type OverviewProps = {
  diagnosis?: string;
  consequence?: string;
  solution?: string;
  result?: string;
  port?: string;
  isAsset?: boolean;
  isLoading?: boolean;
};

export const Overview = ({
  diagnosis,
  consequence,
  solution,
  isAsset,
  result,
  port,
  isLoading,
}: OverviewProps) => {
  const { isMobile } = useApp();
  const theme = useTheme();

  return (
    <Card
      title='Overview'
      headStyles={
        isMobile
          ? {
              pb: theme.sizes[3],
              pt: theme.sizes[3],
            }
          : { pb: theme.sizes[4], titlePaddingRight: theme.sizes['2.5'] }
      }
      bodyPadding={!isMobile ? theme.sizes['2.5'] : theme.sizes[0]}
      cardPadding={!isMobile ? theme.sizes['5.5'] : theme.sizes[5]}
      showLoader={isLoading}
    >
      <MainCardBlock key='diagnosis'>
        <MainCardContentHeader>Diagnosis</MainCardContentHeader>
        <MainCardContent>{parseHtml(diagnosis || NO_VALUE_LABEL)}</MainCardContent>
      </MainCardBlock>

      <MainCardBlock key='consequence'>
        <MainCardContentHeader>Consequence</MainCardContentHeader>
        <MainCardContent>{parseHtml(consequence || NO_VALUE_LABEL)}</MainCardContent>
      </MainCardBlock>

      <MainCardBlock key='solution'>
        <MainCardContentHeader>Solution</MainCardContentHeader>
        <MainCardContent>{parseHtml(solution || NO_VALUE_LABEL)}</MainCardContent>
      </MainCardBlock>

      {isAsset && (
        <MainCardBlock key='result'>
          <MainCardContentHeader>Result</MainCardContentHeader>
          <MainCardContent ws='pre-wrap'>
            {parseHtmlAsString(result || NO_VALUE_LABEL)}
          </MainCardContent>
        </MainCardBlock>
      )}

      {isAsset && (
        <MainCardBlock key='port'>
          <MainCardContentHeader>Port</MainCardContentHeader>
          <MainCardContent>{parseHtml(port || NO_VALUE_LABEL)}</MainCardContent>
        </MainCardBlock>
      )}
    </Card>
  );
};
