import React from 'react';
import { useApp, useCEPlus, useLoading } from 'store';
import { Card } from 'shared';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import {
  cePlusBackRoute,
  CustomPieChartTooltip,
  HIDDEN_FILTER_EMPTY_FIELD,
  IFilterField,
  tooltipTitles,
} from '../../constants';
import { SubTitle } from '../../../../shared/Card/components/SubTitle/SubTitle';
import { emptyMessage } from '../../../../shared/Table/EmptyTablePreview/constants';
import { SegmentedPie } from '../../../../shared/charts';
import { breakpoints } from '../../../../styles/theme';
import { VendorChart } from '../../styled';
import { isEmptyObject } from '../../../../services/helpers';
import { cyberEssentialPlusApi } from '../../../../store/cyberEssentialPlus/thunks';
import { subTitleFormatterSum } from '../../../PatchManagement/helpers';
import { EChartsEventParams } from '../../../../shared/charts/interfaces';
import { Routes } from '../../../../services/entities';
import { getQueryString } from '../../../../services/api/core/request';
import {
  getCustomDataHandler,
  getTotalAssetsParams,
  lastYear,
  validateField,
  validateFieldValue,
  viewMoreConfig,
} from '../../helpers';
import { TotalCustomTooltipContainer } from '../../../../shared/charts/styled';
import { Filter } from '../../../../services/api';

export const VendorPatchAvailable = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile, isMenuCollapsed } = useApp();
  const { vendorPatchAvailable, selectedTags } = useCEPlus();
  const loading = useLoading([cyberEssentialPlusApi.getVendorPatchAvailable.typePrefix]);

  const vendorPatchAvailableColorList = {
    vulnerabilities: theme.colors.green[100],
    fixedVulnerabilities: theme.colors.blue[50],
  };

  const redirectToCMDB = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      const tagAssetsField: IFilterField = {
        name: 'tagsAssets',
        value: selectedTags || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      };

      const vendorPatchAvailableFields: IFilterField[] = [
        {
          name: 'countVulnerabilitiesWithBaseScoreMoreThen7',
          value: 'true',
          type: Filter.type.VALUE,
        },
        {
          name: 'patchable',
          value: 'patch',
          type: Filter.type.VALUE,
        },
      ];

      const vendorPatchAvailableQueryParams = {
        fields: validateField([...vendorPatchAvailableFields, tagAssetsField]),
      };

      const vendorPatchAvailableIfTotalQueryParams = {
        fields: validateField([HIDDEN_FILTER_EMPTY_FIELD as IFilterField, tagAssetsField]),
      };

      if (params.name === 'vulnerabilities') {
        history.push(
          `${Routes.CMDB}${getQueryString(getTotalAssetsParams(vendorPatchAvailableQueryParams))}`,
          {
            ...cePlusBackRoute,
            ignoreDefaultBehavior: true,
          },
        );
      }

      if (params.name === 'fixedVulnerabilities') {
        history.push(
          `${Routes.CMDB}${getQueryString(
            getTotalAssetsParams(vendorPatchAvailableIfTotalQueryParams, {
              patchableFixedVulnsWithBaseScoreMoreThen7: true,
              fixedVulnerabilitiesFrom: lastYear,
            }),
          )}`,
          {
            ...cePlusBackRoute,
            ignoreDefaultBehavior: true,
          },
        );
      }
    },
    [history, selectedTags],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToCMDB(params),
    }),
    [redirectToCMDB],
  );

  const { fixedVulnerabilities = 0, vulnerabilities = 0 } = vendorPatchAvailable;
  const total = [fixedVulnerabilities + vulnerabilities || 0];

  return (
    <Card
      title='Vendor patch available'
      showDivider={false}
      subTitle={<SubTitle boldText={subTitleFormatterSum(total, 1)} />}
      height={theme.sizes.full}
      tooltipTitle={tooltipTitles.vendorPatchAvailable}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getVendorPatchAvailable.typePrefix]}
      viewMore={viewMoreConfig(selectedTags).vendorPatchAvailable}
    >
      {!isEmptyObject(vendorPatchAvailable) ? (
        <TotalCustomTooltipContainer>
          <VendorChart>
            <SegmentedPie
              values={vendorPatchAvailable || {}}
              colors={vendorPatchAvailableColorList}
              direction={breakpoints.xxl ? 'column' : 'row'}
              getCustomDataHandler={getCustomDataHandler}
              entitiesName=''
              style={
                isMobile
                  ? { height: '160px', marginTop: `-${theme.spacing[0]}` }
                  : { height: '258px' }
              }
              isMobile={isMobile}
              isMenuCollapsed={isMenuCollapsed}
              onEvents={onEvents}
              tooltip={CustomPieChartTooltip(theme)}
              isLoading={loading[cyberEssentialPlusApi.getVendorPatchAvailable.typePrefix]}
              isTruncatedText
              noLegend
            />
          </VendorChart>
        </TotalCustomTooltipContainer>
      ) : (
        emptyMessage(`No vendor patch yet`)
      )}
    </Card>
  );
};
