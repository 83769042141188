import styled from 'styled-components';
import { Card } from 'shared';
import { Theme } from 'styles/theme/types';
import { breakpoints } from 'styles/theme';

type Props = {
  theme: Theme;
  ws?: string;
};

export const CardStyled = styled(Card)<Props>`
  .custom-table-card-wrapper {
    border: 0;
    box-shadow: none;
    padding: ${({ theme }: Props) => `${theme.sizes[4]} ${theme.sizes[0]}`};
  }

  .mobile-table-card-wrapper {
    border: 0;
    box-shadow: none;
  }
`;

export const SuppressionWrap = styled.div`
  padding-top: ${({ theme }: Props) => `${theme.sizes[8]}`};

  @media (max-width: ${breakpoints.xlMax}) {
    padding-top: ${({ theme }: Props) => `${theme.sizes[6]}`};
  }
  @media (max-width: ${breakpoints.mdMax}) {
    padding-top: ${({ theme }: Props) => `${theme.sizes[4]}`};
  }
`;
