import React, { useCallback } from 'react';
import { Tooltip } from 'antd';
import { Box, Icon, IViewMore } from 'shared';
import { Link, ViewMoreLink } from 'shared/layouts/Outer/components';
import { Annotations } from 'shared/charts/GradientPie/types';
import { GradientPie } from 'shared/charts';
import { DEFAULT_FONT_SIZE, DEFAULT_FONT_SIZE_LARGE } from 'shared/charts/constants';
import { useTheme } from 'styled-components';
import { CardTitle, GradientPieContainer } from './styled';
import { AccessMessage } from './AcessMessage/AccessMessage';
import { getCssVar } from '../../../styles/theme';

const Z_INDEX = 500;

type Props = {
  title: string | React.ReactNode;
  subTitle?: React.ReactNode;
  tooltipTitle?: string;
  direction?: 'row' | 'column';
  valueMini?: number | null;
  showDivider?: boolean;
  headAction?: React.ReactNode | JSX.Element;
  isHiddable?: boolean;
  isValueMiniLoading?: boolean;
  getAccessMessage?: {
    text: string;
    href: string;
    layout: 'inline' | 'column';
  };
  setIsOpen: (value: boolean) => void;
  isOpen?: boolean;
  underTitle?: JSX.Element;
  headStyles?: {
    pb?: string;
    pt?: string;
    titlePaddingRight?: string;
    titlePaddingLeft?: string;
    maxWidthTitle?: string;
    actionsDisplay?: string;
    actionsWidth?: string;
    actionsGap?: string;
    actionsJustify?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
    mt?: string;
    headAlign?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
    justify?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
    direction?: 'row' | 'column';
    zIndex?: number;
  };
  viewMore?: IViewMore;
  isMobile: boolean;
  valuePlacement?: 'right' | 'left' | 'inside';
  titlePlacement?: 'left' | 'inside';
  scoreAnnotations?: Annotations;
};

export const Head = ({
  title,
  subTitle,
  tooltipTitle,
  direction,
  valueMini,
  showDivider,
  headAction,
  isHiddable,
  setIsOpen,
  isOpen,
  headStyles,
  isMobile,
  underTitle,
  valuePlacement,
  titlePlacement,
  getAccessMessage,
  viewMore,
  isValueMiniLoading,
  scoreAnnotations,
}: Props) => {
  const { sizes, spacing, icons, fontWeights } = useTheme();
  const border = `${sizes['0.5']} solid ${getCssVar('card.footerBorderTopColor')}`;
  const isDirectionRow = direction === 'row';

  let valueMiniTop = '10%';

  if (isHiddable !== undefined && isMobile) {
    valueMiniTop = '-25.5%';
  } else if (underTitle) {
    valueMiniTop = '20%';
  } else if (isDirectionRow) {
    valueMiniTop = '-70%';
  }

  const iconClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <Box
      d='flex'
      justify={headStyles?.justify || (isDirectionRow ? 'space-between' : 'center')}
      align={headStyles?.headAlign || 'center'}
      px={headStyles?.titlePaddingRight || (!isOpen ? spacing[2] : '0')}
      pr={!isDirectionRow ? spacing[6] : ''}
      flexDirection={headStyles?.direction || direction}
      borderBottom={isDirectionRow && isOpen && showDivider ? border : ''}
      borderRight={!isDirectionRow && showDivider ? border : ''}
      marginTop={headStyles?.mt}
      zIndex={headStyles?.zIndex || Z_INDEX}
    >
      <Box
        d={headStyles?.actionsDisplay || 'inline-flex'}
        width={headStyles?.actionsWidth || 'auto'}
        flexWrap='wrap'
        align='center'
        justify={headStyles?.actionsJustify || 'flex-start'}
        fontWeight={fontWeights.bold}
        fontSize={sizes[5]}
        paddingBottom={headStyles?.pb}
        paddingTop={!isOpen ? headStyles?.pt : '0'}
        maxW={headStyles?.maxWidthTitle || '100%'}
        gap={headStyles?.actionsGap || '0'}
      >
        {isHiddable && (
          <Icon
            component={!isOpen ? icons.arrow_down_open : icons.arrow_up_close}
            color={getCssVar('textColor')}
            style={{ marginRight: spacing[1], color: getCssVar('textColor') }}
            onClick={iconClick}
            margin='0 4px 0 0'
          />
        )}

        <Box d='flex' flexDirection='column' mt={underTitle ? spacing[5] : '0'}>
          <Box
            d='flex'
            alignItems='center'
            whiteSpace={!isMobile ? 'nowrap' : 'normal'}
            maxW={headStyles?.maxWidthTitle}
            pl={headStyles?.titlePaddingLeft}
          >
            <CardTitle d='flex' flexDirection='column'>
              {title}
              {viewMore && (
                <ViewMoreLink to={viewMore.route}>
                  {viewMore.text || 'View more'}
                  <Icon component={icons.arrow_right_alt} />
                </ViewMoreLink>
              )}
            </CardTitle>

            {tooltipTitle && (
              <Tooltip
                title={tooltipTitle}
                placement={isMobile ? 'top' : 'right'}
                trigger={isMobile ? 'click' : 'hover'}
              >
                <Icon component={icons.info_outline} dimension={sizes[6]} className='tooltipInfo' />
              </Tooltip>
            )}
            {getAccessMessage && getAccessMessage.layout === 'inline' && !isMobile && (
              <Box
                padding={`${spacing['1.5']} ${spacing[3]}`}
                ml={spacing[4]}
                backgroundColor={getCssVar('card.headAccessMessageBG')}
                fontSize={sizes[4]}
                fontWeight={fontWeights.bold}
                color={getCssVar('textColor')}
              >
                <Link to={getAccessMessage.href}>{getAccessMessage.text}</Link> to get access
              </Box>
            )}
          </Box>

          {getAccessMessage && getAccessMessage.layout === 'column' && !isMobile && (
            <AccessMessage getAccessMessage={getAccessMessage} />
          )}

          {underTitle && (
            <Box
              d='inline'
              fontSize={sizes['3.5']}
              fontWeight={fontWeights.light}
              mb={spacing[5]}
              mt={spacing[1]}
              color={getCssVar('card.headUnderTitleColor')}
            >
              {underTitle}
            </Box>
          )}
        </Box>

        {headAction && <>{headAction}</>}
      </Box>
      {(subTitle ||
        (valueMini !== undefined && valuePlacement !== 'left' && titlePlacement !== 'inside')) && (
        <Box
          position='relative'
          fontWeight={fontWeights.black}
          minH={sizes[14]}
          minW={sizes[14]}
          fontSize={sizes[9]}
          textAlign='center'
          alignSelf='flex-start'
        >
          {subTitle}
          {valueMini !== undefined && (
            <GradientPieContainer
              className='gradient-pie-container'
              isOpen={isOpen}
              isMobile={isMobile}
              isDirectionRow={isDirectionRow}
              customValueTop={valueMiniTop}
              customValueRight={spacing[0]}
            >
              <GradientPie
                value={valueMini}
                height={!isDirectionRow ? '156px' : '76px'}
                fontSize={isDirectionRow ? DEFAULT_FONT_SIZE : DEFAULT_FONT_SIZE_LARGE}
                isLoading={isValueMiniLoading}
                scoreAnnotations={scoreAnnotations}
              />
              {valueMini === null && !isValueMiniLoading && (
                <Icon component={isDirectionRow ? icons.medium_lock : icons.big_lock} />
              )}
            </GradientPieContainer>
          )}
        </Box>
      )}
      {valuePlacement === 'left' && isMobile && <Box minH={sizes[14]} minW={sizes[14]} />}
    </Box>
  );
};
