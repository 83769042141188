import React from 'react';
import { Box } from 'shared';
import { useTheme } from 'styled-components';
import { DotIcon, PercentValue, TotalValue, TotalWrapper } from './styled';
import { VulnerabilityDaysGap } from '../../../constants';

type SubTitleProps = {
  total?: React.ReactNode;
  percent?: string;
  days: string;
};

export const VulnerabilitiesByDay = ({ total, percent, days }: SubTitleProps) => {
  const theme = useTheme();
  const dotColor = (days: string) => {
    switch (days) {
      case VulnerabilityDaysGap.LAST_WEEK:
        return theme.colors.green[300];
      case VulnerabilityDaysGap.PENULTIMATE_WEEK:
        return theme.colors.yellow[500];
      case VulnerabilityDaysGap.MORE_THEN_TWO_WEEKS:
        return theme.colors.red[400];
      default:
        return theme.colors.yellow[100];
    }
  };

  return (
    <TotalWrapper>
      <DotIcon
        component={theme.icons.fiber_manual_record}
        color={dotColor(days)}
        dimension='12px'
      />

      <TotalValue>
        <Box display='flex' flexDirection='row'>
          {total}
          <PercentValue>({percent})</PercentValue>
        </Box>
        <span>{days}</span>
      </TotalValue>
    </TotalWrapper>
  );
};
