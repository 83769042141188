import React from 'react';
import { OuterLayout } from 'shared';
import { useAuth, auth, useApp } from 'store';
import { Verify } from 'pages/Verify/Verify';
import { Routes, TwoFASteps } from 'services/entities';
import { Redirect } from 'react-router';
import { batch, useDispatch } from 'react-redux';
import { getCommonError } from 'services/helpers';
import { getLayoutConfig } from './helpers';
import { Form, TwoFASetup } from './components';

export const Login = () => {
  const dispatch = useDispatch();
  const { isMobile, lastVisitedPage, themeName } = useApp();
  const { twofa, requestError, isAuthorized } = useAuth();
  const layoutConfig = getLayoutConfig(
    twofa.step,
    requestError.message === 'Rejected' ? getCommonError(themeName) : requestError.message,
    isMobile,
  );

  const getContent = (step: number) => {
    switch (step) {
      case TwoFASteps.INITIAL:
        return <Form />;
      case TwoFASteps.SETUP:
        return <TwoFASetup />;
      case TwoFASteps.CONFIRM:
        return <Verify />;
      default:
        return null;
    }
  };

  React.useEffect(() => {
    dispatch(auth.actions.setStepToInitial());
    batch(() => {
      dispatch(auth.actions.setError(''));
      dispatch(auth.actions.setIsPending(false));
    });
  }, [dispatch]);

  return isAuthorized ? (
    <Redirect to={lastVisitedPage || Routes.DASHBOARD} />
  ) : (
    <OuterLayout {...layoutConfig}>{getContent(twofa.step)}</OuterLayout>
  );
};
