import styled, { css } from 'styled-components';
import { Box, BoxProps } from 'shared';
import { Table } from 'antd';
import { Theme } from 'styles/theme/types';
import { ThemeProps } from 'services/interfaces';
import { ComponentType } from 'react';
import { getCssVar } from '../../../styles/theme';

type LegendItemValueProps = {
  unactive?: boolean;
};

export const LegendItemValue = styled<ComponentType<LegendItemValueProps & BoxProps>>(Box)`
  ${({ unactive, theme }: LegendItemValueProps & ThemeProps) =>
    unactive &&
    css`
      color: ${theme.colors.green[200]};
    `}
`;
export const LegendWrapperValue = styled(Box)``;
export const LegendItemTitle = styled(Box)``;
export const ItemValue = styled(Box)``;
export const ItemPercent = styled(Box)``;

export const LegendTable = styled(Table)`
  .ant-table-thead {
    display: none;
  }

  .ant-table-tbody {
    > tr > td {
      background-color: ${getCssVar('card.bg')};
      border-bottom: none;
      color: ${getCssVar('textColor')};
    }

    .ant-table-row {
      cursor: pointer;

      .ant-table-cell {
        font-size: ${({ theme }: { theme: Theme }) => theme.sizes['3.5']};
        padding: ${({ theme }: { theme: Theme }) => `${theme.sizes['1.5']} ${theme.sizes[2]}`};
      }

      .ant-table-cell:nth-child(2n),
      .ant-table-cell:last-child {
        font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
      }
    }
  }
`;
