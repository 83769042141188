import moment from 'moment';
import { TrainingProgress } from 'pages/PeoplePhishing/constants';
import { InnerLayoutProps as InnerLayout } from 'shared';
import { AverageConfidenceLabels, TrainingStatusKeys } from './constants';

export const getLayoutConfig = (): InnerLayout => ({
  title: 'Cybersafety and Phishing Awareness',
});

export const getTrainingProgressStatusFilterValue = (key: string) => {
  let filterValue: string | string[] = '';

  switch (key) {
    case TrainingStatusKeys.Completed:
    case AverageConfidenceLabels.CompletedCourse:
      filterValue = TrainingProgress.Completed;
      break;
    case TrainingStatusKeys.NotStarted:
      filterValue = TrainingProgress.NotStarted;
      break;
    case TrainingStatusKeys.Started:
      filterValue = TrainingProgress.InProgress;
      break;
    case AverageConfidenceLabels.StartedCourse:
      filterValue = [TrainingProgress.InProgress, TrainingProgress.Completed];
      break;
    default:
      filterValue = TrainingProgress.NotStarted;
  }

  return filterValue;
};

export const getCertifiedDateFilterValue = (date: string) => {
  const startDate = new Date(date).getTime();
  const endDate = moment(startDate).endOf('month').valueOf();

  return [`${startDate}`, `${endDate}`];
};
