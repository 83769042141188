import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistoryState } from '../../services/interfaces';

export interface IBackLinkProps {
  historyState?: HistoryState;
  defaultRoute?: string;
  defaultTitle?: string;
  mainTitle?: string;
  preferRoot?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

interface RoutesState {
  routesList: IBackLinkProps[];
}

export const routesInitialState: RoutesState = {
  routesList: [],
};

export const routes = createSlice({
  name: 'routes',
  initialState: routesInitialState,
  reducers: {
    addRoute: (state: RoutesState, action: PayloadAction<IBackLinkProps>) => {
      const { routesList } = state;

      if (routesList.length > 0) {
        const lastRoute = routesList[routesList.length - 1];

        // skip duplicated routes
        if (lastRoute?.defaultRoute === action.payload.defaultRoute) {
          return state;
        }
      }

      return {
        ...state,
        routesList: [...routesList, action.payload],
      };
    },
    removeLast: (state: RoutesState) => {
      return {
        ...state,
        routesList: state.routesList.slice(0, -1),
      };
    },
    resetRoutes: (state: RoutesState) => ({
      ...state,
      routesList: [],
    }),
  },
});
