import { Radio } from 'antd';
import styled from 'styled-components';
import { Theme } from '../../styles/theme/types';
import { getCssVar } from '../../styles/theme';

export const RadioGroup = styled(Radio.Group)`
  margin-top: 3px;

  .ant-radio-button-wrapper {
    background-color: ${getCssVar('datePicker.bg')};
    border-color: ${getCssVar('datePicker.borderColor')};
    color: ${getCssVar('textColor')};

    &:hover {
      color: ${getCssVar('primaryColor')};
    }
  }

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 16px;

    .ant-radio-button-wrapper {
      flex: 0 0 auto;
    }
  }
`;

export const RadioButton = styled(Radio.Button)`
  height: 37px;
  line-height: 33px;

  &:not(:first-child)::before {
    background-color: transparent;
  }

  &:first-child {
    border-radius: 0 0 0 ${({ theme }: { theme: Theme }) => theme.radius.xl};
  }

  &:last-child {
    border-radius: 0 ${({ theme }: { theme: Theme }) => theme.radius.xl} 0 0;
  }

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.colorSet.radio.main};
  }

  &.ant-radio-button-wrapper-checked {
    background: ${getCssVar('datePicker.checked.bg')};
    border-color: ${getCssVar('datePicker.checked.borderColor')} !important;
    color: ${getCssVar('radio.color')};
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.medium};

    &:hover {
      font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.medium};
      border-color: ${getCssVar('radio.main')};
      color: ${getCssVar('radio.color')};
    }

    &:before {
      display: none;
    }
  }
`;
