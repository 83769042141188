import React from 'react';
import { Col, Row } from 'antd';
import { useRowGap } from 'services/hooks/useRowGap';
import { PatchStatus } from 'services/api';
import { useDispatch } from 'react-redux';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { useCustomer, useVendorSeverity } from 'store';
import { useParams } from 'react-router';
import { PmSeverityFilter } from 'shared/PmSeverityFilter';
import { useTheme } from 'styled-components';
import { PatchesBySeverityChart } from './components/PatchesBySeverityChart';
import { RECENT_PATCHES_LIMIT } from './constants';
import { RecentPatches } from './components/RecentPatches';

export const AssetDetailsPatchManagement = () => {
  const { gapNumber } = useRowGap();
  const theme = useTheme();

  const dispatch = useDispatch();
  const customerId = useCustomer();
  const { id } = useParams<{ id: string }>();
  const severities = useVendorSeverity();

  React.useEffect(() => {
    dispatch(
      assetDetailsApi.getAssetPatchesBySeverity({
        customerId,
        assetId: id,
        patchStatus: PatchStatus.MISSING,
        vendorSeverity: severities,
      }),
    );
    dispatch(
      assetDetailsApi.getAssetPatchesBySeverity({
        customerId,
        assetId: id,
        patchStatus: PatchStatus.INSTALLED,
        vendorSeverity: severities,
      }),
    );
    dispatch(
      assetDetailsApi.getAssetRecentPatches({
        customerId,
        assetId: id,
        patchStatus: PatchStatus.MISSING,
        vendorSeverity: severities,
        limit: RECENT_PATCHES_LIMIT,
      }),
    );
    dispatch(
      assetDetailsApi.getAssetRecentPatches({
        customerId,
        assetId: id,
        patchStatus: PatchStatus.INSTALLED,
        vendorSeverity: severities,
        limit: RECENT_PATCHES_LIMIT,
      }),
    );
  }, [dispatch, customerId, id, severities]);

  return (
    <>
      <PmSeverityFilter />
      <Row gutter={[gapNumber, gapNumber]} style={{ marginTop: theme.spacing[6] }}>
        <Col span={gapNumber} xs={24} xxl={12}>
          <PatchesBySeverityChart patchStatus={PatchStatus.MISSING} />
        </Col>
        <Col span={gapNumber} xs={24} xxl={12}>
          <PatchesBySeverityChart patchStatus={PatchStatus.INSTALLED} />
        </Col>
        <Col span={gapNumber} xs={24} xxl={12}>
          <RecentPatches patchStatus={PatchStatus.MISSING} />
        </Col>
        <Col span={gapNumber} xs={24} xxl={12}>
          <RecentPatches patchStatus={PatchStatus.INSTALLED} />
        </Col>
      </Row>
    </>
  );
};
