import { Input as AntdInput } from 'antd';
import styled, { css } from 'styled-components';
import { encryptedPasswordFireFoxFontFamily } from 'styles/theme/Norm/fontsNorm';
import { CommonProps, Password, StrengthBarCellProps, TextInputProps } from '../types';
import { FormItem } from './FormItem';
import { getCssVar } from '../../../../styles/theme';

export const StrengthBarCell = styled.div<StrengthBarCellProps>`
  background-color: ${(props) => props.bgColor};
  border-radius: ${({ theme }: StrengthBarCellProps) => theme.radius['2sm']};
  height: ${({ theme }: StrengthBarCellProps) => theme.sizes[2]};
`;

export const StrengthBar = styled.div`
  column-gap: ${({ theme }: CommonProps) => theme.spacing[2]};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: ${({ theme }: CommonProps) => theme.spacing[1]};
`;

const input = css`
  .ant-input-group {
    .ant-input-group-addon {
      background-color: ${getCssVar('input.bg')};
      border-color: ${getCssVar('input.borderColor')};
      border-right: none;
      color: ${getCssVar('input.color')};
      font-size: ${({ theme }: TextInputProps) => theme.sizes[4]};
      padding: 0 5px;
    }

    .ant-input {
      &:focus,
      &:hover {
        box-shadow: none;
      }

      &:focus,
      &-focused {
        border-color: ${getCssVar('input.focusBorderColor')};
      }
    }
  }

  &,
  .ant-input-affix-wrapper {
    border-color: ${getCssVar('input.borderColor')};
    box-shadow: none;
    font-size: ${({ theme }: TextInputProps) => theme.sizes[4]};
    padding-bottom: 6.5px;
    padding-top: 6.5px;

    &:focus,
    &:hover {
      box-shadow: none;
    }

    &:focus,
    &-focused {
      border-color: ${getCssVar('input.focusBorderColor')};
    }
  }
`;

export const Input = styled(AntdInput)`
  ${input}
  ${(props) =>
    props.type === 'search' &&
    css`
      .ant-input {
        border-color: ${getCssVar('input.search.borderColor')};

        &:hover,
        &:focus {
          border-color: ${getCssVar('input.search.borderColor')};
        }
      }

      .ant-form-item-explain.ant-form-item-explain-error {
        color: ${({ theme }: TextInputProps) => theme.colorSet.input.search.color};
      }

      .ant-input-suffix .anticon {
        font-size: 16px;
        right: 18px;
      }
    `};
`;

export const InputPassword = styled(AntdInput.Password)<Password>`
  ${input};

  input.ant-input {
    background-color: ${getCssVar('input.passwordBg')};
  }

  input[type='password'] {
    font-family: ${({ $value, $isFirefox, $isIOS }) =>
      ($isFirefox || $isIOS) && $value && encryptedPasswordFireFoxFontFamily};
  }
`;

export const InputTextarea = styled(AntdInput.TextArea)`
  ${input};
`;

export const FormItemStyled = styled(FormItem)`
  margin-bottom: ${({ theme }: TextInputProps) => theme.spacing[5]};
  width: ${({ width }) => width};

  @media (max-width: 992px) {
    width: 100%;
  }

  .ant-form-item-control-input-content {
    .ant-input {
      height: ${({ height }) => height};
    }
  }

  .ant-form-item-label,
  .ant-form-item-required {
    font-weight: ${getCssVar('filterLabelWeight')} !important;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: relative;
    top: -2px;
    margin-right: 0;
    font-weight: ${({ theme }: TextInputProps) => theme.fontWeights.normal};
    color: ${getCssVar('formItemLabelColor')};
  }

  label {
    font-weight: ${({ theme }: TextInputProps) => theme.fontWeights.medium};
    color: ${({ labelColor }: TextInputProps & { labelColor: string }) => labelColor};
  }

  .ant-input-prefix {
    color: ${getCssVar('input.color')};
  }

  ${(props) =>
    props.validateStatus === 'error' &&
    css`
      .ant-input {
        border-color: ${getCssVar('input.errorBorder')} !important;

        &:hover,
        &:focus {
          border-color: ${getCssVar('input.errorMessage')};
        }
      }

      .ant-form-item-explain.ant-form-item-explain-error {
        color: ${getCssVar('input.errorMessage')} !important;
      }
    `};
  ${({ validateStatus }: TextInputProps) =>
    validateStatus === 'success' &&
    css`
      .ant-form-item-explain.ant-form-item-explain-success {
        display: none;
      }
    `};

  ${({ isFeedbackCentered }: TextInputProps) => {
    if (isFeedbackCentered) {
      return ".ant-form-item-explain.ant-form-item-explain-error [role='alert'] { text-align: center; }";
    }

    return '';
  }}
`;
