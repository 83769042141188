import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const encodeHtmlEntities = (str: string): string => {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;');
};

export const encodeHtmlTags = (data: string) => {
  const containsHtmlTags = (data: string) => /<[^>]*>/g.test(data);

  if (containsHtmlTags(data)) {
    return encodeHtmlEntities(data);
  }

  return data;
};

export const parseHtmlAsString = (str: string) => parse(encodeHtmlTags(str));

export const parseHtml = (str: string) => {
  const sanitizedContent = DOMPurify.sanitize(str);

  return parse(sanitizedContent);
};
