import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ThemeProps } from '../../services/interfaces';
import { getCssVar } from '../../styles/theme';

export const StyledLink = styled(Link)<ThemeProps>`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${getCssVar('primaryColor')};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }) => theme.spacing[4]};
  font-weight: ${getCssVar('backFontWeight')};

  :hover {
    text-decoration: underline;
  }
`;
