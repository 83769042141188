import { Filter, OrderBy, VulnerabilityStatus } from 'services/api';
import { severityOptions } from 'pages/VulnerabilitiesList/constants';
import { InputType } from '../../../../services/api/models/InputType';

export enum TableTitles {
  Subject = 'Vulnerability title',
  Category = 'Category',
  Severity = 'Severity',
  FirstFound = 'First found',
  LastFound = 'Last found',
}

export const searchSettings = {
  placeholder: 'Search by Vulnerability title',
  fields: ['subject'],
  min: 3,
};

export const filterFields = [
  {
    field: 'severity',
    options: severityOptions,
    label: TableTitles.Severity,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];

export const initialOrderBy = {
  field: 'severity',
  type: OrderBy.type.DESC,
};

export const defaultVulnStatusValue = VulnerabilityStatus.CONFIRMED;

export const confirmedVulnerabilitiesFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [defaultVulnStatusValue],
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const potentialVulnerabilitiesFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.CONFIRMED, VulnerabilityStatus.POTENTIAL],
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const emptyVulnStatus = '';
