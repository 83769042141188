import { inRange } from 'lodash';
import { isMacOs, isSafari } from 'react-device-detect';
import { Theme } from 'styles/theme/types';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_TITLE_FONT_SIZE,
  VALUE_FULL,
  ValueLevels,
} from '../constants';
import { Annotations } from './types';

const calcLevels = (value: number | null) => ({
  empty: value === null,
  low: inRange(value || 0, ValueLevels.LOW),
  medium: inRange(value || 0, ValueLevels.LOW, ValueLevels.MEDIUM),
  high: inRange(value || 0, ValueLevels.MEDIUM, ValueLevels.HIGH),
  very_high: inRange(value || 0, ValueLevels.HIGH, ValueLevels.VERY_HIGH),
});

export const getLevel = (value: number | null) => {
  const levels: Record<string, boolean> = calcLevels(value);

  return Object.keys(levels).find((level) => levels[level]) || 'low';
};

const getLevelColors = (value: number | null, theme: Theme): string[] => {
  const levels: Record<string, boolean> = calcLevels(value);

  type LevelKey = keyof typeof levels;
  const colors: Record<LevelKey, string[]> = theme.colorSet.scoreGradient;
  const levelColor = Object.keys(colors).find((level) => levels[level]);

  return colors[levelColor || 'low'];
};

const getGradientCoords = (value: number | null) => {
  if (inRange(value || 0, ValueLevels.LOW, ValueLevels.MEDIUM)) {
    return {
      x: 1,
      y: 1,
      x2: 0,
      y2: 0,
    };
  }

  if (inRange(value || 0, ValueLevels.MEDIUM, ValueLevels.HIGH)) {
    return {
      x: 1,
      y: 1,
      x2: 1,
      y2: 0,
    };
  }

  return {
    x: 0,
    y: 1,
    x2: 1,
    y2: 1,
  };
};

export const getOptions = (
  value: number | null,
  fontSize: number,
  theme: Theme,
  hasClickHandler?: boolean,
  scoreAnnotations?: Annotations,
  titleFontSize?: number,
  fontFamily?: string,
) => {
  const tooltip = scoreAnnotations ? scoreAnnotations[getLevel(value)]?.description : null;
  const currentColors = getLevelColors(value, theme);
  const gradientCoords = getGradientCoords(value);
  const dataSector = {
    name: 'value',
    value,
    emphasis: { scale: false },
    itemStyle: {
      color: {
        type: 'linear',
        colorStops: currentColors.map((color, offset) => ({ offset, color })),
        ...gradientCoords,
      },
    },
  };
  const restSector = {
    name: 'rest',
    value: VALUE_FULL - (value || 0),
    label: { show: false },
    emphasis: {
      scale: false,
      color: theme.colors.white,
    },
    itemStyle: {
      color: currentColors[0],
      opacity: '0.1',
    },
  };
  const option = {
    legend: { show: false },
    tooltip: {
      show: Boolean(tooltip),
      formatter: tooltip,
      position: 'right',
      extraCssText: 'max-width: 400px; min-width: 320px; white-space: normal;',
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
    },
    series: [
      {
        type: 'pie',
        radius: ['73%', '94%'],
        label:
          value !== null
            ? {
                show: true,
                position: 'center',
                formatter: scoreAnnotations
                  ? `{value|${value}}\n{title|${scoreAnnotations[getLevel(value)].title}}`
                  : `{value|${value}}`,
                rich: {
                  value: {
                    fontSize: fontSize || DEFAULT_FONT_SIZE,
                    color: theme.colorSet.textColor,
                    fontWeight: '900',
                    lineHeight: (fontSize || DEFAULT_FONT_SIZE) * DEFAULT_LINE_HEIGHT,
                    fontFamily: fontFamily || theme.colorSet.fontFamily,
                  },
                  title: {
                    fontSize:
                      (titleFontSize && titleFontSize) ||
                      (isMacOs && isSafari ? theme.sizes['1.5'] : theme.sizes['3.5']),
                    lineHeight: titleFontSize || DEFAULT_TITLE_FONT_SIZE,
                    fontWeight: '300',
                    fontFamily: fontFamily || theme.colorSet.fontFamily,
                    color: theme.colorSet.textColor,
                  },
                },
              }
            : undefined,
        data: [dataSector, restSector],
        cursor: hasClickHandler || tooltip ? 'pointer' : 'auto',
        silent: !hasClickHandler && !tooltip,
      },
    ],
  };

  return option;
};
