import React, { useCallback } from 'react';
import moment from 'moment';
import { ServiceRequestType } from 'pages/SupportRequestForm/constants';
import { backlinkRoutes, dateFormat } from 'app-constants';
import { Routes } from 'services/entities';
import { useHistory } from 'react-router';
import { getIconName, getStatusColor } from 'pages/Process/helpers';
import { Footer } from 'pages/Process/components/Certificate/Footer';
import { ABOUT_TO_EXPIRE_MONTHS } from 'pages/Process/constants';
import { CertificateStatusSchemaWithDownloadName } from 'services/api';
import { Button, Icon } from 'shared';
import { icons } from 'styles/theme/icons';
import { useTheme } from 'styled-components';
import { ProcessItemContainer } from '../styled/styled';

interface IProcessItemProps extends CertificateStatusSchemaWithDownloadName {
  isLocked?: boolean;
  onSelfSertify: (withForm?: boolean | undefined, title?: string | undefined) => void;
  openFormWithPreparedData: (title: string, date?: number, vendorName?: string) => void;
  hasPermissionToBuyCertificate: boolean;
}

export const ProcessItem = ({
  status,
  title,
  expirationDate,
  id,
  vendor,
  isLocked,
  onSelfSertify,
  openFormWithPreparedData,
  hasPermissionToBuyCertificate,
}: IProcessItemProps) => {
  const history = useHistory();
  const theme = useTheme();

  const expiration = expirationDate ? moment(expirationDate) : null;
  const isCertificateSoonExpired =
    expiration && expiration.diff(moment(), 'month') < ABOUT_TO_EXPIRE_MONTHS;

  const redirectToSupportRequest = useCallback(() => {
    history.push(
      `${Routes.SERVICE_REQUEST}?type=${ServiceRequestType.Purchase}&certificate=${title}`,
      {
        backTo: {
          route: Routes.DASHBOARD,
          title: `Back to ${backlinkRoutes[Routes.DASHBOARD]}`,
        },
      },
    );
  }, [history, title]);

  return (
    <ProcessItemContainer
      color={getStatusColor(expiration, isCertificateSoonExpired, status, theme) || undefined}
      isIcon={!status}
      isLocked={isLocked || !status}
      dateColor={expiration?.isBefore(moment()) ? theme.colorSet.colorCritical : 'inherit'}
    >
      <div>
        <span className='icon'>
          {!status && <Icon component={icons.small_lock} className='lockicon' />}
          <Icon
            component={icons[getIconName(title, status)]}
            onClick={() => history.push(Routes.PROCESS)}
          />
        </span>
        <span className='title'>{title}</span>
        {expirationDate && <span className='date'>Exp. {expiration?.format(dateFormat)}</span>}
        {!isLocked ? (
          <Footer
            status={status}
            title={title}
            expirationDate={expirationDate}
            onSelfSertify={onSelfSertify}
            openFormWithPreparedData={openFormWithPreparedData}
            onReorder={redirectToSupportRequest}
            vendorName={vendor}
            id={id}
            hasPermissionToBuyCertificate={hasPermissionToBuyCertificate}
            isDashboard
          />
        ) : (
          <Button type='primary' onClick={redirectToSupportRequest}>
            Order
          </Button>
        )}
      </div>
    </ProcessItemContainer>
  );
};
