import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CmdbDataResponse,
  HostSchema,
  HostsCountBySeverityResponse,
  IsolatedDevicesTrendResponse,
} from '../../services/api';
import { cmdbApi } from './thunks';

export interface ITagsAssetsProps {
  id?: string;
  name?: string;
}

export interface ITagAssetsManageByIdProps {
  customerId?: string;
  id?: string;
  tagsAssets?: ITagsAssetsProps[];
}

interface InitialState {
  data: CmdbDataResponse;
  isolatedDevices?: IsolatedDevicesTrendResponse;
  hostsCountBySeverity?: HostsCountBySeverityResponse;
  showPotentialVulnerabilities: boolean;
}

const initialState: InitialState = {
  data: {},
  isolatedDevices: undefined,
  hostsCountBySeverity: undefined,
  showPotentialVulnerabilities: false,
};

const updateHostTags = (state: InitialState, action: PayloadAction<ITagAssetsManageByIdProps>) => {
  const mutatedRowIndex = state.data.rows?.findIndex((row) => row.id === action.payload.id);

  if (mutatedRowIndex === undefined || state.data.rows === undefined) {
    return;
  }

  const mutatedRow: HostSchema = {
    ...state.data.rows?.[mutatedRowIndex],
    tagsAssets: action.payload.tagsAssets,
  };

  state.data.rows = [
    ...state.data.rows?.slice(0, mutatedRowIndex),
    mutatedRow,
    ...state.data.rows?.slice(mutatedRowIndex + 1),
  ];
};

export const extraReducers = {
  [cmdbApi.fetchData.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<CmdbDataResponse>,
  ) => {
    state.data = action.payload;
  },
  [cmdbApi.fetchData.rejected.toString()]: (state: InitialState) => {
    state.data = {};
  },
  [cmdbApi.getIsolatedDevices.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<IsolatedDevicesTrendResponse>,
  ) => {
    state.isolatedDevices = action.payload;
  },
  [cmdbApi.getIsolatedDevices.rejected.toString()]: (state: InitialState) => {
    state.isolatedDevices = undefined;
  },
  [cmdbApi.getHostsTrendsByCriticalities.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<HostsCountBySeverityResponse>,
  ) => {
    state.hostsCountBySeverity = action.payload;
  },
  [cmdbApi.getHostsTrendsByCriticalities.rejected.toString()]: (state: InitialState) => {
    state.hostsCountBySeverity = undefined;
  },
  [cmdbApi.setTag.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<ITagAssetsManageByIdProps>,
  ) => updateHostTags(state, action),
  [cmdbApi.setTag.rejected.toString()]: (state: InitialState) => {
    return state;
  },
  [cmdbApi.removeTag.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<ITagAssetsManageByIdProps>,
  ) => updateHostTags(state, action),
  [cmdbApi.removeTag.rejected.toString()]: (state: InitialState) => {
    return state;
  },
};

export const cmdb = createSlice({
  name: 'cmdb',
  initialState,
  reducers: {
    setShowPotentialVulnerabilities: (state: InitialState, action: { payload: boolean }) => ({
      ...state,
      showPotentialVulnerabilities: action.payload,
    }),
  },
  extraReducers,
});
