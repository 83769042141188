import { Drawer, Menu as AntMenu, Row } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import styled from 'styled-components';
import { breakpoints, getCssVar } from 'styles/theme';
import AntdLayout, { Content as AntContent } from 'antd/lib/layout/layout';
import { Theme } from 'styles/theme/types';
import { Icon } from 'shared/Icon';
import { Button } from 'shared/buttons/styled';
import { Link } from 'react-router-dom';
import { ThemeProps } from 'services/interfaces';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Box } from '../../../Box';

type Props = {
  theme: Theme;
};
type PropsTheme = {
  $currentTheme: Theme;
};
type PropsMenu = PropsTheme & {
  $collapsed?: boolean;
  openKeys?: [] | string[];
};

export const Menu = styled(AntMenu)<PropsMenu>`
  background-color: inherit;
  border-right: 0;
  min-width: 90px;

  .ant-menu-item::after {
    border-right: 0;
  }

  .ant-menu-item {
    padding: ${({ $collapsed }) => $collapsed && '0 16px 0 33px'};

    &:hover {
      background-color: ${getCssVar('menu.submenu.hoverBg')};
    }

    a:hover {
      color: ${getCssVar('menu.item.selected.color')};
    }
  }

  .ant-menu-submenu-selected {
    color: ${getCssVar('menu.item.color')};
  }

  .ant-menu-submenu-arrow {
    margin-right: 10px;
    margin-top: 1px;
    color: ${getCssVar('leftMenu.unActiveSubmenuIcon')};

    &:before,
    &:after {
      width: 8px;
    }

    &:before {
      left: 1px;
    }
  }

  .ant-menu-submenu-title:hover {
    color: ${getCssVar('secondaryColor')};

    .ant-menu-submenu-arrow {
      color: ${getCssVar('secondaryColor')};
    }
  }

  .ant-menu-sub {
    background-color: ${getCssVar('menu.submenu.bg')};

    li:hover {
      background-color: ${getCssVar('menu.submenu.hoverBg')};

      span a {
        color: ${getCssVar('menu.item.selected.color')};
      }
    }

    .ant-menu-item-selected:before {
      content: '';
      box-shadow: ${getCssVar('menu.boxShadow')};
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 6px;
      border-left: 6px solid transparent;
      background-color: ${getCssVar('arrow')};
    }
  }

  .ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
    background-color: ${({ openKeys, $collapsed }) =>
      !openKeys?.length || $collapsed ? `${getCssVar('leftMenu.submenuClose')}` : ''};
  }
`;

export const Layout = styled(AntdLayout)`
  background-color: ${({ theme }) => theme.colorSet.bodyBackground};
  display: block;

  .ant-layout-content {
    background-color: ${getCssVar('layout.body.bg')};
  }

  .ant-menu-root {
    &.ant-menu {
      padding: 16px 0;
    }

    .ant-menu-item-group {
      .ant-menu-item-group-title {
        color: ${getCssVar('layout.titleColor')};
        text-transform: uppercase;
      }

      .ant-menu-item {
        height: ${({ theme }: Props) => theme.sizes[14]};
        line-height: ${({ theme }: Props) => theme.sizes[14]};
      }
    }

    .ant-menu-sub {
      &.ant-menu {
        padding: 8px 0;
      }

      > .ant-menu-item {
        position: relative;

        &:before {
          border-left: 6px solid transparent;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 6px;
        }

        &.ant-menu-item-selected {
          color: ${getCssVar('leftMenu.iconHoverSelectedColor')};

          &:before {
            border-color: ${getCssVar('leftMenu.activeSubmenuColor')};
            box-shadow: ${getCssVar('layout.boxShadow')};
          }
        }
      }
    }

    .ant-menu-item {
      a {
        &:hover {
          color: ${getCssVar('secondaryColor')};
          text-decoration: none;
        }

        &:focus {
          text-decoration: none;
        }
      }

      :hover {
        span.ant-menu-item-icon {
          color: ${getCssVar('leftMenu.iconHoverSelectedColor')};
        }
      }
    }

    .ant-menu-item-icon {
      color: ${getCssVar('leftMenu.iconColors')};
    }

    .ant-menu-submenu {
      :hover {
        span.ant-menu-item-icon {
          color: ${getCssVar('leftMenu.iconHoverSelectedColor')};
        }
      }
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-selected {
      background-color: ${getCssVar('menu.submenu.hoverBg')};

      .ant-menu-submenu-title {
        color: ${getCssVar('menu.item.color')};
      }

      span.ant-menu-title-content a {
        &:hover {
          color: ${getCssVar('menu.item.selected.color')};
        }
      }

      span.ant-menu-item-icon {
        color: ${getCssVar('leftMenu.iconHoverSelectedColor')};
      }
    }
  }

  .ant-menu-inline-collapsed > {
    .ant-menu-item,
    .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 ~'calc(50% - ${({ theme }: Props) => theme.sizes[6]} / 2)' !important;
    }
  }

  .ant-menu-submenu-open {
    span.ant-menu-item-icon {
      color: ${getCssVar('leftMenu.iconHoverSelectedColor')};
    }
  }
`;

export const RowTitle = styled(Row)<Props>`
  align-items: flex-end;
  margin-bottom: 20px;
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 10px;
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.lg}) {
    margin-bottom: 16px;

    .ant-col {
      &:first-child {
        padding-bottom: 0;
      }
    }
  }

  a {
    font-size: ${({ theme }: Props) => theme.sizes[4]};
    font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
    line-height: ${({ theme }: Props) => theme.sizes[5]};
  }
`;

export const IconMenu = styled(Icon)<Props>`
  align-self: flex-start;
  color: ${getCssVar('iconMenu.sidebarColor')};
  font-size: ${({ theme }: Props) => theme.sizes[6]};
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${breakpoints.lg}) {
    margin-top: 15px;
    margin-left: 100px;
  }
`;

export const Content = styled(AntContent)<{
  padding: string;
}>`
  min-height: 280px;
  padding: ${(props) => props.padding};
`;

export const MenuItemGroup = styled(Menu.ItemGroup)`
  .ant-menu-item {
    height: 56px;
  }
`;

export const DownloadIcon = styled(Icon)`
  padding: 0;
`;

export const ReportIncidentBtn = styled(Button)<Props>`
  background: transparent !important;
  border-color: ${getCssVar('buttonSecondary.border')} !important;
  color: ${getCssVar('leftMenu.btnReportIncidentColor')};
  padding-bottom: ${({ theme }) => theme.spacing[0]};

  :focus {
    background: transparent;
    border-color: ${getCssVar('textColor')};
  }
`;

export const StyledTitle = styled.span`
  color: ${getCssVar('titlePageColor')};
  font-size: ${getCssVar('titlePageSize')} !important;
  font-weight: ${getCssVar('titlePageWeight')} !important;
  margin-right: 0 !important;
  word-break: break-word;

  @media (max-width: ${breakpoints.md}) {
    font-size: ${({ theme }) => theme.sizes['5']} !important;
    max-width: 280px;
  }
`;

export const MenuLink = styled(Link)`
  color: ${getCssVar('menuLink.color')};

  &:hover {
    color: ${getCssVar('menuLink.hoverColor')};
  }
`;

export const ContentWrapper = styled.section``;

export const StyledSider = styled(Sider)`
  background-color: ${getCssVar('sidebar.bg')};
  height: 100vh;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  position: fixed !important;
  z-index: 99;

  + ${ContentWrapper} {
    margin-left: ${({ collapsed, collapsedWidth, width }) =>
      collapsed ? collapsedWidth : width}px;
    transition: max-width 0.2s ease-in-out;
  }

  .logo {
    background: ${({ theme }) => theme.colorSet.leftMenu.headerColor};
    line-height: 1;
    min-height: 80px;
    padding: ${({ collapsed }) => (collapsed ? '26px 16px 26px 20px' : '27px 20px')};
    &__image {
      height: ${({ theme, collapsed }) =>
        collapsed
          ? theme.colorSet.leftMenu.logoTopHeightCollapsed
          : theme.colorSet.leftMenu.logoTopHeight}px;

      width: ${({ theme, collapsed }) =>
        collapsed
          ? theme.colorSet.leftMenu.logoTopWidthCollapsed
          : theme.colorSet.leftMenu.logoTopWidth}px;
    }
  }
`;

type Collapsed = {
  $collapsed?: boolean;
};

export const MenuLogoFooter = styled(Icon)<Collapsed>`
  height: ${({ $collapsed }) => ($collapsed ? '60px' : '49px')};
  margin-left: ${({ $collapsed }) => ($collapsed ? '12px' : '24px')};
  margin-top: auto;
  width: ${({ $collapsed }) => ($collapsed ? '66px' : '110px')};
`;

export const MenuLogoFooterSpace = styled.div`
  min-height: 20px;
  width: 1px;
`;

export const UserNameStyled = styled.span<Props>`
  color: ${getCssVar('leftMenu.userNameColor')};
`;

export const UserAvatar = styled.div<Props>`
  color: ${getCssVar('leftMenu.userAvatarColor')};
`;

export const DrawerFooterLogo = styled.div`
  margin-top: 16px;
  text-align: center;
`;

type PropsSubmenu = {
  $isMenuOpen?: boolean;
  $collapsed?: boolean;
};

export const SubMenuStyled = styled(SubMenu)<PropsSubmenu & ThemeProps>`
  padding-left: ${({ $collapsed }) => $collapsed && '17px'};
  width: ${({ $collapsed }) => $collapsed && 'max-content'};

  .ant-menu-submenu-title {
    padding-right: ${({ $collapsed }) => $collapsed && '18px'};
  }

  .ant-menu-title-content {
    color: ${getCssVar('leftMenu.unActiveSubmenuColor')};
  }

  .ant-menu-submenu-arrow {
    display: ${({ $collapsed }) => $collapsed && 'none'};
  }

  span.ant-menu-item-icon {
    color: ${({ $isMenuOpen, $collapsed }) =>
      !$collapsed && $isMenuOpen && getCssVar('leftMenu.unActiveSubmenuIcon')} !important;
  }

  .ant-menu-submenu-arrow:before,
  .ant-menu-submenu-arrow:after {
    background-color: ${({ $isMenuOpen, $collapsed }) => {
      return !$collapsed && $isMenuOpen && getCssVar('leftMenu.unActiveSubmenuIcon');
    }} !important;
  }
`;

export const Blur = styled.div`
  background: ${getCssVar('blur.bg')};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
`;

export const VerticalDivider = styled.span`
  border-right: 1px solid ${getCssVar('divider.color')};
  color: ${getCssVar('divider.color')};
  margin: 0 16px;
`;

export const NotificationIconWrapper = styled.span`
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
  position: relative;

  &:before {
    border-radius: 50%;
    content: '';
    display: block;
    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: background-color 0.3s ease-in-out;
    width: 40px;
    z-index: 0;
  }

  &.active {
    &::before {
      background: ${getCssVar('notificationIcon.bg')};
    }
  }

  .notification-icon {
    position: relative;
    z-index: 1;
  }

  @media (max-width: ${breakpoints.lg}) {
    margin-left: 15px;
    align-self: start;
    max-height: 25px;

    &.active {
      &::before {
        background: ${getCssVar('notificationIcon.bgMobile')};
      }
    }

    .notification-icon {
      transform: scale(1.2);
    }
  }

  .ant-notification {
    margin-right: 0;
  }
`;

export const Pulsing = styled.div`
  animation: pulsing 2.25s infinite cubic-bezier(0.66, 0, 0, 1);
  background-color: ${getCssVar('pulsing.bg')};
  border-radius: 50%;
  box-shadow: ${getCssVar('pulsing.boxShadow')};
  color: #fff;
  display: block;
  font-size: ${({ theme }: ThemeProps) => theme.sizes['3.5']};
  height: 22px;
  left: 22px;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 17px;
  transform: translateX(30%);
  transition: all 300ms ease-in-out;
  width: 22px;

  &:hover {
    animation: none;
    font-size: ${({ theme }: ThemeProps) => theme.colors.white};
  }

  /* Animation */

  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    left: 20px;
    top: -13px;

    @keyframes pulsing {
      to {
        box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
      }
    }
  }
`;

export const NotificationMessage = styled(Box)`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: ThemeProps) => theme.sizes[6]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.black};

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    font-size: 20px;
  }
`;

export const ViewAllButton = styled(Link)`
  color: ${getCssVar('readButton.color')};
  font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
  padding-bottom: 18px;
  padding-left: 10px;

  &:hover {
    text-decoration: none;
  }
`;

export const NotificationWrapper = styled(Box)`
  border-bottom: 1px solid ${getCssVar('notificationWrapper.borderColor')};
  padding: 17px 0;
`;

export const NotificationTitleWrapper = styled(Box)`
  align-items: start;
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const NotificationTime = styled(Box)`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: ThemeProps) => theme.sizes['3.6']};
  opacity: 50%;
  padding-left: 10px;
`;

export const NotificationTitle = styled(Box)`
  color: ${getCssVar('textColor')};
  font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
`;

/* stylelint-disable */
export const NotificationDescription = styled(Box)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${getCssVar('textColor')};
  display: -webkit-box;
  max-height: 47px;
  overflow: hidden;
  padding-left: 34px;
  padding-top: 5px;
`;
/* stylelint-enable */

export const ReadButtonWrapper = styled(Box)`
  padding-left: 34px;
  padding-top: 10px;
`;

export const ReadButton = styled(Box)`
  color: ${getCssVar('readButton.color')};
  cursor: pointer;
  font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
  width: fit-content;
`;

export const ReadAllButton = styled(ReadButton)`
  padding: 15px 0 0 0;
`;

export const Overlay = styled(Box)<{
  isOpen: boolean;
  theme: ThemeProps;
}>`
  background-color: ${({ isOpen }) =>
    isOpen ? getCssVar('overlay.open') : getCssVar('overlay.close')};
  height: 100%;
  left: 0;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  position: fixed;
  top: 0;
  transition: all 0.5s ease, visibility 0.5s ease;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  width: 100%;
  z-index: 1000;
`;

export const NotificationListDrawer = styled(Drawer)`
  cursor: default;
  height: calc(100% - 65px);
  max-width: 550px;
  top: 65px;

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.lg}) {
    top: 0;
    height: 100%;
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    max-width: 335px;
  }

  .ant-drawer {
    &-wrapper-body {
      @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
        display: block;
        height: auto;
        overflow-y: auto;
      }
    }

    &-header,
    &-content {
      background-color: ${getCssVar('notificationWrapper.bg')};

      @media (max-width: ${({ theme }: Props) => theme.breakpoints.lg}) {
        overflow-y: auto;
      }
    }

    &-header {
      border-bottom-width: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 32px;

      &-title {
        flex-direction: row-reverse;

        .ant-drawer-title {
          flex: 0;
        }
      }

      @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
        padding: 20px 16px;

        &-title {
          flex-direction: row;
        }
      }
    }

    &-body {
      overflow: hidden;
      padding: 0 32px 32px;

      @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    &-close {
      color: ${getCssVar('notificationWrapper.closeIcon')};
      padding: 0;
      position: relative;
    }
  }
`;

export const ToggleButtonTitle = styled.span`
  color: ${getCssVar('textColor')};
`;
