import styled from 'styled-components';
import { Button } from 'shared';

type Props = {
  isPasswordWeak: boolean;
};

type ButtonProps = {
  $isHidden?: boolean;
};

export const CheckPasswordWrapper = styled.div<Props>`
  --color: ${({ theme }) => theme.colors.blue[900]};
  --margin: ${({ theme }) => theme.spacing[2]};

  .ant-form-item {
    &.ant-form-item-has-error {
      margin-bottom: 0;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      line-height: 21px;
      ${({ isPasswordWeak }) =>
        isPasswordWeak ? 'margin-top: var(--margin);' : 'margin-bottom: var(--margin);'}
      ${({ isPasswordWeak }) => isPasswordWeak && 'color: var(--color);'}
    }
  }

  div.example {
    color: var(--color);
    margin-bottom: var(--margin);
    opacity: 0.5;
  }
`;

export const StyledButton = styled(Button)<ButtonProps>`
  display: ${({ $isHidden }) => $isHidden && 'none'};
`;
