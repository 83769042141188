import styled from 'styled-components';
import { Box, Card } from 'shared';
import { ThemeProps } from 'services/interfaces';
import { breakpoints } from 'styles/theme';
import { Theme } from 'styles/theme/types';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
  }
`;
export const LegendItem = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 105px;
  min-width: 170px;
`;

export const Title = styled(Box)`
  font-size: ${({ theme }: ThemeProps) => theme.sizes[4]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.bold};
`;
export const Count = styled(Box)`
  font-size: ${({ theme }: ThemeProps) => theme.sizes[6]};
  font-weight: ${({ theme }: ThemeProps) => theme.fontWeights.black};
`;

export const MapWrapper = styled(Box)`
  height: 95%;
  position: relative;
  top: 25px;
  width: 100%;

  @media (max-width: ${breakpoints.xl}) {
    top: 50px;
    height: 90%;
    margin-bottom: 10px;
  }

  @media (max-width: ${breakpoints.lg}) {
    height: 90%;
  }

  @media (max-width: ${breakpoints.md}) {
    height: 55%;
  }

  &::before {
    background-color: #1f201f;
    content: '';
    display: block;
    height: 60px;
    position: absolute;
    top: -53px;
    width: 100%;
    z-index: 1;

    @media (max-width: ${breakpoints.lg}) {
      left: -1px;
    }

    @media (max-width: ${breakpoints.md}) {
      height: 77px;
    }
  }

  iframe {
    border: 0;
    height: 100%;
    position: absolute;
    top: -50px;
    width: 100%;
  }
`;

export const DarkCard = styled(Card)`
  background-color: #1f201f;
`;

export const MapLegend = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }

  div {
    align-items: flex-start;
    display: flex;
    margin-right: 40px;
    padding-bottom: 10px;

    p {
      color: white;
      margin-left: 10px;
    }
  }
`;
