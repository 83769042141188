import { PatchVendorSeverity } from 'services/api';
import { colors } from '../colors';

export const pieChart = {
  CurrentTrainingStatusSuccess: colors.green[500],
  CurrentTrainingStatusStarted: colors.yellow[500],
  CurrentTrainingStatusNotStarted: colors.blue[50],

  CurrentRefresherTestsPassed: colors.green[300],
  CurrentRefresherTestsIgnored: colors.blue[600],
  CurrentRefresherTestsFailed: colors.red[400],

  PhishingLast30DaysProvidedCredentials: colors.red[700],

  ActiveAlarms: colors.blue[100],
  UnActiveAlarms: colors.red[100],

  PatchesBySeverity: {
    [PatchVendorSeverity._5]: colors.red[400],
    [PatchVendorSeverity._4]: colors.yellow[500],
    [PatchVendorSeverity._3]: colors.green[300],
    [PatchVendorSeverity._2]: colors.blue[600],
    [PatchVendorSeverity._1]: colors.blue[900],
  },
};
