import { Dropdown } from 'antd';
import React from 'react';
import { Icon } from 'shared';
import { useTheme } from 'styled-components';
import { Menu } from 'shared/Menu/Menu.styled';
import { deleteDropdownItems, DeleteDropdownLabels } from '../constants';
import { getCssVar } from '../../../styles/theme';

type DeleteDropdownProps = {
  children: JSX.Element | JSX.Element[];
  placement?: 'bottomLeft' | 'bottomCenter';
  disabled?: boolean;
  onClick: (soft: boolean) => void;
};

type DropdownItem = {
  key: string;
  label: string;
  danger?: boolean;
};

export const DeleteDropdown = ({ children, onClick, placement, disabled }: DeleteDropdownProps) => {
  const theme = useTheme();
  const handleItemClick = ({ key }: { key: React.Key }) => {
    onClick(key === DeleteDropdownLabels.DeleteInternally);
  };

  const dropdownOptions = (
    <Menu onClick={handleItemClick} style={{ background: getCssVar('select.bg') }}>
      {deleteDropdownItems.map((item: DropdownItem) => (
        <Menu.Item key={item.key} danger={item.danger}>
          <Icon component={theme.icons.delete} />{' '}
          <span style={{ color: item.danger ? getCssVar('errorColor') : getCssVar('textColor') }}>
            {item.label}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={dropdownOptions}
      trigger={['click']}
      disabled={disabled}
      placement={placement || 'bottomLeft'}
    >
      {children}
    </Dropdown>
  );
};
