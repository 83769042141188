import moment from 'moment';
import { Routes } from 'services/entities';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { backlinkRoutes } from 'app-constants/routes';
import { HistoryState } from 'services/interfaces';
import { getCapitalizedString } from 'services/helpers';
import { Filter, Filters } from 'services/api';
import { dateFormatHours, initialPagination } from 'app-constants';
import {
  initialOrderBy,
  suppressedItialOrderBy,
  confirmedVulnerabilitiesStatusFilter,
  potentialVulnerabilitiesStatusFilter,
  confirmedAndPotentialVulnerabilitiesStatusFilter,
} from './constants';

export const getLayoutConfig = (
  downloadCSV: () => void,
  loading: boolean,
  isMobile?: boolean,
  historyState?: HistoryState,
  pathname?: string,
): InnerLayout => ({
  title: 'Vulnerabilities',
  downloadDoc: !isMobile
    ? [
        {
          id: 1,
          title: 'Download CSV',
          onDownload: downloadCSV,
          loading,
        },
      ]
    : undefined,
  backLink: (
    <BackLink
      defaultRoute={Routes.VULNERABILITY_MANAGEMENT}
      defaultTitle={backlinkRoutes[Routes.VULNERABILITY_MANAGEMENT]}
      historyState={historyState?.backTo?.route !== pathname ? historyState : undefined}
    />
  ),
});

export const getDefaultQueryParamsByTab = ({
  activeTab,
  forExport,
}: {
  activeTab: string;
  forExport: boolean;
}) => {
  const suppressedAdditionalFilter = forExport ? { suppressedVulnerabilities: true } : {};
  const defaultParams = {
    orderBy: initialOrderBy,
    pagination: initialPagination,
    filter: { ...confirmedVulnerabilitiesStatusFilter },
  };

  switch (activeTab) {
    case 'active':
      return {
        ...defaultParams,
      };
    case 'potential':
      return {
        ...defaultParams,
        filter: { ...potentialVulnerabilitiesStatusFilter },
      };
    case 'suppressed':
      return {
        ...defaultParams,
        orderBy: suppressedItialOrderBy,
        filter: {
          ...confirmedAndPotentialVulnerabilitiesStatusFilter,
          ...suppressedAdditionalFilter,
        },
      };
    default:
      return {
        ...defaultParams,
      };
  }
};

export const getDefaultTabsParams = () => {
  return {
    active: {
      orderBy: initialOrderBy,
      pagination: initialPagination,
    },
    potential: { orderBy: initialOrderBy, pagination: initialPagination },
    suppressed: {
      orderBy: suppressedItialOrderBy,
      pagination: initialPagination,
    },
  };
};

export const getCsvFileName = (activeTab: string): string =>
  `${getCapitalizedString(activeTab)} vulnerabilities ${moment().format(dateFormatHours)}`;

export const excludeFilterFields = (fields: Filter[] = [], excludeFieldsNames: string[] = []) => {
  return fields.filter((field) => !excludeFieldsNames.includes(field.name as string));
};

export const getHasHiddenFilters = (filter?: Filters) => {
  if (!filter) {
    return false;
  }

  const filterFields = filter?.fields || [];

  // TODO should update list of available fields name
  return (
    Object.keys(filter).some((prop) => prop !== 'fields' && prop !== 'search') ||
    filterFields.some((field) => field.name === 'patchable')
  );
};
