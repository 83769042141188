import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page, SidebarTextBlock } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide5.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const ProcessText = styled(SidebarTextBlock)`
  top: 125px;
  width: 413px;
`;

export const ProcessPieWrapper = styled.div`
  position: absolute;
  right: 140px;
  top: 70px;
`;

export const ProcessListWrapper = styled.div`
  bottom: 80px;
  position: absolute;
  right: 77px;
  width: 430px;

  table {
    td .title {
      line-height: normal;
      vertical-align: top;
    }
  }

  hr {
    display: none;
  }
`;
