import { initialPagination, minLengthToSearch } from 'app-constants';

import { AssetStatus, Filter, OrderBy, PatchStatus } from 'services/api';
import {
  initialOrderBy as CMDBinitialOrderBy,
  searchSettings as CMDBSearchSettings,
} from '../Cmdb/constants';
import { ColumnKey } from './types';
import { InputType } from '../../services/api/models/InputType';

export const filterFields = [
  {
    field: 'patchStatus',
    options: Object.values(PatchStatus).map((v) => ({ value: v, label: v })),
    label: 'Patch status',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'assetStatus',
    options: Object.values(AssetStatus).map((v) => ({ value: v, label: v })),
    label: 'Asset scan status',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];

export const searchSettings = {
  fields: [ColumnKey.Asset, ColumnKey.OS],
  min: minLengthToSearch,
  placeholder: 'Search by name, OS',
};

export const initialOrderBy = {
  field: ColumnKey.Scanned,
  type: OrderBy.type.DESC,
};

export const redirectToCmdbParams = (hostname: string) => ({
  orderBy: CMDBinitialOrderBy,
  pagination: initialPagination,
  filter: {
    search: { fields: CMDBSearchSettings.fields, value: hostname },
  },
});
