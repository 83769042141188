import moment from 'moment';
import { dateFormat, dateFormatHours, NO_VALUE_LABEL } from 'app-constants';
import { SERVICE_CONNECTION } from './constants';

export const getConnectionStatus = (isConnected = false) => {
  return isConnected
    ? SERVICE_CONNECTION.STATUS.CONNECTED
    : SERVICE_CONNECTION.STATUS.NOT_CONNECTED;
};

export const getConnectionColor = (isConnected = false) => {
  return isConnected ? SERVICE_CONNECTION.COLOR.CONNECTED : SERVICE_CONNECTION.COLOR.NOT_CONNECTED;
};

export const formatDate = (dateString = '', withDefaultValue = false, withTime = false) => {
  const defaultValue = withDefaultValue ? NO_VALUE_LABEL : null;
  const format = withTime ? dateFormatHours : dateFormat;

  return dateString ? moment(dateString)?.format(format) : defaultValue;
};

export const renderValue = (value = '') => (!value ? NO_VALUE_LABEL : value);
