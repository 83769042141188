import { Tag as AntdTag } from 'antd';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { breakpoints } from 'styles/theme';
import { TagColoring } from '../TagColoring/TagColoring';
import { getCssVar } from '../../styles/theme';

interface IProps {
  theme: Theme;
  title?: string;
}

export const Tag = styled(AntdTag)<IProps>`
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${({ title }) => title === 'Serious' && getCssVar('labelMediumColorText')};
  font-weight: ${getCssVar('labelFontWeight')};
  line-height: 17px;
  margin-left: 16px;
  min-width: 64px;

  padding: 0.5px 2px;
  text-align: center;

  text-transform: ${getCssVar('labelsTextTransform')};

  @media (max-width: ${breakpoints.lg}) {
    margin-top: 5px;
  }

  &.ant-tag-success {
    background: ${getCssVar('success')};
    border-color: ${getCssVar('success')};
    color: ${getCssVar('tagColor')};
  }

  &.ant-tag-warning {
    background: ${getCssVar('medium')};
    border-color: ${getCssVar('medium')};
    color: ${getCssVar('labelMediumColorText')};
  }

  &.ant-tag-error {
    background: ${getCssVar('colorHigh')};
    border-color: ${getCssVar('colorHigh')};
    color: ${getCssVar('tagColor')};
  }

  &.ant-tag-processing {
    background: ${getCssVar('processing')};
    border-color: ${getCssVar('processing')};
    color: ${getCssVar('tagColor')};
  }

  &.ant-tag-default {
    background: ${getCssVar('default')};
    border-color: ${getCssVar('default')};
    color: ${getCssVar('tagColor')};
  }
  &.ant-tag-suppression-suppressed,
  &.ant-tag-suppression-reviewed {
    background: ${({ theme }) => theme.colorSet.vulnSuppression.suppressed.bg};
    border-color: ${({ theme }) => theme.colorSet.vulnSuppression.suppressed.borderColor};
    color: ${({ theme }) => theme.colorSet.vulnSuppression.suppressed.color};
    padding: 0.5px 5px;
  }
  &.ant-tag-suppression-unsuppressed {
    background: ${({ theme }) => theme.colorSet.vulnSuppression.unsuppressed.bg};
    border-color: ${({ theme }) => theme.colorSet.vulnSuppression.unsuppressed.borderColor};
    color: ${({ theme }) => theme.colorSet.vulnSuppression.unsuppressed.color};
    padding: 0.5px 5px;
  }
`;

export const HeaderTagColoring = styled(TagColoring)<IProps>`
  margin-left: 8px;
`;
