import React from 'react';
import { Box } from 'shared';
import { PATH_TO_IMAGES } from 'app-constants';
import { useTheme } from 'styled-components';

export const Nasstar = ({ width = '0px' }: { width?: string }) => {
  const theme = useTheme();
  const nasstarImg = `${PATH_TO_IMAGES}/nasstar-shape.png`;

  return (
    <Box
      position='fixed'
      r='0'
      t='0'
      overflow='hidden'
      height={theme.sizes.full}
      w={width || theme.sizes.pageWidth}
      backgroundColor='black'
      backgroundImage={`url(${nasstarImg})`}
      backgroundPosition='bottom'
      backgroundSize='cover'
    />
  );
};
