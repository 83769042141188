import { Italic, Bold, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Link } from '@ckeditor/ckeditor5-link';
import { Undo } from '@ckeditor/ckeditor5-undo';
import { List } from '@ckeditor/ckeditor5-list';
import {
  Table,
  TableCellProperties,
  TableProperties,
  TableToolbar,
} from '@ckeditor/ckeditor5-table';

export const editorConfig = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Underline,
    Paragraph,
    Link,
    Undo,
    List,
    Table,
    TableToolbar,
    TableCellProperties,
    TableProperties,
  ],
  toolbar: [
    'bold',
    'italic',
    'underline',
    '|',
    'numberedList',
    'bulletedList',
    '|',
    'insertTable',
    '|',
    'undo',
    'redo',
  ],
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
};
