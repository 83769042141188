import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { CustomerId, CybersafetyAndPhishingService, GridRequestParams } from 'services/api';

const getPhishingAndTraining = createAsyncThunk(
  '/people/people_phishing/data/get',
  async (requestBody: GridRequestParams) => {
    const response = await CybersafetyAndPhishingService.getPhishingAndTraining(requestBody);

    return response;
  },
);

const getIncompleteTraining = createAsyncThunk(
  '/people/people_phishing/get/incopleteTraining',
  async (customerId: CustomerId) => {
    const response = await CybersafetyAndPhishingService.getIncompleteTrainingTrend({
      customerId,
      startDate: moment().subtract(1, 'month').valueOf(),
      endDate: moment().valueOf(),
    });

    return response;
  },
);

const getCurrentTrainingStatus = createAsyncThunk(
  '/people/current-training-status/get',
  async (requestBody: { customerId: CustomerId }) => {
    const response = await CybersafetyAndPhishingService.getCurrentTrainingStatus(requestBody);

    return response;
  },
);

const getPhishingLastThirtyDays = createAsyncThunk(
  '/people/phishing-last-thirty-days/get',
  async (requestBody: { customerId: CustomerId; timezoneOffset: number }) => {
    const response = await CybersafetyAndPhishingService.getPhishingLastThirtyDays(requestBody);

    return response;
  },
);

const getRefresherRates = createAsyncThunk(
  '/people/refresher-rates/get',
  async (requestBody: { customerId: CustomerId }) => {
    const response = await CybersafetyAndPhishingService.getRefresherRates(requestBody);

    return response;
  },
);

const getLicenseUsage = createAsyncThunk(
  '/people/license-usage/get',
  async (requestBody: { customerId: CustomerId }) => {
    const response = await CybersafetyAndPhishingService.getLicenseUsage(requestBody);

    return response;
  },
);

const getCulture = createAsyncThunk(
  '/people/culture/get',
  async (requestBody: { customerId: CustomerId }) => {
    const response = await CybersafetyAndPhishingService.getCulture(requestBody);

    return response;
  },
);

const getAverageConfidence = createAsyncThunk(
  '/people/average-confidence/get',
  async (requestBody: { customerId: CustomerId }) => {
    const response = await CybersafetyAndPhishingService.getAverageConfidence(requestBody);

    return response;
  },
);

const getPhishingByMonth = createAsyncThunk(
  '/people/phishing-by-month/get',
  async (requestBody: { customerId: CustomerId; timezoneOffset: number }) => {
    const response = await CybersafetyAndPhishingService.getPhishingByMonth(requestBody);

    return response;
  },
);

export const cybersafetyApi = {
  getPhishingAndTraining,
  getIncompleteTraining,
  getCurrentTrainingStatus,
  getPhishingLastThirtyDays,
  getRefresherRates,
  getLicenseUsage,
  getCulture,
  getAverageConfidence,
  getPhishingByMonth,
};
