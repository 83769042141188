/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSupportRequestRequest } from '../models/CreateSupportRequestRequest';
import type { CustomerId } from '../models/CustomerId';
import type { Filters } from '../models/Filters';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { RecId } from '../models/RecId';
import type { SecurityIncidentsTrendResponse } from '../models/SecurityIncidentsTrendResponse';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { SupportRequestDetailsResponse } from '../models/SupportRequestDetailsResponse';
import type { SupportRequestsResponse } from '../models/SupportRequestsResponse';
import type { Timestamp } from '../models/Timestamp';
import type { VulnerabilityTrendPointTypeSchema } from '../models/VulnerabilityTrendPointTypeSchema';
import { request as __request } from '../core/request';

export class ServiceRequestsService {
  /**
   * Get support requests
   * Get support requests (entity) in service requests section
   * @returns SupportRequestsResponse Get support requests
   * @throws ApiError
   */
  public static async getSupportRequests({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<SupportRequestsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/support-requests`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when get support requests`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get support request by RefId
   * Get support request (entity) in service requests section
   * @returns SupportRequestDetailsResponse Get support request
   * @throws ApiError
   */
  public static async getSupportRequest({
    customerId,
    recId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** id of support request record on ivanti **/
    recId: RecId;
  }): Promise<SupportRequestDetailsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/support-request/${recId}`,
      errors: {
        400: `An error message when get support request`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Export support requests
   * Export filtered support requests to csv file and response this file
   * @returns any Export support requests in csv file
   * @throws ApiError
   */
  public static async exportSupportRequestsToCsv({
    customerId,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/support-request/export-to-csv`,
      query: {
        filter: filter,
      },
      errors: {
        400: `An error message when export`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Create support request
   * Create support request
   * @returns SuccessResponse Create support request
   * @throws ApiError
   */
  public static async createSupportRequest({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Create support requests **/
    requestBody?: CreateSupportRequestRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/support-request`,
      body: requestBody,
      errors: {
        400: `An error message when creating support request`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Service requests. Get Security Incidents trend
   * Get Security Incidents trend
   * @returns SecurityIncidentsTrendResponse Security Incidents trend
   * @throws ApiError
   */
  public static async getSecurityIncidentsTrend({
    customerId,
    startDate,
    endDate,
    pointType,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate?: Timestamp;
    /** The end date parameter **/
    endDate?: Timestamp;
    /** Chart point type **/
    pointType?: VulnerabilityTrendPointTypeSchema;
  }): Promise<SecurityIncidentsTrendResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/support-request/security-incidents-trend`,
      query: {
        startDate: startDate,
        endDate: endDate,
        pointType: pointType,
      },
      errors: {
        400: `An error message when getting isolated devices`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
