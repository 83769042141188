import { SupportRequestsImpact } from 'services/api';

export const delay = 500;

export const paginationSelections = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const priorityOptions = [
  { value: SupportRequestsImpact.HIGH, label: SupportRequestsImpact.HIGH },
  { value: SupportRequestsImpact.MEDIUM, label: SupportRequestsImpact.MEDIUM },
  { value: SupportRequestsImpact.LOW, label: SupportRequestsImpact.LOW },
];

export const hiddenFilterTooltip = 'Filters applied from previous selection.';
export const hiddenFiltersNames = {
  vulnerabilityStatus: 'vulnerabilityStatus',
  destHostName: 'destHostName',
};
