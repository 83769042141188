import { CSSProperties, ReactNode } from 'react';
import { message } from 'antd';
import {
  customError,
  errorDefaultNotificationMessage,
  successNotificationMessage,
  DEFAULT_NOTIFICATION_DURATION,
} from 'app-constants';
import { MessageType } from 'store';
import { Themes } from 'themes/types';
import { getSupportEmail } from 'themes/helpers';

export const showMessage = (
  config: {
    content: string | ReactNode;
    className?: string;
    duration?: number;
    style?: CSSProperties;
  },
  type: string,
) => {
  message.destroy();
  switch (type) {
    case MessageType.Success:
      message.success(config);
      break;
    case MessageType.Error:
      message.error(config);
      break;
    case MessageType.Loading:
      message.loading(config);
      break;
    default:
      message.warning(config);
  }
};

export const showError = (customMessage?: string) => {
  message.destroy();
  showMessage(
    (customMessage && customError(customMessage)) || errorDefaultNotificationMessage,
    MessageType.Error,
  );
};

export const showSuccess = (customMessage?: string, duration?: number) => {
  showMessage(
    (customMessage && {
      content: customMessage,
      duration: duration || DEFAULT_NOTIFICATION_DURATION,
    }) ||
      successNotificationMessage,
    MessageType.Success,
  );
};

export const getCommonError = (themeName?: Themes) =>
  `Oops! Something went wrong. Please try again. If still experiencing problems, please contact ${getSupportEmail(
    themeName,
  )}`;
