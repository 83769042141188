import { SecuritySettingsResponse } from 'services/api';

export enum ExpiryValues {
  ThirtyDays = '2592000000',
  SixtyDays = '5184000000',
  NinetyDays = '7776000000',
  SixMonths = '15552000000',
  ThirteenMonths = '33696000000',
  Never = 'never',
}

export enum Expiry {
  ThirtyDays = '30 days',
  SixtyDays = '60 days',
  NinetyDays = '90 days',
  SixMonths = 'Six months',
  ThirteenMonths = 'Thirteen months',
  Never = 'Never',
}

export enum MinLength {
  SixCharacters = '6 characters',
  EightCharacters = '8 characters',
  TenCharacters = '10 characters',
  TwelveCharacters = '12 characters',
}

export enum Complexity {
  TwoCharacters = '2 character types',
  ThreeCharacters = '3 character types',
  FourCharacters = '4 character types',
}

export enum PasswordReUsePrevention {
  LastTwoPasswords = 'Last 2 passwords',
  LastFourPasswords = 'Last 4 passwords',
  LastSixPasswords = 'Last 6 passwords',
  LastThirteenPasswords = 'Last 13 passwords',
}

export enum Lock {
  AfterThreeAttempts = 'After 3 attempts',
  AfterFiveAttempts = 'After 5 attempts',
  AfterTenAttempts = 'After 10 attempts',
}

export enum TooltipText {
  UserAccountExpiry = 'If an account has not be used for the period indicated it will automatically be locked.  The user will not be able to login until the account has been unlocked by an administrator',
  PasswordExpiry = 'Frequency by which passwords are automatically expired by the system, if not changed by the user.  If the password expires, the user will be required to reset their password before they can login.',
  PasswordMinLength = 'Minimum permitted length for a user’s password',
  PasswordComplexity = 'Minimum number of different character types required in a user’s password. Character types are: lower case letters, uppercase letters, numbers and symbols.',
  PasswordReUsePrevention = 'Specifies the minimum number of previous passwords that a user may not re-use.',
  PasswordLock = 'The number of failed login attempts that a user may attempt before the user account is locked. The user will not be able to login until the account has been unlocked by an administrator.',
}

export enum LabelTitles {
  UserAccountExpiry = 'User account expiry',
  PasswordExpiry = 'Password expiry',
  PasswordMinLength = 'Minimum password length',
  PasswordComplexity = 'Password complexity',
  PasswordReUsePrevention = 'Password re-use prevention',
  PasswordLock = 'Password lock',
}

export const formFields: (keyof SecuritySettingsResponse)[] = [
  'userAccountExpiry',
  'passwordExpiry',
  'passwordMinLength',
  'passwordComplexity',
  'passwordReUsePrevention',
  'passwordLock',
];
