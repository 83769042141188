import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';
import { getCssVar } from '../../styles/theme';

export const Select = styled(AntdSelect)`
  &.ant-select-checked {
    &:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${getCssVar('select.checked.borderColor')};
    }
  }

  &.ant-select-multiple {
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ant-select-selection-item {
      height: 28px;
      line-height: 26px;
      margin-top: 0;
      margin-bottom: 0;
      padding-inline-start: 5px;
      border-color: ${getCssVar('select.multiple.borderColor')};
      background: ${getCssVar('select.multiple.bg')};
    }

    .ant-select-selection-item-remove {
      color: ${getCssVar('select.multiple.remove.color')};

      &:hover,
      &:focus {
        color: ${getCssVar('select.multiple.remove.focusColor')};
      }
    }

    .ant-select-selector {
      padding: 0 4px;
    }
  }

  .ant-select-selection-overflow-item-rest {
    .ant-select-selection-item {
      border-color: transparent;
      background: transparent;
      padding-left: 0;
    }
  }

  .ant-select-arrow {
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }

  .ant-select-clear {
    font-size: 16px;
    margin-top: -8px;
    right: 18px;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    border-color: ${getCssVar('select.borderColor')};
  }

  .ant-select-item-option-state {
    color: ${getCssVar('primary')} !important;
  }
`;
