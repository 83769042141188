import styled from 'styled-components';
import { Drawer } from 'antd';
import { Theme } from 'styles/theme/types';
import { Box } from 'shared';

type Props = { theme: Theme };

export const NewsAndWebinarsDrawer = styled(Drawer)<Props>`
  height: calc(100vh - 80px);
  top: 80px;

  .ant-drawer-content {
    backdrop-filter: blur(32px);
    background-color: ${({ theme }: Props) => `${theme.colors.white}${theme.opacity[75]}`};

    .ant-drawer-header {
      background: none;
      border-bottom: none;
      padding: ${({ theme }: Props) => theme.sizes[8]};
    }

    .ant-drawer-body {
      padding: ${({ theme }: Props) =>
        `${theme.sizes[1]} ${theme.sizes['5.5']} ${theme.sizes['5.5']}`};
    }
  }

  .ant-drawer-mask {
    animation: none !important;
    opacity: 0 !important;
  }

  @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
    height: 100vh;
    top: ${({ theme }: Props) => theme.sizes[0]};

    .ant-drawer-content {
      .ant-drawer-header {
        padding: ${({ theme }: Props) => theme.sizes[5]};
      }

      .ant-drawer-body {
        padding: ${({ theme }: Props) =>
          `${theme.sizes[1]} ${theme.sizes['2.5']} ${theme.sizes['2.5']}`};
      }
    }
  }
`;

export const DrawerTitle = styled(Box)<Props>`
  color: ${({ theme }: Props) => theme.colors.blue[900]};
  font-size: ${({ theme }: Props) => theme.sizes[6]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.black};
`;

export const DrawerContent = styled(Box)<Props>`
  display: flex;
  flex-direction: column;
`;

export const DrawerContentItemContainer = styled(Box)<Props>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }: Props) => `${theme.sizes[2]} ${theme.sizes['2.5']}`};
`;

export const DrawerContentItemTitle = styled(Box)<Props>`
  color: ${({ theme }: Props) => theme.colors.blue[900]};
  font-size: ${({ theme }: Props) => theme.sizes[5]};
  font-weight: ${({ theme }: Props) => theme.fontWeights.bold};
  padding-bottom: ${({ theme }: Props) => theme.sizes[4]};
`;
