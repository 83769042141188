import { Card, CardProps } from 'shared';
import { useApp } from 'store';
import { useTheme } from 'styled-components';
import { PropsWithChildren } from 'react';

type TabContentViewProps = PropsWithChildren<CardProps>;

export const TabContent = ({ children, ...props }: TabContentViewProps) => {
  const { isMobile } = useApp();
  const { sizes } = useTheme();

  return (
    <Card
      {...props}
      cardPadding={!isMobile ? sizes['5.5'] : `${sizes[4]} ${sizes[5]}`}
      headStyles={{
        actionsGap: isMobile ? sizes[2] : sizes[4],
        actionsWidth: '100%',
        actionsDisplay: 'flex',
        pb: isMobile ? sizes[2] : sizes[4],
        titlePaddingRight: isMobile ? sizes[0] : sizes['2.5'],
        zIndex: 1,
      }}
    >
      {children}
    </Card>
  );
};
