import { Filter, PatchManagementPlatform } from 'services/api';
import { InputType } from '../../services/api/models/InputType';

export const platformFilterOption = {
  field: 'platform',
  options: Object.values(PatchManagementPlatform).map((v) => ({ value: v, label: v })),
  label: '',
  inputType: InputType.SELECTOR,
  filterType: Filter.type.VALUE,
};
