import { ActionPopup } from 'shared';
import { ServiceIntegrationResponse } from 'services/api';
import { useDispatch } from 'react-redux';
import { AppDispatch, servicesIntegrationsApi, PayloadActionType } from 'store';
import { showError, showSuccess } from 'services/helpers';
import { RequestStatus } from 'store/loading/slice';

type DisconnectPopupProps = {
  onCancel: () => void;
  onApply?: () => void;
  customerId: string;
  serviceId: string;
};

export const DisconnectPopup = ({
  onCancel,
  onApply,
  customerId,
  serviceId,
}: DisconnectPopupProps) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <ActionPopup
      isVisible
      popupDetails={{
        id: 'disconnectService',
        title: 'Are you sure?',
        onApplyTitle: 'Confirm',
        text: 'All credentials will be cleared and integration will be disconnected',
        danger: true,
        onApply: async () => {
          const result = (await dispatch(
            servicesIntegrationsApi.disconnectService({
              customerId,
              serviceId,
            }),
          )) as PayloadActionType<ServiceIntegrationResponse>;

          if (result.meta.requestStatus === RequestStatus.Rejected) {
            // eslint-disable-next-line no-console
            console.error(result.error);
            showError();
          } else {
            dispatch(servicesIntegrationsApi.getServices({ customerId }));

            showSuccess('Integration has been disconnected');

            if (typeof onApply === 'function') {
              onApply();
            }
          }
        },
      }}
      setIsVisiblePopup={onCancel}
    />
  );
};
