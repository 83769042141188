import { getFormattedDate, isMoreThanNumberOfDaysAgo, getTimeAgoLabel } from 'services/helpers';
import { Box } from 'shared';
import classNames from 'classnames';
import {
  SuppressionHistoryItem,
  SuppressionStatus,
  VulnerabilityDetails,
  VulnerabilitySchema,
} from 'services/api';
import { SUPPRESSION_REVIEW_MAX_DAYS } from 'app-constants';
import { Tooltip } from 'antd';
import { useApp } from 'store';
import { TimeAgoWrap } from './styled';

type RecordType = SuppressionHistoryItem | VulnerabilityDetails | VulnerabilitySchema;

type SuppressedAtColumnProps = {
  date: string;
  record: RecordType;
  index: number;
};

function isSuppressionHistoryItem(record: RecordType): record is SuppressionHistoryItem {
  return (record as SuppressionHistoryItem).date !== undefined;
}

export const SuppressedAtColumn = ({ date, record, index }: SuppressedAtColumnProps) => {
  const { isMobile } = useApp();
  let isExpired = isMoreThanNumberOfDaysAgo(SUPPRESSION_REVIEW_MAX_DAYS, date);

  if (isSuppressionHistoryItem(record)) {
    isExpired = isExpired && record.status !== SuppressionStatus.UNSUPPRESSED && index === 0;
  }

  const suppressionTooltip =
    'Please ensure you review suppressed vulnerabilities every 90 days for security maintenance';

  const timeAgoDate = (
    <TimeAgoWrap className={classNames({ expired: isExpired })}>
      {getTimeAgoLabel(date)}
    </TimeAgoWrap>
  );

  return (
    <Box>
      <Box>{getFormattedDate(date)}</Box>
      {isExpired ? (
        <Tooltip title={suppressionTooltip} placement='top' trigger={isMobile ? 'click' : 'hover'}>
          <TimeAgoWrap className={classNames({ expired: isExpired })}>
            {getTimeAgoLabel(date)}
          </TimeAgoWrap>
        </Tooltip>
      ) : (
        timeAgoDate
      )}
    </Box>
  );
};
