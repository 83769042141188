import React from 'react';
import { Col, Space } from 'antd';
import { Box, Icon } from 'shared';
import { icons } from 'styles/theme/icons';
import { EmptyTablePreviewContainer, TextContent, Title } from './styled';
import { getCssVar } from '../../../styles/theme';

export type EmptyTablePreviewProps = {
  icon?: string;
  actionButton?: JSX.Element | null;
  title?: string;
  textContent?: string | JSX.Element;
  marginTop?: string;
  alignCenter?: boolean;
  loading?: boolean;
  withoutMarginTop?: boolean;
};

export const EmptyTablePreview = ({
  icon = 'empty_table',
  actionButton,
  title,
  textContent,
  marginTop,
  alignCenter,
  loading,
  withoutMarginTop,
}: EmptyTablePreviewProps) => {
  if (loading) {
    return <Box height='140px' />;
  }

  return (
    <EmptyTablePreviewContainer $withoutMarginTop={withoutMarginTop}>
      <Col>
        <Box marginTop={marginTop} color={getCssVar('textColor')}>
          <Space direction='vertical' size={6}>
            {icon && <Icon component={icons[icon as keyof typeof icons]} />}
            {title && <Title>{title}</Title>}
            {textContent && <TextContent alignCenter={alignCenter}>{textContent}</TextContent>}
            {actionButton}
          </Space>
        </Box>
      </Col>
    </EmptyTablePreviewContainer>
  );
};
