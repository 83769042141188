import { ColumnsType } from 'antd/lib/table';
import { getFormattedDate } from 'services/helpers';
import { ActionNames, emptyDataString, PermissionsLevel } from 'app-constants';
import { DocumentSchema, DocumentType, BlobScanStatus } from 'services/api';
import { Box, UcFirstLetter } from 'shared';
import { DownloadButton } from './DownloadButton';
import { DeleteButton } from '../../../shared/buttons/DeleteButton';
import { ColumnKey } from './constants';
import { normalizeFileSize, displayFileScanStatus } from '../helpers';
import { getCssVar } from '../../../styles/theme';

type GetColumnsProps = (
  downloadClick: (name: string) => void,
  actionClick: (row: DocumentSchema, popupId: ActionNames) => void,
  downloading: string,
  isMobile: boolean,
  permission?: PermissionsLevel | null,
  isSuperAdmin?: boolean,
) => ColumnsType<DocumentSchema>;

export const getColumns: GetColumnsProps = (
  downloadClick,
  actionClick,
  downloading,
  isMobile,
  permission,
  isSuperAdmin,
) => {
  return [
    {
      key: ColumnKey.Name,
      title: 'Document name',
      dataIndex: ColumnKey.Name,
      render: (text: string, row: DocumentSchema) => (
        <Box d='flex' gap='16px' alignItems='center' color={getCssVar('textColor')}>
          <span>{text}</span>
          {displayFileScanStatus(row.scanstatus as BlobScanStatus)}
        </Box>
      ),
    },
    {
      key: ColumnKey.DocumentType,
      title: 'Document type',
      dataIndex: ColumnKey.DocumentType,
      showSorterTooltip: false,
      render: (documentType: string, row: DocumentSchema) =>
        !documentType ? (
          ''
        ) : (
          <>
            {UcFirstLetter(documentType.toLowerCase())}
            {documentType === DocumentType.CERTIFICATE && ` - ${row.certificatename}`}
          </>
        ),
      sorter: true,
    },
    {
      key: ColumnKey.Size,
      title: 'Size',
      dataIndex: ColumnKey.Size,
      render: (size: number) => (
        <>{typeof size !== 'number' ? emptyDataString : normalizeFileSize(size)}</>
      ),
    },
    {
      key: ColumnKey.CreatedOn,
      title: 'Uploaded date',
      dataIndex: ColumnKey.CreatedOn,
      showSorterTooltip: false,
      render: (date: string) => <>{getFormattedDate(date)}</>,
      sorter: true,
      className: 'right-head-title',
      align: 'right',
    },
    !isMobile
      ? {
          key: ColumnKey.Actions,
          title: 'Actions',
          dataIndex: ColumnKey.Actions,
          render: (_: string, row: DocumentSchema) => (
            <>
              {(!row.scanstatus || row.scanstatus === BlobScanStatus.SUCCESS) && (
                <DownloadButton
                  name={row.name || ''}
                  click={downloadClick}
                  downloading={downloading}
                />
              )}
              {((row.isownedbysuperadmin && permission === PermissionsLevel.Full && isSuperAdmin) ||
                (!row.isownedbysuperadmin && permission === PermissionsLevel.Full)) &&
                !row.shared && (
                  <DeleteButton onClick={() => actionClick(row, ActionNames.Delete)} />
                )}
            </>
          ),
          align: 'right',
          className: 'right-head-title',
        }
      : {},
  ];
};
