import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { TagColoring } from './components/TagColoring';

export const PMTagColoring = styled(TagColoring)`
  margin-left: 16px;

  @media (max-width: ${breakpoints.md}) {
    margin-top: 5px;
  }
`;
