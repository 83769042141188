import React from 'react';
import { Empty, TableColumnsType } from 'antd';
import { TableBody, ViewTableColumn } from './styled';
import { RowType } from './CustomTable';

export interface ViewMobileTableProps<T extends RowType> {
  data: {
    rows?: T[];
    count?: number;
  };
  columns: TableColumnsType<T>;
  columnsOrder?: Record<string, number>;
  isEmpty?: boolean;
}

export const ViewMobileTable = <T extends RowType>({
  data,
  columns,
  columnsOrder,
  isEmpty,
}: ViewMobileTableProps<T>) => {
  const action = columns.find((column) => column.key === 'action');

  if (isEmpty) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <TableBody>
      {data.rows?.map((row: T) => {
        return (
          <ViewTableColumn column={1} key={row.id as string}>
            {Object.entries(row)
              .sort((valueA: [string, T[keyof T]], valueB: [string, T[keyof T]]) => {
                if (!columnsOrder) {
                  return 0;
                }

                return columnsOrder[valueA[0]] - columnsOrder[valueB[0]];
              })
              .reduce(
                (acc: React.ReactNode[], [key, value]: [string, T[keyof T]], index: number) => {
                  const column = columns.find((column) => column.key === key);

                  if (column) {
                    return [
                      ...acc,
                      <ViewTableColumn.Item key={key} label={column?.title}>
                        {column.render ? column.render(value, row, index) : value}
                      </ViewTableColumn.Item>,
                    ];
                  }

                  return acc;
                },
                [],
              )}
            {action && (
              <ViewTableColumn.Item label={action?.title}>
                {action.render ? action.render('', row, 0) : ''}
              </ViewTableColumn.Item>
            )}
          </ViewTableColumn>
        );
      })}
    </TableBody>
  );
};
