import { createSlice } from '@reduxjs/toolkit';
import {
  ActiveSecurityIncidentsCountResponse,
  AlarmDetailsResponse,
  CustomerMTTIResponse,
  EventServiceDetailsResponse,
  InvestigationOverviewResponse,
  LicenseUsageSchema,
} from 'services/api';
import { managedDetectionsResponseApi } from '../thunks';

type MDRState = {
  mtti: CustomerMTTIResponse['mtti'];
  EDRStatistic: LicenseUsageSchema;
  activeSecurityIncidents: ActiveSecurityIncidentsCountResponse;
  investigationOverview: InvestigationOverviewResponse;
  alarmDetails: AlarmDetailsResponse;
  monitoredServiceDetails: EventServiceDetailsResponse;
};

const initialState: MDRState = {
  mtti: null,
  EDRStatistic: {},
  activeSecurityIncidents: {},
  investigationOverview: {},
  alarmDetails: {},
  monitoredServiceDetails: {},
};

export const mdr = createSlice({
  name: 'mdr',
  initialState,
  reducers: {},
  extraReducers: {
    [managedDetectionsResponseApi.meanTimeToInvestigate.fulfilled.toString()]: (
      state: MDRState,
      action: { payload: CustomerMTTIResponse },
    ) => {
      state.mtti = action.payload.mtti;
    },
    [managedDetectionsResponseApi.meanTimeToInvestigate.rejected.toString()]: (state: MDRState) => {
      state.mtti = null;
    },
    [managedDetectionsResponseApi.getActiveEdrAgentsStatistic.fulfilled.toString()]: (
      state: MDRState,
      action: { payload: LicenseUsageSchema },
    ) => {
      state.EDRStatistic = action.payload;
    },
    [managedDetectionsResponseApi.getActiveEdrAgentsStatistic.rejected.toString()]: (
      state: MDRState,
    ) => {
      state.EDRStatistic = {};
    },
    [managedDetectionsResponseApi.getActiveSecurityIncidents.fulfilled.toString()]: (
      state: MDRState,
      action: { payload: ActiveSecurityIncidentsCountResponse },
    ) => {
      state.activeSecurityIncidents = action.payload;
    },
    [managedDetectionsResponseApi.getActiveSecurityIncidents.rejected.toString()]: (
      state: MDRState,
    ) => {
      state.activeSecurityIncidents = {};
    },
    [managedDetectionsResponseApi.getInvestigationOverview.fulfilled.toString()]: (
      state: MDRState,
      action: { payload: InvestigationOverviewResponse },
    ) => {
      state.investigationOverview = action.payload;
    },
    [managedDetectionsResponseApi.getInvestigationOverview.rejected.toString()]: (
      state: MDRState,
    ) => {
      state.investigationOverview = {};
    },
    [managedDetectionsResponseApi.getAlarmDetails.fulfilled.toString()]: (
      state: MDRState,
      action: { payload: AlarmDetailsResponse },
    ) => {
      state.alarmDetails = action.payload;
    },
    [managedDetectionsResponseApi.getAlarmDetails.rejected.toString()]: (state: MDRState) => {
      state.alarmDetails = {};
    },
    [managedDetectionsResponseApi.getMonitoredServiceDetails.fulfilled.toString()]: (
      state: MDRState,
      action: { payload: EventServiceDetailsResponse },
    ) => {
      state.monitoredServiceDetails = action.payload;
    },
    [managedDetectionsResponseApi.getMonitoredServiceDetails.rejected.toString()]: (
      state: MDRState,
    ) => {
      state.monitoredServiceDetails = {};
    },
  },
});
