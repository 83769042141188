import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page, SidebarTextBlock } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide11.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
  font-size: 13px;
`;

export const PenetrationTestingText = styled(SidebarTextBlock)`
  top: 160px;
`;

export const Chart = styled.div`
  align-items: center;
  display: flex;
  height: 267px;
  justify-content: center;
  position: absolute;
  right: 90px;
  top: 159px;
  width: 544px;
`;
