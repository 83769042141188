import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { useRowGap } from 'services/hooks/useRowGap';
import { InnerLayout } from 'shared';
import {
  AppDispatch,
  newsNotificationsApi,
  useApp,
  useCustomer,
  useLoading,
  useNewsNotifications,
} from 'store';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';
import { getCardPadding, getHeadStyles, getLayoutConfig } from './helpers';
import { StyledCard } from './styled';
import {
  AdminNotificationDetails,
  NotificationStatusInfo,
  UserNotificationDetails,
} from './components';
import { useGetPermission } from '../../services/hooks';
import { PermissionOptions, PermissionsLevel } from '../../app-constants';

export const NotificationDetails = () => {
  const theme = useTheme();
  const customerId = useCustomer();
  const { gapNumber } = useRowGap();
  const { isMobile } = useApp();
  const dispatch: AppDispatch = useDispatch();
  const { notificationDetails } = useNewsNotifications();
  const { id } = useParams<{ id: string }>();
  const { title, status } = notificationDetails;
  const isFullAccess = useGetPermission(PermissionOptions.Notifications) === PermissionsLevel.Full;
  const notificationStatus = isFullAccess ? status : '';

  const loading = useLoading([newsNotificationsApi.getNotificationDetails.typePrefix]);

  useEffect(() => {
    dispatch(newsNotificationsApi.getNotificationDetails({ customerId, notificationId: id }));
  }, [dispatch, customerId, id]);

  const layoutConfig = useMemo(() => {
    return getLayoutConfig(title, notificationStatus, isFullAccess);
  }, [title, notificationStatus, isFullAccess]);

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} lg={16} xs={24} order={isMobile ? 2 : 1}>
          {isFullAccess ? <AdminNotificationDetails /> : <UserNotificationDetails />}
        </Col>
        <Col span={gapNumber} lg={8} xs={24} order={isMobile ? 2 : 1}>
          <StyledCard
            headStyles={getHeadStyles(isMobile, theme)}
            bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
            cardPadding={getCardPadding(isMobile, theme)}
            showLoader={loading[newsNotificationsApi.getNotificationDetails.typePrefix]}
          >
            <NotificationStatusInfo
              notificationDetails={notificationDetails}
              isNormUser={isFullAccess}
            />
          </StyledCard>
        </Col>
      </Row>
    </InnerLayout>
  );
};
