import { OrderBy } from 'services/api';

export enum ColumnKey {
  Subject = 'subject',
  Date = 'date',
  Action = 'action',
}

export enum TableTitles {
  Subject = 'Subject',
  Date = 'Date',
  Action = 'Action',
}

export const initialOrderBy = {
  field: ColumnKey.Date,
  type: OrderBy.type.DESC,
};
