export const emptyFile = new File([''], '');

export const allowedExtensionsArray = [
  'application/pdf',
  '.docx',
  '.doc',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'image/jpg',
  'image/jpeg',
  'zip',
  'application/zip',
];

export const maxFileSizeLabel = '64 MB';

export const allowedExtensionsString = allowedExtensionsArray.join(',');
export const allowedUploadOnlyPDF = 'application/pdf';

export const chunkSize = 4194304;
