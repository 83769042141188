import React, { PropsWithChildren, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import styled from 'styled-components';

type Props = {
  chartRef: React.RefObject<ReactECharts>;
  height?: string;
  width?: string;
  isLoaded?: boolean;
  score?: null | number;
  onFinished: () => void;
  borderWidth?: number;
  borderRadius?: number;
  className?: string;
};

const Block = styled.div<{ height: string; width: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const ChartWrapper = styled(Block)`
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `${theme.spacing['0.5']} solid ${theme.colors.gray[200]}`};
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  z-index: 999;
`;

const ChildrenWrapper = styled.div`
  visibility: hidden;
`;

const Image = styled.img<{ height: string; width: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export function EChartToImage({
  chartRef,
  children,
  isLoaded,
  onFinished,
  className,
  width = '60px',
  height = '60px',
}: PropsWithChildren<Props>) {
  const [dataUrl, setDataUrl] = useState<string | null>(null);

  useEffect(() => {
    if (isLoaded && chartRef && chartRef.current) {
      const instance = chartRef.current.getEchartsInstance();

      if (instance.getDataURL({ type: 'png' })) {
        setDataUrl(instance.getDataURL({ type: 'png', pixelRatio: 4 }));
        onFinished();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return (
    <ChartWrapper className={className} width={width} height={height}>
      {!!dataUrl && <Image height={height} width={width} src={dataUrl} alt='score' />}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </ChartWrapper>
  );
}
