/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SoftwareDocumentSchema = {
  fileext?: SoftwareDocumentSchema.fileext;
  documenttype?: SoftwareDocumentSchema.documenttype;
  servicetype?: SoftwareDocumentSchema.servicetype;
  name?: string;
  id?: string;
  createdOn?: number;
  size?: number;
  shared?: boolean;
};

export namespace SoftwareDocumentSchema {
  export enum fileext {
    PDF = 'pdf',
    DOCX = 'docx',
    XLSX = 'xlsx',
    JPEG = 'jpeg',
    JPG = 'jpg',
  }

  export enum documenttype {
    OTHER = 'OTHER',
    CONTRACT = 'CONTRACT',
    TEMPLATE = 'TEMPLATE',
    REPORT = 'REPORT',
    SOFTWARE_AGENT = 'SOFTWARE AGENT',
    SOFTWARE_IMAGE = 'SOFTWARE IMAGE',
  }

  export enum servicetype {
    _1 = '1',
    _2 = '2',
    _3 = '3',
  }
}
