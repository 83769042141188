import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  MaintenanceSettingsRequest,
  MaintenanceSettingsService,
  ToggleMaintenanceModeRequest,
} from 'services/api';

export interface IMaintenanceSettingProps {
  customerId: string;
  requestBody?: MaintenanceSettingsRequest;
}

const getMaintenanceSettingsSnackBarText = createAsyncThunk(
  'maintenance-settings/snack-bar-text',
  async () => {
    const response = await MaintenanceSettingsService.getSnackBarText();

    return response;
  },
);

const getMaintenanceSettings = createAsyncThunk(
  'maintenance-settings/get',
  async (customerId: string) => {
    const response = await MaintenanceSettingsService.getMaintenanceSettings({ customerId });

    return response;
  },
);

const updateMaintenanceSettings = createAsyncThunk(
  'update-maintenance-settings/patch',
  async ({ customerId, requestBody }: IMaintenanceSettingProps) => {
    try {
      return await MaintenanceSettingsService.updateMaintenanceSettings({
        customerId,
        requestBody,
      });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const toggleMaintenanceMode = createAsyncThunk(
  'toggle-maintenance-settings/put',
  async (requestBody: ToggleMaintenanceModeRequest & { customerId: string }) => {
    try {
      return await MaintenanceSettingsService.toggleMaintenanceMode({
        customerId: requestBody.customerId,
        requestBody,
      });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const maintenanceSettingsApi = {
  getMaintenanceSettingsSnackBarText,
  getMaintenanceSettings,
  updateMaintenanceSettings,
  toggleMaintenanceMode,
};
