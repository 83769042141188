import React, { useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cybersafetyApi, useApp, useCustomer, useLoading, usePhishingByMonth } from 'store';
import { Routes } from 'services/entities';
import { isEmptyObject } from 'services/helpers';
import { getQueryString } from 'services/api/core/request';
import { Card } from 'shared';
import { PhishingByMonthCustomTooltipContainer } from 'shared/charts/styled';
import { Legend, Line } from 'shared/charts';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { useTheme } from 'styled-components';
import { getOption, getRedirectParams, getSeriesName } from './helpers';
import { TooltipTitles } from '../../constants';
import { ScrollContainer } from '../styled';
import { getCssVar } from '../../../../styles/theme';

export const PhishingByMonth = () => {
  const history = useHistory();
  const chartRef = useRef<ReactECharts>();
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const customerId = useCustomer();
  const dispatch = useDispatch();
  const phishingByMonth = usePhishingByMonth();
  const loading = useLoading([cybersafetyApi.getPhishingByMonth.typePrefix]);

  const phishingByMonthColors = {
    opened: getCssVar('pieChart.CurrentRefresherTestsIgnored'),
    clicked: getCssVar('pieChart.CurrentTrainingStatusStarted'),
    providedCredentials: getCssVar('pieChart.PhishingLast30DaysProvidedCredentials'),
  };

  const phishingByMonthLegendValues = {
    opened: 0,
    clicked: 0,
    providedCredentials: 0,
  };

  const redirectToPhishing = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.seriesName || !params.name) {
        return;
      }

      history.push(
        `${Routes.PEOPLE_PHISHING}${getQueryString(
          getRedirectParams(params.seriesName, params.name),
        )}`,
      );
    },
    [history],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToPhishing(params),
    }),
    [redirectToPhishing],
  );

  React.useEffect(() => {
    dispatch(
      cybersafetyApi.getPhishingByMonth({
        customerId,
        timezoneOffset: new Date().getTimezoneOffset(),
      }),
    );
  }, [dispatch, customerId]);

  return (
    <Card
      title='Phishing by month'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles.PhishingByMonth}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2] }
          : { titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? theme.sizes[6]
          : `${theme.sizes[5]} ${theme.sizes[0]} ${theme.sizes[5]} ${theme.sizes[5]}`
      }
      showLoader={loading[cybersafetyApi.getPhishingByMonth.typePrefix]}
    >
      {phishingByMonth && !isEmptyObject(phishingByMonth) ? (
        <>
          <ScrollContainer>
            <PhishingByMonthCustomTooltipContainer>
              <Line
                values={phishingByMonth}
                getOption={getOption}
                onEvents={onEvents}
                isMobile={isMobile}
                chartRef={(r: ReactECharts) => {
                  chartRef.current = r;
                }}
                height={
                  !isMobile ? { grid: '290px', chart: '350px' } : { grid: '240px', chart: '300px' }
                }
              />
            </PhishingByMonthCustomTooltipContainer>
          </ScrollContainer>

          <Legend
            values={phishingByMonthLegendValues}
            colors={phishingByMonthColors}
            noValues
            direction='row'
            formatName={getSeriesName}
            actionOptions={(name: string) => ({ seriesName: name })}
            marginTop={isMobile ? theme.sizes[4] : undefined}
            chartRef={chartRef}
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
          />
        </>
      ) : (
        emptyMessage('No phishing data yet')
      )}
    </Card>
  );
};
