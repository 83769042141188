import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

export const cyberResilienceScoreTrends = createSlice({
  name: 'cyberResilienceScoreTrends',
  initialState,
  reducers: {},
  extraReducers: {},
});
