import { Filter, OrderBy } from 'services/api';
import { OptionType } from 'shared/Table/types';
import { severityOptions } from 'pages/VulnerabilitiesList/constants';
import { InputType } from '../../../../services/api/models/InputType';

export enum TableTitles {
  Vulnerability = 'Vulnerability',
  Severity = 'Severity',
  Status = 'Status',
}

export enum VulnerabilityStatus {
  Open = 'Open',
}

export const searchSettings = {
  placeholder: 'Search by vulnerability name',
  fields: ['vulnerability'],
  min: 3,
};

export const optionsStatus: OptionType[] = [
  { value: VulnerabilityStatus.Open, label: VulnerabilityStatus.Open },
];

export const filterFields = [
  {
    field: 'severity',
    options: severityOptions,
    label: TableTitles.Severity,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'status',
    options: optionsStatus,
    label: TableTitles.Status,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];

export const initialOrderBy = {
  field: 'severity',
  type: OrderBy.type.DESC,
};
