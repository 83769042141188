import { memo } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { MaintenancePageViewMode, NotFound } from 'pages';
import { Routes as EntitiesRoutes } from 'services/entities';
import { PublicRouteGroup } from './PublicRouteGroup';
import { developmentRoutes, protectedRoutes, publicRoutes } from './config';
import { ProtectedRouteGroup } from './ProtectedRouteGroup';

export const Routes = memo(() => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRouteGroup exact path={publicRoutes.map(({ path }) => path)}>
          {publicRoutes.map(({ path, ...props }) => (
            <Route key={path as string} path={path} {...props} />
          ))}
        </PublicRouteGroup>

        <ProtectedRouteGroup exact path={protectedRoutes.map(({ path }) => path)}>
          {protectedRoutes.map(({ path, ...props }) => (
            <Route key={path as string} path={path} {...props} />
          ))}
        </ProtectedRouteGroup>
        {developmentRoutes.map(({ path, ...props }) => (
          <Route key={path} path={path} {...props} />
        ))}
        <Route
          exact
          path={EntitiesRoutes.MAINTENANCE_PAGE_VIEW_MODE}
          component={MaintenancePageViewMode}
        />
        <Route path='*' component={() => <NotFound />} />
      </Switch>
    </BrowserRouter>
  );
});
