import { Checkbox as AntdCheckbox } from 'antd';
import styled from 'styled-components';
import { Theme } from '../../styles/theme/types';
import { getCssVar } from '../../styles/theme';

export const Checkbox = styled(AntdCheckbox)`
  color: ${getCssVar('textColor')};

  .ant-checkbox {
    &,
    &:hover,
    &:focus {
      .ant-checkbox-inner {
        border-color: ${getCssVar('checkBox.borderColor')};
      }
    }

    .ant-checkbox-inner {
      &,
      &:hover,
      &:focus {
        border-radius: ${({ theme }: { theme: Theme }) => theme.radius.sm};
      }
    }

    &.ant-checkbox-checked {
      &,
      &:hover,
      &:focus {
        .ant-checkbox-inner {
          border-color: ${getCssVar('primary')};
        }
      }
    }

    &:hover,
    &:focus {
      .ant-checkbox-inner {
        border-color: ${getCssVar('checkBox.borderColor')};
      }
    }

    &.ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: ${getCssVar('checkBox.borderColor')} !important;

        &:after {
          border-color: ${getCssVar('checkBox.borderColorAfter')};
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background: ${getCssVar('checkBox.bg')};
        }
      }

      &.ant-checkbox {
        &:after {
          display: none;
        }
      }
    }

    &:after {
      border: none;
      position: absolute;
      content: '';
      left: -7px;
      top: -7px;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      border-radius: ${({ theme }: { theme: Theme }) => theme.radius.full};
      background: ${getCssVar('checkBox.bg')}20;
      visibility: visible;
      transition: all 0.1s ease-out;
      animation: none;
      opacity: 0;
    }
  }

  &.hover,
  &:hover,
  &:focus,
  &.active,
  &:active {
    .ant-checkbox {
      &:after {
        opacity: 1;
      }
    }
  }

  &.active,
  &:active {
    .ant-checkbox {
      &:after {
        background: ${getCssVar('checkBox.bg')}33;
      }
    }
  }
`;
