import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  newsNotificationsApi,
  useCustomer,
  useNewsNotifications,
} from '../../../store';
import { Notification } from '../../../services/api';

export const useUpdateData = (paramId: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const customerId = useCustomer();
  const { notificationDetails } = useNewsNotifications();

  useEffect(() => {
    dispatch(newsNotificationsApi.getNotificationDetails({ customerId, notificationId: paramId }));
  }, [customerId, dispatch, paramId]);

  if (paramId !== notificationDetails.id) {
    return {};
  }

  return notificationDetails as Notification;
};
