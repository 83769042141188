import { useCallback, useState } from 'react';
import { Tooltip } from 'antd';

type CroppedTextProps = {
  text: string;
  maxLength?: number;
  withTooltip?: boolean;
};
const maxLengthCharts = 100;

export const CroppedText = ({
  text,
  maxLength = maxLengthCharts,
  withTooltip = false,
}: CroppedTextProps): JSX.Element => {
  const [isExtended, setIsExtended] = useState<boolean>(false);
  const toggleTextLength = useCallback(() => setIsExtended((prev) => !prev), []);

  if (maxLength >= text.length) {
    return <span>{text}</span>;
  }

  if (withTooltip) {
    return (
      <Tooltip title={text} placement='top'>
        {text.substring(0, maxLength)}...
      </Tooltip>
    );
  }

  return (
    // eslint-disable-next-line
    <span onClick={toggleTextLength} style={{ cursor: 'pointer' }}>
      {isExtended ? text : `${text.substring(0, maxLength)}...`}
    </span>
  );
};
