import { createSlice } from '@reduxjs/toolkit';
import { EdrResponse, EdrTrendBySource } from 'services/api';
import { managedDetectionsResponseApi } from '../thunks';

type ActiveEDRAgentsState = {
  activeEDRAgents: {
    count?: number;
    trends?: Array<EdrTrendBySource>;
  };
};

type ActiveEDRAgentsAction = {
  payload: EdrResponse;
};

const initialState: ActiveEDRAgentsState = { activeEDRAgents: {} };

export const activeEDRAgents = createSlice({
  name: 'activeEDRAgents',
  initialState,
  reducers: {},
  extraReducers: {
    [managedDetectionsResponseApi.activeEDRAgents.fulfilled.toString()]: (
      state: ActiveEDRAgentsState,
      action: ActiveEDRAgentsAction,
    ) => {
      state.activeEDRAgents = action.payload || { count: 0, trends: [] };
    },
    [managedDetectionsResponseApi.activeEDRAgents.rejected.toString()]: (
      state: ActiveEDRAgentsState,
    ) => {
      state.activeEDRAgents = { count: 0, trends: [] };
    },
  },
});
