import moment from 'moment';
import React from 'react';
import { LicenseUsageSchema, MonthlyReportResponse } from 'services/api';
import { IDate } from 'services/interfaces';
import { ReportPageLayout } from '../components/ReportPageLayout';
import { ReportTableSection } from '../components/ReportTableSection';

interface ThKeys {
  [key: string]: LicenseUsageSchema | undefined;
}

interface LicenseUsageProp extends ThKeys {
  cyberSafetyAndPhishing?: LicenseUsageSchema;
  vulnerabilityAgents?: LicenseUsageSchema;
  threatDetectionAgents?: LicenseUsageSchema;
  threatDetectionServices?: LicenseUsageSchema;
}

export type RowTitle = Record<string, string>;
const tableScoreColTitle = ['Service', 'Used', 'Current Limit'];
const tableScoreRowsTitle: RowTitle = {
  vulnerabilityAgents: 'Vulnerability Agents',
  threatDetectionServices: 'Threat Detection (Services)',
  cyberSafetyAndPhishing: 'Cyber Safety and Phishing',
  threatDetectionAgents: 'Threat Detection Agents',
};

export const PdfPage5 = ({
  data: { licenseUsage },
  onChartLoaded,
  reportDate,
}: {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  reportDate: IDate;
}) => {
  const getTrData = (titleTr: RowTitle, valuesTr: LicenseUsageProp) => {
    return Object.keys(titleTr).map((key: string) => {
      return {
        ...valuesTr[key],
        title: titleTr[key],
      };
    });
  };

  return (
    <ReportPageLayout
      headerSubtitle={`Your ${moment(reportDate.startDate).format(
        'MMMM YYYY',
      )} update. Your Contacts, Scope of this Report.`}
    >
      <ReportTableSection onLoad={onChartLoaded} title='Your contacts'>
        <p>
          For any incidents, service requests, or general queries, your first point of call should
          be the <b>norm</b> service desk.
        </p>

        <table className='table table-border-hr mb-2'>
          <tbody>
            <tr>
              <td width='140'>Tier 1 Support</td>
              <td>
                <p>
                  <a href='mailto:support@normcyber.com' target='_blank' rel='noreferrer'>
                    support@normcyber.com
                  </a>
                </p>
              </td>
              <td width='140'>+44 (0) 20 3855 6225</td>
            </tr>
          </tbody>
        </table>
        <div className='my-2'>
          <p>
            The <b>norm</b> team assigned to your organisation is listed below, and each has their
            own understanding of your organisation relative to their business role:
          </p>
        </div>
        <table className='table table-border-hr mb-2'>
          <tbody>
            <tr>
              <td width='140'>Customer Experience</td>
              <td>
                <a href='mailto:customer.experience@normcyber.com'>
                  customer.experience@normcyber.com
                </a>
              </td>
              <td width='140'>+44 (0) 20 3855 6215</td>
            </tr>
            <tr>
              <td>Your Focal Analyst</td>
              <td>
                <a href='mailto:@normcyber.com'>@normcyber.com</a>
              </td>
              <td>+44 (0) 20</td>
            </tr>
            <tr>
              <td>Your Account Manager</td>
              <td>
                <a href='mailto:@normcyber.com'>@normcyber.com</a>
              </td>
              <td>+44 (0) 20</td>
            </tr>
          </tbody>
        </table>

        <p>
          More information about your <b>norm</b> contacts as well as their roles and
          responsibilities can be found in your customer handbook. Should you need to escalate a
          query, the <b>norm </b>escalation schedules are available in the same handbook, or on
          request from any of the contacts listed above.
        </p>
      </ReportTableSection>

      <ReportTableSection onLoad={onChartLoaded} title='Scope of this Report'>
        <div className='mb-2'>
          <p>
            This report covers the areas of your organisation that norm has visibility of. The data
            used to compile this report was collected between the 1st{' '}
            {moment(reportDate.startDate).format('MMMM YYYY')} and the 1st{' '}
            {moment(reportDate.startDate).add(1, 'M').format('MMMM YYYY')}
          </p>
        </div>
        <table className='table table-border table-sr'>
          <thead>
            <tr>
              <th colSpan={3} className='text-center'>
                <p className='text-bold'> License Usage</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {tableScoreColTitle.map((item, index) => (
                <th key={item} className={`text-bold${index > 0 && ' text-center'}`}>
                  {item}
                </th>
              ))}
            </tr>
            {licenseUsage &&
              getTrData(tableScoreRowsTitle, licenseUsage).map((item) => (
                <tr key={item.title}>
                  <td>{item.title}</td>
                  <td
                    className={`text-center${
                      (item.used && item.currentLimit && item.used < item.currentLimit
                        ? ' green'
                        : ' red') || ''
                    }`}
                  >
                    {item.used || 0}
                  </td>
                  <td className='text-center'>{item.currentLimit || 0}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </ReportTableSection>
    </ReportPageLayout>
  );
};
