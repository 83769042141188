import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetsListResponse } from 'services/api';
import { patchManagementApi } from './thunks';

interface InitialState {
  data: AssetsListResponse;
}

const initialState: InitialState = {
  data: {},
};

const extraReducers = {
  [patchManagementApi.getAssetsList.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<AssetsListResponse>,
  ) => {
    state.data = action.payload;
  },
  [patchManagementApi.getAssetsList.rejected.toString()]: (state: InitialState) => {
    state.data = {};
  },
};

export const patchManagementAssets = createSlice({
  name: 'patchManagementAssets',
  initialState,
  reducers: {},
  extraReducers,
});
