import { Filter } from 'services/api';
import { initialPagination } from 'app-constants';
import { initialOrderBy } from 'pages/PeoplePhishing/constants';
import { getTrainingProgressStatusFilterValue } from 'pages/People/helpers';

export const getRedirectParams = (key: string) => ({
  orderBy: initialOrderBy,
  pagination: initialPagination,
  filter: {
    fields: [
      {
        name: 'trainingProgressStatus',
        value: [getTrainingProgressStatusFilterValue(key)],
        type: Filter.type.MULTIPLE,
      },
    ],
  },
});
