import React from 'react';
import { Icon } from 'shared';
import { icons } from 'styles/theme';
import { useTheme } from 'styled-components';
import { Status } from '../constants';

type IconColoringProps = {
  status: string;
};

export const IconColoring = ({ status }: IconColoringProps) => {
  const theme = useTheme();

  const statusType = (status: string) => {
    switch (status) {
      case Status.InProgress:
        return theme.colors.yellow[500];
      case Status.Completed:
        return theme.colors.green[500];
      case Status.ToStart:
        return theme.colors.green[200];
      default:
        return theme.colors.yellow[500];
    }
  };

  return (
    <Icon
      component={icons.fiber_manual_record}
      color={statusType(status)}
      dimension='10px'
      margin='0 8px 2px 0'
    />
  );
};
