/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MitreTechniquesEnum {
  RECONNAISSANCE = 'Reconnaissance',
  RESOURCE_DEVELOPMENT = 'Resource Development',
  INITIAL_ACCESS = 'Initial Access',
  EXECUTION = 'Execution',
  PERSISTENCE = 'Persistence',
  PRIVILEGE_ESCALATION = 'Privilege Escalation',
  DEFENSE_EVASION = 'Defense Evasion',
  CREDENTIAL_ACCESS = 'Credential Access',
  DISCOVERY = 'Discovery',
  LATERAL_MOVEMENT = 'Lateral Movement',
  COLLECTION = 'Collection',
  COMMAND_AND_CONTROL = 'Command and Control',
  EXFILTRATION = 'Exfiltration',
  IMPACT = 'Impact',
}
