import styled from 'styled-components';
import AntdIcon from '@ant-design/icons';
import { Theme } from 'styles/theme/types';
import { FunctionComponent } from 'react';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type Props = {
  dimension?: string;
  width?: string;
  height?: string;
  margin?: string;
  verticalAlign?: string;
  theme?: Theme;
};

export const Icon: FunctionComponent<Props & IconComponentProps> = styled(AntdIcon)<Props>`
  font-size: 24px;
  height: ${(props) => props.dimension || props.height || props.theme.sizes[6]};
  margin: ${(props) => props.margin};
  vertical-align: ${(props) => props.verticalAlign || 'middle'};
  width: ${(props) => props.dimension || props.width || props.theme.sizes[6]};

  svg {
    fill: ${(props) => props.color};
    height: ${(props: Props) => props.dimension};
    width: ${(props: Props) => props.dimension};
  }
`;
