import { spacing } from '../spacing';
import { fontWeights } from '../fontWeights';
import { colors } from '../colors';

export const button = {
  fontSize: `${spacing[4]}`,
  fontWeight: `${fontWeights.bold}`,
  textTransform: 'none',
  backgroundColor: `${colors.brown[500]}`,
  color: `${colors.white}`,
  border: 'none',
  borderRadius: '12px 0px',

  hover: {
    backgroundColor: `${colors.brown[500]}`,
    color: `${colors.white}`,
    transform: `translateY(-${spacing[1]})`,
    boxShadow: `0px ${spacing[1]} 0 ${colors.black}`,
  },

  active: {
    backgroundColor: `${colors.brown[400]}`,
    color: `${colors.white}`,
  },
};

export const buttonDisabled = {
  backgroundColor: `${colors.brown[100]}`,
  color: colors.white,
};

export const buttonSecondary = {
  backgroundColor: `${colors.white}`,
  color: `${colors.black}`,
  border: `2px solid ${colors.black}`,
  borderRadius: '12px 0px',

  hover: {
    backgroundColor: `${colors.gray[100]}`,
    color: `${colors.black}`,
    borderColor: `${colors.black}`,
  },

  active: {
    backgroundColor: `${colors.gray[300]}`,
    color: `${colors.black}`,
    borderColor: `${colors.black}`,
  },

  disabled: {
    backgroundColor: `${colors.white}`,
    color: `${colors.brown[100]}`,
    borderColor: `${colors.brown[100]}`,
  },
};

export const buttonDanger = {
  backgroundColor: `${colors.red[700]}`,
  color: `${colors.white}`,

  active: {
    backgroundColor: `${colors.red[400]}`,
  },

  disabled: {
    backgroundColor: `${colors.brown[100]}`,
    color: `${colors.white}`,
  },
};

export const buttonSimple = {
  borderColor: `${colors.yellow[100]}`,

  hover: {
    backgroundColor: `${colors.gray[100]}`,
    color: `${colors.black}`,
    borderColor: `${colors.yellow[100]}`,
  },

  active: {
    backgroundColor: `${colors.gray[300]}`,
    borderColor: `${colors.yellow[100]}`,
    color: `${colors.black}`,
  },

  disabled: {
    backgroundColor: `${colors.white}`,
    color: `${colors.brown[100]}`,
  },
};

export const buttonPrimaryOrDangerDisabled = {
  backgroundColor: `${colors.brown[100]}`,
  color: `${colors.white}`,
  borderColor: `${colors.brown[100]}`,
};
