import styled, { css } from 'styled-components';
import { ThemeProps } from '../../services/interfaces';
import { getCssVar } from '../../styles/theme';

export const PageContainer = styled.div<ThemeProps>`
  background-color: black;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  ${({ theme }) =>
    theme.colorSet.notFoundBackImg &&
    css`
      background-image: ${({ theme }) => `url(${theme.colorSet.notFoundBackImg})`};
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export const BlockContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Code404Wrapper = styled.div<ThemeProps>`
  align-items: center;
  backdrop-filter: blur(15px);
  background: ${getCssVar('notFoundWrapperBackground')};
  border: 8px solid ${getCssVar('notFoundBorder')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 240px;
  padding: 32px 64px 34px 64px;

  a {
    color: ${getCssVar('notFoundBackLinkColor')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 120px;
  }

  h2 {
    color: ${getCssVar('notFoundTextColor')};
    font-size: ${getCssVar('notFoundFontSize')};
    font-weight: ${getCssVar('notFoundFontWeight')};
    line-height: ${getCssVar('notFoundlineHeight')};
    margin-bottom: 10px;
    text-transform: uppercase;
  }
`;

export const Code404 = styled.div`
  color: ${getCssVar('notFoundTextColor')};
  font-size: 120px;
  font-weight: 900;
  line-height: 120px;
  margin-bottom: 8px;
`;

export const Logo = styled.img`
  margin-bottom: 80px;
`;
