import { OptionType } from 'shared/Table/types';
import { colors } from 'styles/theme';
import { Filter, OrderBy, PhishingActivitySchema } from 'services/api';
import { InputType } from '../../services/api/models/InputType';

export const searchSettings = {
  placeholder: 'Search by name',
  fields: ['name'],
  min: 3,
};

export enum TableTitles {
  Name = 'Name',
  PhishingEmailsSent = 'Phishing emails sent',
  PhishingActivity = 'Phishing activity',
  ReportedPhishingTests = 'Reported phishing tests',
  TrainingProgress = 'Training progress',
  RefresherTestStatus = 'Refresher test status',
  TrainingConfidence = 'Training confidence',
  Certified = 'Certified date',
  Subject = 'Subject',
  Status = 'Status',
  Sent = 'Sent',
  EventDate = 'Event date',
  PhishingDate = 'Phishing date',
}

export enum ColumnKey {
  Name = 'name',
  PhishingEmailsSent = 'phishingEmailsSent',
  PhishingActivity = 'phishingActivity',
  ReportedPhishingTests = 'reportedPhishingTests',
  TrainingProgress = 'trainingProgress',
  TrainingConfidence = 'trainingConfidence',
  CertifiedDate = 'certifiedDate',
  Subject = 'subject',
  Status = 'status',
  Sent = 'sent',
  EventDate = 'eventDate',
}

export enum PhishingActivity {
  noActions = 'No actions',
  Opened = 'Opened',
  Clicked = 'Clicked',
  providedCredentials = 'Provided credentials or downloaded an attachment',
}

export const phishingActivityOptions: OptionType[] = [
  { value: PhishingActivitySchema.OTHER, label: PhishingActivity.noActions },
  { value: PhishingActivitySchema.LOW, label: PhishingActivity.Opened },
  { value: PhishingActivitySchema.MEDIUM, label: PhishingActivity.Clicked },
  { value: PhishingActivitySchema.HIGH, label: PhishingActivity.providedCredentials },
];

export enum TrainingProgress {
  Completed = 'Completed',
  InProgress = 'In progress',
  NotStarted = 'Not started',
}

export enum PhishingActivityPriority {
  High = 3,
  Medium = 2,
  Low = 1,
  No = 0,
}

export const trainingProgressOptions: OptionType[] = [
  { value: TrainingProgress.Completed, label: TrainingProgress.Completed },
  { value: TrainingProgress.InProgress, label: TrainingProgress.InProgress },
  { value: TrainingProgress.NotStarted, label: TrainingProgress.NotStarted },
];

export enum RefresherTestStatus {
  Passed = 'Passed',
  Ignored = 'Ignored',
  Failed = 'Failed',
}

export const refresherTestStatusOptions: OptionType[] = [
  { value: TrainingProgress.Completed, label: RefresherTestStatus.Passed },
  { value: TrainingProgress.InProgress, label: RefresherTestStatus.Ignored },
  { value: TrainingProgress.NotStarted, label: RefresherTestStatus.Failed },
];

export const filterFields = [
  {
    field: 'phishingActivity',
    options: phishingActivityOptions,
    label: TableTitles.PhishingActivity,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'trainingProgressStatus',
    options: trainingProgressOptions,
    label: TableTitles.TrainingProgress,
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'phishingDate',
    label: TableTitles.PhishingDate,
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
  {
    field: 'certifiedDate',
    label: TableTitles.Certified,
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export const initialOrderBy = {
  field: 'phishingActivity',
  type: OrderBy.type.DESC,
};

export const barColors = {
  noActions: colors.green[300],
  opened: colors.blue[100],
  clicked: colors.yellow[500],
  providedCredentialsOrDownloadedAnAttachment: colors.red[400],
};

export const completed = 100;
