import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { auditApi } from './thunks';

const initialState = {
  data: {},
  actionTypes: [] as string[],
  isPending: false,
};

type StateType = typeof initialState;

export const audit = createSlice({
  name: 'audit',
  initialState,
  reducers: {},
  extraReducers: {
    [auditApi.getAuditLogs.pending.toString()]: (state: StateType) => {
      state.isPending = true;
    },
    [auditApi.getAuditLogs.rejected.toString()]: (state: StateType) => {
      state.isPending = false;
    },
    [auditApi.getAuditLogs.fulfilled.toString()]: (state: StateType, action) => {
      state.isPending = false;
      state.data = action.payload;
    },
    [auditApi.getAuditLogs.rejected.toString()]: (state: StateType) => {
      state.isPending = false;
      state.data = {};
    },

    [auditApi.getAuditActionTypes.rejected.toString()]: (state: StateType) => {
      state.actionTypes = [];
    },
    [auditApi.getAuditActionTypes.fulfilled.toString()]: (
      state: StateType,
      action: PayloadAction<{ actionTypes: string[] }>,
    ) => {
      state.actionTypes = action.payload.actionTypes;
    },
  },
});
