import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Layout as AntdLayout } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Box, Icon } from 'shared';
import { app, useApp, useAuth } from 'store';
import { useScreenSizes } from 'services/hooks/useScreenSizes';
import { Routes, TwoFASteps } from 'services/entities';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { getLoginRightBackground } from 'themes/helpers';
import { Themes } from 'themes/types';
import { getCssVar } from 'styles/theme';
import { AltLink } from './types';
import { Body, Content, Header, InputsCol, Layout, Row, SubTitle, Title } from './components';
import { Smartbloc } from './components/Smartbloc';

import { LogoIcon } from './Outer.styled';

export type OuterLayoutProps = {
  title?: string | string[] | JSX.Element;
  subTitle?: string | React.ReactNode;
  altLink?: AltLink;
  status?: React.ReactNode;
};

export const OuterLayout = ({
  title,
  subTitle,
  altLink,
  status,
  children,
}: PropsWithChildren<OuterLayoutProps>) => {
  const history = useLocation();
  const FORM_COLS_MAX = 22;

  const dispatch = useDispatch();

  const theme = useTheme();
  const { themeName } = useApp();
  const {
    twofa: { step },
  } = useAuth();
  const [isDesktop, isTablet, isMobile, isLargeDesktop] = useScreenSizes();

  const siderWidth = isDesktop ? theme.sizes['2xl'] : theme.sizes.full;

  React.useEffect(() => {
    if (
      isDesktop !== undefined &&
      isTablet !== undefined &&
      isMobile !== undefined &&
      isLargeDesktop !== undefined
    ) {
      dispatch(app.actions.setScreenSizes({ isLargeDesktop, isDesktop, isTablet, isMobile }));
    }
  }, [isLargeDesktop, isDesktop, isTablet, isMobile, dispatch]);

  const smartBlocIconColor =
    !isDesktop || themeName === Themes.DarkNorm ? theme.colors.white : theme.colors.blue[900];

  return (
    <Layout>
      <Sider width={siderWidth}>
        <Layout>
          {!isDesktop &&
            getLoginRightBackground({
              themeName,
              isMobile,
              width: theme.sizes.full,
              isTablet,
            })}

          <Header>
            <Row justify='space-between'>
              <Col>
                <LogoIcon component={theme.icons.norm} color={smartBlocIconColor} />
              </Col>
            </Row>
          </Header>
          <Content align='middle'>
            <Box>
              {status && (
                <Row justify='center' align='bottom' height={theme.sizes[20]}>
                  <Col>{status}</Col>
                </Row>
              )}

              {history.pathname === Routes.ROOT &&
                getCssVar('login.formLogo') &&
                step === TwoFASteps.INITIAL && (
                  <Smartbloc isMobile={isMobile} isTablet={isTablet} />
                )}

              <Row justify='center' align='middle'>
                <Col xs={FORM_COLS_MAX}>
                  {title && (
                    <Title>
                      {Array.isArray(title) ? (
                        title.map((line) => (
                          <React.Fragment key={line}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                      ) : (
                        <Box
                          mb={`-${theme.spacing[3]}`}
                          color={!isDesktop ? theme.colors.white : getCssVar('textColor')}
                          lineHeight={getCssVar('login.titleLineHeight')}
                          fontSize={getCssVar('login.titleFontSize')}
                          fontWeight={theme.colorSet.login.titleFontWeight}
                          minW='65px'
                          letterSpacing='0.01em'
                        >
                          {title}
                        </Box>
                      )}
                    </Title>
                  )}
                </Col>
              </Row>
              <Row align='middle' justify='center'>
                <InputsCol>
                  {subTitle && <SubTitle isMobile={!isDesktop}>{subTitle}</SubTitle>}
                  <Body altLink={altLink}>
                    <Box color={!isDesktop ? theme.colors.white : getCssVar('textColor')}>
                      {children}
                    </Box>
                  </Body>
                </InputsCol>
              </Row>
            </Box>
          </Content>
          {theme.colorSet.login.footerLogo && (
            <footer
              style={{
                position: 'absolute',
                bottom: '40px',
                right: '40px',
              }}
            >
              <Icon
                component={
                  isTablet || isMobile
                    ? theme.colorSet.login.footerLogoMobile
                    : theme.colorSet.login.footerLogo
                }
                width={getCssVar('login.footerLogoWidth')}
                height={getCssVar('login.footerLogoHeight')}
                color={getCssVar('login.footerLogoColor')}
              />
            </footer>
          )}
        </Layout>
      </Sider>

      <AntdLayout.Content>
        {isDesktop &&
          getLoginRightBackground({
            themeName,
            isMobile,
            width: `calc(${theme.sizes.full} - ${siderWidth})`,
          })}
      </AntdLayout.Content>
    </Layout>
  );
};
