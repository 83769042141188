import React from 'react';
import { Tooltip } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'qrcode';
import { Box, Icon } from 'shared';
import { useTheme } from 'styled-components';
import { TwoFAStep } from '../TwoFAStep';
import { ButtonCopy } from '../styled';
import { useApp } from '../../../../store';

type Props = {
  data: {
    base32: string;
    url: string;
  };
};

export const SecondStep = ({ data }: Props) => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const [isCopied, setIsCopied] = React.useState(false);
  const [qrcode, setQrcode] = React.useState('');

  const handleCopy = (_: string, result: boolean) => {
    const TIMEOUT = 2000;

    setIsCopied(result);
    setTimeout(() => setIsCopied(false), TIMEOUT);
  };

  React.useEffect(() => {
    async function getQrcode() {
      const result = await QRCode.toDataURL(data.url);

      setQrcode(result);
    }

    getQrcode();
  }, [data.url]);

  return (
    <TwoFAStep
      title={
        <Box fontSize={theme.sizes[4]} fontWeight={theme.fontWeights.bold}>
          Step 2. Scan this QR code
        </Box>
      }
      description={
        <>
          <Box mb={theme.spacing[3]}>- Tap the "+" icon in your authenticator app</Box>
          <Box>
            - Scan the QR code below using your device's camera or choose manual entry option and
            enter key below
          </Box>
        </>
      }
    >
      <Box d='flex' justify='space-around' align='center' my={theme.spacing[4]}>
        <Box w='50%' minW='200px' fontWeight={theme.fontWeights.black}>
          <Box fontSize={isMobile ? '16px' : '18px'} mt={theme.spacing[4]} whiteSpace='nowrap'>
            {data.base32}
          </Box>

          <Box mt={theme.spacing[1]}>
            <Tooltip title='Copied' visible={isCopied} placement='bottom'>
              <CopyToClipboard text={data.base32} onCopy={handleCopy}>
                <Box d='flex' justify='center' align='center'>
                  <ButtonCopy type='link'>
                    <Icon component={theme.icons.clipboard} />
                    Copy
                  </ButtonCopy>
                </Box>
              </CopyToClipboard>
            </Tooltip>
          </Box>
        </Box>
        <img src={qrcode} alt='qr_code' />
      </Box>
    </TwoFAStep>
  );
};
