import { useEffect, useCallback, useState } from 'react';
import { Row } from 'antd';
import { useDispatch } from 'react-redux';
import { InnerLayout } from 'shared';
import { useSecuritySettings, securitySettingsApi, useCustomer, useLoading } from 'store';
import { useGetPermission } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { getLayoutConfig } from './helpers';
import { SettingsViewMode, SettingsEditMode } from './components';
import { StyledCard, ColStyled } from './styled';

const GUTTER = 32;

export const SecuritySettings = () => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const { settings } = useSecuritySettings();
  const customer = useCustomer();

  const loading = useLoading([securitySettingsApi.securitySettingsContent.typePrefix]);

  const showEditMode = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const hideEditMode = useCallback(() => {
    setIsEditMode(false);
  }, []);

  const permission = useGetPermission(PermissionOptions.SecuritySettings);

  const isEditModeBtnShown = !isEditMode && permission === PermissionsLevel.Full;

  const layoutConfig = getLayoutConfig(showEditMode, isEditModeBtnShown);

  useEffect(() => {
    dispatch(securitySettingsApi.securitySettingsContent({ customerId: customer }));
  }, [dispatch, customer]);

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[GUTTER, GUTTER]}>
        <ColStyled lg={24} xs={24}>
          <StyledCard showLoader={loading[securitySettingsApi.securitySettingsContent.typePrefix]}>
            {isEditMode ? (
              <SettingsEditMode
                hideEditMode={hideEditMode}
                settings={settings}
                customerId={customer}
              />
            ) : (
              <SettingsViewMode
                settings={settings}
                loading={loading[securitySettingsApi.securitySettingsContent.typePrefix]}
              />
            )}
          </StyledCard>
        </ColStyled>
      </Row>
    </InnerLayout>
  );
};
