import { createSlice } from '@reduxjs/toolkit';
import { EventsByServicesResponse } from 'services/api';
import { managedDetectionsResponseApi } from '../thunks';

type EventsByServicesState = {
  eventsByServices: Array<{
    count?: number;
    name?: string;
  }>;
  activeServicesCount: number;
};

type EventsByServicesAction = {
  payload: EventsByServicesResponse;
};

const initialState: EventsByServicesState = {
  eventsByServices: [],
  activeServicesCount: 0,
};

export const eventsByServices = createSlice({
  name: 'eventsByServices',
  initialState,
  reducers: {},
  extraReducers: {
    [managedDetectionsResponseApi.eventsByServices.fulfilled.toString()]: (
      state: EventsByServicesState,
      action: EventsByServicesAction,
    ) => {
      state.eventsByServices = action.payload.groupedFortisiemServicesEvents || [];
    },
    [managedDetectionsResponseApi.eventsByServices.rejected.toString()]: (
      state: EventsByServicesState,
    ) => {
      state.eventsByServices = initialState.eventsByServices;
    },

    [managedDetectionsResponseApi.activeServices.fulfilled.toString()]: (
      state: EventsByServicesState,
      action: EventsByServicesAction,
    ) => {
      state.activeServicesCount = action.payload.groupedFortisiemServicesEvents?.length || 0;
    },
    [managedDetectionsResponseApi.activeServices.rejected.toString()]: (
      state: EventsByServicesState,
    ) => {
      state.activeServicesCount = initialState.activeServicesCount;
    },
  },
});
