import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, managedDetectionsResponseApi, useApp, useCustomer, useLoading } from 'store';
import { Box, Button, Card, Icon } from 'shared';
import {
  ManagedDetectionsResponseTitles,
  mdrBackRoute,
  tooltipTitles,
  viewMoreConfig,
} from 'pages/ThreatDetection/constants';
import { useTheme } from 'styled-components';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router';
import { getQueryString } from 'services/api/core/request';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { GradientBar } from 'shared/charts';
import { Routes } from 'services/entities';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { useGetPermissions } from 'services/hooks';
import { ButtonWrapper, ChartWrapper } from './styled';
import { GraphPopup } from './components/GraphPopup';
import { LicenseRequest } from './components/LicenseRequest';
import { Empty } from '../MTTI/styled';

export const ActiveEdrAgents = () => {
  const permissions = useGetPermissions([PermissionOptions.CMDB]);
  const theme = useTheme();
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const history = useHistory();
  const dispatch = useDispatch();
  const { used = 0, currentLimit = 0, filterDate } = useSelector(
    ({ mdr: { EDRStatistic } }: AppState) => EDRStatistic,
  );

  const [isVisibleGraph, setIsVisibleGraph] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const loading = useLoading([managedDetectionsResponseApi.getActiveEdrAgentsStatistic.typePrefix]);

  const hasCMDBPermission = permissions.cmdb !== PermissionsLevel.NoAccess;

  React.useEffect(() => {
    dispatch(
      managedDetectionsResponseApi.getActiveEdrAgentsStatistic({
        customerId,
      }),
    );
  }, [customerId, dispatch]);

  const onChartClickHandler = () => {
    history.push(
      `${Routes.CMDB}${getQueryString(viewMoreConfig.activeEDRAgents(filterDate))}`,
      mdrBackRoute,
    );
  };

  const preposition = used > currentLimit ? 'Out of' : 'Of';

  const customText = `${preposition} ${currentLimit}`;

  return (
    <>
      <Card
        title={ManagedDetectionsResponseTitles.ActiveEDRAgents}
        tooltipTitle={tooltipTitles.activeEDRAgents}
        subTitle={<SubTitle boldText={used} customText={customText} />}
        showDivider={false}
        headStyles={
          !isMobile
            ? { pt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
            : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
        }
        bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
        cardPadding={
          !isMobile
            ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
            : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
        }
        showLoader={loading[managedDetectionsResponseApi.getActiveEdrAgentsStatistic.typePrefix]}
      >
        <Box mt='25px'>
          {used ? (
            <Tooltip title={hasCMDBPermission && 'Click to view installed devices'} placement='top'>
              <ChartWrapper
                hasCursor={hasCMDBPermission}
                onClick={() => hasCMDBPermission && onChartClickHandler()}
              >
                <GradientBar height='30px' value={used} max={currentLimit} />
              </ChartWrapper>
            </Tooltip>
          ) : (
            <Empty>No active EDR agents data yet</Empty>
          )}
          <ButtonWrapper>
            <Button type='link' onClick={() => setIsVisibleGraph(true)}>
              View installations over time
              <Icon component={theme.icons.arrow_right_alt} />
            </Button>
            <Button type='primary' onClick={() => setIsVisibleModal(true)}>
              Order licenses
            </Button>
          </ButtonWrapper>
        </Box>
      </Card>
      {isVisibleGraph && <GraphPopup isVisible={isVisibleGraph} setIsVisible={setIsVisibleGraph} />}
      {isVisibleModal && (
        <LicenseRequest isVisible={isVisibleModal} setIsVisible={setIsVisibleModal} />
      )}
    </>
  );
};
