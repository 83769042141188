import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { getFormattedDate, getRenderValue } from 'services/helpers';
import { AuditLogSchema } from 'pages/FindingsVulnerabilityDetails/data/types';
import { Box } from 'shared';
import { fontWeights, sizes } from 'styles/theme';
import { Link } from 'react-router-dom';
import { Routes } from 'services/entities';
import { NO_VALUE_LABEL } from 'app-constants';
import { ColumnKey, TableTitles } from './constants';

export const columns: ColumnsType<AuditLogSchema> = [
  {
    key: ColumnKey.Subject,
    title: TableTitles.Subject,
    dataIndex: ColumnKey.Subject,
    render: (subject: { name?: string; email?: string }) =>
      subject.email ? (
        <>
          <Link to={Routes.USERS}>{subject.name}</Link>
          <Box fontSize={sizes['3.5']} opacity='0.5' fontWeight={fontWeights.light}>
            {subject.email}
          </Box>
        </>
      ) : (
        <>{getRenderValue(subject.name)}</>
      ),
    sorter: false,
  },
  {
    key: ColumnKey.Date,
    title: TableTitles.Date,
    dataIndex: ColumnKey.Date,
    render: (date: number) => <Box w='160px'>{getFormattedDate(date)}</Box>,
    sorter: false,
    width: '160px',
    align: 'right',
    className: 'right-head-title',
  },
  {
    key: ColumnKey.Action,
    title: TableTitles.Action,
    dataIndex: ColumnKey.Action,
    render: (text: string) => <>{getRenderValue(text, NO_VALUE_LABEL)}</>,
    sorter: false,
    align: 'left',
  },
];
