import { Tag, UcFirstLetter } from 'shared';
import { TagProps } from 'antd';
import { Severity } from 'services/constants';
import { getCssVar } from '../../styles/theme';
// import { PriorityLabel } from '../../pages/CmdbHostDetails/components/AssetOwner/constants';

type CustomTagProps = TagProps & {
  title: string;
};

export const TagColoring = ({ title, ...TagProps }: CustomTagProps) => {
  const tagType = (title: string) => {
    switch (title) {
      case Severity.High:
        // case PriorityLabel.High:
        return getCssVar('severity.high');
      case Severity.Medium:
        // case PriorityLabel.Medium:
        return getCssVar('severity.meduim');
      case Severity.Low:
        // case PriorityLabel.Low:
        return getCssVar('severity.low');

      case Severity.Critical:
        return getCssVar('severity.critical');
      case Severity.Info:
        return getCssVar('severity.informational');
      case Severity.Analysing:
        return getCssVar('severity.analysis');
      default:
        return getCssVar('severity.high');
    }
  };

  return (
    <Tag color={tagType(title)} key={title} {...TagProps} title={title}>
      {UcFirstLetter(title)}
    </Tag>
  );
};
