/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VulnerabilityStatus } from './VulnerabilityStatus';

export type VulnerabilityDetails = {
  id?: string;
  hostId?: string;
  qid?: string;
  subject?: string;
  vulnerabilityStatus?: VulnerabilityStatus;
  category?: string;
  severity?: VulnerabilityDetails.severity;
  results?: string;
  port?: string;
  firstFound?: number;
  lastFound?: number;
};

export namespace VulnerabilityDetails {
  export enum severity {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
  }
}
