import { AppDispatch, MessageType, processApi } from 'store';
import { RequestStatus } from 'store/loading/slice';
import { showError, showMessage } from 'services/helpers';
import { IValuesForm } from '../../types';

interface IProps {
  dispatch: AppDispatch;
  customerId: string;
  values: IValuesForm;
  productTitle: string;
}

export const orderCertificate = async ({ dispatch, customerId, values, productTitle }: IProps) => {
  const orderCertificate = await dispatch(
    processApi.updateProductCertificate({
      customerId,
      requestBody: {
        expiry: typeof values.date === 'number' ? values.date : values.date?.valueOf(),
        vendor: values.vendor,
        product: productTitle,
      },
    }),
  );

  if (orderCertificate.meta.requestStatus === RequestStatus.Rejected) {
    showError();

    return;
  }

  showMessage({ content: `Changes saved`, className: 'black-notification' }, MessageType.Success);
};
