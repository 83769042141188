import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Col, Drawer, Row } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { Icon } from 'shared';
import { getCssVar, icons } from 'styles/theme';
import { logoutAction } from 'store';
import { useGetPermission } from 'services/hooks';
import { Routes } from 'services/entities';
import { ParamsPage } from 'services/interfaces';
import { backlinkRoutes, PATH_TO_ICONS, PermissionOptions, PermissionsLevel } from 'app-constants';
import { useTheme } from 'styled-components';

import { CustomersSelect } from './CustomersSelect/CustomersSelect';
import { MainMenu } from './MainMenu';
import { getNameCapitalLetters } from './helpers';
import { DrawerFooterLogo, ReportIncidentBtn, UserAvatar, UserNameStyled } from './styled';
import { MobileThemeSwitcher } from './MobileThemeSwitcher';

export type MainMenuDrawerProps = {
  userName?: string;
  visible?: boolean;
  onClose?: () => void;
};

export const MainMenuDrawer = ({ visible, onClose, userName }: MainMenuDrawerProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleLogoutClick = useCallback(() => {
    dispatch(logoutAction(true));
  }, [dispatch]);

  const permission = useGetPermission(PermissionOptions.SupportRequests);

  const {
    params: { id: pageId },
    url: currentUrl,
    path: pathForBacklink,
  } = useRouteMatch<ParamsPage>();

  return (
    <Drawer
      headerStyle={{
        padding: 16,
        background: `${getCssVar('leftMenu.menuDrawerColor')}`,
        border: 0,
      }}
      title={
        <>
          <Row align='middle' style={{ marginBottom: 16 }} justify='space-between'>
            <Col flex='none'>
              <Icon
                onClick={onClose}
                component={icons.close}
                style={{
                  fontSize: 30,
                  color: getCssVar('leftMenu.iconCloseColor'),
                }}
              />
            </Col>
            <Col flex='auto' style={{ textAlign: 'end' }}>
              <Link to='/' onClick={onClose}>
                <img src={`${PATH_TO_ICONS}/${theme.colorSet.leftMenu.logoTop}`} alt='logo' />
              </Link>
            </Col>
          </Row>
          {permission === PermissionsLevel.Full && (
            <ReportIncidentBtn
              as={Link}
              to={{
                pathname: Routes.REPORT_INCIDENT,
                state: {
                  backTo: {
                    route: currentUrl,
                    title: `Back to ${backlinkRoutes[pathForBacklink]}`,
                    id: pageId && ` ${pageId}`,
                  },
                },
              }}
              type='secondary'
              className='ant-btn ant-btn-block secondary'
              onClick={onClose}
            >
              Report incident
            </ReportIncidentBtn>
          )}
        </>
      }
      placement='left'
      closable={false}
      visible={visible}
      width={284}
      bodyStyle={{ padding: 0, background: getCssVar('mainMenuDrawer.bg') }}
      footer={
        <>
          <Row align='middle' style={{ marginBottom: 16 }}>
            <Col flex='auto'>
              <Avatar
                size={40}
                style={{
                  marginRight: 8,
                  background: getCssVar('leftMenu.avatarColor'),
                }}
              >
                <UserAvatar>{getNameCapitalLetters(userName)}</UserAvatar>
              </Avatar>

              <UserNameStyled>{userName}</UserNameStyled>
            </Col>
            <Col flex='none'>
              <Link to='/' onClick={handleLogoutClick}>
                <Icon
                  component={icons.logout}
                  color={getCssVar('leftMenu.logoutColor')}
                  dimension='21px'
                />
              </Link>
            </Col>
          </Row>
          <MobileThemeSwitcher />
          <CustomersSelect />
          {theme.colorSet.leftMenu.drawerFooterLogo && (
            <DrawerFooterLogo>
              <Icon
                component={theme.colorSet.leftMenu.drawerFooterLogo}
                width='190px'
                height='24px'
              />
            </DrawerFooterLogo>
          )}
        </>
      }
      footerStyle={{
        background: getCssVar('leftMenu.drawerColor'),
        padding: 16,
        border: 0,
      }}
    >
      <MainMenu onClose={onClose} />
    </Drawer>
  );
};
