import { Filter, HostSource, SupportRequestsType } from 'services/api';
import { getQueriesParams } from 'services/helpers/URL';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { EmptyValues, EmptyValuesActiveEDRAgents, IEventsByServicesEmptyValues } from './types';
import { backlinkRoutes, initialPagination } from '../../app-constants';
import { initialOrderBy as initialSupportRequestsOrderBy } from '../SupportRequests/constants';
import { initialOrderBy as initialCmdbOrderBy } from '../Cmdb/constants';
import { initialOrderBy as initialAlarmsOrderBy } from '../Alarms/constants';
import { openIncidentsParams } from './components/ActiveSecurityIncidents/constants';

export enum ManagedDetectionsResponseTitles {
  RecentAlarms = 'Recent alarms',
  ActiveServices = 'Active services',
  ActiveAlarmsByOrigin = 'Active alarms',
  SecurityIncidents = 'Security incidents',
  ActiveEDRAgents = 'Active EDR agents',
  AllEvents = 'All events',
  AllEventsByServices = 'All events by services',
  InvestigationOverview = 'Investigation overview',
  ActiveSecurityIncidents = 'Active security incidents',
  MTTI = 'Mean time to investigate (MTTI)',
  MonitoredServices = 'Monitored services',
}

export const maxTableElementsWithoutScroll = 6;

export const tooltipTitles = {
  recentAlarms: 'Most recent alarms that are currently being triaged by our SOC analysts',
  activeServices: 'Current open security incidents that are being investigated or remediated',
  activeAlarmsByOrigin: 'Total number of Active alarms being Triaged by our SOC analysts',
  securityIncidents: 'Current open security incidents that are being investigated or remediated',
  activeEDRAgents: 'Active EDR agents installed and contracted within your environment.',
  allEvents:
    'Total number of events within your technology environment that are  being monitored and analysed by our SOC',
  allEventsByServices:
    'Total number of events within your technology environment segregated by source',
  investigationOverview:
    'Explore events, alarms, and incidents, comparing them to the previous period',
  MTTI:
    'The average time it takes to investigate and analyse a security incident or alert once it has been detected',
  monitoredServices: 'Current status of the Active services being monitored by norm',
};

export const mobileTableRowMaxLength = 6;
export const desktopTableRowMaxLength = 10;

export const maxAllowedHeightOfTable = 390;

export const emptyValues: EmptyValues[] = [{ collectedDateTime: 0, count: 0 }];
export const emptyValuesActiveEDRAgents: EmptyValuesActiveEDRAgents = {
  [HostSource.EDR_AGENT_TRELLIX_]: [
    {
      collectedDateTime: 0,
      count: 0,
    },
  ],
  [HostSource.EDR_AGENT_FORTI_EDR_]: [
    {
      collectedDateTime: 0,
      count: 0,
    },
  ],
  [HostSource.EDR_AGENT_DEFENDER_]: [
    {
      collectedDateTime: 0,
      count: 0,
    },
  ],
  [HostSource.EDR_AGENT_SENTINEL_ONE_]: [
    {
      collectedDateTime: 0,
      count: 0,
    },
  ],
};
export const allEventsByServicesEmptyValues: IEventsByServicesEmptyValues[] = [
  { count: 0, name: '' },
];

export const mdrBackRoute = {
  backTo: {
    route: Routes.THREAT_DETECTION,
    title: `Back to ${backlinkRoutes[Routes.THREAT_DETECTION]}`,
  },
};

export const viewMoreConfig = {
  activeSecurityIncidents: {
    pathname: Routes.SUPPORT_REQUESTS,
    search: getQueryString(openIncidentsParams),
    state: mdrBackRoute,
  },
  seeAllIncidents: {
    pathname: Routes.SUPPORT_REQUESTS,
    route: Routes.SUPPORT_REQUESTS,
    search: getQueryString(
      getQueriesParams(initialSupportRequestsOrderBy, initialPagination, {
        name: 'type',
        value: SupportRequestsType.SECURITY_INCIDENT,
        type: Filter.type.VALUE,
      }),
    ),
    state: mdrBackRoute,
  },
  activeEDRAgents: (filterDate?: number) => ({
    orderBy: { field: 'lastCheckedIn', type: 'ASC' },
    pagination: initialPagination,
    filter: {
      fields: [
        {
          name: 'assetSource',
          value: [
            HostSource.EDR_AGENT_FORTI_EDR_,
            HostSource.EDR_AGENT_TRELLIX_,
            HostSource.EDR_AGENT_DEFENDER_,
            HostSource.EDR_AGENT_SENTINEL_ONE_,
          ],
          type: Filter.type.MULTIPLE,
        },
        ...(filterDate
          ? [
              {
                name: 'edrSourcesLastCheckedIn',
                value: [filterDate, Date.now()],
                type: Filter.type.RANGE,
              },
            ]
          : []),
      ],
    },
  }),
  investigationOverview: {
    alarms: ({ startDate, endDate }) => ({
      orderBy: initialAlarmsOrderBy,
      pagination: initialPagination,
      filter: {
        fields: [
          {
            name: 'created',
            value: [startDate, endDate],
            type: Filter.type.RANGE,
          },
        ],
      },
    }),
    incidents: ({ startDate, endDate }) => ({
      orderBy: initialAlarmsOrderBy,
      pagination: initialPagination,
      filter: {
        fields: [
          {
            name: 'type',
            value: SupportRequestsType.SECURITY_INCIDENT,
            type: Filter.type.VALUE,
          },
          {
            name: 'created',
            value: [startDate, endDate],
            type: Filter.type.RANGE,
          },
        ],
      },
    }),
  },
  // todo update after clarification
  mttiCmdb: {
    pathname: Routes.CMDB,
    route: Routes.CMDB,
    search: getQueryString({
      orderBy: initialCmdbOrderBy,
      pagination: initialPagination,
    }),
    state: mdrBackRoute,
  },
};
