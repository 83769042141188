import { Box } from 'shared';
import { fontWeights, getCssVar } from 'styles/theme';
import { useTheme } from 'styled-components';
import { AccessMessageStyled, LinkForPurchase } from '../styled';

interface IProps {
  getAccessMessage: { text: string; href: string };
  isMobile?: boolean;
}

export const AccessMessage = ({ getAccessMessage: { text, href }, isMobile }: IProps) => {
  const { sizes, spacing } = useTheme();

  return (
    <AccessMessageStyled
      padding={`${spacing[1]} ${spacing[2]}`}
      mt={isMobile ? spacing[0] : spacing[2]}
      backgroundColor={getCssVar('card.headAccessMessageBG')}
      fontSize={sizes['3.6']}
      fontWeight={fontWeights.medium}
      display='inline-block'
    >
      <LinkForPurchase to={href || ''}>
        <Box fontSize={sizes['3.6']} mr={spacing[1]} fontWeight={fontWeights.medium}>
          {text}
        </Box>
      </LinkForPurchase>
      <Box display='inline-block'>to get access</Box>
    </AccessMessageStyled>
  );
};
