import { ReactNode } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { extraAction } from './extraAction';

export enum MessageType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Loading = 'loading',
}

type NotificationsState = {
  message: string | ReactNode;
  type: string;
};

const initialState: NotificationsState = {
  message: '',
  type: '',
};

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    createNotifications: (state: NotificationsState, action: { payload: NotificationsState }) => ({
      ...state,
      message: action.payload.message,
      type: action.payload.type,
    }),
  },
  extraReducers: {
    [extraAction.toString()]: (
      state: NotificationsState,
      action: { error: { message: string } },
    ) => {
      state.message = action.error.message;
      state.type = MessageType.Error;
    },
  },
});
