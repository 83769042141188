import {
  ConnectionDetailsType,
  SentinelOneCredentialsType,
} from 'pages/ServicesIntegrations/types';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { Form } from 'antd';
import { TextField } from 'shared/inputs/formik-fields';
import { servicesIntegrationsApi, AppDispatch, useCustomer, PayloadActionType } from 'store';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { RequestStatus } from 'store/loading/slice';
import { showError, showSuccess } from 'services/helpers';
import { Routes } from 'services/entities';
import { ServiceIntegrationResponse } from 'services/api';
import { FormWrapper } from '../../styled';
import { FormActionButtons } from './FormActionButtons';

type SentinelOneFormProps = {
  connectionDetails?: ConnectionDetailsType;
};

const MIN_TOKEN_LENGTH = 400;
const MAX_TOKEN_LENGTH = 500;
const MIN_URL_LENGTH = 24;
const MAX_URL_LENGTH = 100;

const validationSchema = Yup.object({
  apiToken: Yup.string()
    .trim()
    .required('Api token is required')
    .min(MIN_TOKEN_LENGTH, 'Must be at least 400 characters')
    .max(MAX_TOKEN_LENGTH, 'Must be at most 500 characters'),
  tenantUrl: Yup.string()
    .trim()
    .required('Tenant Url is required')
    .url('Tenant Url is not valid')
    .min(MIN_URL_LENGTH, 'Must be at least 24 characters')
    .max(MAX_URL_LENGTH, 'Must be at most 100 characters'),
});

export const SentinelOneForm = ({ connectionDetails }: SentinelOneFormProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const customerId = useCustomer();
  const { id: serviceId } = useParams<{ id: string }>();
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        apiToken: connectionDetails?.credentials?.apiToken || '',
        tenantUrl: connectionDetails?.credentials?.tenantUrl || '',
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const result = (await dispatch(
          servicesIntegrationsApi.updateServiceConnectionDetails({
            customerId,
            serviceId,
            requestBody: {
              credentials: values,
            },
          }),
        )) as PayloadActionType<ServiceIntegrationResponse>;

        if (result.meta.requestStatus === RequestStatus.Rejected) {
          showError();
        } else {
          dispatch(servicesIntegrationsApi.getServices({ customerId }));

          showSuccess('Integration successfully connected');

          history.push(Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', serviceId));
        }
      }}
    >
      {(props: FormikProps<SentinelOneCredentialsType>) => {
        const { values, handleSubmit, handleReset, isValid, dirty } = props;

        return (
          <FormWrapper>
            <Form layout='vertical'>
              <TextField name='tenantUrl' label='Tenant URL' required />
              <TextField
                type='textarea'
                name='apiToken'
                label='API token'
                required
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </Form>

            <FormActionButtons
              onSave={handleSubmit}
              onReset={handleReset}
              canSubmit={dirty && isValid}
              connectedAt={connectionDetails?.connectedAt}
              values={values}
            />
          </FormWrapper>
        );
      }}
    </Formik>
  );
};
