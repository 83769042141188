import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { LogoPlaceholder as StyledLogoPlaceholder, Page } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide16.jpeg') no-repeat #fafafa;
  background-size: auto 100%;

  h2 {
    color: #fff;
    font-size: 30px;
    font-weight: normal;
    left: 50%;
    position: absolute;

    top: 205px;
    transform: translateX(-50%);
  }
`;

export const LogoPlaceholder = styled(StyledLogoPlaceholder)`
  bottom: 140px;
  color: #fff;
  left: 50%;

  transform: translateX(-50%);

  > P {
    text-align: center;
  }
`;
