import { Theme } from 'styles/theme/types';
import { MultiValues } from 'shared/charts/types';
import { Radiuses } from 'shared/charts/Radar/constants';

export const getOption = (values: MultiValues, theme: Theme, radius: number) => {
  const data = Object.keys(values).map((key: string) => {
    return {
      value: values[key],
      name: key,
    };
  });

  return {
    color: [theme.colors.red[400]],
    radar: {
      shape: 'polygin',

      splitNumber: 4,
      splitLine: {
        lineStyle: {
          color: theme.colors.green[200],
        },
      },
      splitArea: {
        areaStyle: {
          color: [
            theme.colors.green[500],
            theme.colors.yellow[500],
            theme.colors.red[400],
            theme.colors.red[700],
          ],
          opacity: 0.2,
        },
      },
      axisNameGap: 12,
      indicator: [
        { name: 'Authentication' },
        { name: 'Attack complexity' },
        { name: 'Availability' },
        { name: 'Integrity' },
        { name: 'Confidentiality' },
      ],
      radius,
      axisName: {
        fontSize: radius === Radiuses.ExtraSmall ? '11px' : '12px',
        color: '#6E7079',
        fontWeight: '400',
      },
    },
    series: [
      {
        name: 'data',
        type: 'radar',
        data,
      },
    ],
  };
};
