import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  alarmsApi,
  managedDetectionsResponseApi,
  useActiveServices,
  useApp,
  useCustomer,
  useLoading,
} from 'store';
import { Empty } from 'antd';
import { ActiveServicesItemSchema, AlarmSchema } from 'services/api';
import { Box, Card, ScrollContainer, Table } from 'shared';
import {
  desktopTableRowMaxLength,
  ManagedDetectionsResponseTitles,
  maxTableElementsWithoutScroll,
  mobileTableRowMaxLength,
  tooltipTitles,
} from 'pages/ThreatDetection/constants';
import { Routes } from 'services/entities';
import { useTheme } from 'styled-components';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { backlinkRoutes } from 'app-constants';
import { HistoryState } from 'services/interfaces';
import { IconDanger, Total } from '../styled';
import { columns } from './components/table-columns';
import { TotalCell } from './styled';
import { DataTypesPopup } from './components/SentinelDataTypes/DataTypesPopup';

export const MonitoredServices = () => {
  const history = useHistory();
  const theme = useTheme();
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const { state: historyState } = useLocation<HistoryState>();
  const count = !isMobile ? desktopTableRowMaxLength : mobileTableRowMaxLength;
  const {
    activeServices: { healthy = 0, risk = 0, services },
  } = useActiveServices();
  const dispatch = useDispatch();
  const loading = useLoading([alarmsApi.alarmsTableContent.typePrefix]);
  const loadingTable = loading[alarmsApi.alarmsTableContent.typePrefix];
  const dataSource = services || [];
  const total = healthy + risk || 0;
  const [isVisibleDataTypesPopup, setIsVisibleDataTypesPopup] = useState<boolean>(false);
  const [
    activeSentinelService,
    setActiveSentinelService,
  ] = useState<ActiveServicesItemSchema | null>(null);
  const onOpenDataTypesPopup = (record: ActiveServicesItemSchema) => {
    setActiveSentinelService(record);
    setIsVisibleDataTypesPopup(true);
  };

  const onCloseDataTypesPopup = () => {
    setIsVisibleDataTypesPopup(false);
    setActiveSentinelService(null);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  const isShowTableScroll = !isMobile && dataSource.length > maxTableElementsWithoutScroll;

  useEffect(() => {
    dispatch(
      managedDetectionsResponseApi.activeServices({
        customerId,
      }),
    );
  }, [dispatch, customerId, count]);

  const emptyMessage = {
    emptyText: services?.length ? null : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{ height: '335px' }}
        description='No monitored services yet'
      />
    ),
  };
  const getActiveServicesSubTitle = () => {
    return (
      <Box d='flex' alignItems='center'>
        <IconDanger
          component={theme.icons.warning}
          color={theme.colorSet.dangerTriangle}
          dimension='36px'
          margin={!isMobile ? '0 9px 0 0' : '0 8px 2px 0'}
        />
        <Total>{risk}</Total>
      </Box>
    );
  };

  const getActiveServicePercent = (value: number, total: number) => {
    const totalPercent = 100;

    return Math.round((totalPercent * value) / total);
  };

  const onRowClickHandler = React.useCallback(
    (record: ActiveServicesItemSchema) => {
      if (record.type === 'sentinel') {
        onOpenDataTypesPopup(record);
      } else {
        history.push(Routes.MONITORED_SERVICE_DETAILS.replace(':name', record?.name as string), {
          backTo: {
            route: `${Routes.MONITORED_SERVICE_DETAILS}`,
            search: history.location.search,
            title: `Back to ${backlinkRoutes[Routes.THREAT_DETECTION]}`,
          },
          rootBackTo: historyState?.backTo,
          data: { ...record },
        });
      }
    },
    [history, historyState],
  );

  return (
    <>
      <Card
        title={ManagedDetectionsResponseTitles.MonitoredServices}
        tooltipTitle={tooltipTitles.monitoredServices}
        subTitle={
          <SubTitle
            boldText={risk ? getActiveServicesSubTitle() : ''}
            customText={risk ? 'Risk' : ''}
            period=''
          />
        }
        showDivider={false}
        height={theme.sizes.full}
        headStyles={
          !isMobile
            ? { pt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
            : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
        }
        bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
        cardPadding={
          !isMobile
            ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
            : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
        }
      >
        <ScrollContainer
          width='900px'
          margin={`${theme.sizes[4]} ${theme.sizes[0]} ${theme.sizes[0]}`}
          ref={ref}
          $isScrollTop
        >
          <Table
            columns={columns}
            scroll={isShowTableScroll ? { y: 345 } : {}}
            dataSource={dataSource}
            onRow={(record) => ({
              onClick: () => {
                onRowClickHandler(record);
              },
            })}
            tableLayout='auto'
            pagination={false}
            rowKey={(record: AlarmSchema) => record.id as React.Key}
            loading={loadingTable}
            locale={emptyMessage}
            isClickable
          />
        </ScrollContainer>
        {!!dataSource.length && (
          <TotalCell>
            <Box>
              Risk{' '}
              <span>
                {risk} ({getActiveServicePercent(risk, total)}%)
              </span>
            </Box>
            <Box>
              Healthy{' '}
              <span>
                {healthy} ({getActiveServicePercent(healthy, total)}%)
              </span>
            </Box>
            <Box>
              Total <span>{total}</span>
            </Box>
          </TotalCell>
        )}
      </Card>
      {isVisibleDataTypesPopup && activeSentinelService?.dataTypes && (
        <DataTypesPopup
          record={activeSentinelService}
          isVisible={isVisibleDataTypesPopup}
          onClose={onCloseDataTypesPopup}
        />
      )}
    </>
  );
};
