import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatchManagementPlatform } from 'services/api';

interface InitialState {
  platform: PatchManagementPlatform;
}

const initialState: InitialState = {
  platform: PatchManagementPlatform.WINDOWS,
};

export const platform = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    setPlatform: (state: InitialState, action: PayloadAction<PatchManagementPlatform>) => {
      state.platform = action.payload;
    },
  },
});
