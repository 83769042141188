import moment from 'moment';
import { IDate } from 'services/interfaces';
import { InnerLayoutProps as InnerLayout } from 'shared';
import { fourMonthsTime } from 'app-constants';
import { getPeriod } from 'services/helpers';
import { EdrTrendPointSchema } from 'services/api';

export const getLayoutConfig = (
  downloadCSV: () => void,
  loading: boolean,
  showDownloadCSVButton: boolean,
  isMobile?: boolean,
): InnerLayout => ({
  title: 'Threat detection',
  downloadDoc:
    !isMobile && showDownloadCSVButton
      ? [
          {
            id: 1,
            title: 'Download report',
            onDownload: downloadCSV,
            loading,
          },
        ]
      : undefined,
});

export const getData = (entries: EdrTrendPointSchema[]) => {
  const seriesData: number[] = [];
  const xAxisData: number[] = [];

  entries.map((entry: EdrTrendPointSchema) => {
    if (entry.count == null || !entry.collectedDateTime) {
      return entry;
    }

    seriesData.push(entry.count);
    xAxisData.push(entry.collectedDateTime);

    return entry;
  });

  if (!seriesData.length) {
    return [[], xAxisData];
  }

  return [seriesData, xAxisData];
};

export const formatData = (array: { collectedDateTime: number; count: number }[], date: IDate) => {
  const period = getPeriod(date.startDate, date.endDate);
  let newArray = [...array];

  if (period > fourMonthsTime) {
    const obj = array.reduce(
      (acc: { [key: number]: number }, entry: { collectedDateTime: number; count: number }) => {
        const result = { ...acc };
        const month = moment(+entry.collectedDateTime)
          .startOf('month')
          .valueOf();

        if (!Object.prototype.hasOwnProperty.call(result, month)) {
          result[month] = 0;
        }

        result[month] += entry.count;

        return result;
      },
      {},
    );

    newArray = Object.entries(obj).map((entry: [string, number]) => ({
      collectedDateTime: +entry[0],
      count: entry[1],
    }));
  }

  return newArray;
};
