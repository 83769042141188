import { ActionNames } from 'app-constants';
import { DocumentType, OrderBy, Filter, CertificateName } from 'services/api';
import { UploadDocumentOption } from 'shared/UploadPopup/types';
import { InputType } from '../../../services/api/models/InputType';

export const docTypeOptions: UploadDocumentOption<DocumentType>[] = [
  { value: DocumentType.OTHER, label: 'Other' },
  {
    value: DocumentType.CERTIFICATE,
    label: 'Certificate',
    uploadLabel: 'Pdf (only) files can be uploaded.',
    ext: '.pdf',
  },
];

export const docTypeOptionsForNormUser: UploadDocumentOption<DocumentType>[] = [
  docTypeOptions[0],
  { value: DocumentType.CONTRACT, label: 'Contract' },
  { value: DocumentType.TEMPLATE, label: 'Template' },
  { value: DocumentType.REPORT, label: 'Report' },
  { value: DocumentType.USER_GUIDE, label: 'User guide' },
  docTypeOptions[1],
];

export const certificateOptions: { value: CertificateName; label: string }[] = [
  { value: CertificateName.CYBER_ESSENTIALS, label: CertificateName.CYBER_ESSENTIALS },
  { value: CertificateName.CYBER_ESSENTIALS_PLUS, label: CertificateName.CYBER_ESSENTIALS_PLUS },
  { value: CertificateName.ISO27001, label: 'ISO 27001' },
];

export const documentNameNormalSize = 20;
export const nameStart = 0;

export const initialOrderBy = {
  field: 'createdOn',
  type: OrderBy.type.DESC,
};

export const filtersFields = [
  {
    field: 'documenttype',
    options: docTypeOptionsForNormUser,
    label: 'Document type',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];

export const searchSettings = {
  placeholder: 'Search by document name',
  fields: ['name'],
  min: 3,
};

export const emptyTablePreviewOptionsDocuments = {
  icon: 'empty_table',
  title: 'No documents and files available yet',
  textContent: 'Here will be displayed all documents that you will be able to download.',
};

export const emptyTablePreviewOptionsSoftware = {
  icon: 'empty_table',
  title: 'No download files available yet',
  textContent: 'Here will be displayed all documents that you will be able to download.',
};

export const selections = [
  {
    label: 'Certificate name',
    options: certificateOptions,
    placeholder: 'Select certificate',
    radioButtonRely: DocumentType.CERTIFICATE,
    key: 'certificate',
  },
];
export const initialSelect = {
  certificate: undefined,
};
export const actionsId = {
  download: ActionNames.Download,
  delete: ActionNames.Delete,
};

export const deletePopupDetails = {
  id: ActionNames.Delete,
  danger: true,
  title: 'Delete file',
  text: 'This action cannot be undone.',
  onApplyTitle: 'Delete file',
};

export enum ColumnKey {
  Name = 'name',
  DocumentType = 'documenttype',
  Size = 'size',
  CreatedOn = 'createdOn',
  Actions = 'actions',
}
