import { createSlice } from '@reduxjs/toolkit';
import { ServicesIntegrationsListResponse, ServiceIntegrationResponse } from 'services/api';
import { servicesIntegrationsApi } from './thunks';

type ServicesIntegrationsState = {
  services: ServicesIntegrationsListResponse;
  serviceDetails: ServiceIntegrationResponse;
};

const initialState: ServicesIntegrationsState = {
  services: [],
  serviceDetails: {},
};

export const servicesIntegrations = createSlice({
  name: 'servicesIntegrations',
  initialState,
  reducers: {},
  extraReducers: {
    [servicesIntegrationsApi.getServices.rejected.toString()]: (
      state: ServicesIntegrationsState,
    ) => {
      state.services = [];
    },
    [servicesIntegrationsApi.getServices.fulfilled.toString()]: (
      state: ServicesIntegrationsState,
      action: { payload: ServicesIntegrationsListResponse },
    ) => {
      state.services = action.payload;
    },
    [servicesIntegrationsApi.getServiceDetails.rejected.toString()]: (
      state: ServicesIntegrationsState,
    ) => {
      state.serviceDetails = {};
    },
    [servicesIntegrationsApi.getServiceDetails.fulfilled.toString()]: (
      state: ServicesIntegrationsState,
      action: { payload: ServiceIntegrationResponse },
    ) => {
      state.serviceDetails = action.payload;
    },
    // [servicesIntegrationsApi.updateServiceGuidanceDetails.fulfilled.toString()]: (
    //   state: ServicesIntegrationsState,
    //   action: { payload: ServiceIntegrationResponse },
    // ) => {
    //   state.serviceDetails = {
    //     ...state.serviceDetails,
    //     ...action.payload,
    //   };
    // },
    [servicesIntegrationsApi.updateServiceConnectionDetails.fulfilled.toString()]: (
      state: ServicesIntegrationsState,
      action: { payload: ServiceIntegrationResponse },
    ) => {
      state.serviceDetails = {
        ...state.serviceDetails,
        ...action.payload,
      };
    },
    [servicesIntegrationsApi.disconnectService.fulfilled.toString()]: (
      state: ServicesIntegrationsState,
      action: { payload: ServiceIntegrationResponse },
    ) => {
      state.serviceDetails = {
        ...state.serviceDetails,
        ...action.payload,
      };
    },
  },
});
