/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CertificateName } from '../models/CertificateName';
import type { CustomerId } from '../models/CustomerId';
import type { DocumentsResponse } from '../models/DocumentsResponse';
import type { DocumentType } from '../models/DocumentType';
import type { ExportDocumentResponse } from '../models/ExportDocumentResponse';
import type { Filename } from '../models/Filename';
import type { Filters } from '../models/Filters';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { SuccessResponse } from '../models/SuccessResponse';
import { request as __request } from '../core/request';

export class DocumentsService {
  /**
   * Documents list
   * Get list of documents in Azure Blob
   * @returns DocumentsResponse List of documents in Azure Blob
   * @throws ApiError
   */
  public static async getDocuments({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<DocumentsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/documents`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when get documents`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete document
   * Delete document from Azure Blob
   * @returns SuccessResponse Delete document from Azure Blob
   * @throws ApiError
   */
  public static async deleteDocument({
    customerId,
    filename,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filename parameter **/
    filename: Filename;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/document`,
      query: {
        filename: filename,
      },
      errors: {
        400: `An error message when delete document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Import (upload) ducument
   * Import (upload) ducument to Azure Blob
   * @returns SuccessResponse Import document to Azure Blob
   * @throws ApiError
   */
  public static async importDocument({
    customerId,
    filename,
    documentType,
    currentChunkIndex,
    totalChunks,
    fileSize,
    certificateName,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filename parameter **/
    filename: string;
    /** The document type parameter **/
    documentType: DocumentType;
    /** The current chunk index parameter **/
    currentChunkIndex: number;
    /** Total file's chunks **/
    totalChunks: number;
    /** Total file size **/
    fileSize: number;
    /** Name of uploaded certificate, this query params is only available when client is trying to upload document with type certificate **/
    certificateName?: CertificateName;
    /** Import document **/
    requestBody?: any;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/document/import`,
      query: {
        filename: filename,
        documentType: documentType,
        currentChunkIndex: currentChunkIndex,
        totalChunks: totalChunks,
        fileSize: fileSize,
        certificateName: certificateName,
      },
      body: requestBody,
      errors: {
        400: `An error message when import document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Export (download) document
   * Export (download) document from Azure Blob
   * @returns ExportDocumentResponse Export document from Azure Blob
   * @throws ApiError
   */
  public static async exportDocument({
    customerId,
    filename,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filename parameter **/
    filename: Filename;
  }): Promise<ExportDocumentResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/document/export`,
      query: {
        filename: filename,
      },
      errors: {
        400: `An error message when export document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
