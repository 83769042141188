import styled from 'styled-components';
import { Divider as AntDivider } from 'antd';
import { Box } from 'shared';
import { breakpoints } from 'styles/theme';

type DividerProps = {
  color: string;
};

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
  }
`;
export const LegendItem = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-width: 200px;
  min-height: 105px;
`;

export const LegendDivider = styled(AntDivider)<DividerProps>`
  background-color: ${({ color }: DividerProps) => color};
  height: 3px;
  margin: 10px 0;
`;

export const ChartWrapper = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .chart-container .echarts-for-react {
    bottom: -10px;
    height: 400px !important;
    width: 400px;
  }

  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column;

    .chart-container .echarts-for-react {
      bottom: 0;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .chart-container .echarts-for-react {
      width: 300px;
      height: 300px !important;
    }
  }
`;
