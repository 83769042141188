import React from 'react';
import { Box } from 'shared';
import { byNumber, byValue } from 'sort-es';
import { emptyDataString } from 'app-constants';
import { ColumnsType } from 'antd/es/table/interface';
import { getFormattedDate, getRenderValue } from 'services/helpers';
import { PhishingAndTrainingActivitySchema, PhishingEmailsSchema } from 'services/api';
import { StackedHorizontalBarFull } from 'shared/charts';
import _ from 'lodash';
import { barColors, ColumnKey, completed, TableTitles } from '../constants';
import { PhishingActivityType } from '../types';
import { getPhishingActivityStatus, getTrainingStatus } from '../helpers';
import { IconColoring } from './IconColoring';
import { PhishingActivityIcon } from './PhishingActivityIcon';

export const getColumns = (
  isFontLoaded: boolean,
): ColumnsType<PhishingAndTrainingActivitySchema> => [
  {
    key: ColumnKey.Name,
    title: TableTitles.Name,
    dataIndex: ColumnKey.Name,
    showSorterTooltip: false,
    render: (name: string) => <>{getRenderValue(name)}</>,
  },
  {
    key: ColumnKey.PhishingEmailsSent,
    title: TableTitles.PhishingEmailsSent,
    dataIndex: ColumnKey.PhishingEmailsSent,
    sorter: true,
    showSorterTooltip: false,
    render: (email: number) => (
      <Box flexDirection='row' minW='100px'>
        {getRenderValue(email)}
      </Box>
    ),
    className: 'center-head-title',
    align: 'center',
  },
  {
    key: ColumnKey.PhishingActivity,
    title: TableTitles.PhishingActivity,
    dataIndex: ColumnKey.PhishingActivity,
    width: 220,
    render: (_: PhishingActivityType, row: PhishingAndTrainingActivitySchema) =>
      !row.phishingActivityOtherSeverity &&
      !row.phishingActivityLowSeverity &&
      !row.phishingActivityMediumSeverity &&
      !row.phishingActivityHighSeverity ? (
        emptyDataString
      ) : (
        <Box minW='180px'>
          {isFontLoaded && (
            <StackedHorizontalBarFull
              values={{
                noActions: row.phishingActivityOtherSeverity || 0,
                opened: row.phishingActivityLowSeverity || 0,
                clicked: row.phishingActivityMediumSeverity || 0,
                providedCredentialsOrDownloadedAnAttachment: row.phishingActivityHighSeverity || 0,
              }}
              colors={barColors}
            />
          )}
        </Box>
      ),
    sorter: true,
    showSorterTooltip: false,
    shouldCellUpdate: (record, prevRecord) => !_.isEqual(record, prevRecord),
  },
  {
    key: ColumnKey.ReportedPhishingTests,
    title: TableTitles.ReportedPhishingTests,
    dataIndex: ColumnKey.ReportedPhishingTests,
    sorter: true,
    showSorterTooltip: false,
    render: (reportedTest: number) => <Box flexDirection='row'>{getRenderValue(reportedTest)}</Box>,
    className: 'center-head-title',
    align: 'center',
  },
  {
    key: ColumnKey.TrainingProgress,
    title: TableTitles.TrainingProgress,
    dataIndex: ColumnKey.TrainingProgress,
    render: (status: number) => (
      <Box flexDirection='row' minW='100px'>
        {status === null || status === undefined ? (
          emptyDataString
        ) : (
          <>
            <IconColoring status={getTrainingStatus(status)} />
            <span>
              {getTrainingStatus(status) === 'Not started'
                ? 'Not Started'
                : getTrainingStatus(status)}
            </span>
          </>
        )}
      </Box>
    ),
    sorter: true,
    showSorterTooltip: false,
    width: 180,
  },
  {
    key: ColumnKey.TrainingConfidence,
    title: TableTitles.TrainingConfidence,
    dataIndex: ColumnKey.TrainingConfidence,
    width: 180,
    showSorterTooltip: false,
    render: (status: string, row: PhishingAndTrainingActivitySchema) =>
      row.trainingProgress === completed ? (
        <Box flexDirection='row'>
          <span>{status}%</span>
        </Box>
      ) : (
        emptyDataString
      ),
    className: 'center-head-title',
    align: 'center',
  },
  {
    key: ColumnKey.CertifiedDate,
    title: TableTitles.Certified,
    dataIndex: ColumnKey.CertifiedDate,
    width: 160,
    sorter: true,
    showSorterTooltip: false,
    className: 'right-head-title',
    align: 'right',
    render: (date: string) => <Box w='160px'>{getFormattedDate(date, undefined, true)}</Box>,
  },
];

export const expandTableColumns: ColumnsType<PhishingEmailsSchema> = [
  {
    key: ColumnKey.Subject,
    title: TableTitles.Subject,
    dataIndex: ColumnKey.Subject,
    showSorterTooltip: false,
    sorter: false,
    width: '35%',
    render: (subject: string) => <>{getRenderValue(subject)}</>,
  },
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    showSorterTooltip: false,
    width: '35%',
    sorter: byValue((email: PhishingEmailsSchema) => Number(email.status || 0), byNumber()),
    render: (status: string) => (
      <>
        <PhishingActivityIcon status={getPhishingActivityStatus(status)} />
        <span>{getPhishingActivityStatus(status)}</span>
      </>
    ),
  },
  {
    key: ColumnKey.Sent,
    title: TableTitles.Sent,
    dataIndex: ColumnKey.Sent,
    showSorterTooltip: false,
    sorter: byValue((email: PhishingEmailsSchema) => Number(email.sent), byNumber()),
    className: 'right-head-title',
    align: 'right',
    width: 180,
    render: (sent: string) => <Box minW='160px'>{getFormattedDate(sent)}</Box>,
  },
  {
    key: ColumnKey.EventDate,
    title: TableTitles.EventDate,
    dataIndex: ColumnKey.EventDate,
    showSorterTooltip: false,
    sorter: byValue((email: PhishingEmailsSchema) => Number(email.eventDate), byNumber()),
    defaultSortOrder: 'descend',
    className: 'right-head-title',
    align: 'right',
    width: 180,
    render: (eventDate: string) => <Box minW='160px'>{getFormattedDate(eventDate)}</Box>,
  },
];
