import { AppDispatch, processApi, supportRequestsApi } from 'store';
import { History } from 'history';
import { RequestStatus } from 'store/loading/slice';
import { showError } from 'services/helpers';
import { CreateSupportRequestRequest, CustomerId } from 'services/api';
import { Routes } from 'services/entities';

interface IProps {
  dispatch: AppDispatch;
  values: CreateSupportRequestRequest;
  customerId: CustomerId;
  purchaseCertificate?: string | null;
  history: History;
}

export const onCreateNewSupportRequest = async ({
  dispatch,
  values,
  customerId,
  purchaseCertificate,
  history,
}: IProps) => {
  const createNewSupportRequest = await dispatch(
    supportRequestsApi.createNewSupportRequest({
      requestBody: values,
      customerId,
    }),
  );

  if (createNewSupportRequest.meta.requestStatus === RequestStatus.Rejected) {
    showError();

    return;
  }

  if (purchaseCertificate) {
    const createCertificate = await dispatch(
      processApi.createCertificate({
        customerId,
        requestBody: {
          product: values.product,
        },
      }),
    );

    if (createCertificate.meta.requestStatus === RequestStatus.Rejected) {
      showError();
    }
  }

  history.push(Routes.SUPPORT_REQUESTS);
};
