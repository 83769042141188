import React from 'react';
import * as theme from 'styles/theme';
import {
  NASSTAR_SUPPORT_EMAIL,
  NASSTAR_SUPPORT_PHONE,
  NORM_SUPPORT_EMAIL,
  NORM_SUPPORT_PHONE,
} from 'app-constants';
import { Domains, IloginBackground, Themes } from './types';
import { Dull } from './Norm/components/Dull';
import { Nasstar } from './Nasstar/components/Nasstar';
import { FontsNorm } from '../styles/theme/Norm/fontsNorm';
import { FontsNasstar } from '../styles/theme/Nasstar/fontsNasstar';

export const getThemeNameByDomain = (domain: string) => {
  switch (domain) {
    case Domains.Norm:
      return Themes.Norm;
    case Domains.Nasstar:
      return Themes.Nasstar;
    default:
      return Themes.Nasstar;
  }
};

export const getColorSet = (themeName?: Themes) => {
  switch (themeName) {
    case Themes.Norm:
      return theme.norm;
    case Themes.DarkNorm:
      return theme.darkNorm;
    case Themes.Nasstar:
      return theme.nasstar;
    default:
      return theme.nasstar;
  }
};

export const getLoginRightBackground = ({
  themeName,
  isMobile,
  width,
  isTablet,
}: IloginBackground) => {
  switch (themeName) {
    case Themes.DarkNorm:
    case Themes.Norm:
      return <Dull isMobile={isMobile} width={width} isTablet={isTablet} />;
    case Themes.Nasstar:
      return <Nasstar width={width} />;
    default:
      return <Nasstar width={width} />;
  }
};

export const getFonts = (themeName?: Themes) => {
  switch (themeName) {
    case Themes.Norm:
    case Themes.DarkNorm:
      return <FontsNorm />;
    case Themes.Nasstar:
      return <FontsNasstar />;
    default:
      return <FontsNorm />;
  }
};

export const getSupportEmail = (themeName?: Themes) => {
  switch (themeName) {
    case Themes.Norm:
    case Themes.DarkNorm:
      return NORM_SUPPORT_EMAIL;
    case Themes.Nasstar:
      return NASSTAR_SUPPORT_EMAIL;
    default:
      return NORM_SUPPORT_EMAIL;
  }
};

export const getSupportPhone = (themeName?: Themes) => {
  switch (themeName) {
    case Themes.Norm:
    case Themes.DarkNorm:
      return NORM_SUPPORT_PHONE;
    case Themes.Nasstar:
      return NASSTAR_SUPPORT_PHONE;
    default:
      return NORM_SUPPORT_PHONE;
  }
};
