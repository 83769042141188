export const radiuses = {
  small: 90,
  normal: 120,
};

export enum TooltipTitles {
  AverageConfidence = 'The reported confidence level of users throughout their training journey',
  Culture = 'The security culture within your organisation, as reported by your users',
  CurrentRefresherTests = 'Results of refresher test emails sent to users',
  CurrentTrainingStatus = 'Percentage of users who have completed their allocated modules',
  LicenseUsage = 'Number of licenses consumed and available',
  Phishing = 'Phishing results over the last 30 days',
  PhishingByMonth = 'The trend of your phishing campaigns over time',
}

export enum TrainingStatusKeys {
  Completed = 'completed',
  NotStarted = 'notStarted',
  Started = 'started',
}

export enum PhishingTooltipLabels {
  opened = 'opened',
  clicked = 'clicked',
  providedCredentials = 'providedCredentials',
}

export enum AverageConfidenceKeys {
  InitialAssessments = 'initialAssessment',
  StartedCourse = 'started',
  CompletedCourse = 'completed',
  LatestRefresher = 'latestRefresher',
}

export enum AverageConfidenceLabels {
  InitialAssessments = 'Initial Assessments',
  StartedCourse = 'Started course',
  CompletedCourse = 'Completed course',
  LatestRefresher = 'Latest refresher',
}

export enum PhishingKeys {
  NoActions = 'noActions',
  Opened = 'opened',
  Clicked = 'clicked',
  ProvidedCredentials = 'providedCredentialsOrDownloadedAnAttachment',
}
