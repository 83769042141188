/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CertificateStatusSchema {
  ORDER_RECEIVED = 'Order Received',
  PENDING_DELIVERY = 'Pending Delivery',
  ORDER_ACKNOWLEDGED = 'Order Acknowledged',
  IN_DELIVERY = 'In Delivery',
  LIVE = 'Live',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed',
  CERTIFIED = 'Certified',
}
