import { colors } from '../colors';
import { fontWeights } from '../fontWeights';

export const login = {
  logoDesktop: 'menu-logo-top-dark-norm.svg',
  logoMobile: 'normcyber_logo_mobile.svg',
  logoWidth: '110px',
  logoHeight: '25px',
  formLogo: true,
  footerLogo: undefined,
  footerLogoMobile: undefined,
  footerLogoWidth: '',
  footerLogoHeight: '',
  rightBackground: 'norm',
  altLinkColor: '#BEA167',
  altLinkColorMobile: colors.yellow[400],
  inputTwoFABorderColor: colors.brown[500],
  inputTwoFABorderColorHover: colors.brown[500],

  footerLogoColor: '',

  titleFontSize: '24px',
  titleFontWeight: fontWeights.black,
  titleLineHeight: '28px',

  strengthColors: [colors.red[400], colors.yellow[500], colors.yellow[500], colors.green[500]],
};
