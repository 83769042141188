import { renderValue } from 'pages/ServicesIntegrations/helpers';
import { DefaultCredentialsType } from 'pages/ServicesIntegrations/types';
import { ConnectionDetailsItem } from '../../ConnectionDetailsItem';

type DefaultLayoutProps = {
  credentials: DefaultCredentialsType;
};

export const DefaultLayout = ({ credentials }: DefaultLayoutProps) => {
  return <ConnectionDetailsItem label='API Token' value={renderValue(credentials?.apiToken)} />;
};
