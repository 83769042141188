import React from 'react';
import { isFirefox } from 'react-device-detect';
import { useFormik } from 'formik';
import { Form, Spin } from 'antd';
import { Box, Input } from 'shared';
import * as Yup from 'yup';
import { authApi, useLoading } from 'store';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { StyledButton } from './styled';

export const InitialForm = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loading = useLoading([authApi.resetPasswordInit.typePrefix]);
  const loadingAuth = loading[authApi.resetPasswordInit.typePrefix];

  const { handleSubmit, initialValues, values, handleChange, errors } = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is a required field'),
    }),
    onSubmit: async (values) => dispatch(authApi.resetPasswordInit(values)),
  });

  return (
    <Form layout='vertical' onFinish={handleSubmit} initialValues={initialValues}>
      <Input
        name='email'
        label={
          <Box opacity='50%' ml={theme.spacing[3]}>
            Email
          </Box>
        }
        value={values.email}
        onChange={handleChange}
        validateStatus={errors.email ? 'error' : 'success'}
        help={errors.email}
        isFeedbackCentered
      />
      {isFirefox && loadingAuth && (
        <Box display='flex' justify='center'>
          <Spin />
        </Box>
      )}
      <StyledButton
        type='primary'
        htmlType='submit'
        loading={loadingAuth}
        block
        $isHidden={isFirefox && loadingAuth}
      >
        Send me instructions
      </StyledButton>
    </Form>
  );
};
