export const tooltipTime = 800;

export const lastPosition = 4;

export enum DetailsTitle {
  Type = 'Type',
  Category = 'Category',
  VendorReference = 'Vendor reference',
  AffectedHosts = 'Affected hosts',
  PCIVulnerability = 'PCI vulnerability',
  PatchAvailable = 'Patch available',
  NormPatchAvailable = 'Norm patchable',
  PublishedDate = 'Published date',
}

export enum CVSSTitle {
  Base = 'Base',
  Temporal = 'Temporal',
  Vector = 'Vector',
}

export const SuppressionMaxDaysNumber = 90;
