import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, CustomerId, ReportsService, Timestamp } from 'services/api';
import { Themes } from '../../themes/types';
import { PAGE_HEIGHT, PAGE_WIDTH } from '../../pages/Dashboard/components/Report/config';

export type MonthlyReportRequestParams = {
  /** Customer id **/
  customerId: CustomerId;
  /** The start date parameter **/
  startDate: Timestamp;
  /** The end date parameter **/
  endDate: Timestamp;
  /** client timezone parameter **/
  timezone: string;
};

const getMonthlyReport = createAsyncThunk(
  '/customer/get/monthly-report',
  async (requestBody: MonthlyReportRequestParams) => {
    try {
      return await ReportsService.getMonthlyReport(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

function getOptionsByThemeName(themeName: Themes) {
  switch (themeName) {
    case Themes.Norm:
    case Themes.DarkNorm:
      return {
        generatorOptions: {
          width: PAGE_WIDTH,
          height: PAGE_HEIGHT,
          border: 0,
        },
      };
    default:
      return {};
  }
}

const exportMonthlyReport = createAsyncThunk(
  '/customer/get/monthly-report',
  async ({
    themeName = Themes.Norm,
    ...requestBody
  }: {
    themeName?: Themes;
    customerId: CustomerId;
    requestBody: string;
  }) => {
    try {
      return await ReportsService.exportMonthlyReport({
        ...requestBody,
        ...getOptionsByThemeName(themeName),
      });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const monthlyReportApi = { getMonthlyReport, exportMonthlyReport };
