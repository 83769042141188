import { useTheme } from 'styled-components';
import { PATH_TO_ICONS } from 'app-constants';
import { BackLink } from 'shared';
import { Routes } from 'services/entities';
import { useHistory } from 'react-router-dom';
import { BlockContainer, Code404, Code404Wrapper, Logo, PageContainer } from './styled';
import { getCssVar } from '../../styles/theme';

export const NotFound = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <PageContainer>
      <BlockContainer>
        <Code404Wrapper>
          <Code404>404</Code404>
          <h2>page not found</h2>

          <BackLink
            mainTitle='Go to dashboard'
            defaultRoute={Routes.DASHBOARD}
            onClick={() => history.push(Routes.DASHBOARD)}
          />
        </Code404Wrapper>

        <Logo
          src={`${PATH_TO_ICONS}/${theme.colorSet.login.logoDesktop}`}
          width={getCssVar('login.logoWidth')}
          height={getCssVar('login.logoHeight')}
          alt='logo'
        />
      </BlockContainer>
    </PageContainer>
  );
};
