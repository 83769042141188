/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChartResponse } from '../models/ChartResponse';
import type { CustomerId } from '../models/CustomerId';
import type { DetectionsCountResponse } from '../models/DetectionsCountResponse';
import type { GetTopHardwareResponse } from '../models/GetTopHardwareResponse';
import type { GetTopOperatingSystemResponse } from '../models/GetTopOperatingSystemResponse';
import type { IsServer } from '../models/IsServer';
import type { OSTypes } from '../models/OSTypes';
import type { Timestamp } from '../models/Timestamp';
import type { TotalItemsInScopeResponse } from '../models/TotalItemsInScopeResponse';
import type { VulnerabilityStatus } from '../models/VulnerabilityStatus';
import { request as __request } from '../core/request';

export class AssetInventoryService {
  /**
   * CE Plus Dashboard. Operating System Distribution
   * Operating System Distribution Chart
   * @returns ChartResponse Operating System Distribution chart
   * @throws ApiError
   */
  public static async getOperatingSystemDistribution({
    customerId,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<ChartResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/operating-system-distribution`,
      query: {
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when getting Operating System Distribution data`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CE Plus Dashboard. Top Operating Systems
   * Top Operating Systems
   * @returns GetTopOperatingSystemResponse Get Top Operating System by OS Type
   * @throws ApiError
   */
  public static async getTopOperatingSystem({
    customerId,
    osType,
    limit,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** operating system type **/
    osType?: OSTypes;
    /** The limit of chart items **/
    limit?: number;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<GetTopOperatingSystemResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/top-operating-system`,
      query: {
        osType: osType,
        limit: limit,
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when getting The Top Operating System By OS Type`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CE Plus Dashboard. Get Operating System versions chart for CE Plus Dashboard by OS
   * Top Operating Systems
   * @returns ChartResponse Get Operating System versions chart for CE Plus Dashboard by OS successful response
   * @throws ApiError
   */
  public static async getOperatingSystemVersions({
    customerId,
    operatingSystem,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** operating system **/
    operatingSystem?: string;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<ChartResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/operating-system-versions`,
      query: {
        operatingSystem: operatingSystem,
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when getting Operating System By OS`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CE Plus Dashboard. Get Top Hardware Manufacturer
   * Top Hardware Manufacturer Chart
   * @returns ChartResponse Get Top Hardware Manufacturer chart for CE Plus Dashboard by OS successful response
   * @throws ApiError
   */
  public static async getTopHardwareManufacturer({
    customerId,
    limit,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The limit of chart items **/
    limit?: number;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<ChartResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/top-hardware-manufacturer`,
      query: {
        limit: limit,
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when getting Operating System Distribution data`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CE Plus Dashboard. Get Top Hardware
   * Top Hardware Chart
   * @returns GetTopHardwareResponse Top Hardware chart for CE Plus Dashboard by OS successful response
   * @throws ApiError
   */
  public static async getTopHardware({
    customerId,
    isServer,
    limit,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Is Server **/
    isServer?: IsServer;
    /** The limit of chart items **/
    limit?: number;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<GetTopHardwareResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/top-hardware`,
      query: {
        isServer: isServer,
        limit: limit,
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when getting Operating System Distribution data`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CE Plus Dashboard. Get Detections Count
   * Get Detections Count
   * @returns DetectionsCountResponse Detections count for CE Plus Dashboard by OS successful response
   * @throws ApiError
   */
  public static async getDetectionsWithDateFilter({
    customerId,
    from,
    to,
    vulnerabilityStatus,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The From timestamp filter **/
    from?: Timestamp;
    /** The To timestamp filter **/
    to?: Timestamp;
    /** Vulnerability Status filter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<DetectionsCountResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/detections`,
      query: {
        from: from,
        to: to,
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when get scores`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CE Plus Dashboard. Vendor Patch Available with "vs total incl. fixed"
   * Vendor Patch Available with "vs total incl. fixed"
   * @returns TotalItemsInScopeResponse Detections count for CE Plus Dashboard by OS successful response
   * @throws ApiError
   */
  public static async getVendorPatchAvailableDetectionsTotals({
    customerId,
    vulnerabilityStatus,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Vulnerability Status filter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<TotalItemsInScopeResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/vendor-patch-available`,
      query: {
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when get scores`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CE Plus Dashboard. Total Assets In Scope with "vs total incl. fixed"
   * Total Assets In Scope with "vs total incl. fixed"
   * @returns TotalItemsInScopeResponse Total Assets In Scope with "vs total incl. fixed" for CE Plus Dashboard by OS successful response
   * @throws ApiError
   */
  public static async getTotalAssetsInScope({
    customerId,
    vulnerabilityStatus,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Vulnerability Status filter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<TotalItemsInScopeResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/total-assets-in-scope`,
      query: {
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when get scores`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * CE Plus Dashboard. Total Detections In Scope with "vs total incl. fixed"
   * Total Detections In Scope with "vs total incl. fixed"
   * @returns TotalItemsInScopeResponse Total Detections In Scope with "vs total incl. fixed" for CE Plus Dashboard by OS successful response
   * @throws ApiError
   */
  public static async getTotalDetectionsInScope({
    customerId,
    vulnerabilityStatus,
    tagIds,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Vulnerability Status filter **/
    vulnerabilityStatus?: Array<VulnerabilityStatus>;
    /** Selected tags **/
    tagIds?: Array<string>;
  }): Promise<TotalItemsInScopeResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/asset-inventory/total-detections-in-scope`,
      query: {
        vulnerabilityStatus: vulnerabilityStatus,
        tagIds: tagIds,
      },
      errors: {
        400: `An error message when get scores`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
