import styled from 'styled-components';
import { Select } from 'shared/Select/styled';
import { Theme } from 'styles/theme/types';

export const StyledSelect = styled(Select)`
  width: 277px;

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.lg}) {
    width: ${({ theme }: { theme: Theme }) => theme.sizes.full};
  }
`;
