import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AverageConfidenceResponse,
  CultureResponse,
  IncompleteTrainingTrendResponse,
  LicenseUsageResponse,
  PhishingAndTrainingActivityGridResponse,
  PhishingByMonthResponse,
  PhishingLastThirtyDaysResponse,
  RefresherRatesResponse,
  TrainingStatusResponse,
} from 'services/api';
import { cybersafetyApi } from './thunks';

interface CybersafetyState {
  phishingAndTraining: PhishingAndTrainingActivityGridResponse;
  incompleteTraining?: IncompleteTrainingTrendResponse;
  currentTrainingStatus: TrainingStatusResponse;
  phishingLastThirtyDays: PhishingLastThirtyDaysResponse;
  refresherRates: RefresherRatesResponse;
  licenseUsage: LicenseUsageResponse;
  culture: CultureResponse;
  averageConfidence: AverageConfidenceResponse;
  phishingByMonth: PhishingByMonthResponse;
}

const initialState: CybersafetyState = {
  phishingAndTraining: {},
  incompleteTraining: undefined,
  currentTrainingStatus: {},
  phishingLastThirtyDays: {},
  refresherRates: {},
  licenseUsage: {},
  culture: {},
  averageConfidence: {},
  phishingByMonth: {},
};

export const cybersafety = createSlice({
  name: 'cybersafety',
  initialState,
  reducers: {},
  extraReducers: {
    [cybersafetyApi.getPhishingAndTraining.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<PhishingAndTrainingActivityGridResponse>,
    ) => {
      state.phishingAndTraining = action.payload;
    },
    [cybersafetyApi.getPhishingAndTraining.rejected.toString()]: (state: CybersafetyState) => {
      state.phishingAndTraining = {};
    },

    [cybersafetyApi.getIncompleteTraining.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<IncompleteTrainingTrendResponse>,
    ) => {
      state.incompleteTraining = action.payload;
    },
    [cybersafetyApi.getIncompleteTraining.rejected.toString()]: (state: CybersafetyState) => {
      state.incompleteTraining = undefined;
    },

    [cybersafetyApi.getCurrentTrainingStatus.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<TrainingStatusResponse>,
    ) => {
      state.currentTrainingStatus = action.payload;
    },
    [cybersafetyApi.getCurrentTrainingStatus.rejected.toString()]: (state: CybersafetyState) => {
      state.currentTrainingStatus = {};
    },

    [cybersafetyApi.getPhishingLastThirtyDays.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<PhishingLastThirtyDaysResponse>,
    ) => {
      state.phishingLastThirtyDays = action.payload;
    },
    [cybersafetyApi.getPhishingLastThirtyDays.rejected.toString()]: (state: CybersafetyState) => {
      state.phishingLastThirtyDays = {};
    },

    [cybersafetyApi.getRefresherRates.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<RefresherRatesResponse>,
    ) => {
      state.refresherRates = action.payload;
    },
    [cybersafetyApi.getRefresherRates.rejected.toString()]: (state: CybersafetyState) => {
      state.refresherRates = {};
    },

    [cybersafetyApi.getLicenseUsage.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<LicenseUsageResponse>,
    ) => {
      state.licenseUsage = action.payload;
    },
    [cybersafetyApi.getLicenseUsage.rejected.toString()]: (state: CybersafetyState) => {
      state.licenseUsage = {};
    },

    [cybersafetyApi.getCulture.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<CultureResponse>,
    ) => {
      state.culture = action.payload;
    },
    [cybersafetyApi.getCulture.rejected.toString()]: (state: CybersafetyState) => {
      state.culture = {};
    },

    [cybersafetyApi.getAverageConfidence.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<AverageConfidenceResponse>,
    ) => {
      state.averageConfidence = action.payload;
    },
    [cybersafetyApi.getAverageConfidence.rejected.toString()]: (state: CybersafetyState) => {
      state.averageConfidence = {};
    },

    [cybersafetyApi.getPhishingByMonth.fulfilled.toString()]: (
      state: CybersafetyState,
      action: PayloadAction<PhishingByMonthResponse>,
    ) => {
      state.phishingByMonth = action.payload;
    },
    [cybersafetyApi.getPhishingByMonth.rejected.toString()]: (state: CybersafetyState) => {
      state.phishingByMonth = {};
    },
  },
});
