import { OrderBy, Filter, SeverityLevel, VulnerabilityStatus } from 'services/api';
import { Severity } from 'services/constants';
import { InputType } from '../../services/api/models/InputType';

export enum VulnerabilitiesTitle {
  Vulnerability = 'Vulnerability',
  AffectedHosts = 'Affected hosts',
  Category = 'Category',
  Severity = 'Severity',
  CVSS = 'CVSS',
  Updated = 'Updated',
}

export enum ColumnKey {
  Title = 'title',
  AffectedHosts = 'affectedHosts',
  Category = 'category',
  Severity = 'severity',
  CVSS = 'cvssScore',
  Updated = 'updated',
}

export const searchSettings = {
  placeholder: 'Search by Vulnerability title',
  fields: ['title'],
  min: 3,
};

export const severityOptions = [
  { value: SeverityLevel._5, label: Severity.Critical },
  { value: SeverityLevel._4, label: Severity.High },
  { value: SeverityLevel._3, label: Severity.Medium },
  { value: SeverityLevel._2, label: Severity.Low },
  { value: SeverityLevel._1, label: Severity.Info },
];

export const filterFields = [
  {
    field: 'severity',
    options: severityOptions,
    label: 'Severity',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'updated',
    label: 'Updated',
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export const confirmedVulnerabilitiesFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.CONFIRMED],
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const potentialVulnerabilitiesFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.CONFIRMED, VulnerabilityStatus.POTENTIAL],
      type: Filter.type.MULTIPLE,
    },
  ],
};

// TODO update filters to new ones

export const confirmedVulnerabilitiesStatusFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.CONFIRMED],
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const potentialVulnerabilitiesStatusFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.POTENTIAL],
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const confirmedAndPotentialVulnerabilitiesStatusFilter = {
  fields: [
    {
      name: 'vulnerabilityStatus',
      value: [VulnerabilityStatus.CONFIRMED, VulnerabilityStatus.POTENTIAL],
      type: Filter.type.MULTIPLE,
    },
  ],
};

export const initialOrderBy = {
  field: 'severity',
  type: OrderBy.type.DESC,
};

// Suppressed TAB

export enum SuppressedColumnsLabels {
  Vulnerability = 'Vulnerability',
  Severity = 'Severity',
  CVSS = 'CVSS',
  AffectedHosts = 'Affected hosts',
  SuppressedBy = 'Suppressed by',
  BusinessReason = 'Business reason for suppression',
  SuppressedAt = 'Suppressed at',
}

export enum SuppressedColumnsKeys {
  Title = 'title',
  Severity = 'severity',
  CVSS = 'cvssScore',
  AffectedHosts = 'affectedHosts',
  SuppressedBy = 'suppressedBy',
  BusinessReason = 'suppressReason',
  SuppressedAt = 'suppressedDate',
}

export const suppressedSearchSettings = {
  placeholder: 'Search by title/reason',
  fields: [SuppressedColumnsKeys.Title, SuppressedColumnsKeys.BusinessReason],
  min: 3,
};

export const suppressedItialOrderBy = {
  field: SuppressedColumnsKeys.SuppressedAt,
  type: OrderBy.type.ASC,
};

export const suppressedFilterFields = [
  {
    field: 'severity',
    options: severityOptions,
    label: 'Severity',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'suppressedDate',
    label: 'Suppressed date',
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export const tabsKeys = {
  active: 'active',
  potential: 'potential',
  suppressed: 'suppressed',
};
export const tabsLabels = {
  active: 'Active',
  potential: 'Potential',
  suppressed: 'Suppressed',
};

export const potentialTabTooltip =
  'Potential Vulnerabilities include vulnerabilities that cannot be fully verified. In these cases, at least one necessary condition for the vulnerability has been detected.';

export const suppressedVulnerabilitiesParams = {
  filter: { suppressedVulnerabilities: true },
  tab: tabsKeys.suppressed,
};
