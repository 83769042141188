import { TagProps } from 'antd';
import React from 'react';
import { Tag, UcFirstLetter } from 'shared';
import { useTheme } from 'styled-components';
import { SeverityOptionLabel } from '../constants';

type TagColoringProps = TagProps & {
  title: string;
};

export const TagColoring = ({ title, ...TagProps }: TagColoringProps) => {
  const theme = useTheme();

  const tagType = (title: string) => {
    switch (title) {
      case SeverityOptionLabel.URGENT:
        return theme.colors.red[700];
      case SeverityOptionLabel.CRITICAL:
        return theme.colors.red[400];
      case SeverityOptionLabel.SERIOUS:
        return theme.colors.yellow[500];
      case SeverityOptionLabel.MEDIUM:
        return theme.colors.green[300];
      default:
        return theme.colors.blue[100];
    }
  };

  return (
    <Tag color={tagType(title)} key={title} {...TagProps} title={title}>
      {UcFirstLetter(title)}
    </Tag>
  );
};
