import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'antd';
import { useRowGap } from 'services/hooks/useRowGap';
import { Box, InnerLayout } from 'shared';
import {
  AppDispatch,
  MessageType,
  newsNotificationsApi,
  useApp,
  useCustomer,
  useLoading,
} from 'store';
import { useTheme } from 'styled-components';
import { getCardPadding, getHeadStyles, getLayoutConfig } from './helpers';
import { redirectToNotificationDetails, showPopup } from '../Notifications/helpers';
import { StyledCard } from './styled';
import { Spacer } from '../../shared/TableFilters/styled';
import { Form } from '../../shared/Notification/Form';
import { CreateNotificationRequest, NotificationStatus } from '../../services/api';
import { popupConfig } from '../Notifications/constant';
import { RequestStatus } from '../../store/loading/slice';
import { showError, showMessage } from '../../services/helpers';
import { PopupConfigType } from '../../shared/ActionPopup/types';
import { NotificationPayload } from './types';
import { initialValues, onCreateMessage } from './constants';

export const CreateNotification = () => {
  const theme = useTheme();
  const { gapNumber } = useRowGap();
  const { isMobile } = useApp();
  const customerId = useCustomer();
  const dispatch = useDispatch<AppDispatch>();
  const loading = useLoading([newsNotificationsApi.createNotification.typePrefix]);
  const history = useHistory();
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);

  const layoutConfig = getLayoutConfig();

  const successMessage = useCallback(
    (notificationStatus: NotificationStatus) =>
      showMessage(
        {
          content: onCreateMessage[notificationStatus],
        },
        MessageType.Success,
      ),
    [],
  );

  const onSubmitForm = useCallback(
    async (values: CreateNotificationRequest) => {
      const requestBody = { ...values };
      const notificationStatus = requestBody.status as NotificationStatus;

      if (requestBody.status === NotificationStatus.PUBLISHED) {
        showPopup({
          popupId: popupConfig.createAndPublish.id,
          setPopupDetails,
          setIsVisiblePopup,
          notificationDetails: requestBody,
          dispatch,
          customerId,
          onSuccess: () => successMessage(notificationStatus),
          history,
        });

        return;
      }

      dispatch(newsNotificationsApi.createNotification({ customerId, requestBody })).then(
        (result) => {
          if (result.meta.requestStatus === RequestStatus.Rejected) {
            showError();

            return;
          }

          redirectToNotificationDetails(history, (result as NotificationPayload).payload.id);
          successMessage(notificationStatus);
        },
      );
    },
    [customerId, dispatch, history, successMessage],
  );

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col span={gapNumber} lg={24} xs={24} order={isMobile ? 2 : 1}>
          <StyledCard
            headStyles={getHeadStyles(isMobile, theme)}
            bodyPadding={!isMobile ? `${theme.spacing[2]}` : `${theme.spacing[0]}`}
            cardPadding={getCardPadding(isMobile, theme)}
            showLoader={loading[newsNotificationsApi.createNotification.typePrefix]}
          >
            <Box fontSize='20px' fontWeight={700}>
              Notification
            </Box>
            <Spacer h='20px' />
            <Form
              initialValues={initialValues}
              onSubmitForm={onSubmitForm}
              isVisiblePopup={isVisiblePopup}
              popupDetails={popupDetails}
              setIsVisiblePopup={setIsVisiblePopup}
            />
          </StyledCard>
        </Col>
      </Row>
    </InnerLayout>
  );
};
