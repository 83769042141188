import { Severity } from 'services/constants';
import { OptionType } from 'shared/Table/types';

export const data = {
  rows: [
    {
      vulnerability: 'No validation on file upload',
      id: '1',
      status: 'To start',
      severity: Severity.Critical,
      startDate: 1643373991946,
      endDate: 1643373991946,
      test: 'Web application — Customer portal',
    },
    {
      vulnerability: 'No validation on file upload',
      id: '2',
      status: 'Completed',
      severity: Severity.Critical,
      startDate: 1643373991946,
      endDate: 1643373991946,
      test: 'Web application — Online store',
    },
    {
      vulnerability: 'No validation on file upload',
      id: '3',
      status: 'In progress',
      severity: Severity.Critical,
      startDate: 1643373991946,
      endDate: 1643373991946,
      test: 'Internal — UK',
    },
    {
      vulnerability: 'No validation on file upload',
      id: '4',
      status: 'To start',
      severity: Severity.Critical,
      startDate: 1643373991946,
      endDate: 1643373991946,
      test: 'Internal — Norway',
    },
  ],
  count: 4,
};

export const testFilterOptions: OptionType[] = data.rows.map((row) => ({
  value: row.test,
  label: row.test,
}));
