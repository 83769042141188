import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { IEvents } from '../../../../../../ThreatDetection/types';
import { IDate } from '../../../../../../../services/interfaces';
import {
  IEChartsOption,
  LabelFormatterParams,
} from '../../../../../../../shared/charts/interfaces';
import { RadioButtonsDays } from '../../../../../../../shared/RadioButtonsWithRangePicker/constants';
import { formatData } from '../../../../../../ThreatDetection/helpers';
import { formatDate, numberFormatter } from '../../../../../../../services/helpers';
import { ReportChart } from './ReportChart';

type Values = { values: IEvents[]; date?: IDate };

const getOption = (params: Values): IEChartsOption => {
  const date: IDate = params.date
    ? params.date
    : {
        startDate: moment().subtract(RadioButtonsDays.OneMonth, 'days').startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf(),
      };

  const entries = formatData(params.values, date);
  const markPointLableMaxLength = 5;
  const isEmpty =
    entries?.length <= 1 && entries[0]?.count === 0 && entries[0]?.collectedDateTime === 0;

  return {
    grid: {
      top: 55,
      bottom: 0,
      right: 33,
      left: 58,
      height: 185,
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, '10%'],
      data: isEmpty
        ? []
        : entries.map((events: IEvents) => formatDate(`${events.collectedDateTime}`, date)),
      axisLabel: {
        fontSize: 9,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => numberFormatter(value),
        fontSize: 9,
      },
      min: 0,
    },
    series: [
      {
        data: entries.map((events: IEvents) => events.count),
        type: 'line',
        name: 'Total',
        lineStyle: {
          width: 1,
        },
        markPoint: {
          symbolSize: 35,
          data: [
            { type: 'max', name: 'Max' },
            { type: 'min', name: 'Min' },
          ],
          label: {
            fontSize: 9,
            show: true,
            formatter: (_params: LabelFormatterParams) => {
              const value = numberFormatter(_params.data.value);

              if (`${value}`.length >= markPointLableMaxLength) {
                return `${value}`.split('K').join('\nK');
              }

              return value;
            },
          },
        },
        markLine: isEmpty
          ? {}
          : {
              data: [
                {
                  type: 'average',
                  name: 'Average',
                },
              ],
              label: {
                show: false,
              },
              silent: true,
              symbol: 'none',
            },
        symbol: 'emptyCircle',
        areaStyle: { opacity: 0.2 },
        showSymbol: !isEmpty,
        symbolSize: 4,
        silent: true,
      },
    ],
  };
};

interface Props {
  onChartLoaded: () => void;
  values: Values;
  height: string;
  width: string;
}

export const Wrapper = styled.div`
  > div {
    background: transparent;
    z-index: 0;
  }
`;

export function AreaReportChart({ width, height, values, onChartLoaded }: Props) {
  return (
    <Wrapper>
      <ReportChart
        width={width}
        height={height}
        onFinished={onChartLoaded}
        option={getOption(values)}
      />
    </Wrapper>
  );
}
