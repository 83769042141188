import React from 'react';
import { Wrapper } from './styled';
import { Action, Goals } from '../styled';

export function PdfPage2() {
  return (
    <Wrapper>
      <Goals>
        <p>
          Goal 1: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam earum iste
          libero nisi nulla quae repellendus reprehenderit veritatis?
        </p>
        <p>
          Goal 2: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam earum iste
          libero nisi nulla quae repellendus reprehenderit veritatis?
        </p>
        <p>
          Goal 3: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam earum iste
          libero nisi nulla quae repellendus reprehenderit veritatis?
        </p>
        <p>
          Goal 4: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam earum iste
          libero nisi nulla quae repellendus reprehenderit veritatis?
        </p>
      </Goals>
      <Action>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam asperiores aut beatae
          blanditiis cumque exercitationem, hic in inventore ipsum laborum maxime minima nam
          recusandae velit veniam voluptas voluptate? Consequuntur, quasi! Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Aliquam asperiores aut beatae blanditiis cumque
          exercitationem, hic in inventore ipsum laborum maxime minima nam recusandae velit veniam
          voluptas voluptate? Consequuntur, quasi!
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam asperiores aut beatae
          blanditiis cumque exercitationem, hic in inventore ipsum laborum maxime minima nam
          recusandae velit veniam voluptas voluptate? Consequuntur, quasi! Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Aliquam asperiores aut beatae blanditiis cumque.
        </p>
      </Action>
    </Wrapper>
  );
}
