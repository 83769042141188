/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AverageConfidenceResponse } from '../models/AverageConfidenceResponse';
import type { CultureResponse } from '../models/CultureResponse';
import type { CustomerId } from '../models/CustomerId';
import type { Filters } from '../models/Filters';
import type { IncompleteTrainingTrendResponse } from '../models/IncompleteTrainingTrendResponse';
import type { LicenseUsageResponse } from '../models/LicenseUsageResponse';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { PhishingAndTrainingActivityGridResponse } from '../models/PhishingAndTrainingActivityGridResponse';
import type { PhishingByMonthResponse } from '../models/PhishingByMonthResponse';
import type { PhishingLastThirtyDaysResponse } from '../models/PhishingLastThirtyDaysResponse';
import type { RefresherRatesResponse } from '../models/RefresherRatesResponse';
import type { Timestamp } from '../models/Timestamp';
import type { TrainingStatusResponse } from '../models/TrainingStatusResponse';
import type { VulnerabilityTrendPointTypeSchema } from '../models/VulnerabilityTrendPointTypeSchema';
import { request as __request } from '../core/request';

export class CybersafetyAndPhishingService {
  /**
   * Phishing and training
   * Phishing and training activity grid
   * @returns PhishingAndTrainingActivityGridResponse Phishing and training activity
   * @throws ApiError
   */
  public static async getPhishingAndTraining({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<PhishingAndTrainingActivityGridResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/phishing-and-training`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when getting cybersafety statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Calculated current training status in dashboard for chart
   * Get calculated training status for chart in dashboard
   * @returns TrainingStatusResponse Cybsafe training status
   * @throws ApiError
   */
  public static async getCurrentTrainingStatus({
    customerId,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
  }): Promise<TrainingStatusResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cybersafety/current-training-status`,
      errors: {
        400: `An error message when getting cybersafety statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Calculated phishing by last 30 days in dashboard for chart
   * Get phishing by last 30 days for chart in dashboard
   * @returns PhishingLastThirtyDaysResponse Cybsafe phishing last thirty days status
   * @throws ApiError
   */
  public static async getPhishingLastThirtyDays({
    customerId,
    timezoneOffset,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
    /** client timezone offset parameter **/
    timezoneOffset: number;
  }): Promise<PhishingLastThirtyDaysResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cybersafety/phishing-last-thirty-days`,
      query: {
        timezoneOffset: timezoneOffset,
      },
      errors: {
        400: `An error message when getting cybersafety statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Getting refresher rates from cybersafety API
   * Get refresher rates from cybersafety API for chart in dashboard
   * @returns RefresherRatesResponse Cybsafe refresher rates
   * @throws ApiError
   */
  public static async getRefresherRates({
    customerId,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
  }): Promise<RefresherRatesResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cybersafety/refresher-rates`,
      errors: {
        400: `An error message when getting cybersafety statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Getting license usage from cybersafety API
   * Get license usage from cybersafety API for chart in dashboard
   * @returns LicenseUsageResponse Cybsafe license usage
   * @throws ApiError
   */
  public static async getLicenseUsage({
    customerId,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
  }): Promise<LicenseUsageResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cybersafety/license-usage`,
      errors: {
        400: `An error message when getting cybersafety license usage statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Getting culture from cybersafety API
   * Get culture from cybersafety API for chart in dashboard
   * @returns CultureResponse Cybsafe culture statistics
   * @throws ApiError
   */
  public static async getCulture({
    customerId,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
  }): Promise<CultureResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cybersafety/culture`,
      errors: {
        400: `An error message when getting cybersafety culture statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Getting average confidence from cybersafety API
   * Get average confidence from cybersafety API for chart in dashboard
   * @returns AverageConfidenceResponse Cybsafe average confidence statistics
   * @throws ApiError
   */
  public static async getAverageConfidence({
    customerId,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
  }): Promise<AverageConfidenceResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cybersafety/average-confidence`,
      errors: {
        400: `An error message when getting cybersafety average confidence statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Getting phishing by month from cybersafety API
   * Get phishing by month from cybersafety API for chart in dashboard
   * @returns PhishingByMonthResponse Cybsafe Phishing by month statistics
   * @throws ApiError
   */
  public static async getPhishingByMonth({
    customerId,
    timezoneOffset,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
    /** client timezone offset parameter **/
    timezoneOffset: number;
  }): Promise<PhishingByMonthResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cybersafety/phishing-by-month`,
      query: {
        timezoneOffset: timezoneOffset,
      },
      errors: {
        400: `An error message when getting cybersafety phishing by month statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Get incomplete training trend
   * Get incomplete training trend for chart in dashboard top block
   * @returns IncompleteTrainingTrendResponse Cybsafe incomplete training trend
   * @throws ApiError
   */
  public static async getIncompleteTrainingTrend({
    customerId,
    startDate,
    endDate,
    pointType,
  }: {
    /** Customer ID **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate?: Timestamp;
    /** The end date parameter **/
    endDate?: Timestamp;
    /** Chart point type **/
    pointType?: VulnerabilityTrendPointTypeSchema;
  }): Promise<IncompleteTrainingTrendResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/cybersafety/incomplete-training-trend`,
      query: {
        startDate: startDate,
        endDate: endDate,
        pointType: pointType,
      },
      errors: {
        400: `An error message when getting cybersafety statistics`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
