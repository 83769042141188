import { sum } from 'lodash';
import { Theme } from 'styles/theme/types';
import { LabelFormatterParams } from '../interfaces';
import { Colors, SegmentedPieData, Values } from '../types';
import { ICustomPieChartTooltipProps } from '../formatters';
import { numberFormatter } from '../../../services/helpers';

const START_ANGLE = 90;

export const getData = (values: Values, colors?: Colors) => {
  return Object.keys(values).reduce(
    (result: SegmentedPieData, key) => [
      ...result,
      {
        name: key,
        value: values[key],
        itemStyle: { color: colors && colors[key] },
      },
    ],
    [],
  );
};

const subTitleFormatterSum = (data: number[], float = 0): number => {
  const sum = data?.reduce((accumulator: number, value = 0) => accumulator + value, 0);

  return numberFormatter(sum, float) as number;
};

export const getOption = ({
  values,
  theme,
  colors,
  entitiesName,
  isMobile,
  noPercentageLabel,
  hasClickHandler,
  getCustomDataHandler,
  tooltip,
  customTotal,
  minShowLabelAngle,
  startAngle = START_ANGLE,
}: {
  values: Values;
  theme: Theme;
  colors?: Colors;
  entitiesName: string;
  isMobile?: boolean;
  noPercentageLabel?: boolean;
  hasClickHandler?: boolean;
  getCustomDataHandler?: (values: Values, colors?: Colors) => SegmentedPieData;
  tooltip?: ICustomPieChartTooltipProps;
  customTotal?: string;
  minShowLabelAngle?: number;
  startAngle?: number;
}) => {
  const totalValue = sum(Object.values(values));
  const label = {
    fontSize: isMobile ? theme.sizes['3.5'] : theme.sizes[4],
    color: theme.colorSet.textColor,
  };
  const seriesCommonSettings = {
    type: 'pie',
    radius: isMobile ? ['45%', '70%'] : ['45%', '70%'],
    center: ['50%', '50%'],
  };

  const data = getCustomDataHandler
    ? getCustomDataHandler(values, colors)
    : getData(values, colors);

  const totalText = customTotal ?? `Total ${entitiesName}`;

  const percentageLabel = noPercentageLabel
    ? null
    : {
        ...seriesCommonSettings,
        name: 'layer1',
        label: {
          ...label,
          position: 'outside',
          formatter: (params: LabelFormatterParams) => `${Math.round(params.percent)}%`,
          alignTo: 'none',
          bleedMargin: 0,
          color: theme.colorSet.textColor,
        },
        emphasis: { scale: false },
        data,
        cursor: hasClickHandler ? 'pointer' : 'auto',
        silent: true,
      };

  return {
    ...tooltip,
    legend: { show: false },
    title: {
      text: entitiesName
        ? `{totalValue|${subTitleFormatterSum([totalValue], 0)}}\n{entitiesName|${totalText}}`
        : null,
      left: 'center',
      top: 'center',
      textStyle: {
        rich: {
          totalValue: {
            color: theme.colorSet.textColor,
            fontSize: isMobile ? theme.sizes[7] : theme.sizes[9],
            fontWeight: theme.fontWeights.black,
            lineHeight: 36,
          },
          entitiesName: {
            fontWeight: theme.fontWeights.normal,
            fontSize: isMobile ? theme.sizes[3] : theme.sizes[4],
            color: theme.colorSet.textColor,
          },
        },
      },
    },
    series: [
      {
        ...seriesCommonSettings,
        minAngle: minShowLabelAngle ?? 0,
        name: 'total',
        label: {
          show: false,
          fontWeight: theme.fontWeights.normal,
          fontSize: isMobile ? theme.sizes[3] : theme.sizes[4],
          color: theme.colorSet.textColor,
        },
        startAngle,
        data,
        emphasis: {
          scale: !tooltip,
          itemStyle: tooltip
            ? {
                opacity: -1,
              }
            : {},
        },
      },
      percentageLabel,
    ],
    textStyle: {
      fontFamily: theme.colorSet.fontFamily,
      fontWeight: theme.fontWeights.bold,
    },
    cursor: hasClickHandler ? 'pointer' : 'auto',
  };
};
