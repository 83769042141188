import { createSlice } from '@reduxjs/toolkit';
import { FilteredUsersResponse, UserDetailsResponse, UserPermissionsResponse } from 'services/api';
import { adminUsersApi } from './thunks';

type AdminUsersState = {
  data: FilteredUsersResponse;
  userDetails: UserDetailsResponse;
  permissions: UserPermissionsResponse;
};

const initialState: AdminUsersState = {
  data: {} as FilteredUsersResponse,
  userDetails: {} as UserDetailsResponse,
  permissions: {} as UserPermissionsResponse,
};

export const adminUsers = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {
    setSuccess: (state: AdminUsersState, action: { payload: boolean }) => ({
      ...state,
      success: action.payload,
    }),
  },
  extraReducers: {
    [adminUsersApi.adminUsersTableContent.rejected.toString()]: (state: AdminUsersState) => {
      state.data = {};
    },
    [adminUsersApi.adminUsersTableContent.fulfilled.toString()]: (
      state: AdminUsersState,
      action: { payload: FilteredUsersResponse },
    ) => {
      state.data = action.payload;
    },

    [adminUsersApi.userDetails.fulfilled.toString()]: (
      state: AdminUsersState,
      action: { payload: UserDetailsResponse },
    ) => {
      state.userDetails = action.payload;
    },

    [adminUsersApi.userPermissions.fulfilled.toString()]: (
      state: AdminUsersState,
      action: { payload: UserPermissionsResponse },
    ) => {
      state.permissions = action.payload;
    },
  },
});
