import React from 'react';
import { useDispatch } from 'react-redux';
import { PopupConfigType } from 'shared/ActionPopup/types';
import { ActionPopup } from 'shared';
import { useApp, useCustomer } from 'store';
import { Popup } from './styled';
import { getCustomMenu, showPopup } from '../helpers';
import { DropdownActionsProps } from './DropdownActions';
import { actions } from '../constant';

export interface MobileActionsPopupProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

export const MobileActionsPopup = ({
  isVisible,
  setIsVisible,
  tag,
  onSuccess,
}: MobileActionsPopupProps & DropdownActionsProps) => {
  const customerId = useCustomer();
  const { isMobile } = useApp();

  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false);
  const [popupDetails, setPopupDetails] = React.useState<PopupConfigType | null>(null);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleClickAction = (popupId: string) => {
    handleCancel();
    if (tag.id && tag.name) {
      const tagDetails = { id: tag.id, name: tag.name };

      showPopup({
        popupId,
        setPopupDetails,
        setIsVisiblePopup,
        tagData: tagDetails,
        dispatch,
        customerId,
        onSuccess,
        isMobile,
      });
    }
  };

  return (
    <>
      {isVisible && (
        <Popup
          className='mobile-actions-modal'
          title='Actions'
          visible={isVisible}
          width={240}
          footer={null}
          onCancel={handleCancel}
          closable={false}
        >
          {getCustomMenu(actions, handleClickAction)}
        </Popup>
      )}

      {isVisiblePopup && popupDetails && (
        <ActionPopup
          isVisible={isVisiblePopup}
          popupDetails={popupDetails}
          setIsVisiblePopup={setIsVisiblePopup}
          inputType='textarea'
        />
      )}
    </>
  );
};
