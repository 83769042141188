import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cybersafetyApi, useApp, useCurrentTrainingStatus, useCustomer, useLoading } from 'store';
import { isEmptyObject } from 'services/helpers';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { Card } from 'shared';
import { SegmentedPie } from 'shared/charts/SegmentedPie/SegmentedPie';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { useTheme } from 'styled-components';
import { TooltipTitles } from '../../constants';
import { getRedirectParams } from './helpers';

export const CurrentTrainingStatus = () => {
  const history = useHistory();
  const theme = useTheme();
  const { isMobile, isMenuCollapsed } = useApp();
  const customerId = useCustomer();
  const dispatch = useDispatch();
  const currentTrainingStatus = useCurrentTrainingStatus();
  const loading = useLoading([cybersafetyApi.getCurrentTrainingStatus.typePrefix]);

  const trainingColors = {
    completed: theme.colorSet.pieChart.CurrentTrainingStatusSuccess,
    started: theme.colorSet.pieChart.CurrentTrainingStatusStarted,
    notStarted: theme.colorSet.pieChart.CurrentTrainingStatusNotStarted,
  };

  const totalClickHandler = () => {
    history.push(`${Routes.PEOPLE_PHISHING}`);
  };

  const redirectToPhishing = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params.name) {
        return;
      }

      history.push(`${Routes.PEOPLE_PHISHING}${getQueryString(getRedirectParams(params.name))}`);
    },
    [history],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToPhishing(params),
    }),
    [redirectToPhishing],
  );

  const renderChart = () => {
    if (isEmptyObject(currentTrainingStatus)) {
      return emptyMessage('No training status data yet');
    }

    return (
      <SegmentedPie
        style={isMobile ? { height: '240px' } : { height: '320px' }}
        values={currentTrainingStatus}
        isLoading={loading[cybersafetyApi.getCurrentTrainingStatus.typePrefix]}
        colors={trainingColors}
        entitiesName='users'
        isMobile={isMobile}
        isMenuCollapsed={isMenuCollapsed}
        onEvents={onEvents}
        totalClickHandler={totalClickHandler}
      />
    );
  };

  React.useEffect(() => {
    dispatch(
      cybersafetyApi.getCurrentTrainingStatus({
        customerId,
      }),
    );
  }, [dispatch, customerId]);

  return (
    <Card
      title='Current training status'
      showDivider={false}
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles.CurrentTrainingStatus}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2] }
          : { titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={!isMobile ? theme.sizes[6] : theme.sizes[5]}
      showLoader={loading[cybersafetyApi.getCurrentTrainingStatus.typePrefix]}
    >
      {renderChart()}
    </Card>
  );
};
