import { CSSProperties, DetailedHTMLProps, FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

export type BoxProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  Omit<CSSProperties, 'fontWeight'> & {
    h?: string; // height
    w?: string; // width
    maxH?: string;
    maxW?: string;
    minH?: string;
    minW?: string;
    d?: string; // display
    justify?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
    align?: 'space-between' | 'space-around' | 'center' | 'flex-start' | 'flex-end';
    m?: string;
    mb?: string;
    mt?: string;
    ml?: string;
    mr?: string;
    mx?: string;
    my?: string;
    p?: string;
    pb?: string;
    pt?: string;
    pl?: string;
    pr?: string;
    px?: string;
    py?: string;
    t?: string;
    b?: string;
    r?: string;
    l?: string;
    flex?: string;
    bgColor?: string | null;
    flexShrink?: number;
    paddingBottom?: string;
    paddingTop?: string;
    textOverflow?: string;
    textTransform?: 'capitalize' | 'lowercase' | 'uppercase';
    fontWeight?: string | number;
  };

export const Box: FunctionComponent<BoxProps> = styled.div<BoxProps>`
  align-items: ${(props) => props.alignItems || props.align};
  background-color: ${(props) => props.bgColor || props.backgroundColor};
  background-image: ${(props) => props.backgroundImage};
  background-position: ${(props) => props.backgroundPosition};
  background-size: ${(props) => props.backgroundSize};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  border-color: ${(props) => props.borderColor};
  border-radius: ${(props) => props.borderRadius};
  border-right: ${(props) => props.borderRight};
  border-top: ${(props) => props.borderTop};
  bottom: ${(props) => props.bottom || props.b};
  box-shadow: ${(props) => props.boxShadow};
  color: ${(props) => props.color};
  cursor: ${(props) => props.cursor};
  display: ${(props) => props.display || props.d};
  flex: ${(props) => props.flex};
  flex-direction: ${(props) => props.flexDirection};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
  flex-wrap: ${(props) => props.flexWrap};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  gap: ${(props) => props.gap};
  height: ${(props) => props.height || props.h};
  justify-content: ${(props) => props.justifyContent || props.justify};
  left: ${(props) => props.left || props.l};
  letter-spacing: ${(props) => props.letterSpacing};
  line-height: ${(props) => props.lineHeight};
  margin: ${(props) => props.margin || props.m};
  margin-bottom: ${(props) => props.marginBottom || props.mb || props.my};
  margin-left: ${(props) => props.marginLeft || props.ml || props.mx};
  margin-right: ${(props) => props.marginRight || props.mr || props.mx};
  margin-top: ${(props) => props.marginTop || props.mt || props.my};
  max-width: ${(props) => props.maxW};
  min-height: ${(props) => props.minH};
  min-width: ${(props) => props.minW};
  opacity: ${(props) => props.opacity};
  overflow: ${(props) => props.overflow};
  padding: ${(props) => props.padding || props.p};
  padding-bottom: ${(props) => props.paddingBottom || props.pb || props.py};
  padding-left: ${(props) => props.paddingLeft || props.pl || props.px};
  padding-right: ${(props) => props.paddingRight || props.pr || props.px};
  padding-top: ${(props) => props.paddingTop || props.pt || props.py};
  position: ${(props) => props.position};
  right: ${(props) => props.right || props.r};
  text-align: ${(props) => props.textAlign};
  text-decoration: ${(props) => props.textDecoration};
  text-overflow: ${(props) => props.textOverflow};
  text-transform: ${(props) => props.textTransform};
  top: ${(props) => props.top || props.t};
  visibility: ${(props) => props.visibility};
  white-space: ${(props) => props.whiteSpace};
  width: ${(props) => props.width || props.w};
  z-index: ${(props) => props.zIndex};
`;
