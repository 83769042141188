/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditActionTypesResponse } from '../models/AuditActionTypesResponse';
import type { AuditLogsResponse } from '../models/AuditLogsResponse';
import type { Filters } from '../models/Filters';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import { request as __request } from '../core/request';

export class LogsService {
  /**
   * Logs list
   * Get list of audit-logs
   * @returns AuditLogsResponse List of audit-logs and amount of them
   * @throws ApiError
   */
  public static async getAuditLogs({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: string;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<AuditLogsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/audit-logs`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when get audit logs list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Logs list
   * Get list of action types
   * @returns AuditActionTypesResponse List of action types
   * @throws ApiError
   */
  public static async getActionTypes({
    customerId,
  }: {
    /** Customer id **/
    customerId: string;
  }): Promise<AuditActionTypesResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/audit-logs/get-action-types`,
      errors: {
        400: `An error message when get audit logs list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
