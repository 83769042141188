import { VulnerabilityDetails } from 'services/api';
import { VulnerabilityTitle } from 'pages/CmdbHostDetails/styled';
import { getCapitalizedString, getRenderValue } from 'services/helpers';
import { Box, SuppressedAtColumn, TagColoring } from 'shared';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { ColumnsType } from 'antd/es/table/interface';
import { CroppedText } from 'shared/CroppedText';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { TableTitles } from './constants';

export const columns: ColumnsType<VulnerabilityDetails> = [
  {
    key: 'subject',
    title: TableTitles.Subject,
    dataIndex: 'subject',
    render: (subject: string) => (
      <VulnerabilityTitle w='280px'>
        <span>{getRenderValue(subject, NO_VALUE_LABEL)}</span>
      </VulnerabilityTitle>
    ),
  },
  {
    key: 'severity',
    title: TableTitles.Severity,
    dataIndex: 'severity',
    className: 'center-head-title',
    align: 'center',
    render: (severity: string) => {
      const severityRender = severity ? (
        <TagColoring title={getCapitalizedString(getSeverityLevelName(severity))} />
      ) : (
        emptyDataString
      );

      return <Box>{severityRender}</Box>;
    },
    sorter: false,
    showSorterTooltip: false,
  },
  {
    key: 'suppressedBy',
    title: TableTitles.SuppressedBy,
    dataIndex: 'suppressedBy',
    width: '160px',
    className: 'right-head-title',
    align: 'right',
    render: (name: string) => (
      <Box w='160px'>
        <Box>{getRenderValue(name, NO_VALUE_LABEL)}</Box>
      </Box>
    ),
    sorter: false,
    showSorterTooltip: false,
  },
  {
    key: 'suppressReason',
    title: TableTitles.BusinessReason,
    dataIndex: 'suppressReason',
    width: '160px',
    className: 'right-head-title',
    render: (reason: string) => (
      <Box w='160px'>
        <CroppedText
          text={getRenderValue(reason, NO_VALUE_LABEL) as string}
          maxLength={80}
          withTooltip
        />
      </Box>
    ),
    sorter: false,
    showSorterTooltip: false,
  },
  {
    key: 'suppressedAt',
    title: TableTitles.SuppressedAt,
    dataIndex: 'suppressedAt',
    width: '160px',
    className: 'right-head-title',
    align: 'right',
    render: (date: string, record: VulnerabilityDetails, index: number) => (
      <SuppressedAtColumn date={date} record={record} index={index} />
    ),
    sorter: false,
    showSorterTooltip: false,
  },
];
