import { Table as CustomTable, Button } from 'shared';
import styled from 'styled-components';
import { Theme } from 'styles/theme/types';

export const Table = styled(CustomTable)`
  .ant-table-thead {
    .ant-table-cell,
    .ant-table-cell:first-child,
    .ant-table-cell:last-child {
      @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
        padding: ${({ theme }: { theme: Theme }) => `${theme.spacing[3]} ${theme.spacing['1.5']}`};
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell,
      .ant-table-cell:first-child,
      .ant-table-cell:last-child {
        @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.md}) {
          padding: ${({ theme }: { theme: Theme }) =>
            `${theme.spacing[2]} ${theme.spacing['1.5']}`};
        }
      }
    }
    > tr {
      > td {
        height: ${({ theme }: { theme: Theme }) => theme.sizes[10]};
      }
    }
  }
`;

export const ButtonStyled = styled(Button)`
  max-width: 100%;
`;
