export enum DescriptionTitle {
  Common_Data = 'Common data',
  File_Data = 'File data',
  Device_Data = 'Device data',
  Network_Data = 'Network data',
  Email_Data = 'Email data',
}

export enum CommonData {
  description = 'Description',
  enrichmentData = 'Enrichment data',
  eventIOCDetails = 'Event IOC details',
}

export enum SourceData {
  origin = 'Origin',
  status = 'Status',
  created = 'Created',
  updated = 'Last updated',
  resolved = 'Resolved',
  closureReason = 'Closure reason',
  relatedIncident = 'Related incident ID',
  mitreAttackId = 'MITRE ATT&CK ID',
}

export enum FileData {
  processId = 'Process ID',
  fileNames = 'File names',
  sourceProcess = 'Source process',
  parentProcessName = 'Parent process name',
  parentProcessPid = 'Parent process id',
  fileHash = 'File hash',
  commandLine = 'Command line',
  decodedCommandLine = 'Decoded command line',
}

export enum DeviceData {
  host = 'Computer name',
  userName = 'User name',
  domain = 'Domain',
}

export enum NetworkData {
  sourceIp = 'Source IP',
  destIp = 'Destination IP',
  reportingIP = 'Reporting IP',
}

export enum EmailData {
  emailClassification = 'Email classification',
  emailFrom = 'Email from',
  emailTo = 'Email recipients (To)',
  emailSubject = 'Email subject',
  emailBody = 'Email body',
}
