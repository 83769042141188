export const ANDROID_GOOGLE_AUTHENTICATOR_URL =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US';

export const ANDROID_AUTHY_URL =
  'https://play.google.com/store/apps/details?id=com.authy.authy&hl=ru&gl=US';

export const IOS_GOOGLE_AUTHENTICATOR_URL =
  'https://apps.apple.com/us/app/google-authenticator/id388497605';

export const IOS_AUTHY_URL = 'https://apps.apple.com/us/app/twilio-authy/id494168017';
