import React, { useEffect } from 'react';
import moment from 'moment';
import { MonthlyReportResponse } from 'services/api';
import { IDate } from 'services/interfaces';
import { devicesImageUrl } from '../constants';
import { ReportPageLayout } from '../components/ReportPageLayout';
import { BoostScoreTable } from '../components/BoostScoreTable';
import { ReportTableSection } from '../components/ReportTableSection';

const renderPenetrationTestingContent = (hasPenetrationTestingProduct?: boolean) => {
  if (hasPenetrationTestingProduct)
    return (
      <>
        <table className='table-border-vr text-center'>
          <tbody>
            <tr>
              <td>
                <h4>INTERNAL</h4>
                <p className='mb-2 text-center'>
                  Last Test: 15/01/22 <br /> Last Re-Test: 13/03/22
                </p>
              </td>
              <td>
                <h4>EXTERNAL</h4>
                <p className='mb-2 text-center'>
                  Last Test: 22/01/22 <br /> Last Re-Test: 30/03/22
                </p>
              </td>
              <td>
                <h4>WEB APPLICATION(S)</h4>
                <p className='mb-2 text-center'>
                  Last Test: Various <br /> Last Re-Test: Various
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <h3 className='text-center font-size-14 my-2'>
                  Urgent to Critical Vulnerabilities Discovered (and After Re-Test)
                </h3>
              </td>
            </tr>
            <tr>
              <td>
                <p className='h2 text-normal text-center'>
                  6 (<span className='color-green'>1</span>)
                </p>
              </td>

              <td>
                <p className='h2 text-normal text-center'>
                  12 (<span className='color-green'>2</span>)
                </p>
              </td>
              <td>
                <p className='h2 text-normal text-center'>
                  8 (<span className='color-green'>2</span>)
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <br />
        </p>
        <p>
          Nunc tincidunt ultricies nisi. Nulla ut viverra lorem. Vestibulum mattis pretium augue, a
          pulvinar enim auctor eget. Nulla quis blandit diam. Pellentesque faucibus, mauris sed
          mollis viverra, velit sapien aliquet mauris, id placerat felis nunc id diam. Fusce auctor
          augue at tincidunt pretium. Nullam at elit vel risus luctus tristique a id augue.
          Phasellus efficitur posuere est nec mattis. Proin efficitur sit amet nisl at fermentum.
          Integer diam urna, placerat a sem id, rutrum condimentum quam.
        </p>
      </>
    );

  return (
    <>
      <table className='table-border-vr text-center'>
        <tbody>
          <tr>
            <td>
              <h4>INTERNAL</h4>
              <p className='mb-2 text-center'>
                Last Test: — <br /> Last Re-Test: —
              </p>
            </td>
            <td>
              <h4>EXTERNAL</h4>
              <p className='mb-2 text-center'>
                Last Test: — <br /> Last Re-Test: —
              </p>
            </td>
            <td>
              <h4>WEB APPLICATION(S)</h4>
              <p className='mb-2 text-center'>
                Last Test: — <br /> Last Re-Test: —
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <h3 className='text-center font-size-14 my-2'>
                Urgent to Critical Vulnerabilities Discovered (and After Re-Test)
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p className='h2 text-normal text-center'>0 (0)</p>
            </td>

            <td>
              <p className='h2 text-normal text-center'>0 (0)</p>
            </td>
            <td>
              <p className='h2 text-normal text-center'>0 (0)</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <br />
      </p>
      <p>
        You currently do not purchase regular Penetration Testing from norm. If you would like to
        add this, please speak to your account manager whose details can be found at the end of this
        report.
      </p>
    </>
  );
};

export const PdfPage4 = ({
  data,
  onChartLoaded,
  reportDate,
}: {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  reportDate: IDate;
}) => {
  const { boostScoreItems, hasPenetrationTestingProduct } = data;
  const boostScore = data?.dashboardScores?.[data?.dashboardScores?.length - 1]?.boost;

  useEffect(() => {
    if (hasPenetrationTestingProduct) {
      onChartLoaded();
    }
    // eslint-disable-next-line
  }, [hasPenetrationTestingProduct]);

  return (
    <ReportPageLayout
      headerSubtitle={`Your ${moment(reportDate.startDate).format(
        'MMMM YYYY',
      )} update. Penetration Tests, Boost Your Score.`}
    >
      <ReportTableSection
        onLoad={onChartLoaded}
        title='Penetration Testing'
        showScore={!hasPenetrationTestingProduct}
        score={null}
      >
        {renderPenetrationTestingContent(hasPenetrationTestingProduct)}
      </ReportTableSection>

      <ReportTableSection
        onLoad={onChartLoaded}
        showScore
        title='Boost Your Score'
        score={boostScore || 0}
      >
        <p>
          There are several easy to implement controls that form the basis of a good security
          posture for any business. In <b>norm</b>’s experience, the implementation of these four
          basic controls would have prevented the vast majority of the breaches we have seen. To
          improve your cyber resilience score, implement these controls and update the “Boost score”
          section on the smartbloc™LIVE home page.
        </p>
        <p>
          <br />
        </p>

        <BoostScoreTable boostScoreItems={boostScoreItems} />
        <hr />

        <table>
          <tbody>
            <tr className='row-spacing'>
              <td>
                <h3>Did you know?</h3>
                <p>
                  You can access smartbloc™LIVE via your smartphone and tablet. Giving you access to
                  your Cyber Resilience score on-the-go with near-real-time updates on the status of
                  your network. Including, vulnerability information, threat data, and allowing you
                  to see any updates to active incidents.
                </p>
                <p>
                  You can also request more information about any of the other <b>norm</b> services
                  that might enhance your security posture.
                </p>
              </td>
              <td>
                <p>
                  <img width='230' height='130' alt='Devices' src={devicesImageUrl} />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </ReportTableSection>
    </ReportPageLayout>
  );
};
