import React from 'react';
import { Icon } from 'shared';
import { icons } from 'styles/theme';
import { AssetStatus } from 'services/api';
import { useTheme } from 'styled-components';

type IconColoringProps = {
  status: AssetStatus;
};

export const IconColoring = ({ status }: IconColoringProps) => {
  const theme = useTheme();

  const statusType = (status: AssetStatus) => {
    switch (status) {
      case AssetStatus.SCANNED:
        return theme.colors.green[500];
      case AssetStatus.ASSIGNED:
        return theme.colors.blue[100];
      case AssetStatus.FAILED:
        return theme.colors.red[700];
      case AssetStatus.PENDING_REBOOT:
        return theme.colors.red[400];
      case AssetStatus.REBOOTED:
        return theme.colors.green[500];
      case AssetStatus.READY:
        return theme.colors.yellow[500];
      case AssetStatus.PREPARING:
        return theme.colors.yellow[500];
      case AssetStatus.ASSIGNING:
        return theme.colors.yellow[500];
      case AssetStatus.CONFIGURED:
        return theme.colors.blue[100];
      default:
        return theme.colors.green[200];
    }
  };

  return (
    <Icon
      component={icons.fiber_manual_record}
      color={statusType(status)}
      dimension='10px'
      margin='0 8px 2px 0'
    />
  );
};
