import { createSlice } from '@reduxjs/toolkit';
import { cyberEssentialPlusApi } from './thunks';
import { DetectionsCountResponse, TotalItemsInScopeResponse } from '../../services/api';
import { VulnerabilityDetectionsGap } from '../../pages/CEPlus/constants';

export type CePlusChartProps = {
  operationSystem?: string;
  product?: string;
  title?: string;
  name?: string;
  manufacturer?: string;
  count: number;
};

type CePlusListProps = {
  chart?: Array<CePlusChartProps>;
  count?: number;
};

interface CyberEssentialPlusState {
  detectionsLastWeek: { title?: string; count?: number };
  detectionsPenultimateWeek: { title?: string; count?: number };
  detectionsAfterTwoWeeks: { title?: string; count?: number };
  totalAssetsInScope: TotalItemsInScopeResponse;
  totalVulnerabilitiesInScope: TotalItemsInScopeResponse;
  vendorPatchAvailable: TotalItemsInScopeResponse;
  getOperatingSystemDistribution: CePlusListProps;
  topClientOperatingSystem: CePlusListProps;
  topServerOperatingSystem: CePlusListProps;
  topOS: CePlusListProps;
  topHardwareManufacturer: CePlusListProps;
  topClientHardware: CePlusListProps;
  topServerHardware: CePlusListProps;
  selectedTags: string[];
}

export const cyberEssentialPlusInitialState: CyberEssentialPlusState = {
  detectionsLastWeek: {},
  detectionsPenultimateWeek: {},
  detectionsAfterTwoWeeks: {},
  totalAssetsInScope: {},
  totalVulnerabilitiesInScope: {},
  vendorPatchAvailable: {},
  getOperatingSystemDistribution: {},
  topClientOperatingSystem: {},
  topServerOperatingSystem: {},
  topOS: {},
  topHardwareManufacturer: {},
  topClientHardware: {},
  topServerHardware: {},
  selectedTags: [],
};

export const cyberEssentialPlus = createSlice({
  name: 'cyberEssentialPlus',
  initialState: cyberEssentialPlusInitialState,
  reducers: {
    setFilterTags: (state: CyberEssentialPlusState, action: { payload: string[] }) => ({
      ...state,
      selectedTags: action.payload,
    }),
  },
  extraReducers: {
    [cyberEssentialPlusApi.getLastWeekDetections.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: DetectionsCountResponse },
    ) => {
      state.detectionsLastWeek = { ...action.payload, title: VulnerabilityDetectionsGap.LAST_WEEK };
    },
    [cyberEssentialPlusApi.getLastWeekDetections.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.detectionsLastWeek = {};
    },
    [cyberEssentialPlusApi.getPenultimateWeekDetections.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: DetectionsCountResponse },
    ) => {
      state.detectionsPenultimateWeek = {
        ...action.payload,
        title: VulnerabilityDetectionsGap.PENULTIMATE_WEEK,
      };
    },
    [cyberEssentialPlusApi.getPenultimateWeekDetections.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.detectionsPenultimateWeek = {};
    },
    [cyberEssentialPlusApi.getDetectionsAfterTwoWeeks.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: DetectionsCountResponse },
    ) => {
      state.detectionsAfterTwoWeeks = {
        ...action.payload,
        title: VulnerabilityDetectionsGap.MORE_THEN_TWO_WEEKS,
      };
    },
    [cyberEssentialPlusApi.getDetectionsAfterTwoWeeks.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.detectionsAfterTwoWeeks = {};
    },
    [cyberEssentialPlusApi.getTotalAssetsInScope.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: TotalItemsInScopeResponse },
    ) => {
      state.totalAssetsInScope = action.payload;
    },
    [cyberEssentialPlusApi.getTotalAssetsInScope.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.totalAssetsInScope = {};
    },
    [cyberEssentialPlusApi.getTotalVulnerabilitiesInScope.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: TotalItemsInScopeResponse },
    ) => {
      state.totalVulnerabilitiesInScope = action.payload;
    },
    [cyberEssentialPlusApi.getTotalVulnerabilitiesInScope.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.totalVulnerabilitiesInScope = {};
    },
    [cyberEssentialPlusApi.getVendorPatchAvailable.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: TotalItemsInScopeResponse },
    ) => {
      state.vendorPatchAvailable = action.payload;
    },
    [cyberEssentialPlusApi.getVendorPatchAvailable.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.vendorPatchAvailable = {};
    },
    [cyberEssentialPlusApi.getOperatingSystemDistribution.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: CePlusListProps },
    ) => {
      state.getOperatingSystemDistribution = action.payload;
    },
    [cyberEssentialPlusApi.getOperatingSystemDistribution.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.getOperatingSystemDistribution = {};
    },
    [cyberEssentialPlusApi.getTopClientOperatingSystem.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: CePlusListProps },
    ) => {
      state.topClientOperatingSystem = action.payload;
    },
    [cyberEssentialPlusApi.getTopClientOperatingSystem.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.topClientOperatingSystem = {};
    },
    [cyberEssentialPlusApi.getTopServerOperatingSystem.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: CePlusListProps },
    ) => {
      state.topServerOperatingSystem = action.payload;
    },
    [cyberEssentialPlusApi.getTopServerOperatingSystem.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.topServerOperatingSystem = {};
    },
    [cyberEssentialPlusApi.getTopHardwareManufacturer.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: CePlusListProps },
    ) => {
      state.topHardwareManufacturer = action.payload;
    },
    [cyberEssentialPlusApi.getTopHardwareManufacturer.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.topHardwareManufacturer = {};
    },
    [cyberEssentialPlusApi.getTopClientHardware.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: CePlusListProps },
    ) => {
      state.topClientHardware = action.payload;
    },
    [cyberEssentialPlusApi.getTopClientHardware.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.topClientHardware = {};
    },
    [cyberEssentialPlusApi.getTopServerHardware.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: CePlusListProps },
    ) => {
      state.topServerHardware = action.payload;
    },
    [cyberEssentialPlusApi.getTopServerHardware.rejected.toString()]: (
      state: CyberEssentialPlusState,
    ) => {
      state.topServerHardware = {};
    },
    [cyberEssentialPlusApi.getTopOS.fulfilled.toString()]: (
      state: CyberEssentialPlusState,
      action: { payload: CePlusListProps },
    ) => {
      state.topOS = action.payload;
    },
    [cyberEssentialPlusApi.getTopOS.rejected.toString()]: (state: CyberEssentialPlusState) => {
      state.topOS = {};
    },
  },
});
