import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Box } from 'shared';
import { useApp } from 'store';
import { useTheme } from 'styled-components';
import { AltLink } from '../types';
import { Link } from './styled';

type Props = {
  altLink?: AltLink;
};

export const Body = ({ children, altLink }: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const { isDesktop } = useApp();

  return (
    <Box mt={theme.spacing[5]}>
      {children}
      {altLink && (
        <Box textAlign='center' mt={theme.spacing[4]}>
          <Link to={altLink.to} $isMobile={!isDesktop}>
            {altLink.title}
          </Link>
        </Box>
      )}
    </Box>
  );
};
