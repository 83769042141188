import React from 'react';
import { useDispatch } from 'react-redux';
import { PatchManagementPlatform } from 'services/api';
import { Icon } from 'shared/Icon';
import { TableFiltersSelect } from 'shared/TableFilters';
import { usePlatform } from 'store';
import { platform } from 'store/patchManagement/platform';
import { icons } from 'styles/theme';
import { platformFilterOption } from './constants';

export const PmPlatformFilter = () => {
  const platformValue = usePlatform();
  const dispatch = useDispatch();

  return (
    <TableFiltersSelect
      key={platformFilterOption.field}
      showSearch={false}
      value={platformValue}
      onSelectChange={(_: string, value?: string | string[]) => {
        dispatch(platform.actions.setPlatform(value as PatchManagementPlatform));
      }}
      suffixIcon={<Icon className='ant-select-suffix' component={icons.arrow_drop_down} />}
      {...platformFilterOption}
    />
  );
};
