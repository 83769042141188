import { PermissionOptions } from 'app-constants/permissions';
import { Routes } from 'services/entities';

export const routesToPermissionName: { [x: string]: PermissionOptions | PermissionOptions[] } = {
  [Routes.DOCUMENTS]: PermissionOptions.Documents,
  [Routes.PROCESS]: PermissionOptions.Process,
  [Routes.PEOPLE]: PermissionOptions.CybersafetyAndPhishing,
  [Routes.VULNERABILITY_MANAGEMENT]: PermissionOptions.VulnerabilityManagement,
  [Routes.THREAT_DETECTION]: PermissionOptions.ThreatDetection,
  [Routes.SUPPORT_REQUESTS]: [PermissionOptions.ThreatDetection, PermissionOptions.SupportRequests],
  [Routes.PENETRATION_TESTING]: PermissionOptions.PenetrationTesting,
  [Routes.EMAIL_SECURITY]: PermissionOptions.EmailSecurity,
  [Routes.DATA_PROTECTION_LIBRARY]: PermissionOptions.DataProtection,
  [Routes.CMDB]: PermissionOptions.CMDB,
  [Routes.USERS]: PermissionOptions.AdminUsers,
  [Routes.SECURITY_SETTINGS]: PermissionOptions.SecuritySettings,
  [Routes.AUDIT_LOG]: PermissionOptions.AuditLogs,
  [Routes.SOFTWARE]: PermissionOptions.ManageSoftware,
  [Routes.PATCH_MANAGEMENT]: PermissionOptions.PatchManagement,
  [Routes.CE_PLUS]: PermissionOptions.VulnerabilityManagement,
  [Routes.MAINTENANCE_SETTINGS]: PermissionOptions.MaintenanceSettings,
  [Routes.TAGS_MANAGEMENT]: PermissionOptions.CMDB,
  [Routes.THREAT_MAP]: PermissionOptions.ThreatDetection,
  [Routes.SERVICES_INTEGRATIONS]: PermissionOptions.ServicesIntegrations,
};

export const routesNeedSuperAdmin: string[] = [Routes.AUDIT_LOG];
