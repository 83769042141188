/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerId } from '../models/CustomerId';
import type { DPLCategoriesResponse } from '../models/DPLCategoriesResponse';
import type { DPLDocumentCategory } from '../models/DPLDocumentCategory';
import type { DPLDocumentsResponse } from '../models/DPLDocumentsResponse';
import type { DPLDocumentType } from '../models/DPLDocumentType';
import type { ExportDocumentResponse } from '../models/ExportDocumentResponse';
import type { Filename } from '../models/Filename';
import type { Filters } from '../models/Filters';
import type { OrderBy } from '../models/OrderBy';
import type { Pagination } from '../models/Pagination';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { Timestamp } from '../models/Timestamp';
import { request as __request } from '../core/request';

export class DataProtectionLibraryService {
  /**
   * Documents list
   * Get list of data protection library documents in Azure Blob
   * @returns DPLDocumentsResponse List of data protection library documents in Azure Blob
   * @throws ApiError
   */
  public static async getDplDocuments({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<DPLDocumentsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/data-protection-library`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when get documents`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Delete document
   * Delete document from Azure Blob
   * @returns SuccessResponse Delete document from Azure Blob
   * @throws ApiError
   */
  public static async deleteDplDocument({
    customerId,
    filename,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filename parameter **/
    filename: Filename;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'DELETE',
      path: `/customer/${customerId}/data-protection-library`,
      query: {
        filename: filename,
      },
      errors: {
        400: `An error message when delete document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Import (upload) ducument
   * Import (upload) ducument to Azure Blob
   * @returns SuccessResponse Import document to Azure Blob
   * @throws ApiError
   */
  public static async dplImportDocument({
    customerId,
    filename,
    documentName,
    documentType,
    documentCategory,
    createdDate,
    premium,
    essentialPlus,
    requestBody,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filename parameter **/
    filename: Filename;
    /** The documentName parameter **/
    documentName: string;
    /** The document type parameter **/
    documentType: DPLDocumentType;
    /** The document category ID parameter **/
    documentCategory: DPLDocumentCategory;
    /** Created date timestamp parameter **/
    createdDate: Timestamp;
    /** The document availability parameter (premium) **/
    premium: boolean;
    /** The document availability parameter (essential plus) **/
    essentialPlus: boolean;
    /** Import document **/
    requestBody?: any;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/data-protection-library/import`,
      query: {
        filename: filename,
        documentName: documentName,
        documentType: documentType,
        documentCategory: documentCategory,
        createdDate: createdDate,
        premium: premium,
        essentialPlus: essentialPlus,
      },
      body: requestBody,
      errors: {
        400: `An error message when import document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Export (download) document
   * Export (download) document from Azure Blob
   * @returns ExportDocumentResponse Export document from Azure Blob
   * @throws ApiError
   */
  public static async dplExportDocument({
    customerId,
    filename,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The filename parameter **/
    filename: Filename;
  }): Promise<ExportDocumentResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/data-protection-library/export`,
      query: {
        filename: filename,
      },
      errors: {
        400: `An error message when export document`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Categories List
   * Get list of data protection library categories
   * @returns DPLCategoriesResponse List of data protection library categories
   * @throws ApiError
   */
  public static async getDplCategories({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<DPLCategoriesResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/data-protection-library/categories`,
      errors: {
        400: `An error message when get documents`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
