import { DatePicker as AntdDatePicker } from 'antd';
import styled, { css } from 'styled-components';
import { Theme } from '../../styles/theme/types';
import { DataPicker } from './DataPicker';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

const inputPicker = css`
  background-color: ${getCssVar('datePicker.bg')};
  border-color: ${getCssVar('datePicker.borderColor')};

  color: ${getCssVar('textColor')};
  padding-bottom: 6.5px;
  padding-top: 6.5px;

  .ant-picker-input > input {
    color: ${getCssVar('textColor')};
  }

  .ant-picker-suffix {
    color: ${getCssVar('datePicker.iconColor')};
    font-size: ${({ theme }: Props) => theme.sizes[4]};
  }

  &:hover,
  &.ant-picker-focused {
    border-color: ${getCssVar('datePicker.hoverBorderColor')};
    box-shadow: none;
  }

  .ant-picker-range-separator {
    color: ${getCssVar('datePicker.color')};
  }
`;

export const DatePicker = styled(AntdDatePicker)`
  height: 40px;
  ${inputPicker};
`;

export const RangePicker = styled(DataPicker)`
  ${inputPicker};
  height: 40px;
  width: 250px;

  &.ant-picker-range {
    .ant-tag {
      &.ant-tag-blue {
        background: ${getCssVar('datePicker.pickerPanelBg')};
        border-width: ${({ theme }: Props) => theme.sizes.px};
      }
    }

    .ant-picker-panel-container {
      .ant-picker-panel {
        border-bottom-width: ${({ theme }: Props) => theme.sizes.px};
      }
    }
  }

  .ant-picker-active-bar {
    background: ${getCssVar('input.datePicker')};
  }
`;
