export const serviceIcons = {
  SentinelOne: (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 37 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_16045_285006)'>
        <path
          d='M18.4663 9.10208L36.6313 18.1838V9.08337L18.4663 0V9.10208Z'
          fill='url(#paint0_linear_16045_285006)'
        />
        <path
          d='M18.4663 36.3061L36.6313 27.2227V36.3248L18.4663 45.4082V36.3061Z'
          fill='url(#paint1_linear_16045_285006)'
        />
        <path
          d='M18.4663 9.10208L0.30127 18.1838V9.08337L18.4663 0V9.10208Z'
          fill='url(#paint2_linear_16045_285006)'
        />
        <path
          d='M18.4663 36.3061L0.30127 27.2227V36.3248L18.4663 45.4082V36.3061Z'
          fill='url(#paint3_linear_16045_285006)'
        />
        <path
          d='M4.83936 20.4413L22.9908 29.5178L32.0878 24.966L13.9448 15.8945L4.83936 20.4413Z'
          fill='url(#paint4_linear_16045_285006)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_16045_285006'
          x1='36.5038'
          y1='13.5757'
          x2='18.4578'
          y2='4.54168'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4500B6' />
          <stop offset='1' stopColor='#6100FF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_16045_285006'
          x1='36.5038'
          y1='31.8324'
          x2='18.4578'
          y2='40.8665'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4500B6' />
          <stop offset='1' stopColor='#6100FF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_16045_285006'
          x1='0.428845'
          y1='13.5757'
          x2='18.4748'
          y2='4.54168'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6100FF' />
          <stop offset='1' stopColor='#4500B6' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_16045_285006'
          x1='0.428844'
          y1='31.8324'
          x2='18.4748'
          y2='40.8665'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6100FF' />
          <stop offset='1' stopColor='#4500B6' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_16045_285006'
          x1='28.155'
          y1='27.5549'
          x2='8.6258'
          y2='17.7775'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6100FF' />
          <stop offset='1' stopColor='#4500B6' />
        </linearGradient>
        <clipPath id='clip0_16045_285006'>
          <rect width='37' height='46' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
};
