import { Filter, PatchStatus } from 'services/api';
import { initialPagination } from 'app-constants';
import { initialOrderBy } from 'pages/PatchManagementPatches/constants';

export const getRedirectParams = (
  patchStatus: PatchStatus,
  hostId: string,
  severity?: string | string[],
) => {
  return {
    orderBy: initialOrderBy,
    pagination: initialPagination,
    filter: {
      fields: [
        {
          name: 'patchStatus',
          value: [patchStatus],
          type: Filter.type.MULTIPLE,
        },
        severity
          ? {
              name: 'vendorSeverity',
              value: severity,
              type: Filter.type.MULTIPLE,
            }
          : null,
        {
          name: 'hostId',
          value: hostId,
          type: Filter.type.VALUE,
        },
      ].filter((v) => v),
    },
  };
};
