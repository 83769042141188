import React from 'react';
import { Box, Button, Icon } from 'shared';
import { useAssetDetails, useLoading } from 'store';
import { assetDetailsApi } from 'store/assetDetails/thunks';
import { useGetPermission } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { useTheme } from 'styled-components';
import { CardTitles } from '../../constants';
import { AssetOwnerViewMode } from './AssetOwnerViewMode';
import { AssetOwnerForm } from './AssetOwnerForm';
import { CardStyled } from './styled';

export type AssetOwnerProps = {
  isMobile?: boolean;
};

export const AssetOwner = ({ isMobile }: AssetOwnerProps) => {
  const theme = useTheme();
  const loading = useLoading([
    assetDetailsApi.getDetailsAdditionalInformation.typePrefix,
    assetDetailsApi.setsAssetOwner.typePrefix,
  ]);
  const { detailsAdditionalInformation } = useAssetDetails();

  const [isFormVisible, setIsFormVisible] = React.useState(false);

  const isShowEditButton = useGetPermission(PermissionOptions.CMDB) === PermissionsLevel.Full;

  const showForm = React.useCallback(() => {
    setIsFormVisible(true);
  }, []);

  const handleSave = React.useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const handleCancel = React.useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const getCardPadding = () => {
    if (!isMobile) {
      return theme.spacing['5.5'];
    }

    if (!isShowEditButton || isFormVisible) {
      return `${theme.spacing[4]} ${theme.spacing[5]} ${theme.spacing[1]}`;
    }

    return `${theme.spacing['2.5']} ${theme.spacing[5]} ${theme.spacing['0.5']}`;
  };

  const getheadStyles = () => {
    if (!isMobile) {
      return { pb: theme.spacing[4], titlePaddingRight: theme.spacing['2.5'] };
    }

    if (!isShowEditButton || isFormVisible) {
      return {
        pb: theme.spacing[4],
        titlePaddingRight: theme.spacing[0],
      };
    }

    return {
      pb: theme.spacing['2.5'],
      titlePaddingRight: theme.spacing[0],
    };
  };

  return (
    <CardStyled
      title={CardTitles.AssetOwner}
      headAction={
        !isFormVisible &&
        isShowEditButton && (
          <Box ml={theme.spacing[3]}>
            <Button type='link' icon={<Icon component={theme.icons.edit} />} onClick={showForm}>
              Edit
            </Button>
          </Box>
        )
      }
      headStyles={getheadStyles()}
      bodyPadding={
        !isMobile
          ? `${theme.spacing[6]} ${theme.spacing['2.5']} ${theme.spacing['2.5']}`
          : theme.spacing[0]
      }
      cardPadding={getCardPadding()}
      showLoader={
        loading[assetDetailsApi.getDetailsAdditionalInformation.typePrefix] ||
        loading[assetDetailsApi.setsAssetOwner.typePrefix]
      }
    >
      {isFormVisible ? (
        <AssetOwnerForm
          handleSave={handleSave}
          handleCancel={handleCancel}
          assetOwner={detailsAdditionalInformation?.assetOwner}
        />
      ) : (
        <AssetOwnerViewMode assetOwner={detailsAdditionalInformation?.assetOwner} />
      )}
    </CardStyled>
  );
};
