/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InputType } from './InputType';

export type Filter = {
  name?: string;
  value?: string | Array<string>;
  type?: Filter.type;
  negate?: boolean;
  inputType?: InputType;
};

export namespace Filter {
  export enum type {
    MULTIPLE = 'MULTIPLE',
    RANGE = 'RANGE',
    VALUE = 'VALUE',
  }
}
