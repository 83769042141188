import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, managedDetectionsResponseApi, useApp, useCustomer, useLoading } from 'store';
import { Card } from 'shared';
import { ManagedDetectionsResponseTitles, tooltipTitles } from 'pages/ThreatDetection/constants';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { MTTIWrapper, TimeCount, TimeName, Empty } from './styled';

export const MTTI = () => {
  const theme = useTheme();
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const dispatch = useDispatch();
  const loading = useLoading([managedDetectionsResponseApi.meanTimeToInvestigate.typePrefix]);
  const mtti = useSelector(({ mdr: { mtti } }: AppState) => mtti);

  React.useEffect(() => {
    const startDate = moment().subtract(1, 'month').valueOf();
    const endDate = moment().valueOf();

    dispatch(
      managedDetectionsResponseApi.meanTimeToInvestigate({
        customerId,
        startDate,
        endDate,
      }),
    );
  }, [customerId, dispatch]);

  return (
    <Card
      title={ManagedDetectionsResponseTitles.MTTI}
      tooltipTitle={tooltipTitles.MTTI}
      showDivider={false}
      headStyles={
        !isMobile
          ? { pt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[managedDetectionsResponseApi.meanTimeToInvestigate.typePrefix]}
    >
      <MTTIWrapper>
        {!mtti && <Empty>Not currently available</Empty>}

        {!!mtti && (
          <>
            {!!mtti?.hours && mtti.hours > 0 && (
              <>
                <TimeCount>{mtti.hours}</TimeCount>
                <TimeName>hours</TimeName>
              </>
            )}

            <TimeCount>{mtti.minutes}</TimeCount>
            <TimeName>minutes</TimeName>

            <TimeCount>{mtti.seconds}</TimeCount>
            <TimeName>seconds</TimeName>
          </>
        )}
      </MTTIWrapper>
    </Card>
  );
};
