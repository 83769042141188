import React from 'react';
import { ButtonType, IDownloadDoc, DropdownType } from '../layouts/Inner/types';
import { DownloadDocButton } from './DownloadDocButton';
import { DownloadDocDropdown } from './DownloadDocDropdown';

export function Factory({ type = ButtonType, ...props }: IDownloadDoc) {
  if (type === DropdownType) {
    return <DownloadDocDropdown {...props} />;
  }

  return <DownloadDocButton {...props} />;
}
