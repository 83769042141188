import React, { PropsWithChildren } from 'react';
import { logoReportImageUrl } from '../constants';

type Props = {
  headerTitle?: string | JSX.Element;
  headerSubtitle?: string | JSX.Element;
  headerLogo?: string;
};

export const ReportPageLayout = ({
  headerTitle = 'the dull in detail',
  headerSubtitle = '*Apart from this, your May 2022 update',
  headerLogo = logoReportImageUrl,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div className='page'>
      <table>
        <tbody>
          <tr className='header'>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td className='title'>
                      <h1>{headerTitle}</h1>
                      <div>{headerSubtitle}</div>
                    </td>
                    <td>
                      <img width='132' height='72' alt='Header logo' src={headerLogo} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className='body'>
            <td>{children}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
