import React from 'react';
import { Space } from 'antd';
import { useApp } from 'store';
import { Link } from 'services/api';
import { useTheme } from 'styled-components';
import { CardStyled, CveContainer } from '../styled';
import { renderValue } from '../helpers';

type CVEProps = {
  cveList?: Link[];
  isLoading?: boolean;
};

export const CVE = ({ cveList, isLoading }: CVEProps) => {
  const theme = useTheme();
  const { isMobile } = useApp();

  return (
    <CardStyled
      title='CVE'
      isHiddable={isMobile}
      headStyles={
        !isMobile
          ? { pb: theme.sizes[5], titlePaddingRight: theme.sizes['2.5'] }
          : { pb: theme.sizes['2.5'], titlePaddingRight: theme.sizes[0] }
      }
      bodyPadding={
        !isMobile ? `${theme.sizes[6]} ${theme.sizes['2.5']} ${theme.sizes['2.5']}` : theme.sizes[0]
      }
      cardPadding={
        !isMobile
          ? `${theme.sizes[7]} ${theme.sizes['5.5']} ${theme.sizes['5.5']}`
          : `${theme.sizes[4]} ${theme.sizes[5]} ${theme.sizes['1.5']}`
      }
      showLoader={isLoading}
    >
      <CveContainer>
        <Space direction='vertical' size={25}>
          {cveList && cveList.map((cve: Link) => renderValue(cve))}
        </Space>
      </CveContainer>
    </CardStyled>
  );
};
