/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Cvss } from './Cvss';
import type { Link } from './Link';
import type { SeverityLevel } from './SeverityLevel';
import type { Suppression } from './Suppression';
import type { VulnerabilityStatus } from './VulnerabilityStatus';

export type AssetVulnerabilitySchema = {
  id?: string;
  qid?: string;
  vuln_type?: string;
  severity_level?: SeverityLevel;
  title?: string;
  category?: string;
  last_service_modification_datetime?: string;
  published_datetime?: string;
  bugtraq_list?: {
    bugtraq?: Array<Link>;
  };
  patchable?: AssetVulnerabilitySchema.patchable;
  software_list?: {
    software?: Array<{
      product?: string;
      vendor?: string;
    }>;
  };
  vendor_reference_list?: {
    vendor_reference?: Array<Link>;
  };
  cve_list?: {
    cve?: Array<Link>;
  };
  diagnosis?: string;
  compliance_list?: {
    compliance?: Array<{
      type?: string;
      section?: string;
      description?: string;
    }>;
  };
  consequence?: string;
  solution?: string;
  correlation?: {
    exploits?: {
      explt_src?: Array<{
        src_name?: string;
        explt_list?: {
          explt?: Array<{
            ref?: string;
            desc?: string;
            link?: string;
          }>;
        };
      }>;
    };
  };
  cvss?: Cvss;
  cvss_v3?: Cvss;
  pci_flag?: AssetVulnerabilitySchema.pci_flag;
  discovery?: {
    remote?: string;
    auth_type_list?: {
      auth_type?: string;
    };
    additional_info?: string;
  };
  _rid?: string;
  _self?: string;
  _etag?: string;
  _attachments?: string;
  _ts?: string;
  port?: string;
  results?: string;
  vulnerabilityStatus?: VulnerabilityStatus;
  host?: {
    hostname?: string;
    hostId?: string;
  };
  suppression?: Suppression;
};

export namespace AssetVulnerabilitySchema {
  export enum patchable {
    _0 = '0',
    _1 = '1',
  }

  export enum pci_flag {
    _0 = '0',
    _1 = '1',
  }
}
