import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { getCssVar } from 'styles/theme';

export const StartIcon = styled(Icon)<{ active: boolean }>`
  svg > path {
    fill: ${({ theme, active }) =>
      active
        ? theme.colorSet.stepper.activeBackgroundColor
        : theme.colorSet.stepper.backgroundColor};
    stroke: ${({ theme, active }) =>
      active ? theme.colorSet.stepper.activeBorderColor : theme.colorSet.stepper.borderColor};
  }
`;

export const EndIcon = styled(Icon)<{ active: boolean }>`
  svg > path {
    fill: ${({ theme, active }) =>
      active
        ? theme.colorSet.stepper.activeBackgroundColor
        : theme.colorSet.stepper.backgroundColor};
    stroke: ${({ theme, active }) =>
      active ? theme.colorSet.stepper.activeBorderColor : theme.colorSet.stepper.borderColor};
  }
`;

export const StepItemContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 400;

  height: 60px;
  justify-content: center;
  line-height: 21px;
  position: relative;
  text-align: center;

  &:not(:first-child) {
    margin-left: -10px;
  }
`;

export const StepItemBody = styled.div<{ active: boolean }>`
  align-items: center;
  background: ${({ active }) =>
    getCssVar(active ? 'stepper.activeBackgroundColor' : 'stepper.backgroundColor')};
  border-color: ${({ active }) =>
    getCssVar(active ? 'stepper.activeBorderColor' : 'stepper.borderColor')};
  border-style: solid;
  border-width: 2px 0 2px 0;
  color: ${getCssVar('textColor')};

  display: flex;
  height: 100%;

  justify-content: center;
  padding: 8px;
  width: 125px;

  &:first-child {
    border-width: 2px 0 2px 2px;
  }

  > a {
    color: ${getCssVar('textColor')};
    display: block;
  }
`;

export const StepContainer = styled.div`
  display: flex;

  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
`;
