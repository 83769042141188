import { Col, Row, Spin } from 'antd';
import { InnerLayout } from 'shared';
import { useRowGap } from 'services/hooks/useRowGap';
import { useEffect } from 'react';
import {
  useCustomer,
  useServicesIntegrations,
  servicesIntegrationsApi,
  useRequestLoading,
  AppDispatch,
} from 'store';
import { useDispatch } from 'react-redux';
import { SpinContainer } from 'shared/Card/components/styled';

import { ServiceCard, EmptyMessage, ForbiddenMessage } from './components';
import { useUserPermissions } from './hooks';

export const ServicesIntegrations = () => {
  const { gapNumber } = useRowGap();
  const customerId = useCustomer();
  const dispatch: AppDispatch = useDispatch();

  const { canView, canEnableService, isNormUser } = useUserPermissions();

  const services = useServicesIntegrations();
  const isLoading = useRequestLoading(servicesIntegrationsApi.getServices.typePrefix);

  useEffect(() => {
    if (canView) {
      dispatch(servicesIntegrationsApi.getServices({ customerId }));
    }
  }, [customerId, dispatch, canView]);

  if (!services.length && isLoading) {
    return (
      <SpinContainer>
        <Spin size='large' />
      </SpinContainer>
    );
  }

  return (
    <InnerLayout title='Norm-approved integrations'>
      {!canView && <ForbiddenMessage />}

      {canView && !services.length && <EmptyMessage />}

      {canView && services.length > 0 && (
        <Row gutter={[gapNumber, gapNumber]}>
          {services.map((service) => {
            // show service for norm user or if it's enabled and user is not norm
            if (!service.enabled && !isNormUser) {
              return null;
            }

            return (
              <Col key={service.serviceName} span={gapNumber} xs={24} md={12} xxl={8}>
                <ServiceCard
                  {...service}
                  canEnableService={canEnableService}
                  showToggle={isNormUser}
                  isLoading={isLoading}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </InnerLayout>
  );
};
