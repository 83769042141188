import { minLengthToSearch } from 'app-constants';
import { OrderBy } from 'services/api';
import * as Yup from 'yup';
import { ISimpleInputFields } from '../../shared/inputs/Input/types';
import { icons } from '../../styles/theme';
import { ActionType } from '../../shared/ActionPopup/types';

export enum TableTitles {
  TagName = 'Tag name',
  TaggedAssets = 'Active tagged assets',
  AgedTaggedAssets = 'Aged tagged assets',
  Action = 'Actions',
}

export const searchPlaceholder = 'Search';
export const searchFields = ['name'];

export const searchSettings = {
  fields: searchFields,
  min: minLengthToSearch,
  placeholder: searchPlaceholder,
};

export enum ColumnKey {
  TagName = 'name',
  TaggedAssets = 'taggedAssets',
  AgedTaggedAssets = 'agedTaggedAssets',
  Actions = 'action',
}

export const popupConfig = {
  edit: {
    id: 'edit',
    title: 'Edit tag',
    text: 'All associated assets will be editing too',
    onApplyTitle: 'Edit',
    isShowInput: true,
  },
  delete: {
    id: 'delete',
    title: 'Delete tag',
    onApplyTitle: 'Delete tag',
    danger: true,
    isShowInput: true,
  },
};

export const initialOrderBy = {
  field: 'name',
  type: OrderBy.type.ASC,
};

export enum ActionTypeName {
  Edit = 'Edit',
  Delete = 'Delete',
}

export const actions: ActionType[] = [
  { name: ActionTypeName.Edit, icon: icons.edit, popupId: popupConfig.edit.id },
  {
    name: ActionTypeName.Delete,
    icon: icons.delete,
    popupId: popupConfig.delete.id,
    isDanger: true,
  },
];

export enum FieldNames {
  TagName = 'name',
}

export const formErrorMessages: Record<FieldNames, Record<string, string>> = {
  [FieldNames.TagName]: {
    isRequired: 'Tag name is required',
  },
};

export const MAX_TAG_NAME_LENGTH_DESKTOP = 30;
export const MAX_TAG_NAME_LENGTH_MOBILE = 20;

export const createTagFields: ISimpleInputFields[] = [
  {
    label: 'Tag name',
    name: FieldNames.TagName,
    type: 'text',
    placeholder: 'Tag name',
    value: '',
    maxLength: MAX_TAG_NAME_LENGTH_DESKTOP,
  },
];

export const defaultFieldsValues = createTagFields.reduce(
  (acc: FieldValues, field: ISimpleInputFields) => ({ ...acc, [field.name]: field.value }),
  {} as FieldValues,
);

export const validationSchema = Yup.object({
  [FieldNames.TagName]: Yup.string()
    .trim()
    .required(formErrorMessages[FieldNames.TagName].isRequired),
});

export enum TagFieldNames {
  Name = 'name',
}

export type FieldValues = Record<TagFieldNames, string>;
