import { InnerLayout, Card, BackLink } from 'shared';
import { useCustomer, useApp } from 'store';
import { Routes } from 'services/entities';
import { useTheme } from 'styled-components';
import { GuidanceType } from 'pages/ServicesIntegrations/types';
import { useLocation } from 'react-router-dom';
import { HistoryState } from 'services/interfaces';
import { useGetServiceDetails } from '../../../hooks';
import { LastUpdated } from '../../LastUpdated';
import { EditForm } from './EditForm';

type EditGuidanceDetailsProps = {
  type: GuidanceType;
  title: string;
};

export const EditGuidanceDetails = ({ type, title }: EditGuidanceDetailsProps) => {
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const { sizes } = useTheme();
  const { state: historyState, pathname } = useLocation<HistoryState>();

  const { isLoading, serviceName, serviceId, guidanceDetails } = useGetServiceDetails();

  return (
    <InnerLayout
      title={serviceName}
      backLink={
        <BackLink
          defaultRoute={`${Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', serviceId)}`}
          defaultTitle={`${serviceName} integration`}
          historyState={historyState?.backTo?.route !== pathname ? historyState : undefined}
        />
      }
    >
      <Card
        title={title}
        showDivider
        showLoader={isLoading}
        cardPadding={!isMobile ? sizes['5.5'] : `${sizes[4]} ${sizes[5]}`}
        headStyles={{
          actionsGap: isMobile ? sizes[2] : sizes[4],
          actionsWidth: '100%',
          actionsDisplay: 'flex',
          actionsJustify: 'space-between',
          pb: isMobile ? sizes[2] : sizes[4],
          titlePaddingRight: isMobile ? sizes[0] : sizes['2.5'],
        }}
        bodyPadding='0px'
        headAction={<LastUpdated details={guidanceDetails?.[type]} />}
      >
        <EditForm
          serviceId={serviceId}
          serviceName={serviceName}
          data={guidanceDetails?.[type]}
          customerId={customerId}
          type={type}
        />
      </Card>
    </InnerLayout>
  );
};
