export enum TabTitles {
  PatchDetails = 'Patch details',
  AffectedApplications = 'Affected applications',
  Superseded = 'Superseded',
  ResolvedVulns = 'Resolved vulnerabilities',
}

export enum MainInfoTitles {
  Vendor = 'Vendor',
  OS = 'OS',
  PatchSize = 'Patch size',
}

export enum SummaryTitles {
  Missing = 'Missing on assets',
  Installed = 'Installed on assets',
}
