import { SoftwareDocumentSchema } from 'services/api';
import { UploadDocumentOption } from 'shared/UploadPopup/types';

export const softwareDocTypeOptions: UploadDocumentOption<SoftwareDocumentSchema.documenttype>[] = [
  { value: SoftwareDocumentSchema.documenttype.OTHER, label: 'Other' },
  { value: SoftwareDocumentSchema.documenttype.CONTRACT, label: 'Contract' },
  { value: SoftwareDocumentSchema.documenttype.TEMPLATE, label: 'Template' },
  { value: SoftwareDocumentSchema.documenttype.REPORT, label: 'Report' },
  { value: SoftwareDocumentSchema.documenttype.SOFTWARE_AGENT, label: 'Software agent' },
  { value: SoftwareDocumentSchema.documenttype.SOFTWARE_IMAGE, label: 'Software image' },
];

export const emptyTablePreviewOptions = {
  icon: 'empty_table',
  title: 'No documents files available yet',
  textContent: 'Here will be displayed all documents that you will be able to download.',
};

export const initialSelect = {
  service: undefined,
};

export const searchSettings = {
  placeholder: 'Search by document name',
  fields: ['name'],
  min: 3,
};

export const selectOptionOther = { label: 'Other', value: 'Other' };
