import React from 'react';
import Segmented from 'rc-segmented';
import 'rc-segmented/assets/index.css';
import { SegmentedWrapper } from './styled';

export const SegmentedButton = ({ options, onChange, defaultValue }) => {
  return (
    <SegmentedWrapper>
      <Segmented options={options} defaultValue={defaultValue} onChange={onChange} />
    </SegmentedWrapper>
  );
};
