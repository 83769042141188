export const spacing = {
  px: '1px',
  0: '0',
  0.5: '0.125rem', // 2px
  1: '0.25rem', // 4px
  1.2: '0.3125rem', // 5px
  1.5: '0.375rem', // 6px
  2: '0.5rem', // 8px
  2.5: '0.625rem', // 10px
  3: '0.75rem', // 12px
  3.4: '0.8125rem', // 13px
  3.5: '0.875rem', // 14px
  3.6: '0.9375rem', // 15px
  4: '1rem', // 16px
  4.2: '1.0625rem', // 17px
  4.5: '1.1rem', // 18px
  5: '1.25rem', // 20px
  5.5: '1.375rem', // 22px
  6: '1.5rem', // 24px
  6.1: '1.5625rem', // 25px
  6.9: '1.688rem', // 27px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  9: '2.25rem', // 36px
  10: '2.5rem', // 40px
  10.1: '2.8125rem', // 45px
  12: '3rem', // 48px
  14: '3.5rem', // 56px
  16: '4rem', // 64px
  17: '4.25rem', // 68px
  20: '5rem', // 80px
  22: '5.5rem', // 88px
  24: '6rem', // 96px
  26: '6.5rem', // 104px
  28: '7rem', // 112px
  32: '8rem', // 128px
  36: '9rem', // 144px
  40: '10rem', // 160px
  44: '11rem', // 176px
  48: '12rem', // 192px
  52: '13rem', // 208px
  56: '14rem', // 224px
  60: '15rem', // 240px
  64: '16rem', // 256px
  72: '18rem', // 288px
  80: '20rem', // 320px
  87: '21.75rem', // 348px
  96: '24rem', // 384px
};
