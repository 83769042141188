import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide15.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const TextBlock = styled.div`
  font-size: 11px;
  font-weight: 300;
  left: 50px;
  letter-spacing: 0.1px;
  max-width: 959px;
  padding: 10px 39px;
  position: absolute;
  width: 100%;
`;

export const SectionDescriptionBlock = styled(TextBlock)`
  top: 110px;
`;

export const SupportTextBlock = styled(TextBlock)`
  top: 293px;
`;

export const MoreInformationTextBlock = styled(TextBlock)`
  top: 440px;
`;

export const YourContactsTable = styled.table`
  left: 50px;
  max-width: 959px;
  position: absolute;
  top: 248px;

  td {
    font-weight: 300;
    padding: 15px 40px;
  }
`;

export const Column = styled.td``;

export const FirstColumn = styled(Column)`
  font-weight: 600 !important;
  width: 356px;
`;

export const SecondColumn = styled(Column)`
  width: 375px;
`;

export const NormTeamTable = styled.table`
  left: 50px;
  max-width: 959px;
  position: absolute;
  top: 358px;

  td {
    font-weight: 300;
    padding: 4px 40px;
  }
`;
