import { formatUpdatedOrCreatedTime } from 'services/helpers';
import { BackLink, InnerLayoutProps as InnerLayout } from 'shared';
import { backlinkRoutes, NO_VALUE_LABEL } from 'app-constants';
import { HostSchema, HostSource } from 'services/api';
import { HistoryState } from 'services/interfaces';
import { toggleButton } from 'pages/VulnerabilityManagement/constants';
import { RemoveAsset } from './components/RemoveAsset/RemoveAsset';
import { PriorityLabel } from './components/AssetOwner/constants';

export const getLayoutConfig = (
  onSuccess: () => void,
  onSwitchToggleButton: (value: boolean) => void,
  backLinkRoute: string,
  historyState?: HistoryState | undefined,
  pathname?: string,
  hostName?: string,
  hostId?: string,
  isShowDeleteButton?: boolean,
  isToggleButtonActive?: boolean,
  isToggleButtonLoading?: boolean,
  potentialVulnerabilitiesCount?: number,
): InnerLayout => ({
  title: hostName || '',
  customComponent: !isShowDeleteButton ? undefined : (
    <RemoveAsset hostData={{ id: hostId, hostName }} onSuccess={onSuccess} />
  ),
  toggleButton:
    potentialVulnerabilitiesCount && potentialVulnerabilitiesCount > 0
      ? {
          title: `${toggleButton.title} (${potentialVulnerabilitiesCount})`,
          isActive: isToggleButtonActive,
          onChange: onSwitchToggleButton,
          loading: isToggleButtonLoading,
          tooltipTitle: toggleButton.tooltipTitle,
        }
      : undefined,
  backLink: (
    <BackLink
      historyState={historyState?.backTo?.route !== pathname ? historyState : undefined}
      defaultRoute={backLinkRoute}
      defaultTitle={backlinkRoutes[backLinkRoute]}
    />
  ),
});

export const renderValue = (value?: string) => (!value ? NO_VALUE_LABEL : value);

export const renderDate = (date?: string | number) =>
  !date ? NO_VALUE_LABEL : formatUpdatedOrCreatedTime(date);

export const getPriorityValue = (value: string) => {
  switch (value) {
    case HostSchema.criticality._2:
      return PriorityLabel.High;
    case HostSchema.criticality._1:
      return PriorityLabel.Medium;
    case HostSchema.criticality._0:
      return PriorityLabel.Low;
    default:
      return PriorityLabel.Low;
  }
};

export const checkHostHasOnlyDefenderSource = (host?: HostSchema) => {
  if (host?.assetSource?.length !== 1) {
    return false;
  }

  return host.assetSource[0] === HostSource.EDR_AGENT_DEFENDER_;
};
