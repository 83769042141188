import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

export enum RequestStatus {
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export type LoadingState = {
  [key: string]: boolean;
};
const initialState: LoadingState = {};

export const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<LoadingState>) => {
    builder.addMatcher(
      (action: AnyAction) => {
        return (
          action.type.endsWith(`/${RequestStatus.Pending}`) ||
          action.type.endsWith(`/${RequestStatus.Fulfilled}`) ||
          action.type.endsWith(`/${RequestStatus.Rejected}`)
        );
      },
      (state: LoadingState, action: AnyAction) => {
        const { type } = action;
        const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(type);

        if (!matches) return;
        const [, requestName, requestState] = matches;

        state[requestName] = requestState === RequestStatus.Pending;
      },
    );
  },
});
