import moment from 'moment';
import { getData } from 'pages/VulnerabilityManagement/helpers';
import { VulnerabilityTrendPointSchema } from 'services/api';
import { formatDate } from 'services/helpers';
import { IDate } from 'services/interfaces';
import { OptionParams } from 'shared/charts';
import { RadioButtonsDays } from 'shared/RadioButtonsWithRangePicker/constants';
import { totalTooltipFormatter } from 'shared/charts/formatters';
import { IEChartsOption } from 'shared/charts/interfaces';
import { Theme } from 'styles/theme/types';

const rightGridForMobile = 38;
const rightGridForDesktop = 32;

export const getOption = ({
  values,
  theme,
  date,
  height,
  isMobile,
  top,
  bottom,
  left,
  right,
}: OptionParams<VulnerabilityTrendPointSchema[]>): IEChartsOption => {
  const dateRange: IDate = date || {
    startDate: moment().subtract(RadioButtonsDays.OneMonth, 'days').startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
  };
  const [seriesData, xAxisData] = getData(values);
  const color = [theme.colors.blue[600]];

  return {
    grid: {
      left: left || '10px',
      top: top || '35px',
      right: right || (!isMobile ? rightGridForDesktop : rightGridForMobile),
      bottom: bottom || '10px',
      containLabel: true,
      height: height || '260px',
    },
    xAxis: {
      type: 'category',
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, '10%'],
      data: xAxisData.map((value: number) => formatDate(`${value}`, dateRange, true)),
      axisLabel: {
        color: theme.colorSet.charts.axisLabel,
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      axisLabel: {
        color: theme.colorSet.charts.axisLabel,
      },
    },
    series: [
      {
        type: 'line',
        name: 'Total',
        data: seriesData,
        showSymbol: seriesData.length <= 1 && seriesData[0] !== 0,
        symbolSize: 6,
        areaStyle: { opacity: 0 },
      },
    ],
    tooltip: {
      trigger: 'axis',
      textStyle: {
        color: theme.colorSet.charts.tooltip.color,
      },
      backgroundColor: theme.colorSet.charts.tooltip.bg,
      borderColor: theme.colorSet.charts.tooltip.bg,
      formatter: (params: Record<string, number>[]) => {
        return totalTooltipFormatter(values[params[0].dataIndex], dateRange);
      },
    },
    color,
  };
};

export const getCardPadding = (isMobile: boolean, theme: Theme) =>
  !isMobile
    ? theme.sizes[6]
    : `${theme.sizes[5]} ${theme.sizes[0]} ${theme.sizes[5]} ${theme.sizes[5]}`;

export const getBodyPadding = (isMobile: boolean, theme: Theme) =>
  !isMobile ? `${theme.sizes[2]} ${theme.sizes[2]} ${theme.sizes[0]}` : theme.sizes[0];

export const getHeadStyles = (isMobile: boolean, theme: Theme) => ({
  headAlign: 'flex-start' as const,
  titlePaddingRight: isMobile ? theme.sizes[0] : theme.sizes[2],
  maxWidthTitle: isMobile ? '190px' : '250px',
});
