import { TableProps } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { HistoryState } from 'services/interfaces';
import { Box, Card, InnerLayout } from 'shared';
import { useApp } from 'store';
import { useTheme } from 'styled-components';
import { getLayoutConfig } from './helpers';
import { CvssTable, Header2, Text } from './styled';
import { getColumns } from './TableColumns';
import {
  cvssV2BaseScoreMetrics,
  cvssV2TemporalScoreMetrics,
  cvssV3BaseScoreMetrics,
  cvssV3TemporalScoreMetrics,
} from './tablesData';
import { CVSSTableRow } from './types';
import { getCssVar } from '../../styles/theme';

export const CvssVectorStrings = () => {
  const { state: historyState, pathname } = useLocation<HistoryState>();
  const layoutConfig = getLayoutConfig(historyState, pathname);
  const theme = useTheme();
  const { isMobile } = useApp();

  return (
    <InnerLayout {...layoutConfig}>
      <Card
        title='Sample Vector Strings'
        headStyles={
          isMobile
            ? {
                pb: theme.sizes[3],
                pt: theme.sizes[3],
              }
            : { pb: theme.sizes[4], titlePaddingRight: theme.sizes['2.5'] }
        }
        height={theme.sizes.full}
        bodyPadding={!isMobile ? theme.sizes['2.5'] : theme.sizes[0]}
        cardPadding={!isMobile ? theme.sizes['5.5'] : theme.sizes[5]}
      >
        <Text>
          Here are sample CVSS scores followed by vector strings. (Note: CVSS represents CVSS
          version 2 and CVSS3.1 represents CVSS version 3.1.)
        </Text>
        <Text>CVSS Base: 5.5 AV:N/AC:L/Au:S/C:P/I:P/A:N</Text>
        <Text>CVSS Temporal: 4.3 E:POC/RL:OF/RC:C</Text>
        <Text>CVSS3 Base: 6.4 AV:N/AC:L/PR:L/UI:N/S:C/C:L/I:L/A:N</Text>
        <Text>CVSS3 Temporal: 5.8 E:P/RL:O/RC:C</Text>

        <Header2>Vector string format</Header2>
        <Text hardBreak>
          metric:value/metric:value/metric:value/metric:value/metric:value/metric:value
        </Text>
        <Text>where / is the separator between metric:value pairs</Text>
        <Header2>Use the table below to look up metric values in a vector string</Header2>
        <Text>
          For example, the CVSS v2 base vector string "AV:N/AC:L/Au:S/C:P/I:P/A:N" has these values:
        </Text>
        <Text>AV:N indicates the Access Vector metric has a value of Network.</Text>
        <Text>AC:L indicates the Access Complexity metric has a value of Low.</Text>
        <Text>Au:S indicates the Authentication metric has a value of Single.</Text>
        <Text>C:P indicates the Confidentiality Impact metric has a value of Partial.</Text>
        <Text>I:P indicates the Integrity Impact metric has a value of Partial.</Text>
        <Text>A:N indicates the Availability Impact metric has a value of None.</Text>
        <Box
          borderBottom={`${theme.sizes['0.5']} solid ${getCssVar('divider.color')}`}
          mb={theme.spacing[4]}
          ml={isMobile ? '' : `-${theme.spacing[3]}`}
          mt={theme.spacing[6]}
          pl={isMobile ? '' : `-${theme.spacing[2]}`}
          pb={theme.spacing[1]}
        >
          <Header2>Metric Values</Header2>
        </Box>

        <Text>
          The CVSS v2 and v3.1 metric values as defined by the CVSS standard are listed below.
        </Text>
        <Header2>CVSS v2: Base Score Metrics</Header2>
        <CvssTable<React.FC<TableProps<CVSSTableRow>>>
          columns={getColumns()}
          dataSource={cvssV2BaseScoreMetrics}
          tableLayout='fixed'
          pagination={false}
        />
        <Header2>CVSS v2: Temporal Score Metrics</Header2>
        <CvssTable<React.FC<TableProps<CVSSTableRow>>>
          columns={getColumns()}
          dataSource={cvssV2TemporalScoreMetrics}
          tableLayout='fixed'
          pagination={false}
        />
        <Header2>CVSS v3.1: Base Score Metrics</Header2>
        <CvssTable<React.FC<TableProps<CVSSTableRow>>>
          columns={getColumns()}
          dataSource={cvssV3BaseScoreMetrics}
          tableLayout='fixed'
          pagination={false}
        />
        <Header2>CVSS v3.1: Temporal Score Metrics</Header2>
        <CvssTable<React.FC<TableProps<CVSSTableRow>>>
          columns={getColumns()}
          dataSource={cvssV3TemporalScoreMetrics}
          tableLayout='fixed'
          pagination={false}
        />
      </Card>
    </InnerLayout>
  );
};
