import { Filter, PatchVendorSeverity } from 'services/api';
import { InputType } from '../../services/api/models/InputType';
import { SeverityOptions } from '../../pages/PatchManagementPatches/constants';

export const vendorSeverityFilterOption = {
  field: 'vendorSeverity',
  options: SeverityOptions,
  label: '',
  inputType: InputType.SELECTOR,
  filterType: Filter.type.MULTIPLE,
};

export const defaultVendorSeverityValue = [
  PatchVendorSeverity._5,
  PatchVendorSeverity._4,
  PatchVendorSeverity._3,
  PatchVendorSeverity._2,
  PatchVendorSeverity._1,
];

export const vendorSeverityFilterWidth = 464;
