/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveServicesInformationResponse } from '../models/ActiveServicesInformationResponse';
import type { AlarmDetailsResponse } from '../models/AlarmDetailsResponse';
import type { AlarmId } from '../models/AlarmId';
import type { CustomerId } from '../models/CustomerId';
import type { EdrResponse } from '../models/EdrResponse';
import type { EventsByServicesResponse } from '../models/EventsByServicesResponse';
import type { EventsCountResponse } from '../models/EventsCountResponse';
import type { EventServiceDetailsResponse } from '../models/EventServiceDetailsResponse';
import type { EventsResponse } from '../models/EventsResponse';
import type { FilteredAlarmsResponse } from '../models/FilteredAlarmsResponse';
import type { Filters } from '../models/Filters';
import type { InvestigationOverviewResponse } from '../models/InvestigationOverviewResponse';
import type { OrderBy } from '../models/OrderBy';
import type { OrderMDRLicenseRequest } from '../models/OrderMDRLicenseRequest';
import type { Pagination } from '../models/Pagination';
import type { SuccessResponse } from '../models/SuccessResponse';
import { request as __request } from '../core/request';

export class ThreatDetectionService {
  /**
   * Alarms list
   * Get filtered, searchable and sortable list of alarms
   * @returns FilteredAlarmsResponse Filtered, searchable and sortable list of alarms
   * @throws ApiError
   */
  public static async getAlarms({
    customerId,
    orderBy,
    pagination,
    filter,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Order by parameter **/
    orderBy: OrderBy;
    /** Pagination parameter **/
    pagination: Pagination;
    /** The filter parameter **/
    filter?: Filters;
  }): Promise<FilteredAlarmsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/alarms`,
      query: {
        orderBy: orderBy,
        pagination: pagination,
        filter: filter,
      },
      errors: {
        400: `An error message when get alarms list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Alarm details
   * Get filtered, searchable and sortable list of alarms
   * @returns AlarmDetailsResponse Filtered, searchable and sortable list of alarms
   * @throws ApiError
   */
  public static async getAlarmDetails({
    customerId,
    alarmId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** Alarm id **/
    alarmId: AlarmId;
  }): Promise<AlarmDetailsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/alarms/${alarmId}`,
      errors: {
        400: `An error message when get alarms list`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Events
   * Get fortisiem events by date range
   * @returns EventsResponse Fortisiem events by date range
   * @throws ApiError
   */
  public static async getEvents({
    customerId,
    startDate,
    endDate,
    eventService,
  }: {
    /** Customer id **/
    customerId: string;
    /** The start date parameter **/
    startDate: number;
    /** The end date parameter **/
    endDate: number;
    /** The event service parameter **/
    eventService?: string;
  }): Promise<EventsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/events`,
      query: {
        startDate: startDate,
        endDate: endDate,
        eventService: eventService,
      },
      errors: {
        400: `An error message when get fortisiem events by date range`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Events
   * Get fortisiem events grouped by services names
   * @returns EventsByServicesResponse Fortisiem events grouped by services names
   * @throws ApiError
   */
  public static async getEventsByServices({
    customerId,
    startDate,
    endDate,
  }: {
    /** Customer id **/
    customerId: string;
    /** The start date parameter **/
    startDate: number;
    /** The end date parameter **/
    endDate: number;
  }): Promise<EventsByServicesResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/events-by-services`,
      query: {
        startDate: startDate,
        endDate: endDate,
      },
      errors: {
        400: `An error message when get fortisiem events by services names`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Services informations
   * Get active services informations
   * @returns ActiveServicesInformationResponse Active services informations
   * @throws ApiError
   */
  public static async getActiveServices({
    customerId,
    limit,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The Limit Parameter **/
    limit?: number;
  }): Promise<ActiveServicesInformationResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/active-services`,
      query: {
        limit: limit,
      },
      errors: {
        400: `An error message when get active services informations`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * EDR
   * Get fireeye edr by date range
   * @returns EdrResponse Fireeye edr by date range
   * @throws ApiError
   */
  public static async getEdr({
    customerId,
    startDate,
    endDate,
  }: {
    /** Customer id **/
    customerId: string;
    /** The start date parameter **/
    startDate: number;
    /** The end date parameter **/
    endDate: number;
  }): Promise<EdrResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/edr`,
      query: {
        startDate: startDate,
        endDate: endDate,
      },
      errors: {
        400: `An error message when get fireeye edr by date range`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Investigation Overivew chart
   * Investigation Overview chart - events, alarms, incidents
   * @returns InvestigationOverviewResponse Investigation overview chart
   * @throws ApiError
   */
  public static async getInvestigationOverview({
    customerId,
    startDate,
    endDate,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** The start date parameter **/
    startDate: number;
    /** The end date parameter **/
    endDate: number;
  }): Promise<InvestigationOverviewResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/investigation-overview`,
      query: {
        startDate: startDate,
        endDate: endDate,
      },
      errors: {
        400: `An error message when get investigation overview by date time period`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Service details
   * Get service details
   * @returns EventServiceDetailsResponse Service details
   * @throws ApiError
   */
  public static async getEventServiceDetails({
    customerId,
    name,
  }: {
    /** Customer id **/
    customerId: CustomerId;
    /** service name **/
    name: string;
  }): Promise<EventServiceDetailsResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/event-service/${name}`,
      errors: {
        400: `An error message when get service details`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
        404: `An error message in case of no data found`,
      },
    });
    return result.body;
  }

  /**
   * Order additional license request
   * Sends an email to order additional license
   * @returns SuccessResponse License MDR request
   * @throws ApiError
   */
  public static async orderLicenseRequest({
    customerId,
    requestBody,
  }: {
    /** Customer id **/
    customerId: string;
    /** Name, surname, phone, email **/
    requestBody?: OrderMDRLicenseRequest;
  }): Promise<SuccessResponse> {
    const result = await __request({
      method: 'POST',
      path: `/customer/${customerId}/order-license-request`,
      body: requestBody,
      errors: {
        400: `An error message when order MDR license`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Export EDR agents
   * Export active EDR agents CSV report
   * @returns any Export EDR agents in csv file
   * @throws ApiError
   */
  public static async exportActiveEdrAgents({
    customerId,
  }: {
    /** Customer id **/
    customerId: CustomerId;
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/edr/export-edr`,
      errors: {
        400: `An error message when exporting EDR data`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }

  /**
   * Events
   * Get Sentinel events count by date range
   * @returns EventsCountResponse Sentinel events count
   * @throws ApiError
   */
  public static async getSentinelEventsCount({
    customerId,
    startDate,
    endDate,
  }: {
    /** Customer id **/
    customerId: string;
    /** The start date parameter **/
    startDate: number;
    /** The end date parameter **/
    endDate: number;
  }): Promise<EventsCountResponse> {
    const result = await __request({
      method: 'GET',
      path: `/customer/${customerId}/sentinel/events-count`,
      query: {
        startDate: startDate,
        endDate: endDate,
      },
      errors: {
        400: `An error message when get sentinel events count by date range`,
        401: `An error message for unauthorized request`,
        403: `An error message for forbidden request`,
      },
    });
    return result.body;
  }
}
