import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GridRequestParams } from 'services/api';
import { getQueryString } from 'services/api/core/request';
import { Routes } from 'services/entities';
import { getParametersFromPath } from 'services/helpers';
import { tabsKeys } from '../constants';
import { getDefaultTabsParams, getHasHiddenFilters } from '../helpers';

export const useTabsQueryParams = () => {
  const history = useHistory();
  const defaultTabsQueryParams = getDefaultTabsParams();
  const [tabsQueryParams, setTabsQueryParams] = useState<
    Record<string, Partial<GridRequestParams>>
  >(defaultTabsQueryParams);
  const { tab } = getParametersFromPath(history.location.search) || ({} as GridRequestParams);

  const initialTab = tab || 'active';
  const [, setActiveTab] = useState(initialTab);
  const [initedHiddenFilters, setInitedHiddenFilters] = useState(false);
  const updateActiveTab = (selectedTab: string) => {
    const params = tabsQueryParams[selectedTab];
    const queryString = getQueryString({
      ...params,
      tab: selectedTab,
    });

    setActiveTab(selectedTab);
    history.push(`${Routes.VULNERABILITIES_LIST}${queryString}`);
  };

  const updateQueryParams = (params: Partial<GridRequestParams>): void => {
    const { tab = tabsKeys.active, customerId, ...rest } = params;
    const hasHiddenFilter = getHasHiddenFilters(rest?.filter);

    if (hasHiddenFilter && !initedHiddenFilters) {
      setInitedHiddenFilters(true);
      Object.keys(defaultTabsQueryParams).map((key) => {
        defaultTabsQueryParams[key].filter = rest.filter;

        return key;
      });
    }

    setTabsQueryParams((prev) => ({
      ...prev,
      [tab]: { ...rest, tab },
    }));
  };

  return { updateActiveTab, updateQueryParams };
};
