import { Tag, UcFirstLetter } from 'shared';
import { TagProps } from 'antd';
import { CMDBSeverity } from 'services/constants';
import { useTheme } from 'styled-components';

type CustomTagProps = TagProps & {
  title: string;
};

export const StatusTagColoring = ({ title, ...TagProps }: CustomTagProps) => {
  const theme = useTheme();

  const tagType = (title: string) => {
    switch (title) {
      case CMDBSeverity.High:
        return theme.colorSet.severity.high;
      case CMDBSeverity.Medium:
        return theme.colorSet.severity.meduim;
      case CMDBSeverity.Low:
        return theme.colorSet.severity.low;
      default:
        return theme.colorSet.severity.high;
    }
  };

  return (
    <Tag color={tagType(title)} key={title} {...TagProps} title={title}>
      {UcFirstLetter(title)}
    </Tag>
  );
};
