/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VulnerabilityStatus } from './VulnerabilityStatus';

export type SuppressedVulnerabilityDetails = {
  id?: string;
  hostId?: string;
  qid?: string;
  subject?: string;
  vulnerabilityStatus?: VulnerabilityStatus;
  suppressReason?: string;
  suppressedBy?: string;
  suppressedAt?: number;
  severity?: SuppressedVulnerabilityDetails.severity;
};

export namespace SuppressedVulnerabilityDetails {
  export enum severity {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
  }
}
