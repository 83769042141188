import React from 'react';
import { getCapitalizedString } from 'services/helpers';

interface IProps {
  status?: string;
  level: string;
  className?: string;
  style?: React.CSSProperties;
}

export const Status = ({ level, status, className, style }: IProps) => {
  return (
    <span className={className} style={style}>
      <span>{getCapitalizedString(status || level)}</span>
    </span>
  );
};
