import { SERVICE_INTEGRATION } from 'pages/ServicesIntegrations/constants';
import { ConnectionDetailsType, ServiceIntegrationType } from 'pages/ServicesIntegrations/types';
import { emptyMessage } from 'shared/Table/EmptyTablePreview/constants';
import { SentinelOneForm } from './layouts';

export type EditCredentialsFactoryProps = {
  serviceName: ServiceIntegrationType;
  connectionDetails?: ConnectionDetailsType;
};

export const EditConnectionSettingsFactory = ({
  serviceName,
  connectionDetails,
}: EditCredentialsFactoryProps) => {
  switch (serviceName) {
    case SERVICE_INTEGRATION.SENTINEL_ONE:
      return <SentinelOneForm connectionDetails={connectionDetails} />;
    default:
      return <div>{emptyMessage('No services available yet')}</div>;
  }
};
