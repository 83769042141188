import { Tag } from 'shared';
import { TagProps } from 'antd';
import { SuppressionStatus } from 'services/api';
import { getCapitalizedString } from 'services/helpers';

type CustomTagProps = TagProps & {
  status?: SuppressionStatus;
  onClickHandler?: () => void;
  asButton?: boolean;
};

export const TagVulnSuppression = ({
  status,
  onClickHandler,
  asButton,
  ...TagProps
}: CustomTagProps) => {
  if (!status || (asButton && status === SuppressionStatus.UNSUPPRESSED)) {
    return null;
  }

  const tagClickHandler = () => {
    if (onClickHandler && typeof onClickHandler === 'function') {
      onClickHandler();
    }
  };

  const tagLabel =
    status === SuppressionStatus.UNSUPPRESSED
      ? 'Not suppressed'
      : getCapitalizedString(SuppressionStatus.SUPPRESSED);

  return (
    <Tag
      className={`ant-tag-suppression-${status}`}
      key={status}
      {...TagProps}
      title={tagLabel}
      onClick={tagClickHandler}
    >
      {tagLabel}
    </Tag>
  );
};
