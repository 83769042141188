import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { REHYDRATE } from 'redux-persist';
import {
  ForgotPasswordResponse,
  GetTokenResponse,
  LoginResponse,
  OpenAPI,
  SetTwoFAResponse,
  TwoFACustomerSchema,
  TwoFAUploadFileSizeSchema,
  ValidateTwoFAResponse,
} from 'services/api';
import { GetResetPasswordInfoResponse } from 'services/api/models/GetResetPasswordInfoResponse';
import { RestorePasswordSteps, TwoFASteps } from 'services/entities';
import { authApi } from './thunks';

const authData: ValidateTwoFAResponse = {};
const restorePassword = { step: RestorePasswordSteps.INITIAL, email: '' };
const twofa = {
  step: TwoFASteps.INITIAL,
  data: { base32: '', url: '' },
  isValid: false,
};

export type Permission = {
  option?: PermissionOptions;
  level?: PermissionsLevel;
};

export type UserProps = {
  name: string;
  email: string;
  customer: string;
  twoFA: boolean;
  isSuperAdmin?: boolean;
  uploadFileSize?: TwoFAUploadFileSizeSchema;
};

type ValidateResponseProps = {
  user: UserProps;
  permissions: Permission[];
  customersList: TwoFACustomerSchema[];
  valid: boolean;
  token: null;
  uploadFileSize: null;
};

const initialState = {
  ...authData,
  authToken: null as null | string,
  user: {} as UserProps,
  permissions: [] as Permission[],
  customersList: [] as TwoFACustomerSchema[],
  restorePassword,
  resetPasswordInfo: null as null | GetResetPasswordInfoResponse,
  twofa,
  isPending: false,
  isAuthorized: false,
  isSuperAdmin: false,
  requestError: {
    message: '',
  },
};

type InitialState = typeof initialState;

const setPendingStatus = (flag: boolean) => (state: InitialState) => {
  state.isPending = flag;
};

const handleError = (state: InitialState, action: { error: { message: string } }) => {
  state.isPending = false;
  state.requestError.message = action.error.message;
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRestorePasswordStep: (state: InitialState, action: PayloadAction<RestorePasswordSteps>) => ({
      ...state,
      restorePassword: {
        ...state.restorePassword,
        step: action.payload,
      },
    }),
    setStepToInitial: (state: InitialState) => ({
      ...state,
      twofa: { ...twofa },
    }),
    setError: (state: InitialState, action: PayloadAction<string>) => ({
      ...state,
      requestError: {
        ...state.requestError,
        message: action.payload,
      },
    }),
    setIsPending: (state: InitialState, action: PayloadAction<boolean>) => ({
      ...state,
      isPending: action.payload,
    }),
    setAuthToken: (state: InitialState, action: PayloadAction<string>) => {
      if (state.authToken === action.payload) {
        return state;
      }

      OpenAPI.TOKEN = action.payload;

      return {
        ...state,
        authToken: action.payload,
      };
    },
  },
  extraReducers: {
    [authApi.resetPasswordInit.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<ForgotPasswordResponse>,
    ) => {
      state.restorePassword.step = RestorePasswordSteps.INSTRUCTIONS_SENT;
      state.restorePassword.email = action.payload.email || '';
    },
    [authApi.resetPasswordInit.rejected.toString()]: (state: InitialState) => {
      state.restorePassword.step = RestorePasswordSteps.INSTRUCTIONS_SENT;
      state.restorePassword.email = '';
    },

    [authApi.resetPasswordConfirm.fulfilled.toString()]: (state: InitialState) => {
      state.restorePassword.step = RestorePasswordSteps.DONE;
    },

    [authApi.login.pending.toString()]: setPendingStatus(true),
    [authApi.login.rejected.toString()]: handleError,
    [authApi.login.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<LoginResponse>,
    ) => {
      const is2FASetted = action.payload.user?.twoFA;

      state.user = action.payload.user as UserProps;
      state.token = action.payload.token;
      if (is2FASetted) {
        state.twofa.step = TwoFASteps.CONFIRM;
      } else {
        state.twofa.step = TwoFASteps.SETUP;
      }

      setPendingStatus(false)(state);
    },

    [authApi.twofaData.pending.toString()]: setPendingStatus(true),
    [authApi.twofaData.rejected.toString()]: handleError,
    [authApi.twofaData.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<SetTwoFAResponse>,
    ) => {
      setPendingStatus(false)(state);
      state.twofa.data.base32 = action.payload.twofa?.base32 || '';
      state.twofa.data.url = action.payload.twofa?.url || '';
    },

    [authApi.twofaValidate.pending.toString()]: setPendingStatus(true),
    [authApi.twofaValidate.rejected.toString()]: (state: InitialState) => {
      state.requestError = {
        message: 'Please enter valid code',
      };
      state.isPending = false;
    },
    [authApi.twofaValidate.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<ValidateResponseProps>,
    ) => {
      setPendingStatus(false)(state);
      state.isAuthorized = true;
      state.twofa.isValid = action.payload.valid;
      const { customer, email, twoFA, name, isSuperAdmin, uploadFileSize } = action.payload.user;

      state.user = {
        name,
        customer,
        email,
        twoFA,
        isSuperAdmin,
        uploadFileSize,
      };
      state.permissions = action.payload.permissions;
      state.customersList = action.payload.customersList;
      state.authToken = action.payload.token;
    },
    [authApi.refreshToken.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<GetTokenResponse>,
    ) => {
      state.authToken = action.payload.token || null;
      OpenAPI.TOKEN = action.payload.token;
    },
    [authApi.getResetPasswordInfo.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<GetResetPasswordInfoResponse>,
    ) => {
      state.resetPasswordInfo = action.payload;
    },
    [authApi.getProfile.fulfilled.toString()]: (
      state: InitialState,
      action: PayloadAction<ValidateResponseProps>,
    ) => {
      const { customer, email, twoFA, name, isSuperAdmin, uploadFileSize } = action.payload.user;

      state.user = {
        name,
        customer,
        email,
        twoFA,
        isSuperAdmin,
        uploadFileSize,
      };
      state.permissions = action.payload.permissions;
      state.customersList = action.payload.customersList;
    },
    [REHYDRATE]: (_: InitialState, action: PayloadAction<{ auth: { authToken: string } }>) => {
      if (action.payload?.auth.authToken) {
        OpenAPI.TOKEN = action.payload.auth.authToken;
      }
    },
  },
});
