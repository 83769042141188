import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApp } from '../../store';
import { Themes } from '../../themes/types';

export const useSystemThemeSwitcher = (isSystemModeActive: (theme?: string) => boolean) => {
  const dispatch = useDispatch();
  const { currentThemeMode, themeName } = useApp();
  const [systemTheme, setSystemTheme] = useState(themeName);

  const changeTheme = useCallback((event: boolean) => {
    const colorScheme = event ? Themes.DarkNorm : Themes.Norm;

    setSystemTheme(colorScheme);
  }, []);

  useEffect(() => {
    changeTheme(window.matchMedia('(prefers-color-scheme: dark)')?.matches);
    if (isSystemModeActive(currentThemeMode)) {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
        changeTheme(event?.matches);
      });
    }

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', (event) => {
        changeTheme(event?.matches);
      });
    };
  }, [changeTheme, currentThemeMode, dispatch, isSystemModeActive]);

  return { systemTheme };
};
