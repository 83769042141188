import { getCapitalizedString } from 'services/helpers';
import { Annotations } from 'shared/charts/GradientPie/types';
import {
  CyberResilienceScoreDescription,
  CyberResilienceScorePeopleDescription,
  CyberResilienceScoreProcessDescription,
  CyberResilienceScoreTechnologyDescription,
  PeoplePieWrapper,
  ProcessPieWrapper,
  TechnologyPieWrapper,
  Wrapper,
} from './styled';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { titleFontSize, totalPieFontSize, totalPieScoreSize } from '../config/chart';
import { CyberResilienceScoreDescriptionScoreMessage, TotalScorePieWrapper } from '../styled';

interface CyberResilienceScorePageProps {
  scoreAnnotations?: Annotations;
  onChartLoaded: () => void;
  totalScore: number;
  peopleScore: number | undefined | null;
  processScore: number | undefined | null;
  technologyScore: number | undefined | null;
  totalRank: string;
  technologyRank: string;
  peopleRank: string;
  processRank: string;
}

export function PdfPage3({
  scoreAnnotations,
  onChartLoaded,
  totalScore,
  peopleScore,
  processScore,
  technologyScore,
  totalRank,
  technologyRank,
  peopleRank,
  processRank,
}: CyberResilienceScorePageProps) {
  return (
    <Wrapper>
      <TotalScorePieWrapper>
        <GradientPieReport
          score={totalScore}
          height={totalPieScoreSize}
          width={totalPieScoreSize}
          onFinished={onChartLoaded}
          scoreAnnotations={scoreAnnotations}
          fontSize={totalPieFontSize}
          titleFontSize={titleFontSize}
        />
      </TotalScorePieWrapper>
      <CyberResilienceScoreDescription>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(totalRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque neceari opta sinis
          excestiate comni nonsedi velibeate coriti as quas mo exerspe rumql et ipsum utem nos eos
          et landanti con ni ad ut que eum quis et aperuptatur? Lam, odit rumql et ipsum utem nos
          eos et landanti con ni ad ut que eum quis et aperuptatur?
        </p>
      </CyberResilienceScoreDescription>

      <ProcessPieWrapper>
        <GradientPieReport
          score={processScore}
          height='65px'
          width='65px'
          onFinished={onChartLoaded}
          fontSize={18}
          titleFontSize={8}
        />
      </ProcessPieWrapper>

      <CyberResilienceScoreProcessDescription>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(processRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt. Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque necri opta sinis.
        </p>
      </CyberResilienceScoreProcessDescription>

      <PeoplePieWrapper>
        <GradientPieReport
          score={peopleScore}
          height='65px'
          width='65px'
          onFinished={onChartLoaded}
          fontSize={18}
          titleFontSize={8}
        />
      </PeoplePieWrapper>

      <CyberResilienceScorePeopleDescription>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(peopleRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt. Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque near opta sinis.
        </p>
      </CyberResilienceScorePeopleDescription>

      <TechnologyPieWrapper>
        <GradientPieReport
          score={technologyScore}
          width='65px'
          height='65px'
          onFinished={onChartLoaded}
          fontSize={18}
          titleFontSize={8}
        />
      </TechnologyPieWrapper>

      <CyberResilienceScoreTechnologyDescription>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(technologyRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt. Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque ear opta sinis.
        </p>
      </CyberResilienceScoreTechnologyDescription>
    </Wrapper>
  );
}
