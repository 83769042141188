import React from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { Box, TagColoring } from 'shared';
import { getCapitalizedString, getFormattedDate, getRenderValue } from 'services/helpers';
import { VulnerabilitySchema } from 'services/api';
import { getSeverityLevelName } from 'pages/VulnerabilityManagement/helpers';
import { emptyDataString, NO_VALUE_LABEL } from 'app-constants';
import { VulnerabilitiesTitle, ColumnKey } from 'pages/VulnerabilitiesList/constants';
import { byNumber, byString, byValue } from 'sort-es';
import { VulnerabilityTitle } from './styled';

export const columns: ColumnsType<VulnerabilitySchema> = [
  {
    key: ColumnKey.Title,
    title: VulnerabilitiesTitle.Vulnerability,
    dataIndex: ColumnKey.Title,
    sorter: byValue((row: VulnerabilitySchema) => String(row.title), byString()),
    width: '70%',
    render: (title: string) => (
      <VulnerabilityTitle>
        <span>{getRenderValue(title, NO_VALUE_LABEL)}</span>
      </VulnerabilityTitle>
    ),
  },
  {
    key: 'severity_level',
    title: VulnerabilitiesTitle.Severity,
    dataIndex: 'severity_level',
    sorter: byValue((row: VulnerabilitySchema) => Number(row.severity_level), byNumber()),
    render: (severity: string) => (
      <>
        {!severity ? (
          emptyDataString
        ) : (
          <TagColoring title={getCapitalizedString(getSeverityLevelName(severity))} />
        )}
      </>
    ),
  },
  {
    key: ColumnKey.Category,
    title: VulnerabilitiesTitle.Category,
    dataIndex: ColumnKey.Category,
    sorter: byValue((row: VulnerabilitySchema) => String(row.category), byString()),
    render: (category: string) => <>{getRenderValue(category)}</>,
  },
  {
    key: 'published_datetime',
    title: 'Published date',
    dataIndex: 'published_datetime',
    className: 'right-head-title',
    align: 'right',
    sorter: byValue((row: VulnerabilitySchema) => Number(row.published_datetime), byNumber()),
    defaultSortOrder: 'descend',
    showSorterTooltip: false,
    width: 160,
    render: (date: string) => (
      <Box textAlign='right' w='160px'>
        {getFormattedDate(date)}
      </Box>
    ),
  },
];
