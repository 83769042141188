/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SeverityLevel {
  _0 = '0',
  _1 = '1',
  _2 = '2',
  _3 = '3',
  _4 = '4',
  _5 = '5',
}
