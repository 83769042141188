import { createSlice } from '@reduxjs/toolkit';
import {
  VulnerabilitiesBySeverity,
  VulnerabilitiesByHost,
  CMDBDetailAdditionalInformation,
  HostAssetDetails,
  HostNetworkInterfaces,
  HostInstalledSoftware,
  VulnerabilitiesCountByStatusResponse,
  AssetPatchesCountBySeverityResponse,
  PatchStatus,
  AssetRecentPatchesListResponse,
  SuppressedVulnerabilitiesByHost,
} from 'services/api';
import { assetDetailsApi } from './thunks';

interface AssetDetailsState {
  vulnerabilitiesBySeverity: VulnerabilitiesBySeverity;
  detailsVulnerabilitiesListByHost: VulnerabilitiesByHost;
  detailsSuppressedVulnerabilitiesListByHost: SuppressedVulnerabilitiesByHost;
  detailsAdditionalInformation: CMDBDetailAdditionalInformation;
  assetDetails: HostAssetDetails;
  interfaceDetails: HostNetworkInterfaces;
  installedSoftware: HostInstalledSoftware;
  vulnerabilitiesCountByStatus: VulnerabilitiesCountByStatusResponse;
  showPotentialVulnerabilities: boolean;
  missingPatchesBySeverity: AssetPatchesCountBySeverityResponse;
  installedPatchesBySeverity: AssetPatchesCountBySeverityResponse;
  missingRecentPatches: AssetRecentPatchesListResponse;
  installedRecentPatches: AssetRecentPatchesListResponse;
}

const initialState: AssetDetailsState = {
  vulnerabilitiesBySeverity: [],
  detailsVulnerabilitiesListByHost: {},
  detailsSuppressedVulnerabilitiesListByHost: {},
  detailsAdditionalInformation: {},
  assetDetails: {},
  interfaceDetails: [],
  installedSoftware: {},
  vulnerabilitiesCountByStatus: [],
  showPotentialVulnerabilities: false,
  missingPatchesBySeverity: {},
  installedPatchesBySeverity: {},
  missingRecentPatches: {},
  installedRecentPatches: {},
};

export const assetDetails = createSlice({
  name: 'assetDetails',
  initialState,
  reducers: {
    setShowPotentialVulnerabilities: (state: AssetDetailsState, action: { payload: boolean }) => ({
      ...state,
      showPotentialVulnerabilities: action.payload,
    }),
    resetValues: () => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [assetDetailsApi.getVulnerabilitiesBySeverity.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: VulnerabilitiesBySeverity },
    ) => {
      state.vulnerabilitiesBySeverity = action.payload;
    },
    [assetDetailsApi.getVulnerabilitiesBySeverity.rejected.toString()]: (
      state: AssetDetailsState,
    ) => {
      state.vulnerabilitiesBySeverity = [];
    },

    [assetDetailsApi.getDetailsVulnerabilitiesListByHost.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: VulnerabilitiesByHost },
    ) => {
      state.detailsVulnerabilitiesListByHost = action.payload;
    },
    [assetDetailsApi.getDetailsVulnerabilitiesListByHost.rejected.toString()]: (
      state: AssetDetailsState,
    ) => {
      state.detailsVulnerabilitiesListByHost = {};
    },

    [assetDetailsApi.getDetailsSuppressedVulnerabilitiesListByHost.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: SuppressedVulnerabilitiesByHost },
    ) => {
      state.detailsSuppressedVulnerabilitiesListByHost = action.payload;
    },
    [assetDetailsApi.getDetailsSuppressedVulnerabilitiesListByHost.rejected.toString()]: (
      state: AssetDetailsState,
    ) => {
      state.detailsSuppressedVulnerabilitiesListByHost = {};
    },

    [assetDetailsApi.getDetailsAdditionalInformation.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: CMDBDetailAdditionalInformation },
    ) => {
      state.detailsAdditionalInformation = action.payload;
    },
    [assetDetailsApi.getDetailsAdditionalInformation.rejected.toString()]: (
      state: AssetDetailsState,
    ) => {
      state.detailsAdditionalInformation = {};
    },

    [assetDetailsApi.getAssetDetails.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: HostAssetDetails },
    ) => {
      state.assetDetails = action.payload;
    },
    [assetDetailsApi.getAssetDetails.rejected.toString()]: (state: AssetDetailsState) => {
      state.assetDetails = {};
    },

    [assetDetailsApi.getInterfaceDetails.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: HostNetworkInterfaces },
    ) => {
      state.interfaceDetails = action.payload;
    },
    [assetDetailsApi.getInterfaceDetails.rejected.toString()]: (state: AssetDetailsState) => {
      state.interfaceDetails = [];
    },

    [assetDetailsApi.getInstalledSoftware.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: HostInstalledSoftware },
    ) => {
      state.installedSoftware = action.payload;
    },
    [assetDetailsApi.getInstalledSoftware.rejected.toString()]: (state: AssetDetailsState) => {
      state.installedSoftware = {};
    },

    [assetDetailsApi.setsAssetOwner.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: CMDBDetailAdditionalInformation },
    ) => {
      state.detailsAdditionalInformation = {
        ...state.detailsAdditionalInformation,
        assetOwner: action.payload.assetOwner,
      };
    },
    [assetDetailsApi.getVulnerabilitiesCountByStatus.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: VulnerabilitiesCountByStatusResponse },
    ) => {
      state.vulnerabilitiesCountByStatus = action.payload;
    },
    [assetDetailsApi.getVulnerabilitiesCountByStatus.rejected.toString()]: (
      state: AssetDetailsState,
    ) => {
      state.vulnerabilitiesCountByStatus = [];
    },
    [assetDetailsApi.getAssetPatchesBySeverity.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: AssetPatchesCountBySeverityResponse },
    ) => {
      const { payload } = action;

      if (payload.patchStatus === PatchStatus.MISSING)
        state.missingPatchesBySeverity = action.payload;

      if (payload.patchStatus === PatchStatus.INSTALLED)
        state.installedPatchesBySeverity = action.payload;
    },
    [assetDetailsApi.getAssetPatchesBySeverity.rejected.toString()]: (state: AssetDetailsState) => {
      state.missingPatchesBySeverity = {};
      state.installedPatchesBySeverity = {};
    },
    [assetDetailsApi.getAssetRecentPatches.fulfilled.toString()]: (
      state: AssetDetailsState,
      action: { payload: AssetPatchesCountBySeverityResponse },
    ) => {
      const { payload } = action;

      if (payload.patchStatus === PatchStatus.MISSING) state.missingRecentPatches = action.payload;

      if (payload.patchStatus === PatchStatus.INSTALLED)
        state.installedRecentPatches = action.payload;
    },
    [assetDetailsApi.getAssetRecentPatches.rejected.toString()]: (state: AssetDetailsState) => {
      state.missingRecentPatches = {};
      state.installedRecentPatches = {};
    },
  },
});
