import { colors } from '../colors';

export const leftMenu = {
  iconColors: colors.gray[500],
  activeSubmenuColor: colors.brown[400],
  unActiveSubmenuColor: colors.gray[300],
  submenuClose: colors.gray[800],
  unActiveSubmenuIcon: colors.gray[500],
  activeSubmenuSelectedColor: colors.gray[800],
  headerColor: colors.gray[500],
  logoTop: 'menu-logo-top-norm.svg',
  logoTopSmall: 'menu-logo-top-small-norm.svg',
  arrowColor: colors.gray[520],
  logoTopWidth: '110',
  logoTopWidthCollapsed: '28',
  logoTopHeight: '26',
  logoTopHeightCollapsed: '25',

  logoBottom: undefined,
  logoBottomCollapsed: undefined,

  menuDrawerColor: colors.gray[500],

  btnReportIncidentColor: colors.blue[900],

  iconCloseColor: colors.blue[900],

  iconHoverSelectedColor: colors.gray[500],

  drawerColor: colors.gray[310],

  avatarColor: colors.brown[500],

  userNameColor: colors.black,

  userAvatarColor: colors.white,

  logoutColor: colors.gray[500],

  drawerFooterLogo: undefined,
};
