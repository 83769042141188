import React from 'react';
import { getFormattedDate, getRenderValue } from 'services/helpers';
import { ColumnsType } from 'antd/es/table/interface';
import { RowType } from 'shared/Table/CustomTable';
import { Box } from 'shared';
import { CroppedText } from 'shared/CroppedText';
import { ColumnKey, TableTitles, UserColumns } from '../constant';
import { emptyDataString } from '../../../app-constants';

export const columns: ColumnsType<RowType> = [
  {
    key: ColumnKey.Email,
    title: TableTitles.Email,
    dataIndex: 'email',
    width: '25%',
    render: (email: string) => <>{getRenderValue(email)}</>,
  },
  {
    key: ColumnKey.Ip,
    title: TableTitles.Address,
    dataIndex: 'ip',
    width: '20%',
    render: (ip: string) => <Box textAlign='left'>{getRenderValue(ip)}</Box>,
  },
  {
    key: ColumnKey.CustomerId,
    title: TableTitles.CustomerId,
    dataIndex: ColumnKey.CustomerId,
    width: '10%',
    render: (customerId: string) => <Box textAlign='left'>{getRenderValue(customerId)}</Box>,
  },
  {
    key: ColumnKey.ActorEmail,
    title: TableTitles.ActorEmail,
    dataIndex: 'actor',
    render: (action: string) => <>{getRenderValue(action)}</>,
  },
  {
    key: ColumnKey.Action,
    title: TableTitles.Action,
    dataIndex: 'action',
    render: (action: string) => <>{getRenderValue(action)}</>,
  },
  {
    key: ColumnKey.Note,
    title: TableTitles.Note,
    dataIndex: 'note',
    render: (note: string) => (
      <CroppedText text={getRenderValue(note, emptyDataString) as string} maxLength={80} />
    ),
  },
  {
    key: ColumnKey.Timestamp,
    title: TableTitles.Date,
    dataIndex: 'timestamp',
    sorter: true,
    showSorterTooltip: false,
    render: (time: number) => <>{getFormattedDate(time)}</>,
    className: 'right-head-title',
    align: 'right',
    width: '200px',
  },
];

export const GetUserColumns = (): ColumnsType<RowType> => [
  ...columns.filter((column) => UserColumns.includes(column?.key as ColumnKey)),
];
