import { useAuth } from 'store';
import { useGetPermission } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';

export const useUserPermissions = () => {
  const { user } = useAuth();
  const permission = useGetPermission(PermissionOptions.ServicesIntegrations);

  const canView = permission === PermissionsLevel.ViewOnly;
  const canEdit = permission === PermissionsLevel.Full;

  const isNormUser = user.isSuperAdmin;

  return {
    canView: canView || canEdit,
    canEnableService: !!(isNormUser && canEdit),
    canSetupConnection: canEdit,
    canSetupGuide: isNormUser && canEdit,
    isNormUser,
  };
};
