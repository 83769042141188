import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiError,
  CreateNotificationRequest,
  CustomerId,
  GridRequestParams,
  MarkAsReadOrUnreadNotificationsRequest,
  NotificationsService,
  UpdateNotificationRequest,
} from 'services/api';

interface ICreateNotification {
  customerId: CustomerId;
  requestBody: CreateNotificationRequest;
}

interface ISetReadNotification {
  customerId: CustomerId;
  isRead: boolean;
  requestBody: MarkAsReadOrUnreadNotificationsRequest;
}

interface IDeleteNotification {
  customerId: CustomerId;
  notificationId: string;
}

interface IUpdateNotification {
  customerId: CustomerId;
  notificationId: string;
  requestBody: UpdateNotificationRequest;
}

const createNotification = createAsyncThunk(
  'notification/create',
  async ({ customerId, requestBody }: ICreateNotification) => {
    try {
      return await NotificationsService.createNotification({ customerId, requestBody });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const updateNotification = createAsyncThunk(
  'notification/update',
  async ({ customerId, notificationId, requestBody }: IUpdateNotification) => {
    try {
      return await NotificationsService.updateNotification({
        customerId,
        notificationId,
        requestBody,
      });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getNotificationGrid = createAsyncThunk(
  'notification/grid',
  async (requestBody: GridRequestParams) => {
    try {
      return await NotificationsService.getNotifications(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getUnreadNotificationGrid = createAsyncThunk(
  'notification/grid-unread',
  async (requestBody: GridRequestParams) => {
    try {
      return await NotificationsService.getNotifications(requestBody);
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const setReadNotification = createAsyncThunk(
  'notification/set-read-by',
  async ({ customerId, isRead, requestBody }: ISetReadNotification) => {
    try {
      return await NotificationsService.setReadBy({ customerId, isRead, requestBody });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getCreatedByList = createAsyncThunk(
  'notifications/created-by',
  async (customerId: CustomerId) => {
    try {
      return await NotificationsService.getNotificationCreatedBy({ customerId });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const deleteNotification = createAsyncThunk(
  'notifications/delete',
  async ({ customerId, notificationId }: IDeleteNotification) => {
    try {
      return await NotificationsService.deleteNotification({ customerId, notificationId });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

const getNotificationDetails = createAsyncThunk(
  'notifications/details',
  async ({ customerId, notificationId }: IDeleteNotification) => {
    try {
      return await NotificationsService.getNotificationDetails({ customerId, notificationId });
    } catch (e) {
      const { body } = e as ApiError;

      throw body;
    }
  },
);

export const newsNotificationsApi = {
  createNotification,
  updateNotification,
  getNotificationGrid,
  getUnreadNotificationGrid,
  setReadNotification,
  getCreatedByList,
  deleteNotification,
  getNotificationDetails,
};
