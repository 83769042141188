import { ColumnsType } from 'antd/es/table/interface';
import { renderValue } from 'pages/CmdbHostDetails/helpers';
import { HostSoftwareSchema } from 'services/api';
import { TableTitles } from './constants';

export const columns: ColumnsType<HostSoftwareSchema> = [
  {
    key: 'name',
    title: TableTitles.InstalledSoftware,
    dataIndex: 'name',
    sorter: true,
    showSorterTooltip: false,
    width: 400,
    render: (value: string) => renderValue(value),
  },
  {
    key: 'version',
    title: TableTitles.Version,
    dataIndex: 'version',
    align: 'left',
    render: (value: string) => renderValue(value),
  },
];
