import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { breakpoints, getCssVar } from '../../../../styles/theme';

type Props = { theme: Theme };

interface IWrapperTitle extends Props {
  isMobile?: boolean;
  isLabelStatus?: boolean;
}

export const WrapperTitle = styled.div<IWrapperTitle>`
  align-self: flex-end;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.lg}) {
    margin-left: auto;
  }

  h2 {
    align-items: flex-end;
    color: ${getCssVar('textColor')};
    display: ${({ isMobile, isLabelStatus }) => isMobile && isLabelStatus && 'flex'};
    flex-direction: ${({ isMobile }) => isMobile && 'column'};
    font-size: ${({ isMobile }) => (isMobile ? '20px' : '24px')};

    @media (max-width: ${breakpoints.xs}) {
      word-wrap: break-word;
      max-width: 310px;
    }

    @media (max-width: 400px) {
      word-wrap: break-word;
      max-width: 270px;
    }

    margin-bottom: ${({ theme }) => theme.sizes[0]};
    max-width: 100%;
    text-align: ${({ isMobile }) => (isMobile ? 'right' : 'left')};

    &.ant-typography {
      line-height: 20px;
    }

    > span:first-child {
      font-weight: ${({ theme }) => theme.fontWeights.extrabold};
      margin-right: ${({ theme }) => theme.sizes[2]};
      vertical-align: middle;

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        margin-right: ${({ theme }) => theme.sizes[0]};
      }
    }
  }

  a {
    align-self: ${({ isMobile }) => isMobile && 'flex-end'};
    text-align: right;
    word-wrap: break-word;

    @media (max-width: ${breakpoints.md}) {
      max-width: 400px;
    }

    @media (max-width: 420px) {
      max-width: 285px;
    }

    @media (max-width: 400px) {
      max-width: 265px;
    }

    @media (max-width: 380px) {
      max-width: 240px;
    }
  }
`;
