import React from 'react';
import { UserDetailsResponse } from 'services/api';
import { useModal } from 'services/hooks/useModal';
import { adminUsersApi, useCustomer, useLoading } from 'store';
import { useGetPermission } from 'services/hooks';
import { Box, Button, Icon } from 'shared';
import { useDispatch } from 'react-redux';
import { NO_VALUE_LABEL, PermissionOptions, PermissionsLevel } from 'app-constants';
import { CreateModalMode } from 'pages/Users/types';
import { useTheme } from 'styled-components';
import { CardStyled, Descriptions } from './styled';
import { renderDate } from '../helpers';
import { TableTitles } from './constants';
import { CreateUserPopup } from '../../CreateUserPopup';

type DetailsProps = {
  data: UserDetailsResponse;
  isMobile: boolean;
};

export const Details = ({ data, isMobile }: DetailsProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customer = useCustomer();
  const loading = useLoading([adminUsersApi.userDetails.typePrefix]);
  const editUserModal = useModal();
  const isFullAccess = useGetPermission(PermissionOptions.AdminUsers) === PermissionsLevel.Full;

  const editFieldValues = React.useMemo(() => {
    const [firstName, lastName] = (data.name || '').split(' ');

    return {
      firstName,
      lastName,
      phone: data.phone || '',
      email: data.email || '',
    };
  }, [data]);

  const renderHeaderActions = () => (
    <Box ml={theme.spacing[3]}>
      <Button
        type='link'
        icon={<Icon component={theme.icons.edit} />}
        disabled={!isFullAccess}
        onClick={() => editUserModal.showModal()}
      >
        Edit
      </Button>
    </Box>
  );

  const handleEdit = () => {
    dispatch(adminUsersApi.userDetails({ customerId: customer, userId: data?.id || '' }));
  };

  return (
    <>
      <CardStyled
        showLoader={loading[adminUsersApi.userDetails.typePrefix]}
        showDivider
        title='Details'
        headAction={renderHeaderActions()}
        isHiddable={isMobile}
        headStyles={
          !isMobile
            ? { pb: theme.spacing[4], titlePaddingRight: theme.spacing['2.5'] }
            : { pb: theme.spacing[2], titlePaddingRight: theme.spacing[0] }
        }
        bodyPadding={!isMobile ? `${theme.spacing[2]} ${theme.spacing['2.5']}` : theme.spacing[0]}
        cardPadding={
          !isMobile
            ? `${theme.spacing['5.5']} ${theme.spacing['5.5']} ${theme.spacing[0]}`
            : `${theme.spacing['2.5']} ${theme.spacing[5]} ${theme.spacing['0.5']}`
        }
      >
        {data && (
          <Descriptions column={1}>
            <Descriptions.Item key={data.name} label={TableTitles.Name}>
              {data.name || NO_VALUE_LABEL}
            </Descriptions.Item>

            <Descriptions.Item key={data.email} label={TableTitles.Email}>
              {data.email || NO_VALUE_LABEL}
            </Descriptions.Item>

            <Descriptions.Item key={data.phone} label={TableTitles.PhoneNumber}>
              {data.phone || NO_VALUE_LABEL}
            </Descriptions.Item>

            <Descriptions.Item key={data.type} label={TableTitles.UserType}>
              {data.type || NO_VALUE_LABEL}
            </Descriptions.Item>

            <Descriptions.Item key={data.createdDate} label={TableTitles.Created}>
              {renderDate(data.createdDate)}
            </Descriptions.Item>

            <Descriptions.Item key={data.updatedDate} label={TableTitles.Updated}>
              {renderDate(data.updatedDate)}
            </Descriptions.Item>
          </Descriptions>
        )}
      </CardStyled>
      {editUserModal.isModalShown && (
        <CreateUserPopup
          isVisible={editUserModal.isModalShown}
          setIsVisible={(value: boolean) =>
            value ? editUserModal.showModal() : editUserModal.hideModal()
          }
          editFieldValues={editFieldValues}
          mode={CreateModalMode.Edit}
          currentContactId={data?.id}
          onSuccess={handleEdit}
        />
      )}
    </>
  );
};
