import { ActionPopup } from 'shared';
import { showError } from 'services/helpers';
import { ServicesIntegrationsService } from 'services/api';
import { SpinContainer } from 'shared/Card/components/styled';
import { Spin } from 'antd';
import { useState } from 'react';

type PopupProps = {
  onCancel: () => void;
  onApply: (value: string) => void;
  customerId: string;
  serviceId: string;
  name: string;
  label: string;
  isVisible: boolean;
};

export const Popup = ({
  onCancel,
  onApply,
  customerId,
  serviceId,
  name,
  label,
  isVisible = false,
}: PopupProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && (
        <SpinContainer>
          <Spin size='large' />
        </SpinContainer>
      )}

      <ActionPopup
        isVisible={isVisible}
        popupDetails={{
          id: 'unmaskedText',
          title: 'Are you sure you want to proceed?',
          onApplyTitle: 'Confirm',
          text: `You are about to view sensitive information that includes a full ${label}.`,
          danger: true,
          onApply: async () => {
            setLoading(true);

            try {
              const result = await ServicesIntegrationsService.getServiceConnectionCredential({
                customerId,
                serviceId,
                credentialName: name,
              });

              onApply(result.unmaskedValue as string);
            } catch (error) {
              showError();
            } finally {
              setLoading(false);
            }
          },
        }}
        setIsVisiblePopup={onCancel}
      />
    </>
  );
};
