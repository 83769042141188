/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HostSource {
  EDR_AGENT_TRELLIX_ = 'EDR agent (Trellix)',
  EDR_AGENT_FORTI_EDR_ = 'EDR agent (FortiEDR)',
  EDR_AGENT_DEFENDER_ = 'EDR agent (Defender)',
  EDR_AGENT_SENTINEL_ONE_ = 'EDR agent (SentinelOne)',
  NETWORK_SCAN = 'Network scan',
  VULNERABILITY_AGENT = 'Vulnerability agent',
}
