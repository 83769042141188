import React, { PropsWithChildren } from 'react';
import { Col } from 'antd';
import { Box } from 'shared';

type Props = PropsWithChildren<{ isDesktop?: boolean }>;

export function DownloadButtonsColumn({ isDesktop, children }: Props) {
  if (!children) {
    return null;
  }

  return (
    <Col flex={!isDesktop ? '100%' : 'none'}>
      <Box d='flex' justify='flex-end' mt={!isDesktop ? '10px' : '0'} gap='10px'>
        {children}
      </Box>
    </Col>
  );
}
