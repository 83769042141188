import { HostSchema } from 'services/api';

export const MAX_LENGTH_VALUE = 200;

export enum AssetOwnerTitle {
  Name = 'Name',
  Department = 'Department',
  Priority = 'Priority',
}

export enum PriorityLabel {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export const priorityOptions = [
  { value: HostSchema.criticality._2, label: PriorityLabel.High },
  { value: HostSchema.criticality._1, label: PriorityLabel.Medium },
  { value: HostSchema.criticality._0, label: PriorityLabel.Low },
];
