import { PermissionOptions, PermissionsLevel } from 'app-constants';
import React from 'react';
import { Empty } from 'antd';
import { useDispatch } from 'react-redux';
import { CustomerId, DocumentSchema, DocumentsResponse } from 'services/api';
import { downloadDocument } from 'services/helpers/downloadDocument';
import { useGetPermission } from 'services/hooks';
import { Card, Table, ViewMobileTable } from 'shared';
import { AppDispatch, useApp } from 'store';
import { useTheme } from 'styled-components';
import { viewMoreConfig } from '../../constants';
import { columnsOrder } from '../constants';
import { getTableColumns } from './getTableColumns';

type LatestReportsProps = {
  data: DocumentsResponse;
  customerId: CustomerId;
  isLoading?: boolean;
  downloading?: string;
};

export const LatestReports = ({ data, isLoading, customerId, downloading }: LatestReportsProps) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();

  const downloadFile = React.useCallback(
    (name: string) => {
      downloadDocument({ dispatch, customer: customerId, filename: name });
    },
    [dispatch, customerId],
  );

  const permission = useGetPermission(PermissionOptions.Documents);

  const columns = getTableColumns(downloadFile, downloading);

  const { isMobile } = useApp();

  return (
    <Card
      title='Latest reports'
      showDivider={isMobile}
      viewMore={permission === PermissionsLevel.NoAccess ? undefined : viewMoreConfig.latestReports}
      height={theme.sizes.full}
      headStyles={
        !isMobile
          ? {
              titlePaddingRight: theme.spacing['2.5'],
              mt: theme.spacing[1],
            }
          : { pb: theme.spacing['2.5'], titlePaddingRight: theme.spacing[0] }
      }
      bodyPadding={theme.spacing[0]}
      cardPadding={!isMobile ? theme.spacing['5.5'] : theme.spacing[5]}
      showLoader={isLoading}
    >
      {!isMobile ? (
        <Table
          columns={columns}
          dataSource={data?.rows?.map((row: DocumentSchema) => {
            return {
              ...row,
              key: row.id,
            };
          })}
          tableLayout='auto'
          pagination={false}
          scroll={{ x: 400 }}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No reports yet' />,
          }}
        />
      ) : (
        <ViewMobileTable<DocumentSchema>
          columnsOrder={columnsOrder}
          columns={columns}
          data={data}
          isEmpty={!data.count && !isLoading}
        />
      )}
    </Card>
  );
};
