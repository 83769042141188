import React from 'react';
import { SupportRequestDetailsResponse } from 'services/api';
import { Paragraph, SummaryDetails } from '../styled';
import { Table } from './Table/Table';
import { getRowsForSummaryTable } from '../helpers';

interface IProps {
  requestDetails: SupportRequestDetailsResponse;
  isServiceRequest: boolean;
}

export const Summary = ({ requestDetails, isServiceRequest }: IProps) => {
  const summaryRows = getRowsForSummaryTable(isServiceRequest, requestDetails);
  const { summary } = requestDetails;

  return (
    <>
      <SummaryDetails>Summary</SummaryDetails>
      <Paragraph>{summary}</Paragraph>
      <Table tableData={summaryRows} />
    </>
  );
};
