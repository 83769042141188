import { Filter, OrderBy } from 'services/api';
import { InputType } from 'services/api/models/InputType';
import { optionsRisk } from '../../../Alarms/constants';

export enum TableTitles {
  Name = 'Alarm',
  Risk = 'Risk',
  Status = 'Status',
  Updated = 'Updated',
}

export enum ColumnKey {
  Alarm = 'alarm',
  Risk = 'risk',
  Status = 'status',
  Updated = 'updated',
}

export const searchSettings = {
  placeholder: 'Search by alarm name',
  fields: ['name'],
  min: 3,
};

export const filterFields = [
  {
    field: 'risk',
    options: optionsRisk,
    label: 'Risk',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
];

export const initialOrderBy = {
  field: 'severity',
  type: OrderBy.type.DESC,
};
