import styled from 'styled-components';
import { Button, Input } from 'shared';
import { getCssVar } from '../../../styles/theme';

export const StyledInput = styled(Input)`
  height: 40px;

  .ant-input-clear-icon-hidden {
    display: none;
  }

  .ant-input-suffix {
    cursor: pointer;
    padding-top: 10px;

    .anticon-close-circle {
      display: none;
    }
  }
`;

export const DeleteButton = styled(Button)`
  transform: none;

  .anticon {
    margin-top: 16px;

    svg {
      width: 12px;
    }

    svg > path {
      fill: ${getCssVar('bulkActionButtons.deleteColor')};
    }
  }
`;

export const ManageTagButton = styled(Button)`
  margin-right: 5px;
  transform: none;

  .anticon {
    margin-top: 16px;

    svg {
      width: 12px;
    }

    svg > path {
      fill: ${getCssVar('bulkActionButtons.manageColor')};
    }
  }
`;
