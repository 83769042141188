import { GridRequestParams, Filter, Pagination } from 'services/api';

const normalizeValue = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const getParametersFromPath = (searchParams: string | undefined, customerId?: string) => {
  if (searchParams) {
    const parametersFromPath = [...new URLSearchParams(searchParams)].reduce(
      (accumulator: GridRequestParams, parameter: [string, string]) => ({
        ...accumulator,
        [parameter[0]]: normalizeValue(parameter[1]),
      }),
      { customerId } as GridRequestParams,
    );

    return parametersFromPath;
  }

  return null;
};

export const getQueriesParams = (
  orderBy: { field: string; type: string },
  pagination: Pagination,
  ...fields: {
    value: unknown;
    name: string;
    type: Filter.type;
    validate?: (value: unknown) => boolean;
  }[]
) => ({
  orderBy,
  pagination,
  filter: {
    fields,
  },
});
