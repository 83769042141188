import React from 'react';
import { Box } from 'shared';
import { ColumnsType } from 'antd/es/table/interface';
import { AlarmSchema, ActiveServiceTypeEnum } from 'services/api';
import { emptyDataString } from 'app-constants';
import { Icon } from 'shared/Icon';
import { icons } from 'styles/theme';
import { StatusColoring } from './StatusColoring';
import { TableTitles } from '../constants';

const RowLogo = ({ type }: { type: ActiveServiceTypeEnum }) => {
  return type === ActiveServiceTypeEnum.SENTINEL ? (
    <Icon component={icons.microsoftLogo} style={{ marginLeft: 5 }} />
  ) : null;
};

export const columns: ColumnsType<AlarmSchema> = [
  {
    key: 'Service',
    title: TableTitles.Service,
    dataIndex: 'name',
    width: '80%',
    render: (name, { type }) => {
      return (
        <>
          {name} <RowLogo type={type as ActiveServiceTypeEnum} />
        </>
      );
    },
  },
  {
    key: 'status',
    title: TableTitles.Status,
    dataIndex: 'status',
    render: (status: string) =>
      !status ? (
        emptyDataString
      ) : (
        <Box className='status'>
          <StatusColoring status={status} />
        </Box>
      ),
  },
];
