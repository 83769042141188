import { css } from 'styled-components';
import { Theme } from 'styles/theme/types';
import { getCssVar } from '../../styles/theme';

type Props = {
  theme: Theme;
};

export const Secondary = css<Props>`
  background-color: ${getCssVar('buttonSecondary.backgroundColor')};
  border: ${getCssVar('buttonSecondary.border')};
  border-radius: ${getCssVar('button.borderRadius')};
  color: ${getCssVar('textColor')};

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    &:not(:disabled) {
      background-color: ${getCssVar('buttonSecondary.hover.backgroundColor')};
      border: ${getCssVar('buttonSecondary.border')};
      border-color: ${getCssVar('buttonSecondary.border')};
      color: ${getCssVar('buttonSecondary.hover.color')};
    }
  }

  &:active,
  &.active {
    &:not(:disabled) {
      background-color: ${getCssVar('buttonSecondary.active.backgroundColor')};
      color: ${getCssVar('buttonSecondary.active.color')};
    }
  }

  &:not(:disabled) {
    border-color: ${getCssVar('buttonSecondary.border')};
  }

  &:disabled {
    background-color: ${getCssVar('buttonSecondary.disabled.backgroundColor')};
    border-color: ${getCssVar('buttonSecondary.disabled.borderColor')};
    color: ${getCssVar('buttonSecondary.disabled.color')};
    cursor: not-allowed;
  }
`;
