import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Action, Goals, Page } from '../styled';

export const Wrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide14.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const NewGoals = styled(Goals)``;

export const NewActions = styled(Action)`
  left: 100px;
  max-width: 865px;
  padding: 20px 5px;

  ul > li {
    margin-bottom: 10px;
  }
`;
