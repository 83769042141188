import React from 'react';
import { Icon } from 'shared';
import { icons } from 'styles/theme';
import { NotificationType } from '../../../services/api';

type IStatusIconProps = {
  type: string;
  style?: React.CSSProperties;
};

export const IconType = ({ type, style }: IStatusIconProps) => {
  const statusType = (status: string) => {
    switch (status) {
      case NotificationType.ESSENTIAL_ANNOUNCEMENTS:
        return icons.essentialAnnouncements;
      case NotificationType.SMARTBLOC_NEWS:
        return icons.smartBlockNews;
      default:
        return icons.releaseNote;
    }
  };

  const statusIcon = statusType(type);

  return <Icon component={statusIcon} style={style} />;
};
