import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { Routes } from 'services/entities';
import { backlinkRoutes } from 'app-constants';
import { useTheme } from 'styled-components';
import * as S from './ServiceCard.styled';

type ConfigureIntegrationLinkProps = {
  id: string;
};

export const ConfigureIntegrationLink = ({ id }: ConfigureIntegrationLinkProps) => {
  const history = useHistory();
  const { icons } = useTheme();

  const redirectToServiceDetails = useCallback(() => {
    history.push(`${Routes.SERVICE_INTEGRATION_DETAILS.replace(':id', id)}`, {
      backTo: {
        route: Routes.SERVICES_INTEGRATIONS,
        title: `Back to ${backlinkRoutes[Routes.SERVICES_INTEGRATIONS]}`,
      },
    });
  }, [history, id]);

  return (
    <S.ConfigureIntegrationWrapper disabled={!id} onClick={redirectToServiceDetails}>
      <S.LikText>Integration details</S.LikText>
      <S.IconLeftArrow component={icons.arrow_right_alt_small} />
    </S.ConfigureIntegrationWrapper>
  );
};
