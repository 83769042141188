import { Form, FormItemProps } from 'antd';

type Props = FormItemProps & {
  labelColor: string;
  isFeedbackCentered?: boolean;
  width?: string;
  height?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FormItem = ({
  labelColor,
  isFeedbackCentered,
  width,
  height,
  ...formItemProps
}: Props) => <Form.Item {...formItemProps} />;
