import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Themes } from 'themes/types';
import { saveThemeMode } from 'services/helpers/theme';

type InitialState = {
  isLargeDesktop: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  themeName?: Themes;
  currentCustomerId?: string;
  currentCustomerName?: string;
  isMenuCollapsed?: boolean;
  isChartsFontLoaded?: boolean;
  lastVisitedPage: string;
  notFoundBackPage: string;
  blured: boolean;
  currentThemeMode?: string;
};

export const appInitialState: InitialState = {
  isLargeDesktop: false,
  isDesktop: false,
  isTablet: false,
  isMobile: false,
  themeName: undefined,
  blured: false,
  currentCustomerId: '',
  currentCustomerName: '',
  isMenuCollapsed: false,
  isChartsFontLoaded: false,
  lastVisitedPage: '',
  notFoundBackPage: '',
  currentThemeMode: undefined,
};

export const app = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setScreenSizes: (
      state: InitialState,
      action: PayloadAction<{
        isLargeDesktop: boolean;
        isDesktop: boolean;
        isTablet: boolean;
        isMobile: boolean;
      }>,
    ) => ({
      ...state,
      isLargeDesktop: action.payload.isLargeDesktop,
      isDesktop: action.payload.isDesktop,
      isTablet: action.payload.isTablet,
      isMobile: action.payload.isMobile,
    }),
    setBlured: (state: InitialState, action: PayloadAction<boolean>) => ({
      ...state,
      blured: action.payload,
    }),
    setMode: (state: InitialState, action: PayloadAction<string>) => {
      saveThemeMode(action.payload as Themes);

      return {
        ...state,
        currentThemeMode: action.payload,
      };
    },
    setTheme: (state: InitialState, action: PayloadAction<Themes>) => ({
      ...state,
      themeName: action.payload,
    }),
    setNotFoundBackPage: (state: InitialState, action: PayloadAction<string>) => ({
      ...state,
      notFoundBackPage: action.payload,
    }),
    setLastVisitedPage: (state: InitialState, action: PayloadAction<string>) => ({
      ...state,
      lastVisitedPage: action.payload,
    }),
    setIsChartsFontLoaded: (state: InitialState, action: PayloadAction<boolean>) => ({
      ...state,
      isChartsFontLoaded: action.payload,
    }),
    setIsMenuCollapsed: (state: InitialState, action: PayloadAction<boolean>) => ({
      ...state,
      isMenuCollapsed: action.payload,
    }),
    setCurrentCustomerId: (state: InitialState, action: { payload: string }) => ({
      ...state,
      currentCustomerId: action.payload,
    }),
    setCurrentCustomerName: (state: InitialState, action: { payload: string }) => ({
      ...state,
      currentCustomerName: action.payload,
    }),
  },
});
