import styled from 'styled-components';
import { TableColumn as CmdbTableColumn } from 'pages/CmdbHostDetails/components/AssetDetails/styled';
import { Theme } from 'styles/theme/types';

export const TableColumn = styled(CmdbTableColumn)`
  .ant-descriptions-item {
    padding: ${({ theme }: { theme: Theme }) => `${theme.spacing[3]} ${theme.spacing[0]}`};
  }
  .ant-descriptions-item:last-child {
    padding: ${({ theme }: { theme: Theme }) =>
      `${theme.spacing[3]} ${theme.spacing[0]} !important`};
  }
`;
