import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssetsPatchesCountByPlatformResponse,
  PatchCountGrouppedByResponse,
  PatchesCountByStatusResponse,
  PatchesCountResponse,
  PatchesListResponse,
} from 'services/api';
import { patchDashboardApi } from './thunks';

interface InitialState {
  metrics: {
    allAssets: PatchesListResponse;
    assetsAwaitingReboot: PatchesListResponse;
    patchesByPlatform: AssetsPatchesCountByPlatformResponse;
    patchesByOSStatus: PatchesCountByStatusResponse;
    patchesByStatus: PatchesCountResponse;
  };
  patchesGroupedBy: PatchCountGrouppedByResponse;
}

const initialState: InitialState = {
  metrics: {
    allAssets: {},
    assetsAwaitingReboot: {},
    patchesByPlatform: {},
    patchesByOSStatus: {
      successCount: 0,
      installedCount: 0,
      failedCount: 0,
      successPatches: [],
      failedPatches: [],
      installedPatches: [],
    },
    patchesByStatus: {},
  },
  patchesGroupedBy: [],
};

const extraReducers = {
  [patchDashboardApi.getAllAssets.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchesListResponse>,
  ) => {
    state.metrics.allAssets = action.payload;
  },
  [patchDashboardApi.getAllAssets.rejected.toString()]: (state: InitialState) => {
    state.metrics.allAssets = {};
  },
  [patchDashboardApi.getAssetsAwaitingRebootCount.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchesListResponse>,
  ) => {
    state.metrics.assetsAwaitingReboot = action.payload;
  },
  [patchDashboardApi.getAssetsAwaitingRebootCount.rejected.toString()]: (state: InitialState) => {
    state.metrics.assetsAwaitingReboot = {};
  },
  [patchDashboardApi.getMissingPatchesByPlatform.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchesListResponse>,
  ) => {
    state.metrics.patchesByPlatform = action.payload;
  },
  [patchDashboardApi.getMissingPatchesByPlatform.rejected.toString()]: (state: InitialState) => {
    state.metrics.patchesByPlatform = {};
  },
  [patchDashboardApi.getPatchesByOSStatus.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchesCountByStatusResponse>,
  ) => {
    state.metrics.patchesByOSStatus = action.payload;
  },
  [patchDashboardApi.getPatchesByOSStatus.rejected.toString()]: (state: InitialState) => {
    state.metrics.patchesByOSStatus = initialState.metrics.patchesByOSStatus;
  },
  [patchDashboardApi.getPatchesByStatus.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchesCountResponse>,
  ) => {
    state.metrics.patchesByStatus = action.payload;
  },
  [patchDashboardApi.getPatchesByStatus.rejected.toString()]: (state: InitialState) => {
    state.metrics.patchesByStatus = {};
  },
  [patchDashboardApi.getPatchesGroupedBy.fulfilled.toString()]: (
    state: InitialState,
    action: PayloadAction<PatchCountGrouppedByResponse>,
  ) => {
    state.patchesGroupedBy = action.payload;
  },
  [patchDashboardApi.getPatchesGroupedBy.rejected.toString()]: (state: InitialState) => {
    state.patchesGroupedBy = [];
  },
};

export const patchManagementDashboard = createSlice({
  name: 'patchManagementDashboard',
  initialState,
  reducers: {},
  extraReducers,
});
