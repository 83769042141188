import React from 'react';
import { startCase } from 'lodash';
import styled from 'styled-components';
import { Theme } from '../../../../../../../styles/theme/types';
import { Colors } from '../../../../../../../shared/charts/types';
import { getCurrentVulnerabilitiesBySeverityColors } from '../../../../../../VulnerabilityManagement/constants';
import { Severity } from '../../../../../../../services/constants';
import { ReportChart } from './ReportChart';

export type Values = Record<string, { value: number; previousDifference?: number }>;

interface Props {
  onChartLoaded: () => void;
  theme: Theme;
  values: Values;
  height: string;
  width: string;
}

interface DataInterface {
  name: string;
  itemStyle: { color: keyof Colors };
  value: number;
  label: Record<string, unknown>;
  cursor: 'pointer' | 'default';
}

const getOption = (values: Values, colors: Colors) => {
  const seriesNames: string[] = [];
  const data: DataInterface[] = [];

  Object.entries(values).forEach(([name, { value, previousDifference }]) => {
    seriesNames.push(startCase(name));

    let formatter = '{c}';

    if (typeof previousDifference === 'number') {
      if (previousDifference > 0) {
        formatter += ` {negative|(+${previousDifference})}`;
      }

      if (previousDifference < 0) {
        formatter += ` {positive|(${previousDifference})}`;
      }
    }

    data.push({
      name,
      itemStyle: { color: colors[name.toLowerCase()] },
      value,
      label: {
        show: true,
        position: 'top',
        color: '#000',
        fontSize: 9,
        fontWeight: 500,
        formatter,
        rich: {
          positive: {
            color: '#43a047',
            fontSize: 9,
          },
          negative: {
            color: '#9C2B23',
            fontSize: 9,
          },
        },
      },
      cursor: name !== Severity.Analysing ? 'pointer' : 'default',
    });
  });

  return {
    grid: {
      left: 10,
      top: 25,
      right: 10,
      bottom: 5,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: seriesNames,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          show: true,
          interval: 0,
          fontSize: 9,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        axisLabel: { show: true, interval: 0, fontSize: 9 },
      },
    ],
    series: [
      {
        data,
        type: 'bar',
        name: 'Total',
        label: {
          show: true,
          position: 'top',
          formatter({ data: { value, name } }: { data: { value: number; name: keyof Values } }) {
            const previousDifference = values[name]?.previousDifference;

            if (typeof previousDifference === 'number') {
              if (previousDifference > 0) {
                return `${value} (+${previousDifference})`;
              }

              if (previousDifference < 0) {
                return `${value} (${previousDifference})`;
              }
            }

            return `${value}`;
          },
        },
      },
    ],
  };
};

export const Wrapper = styled.div`
  > div {
    background: transparent;
    z-index: 0;
  }
`;

export function MultiColumnsReportChart({ onChartLoaded, theme, values, height, width }: Props) {
  return (
    <Wrapper>
      <ReportChart
        width={width}
        height={height}
        onFinished={onChartLoaded}
        option={getOption(values, getCurrentVulnerabilitiesBySeverityColors(theme))}
      />
    </Wrapper>
  );
}
