import { SuppressionHistoryItem } from 'services/api';
import { ColumnsType } from 'antd/es/table/interface';
import { getCapitalizedString, getRenderValue } from 'services/helpers';
import { NO_VALUE_LABEL } from 'app-constants';
import { SuppressedAtColumn } from 'shared';
import { CroppedText } from 'shared/CroppedText';
import { ColumnKey, TableTitles } from './constants';

export const getColumns = (): ColumnsType<SuppressionHistoryItem> => [
  {
    key: ColumnKey.Status,
    title: TableTitles.Status,
    dataIndex: ColumnKey.Status,
    align: 'left',
    render: (status: string) => <>{!status ? NO_VALUE_LABEL : getCapitalizedString(status)}</>,
    sorter: false,
    showSorterTooltip: false,
  },
  {
    key: ColumnKey.SuppressedBy,
    title: TableTitles.SuppressedBy,
    dataIndex: ColumnKey.SuppressedBy,
    sorter: false,
    showSorterTooltip: false,
    render: (suppressedBy: string) => <>{getRenderValue(suppressedBy, NO_VALUE_LABEL)}</>,
  },
  {
    key: ColumnKey.BusinessReason,
    title: TableTitles.BusinessReason,
    dataIndex: ColumnKey.BusinessReason,
    sorter: false,
    showSorterTooltip: false,
    render: (businessReason: string) => (
      <CroppedText text={getRenderValue(businessReason, NO_VALUE_LABEL) as string} maxLength={80} />
    ),
    width: 320,
  },
  {
    key: ColumnKey.UpdatedAt,
    title: TableTitles.UpdatedAt,
    dataIndex: ColumnKey.UpdatedAt,
    sorter: false,
    showSorterTooltip: false,
    render: (date: string, record: SuppressionHistoryItem, index: number) => (
      <SuppressedAtColumn date={date} record={record} index={index} />
    ),
    className: 'right-head-title',
    align: 'right',
  },
];
