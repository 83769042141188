import React, { useMemo } from 'react';
import { MonthlyReportResponse } from 'services/api';
import { IDate } from 'services/interfaces';
import { subTitleFormatterSum } from 'pages/PatchManagement/helpers';
import {
  ChartTitle,
  CurrentLimitHeaderCell,
  EventsChart,
  LicenseUsageTable,
  LicenseUsageTableWrapper,
  ServiceConsumption,
  ServiceHeaderCell,
  SubTitle,
  SubTitlePrefix,
  UsedHeaderCell,
  Wrapper,
} from './styled';
import { IEvents } from '../../../../../../ThreatDetection/types';
import { AreaReportChart } from '../../components/Charts/AreaReportChart';

export type RowTitle = Record<string, string>;
const tableScoreRowsTitle: RowTitle = {
  vulnerabilityAgents: 'Vulnerability Agents',
  threatDetectionServices: 'Threat Detection (Services)',
  cyberSafetyAndPhishing: 'Cyber Safety and Phishing',
  threatDetectionAgents: 'Threat Detection Agents',
};

type Props = {
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  reportDate: IDate;
};

// const getOption = (params: { values: IEvents[]; date?: IDate }): IEChartsOption => {
//   const date: IDate = params.date
//     ? params.date
//     : {
//         startDate: moment().subtract(RadioButtonsDays.OneMonth, 'days').startOf('day').valueOf(),
//         endDate: moment().endOf('day').valueOf(),
//       };

//   const entries = formatData(params.values, date);
//   const markPointLableMaxLength = 5;
//   const isEmpty =
//     entries?.length <= 1 && entries[0]?.count === 0 && entries[0]?.collectedDateTime === 0;

//   return {
//     grid: {
//       top: 50,
//       bottom: 0,
//       right: 20,
//       left: 40,
//       height: 190,
//     },
//     xAxis: {
//       type: 'category',
//       axisTick: { alignWithLabel: true },
//       boundaryGap: [0, '10%'],
//       data: isEmpty
//         ? []
//         : entries.map((events: IEvents) => formatDate(`${events.collectedDateTime}`, date)),
//       axisLabel: {
//         fontSize: 9,
//       },
//     },
//     yAxis: {
//       type: 'value',
//       axisLabel: {
//         formatter: (value: number) => numberFormatter(value),
//         fontSize: 9,
//       },
//       min: 0,
//     },
//     series: [
//       {
//         data: entries.map((events: IEvents) => events.count),
//         type: 'line',
//         name: 'Total',
//         lineStyle: {
//           width: 1,
//         },
//         markPoint: {
//           symbolSize: 35,
//           data: [
//             { type: 'max', name: 'Max' },
//             { type: 'min', name: 'Min' },
//           ],
//           label: {
//             fontSize: 9,
//             show: true,
//             formatter: (_params: LabelFormatterParams) => {
//               const value = numberFormatter(_params.data.value);
//               if (`${value}`.length >= markPointLableMaxLength) {
//                 return `${value}`.split('K').join('\nK');
//               }
//               return value;
//             },
//           },
//         },
//         markLine: isEmpty
//           ? {}
//           : {
//               data: [
//                 {
//                   type: 'average',
//                   name: 'Average',
//                 },
//               ],
//               label: {
//                 show: false,
//               },
//               silent: true,
//               symbol: 'none',
//             },
//         symbol: 'emptyCircle',
//         areaStyle: { opacity: 0.2 },
//         showSymbol: !isEmpty,
//         symbolSize: 4,
//         silent: true,
//       },
//     ],
//   };
// };

export function PdfPage9({
  data: { licenseUsage, eventsChart },
  onChartLoaded,
  reportDate,
}: Props) {
  const eventsData = eventsChart?.allEvents || [];
  const total = eventsChart?.allEventsCount || 0;
  const isUp = Number(eventsChart?.trendingPercent) > 0;
  const tableData = useMemo(
    () =>
      Object.entries(licenseUsage || {}).map(([key, value]) => ({
        currentLimit: value?.currentLimit || 0,
        used: value?.used || 0,
        service: tableScoreRowsTitle[key],
      })),
    [licenseUsage],
  );

  return (
    <Wrapper>
      <ServiceConsumption>
        Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
        quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
        pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque neceari opta sinis
        excestiate comni nonsedi velibeate coriti as quas mo exerspe rumql et ipsum utem nos eos et
        landanti con ni ad ut que eum quis et aperuptatur? Lam, odit rumql et ipsum utem nos eos et
        landanti con ni ad ut que eum? Insert copy here. Pelictaspis experat emoluptatur, quam eos
        eiur aut libust, ipita del mi, quist excea sunt.Oviti cullanis quibernati ut milit auda
        nescimendi dolum fuga. Lam, odit pereped ut qui quisimi, tem dolorro vidunde
      </ServiceConsumption>
      <SubTitle>
        <SubTitlePrefix>{`${isUp ? '+' : ''}${
          eventsChart?.trendingPercent || 0
        }% vs last period`}</SubTitlePrefix>
        {subTitleFormatterSum([total], 1)}
      </SubTitle>
      <ChartTitle>All events</ChartTitle>
      <EventsChart>
        <AreaReportChart
          values={{ values: eventsData as IEvents[], date: reportDate }}
          onChartLoaded={onChartLoaded}
          width='605px'
          height='260px'
        />
      </EventsChart>

      <LicenseUsageTableWrapper>
        <p>Licence usage</p>
        <LicenseUsageTable>
          <thead>
            <tr>
              <ServiceHeaderCell>Service</ServiceHeaderCell>
              <UsedHeaderCell>Used</UsedHeaderCell>
              <CurrentLimitHeaderCell>Current Limit</CurrentLimitHeaderCell>
            </tr>
          </thead>
          <tbody>
            {tableData.map(({ service, used, currentLimit }) => (
              <tr key={service}>
                <td>{service}</td>
                <td
                  style={{
                    color: (currentLimit as number) <= used ? 'rgb(235, 100, 74)' : '#9ccc65',
                    fontWeight: 'bold',
                  }}
                >
                  {used}
                </td>
                <td>{currentLimit}</td>
              </tr>
            ))}
          </tbody>
        </LicenseUsageTable>
      </LicenseUsageTableWrapper>
    </Wrapper>
  );
}
