import React from 'react';
import { useApp, useLoading, usePatchManagement, usePlatform } from 'store';
import { Card } from 'shared';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { initialOrderByPatches, PBBackRoute, TooltipTitles, viewMoreConfig } from '../../constants';
import { patchDashboardApi } from '../../../../store/patchManagement/dashboard/thunks';
import { SegmentedPie } from '../../../../shared/charts';
import { getLegendColors, getLegendValues, subTitleFormatterSum } from '../../helpers';
import { EChartsEventParams } from '../../../../shared/charts/interfaces';
import { Routes } from '../../../../services/entities';
import { getQueryString } from '../../../../services/api/core/request';
import { Filter, PatchStatus } from '../../../../services/api';
import { initialPagination } from '../../../../app-constants';
import { SubTitle } from '../../../../shared/Card/components/SubTitle/SubTitle';
import { VendorChart } from '../../styled';
import { emptyMessage } from '../../../../shared/Table/EmptyTablePreview/constants';
import { isEmptyObject } from '../../../../services/helpers';

export const ByVendors = () => {
  const theme = useTheme();
  const { isMobile, isTablet, isMenuCollapsed } = useApp();
  const history = useHistory();
  const { patchesGroupedBy } = usePatchManagement();
  const loading = useLoading([patchDashboardApi.getPatchesGroupedBy.typePrefix]);
  const vendorChart = patchesGroupedBy?.find((chart) => chart?.chart === 'vendor');
  const platform = usePlatform();
  const vendorColors = [
    theme.colors.blue[700],
    theme.colors.purple[300],
    theme.colors.green[600],
    theme.colors.purple[500],
    theme.colors.yellow[300],
    theme.colors.blue[50],
  ];

  const limit = vendorColors.length - 1;
  const vendorData = patchesGroupedBy.find(({ chart }) => chart === 'vendor');
  const legendValues = getLegendValues(vendorData?.points, 'title', limit);

  const getPatchParams = (value: string | string[]) => ({
    orderBy: initialOrderByPatches,
    pagination: initialPagination,
    filter: {
      fields: [
        {
          name: 'patchStatus',
          value: [PatchStatus.MISSING],
          type: Filter.type.MULTIPLE,
        },
        {
          name: 'vendor',
          value,
          type: Filter.type.MULTIPLE,
        },
      ],
    },
  });

  const redirectToMissingPatches = React.useCallback(
    (params: EChartsEventParams) => {
      if (!params?.name) {
        return;
      }

      const otherValues = vendorChart?.points?.slice(limit).map((point) => point.title) || [];

      const value = params.name === 'Other' ? (otherValues as string[]) : [params.name];
      const search = getQueryString(getPatchParams(value));
      const url = `${Routes.PATCH_MANAGEMENT_PATCHES}${search}`;

      history.push(url, PBBackRoute);
    },
    [history, limit, vendorChart?.points],
  );

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => redirectToMissingPatches(params),
    }),
    [redirectToMissingPatches],
  );

  return (
    <Card
      title='By vendors'
      showDivider={false}
      subTitle={
        <SubTitle
          boldText={subTitleFormatterSum(Object.values(legendValues), 1)}
          customText='Total'
        />
      }
      height={theme.sizes.full}
      tooltipTitle={TooltipTitles(platform).ByVendors}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[patchDashboardApi.getPatchesGroupedBy.typePrefix]}
      viewMore={viewMoreConfig(false, true).patches}
    >
      {!isEmptyObject(legendValues) ? (
        <VendorChart>
          <SegmentedPie
            values={legendValues || {}}
            colors={getLegendColors(legendValues, vendorColors)}
            direction={isMobile || !isTablet ? 'column' : 'row'}
            entitiesName=''
            style={
              isMobile
                ? {
                    height: '165px',
                    marginTop: `-${theme.spacing[0]}`,
                    marginLeft: `${theme.spacing[1]}`,
                  }
                : { height: '258px', width: '350px' }
            }
            isMobile={isMobile}
            isMenuCollapsed={isMenuCollapsed}
            onEvents={onEvents}
          />
        </VendorChart>
      ) : (
        emptyMessage('No missing patches by vendor yet')
      )}
    </Card>
  );
};
