import React from 'react';
import moment from 'moment/moment';
import { getQueriesParams } from 'services/helpers/URL';
import { Filter, OSTypes } from 'services/api';
import { Routes } from 'services/entities';
import { getQueryString } from 'services/api/core/request';
import { CEPlusStatus } from './components/CEPlusStatus';
import { numberFormatter } from '../../services/helpers';
import {
  cePlusBackRoute,
  DAYS_ON_WEEK,
  DEFAULT_CVSS_SCORE,
  DEFAULT_DAYS_ON_MONTH,
  DEFAULT_DAYS_ON_YEAR,
  DEFAULT_OPERATING_SYSTEM,
  DOT_LENGTH,
  ICMDBRedirectProps,
  IFilterField,
  IGNORE_DEFAULT_FILED_NAME,
  initialCMDBOrderBy,
  initialVulnerabilityOrderBy,
  IVulnerabilitiesDateParams,
  MAX_CHART_TEXT_LENGTH,
  MIN_CHART_DATA_VALUE,
  VulnerabilityDaysGap,
  VulnerabilityDetectionsGap,
} from './constants';
import { emptyDataString, initialPagination } from '../../app-constants';
import { Colors, SegmentedPieData, Values } from '../../shared/charts/types';
import { FilterCEPlusByTags } from './components/FilterCEPlusByTags';

interface LayoutProps {
  status: string;
}

export const getLayoutConfig = ({ status }: LayoutProps) => ({
  title: 'Cyber Essentials dashboard',
  isBeforeToggleComponent: <FilterCEPlusByTags />,
  isCustomComponentNearTitle: <CEPlusStatus status={status} />,
  mobileCustomComponentStyle: { display: 'flex', justifyContent: 'end' },
});

export const validateFieldValue = (value: string | string[]) => value.length > 0;

export const validateField = (fields: IFilterField[]) => {
  return fields.filter((item) => (item.validate ? item.validate(item.value) : true));
};

export const getPercentFromTotal = (num = 0, total = 0) => {
  const hundredPercent = 100;

  const result = (num * hundredPercent) / total || 0;

  return `${result.toFixed(0)}%`;
};

export const createVulnerabilityList = (
  lastWeek = 0,
  PenultimateWeek = 0,
  moreThenTwoWeeks = 0,
  total = 0,
) => [
  {
    total: numberFormatter(lastWeek, 1),
    percent: getPercentFromTotal(lastWeek, total),
    filterName: VulnerabilityDetectionsGap.LAST_WEEK,
    days: VulnerabilityDaysGap.LAST_WEEK,
  },
  {
    total: numberFormatter(PenultimateWeek, 1),
    percent: getPercentFromTotal(PenultimateWeek, total),
    filterName: VulnerabilityDetectionsGap.PENULTIMATE_WEEK,
    days: VulnerabilityDaysGap.PENULTIMATE_WEEK,
  },
  {
    total: numberFormatter(moreThenTwoWeeks, 1),
    percent: getPercentFromTotal(moreThenTwoWeeks, total),
    filterName: VulnerabilityDetectionsGap.MORE_THEN_TWO_WEEKS,
    days: VulnerabilityDaysGap.MORE_THEN_TWO_WEEKS,
  },
];

export const lastWeek = moment().subtract(DAYS_ON_WEEK, 'days').startOf('day').valueOf();

export const penultimateWeek = moment()
  .subtract(DAYS_ON_WEEK * 2, 'days')
  .valueOf();

export const lastMonth = moment().subtract(DEFAULT_DAYS_ON_MONTH, 'days').startOf('day').valueOf();
export const lastYear = moment().subtract(DEFAULT_DAYS_ON_YEAR, 'days').startOf('day').valueOf();

export const getVulnerabilityDetectionsFilter = (name: string | undefined) => {
  let vulnerabilitiesDetectionsFilter: IVulnerabilitiesDateParams = {};

  switch (name) {
    case VulnerabilityDetectionsGap.LAST_WEEK:
      vulnerabilitiesDetectionsFilter.fromFirstFound = lastWeek;
      break;
    case VulnerabilityDetectionsGap.PENULTIMATE_WEEK:
      vulnerabilitiesDetectionsFilter.fromFirstFound = penultimateWeek;
      vulnerabilitiesDetectionsFilter.toFirstFound = lastWeek;
      break;
    case VulnerabilityDetectionsGap.MORE_THEN_TWO_WEEKS:
      vulnerabilitiesDetectionsFilter.toFirstFound = penultimateWeek;
      break;
    default:
      vulnerabilitiesDetectionsFilter = {};
  }

  return vulnerabilitiesDetectionsFilter;
};

export const getFullCMDBParams = (
  { product, publisher, osType }: ICMDBRedirectProps,
  tagsAssets?: string[],
) => {
  const filterFields: IFilterField[] = [
    {
      name: 'publisher',
      value: publisher,
      type: Filter.type.VALUE,
    },
    {
      name: 'product',
      value: product,
      type: Filter.type.VALUE,
    },
    {
      name: 'operatingSystemType',
      value: osType,
      type: Filter.type.VALUE,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];

  return {
    orderBy: initialCMDBOrderBy,
    pagination: initialPagination,
    filter: {
      fields: validateField(filterFields),
    },
  };
};

export const getTopHardwareParams = (
  hardwareManufacturer?: string,
  product?: string,
  isServer = true,
  tagsAssets?: string[],
) => {
  const filterFields: IFilterField[] = [
    {
      name: 'hardwareManufacturer',
      value: hardwareManufacturer,
      type: Filter.type.VALUE,
    },
    {
      name: 'hardwareProduct',
      value: product,
      type: Filter.type.VALUE,
    },
    {
      name: 'hardwareCategory1',
      value: 'Computers',
      type: Filter.type.VALUE,
    },
    {
      name: 'hardwareCategory2',
      value: OSTypes.SERVER,
      type: Filter.type.VALUE,
      negate: !isServer,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (tags) => validateFieldValue(tags as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];

  return {
    orderBy: initialCMDBOrderBy,
    pagination: initialPagination,
    filter: {
      fields: validateField(filterFields),
    },
  };
};

export const getTotalAssetsParams = (filtersFields = {}, filters = {}) => ({
  orderBy: initialCMDBOrderBy,
  pagination: initialPagination,
  filter: {
    ...filters,
    ...filtersFields,
  },
});

export const getTotalVulnerabilitiesParams = (
  filters = {},
  filtersFields = [] as IFilterField[],
) => ({
  orderBy: initialVulnerabilityOrderBy,
  pagination: initialPagination,
  filter: {
    ...filters,
    fields: [...filtersFields],
  },
});

export const viewMoreConfig = (tagsAssets?: string[]) => {
  const cmdbDistributionFields: IFilterField[] = [
    {
      name: 'platform',
      value: emptyDataString,
      type: Filter.type.VALUE,
      negate: true,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];
  const cmdbHardwareManufacturerFields: IFilterField[] = [
    {
      name: 'hardwareManufacturer',
      value: emptyDataString,
      type: Filter.type.VALUE,
      negate: true,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];
  const CMDBClientsFields: IFilterField[] = [
    {
      name: 'hardwareCategory1',
      value: 'Computers',
      type: Filter.type.VALUE,
    },
    {
      name: 'hardwareCategory2',
      value: OSTypes.SERVER,
      type: Filter.type.VALUE,
      negate: true,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];
  const CMDBServerFields: IFilterField[] = [
    {
      name: 'hardwareCategory1',
      value: 'Computers',
      type: Filter.type.VALUE,
    },
    {
      name: 'hardwareCategory2',
      value: OSTypes.SERVER,
      type: Filter.type.VALUE,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];
  const clientFields: IFilterField[] = [
    {
      name: 'operatingSystemType',
      value: OSTypes.CLIENT,
      type: Filter.type.VALUE,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];
  const serverFields: IFilterField[] = [
    {
      name: 'operatingSystemType',
      value: OSTypes.SERVER,
      type: Filter.type.VALUE,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];
  const top10WindowsFields: IFilterField[] = [
    {
      name: 'product',
      value: DEFAULT_OPERATING_SYSTEM,
      type: Filter.type.VALUE,
    },
    {
      name: 'osVersion',
      value: emptyDataString,
      type: Filter.type.VALUE,
      negate: true,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];
  const totalAssetsInScopeFields: IFilterField[] = [
    {
      name: 'countVulnerabilitiesWithBaseScoreMoreThen7',
      value: 'true',
      type: Filter.type.VALUE,
    },
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];
  const vendorPatchAvailableFields: IFilterField[] = [
    ...totalAssetsInScopeFields,
    {
      name: 'patchable',
      value: 'patch',
      type: Filter.type.VALUE,
    },
  ];
  const totalVulnerabilitiesInScopeFilters = {
    cvssScore: DEFAULT_CVSS_SCORE,
    fixedVulnerabilities: false,
    fixedVulnerabilitiesFrom: null,
  };
  const totalVulnerabilitiesInScopeFields: IFilterField[] = [
    {
      name: 'tagsAssets',
      value: tagsAssets || [],
      validate: (value) => validateFieldValue(value as string[]),
      type: Filter.type.MULTIPLE,
    },
  ];

  return {
    CMDB: { pathname: Routes.CMDB, search: '', route: Routes.CMDB, state: cePlusBackRoute },
    cmdbDistribution: {
      pathname: Routes.CMDB,
      route: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(
          initialCMDBOrderBy,
          initialPagination,
          ...validateField(cmdbDistributionFields),
        ),
      ),
      state: cePlusBackRoute,
    },
    cmdbHardwareManufacturer: {
      pathname: Routes.CMDB,
      route: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(
          initialCMDBOrderBy,
          initialPagination,
          ...validateField(cmdbHardwareManufacturerFields),
        ),
      ),
      state: cePlusBackRoute,
    },
    CMDBClients: {
      route: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(
          initialCMDBOrderBy,
          initialPagination,
          ...validateField(CMDBClientsFields),
        ),
      ),
    },
    CMDBServer: {
      route: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(initialCMDBOrderBy, initialPagination, ...validateField(CMDBServerFields)),
      ),
    },
    client: {
      pathname: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(initialCMDBOrderBy, initialPagination, ...validateField(clientFields)),
      ),
      state: cePlusBackRoute,
      route: Routes.CMDB,
    },
    server: {
      pathname: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(initialCMDBOrderBy, initialPagination, ...validateField(serverFields)),
      ),
      route: Routes.CMDB,
      state: cePlusBackRoute,
    },
    top10Windows: {
      pathname: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(
          initialCMDBOrderBy,
          initialPagination,
          ...validateField(top10WindowsFields),
        ),
      ),
      route: Routes.CMDB,
      state: cePlusBackRoute,
    },
    totalAssetsInScope: {
      pathname: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(
          initialCMDBOrderBy,
          initialPagination,
          ...validateField(totalAssetsInScopeFields),
        ),
      ),
      state: { ...cePlusBackRoute, ignoreDefaultBehavior: true },
      route: Routes.CMDB,
    },
    totalVulnerabilitiesInScope: {
      pathname: Routes.VULNERABILITIES_LIST,
      search: getQueryString(
        getTotalVulnerabilitiesParams(totalVulnerabilitiesInScopeFilters, [
          ...validateField(totalVulnerabilitiesInScopeFields),
        ]),
      ),
      state: { ...cePlusBackRoute, ignoreDefaultBehavior: true },
      route: Routes.VULNERABILITIES_LIST,
    },
    vendorPatchAvailable: {
      pathname: Routes.CMDB,
      search: getQueryString(
        getQueriesParams(
          initialCMDBOrderBy,
          initialPagination,
          ...validateField(vendorPatchAvailableFields),
        ),
      ),
      state: { ...cePlusBackRoute, ignoreDefaultBehavior: true },
      route: Routes.CMDB,
    },
  };
};

export const trimmedString = (string: string, length = MAX_CHART_TEXT_LENGTH) =>
  string.length > length ? `${string.substring(0, length - DOT_LENGTH)}...` : string;

export const getCustomDataHandler = (
  values: Values,
  colors?: Colors,
  ignoreFiled = IGNORE_DEFAULT_FILED_NAME,
) => {
  const vulnerabilitiesValueOnChart = values.total - values.vulnerabilities;

  const vulnerabilitiesResult =
    vulnerabilitiesValueOnChart < MIN_CHART_DATA_VALUE
      ? MIN_CHART_DATA_VALUE
      : vulnerabilitiesValueOnChart;

  return Object.keys(values).reduce(
    (result: SegmentedPieData, key) => [
      ...result,
      {
        name: key,
        value: key === ignoreFiled ? vulnerabilitiesResult : values[key],
        itemStyle: { color: colors && colors[key] },
        label: key === ignoreFiled ? { show: false } : {},
        result: values[key],
      },
    ],
    [],
  );
};
