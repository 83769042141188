import { AppDispatch, processApi } from 'store';
import { uploadDocument } from 'services/helpers/uploadDocument';
import { DocumentType } from 'services/api';
import moment from 'moment';
import { IValuesForm } from '../../types';
import { orderCertificate } from './orderCertificate';

type Test = {
  values: IValuesForm;
  setLoading: (loading: boolean) => void;
  isFileAttached: boolean;
  attachedFile: File;
  dispatch: AppDispatch;
  customerId: string;
  title?: string;
  hideModal: () => void;
};

export const onSubmitForm = async ({
  values,
  setLoading,
  isFileAttached,
  dispatch,
  customerId,
  title,
  hideModal,
  attachedFile,
}: Test) => {
  const now = moment();

  const modifyExpiredTime =
    typeof values.date === 'number'
      ? moment(values.date).set({
          hour: now.hour(),
          minute: now.minute(),
          second: now.second(),
        })
      : values.date?.set({
          hour: now.hour(),
          minute: now.minute(),
          second: now.second(),
        });

  const newValues = { ...values, date: modifyExpiredTime };

  setLoading(true);

  if (isFileAttached) {
    await uploadDocument({
      dispatch,
      customerId,
      attachedFile,
      documentType: DocumentType.CERTIFICATE,
      certificateName: title?.replace(' Self-certification', ''),
      setLoading,
    });
  }

  await orderCertificate({
    dispatch,
    customerId,
    values: newValues,
    productTitle: title?.replace(' Self-certification', '') || '',
  });

  setLoading(false);

  dispatch(processApi.getAllCertificates({ customerId }));

  hideModal();
};
