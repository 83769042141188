export enum Severity {
  High = 'Critical',
  Medium = 'Serious',
  Low = 'Medium',
  Info = 'Minimal',
  Critical = 'Urgent',
  Analysing = 'Analysing',
}

export enum CMDBSeverity {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum StatusLevel {
  Critical = 'urgent',
  Active = 'Active',
  High = 'critical',
  Medium = 'serious',
  Low = 'medium',
  Informational = 'minimal',
  Open = 'open',
  Resolved = 'resolved',
  Closed = 'closed',
  Analysing = 'analysing',
  None = 'none',
}

export const tagAssetsField = 'tagsAssets';
