import * as Yup from 'yup';

const MAX_DETAILS_LENGTH = 200;

export enum FieldNames {
  Details = 'details',
}

export const validationSchema = Yup.object({
  [FieldNames.Details]: Yup.string().trim().required('Detail field is required'),
});

export const licenceRequestFields = [
  {
    label: 'Detail',
    name: FieldNames.Details,
    type: 'textarea',
    placeholder:
      'Please detail the number of licenses you would like to purchase along with any additional questions or requirements. ',
    height: '120px',
    value: '',
    required: true,
    maxLength: MAX_DETAILS_LENGTH,
  },
];
