import { Status } from '../constants';

export const data = {
  rows: [
    {
      id: '1',
      document: 'Data Protection Policy',
      status: Status.ReviewedAndCompilant,
    },
    {
      id: '2',
      document: 'Both Privacy and Cookie Policies/Notices',
      status: Status.ReviewedAndCompilant,
    },
    {
      id: '3',
      document: 'Both Privacy and Cookie Policies/Notices',
      status: Status.NotDefined,
    },
    {
      id: '4',
      document: 'Data Breach/Incident Responce Policy',
      status: Status.NotReviewed,
      comments:
        'Benjamin Adams: This is an excellent document that meets all the requirements. Norm would advise renewing this documnentation on an annual basis',
    },
  ],
  count: 4,
};
