import moment from 'moment';
import React from 'react';
import { IDate } from 'services/interfaces';
import { logoReportImageUrl, penknifeImageUrl } from '../constants';

export const PageTitle = ({
  reportDate,
  companyName,
}: {
  reportDate: IDate;
  companyName: string;
}) => {
  return (
    <div className='page page-first'>
      <div className='page-first-body'>
        <div className='page-first-title'>
          <p>
            Monthly
            <br /> Service Report
          </p>
        </div>
        <div className='page-first-description'>
          <p>
            {moment(reportDate.startDate).format('MMMM YYYY')}
            <br />
            {companyName}
          </p>
        </div>

        <div className='page-first-content'>
          <div className='page-first-img-wr'>
            <img width='360' height='235' alt='Penknife' src={penknifeImageUrl} />
          </div>
          <div className='page-first-company-title'>COMPANY CONFIDENTIAL</div>
        </div>
      </div>
      <div className='page-first-footer'>
        <table>
          <tbody>
            <tr>
              <td width='100%'>
                <p>
                  *Like a Swiss Army Knife this <br /> report contains everything you <br /> need to
                  know about your <br /> Cyber Resilience.
                </p>
              </td>
              <td>
                <img alt='logo' src={logoReportImageUrl} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
