import { Box } from 'shared';

export const SuppressedVulnsSnackBarNotification = () => {
  return (
    <Box w='400px'>
      <p>
        There are&nbsp;
        <a href='/vulnerability_management/list/?tab=suppressed&expiredSuppression=true'>
          suppressed vulnerabilities
        </a>
        &nbsp;within your environment that need to be reviewed.
      </p>
    </Box>
  );
};
