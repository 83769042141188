export const auditLogsData = {
  rows: [
    {
      subject: {
        name: 'Samantha Elliott',
        email: 'seliott@normcyber.com',
      },
      date: 1643806659098,
      action: 'Assigned to Alan Richardson <a.richardson@normcyber.com>',
    },
    {
      subject: {
        name: 'System',
      },
      date: 1643806659098,
      action: 'Test status changed to Open',
    },
    {
      subject: {
        name: 'Henrietta Rogers',
        email: 'dowson@normcyber.com',
      },
      date: 1643806659098,
      action: 'Test assignment deleted',
    },
    {
      subject: {
        name: 'System',
      },
      date: 1643806659098,
      action: 'Test status changed to Assigned',
    },
    {
      subject: {
        name: 'Montgomery Bartholomew Richardson Junior',
        email: 'montgomery.b.richardson_jr@starbuckscoffee.com',
      },
      date: 1643806659098,
      action: 'Assigned to Ben Richardson <a.richardson@normcyber.com>',
    },
  ],
  count: 5,
};
