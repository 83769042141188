import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AlarmId,
  ApiError,
  CustomerId,
  MdrService,
  OrderMDRLicenseRequest,
  SecurityIncidentsService,
  ThreatDetectionService,
} from 'services/api';

const activeEDRAgents = createAsyncThunk(
  'active-EDR-agents/data/get',
  async ({
    customerId,
    startDate,
    endDate,
  }: {
    customerId: string;
    startDate: number;
    endDate: number;
  }) => {
    try {
      const response = await ThreatDetectionService.getEdr({
        customerId,
        startDate,
        endDate,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const events = createAsyncThunk(
  'events/data/get',
  async ({
    customerId,
    startDate,
    endDate,
    eventService,
  }: {
    customerId: string;
    startDate: number;
    endDate: number;
    eventService?: string;
  }) => {
    try {
      const response = await ThreatDetectionService.getEvents({
        customerId,
        startDate,
        endDate,
        eventService,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const eventsByServices = createAsyncThunk(
  'events-by-services/data/get',
  async ({
    customerId,
    startDate,
    endDate,
  }: {
    customerId: string;
    startDate: number;
    endDate: number;
  }) => {
    try {
      const response = await ThreatDetectionService.getEventsByServices({
        customerId,
        startDate,
        endDate,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const activeServices = createAsyncThunk(
  'active-services/data/get',
  async ({ customerId }: { customerId: CustomerId }) => {
    try {
      const response = await ThreatDetectionService.getActiveServices({
        customerId,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const meanTimeToInvestigate = createAsyncThunk(
  'managed-detections/mtti',
  async ({
    customerId,
    startDate,
    endDate,
  }: {
    customerId: CustomerId;
    startDate: number;
    endDate: number;
  }) => {
    try {
      const response = await MdrService.getCustomerMtti({
        customerId,
        startDate,
        endDate,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const getActiveEdrAgentsStatistic = createAsyncThunk(
  'managed-detections/edr-statistic',
  async ({ customerId }: { customerId: CustomerId }) => {
    try {
      const response = await MdrService.getActiveEdrAgentsStatistic({
        customerId,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const getActiveSecurityIncidents = createAsyncThunk(
  'managed-detections/active-security-incidents',
  async ({ customerId }: { customerId: CustomerId }) => {
    try {
      const response = await SecurityIncidentsService.getActiveSecurityIncidentsCount({
        customerId,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const getInvestigationOverview = createAsyncThunk(
  'managed-detections/get-investigation-overview',
  async ({
    customerId,
    startDate,
    endDate,
  }: {
    customerId: string;
    startDate: number;
    endDate: number;
  }) => {
    try {
      const response = await ThreatDetectionService.getInvestigationOverview({
        customerId,
        startDate,
        endDate,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const getAlarmDetails = createAsyncThunk(
  'managed-detections/get-alarm-details',
  async ({ customerId, alarmId }: { customerId: string; alarmId: AlarmId }) => {
    try {
      const response = await ThreatDetectionService.getAlarmDetails({
        customerId,
        alarmId,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const sendLicenseRequest = createAsyncThunk(
  'managed-detections/send-licence-request',
  async ({
    customerId,
    requestBody,
  }: {
    customerId: string;
    requestBody?: OrderMDRLicenseRequest;
  }) => {
    try {
      const response = await ThreatDetectionService.orderLicenseRequest({
        customerId,
        requestBody,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const getMonitoredServiceDetails = createAsyncThunk(
  'managed-detections/send-licence-request',
  async ({ customerId, name }: { customerId: string; name: string }) => {
    try {
      const response = await ThreatDetectionService.getEventServiceDetails({
        customerId,
        name,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

const exportActiveEdrAgents = createAsyncThunk(
  'managed-detections/export-active-edr-agents',
  async ({ customerId }: { customerId: string }) => {
    try {
      const response = await ThreatDetectionService.exportActiveEdrAgents({
        customerId,
      });

      return response;
    } catch (error) {
      const { body } = error as ApiError;

      throw body;
    }
  },
);

export const managedDetectionsResponseApi = {
  activeServices,
  activeEDRAgents,
  events,
  eventsByServices,
  meanTimeToInvestigate,
  getActiveEdrAgentsStatistic,
  getActiveSecurityIncidents,
  getInvestigationOverview,
  getAlarmDetails,
  sendLicenseRequest,
  getMonitoredServiceDetails,
  exportActiveEdrAgents,
};
