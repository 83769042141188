import { modeIcons } from './Icons/systemIcons';
import { Themes } from '../../themes/types';

export enum ThemeMode {
  Light = 'Light',
  Dark = 'Dark',
  System = 'System',
}

export type ThemeModesType = {
  value: string;
  icon: () => JSX.Element;
  label: ThemeMode;
};

export const themeModes: ThemeModesType[] = [
  {
    value: 'system',
    icon: modeIcons.system,
    label: ThemeMode.System,
  },
  { value: Themes.Norm, icon: modeIcons.light, label: ThemeMode.Light },
  { value: Themes.DarkNorm, icon: modeIcons.dark, label: ThemeMode.Dark },
];

export const defaultThemeMode = 'system';
