import React from 'react';
import { MonthlyReportResponse, SupportRequestsStatus } from 'services/api';
import moment from 'moment/moment';
import { getSortedDashboardScores } from '../../helpers';
import {
  ActiveState,
  ClosedState,
  CreatedAtCell,
  IconCircle,
  PriorityCell,
  SecurityIncidenceTable,
  StatisticsAlerts,
  StatisticsEvents,
  StatisticsIncidents,
  StatusCell,
  StyledStatusTagColoring,
  SummaryCell,
  TableWrapper,
  TextWrapper,
  TicketCell,
  UpdatedAtCell,
  Wrapper,
} from './styled';
import { GradientPieReport } from '../../components/Charts/GradientPieReport';
import { CyberResilienceScoreDescriptionScoreMessage, Score } from '../styled';
import {
  getLabelPriority,
  getNormalizedStatus,
} from '../../../../../../SupportRequests/helpers/helpers';
import { dateFormat } from '../../../../../../../app-constants';
import { getCapitalizedString } from '../../../../../../../services/helpers';
import { StatusLevel } from '../../../../../../../services/constants';
import { Theme } from '../../../../../../../styles/theme/types';

const NUMBER_ROUNDING_MILLION = 6;
const NUMBER_ROUNDING_MILLION_SLICE = -3;

type Props = {
  technologyRank: string;
  data: MonthlyReportResponse;
  onChartLoaded: () => void;
  theme: Theme;
};

function statusType(status: string, theme: Theme) {
  switch (status) {
    case StatusLevel.Open:
    case SupportRequestsStatus.WITH_CUSTOMER:
      return theme.colorSet.medium;
    case StatusLevel.Resolved:
      return theme.colorSet.success;
    default:
      return theme.colorSet.closed;
  }
}

export function PdfPage8({ data, onChartLoaded, theme, technologyRank }: Props) {
  const { dashboardScores, alarmsCount, eventsCount, supportRequests } = data;

  const scores = dashboardScores || [];
  const checkingDashboardScores = getSortedDashboardScores([...scores])[scores.length - 1];
  const technologyScore = checkingDashboardScores?.technology;

  const securityIncidentsClosed = supportRequests?.securityIncidents?.closed || 0;
  const securityIncidentsActive = supportRequests?.securityIncidents?.active || 0;
  const securityIncidentsOpened = supportRequests?.securityIncidents?.opened || 0;

  const numberFormatting = (num: number) => {
    const numString = num.toString();
    const regEx = /\B(?=(\d{3})+(?!\d))/g;

    if (numString.length > NUMBER_ROUNDING_MILLION) {
      const newNum = numString.slice(0, NUMBER_ROUNDING_MILLION_SLICE).replace(regEx, '.');

      return `${newNum}M`;
    }

    return numString.replace(regEx, ',');
  };

  const openedList = data.supportRequests?.securityIncidents?.openedList || [];

  return (
    <Wrapper>
      <TextWrapper>
        <CyberResilienceScoreDescriptionScoreMessage
          dangerouslySetInnerHTML={{ __html: getCapitalizedString(technologyRank) }}
        />
        <p>
          Insert copy here. Pelictaspis experat emoluptatur, quam eos eiur aut libust, ipita del mi,
          quist excea sunt.Oviti cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit
          pereped ut qui quisimi, tem dolorro vidunde lenderor aut estrumque neceari opta sinis
          excestiate comni nonsedi velibeate coriti as quas mo exerspe rumql et ipsum utem nos eos
          et landanti con ni ad ut que eum quis et aperuptatur? Insert copy here. Pelictaspis
          experat emoluptatur, quam eos eiur aut libust, ipita del mi, quist excea sunt.Oviti
          cullanis quibernati ut milit auda nescimendi dolum fuga. Lam, odit pereped ut qui quisimi,
          tem dolorro vidunde lenderor aut estrumque neceari opta sinis excestiate comni nonsedi.
        </p>
      </TextWrapper>
      <Score>
        <GradientPieReport
          width='110px'
          height='110px'
          fontSize={25}
          score={technologyScore}
          onFinished={onChartLoaded}
        />
      </Score>
      <StatisticsEvents>{numberFormatting(eventsCount || 0)} Events</StatisticsEvents>
      <StatisticsAlerts>{numberFormatting(alarmsCount || 0)} Alerts</StatisticsAlerts>
      <StatisticsIncidents>
        {numberFormatting(securityIncidentsOpened)} Incidents
      </StatisticsIncidents>
      <ClosedState>{numberFormatting(securityIncidentsClosed)} Closed</ClosedState>
      <ActiveState>{numberFormatting(securityIncidentsActive)} Active</ActiveState>
      <TableWrapper>
        <h4>Top 5 security incidents</h4>
        <SecurityIncidenceTable>
          <thead>
            <tr>
              <PriorityCell>Priority</PriorityCell>
              <TicketCell>Ticket #</TicketCell>
              <th>Summary</th>
              <StatusCell>Status</StatusCell>
              <CreatedAtCell>Created date</CreatedAtCell>
              <UpdatedAtCell>Updated date</UpdatedAtCell>
            </tr>
          </thead>
          <tbody>
            {openedList.map(({ id, summary, created, updated, priority, status }) => (
              <tr key={id}>
                <td>
                  <StyledStatusTagColoring title={getLabelPriority(priority as string)} />
                </td>
                <td className='text-center'>{id}</td>
                <td>
                  <SummaryCell>
                    <span>{summary}</span>
                  </SummaryCell>
                </td>
                <td>
                  <IconCircle
                    style={{ backgroundColor: statusType((status as string).toLowerCase(), theme) }}
                  />

                  {getCapitalizedString(getNormalizedStatus(status as string))}
                </td>
                <td className='text-right'>{moment(created).format(dateFormat)}</td>
                <td className='text-right'>{moment(updated).format(dateFormat)}</td>
              </tr>
            ))}
          </tbody>
        </SecurityIncidenceTable>
      </TableWrapper>
    </Wrapper>
  );
}
