import React from 'react';
import { useApp, useCEPlus, useCustomerChange, useLoading } from 'store';
import { Box, Card, Icon, ScrollContainer } from 'shared';
import { BarChart } from 'shared/charts';
import { RenderChartWrapper } from 'shared/RenderChartWrapper';
import { Link, useHistory } from 'react-router-dom';
import { validateField, validateFieldValue, viewMoreConfig } from 'pages/CEPlus/helpers';
import { useTheme } from 'styled-components';
import { LinkContainer } from 'shared/Link/LinkContainer';
import { SubTitle } from 'shared/Card/components/SubTitle/SubTitle';
import { Routes } from 'services/entities';
import { cyberEssentialPlusApi } from 'store/cyberEssentialPlus/thunks';
import { numberFormatter } from 'services/helpers';
import { EChartsEventParams } from 'shared/charts/interfaces';
import { Filter } from 'services/api';
import { getQueryString } from 'services/api/core/request';
import { TotalCustomTooltipContainer } from 'shared/charts/styled';
import { initialPagination } from 'app-constants';
import {
  cePlusBackRoute,
  IFilterField,
  initialCMDBOrderBy,
  ITEM_CHART_HEIGHT,
  MAX_EVENTS_COUNT,
  MIN_CHART_HEIGHT,
  tooltipTitles,
} from '../../constants';
import { ChartContainer } from '../../styled';
import { getOption } from '../../barChartHelper';

export const HardwareManufacturer = () => {
  const theme = useTheme();
  const { isMobile } = useApp();
  const history = useHistory();
  const { topHardwareManufacturer, selectedTags } = useCEPlus();
  const loading = useLoading([cyberEssentialPlusApi.getTopHardwareManufacturer.typePrefix]);
  const customerChange = useCustomerChange([
    cyberEssentialPlusApi.getTopHardwareManufacturer.typePrefix,
  ]);
  const isShowEmptyChart =
    customerChange[cyberEssentialPlusApi.getTopHardwareManufacturer.typePrefix];

  const colors = [
    theme.colors.purple[400],
    theme.colors.orange[400],
    theme.colors.green[100],
    theme.colors.blue[400],
    theme.colors.red[600],
  ];

  const barChartValue = topHardwareManufacturer?.chart || [];
  const isScrollable = barChartValue.length > MAX_EVENTS_COUNT;

  const heightOfChart = !isScrollable
    ? MIN_CHART_HEIGHT
    : MIN_CHART_HEIGHT + ITEM_CHART_HEIGHT * (barChartValue.length - MAX_EVENTS_COUNT);

  const getHardwareManufacturerParams = (value: string, tagsAssets?: string[]) => {
    const filterFields: IFilterField[] = [
      {
        name: 'hardwareManufacturer',
        value,
        type: Filter.type.VALUE,
      },
      {
        name: 'tagsAssets',
        value: tagsAssets || [],
        validate: (tags) => validateFieldValue(tags as string[]),
        type: Filter.type.MULTIPLE,
      },
    ];

    return {
      orderBy: initialCMDBOrderBy,
      pagination: initialPagination,
      filter: {
        fields: validateField(filterFields),
      },
    };
  };

  const onEvents = React.useMemo(
    () => ({
      click: (params: EChartsEventParams) => {
        if (!params.name) {
          return null;
        }

        return history.push(
          `${Routes.CMDB}${getQueryString(
            getHardwareManufacturerParams(params.name, selectedTags),
          )}`,
          cePlusBackRoute,
        );
      },
    }),
    [history, selectedTags],
  );

  return (
    <Card
      title='Hardware manufacturer'
      tooltipTitle={tooltipTitles.hardwareManufacturer}
      subTitle={
        <SubTitle
          boldText={numberFormatter(topHardwareManufacturer.count || 0, 1)}
          customText='Total'
        />
      }
      showDivider={false}
      height={theme.sizes.full}
      headStyles={
        !isMobile
          ? { mt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[cyberEssentialPlusApi.getTopHardwareManufacturer.typePrefix]}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        h={theme.sizes.full}
      >
        <ScrollContainer width='600px'>
          <ChartContainer isScrollable={isScrollable}>
            <TotalCustomTooltipContainer>
              <RenderChartWrapper
                values={barChartValue}
                emptyText='No hardware manufacturer yet'
                isShowEmptyChart={isShowEmptyChart}
              >
                <BarChart
                  values={barChartValue}
                  isMobile={isMobile}
                  getOption={getOption}
                  height={heightOfChart}
                  onEvents={onEvents}
                  color={colors}
                  isLoading={loading[cyberEssentialPlusApi.getTopHardwareManufacturer.typePrefix]}
                />
              </RenderChartWrapper>
            </TotalCustomTooltipContainer>
          </ChartContainer>
        </ScrollContainer>

        <LinkContainer
          borderTop={!isMobile ? '' : `${theme.sizes.px} solid ${theme.colors.blue[50]}`}
          hasBorder
        >
          <Link to={viewMoreConfig().cmdbHardwareManufacturer}>
            See all hardware manufacturers
            <Icon component={theme.icons.arrow_right_alt} />
          </Link>
        </LinkContainer>
      </Box>
    </Card>
  );
};
