import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { Box, InnerLayout, Switch } from 'shared';
import { maintenanceSetting, useApp, useCustomer, useLoading, useMaintenanceSettings } from 'store';
import { useGetPermission, useRowGap } from 'services/hooks';
import { PermissionOptions, PermissionsLevel } from 'app-constants';
import { useTheme } from 'styled-components';
import {
  EditButton,
  EditMode,
  getLayoutConfig,
  LabelTitles,
  toggleNotifyUsersTitle,
  TooltipText,
} from './helpers';
import { NotifyText, StyledCard } from './style';
import { maintenanceSettingsApi } from '../../store/maintenance/thunks';
import { MaintenanceEditMode } from './components/MaintenanceMode/EditMode';
import { MaintenanceViewMode } from './components/MaintenanceMode/ViewMode';
import { SnackBarEditMode } from './components/SnackBar/EditMode';
import { SnackBarViewMode } from './components/SnackBar/ViewMode';

export const MaintenancePageSettings = () => {
  const dispatch = useDispatch();
  const { isMobile } = useApp();
  const customerId = useCustomer();
  const { toggleNotifyUsers } = useMaintenanceSettings();
  const theme = useTheme();
  const loading = useLoading([maintenanceSettingsApi.getMaintenanceSettings.typePrefix]);
  const [isEditMode, setIsEditMode] = useState({ snackbar: false, maintenanceMessage: false });
  const permission = useGetPermission(PermissionOptions.MaintenanceSettings);
  const { gapNumber } = useRowGap();

  const editMobileSnackBarCardHeight = isMobile ? '550px' : '540px';
  const editMobileMaintenanceCardHeight = isMobile ? '420px' : '540px';

  const layoutConfig = getLayoutConfig();

  useEffect(() => {
    dispatch(maintenanceSettingsApi.getMaintenanceSettings(customerId));
  }, [customerId, dispatch, isEditMode.maintenanceMessage, isEditMode.snackbar]);

  const toggleEditMode = useCallback((editMode: EditMode) => {
    setIsEditMode((prevState) => {
      return { ...prevState, [editMode]: !prevState[editMode] };
    });
  }, []);

  const onSwitchToggleButton = React.useCallback(
    (isNotifyUsers: boolean) => {
      dispatch(maintenanceSettingsApi.toggleMaintenanceMode({ customerId, isNotifyUsers }));
      dispatch(maintenanceSetting.actions.setIsNotifyUsers(isNotifyUsers));
    },
    [customerId, dispatch],
  );

  const isEditSnackBartModeBtnShown = !isEditMode.snackbar && permission === PermissionsLevel.Full;
  const isEditMaintenanceModeBtnShown =
    !isEditMode.maintenanceMessage && permission === PermissionsLevel.Full;

  return (
    <InnerLayout {...layoutConfig}>
      <Row gutter={[gapNumber, gapNumber]}>
        <Col xl={12} lg={24}>
          <StyledCard
            title={LabelTitles.SnackbarText}
            showDivider
            tooltipTitle={TooltipText.SnackbarText}
            showLoader={loading[maintenanceSettingsApi.getMaintenanceSettings.typePrefix]}
            height={
              isEditMode.maintenanceMessage || isEditMode.snackbar
                ? editMobileSnackBarCardHeight
                : '330px'
            }
            headAction={
              <EditButton
                showButton={isEditSnackBartModeBtnShown}
                editType='snackbar'
                action={toggleEditMode}
              />
            }
            subTitle={
              <Box d='flex' mt='10px'>
                <Switch size='small' onChange={onSwitchToggleButton} checked={toggleNotifyUsers} />
                <NotifyText>{toggleNotifyUsersTitle}</NotifyText>
              </Box>
            }
            headStyles={{ pb: theme.spacing[4], titlePaddingRight: theme.spacing['2.5'] }}
            bodyPadding={`${theme.spacing[6]} ${theme.spacing['2.5']}`}
            cardPadding={`${theme.spacing['5.5']} ${theme.spacing['5.5']} ${theme.spacing[0]}`}
          >
            {isEditMode.snackbar ? (
              <SnackBarEditMode hideEditMode={toggleEditMode} />
            ) : (
              <SnackBarViewMode
                loading={loading[maintenanceSettingsApi.getMaintenanceSettings.typePrefix]}
              />
            )}
          </StyledCard>
        </Col>
        <Col xl={12} lg={24} sm={24} xs={24}>
          <StyledCard
            title={LabelTitles.MaintenancePageMessage}
            showDivider
            tooltipTitle={TooltipText.MaintenancePageMessage}
            showLoader={loading[maintenanceSettingsApi.getMaintenanceSettings.typePrefix]}
            height={
              isEditMode.maintenanceMessage || isEditMode.snackbar
                ? editMobileMaintenanceCardHeight
                : '330px'
            }
            headAction={
              <EditButton
                showButton={isEditMaintenanceModeBtnShown}
                editType='maintenanceMessage'
                action={toggleEditMode}
              />
            }
            headStyles={{
              pb: isEditMode.maintenanceMessage ? theme.spacing[7] : theme.spacing[4],
            }}
            bodyPadding={`${theme.spacing[6]} ${theme.spacing['2.5']}`}
            cardPadding={`${theme.spacing['5.5']} ${theme.spacing['5.5']} ${theme.spacing[0]}`}
          >
            {isEditMode.maintenanceMessage ? (
              <MaintenanceEditMode hideEditMode={toggleEditMode} />
            ) : (
              <MaintenanceViewMode
                loading={loading[maintenanceSettingsApi.getMaintenanceSettings.typePrefix]}
              />
            )}
          </StyledCard>
        </Col>
      </Row>
    </InnerLayout>
  );
};
