import styled from 'styled-components';
import { Theme } from 'styles/theme/types';
import { Card } from 'shared';
import { CardFooter } from 'shared/Card/components/styled';

type Props = { theme: Theme };

export const CardStyled = styled(Card)<Props>`
  ${CardFooter} {
    @media (max-width: ${({ theme }: Props) => theme.breakpoints.md}) {
      padding-top: ${({ theme }: Props) => theme.sizes[1]};
    }
  }
`;
