import styled from 'styled-components';
import { Button } from 'shared';
import { Theme } from 'styles/theme/types';
import { Modal } from 'shared/Modal/Modal';
import { getCssVar } from '../../styles/theme';

export const Popup = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
      padding: 0;
      border-radius: 4px;
      border: none;

      .ant-modal-title > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .ant-modal-body {
      padding: 0 0 4px;
    }
  }
`;

type TitleProps = {
  noSorting: boolean;
};

export const Title = styled.div<TitleProps>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;

  > span {
    ${(props: TitleProps) => props.noSorting && 'padding-top: 8px'};
    font-size: 16px;
    font-weight: ${({ theme }: { theme: Theme }) => theme.fontWeights.bold};
  }
`;

type ItemProps = {
  active: boolean;
  theme: Theme;
};

export const PopupItem = styled.div<ItemProps>`
  background-color: ${(props: ItemProps) =>
    props.active ? getCssVar('filterSortPopup') : getCssVar('filterSortPopupNotActive')};
  display: flex;
  font-size: ${({ theme }: { theme: Theme }) => theme.sizes[4]};
  justify-content: space-between;
  padding: ${({ theme }: { theme: Theme }) => `${theme.spacing['3.5']} ${theme.spacing[3]}`};
`;

export const OrderArrows = styled.div`
  height: ${({ theme }: { theme: Theme }) => theme.sizes[5]};
  position: relative;
  width: ${({ theme }: { theme: Theme }) => theme.spacing[8]};

  .anticon:last-child {
    bottom: -12%;
    left: 28%;
    position: absolute;
    transform: rotate(180deg);
  }

  .anticon:first-child {
    position: absolute;
    right: -18%;
    top: -6%;
  }
`;

export const StyledButton = styled(Button)`
  width: 75px;

  > span {
    text-align: center;
    width: 100%;
  }
`;
