import { useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  AppDispatch,
  servicesIntegrationsApi,
  useCustomer,
  useServiceIntegrationDetails,
  useRequestLoading,
  PayloadActionType,
} from 'store';
import { useParams, useHistory } from 'react-router';
import { useUserPermissions } from 'pages/ServicesIntegrations/hooks';
import { RequestStatus } from 'store/loading/slice';
import { showError } from 'services/helpers';
import { Routes } from 'services/entities';
import { ServiceIntegrationResponse } from 'services/api';
import { useSearchParams } from 'services/hooks';

export const useGetServiceDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentCustomerId = useCustomer();
  const { id: serviceIdFromUrl } = useParams<{ id: string }>();
  const { canView } = useUserPermissions();
  const history = useHistory();
  const ref = useRef({ customerId: currentCustomerId });

  const isLoading = useRequestLoading(servicesIntegrationsApi.getServiceDetails.typePrefix);
  const { serviceName, connectionDetails, guidanceDetails } = useServiceIntegrationDetails();

  const isCustomerChanged = ref.current.customerId !== currentCustomerId;
  // const canMakeServiceRequest = canView && serviceIdFromUrl !== serviceId && !isCustomerChanged;
  const canMakeServiceRequest = canView;

  useEffect(() => {
    // when customer is changed, we should go back to the services integrations page
    if (isCustomerChanged) {
      ref.current.customerId = currentCustomerId;

      history.push(Routes.SERVICES_INTEGRATIONS);

      return;
    }

    if (canMakeServiceRequest) {
      dispatch(
        servicesIntegrationsApi.getServiceDetails({
          customerId: currentCustomerId,
          serviceId: serviceIdFromUrl,
        }),
      ).then((response) => {
        const result = response as PayloadActionType<ServiceIntegrationResponse>;

        if (result.meta.requestStatus === RequestStatus.Rejected) {
          if (result.error.message === 'Not found') {
            history.push(Routes.NOT_FOUND);
          } else {
            showError();
          }
        }
      });
    }
  }, [
    canMakeServiceRequest,
    isCustomerChanged,
    serviceIdFromUrl,
    currentCustomerId,
    dispatch,
    history,
  ]);

  return {
    isLoading,
    serviceName,
    serviceId: serviceIdFromUrl,
    connectionDetails,
    guidanceDetails,
  };
};

export const useGuidanceDetailsTabs = () => {
  const history = useHistory();
  const { tab } = useSearchParams();

  const setActiveTab = useCallback(
    (activeTab: string) => {
      history.push({
        pathname: history.location.pathname,
        search: `tab=${activeTab}`,
      });
    },
    [history],
  );

  return {
    tab,
    setActiveTab,
  };
};
