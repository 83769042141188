import { Filter, InputType, NotificationStatus, NotificationType, OrderBy } from 'services/api';
import { icons } from '../../styles/theme';
import { ActionType } from '../../shared/ActionPopup/types';
import { OptionType } from '../../shared/Table/types';
import { minLengthToSearch } from '../../app-constants';

export enum TableTitles {
  Title = 'Title',
  Message = 'Message',
  Type = 'Type',
  Status = 'Status',
  CreatedBy = 'Created by',
  Created = 'Created',
  Published = 'Published',
  Actions = 'Actions',
}

export enum ColumnKey {
  Title = 'title',
  Message = 'message',
  Type = 'type',
  Status = 'status',
  CreatedBy = 'createdBy',
  Created = 'created',
  Published = 'published',
  Actions = 'actions',
}

export const searchPlaceholder = 'Search by title/message';

export const searchFieldsForNormUser = ['title', 'message'];
export const searchFields = ['title', 'message'];

export const filterFieldsNormUser = (createdByOptions: string[]) => [
  {
    field: 'type',
    options: Object.values(NotificationType).map((type) => ({
      label: type,
      value: type,
    })),
    label: 'Type',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'status',
    options: Object.values(NotificationStatus).map((status) => ({
      label: status,
      value: status,
    })),
    label: 'Status',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'createdBy',
    options: createdByOptions.map((author) => ({
      label: author,
      value: author,
    })),
    label: 'Created by',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'created',
    label: 'Created',
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
  {
    field: 'published',
    label: 'Published',
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
];

export const filterFields = (unreadCount: number) => [
  {
    field: 'type',
    options: Object.values(NotificationType).map((type) => ({
      label: type,
      value: type,
    })),
    label: 'Type',
    inputType: InputType.SELECTOR,
    filterType: Filter.type.MULTIPLE,
  },
  {
    field: 'published',
    label: 'Received',
    inputType: InputType.DATE,
    filterType: Filter.type.RANGE,
  },
  {
    field: 'read',
    count: unreadCount,
    label: 'Show unread only',
    inputType: InputType.RADIO,
    filterType: Filter.type.VALUE,
  },
];

export const searchSettings = (isNormUser: boolean) => ({
  fields: isNormUser ? searchFieldsForNormUser : searchFields,
  min: minLengthToSearch,
  placeholder: searchPlaceholder,
});

export const popupConfig = {
  edit: {
    id: 'edit',
    title: 'Edit tag',
  },
  createAndPublish: {
    id: 'createAndPublish',
    title: 'Publish notification now',
    onApplyTitle: 'Publish now',
  },
  updateAndPublish: {
    id: 'updateAndPublish',
  },
  publish: {
    id: 'publish',
    title: 'publish',
  },
  unPublish: {
    id: 'unPublish',
    title: 'Unpublish notification now',
    onApplyTitle: 'Unpublish now',
  },
  markAsRead: {
    id: 'markAsRead',
    title: 'Mark as read',
  },
  markAsUnread: {
    id: 'markAsUnread',
    title: 'Mark as unread',
  },
  delete: {
    id: 'delete',
    title: 'Delete notification',
    text: "This action can't be undone.",
    onApplyTitle: 'Delete notification',
    danger: true,
  },
};

export const userInitialOrderBy = {
  field: 'published',
  type: OrderBy.type.DESC,
};

export const NormInitialOrderBy = {
  field: 'created',
  type: OrderBy.type.DESC,
};

export enum ActionTypeName {
  Edit = 'Edit',
  Publish = 'Publish',
  UnPublish = 'Unpublish',
  MarkAsRead = 'Mark as read',
  MarkAsUnread = 'Mark as unread',
  Delete = 'Delete',
}

export const userActions: ActionType[] = [
  { name: ActionTypeName.MarkAsRead, icon: icons.read, popupId: popupConfig.markAsRead.id },
  {
    name: ActionTypeName.MarkAsUnread,
    icon: icons.unread,
    popupId: popupConfig.markAsUnread.id,
  },
];

export const AdminActions: ActionType[] = [
  { name: ActionTypeName.Edit, icon: icons.edit, popupId: popupConfig.edit.id },
  { name: ActionTypeName.Publish, icon: icons.file_upload, popupId: popupConfig.publish.id },
  {
    name: ActionTypeName.UnPublish,
    icon: icons.uploadNotAvailable,
    popupId: popupConfig.unPublish.id,
  },
];

export const DangerActions: ActionType[] = [
  {
    name: ActionTypeName.Delete,
    icon: icons.delete,
    popupId: popupConfig.delete.id,
    isDanger: true,
  },
];

export const actions: ActionType[] = [...AdminActions, ...userActions, ...DangerActions];

export const optionTypes: OptionType[] = Object.values(NotificationType).map((notification) => ({
  value: notification,
  label: notification,
}));

export const emptyGridMessage = 'No notifications yet';
export const noUnreadNotificationsMessage = 'There are no unread notifications';

export const userColumns = [
  ColumnKey.Title,
  ColumnKey.Message,
  ColumnKey.Type,
  ColumnKey.Published,
];

export const MAX_CHARS_IN_SHORTED_TEXT = 155;
