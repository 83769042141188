import React, { PropsWithChildren } from 'react';
import { Modal as AntdModal, ModalProps } from 'antd';
import { isIOS } from 'react-device-detect';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { Box } from 'shared';

export const Modal = ({
  className,
  title,
  children,
  visible,
  width,
  onOk,
  onCancel,
  ...props
}: PropsWithChildren<ModalProps>) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!isIOS || !ref.current) {
      return undefined;
    }

    if (visible) {
      disableBodyScroll(ref.current as HTMLElement);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [visible, ref]);

  return (
    <Box ref={ref}>
      <AntdModal
        className={className}
        title={title}
        visible={visible}
        width={width}
        onOk={onOk}
        onCancel={onCancel}
        {...props}
      >
        {children}
      </AntdModal>
    </Box>
  );
};
