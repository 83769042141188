import { createSlice } from '@reduxjs/toolkit';
import { EventsResponse } from 'services/api';
import { managedDetectionsResponseApi } from '../thunks';

type EventsState = {
  eventsData: EventsResponse;
};

type EventsAction = {
  payload: EventsResponse;
};

const initialState: EventsState = { eventsData: {} };

export const events = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: {
    [managedDetectionsResponseApi.events.fulfilled.toString()]: (
      state: EventsState,
      action: EventsAction,
    ) => {
      state.eventsData = { ...action.payload };
    },
    [managedDetectionsResponseApi.events.rejected.toString()]: (state: EventsState) => {
      state.eventsData = {};
    },
  },
});
