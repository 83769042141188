import React, { useMemo, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { Theme } from 'styles/theme/types';
import { useTheme } from 'styled-components';
import { IEventsByServices } from 'pages/ThreatDetection/types';
import { CePlusChartProps } from 'store/cyberEssentialPlus/slice';
import { IEChartsOption } from '../interfaces';
import { DELAY } from '../constants';

type ValuesType = IEventsByServices[] | CePlusChartProps[];

type Props<T extends ValuesType> = {
  values: T;
  getOption: (
    values: T,
    theme: Theme,
    height?: number,
    isMobile?: boolean,
    hasClickHandler?: boolean,
    color?: string[],
  ) => IEChartsOption;
  height?: number;
  isMobile?: boolean;
  onEvents?: Record<string, (event: { name: string }) => void>;
  color?: string[];
  isLoading?: boolean;
  isAsyncAnimation?: boolean;
};

let timerId: NodeJS.Timeout;

export const BarChart = <T extends ValuesType>({
  values,
  height,
  getOption,
  isMobile,
  onEvents,
  color,
  isLoading = false,
  isAsyncAnimation = false,
}: Props<T>) => {
  const theme = useTheme();
  const ref = useRef<ReactECharts>();

  const option = useMemo(() => {
    if (isLoading) {
      return {};
    }

    return getOption(values, theme, height, isMobile, Boolean(onEvents), color);
  }, [color, getOption, height, isLoading, isMobile, onEvents, theme, values]);

  const asyncOption = React.useMemo(async () => {
    if (isLoading) {
      clearTimeout(timerId);
    }

    return setTimeout(
      () =>
        ref.current
          ?.getEchartsInstance()
          .setOption(getOption(values, theme, height, isMobile, Boolean(onEvents), color), true),
      DELAY,
    );
  }, [color, getOption, height, isLoading, isMobile, onEvents, theme, values]);

  return (
    <ReactECharts
      option={isAsyncAnimation ? asyncOption : option}
      opts={{ height }}
      onEvents={onEvents}
      ref={(r: ReactECharts) => {
        ref.current = r;
      }}
      notMerge
    />
  );
};
