import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, managedDetectionsResponseApi, useApp, useCustomer, useLoading } from 'store';
import { Box, Card, Icon } from 'shared';
import {
  ManagedDetectionsResponseTitles,
  mdrBackRoute,
  tooltipTitles,
  viewMoreConfig,
} from 'pages/ThreatDetection/constants';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { Routes } from 'services/entities';
import { getQueryString } from 'services/api/core/request';
import { useHistory } from 'react-router';
import { LinkContainer } from 'shared/Link/LinkContainer';
import { VerticalDivider } from 'shared/layouts/Inner/components';
import { Count, IconDanger, Title, Total } from '../styled';
import { IncidentTypeWrapper, SubTitleStyled } from './styled';
import { withNormIncidentsParams, withYouIncidentsParams } from './constants';

export const ActiveSecurityIncidents = () => {
  const theme = useTheme();
  const customerId = useCustomer();
  const { isMobile } = useApp();
  const dispatch = useDispatch();
  const { withNorm = 0, withCustomer = 0 } = useSelector(
    ({ mdr: { activeSecurityIncidents } }: AppState) => activeSecurityIncidents,
  );
  const history = useHistory();

  const withNormRedirect = () =>
    history.push(
      `${Routes.SUPPORT_REQUESTS}${getQueryString(withNormIncidentsParams)}`,
      mdrBackRoute,
    );
  const withCustomerRedirect = () =>
    history.push(
      `${Routes.SUPPORT_REQUESTS}${getQueryString(withYouIncidentsParams)}`,
      mdrBackRoute,
    );

  const total = withNorm + withCustomer;

  const loading = useLoading([managedDetectionsResponseApi.getActiveSecurityIncidents.typePrefix]);

  React.useEffect(() => {
    dispatch(
      managedDetectionsResponseApi.getActiveSecurityIncidents({
        customerId,
      }),
    );
  }, [customerId, dispatch]);

  const getActiveServicesSubTitle = () => {
    return (
      <>
        {withCustomer > 0 ? (
          <Box d='flex' alignItems='center'>
            <IconDanger
              component={theme.icons.warning}
              color={theme.colorSet.dangerTriangle}
              dimension='36px'
              margin={!isMobile ? '0 9px 0 0' : '0 8px 2px 0'}
            />
            <Total>{total}</Total>
          </Box>
        ) : (
          <Box marginLeft='50px'>{withNorm}</Box>
        )}
      </>
    );
  };

  return (
    <Card
      title={ManagedDetectionsResponseTitles.ActiveSecurityIncidents}
      subTitle={<SubTitleStyled boldText={getActiveServicesSubTitle()} customText='Total' />}
      tooltipTitle={tooltipTitles.activeServices}
      showDivider={false}
      headStyles={
        !isMobile
          ? { pt: theme.sizes[1], titlePaddingRight: theme.sizes[2], headAlign: 'flex-start' }
          : { titlePaddingRight: theme.sizes[0], headAlign: 'flex-start' }
      }
      bodyPadding={!isMobile ? theme.sizes[2] : theme.sizes[0]}
      cardPadding={
        !isMobile
          ? `${theme.sizes[6]} ${theme.sizes[6]} ${theme.sizes[3]}`
          : `${theme.sizes[5]} ${theme.sizes[5]} ${theme.sizes[2]}`
      }
      showLoader={loading[managedDetectionsResponseApi.getActiveSecurityIncidents.typePrefix]}
    >
      <Box display='flex' justifyContent='space-between' mt='22px'>
        <IncidentTypeWrapper onClick={withCustomerRedirect}>
          <Title>With you</Title>
          <Count>{withCustomer}</Count>
        </IncidentTypeWrapper>
        <VerticalDivider />
        <IncidentTypeWrapper onClick={withNormRedirect}>
          <Title>With norm</Title>
          <Count>{withNorm}</Count>
        </IncidentTypeWrapper>
      </Box>
      <LinkContainer style={{ padding: 0, marginTop: '45px' }}>
        <Link to={viewMoreConfig.activeSecurityIncidents}>
          View all incidents
          <Icon component={theme.icons.arrow_right_alt} />
        </Link>
      </LinkContainer>
    </Card>
  );
};
