/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BlobScanStatus {
  PENDING_SCAN = 'Pending scan',
  BEING_SCANNED = 'Being scanned',
  SUCCESS = 'Success',
  FILE_SCAN_ERROR = 'File scan error',
}
