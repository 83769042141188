import { isIOS } from 'react-device-detect';

export const downloadCSV = (csv: string, name: string) => {
  const blob = new Blob(['sep=,\r\n', csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', isIOS ? `${name}.csv` : name);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
