import styled from 'styled-components';
import { PATH_TO_MONTHLY_REPORT_IMAGES } from 'app-constants';
import { Page } from '../styled';

export const PageWrapper = styled(Page)`
  background: url('${PATH_TO_MONTHLY_REPORT_IMAGES}/slide13.jpeg') no-repeat #fafafa;
  background-size: auto 100%;
`;

export const ServiceChangesTable = styled.table`
  font-size: 13px;
  left: 50px;
  position: absolute;
  top: 221px;
  width: 956px !important;

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px solid #242430;
      }
    }

    > td {
      padding: 12px 20px;

      &:first-child {
        padding-left: 40px;
        padding-right: 40px;
        width: 603px;
      }

      &:nth-child(2) {
        width: 195px;
      }
    }
  }
`;
