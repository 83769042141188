import React from 'react';
import { UcFirstLetter } from 'shared';
import { getCssVar } from 'styles/theme';
import { ActiveServiceStatusEnum } from 'services/api';
import { useTheme } from 'styled-components';
import { StatusTag } from '../styled';

type IconColoringProps = {
  status: string;
};

export const StatusColoring = ({ status }: IconColoringProps) => {
  const theme = useTheme();

  const statusType = (status: string) => {
    switch (status) {
      case ActiveServiceStatusEnum.RISK:
        return getCssVar('errorColor');
      default:
        return theme.colors.green[300];
    }
  };

  return (
    <StatusTag color={statusType(status)} key={status}>
      <span style={{ color: getCssVar('criticality') }}>{UcFirstLetter(status)}</span>
    </StatusTag>
  );
};
